import React from "react";
import { useStyles } from "./style";
import {
  Grid,
  Box,
  Card,
  CardContent,
  Typography,
  TableCell,
  TableRow,
  Table,
  TableHead,
  TableBody,
} from "@material-ui/core";
import { useTableStyles } from "../../../styles/table.style";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export const StockEntry2Print = React.forwardRef(({ doc }, ref) => {
  const classes = useStyles();
  const tableClasses = useTableStyles();
  const activeImageTheme = useSelector((state) => state.Theme.image);
  const contactCompany = useSelector((state) => state.Login.selectedData);
  const warehouseCible = useSelector(
    (state) => state.Transfert.selectedWarehouseCible
  );
  const { t } = useTranslation(["common", "transfert"]);

  return (
    <div className={classes.main} ref={ref}>
      <header className={classes.header}>
        <div className={classes.logo}>
          <img src={activeImageTheme} />
        </div>
        <h1 className={classes.title}>Entrée des stocks: {doc?.name}</h1>
        <div className={classes.company}>
          <div>Date d'enregistrement: {doc?.posting_date}</div>
        </div>
      </header>

      <Box className={classes.root} style={{ marginTop: 20 }} display="flex">
        <Grid item xs={12} md={6}>
          <Typography ariant="h5" component="h2" className={classes.titlecard}>
            De: {doc?.from_warehouse ?? doc?.items?.[0]?.s_warehouse}
          </Typography>
          <Typography variant="body2" component="p">
            <strong>Ville: </strong>
            {contactCompany && contactCompany?.[0]?.city}
            <br />
            <strong>Adresse: </strong>
            {contactCompany && contactCompany?.[0]?.address_line_1}
          </Typography>
          <Typography variant="body2" component="p">
            <strong>Contact: </strong>
            {contactCompany && contactCompany?.[0]?.mobile_no} |
            {contactCompany && contactCompany?.[0]?.phone_no}
            <br />
            <strong>Email: </strong>
            {contactCompany && contactCompany?.[0]?.email}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography className={classes.titlecard}>
            A : {doc?.to_warehouse ?? doc?.items?.[0]?.t_warehouse}
          </Typography>
          <Typography variant="body2" component="p">
            <strong color="primary">Ville: </strong>
            {warehouseCible && warehouseCible?.city}
            <br />
            <strong>Adresse: </strong>
            {warehouseCible && warehouseCible?.address_line_1}
          </Typography>
          <Typography variant="body2" component="p">
            <strong>Contact: </strong>
            {warehouseCible && warehouseCible?.mobile_no} |
            {warehouseCible && warehouseCible?.phone_no}
            <br />
            <strong>Email: </strong>
            {warehouseCible && warehouseCible?.email}
          </Typography>
        </Grid>
      </Box>
      <Box className={classes.paper}>
        <Typography style={{ paddingTop: 50 }} color={"primary"}>
          Produits transférés
        </Typography>
        <Table className={tableClasses.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                className={tableClasses.tablecellHeader}
                align="center"
              >
                {t("common:mgsSource")}
              </TableCell>
              <TableCell
                className={tableClasses.tablecellHeader}
                align="center"
              >
                {t("common:mgsCible")}
              </TableCell>
              <TableCell className={tableClasses.tablecellHeader} align="left">
                {t("common:ref")}
              </TableCell>
              <TableCell
                className={tableClasses.tablecellHeader}
                align="center"
              >
                {t("common:name")}
              </TableCell>
              <TableCell
                className={tableClasses.tablecellHeader}
                align="center"
              >
                {t("transfert:qtyRecu")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {doc?.items &&
              Array.isArray(doc?.items) &&
              doc?.items.map((row, index) => (
                <TableRow className={tableClasses.tableRow} key={index}>
                  <TableCell className={tableClasses.tableCell} align="left">
                    {row?.s_warehouse}
                  </TableCell>
                  <TableCell className={tableClasses.tableCell} align="center">
                    {row?.t_warehouse}
                  </TableCell>
                  <TableCell className={tableClasses.tableCell} align="center">
                    {row?.item_code}
                  </TableCell>
                  <TableCell className={tableClasses.tableCell} align="center">
                    {row?.item_name}
                  </TableCell>
                  <TableCell className={tableClasses.tableCell} align="center">
                    {row?.qty}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Box>

      <Box className={classes.status} display="flex">
        <Grid item xs={12}>
          <Card>
            <CardContent style={{ height: 50 }}>
              <Typography ariant="h5" component="h2">
                Remarques / Commentaires:
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Box>

      <Box className={classes.status} display="flex">
        <Grid item xs={12} md={6}>
          <Card className={classes.card}>
            <CardContent style={{ height: 100 }}>
              <Typography ariant="h5" component="h2">
                Signature Emetteur:
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card className={classes.card}>
            <CardContent style={{ height: 100 }}>
              <Typography ariant="h5" component="h2">
                Signature Receveur :
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Box>
    </div>
  );
});
