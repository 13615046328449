import React from 'react'
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import {ClientForm} from '../components/createFormClient';

const useStyles = makeStyles((theme) => ({
    root : {
        marginTop: "1rem",
        display: "flex"
    },
}));
export const ClientNonResidCreate = () => {
    const classes = useStyles();

    return (
        <Box>
            <Box className={classes.root}>
                <ClientForm/>
            </Box>
        </Box>
    )
}
