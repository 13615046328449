import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import { TotalPrice } from '../totalPrice';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import {useTranslation} from "react-i18next";
const useStyles = makeStyles((theme) => ({
  table: {
    background: theme.palette.productsTable.background,
    borderSpacing: "0 5px",
    borderCollapse: "separate"
  },
  tableRow: {
    height : 40,
    border : "none",
    "& .MuiTableCell-root:first-child": {
      borderTopLeftRadius: 5,
      borderBottomLeftRadius: 5
    },
    "& .MuiTableCell-root:last-child": {
      borderTopRightRadius: 5,
      borderBottomRightRadius: 5
    }
  },
  tableCell : {
      background : theme.palette.productsTable.tableCell,
      padding : "7px 16px",
      color : theme.palette.primary.main
  },
  paper :  {
      padding: "0px 20px",
      background: theme.palette.productsTable.background,
      overflow: "auto",
      marginBottom: 20,
      borderRadius : 19,
      height:"100%",
      '& .MuiInputBase-root' : {
        background : theme.palette.productsTable.tableCell,
        marginTop : 20
    },
    '& .MuiOutlinedInput-input' : {
        padding: "7.5px 14px",
        fontSize : 12
    }
  },
  price : {
    fontWeight : 700
  },
  tablecellHeader : {
    paddingBottom : 0,
    color : theme.palette.productsTable.tablecellHeader,
    fontSize : 12,
    borderBottom : 'none'
  },
  formControl : {
    minWidth :340,
  }
}));

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('', '', '', ''),
  createData('', '', '', ''),
  createData('', '', '', ''),
  createData('', '', '', ''),
  createData('', '', '', ''),
];

export const  BasicTable = () => {
  const classes = useStyles();
  const {t} = useTranslation(["interfaceCustomization","common"]);

  return (
    <Box className={classes.paper} >
      <FormControl variant="outlined" className={classes.formControl}>
          <OutlinedInput
              id="outlined-adornment-weight"
              value={null}
              placeholder="Enter item code"
              onChange={()=>{}}
              endAdornment={<InputAdornment position="end"><span className={"icon-scan-code"}/></InputAdornment>}
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
              'aria-label': 'weight',
              }}
              labelWidth={0}
          />
        </FormControl>
      <Table className={classes.table} aria-label="simple table">
        <TableHead >
          <TableRow>
            <TableCell  className={classes.tablecellHeader}>{t("common:produit")}</TableCell>
            <TableCell  className={classes.tablecellHeader} align="center">{t("common:ref")}</TableCell>
            <TableCell  className={classes.tablecellHeader} align="center">{t("common:qty")}</TableCell>
            <TableCell  className={classes.tablecellHeader} align="center">{t("common:prix")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row,index) => (  
            <TableRow className={classes.tableRow} key={index}>
              <TableCell className={classes.tableCell}  scope="row">
                {row.name}
              </TableCell>
              <TableCell className={classes.tableCell} align="center">{row.calories}</TableCell>
              <TableCell className={classes.tableCell} align="center">{row.fat}</TableCell>
              <TableCell align="center" className={clsx(classes.tableCell,classes.price)}>{row.carbs}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TotalPrice/>
    </Box>
  );
}