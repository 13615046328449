import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import clsx from "clsx";
const useStyles = makeStyles((theme) => ({
    backLink: {
        fontSize: 12,
        color: '#2F334D',
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        marginBottom: -35
    },
    backIcon: {
        height: 10
    }
}));

export const ButtongoBack = (props) => {
    const classes = useStyles();

    return (
        <Box >
            <Button onClick={() => props.onClick()} color={"primary"} className={clsx(classes.title, classes.backLink)}>
                <ArrowBackIcon />
                {props.label}
            </Button>
        </Box>
    );
};
