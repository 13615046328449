import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import {
    Grid,
    Typography,
    Button,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import LoyaltyOutlinedIcon from '@material-ui/icons/LoyaltyOutlined';
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn';
import YoutubeSearchedForIcon from '@material-ui/icons/YoutubeSearchedFor';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        overflow: 'auto',
        height: '100%'
    },
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        borderRadius: 10,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },
    button: {
        width: '100%',
        height: 90,
        backgroundColor: theme.palette.secondary.main,
        fontSize: 16,
        fontWeight: 'bold',
        border: '3px solid',
        borderColor: theme.palette.blue.main,
        color: theme.palette.freeButton.text,
        '&:hover': {
            backgroundColor: theme.palette.freeButton.background,
        }
    },
    buttonn: {
        width: '100%',
        height: 90,
        backgroundColor: theme.palette.info.dark,
        fontSize: 16,
        fontWeight: 'bold',
        border: '3px solid',
        borderColor: theme.palette.blue.main,
        color: '#fff',
        '&:hover': {
            backgroundColor: theme.palette.productsTable.button,
        },
    },
}));

export const SelectButton = props => {

    const { selected, handleAddbutton, handledeletebutton, open, handleClose, buttons } = props;
    const classes = useStyles();
    const { t } = useTranslation(['interfaceCustomization', 'common', 'checkout']);
    // getModalStyle is not a pure function, we roll the style only on the first render

    const body = (
        <div className={classes.paper}>
            <Typography align={'center'} color={'primary'}>
                {t('interfaceCustomization:buttonLib')}
            </Typography>
            <div className={clsx('freebutton', classes.root)}>
                <Grid container spacing={1}>
                    {buttons.map(button => (

                        <Grid key={button?.id} item xs={6}>
                            <Button className={selected.findIndex(el => el?.id === button?.id) < 0 ? classes.button : classes.buttonn} variant='contained' color='primary'
                                onClick={() => selected.findIndex(el => el?.id === button?.id) < 0 ? handleAddbutton(button) : handledeletebutton(button)}
                            >
                                <Grid item>
                                    {button?.icon}
                                </Grid>
                                <Grid item>
                                    <span>
                                        {t(button?.title)}
                                    </span>
                                </Grid>

                            </Button>
                        </Grid>
                    ))}
                </Grid>
            </div>
        </div>
    );

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby='simple-modal-title'
                aria-describedby='simple-modal-description'
            >
                {body}
            </Modal>
        </div>
    );
};
