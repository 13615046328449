import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import { useTableStyles } from '../../styles/table.style';
import { Button } from '@material-ui/core';
import { useSelector,useDispatch } from 'react-redux';
import { RetryRequest } from '../../store/rollback/rollback.action';
import {useTranslation} from "react-i18next";



export const  Outbox = () => {
  const tableClasses = useTableStyles();
  const dispatch = useDispatch();
  const {t} = useTranslation(["common"]);
  const requests = useSelector(state => state.offline);
  const rollbackItems = useSelector(state => state.Rollback.rollback);
  const HandleRetry = (action) => {
    console.log(action)
    dispatch (RetryRequest(action));
  }
  return (
    <Box className={tableClasses.paper} >
      <Table className={tableClasses.table} aria-label="simple table">
        <TableHead >
          <TableRow>
            <TableCell  className={tableClasses.tablecellHeader}align="left"> {t("common:date")} </TableCell>
            <TableCell  className={tableClasses.tablecellHeader} align="left">{t("common:objet")}</TableCell>
            <TableCell  className={tableClasses.tablecellHeader} align="left">{t("common:Description")}</TableCell>
            {requests.online && (<TableCell  className={tableClasses.tablecellHeader} align="left">{t("common:Action") }</TableCell>)}
          </TableRow>
        </TableHead>
        <TableBody>
          {requests.outbox.map((row,index) => (  
            <TableRow  className={tableClasses.tableRow} key={index}>
                <TableCell className={tableClasses.tableCell} align="left">10/09/2020</TableCell>
                <TableCell className={tableClasses.tableCell} align="left">{row.type}</TableCell>
                {row.type === "CREATE_ORDER" && (
                   <TableCell  className={tableClasses.tablecellHeader} align="left">{t("common:commandeAjouter")} {row.payload.customer}</TableCell>
                )}
                {row.type !== "CREATE_ORDER" && (
                   <TableCell  className={tableClasses.tablecellHeader} align="left">-</TableCell>
                )}
            </TableRow>
          ))}
          {requests.online && (
            rollbackItems.map((row,index) => ( 
              <TableRow  className={tableClasses.tableRow} key={index}>
                <TableCell className={tableClasses.tableCell} align="left">10/09/2020</TableCell>
                <TableCell className={tableClasses.tableCell} align="left">{row.meta.type}</TableCell>
                {row.meta.type === "CREATE_ORDER" && (
                   <TableCell  className={tableClasses.tablecellHeader} align="left">{t("common:commandeAjouter")}  {row.meta.payload.customer}</TableCell>
                )}
                {row.meta.type !== "CREATE_ORDER" && (
                   <TableCell  className={tableClasses.tablecellHeader} align="left">-</TableCell>
                )}
                <TableCell onClick={() => HandleRetry (row)} className={tableClasses.tableCell} align="left"><Button color="secondary" variant={"contained"}>{t("common:retry")}</Button></TableCell>
            </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </Box>
  );
}