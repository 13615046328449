import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import clsx from "clsx";
import { Products } from "../products";
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { statusFormat } from "../../../../helpers/utils";
import { useTableStyles } from "../../../../styles/table.style";

export const useStyles = makeStyles((theme) => ({
    card: {
        background: theme.palette.secondary.main,
        borderRadius: 10,
        border: "none",
    },
    cardBoldText: {
        color: theme.palette.primary.main,
        fontWeight: 600,
        fontSize: 14
    },
    cardTitle: {
        fontSize: 16
    },
    cardTitleBlock: {
        borderBottom: "1px solid #e7eaef",
        padding: "10px 20px 17px"
    },
    cardContentBlock: {
        padding: "1.4375rem 1.5rem 2.0625rem"
    },
    usernameBlock: {
        background: '#FFF',
        borderRadius: 19,
        padding: 14,
        marginBottom: 17
    },
    username: {
        color: "#2F334D",
        fontSize: 20,
        fontWeight: 600
    },
    textLight: {
        color: theme.palette.gray.main,
        fontSize: 12,
        fontWeight: 500
    },
    text: {
        fontSize: 14,
        fontWeight: 600
    },
    item: {
        padding: "0rem 1.625rem",
        marginBottom: "1.4375rem"
    },
    backLink: {
        fontSize: 12,
        color: '#2F334D',
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        marginTop: 14,
        marginLeft: 22
    },
    linkIcon: {
        height: 10,
        width: 8
    }
}));

export const DeliveryDocument = (props) => {
    const classes = useStyles();
    const tableClasses = useTableStyles();
    const { t } = useTranslation(["common"]);
    const globalDefaults = useSelector(state => state.Login.globalDefaults);

    return (
        <Box>
            {props.details && (
                <Card
                    className={clsx(classes.card, classes.cardBoldText)}
                    variant="outlined"
                >
                    <CardContent>
                        <Box display="flex">
                            <Grid item md={6}>
                                <Box className={classes.item}>
                                    <Typography color={'primary'} className={classes.textLight}>{t("common:email")}</Typography>
                                    <Typography className={classes.text}>{props.details.contact_email}</Typography>
                                </Box>
                                <Box className={classes.item}>
                                    <Typography color={'primary'} className={classes.textLight}>{t("common:user")}</Typography>
                                    <Typography className={classes.text}>{props.details.customer}</Typography>
                                </Box>
                                <Box className={classes.item}>
                                    <Typography color={'primary'} className={clsx(classes.textLight, tableClasses[statusFormat(props.details.status).color])}>{t("common:statut")}</Typography>
                                    <Typography className={classes.text}>{statusFormat(props.details.status).value}</Typography>
                                </Box>
                                <Box className={classes.item}>
                                    <Typography color={'primary'} className={classes.textLight}>{t("common:montantRemise")}</Typography>
                                    <Typography className={classes.text}>{props.details.discount_amount.toFixed(3)} {globalDefaults?.default_currency}</Typography>
                                </Box>
                            </Grid>
                            <Grid item md={6}>
                                <Box className={classes.item}>
                                    <Typography color={'primary'} className={classes.textLight}>{t("common:date")}</Typography>
                                    <Typography className={classes.text}>{props.details.posting_date}</Typography>
                                </Box>
                                <Box className={classes.item}>
                                    <Typography color={'primary'} className={classes.textLight}>{t("common:total")}</Typography>
                                    <Typography className={classes.text}>{props.details.grand_total.toFixed(3)} {globalDefaults?.default_currency}</Typography>
                                </Box>
                                <Box className={classes.item}>
                                    <Typography color={'primary'} className={classes.textLight}>{t("common:company")}</Typography>
                                    <Typography className={classes.text}>{props.details.company}</Typography>
                                </Box>
                                <Box className={classes.item}>
                                    <Typography color={'primary'} className={classes.textLight}>{t("common:poucentageRemise")}</Typography>
                                    <Typography className={classes.text}>{props.details.additional_discount_percentage} %</Typography>
                                </Box>
                            </Grid>
                        </Box>
                        <Box>
                            <Products isOrderDetail={true} orders={props.details.items} />
                        </Box>
                    </CardContent>
                </Card>
            )}
        </Box>
    );
};
