import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Grid,
  Modal,
  Typography,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { LoadWarehouseCibleByName } from "../../../../store/transferRequest/transferRequest.action";
import { Loader } from "../../../../components/loader";
import { useTranslation } from "react-i18next";
import { Header } from "../../../stockEntry/components/header";
import { SimpleTable } from "../../../../components/simpleTable";
import {
  CreatePurchaseReceiptRequest,
  LoadPurchaseOrderById,
  LoadPurchaseOrderPackageById,
  SetPurchaseInvoiceMappedDoc,
  SetpackagesWithItemsDbErpData,
  createPurchaseInvoiceDocFromPO,
  createPurchaseInvoiceRequests,
} from "../../../../store/buying/buying.action";
import { CardDetails } from "./cardDetails";
import {
  AccordionDetails,
  Accordion,
  AccordionSummary,
} from "../../../../components/accordionComponent";
import { BasicTable } from "../../../checkout/components/basictable";
import { GlobalfilterData } from "../../../../helpers/helper";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import EditIcon from "@material-ui/icons/Edit";
import { useHistory } from "react-router-dom";
import { PurchaseRecDetails } from "../purchaseInvoiceTable/purchaseRecDetails";
import DeleteIcon from "@material-ui/icons/Delete";
import AlertModal from "../../../../components/common/alertModal";
import { set } from "date-fns";
import axiosInstance from "../../../../history/axiosInstance";
import { SetNotification } from "../../../../store/notification/notification.action";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 27,
  },
  accordion: {
    background: theme.palette.secondary.main,
  },
  fullWidthAccordionDetails: {
    width: "100%",
  },
  addButton: {
    width: 28,
    height: 25,
    background: theme.palette.secondary.main,
    borderRadius: 5,
    fontSize: 21,
    paddingTop: 2,
    marginLeft: 8,
  },
  paper: {
    position: "absolute",
    width: 600,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 10,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  },
  title: {
    fontWeight: 700,
    fontSize: 20,
  },
  number: {
    color: theme.palette.blue.main,
    marginLeft: "0.5rem",
    marginRight: "0.5rem",
  },
  formControl: {
    marginBottom: 15,
    "& .MuiInputBase-root": {
      background: theme.palette.white.main,
    },
    "& .MuiOutlinedInput-input": {
      padding: "8.9px 14px",
      fontSize: 12,
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  button: {
    background: theme.palette.productsTable.background,
  },
}));

export const PurchaseOrderDetails = () => {
  const classes = useStyles();
  const { id } = useParams();
  const { t } = useTranslation(["transfert", "common", "buying"]);
  const dispatch = useDispatch();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [suggestion, setsuggestion] = useState([]);
  const [searchProduct, setSearchProduct] = useState("");
  const [initialLines, setInitialLines] = useState([0, 1, 2, 3]);
  const [packagesWithItems, setPackagesWithItems] = useState([]);
  const [doc, setDoc] = useState([]);
  const [mappedDoc, setMappedDoc] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false);
  const products = useSelector((state) => state.Product.productsPos);

  const [expanded, setExpanded] = useState("");
  const [expandedModal, setExpandedModal] = useState("");
  const [selectedAccordions, setSelectedAccordions] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [stateDelete, setStateDelete] = useState({
    open: false,
    row: null,
  });
  const handleCloseAlert = () => {
    setStateDelete({ open: false, row: null });
  };
  const purchaseOrderDetails = useSelector(
    (state) => state.Buying.purchaseOrderDetails
  );
  const purchaseReceivedMaterial = useSelector(
    (state) => state.Buying.purchaseReceivedMaterial
  );

  const selectedPurchaseOrderPackage = useSelector(
    (state) => state.Buying.selectedPurchaseOrderPackage
  );
  const notification = useSelector((state) => state.Notification.notification);

  const header = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
    Authorization: JSON.parse(localStorage.getItem("user"))?.token,
  };

  const purchaseOrderDetailsColumns = [
    { key: "item_code", title: "common:refArticle" },
    { key: "item_name", title: "common:nom" },
    { key: "qty", title: "transfert:qtyDemandé" },
    { key: "received_qty", title: "transfert:qtyRecu" },
    { key: "warehouse", title: "common:mgsCible" },
  ];
  const purchasePackageDetailsColumns = [
    { key: "item_code", title: "common:refArticle" },
    { key: "item_name", title: "common:nom" },
    { key: "maxQty", title: "transfert:qtyDemandé" },
    { key: "acceptedQty", title: "transfert:qtyRecu" },
    { key: "warehouse", title: "common:mgsCible" },
  ];

  const editpackage = (purchaseorder_id, package_id) => {
    dispatch(SetpackagesWithItemsDbErpData(packagesWithItems));
    history.push(
      `/purchase-package/${purchaseorder_id}?package_id=${package_id}`
    );
  };
  const HandleDeletepackage = () => {
    // id
    axiosInstance
      .post(
        `${process.env.REACT_APP_API_URI}/api/purchase-package-delete/${stateDelete?.row?.id}`,
        {},
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
            Authorization: JSON.parse(localStorage.getItem("user")).token,
          },
        }
      )
      .then((resp) => {
        if (resp?.data?.message == "deleted") {
          dispatch(
            SetNotification({
              code: "success",
              message: "notification:successfulSuppression",
            })
          );
        } else {
          dispatch(
            SetNotification({
              code: "error",
              message: "notification:suppressionFailed",
            })
          );
        }
        initialisation();
      });
  };
  const handleSearchProduct = (e) => {
    setSearchProduct(e.target.value);
    if (e.target.value == "") {
      setsuggestion([]);
      return;
    }
    const data = GlobalfilterData(
      products?.filter((p) => p?.is_stock_item == 1),
      e.target.value,
      ["item_code", "item_name", "description", "price_list_rate"]
    );
    setsuggestion(data);
  };

  const handleClose = () => {
    setOpen(false);
    setMappedDoc(null);
    setSelectedProducts([]);
    setSelectedAccordions([]);
    initialisation();
  };
  const initialisation_page = () => {
    setOpen(false);
    setLoading(true);
    setIsLoading(true);
    setsuggestion([]);
    setSearchProduct("");
    setPackagesWithItems([]);
    setDoc([]);
    setMappedDoc(null);
    setIsSubmit(false);
    setExpanded("");
    setExpandedModal("");
    setSelectedAccordions([]);
    setSelectedProducts([]);
    setStateDelete({
      open: false,
      row: null,
    });
    setOpenDetailsModal(false);
  };
  const initialisation = async () => {
    await dispatch(LoadPurchaseOrderById(id));
    await dispatch(LoadPurchaseOrderPackageById(id));
    if (purchaseOrderDetails?.set_warehouse) {
      await dispatch(
        purchaseOrderDetails?.set_warehouse &&
          LoadWarehouseCibleByName(purchaseOrderDetails?.set_warehouse)
      );
    }
    setLoading(false);
  };

  const mergeObjects = (target, source) => {
    for (const key in source) {
      if (source.hasOwnProperty(key) && !target.hasOwnProperty(key)) {
        target[key] = source[key];
      }
    }
    return target;
  };

  const mapPackagesWithItems = (selectedPackages, receivedMaterial) => {
    return selectedPackages.map((pack) => {
      try {
        const itemsInPackage = JSON.parse(pack.items);
        const itemsWithCorrespondingDetails = itemsInPackage.map(
          (itemInPackage) => {
            const correspondingItem = receivedMaterial.find(
              (item) => item.item_code === itemInPackage.item_code
            );
            return mergeObjects({ ...itemInPackage }, correspondingItem);
          }
        );
        itemsWithCorrespondingDetails?.forEach(function (obj) {
          if (!obj?.acceptedQty) {
            obj.acceptedQty = 0;
          }
          obj.maxQty = obj?.qty;
        });
        return {
          ...pack,
          items: itemsWithCorrespondingDetails,
        };
      } catch (error) {
        return pack;
      }
    });
  };

  const makeMappedDoc = () => {
    fetch(`${process.env.REACT_APP_API_URI}/api/make-mapped-doc`, {
      method: "POST",
      headers: header,
      body: JSON.stringify({
        method:
        
          "erpnext.buying.doctype.purchase_order.purchase_order.make_purchase_receipt",
        source_name: id,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setDoc(data?.message?.items);
        selectedPurchaseOrderPackage && selectedPurchaseOrderPackage?.length > 0
          ? setMappedDoc({
              ...data?.message,
              items: [],
            })
          : setMappedDoc({
              ...data?.message,
            });
        setOpen(true);
      });
  };

  useEffect(async () => {
    if (selectedPurchaseOrderPackage && purchaseReceivedMaterial) {
      const updatedPackagesWithItems = await mapPackagesWithItems(
        selectedPurchaseOrderPackage,
        purchaseReceivedMaterial
      );
      setPackagesWithItems(updatedPackagesWithItems);
    }

    setIsLoading(false);
    setInitialLines(packagesWithItems?.length);
  }, [selectedPurchaseOrderPackage, purchaseReceivedMaterial]);

  const handleAccordionSelect = (index) => {
    if (selectedAccordions.includes(index)) {
      setSelectedAccordions([]);
    } else {
      setSelectedAccordions([index]);
    }
  };

  const handleUpdateMappedDoc = (data) => {
    let packagedb = selectedPurchaseOrderPackage?.find(
      (e) => e?.id == packagesWithItems[selectedAccordions[0]]?.id
    );
    data?.forEach(function (obj) {
      let it = JSON.parse(packagedb?.items)?.find(
        (e) => e?.item_code == obj?.item_code
      );
      obj.qty = it?.acceptedQty
        ? parseInt(obj?.maxQty) - parseInt(it?.acceptedQty)
        : obj?.qty;
    });
    setMappedDoc({
      ...mappedDoc,
      items: data.filter((e) => e?.qty > 0),
    });
  };

  useEffect(() => {
    const updatedSelectedProducts = packagesWithItems
      ?.filter((_, index) => selectedAccordions?.includes(index))
      ?.flatMap((p) => p.items);
    setSelectedProducts(updatedSelectedProducts);
    handleUpdateMappedDoc(updatedSelectedProducts);
  }, [selectedAccordions]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded((prevExpanded) => {
      if (isExpanded) {
        return [...prevExpanded, panel];
      } else {
        return prevExpanded.filter((item) => item !== panel);
      }
    });
  };
  const handleChangeModal = (panel) => (event, isExpanded) => {
    setExpandedModal((prevExpanded) => {
      if (isExpanded) {
        return [...prevExpanded, panel];
      } else {
        return prevExpanded.filter((item) => item !== panel);
      }
    });
  };

  const handleAddproduct = (id) => {
    var data = suggestion.find((s) => s.item_name == id);
    const ItemIndex = mappedDoc.items.findIndex((s) => s.item_name == id);
    if (ItemIndex != -1) {
      mappedDoc.items[ItemIndex].qty = mappedDoc.items[ItemIndex].qty + 1;
      mappedDoc.items[ItemIndex].stock_qty =
        mappedDoc.items[ItemIndex].stock_qty + 1;
    } else {
      data.qty = 1;
      data.stock_qty = 1;
      mappedDoc.items.push(data);
      setMappedDoc({
        ...mappedDoc,
        items: mappedDoc.items,
      });
      setInitialLines(mappedDoc?.items?.length);
    }
    setsuggestion([]);
    setSearchProduct("");
  };

  const HandleQuantityChange = (onAdd, id) => {
    const ItemIndex = mappedDoc.items?.findIndex((s) => s.item_code == id);
    const data = [...mappedDoc.items];
    if (ItemIndex != -1) {
      data[ItemIndex].qty =
        onAdd &&
        parseInt(data[ItemIndex].qty) <
          parseInt(data[ItemIndex]?.maxQty) -
            parseInt(data[ItemIndex]?.acceptedQty)
          ? parseInt(data[ItemIndex].qty) + 1
          : parseInt(data[ItemIndex].qty) > 1 && !onAdd
          ? parseInt(data[ItemIndex].qty) - 1
          : data[ItemIndex].qty;
      data[ItemIndex].stock_qty = data[ItemIndex].qty;
      setMappedDoc({
        ...mappedDoc,
        items: [...data],
      });
    }
  };

  const HandleDelete = (id) => {
    const data = mappedDoc?.items?.filter((s) => s.item_code !== id);
    setMappedDoc({
      ...mappedDoc,
      items: [...data],
    });
    setInitialLines(mappedDoc?.items?.length);
  };
  const HandleUpdateQuantity = (e, id) => {
    const ItemIndex = mappedDoc.items.findIndex((s) => s.item_code == id);
    const data = [...mappedDoc.items];
    if (ItemIndex != -1 && !isNaN(e.target.value)) {
      if (
        parseInt(data[ItemIndex]?.maxQty) -
          parseInt(data[ItemIndex]?.acceptedQty) >=
        parseInt(e.target.value)
      ) {
        data[ItemIndex].qty = parseInt(e.target.value);
        data[ItemIndex].stock_qty = parseInt(e.target.value);
        setMappedDoc({
          ...mappedDoc,
          items: [...data],
        });
      } else {
        //msg err qty
      }
    }
  };
  const handleSubmit = async (event) => {
    var isvalid = true;

    mappedDoc?.items?.forEach((element) => {
      if (element?.qty == 0) {
        isvalid = false;
      }
    });
    if (isvalid) {
      let selectItems = [];
      let reqpackage = null;
      selectedProducts?.forEach((mappedItem) => {
        selectedPurchaseOrderPackage?.forEach((packageData) => {
          const packageItems = JSON.parse(packageData.items);
          const matchingPackageItem = packageItems?.find(
            (packageItem) =>
              packageItem?.item_code + "" === mappedItem?.item_code + ""
          );
          if (
            matchingPackageItem &&
            packagesWithItems[selectedAccordions[0]]?.id === packageData?.id
          ) {
            let it = JSON.parse(packageData?.items)?.find(
              (e) => e?.item_code == matchingPackageItem?.item_code
            );
            selectItems.push({
              item_code: matchingPackageItem?.item_code,
              item_name: matchingPackageItem?.item_name,
              qty: matchingPackageItem?.qty,
              acceptedQty: it?.acceptedQty
                ? parseInt(mappedItem?.qty) + parseInt(it?.acceptedQty)
                : mappedItem?.qty,
            });

            if (
              packagesWithItems[selectedAccordions[0]]?.id === packageData?.id
            ) {
              let itemCompleted = 0;
              selectItems.forEach((element) => {
                if (element.qty + "" == element.acceptedQty + "") {
                  itemCompleted++;
                }
              });
              reqpackage = {
                id: packageData?.id,
                items: JSON.stringify(selectItems),
                status:
                  itemCompleted == selectItems?.length
                    ? "Completed"
                    : packageData?.status,
                purchasereceipt_ids:
                  packagesWithItems[selectedAccordions[0]]?.purchasereceipt_ids,
              };
            }
          }
        });
      });
      let itemdoc = [];
      mappedDoc?.items?.forEach((element) => {
        let iteminitial = doc.find((e) => e.item_code == element.item_code);
        if (iteminitial) {
          iteminitial.qty = element.qty;
          itemdoc.push(iteminitial);
        }
      });
      let mappDoc = {
        ...mappedDoc,
        items: itemdoc,
      };
      mappDoc?.items?.forEach(function (obj) {
        obj.purchase_order = id;
      });
      let updateMappDoc = {
        ...mappDoc,
        is_subcontracted: 0,
      };
      let updateMappedDoc = {
        ...mappedDoc,
        is_subcontracted: 0,
      };
      selectedPurchaseOrderPackage.length != 0 ||
      (packagesWithItems.length != 0 && mappDoc?.items?.length > 0)
        ? await dispatch(
            CreatePurchaseReceiptRequest(updateMappDoc, id, reqpackage)
          )
        : await dispatch(CreatePurchaseReceiptRequest(updateMappedDoc, id));
      setOpen(false);
    }
  };

  useEffect(() => {
    initialisation();
  }, []);

  const puchaseInvoiceDoc = useSelector(
    (state) => state.Buying.puchaseInvoiceDoc
  );
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const handlePurInvoiceDetails = (id) => {
    setOpenDetailsModal(true);
    dispatch(createPurchaseInvoiceDocFromPO(id));
  };
  const handleCloseModal = () => {
    setOpenDetailsModal(false);
    dispatch(SetPurchaseInvoiceMappedDoc(null));
  };
  const handleCreatePurInvoice = () => {
    setIsSubmit(true);
    let puchaseInvoiceDocMapped = {
      ...puchaseInvoiceDoc,
      is_subcontracted: 0,
    };
    dispatch(createPurchaseInvoiceRequests(puchaseInvoiceDocMapped));
    handleCloseModal();
  };
  useEffect(() => {
    if (notification) {
      if (
        notification?.message === "notification:successCreatedPurchaseInvoice"
      ) {
        window.location.href = "/buying?tab=5";
      }
      if (notification?.code !== "success") {
        setIsSubmit(false);
      }
      if (notification?.code == "success") {
        initialisation_page();
        initialisation();
      }
    }
  }, [notification]);
  if (isSubmit) return <Loader />;
  return (
    <Box>
      <Header goBack={"/wms"} />

      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        flexWrap="wrap"
      >
        <Box display="flex" flexWrap="wrap" alignItems="center">
          <Box ml={1} mt={3}>
            {purchaseOrderDetails?.status &&
              (purchaseOrderDetails?.status != "To Bill" ? (
                <Button
                  variant="contained"
                  color={"primary"}
                  disabled={
                    purchaseOrderDetails?.status != "To Receive and Bill"
                  }
                  onClick={() => makeMappedDoc()}
                >
                  {t("common:stock_receiving")}
                </Button>
              ) : (
                <Button
                  disabled={purchaseOrderDetails?.status != "To Bill"}
                  size={"small"}
                  variant="outlined"
                  color="primary"
                  onClick={() =>
                    handlePurInvoiceDetails(purchaseOrderDetails?.name)
                  }
                >
                  {t("buying:CreateFactAchat")}
                </Button>
              ))}
          </Box>
        </Box>
      </Box>
      {isLoading || loading ? (
        <Loader />
      ) : (
        <Grid
          container
          direction="row"
          spacing={2}
          className={classes.root}
          display="flex"
          style={{ marginTop: 6 }}
        >
          <Grid item xs={12} md={4}>
            <CardDetails
              details={purchaseOrderDetails}
              schedule_date={purchaseOrderDetails?.schedule_date}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            {packagesWithItems.length != 0 ? (
              Array.isArray(packagesWithItems) &&
              packagesWithItems?.map((p, index) => (
                <Accordion
                  key={index}
                  square
                  expanded={expanded.includes(`panel${index}`)}
                  onChange={handleChange(`panel${index}`)}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    className={classes.accordion}
                    aria-controls={`panel${index}-content`}
                    id={`panel${index}-header`}
                  >
                    <Typography className={classes.heading}>
                      Colis {index + 1}
                    </Typography>
                    <Typography className={classes.secondaryHeading}>
                      <strong>{t("transfert:schedule_date")} :</strong>
                      {p?.scheduledate}
                    </Typography>
                  </AccordionSummary>

                  <AccordionDetails>
                    <Grid container alignItems="center" spacing={1}>
                      {(!p?.status ||
                        p?.status?.toLowerCase() != "completed") && (
                        <Grid item style={{ width: "100%" }}>
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-end"
                            flexWrap="wrap"
                          >
                            <Box
                              display="flex"
                              flexWrap="wrap"
                              alignItems="center"
                            >
                              <Box ml={1}>
                                <IconButton
                                  size={"small"}
                                  variant="contained"
                                  style={{
                                    backgroundColor: "#2F4B7C",
                                    color: "white",
                                  }}
                                  onClick={() =>
                                    editpackage(p?.purchaseorder_id, p?.id)
                                  }
                                >
                                  <EditIcon />
                                </IconButton>
                              </Box>
                              {p?.items?.filter((e) => e?.acceptedQty != 0)
                                ?.length == 0 && (
                                <Box ml={2}>
                                  <IconButton
                                    disabled={
                                      p?.items?.filter(
                                        (e) => e?.acceptedQty != 0
                                      )?.length > 0
                                    }
                                    size={"small"}
                                    variant="contained"
                                    style={{
                                      opacity:
                                        p?.items?.filter(
                                          (e) => e?.acceptedQty != 0
                                        )?.length > 0
                                          ? 0.5
                                          : 1,
                                      backgroundColor: "red",
                                      color: "white",
                                    }}
                                    onClick={() =>
                                      setStateDelete({ open: true, row: p })
                                    }
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Box>
                              )}
                            </Box>
                          </Box>
                        </Grid>
                      )}
                      <Grid item style={{ width: "100%" }}>
                        <SimpleTable
                          title={"Purchase Package Details"}
                          data={p?.items}
                          columns={purchasePackageDetailsColumns}
                          hasPaper={true}
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              ))
            ) : (
              <SimpleTable
                title={"Purchase Order Details"}
                data={purchaseReceivedMaterial}
                columns={purchaseOrderDetailsColumns}
                hasPaper={true}
              />
            )}
          </Grid>
        </Grid>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle align={"center"}>
          <Typography
            className={classes.title}
            align={"center"}
            color={"primary"}
          >
            {t("transfert:recepStock")}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="scroll-dialog-description">
            <Box align={"left"}>
              {packagesWithItems.length != 0 ? (
                Array.isArray(packagesWithItems) &&
                packagesWithItems?.map((p, index) => (
                  <Accordion
                    key={index}
                    square
                    expanded={expandedModal.includes(`panel${index}`)}
                    onChange={handleChangeModal(`panel${index}`)}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel${index}-content`}
                      id={`panel${index}-header`}
                    >
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          alignContent: "center",
                          justifyContent: "flex-start",
                          gap: 20,
                        }}
                      >
                        <Button
                          disabled={p?.status?.toLowerCase() == "completed"}
                          variant="outlined"
                          onClick={(event) => {
                            event.stopPropagation();
                            handleAccordionSelect(index);
                          }}
                          color="primary"
                          className={
                            selectedAccordions.includes(index)
                              ? classes.button
                              : ""
                          }
                          startIcon={
                            selectedAccordions.includes(index) ? (
                              <CheckCircleOutlineIcon />
                            ) : (
                              <HighlightOffIcon />
                            )
                          }
                        >
                          {selectedAccordions.includes(index)
                            ? "Select"
                            : "Deselect"}
                        </Button>
                        <span style={{ marginTop: 5 }}>Colis {index + 1}</span>
                        <span style={{ marginTop: 5 }}>
                          <strong>{t("transfert:schedule_date")} :</strong>
                          {p?.scheduledate}
                        </span>
                      </div>
                    </AccordionSummary>

                    <AccordionDetails>
                      <SimpleTable
                        title={"Purchase Package Details"}
                        data={p?.items}
                        columns={purchasePackageDetailsColumns}
                        hasPaper={true}
                      />
                    </AccordionDetails>
                  </Accordion>
                ))
              ) : (
                <SimpleTable
                  title={"Purchase Order Details"}
                  data={purchaseReceivedMaterial}
                  columns={purchaseOrderDetailsColumns}
                  hasPaper={true}
                />
              )}
            </Box>
          </DialogContentText>
          <Typography
            className={classes.blockTitle}
            color={"primary"}
            variant="caption"
            display="block"
          >
            {t("common:article")} *
          </Typography>
          <div data-grid={{ w: 9, h: 3, x: 0, y: 0, minW: 4, minH: 3 }}>
            {purchaseReceivedMaterial && (
              <BasicTable
                suggestion={suggestion}
                initialLines={initialLines}
                rows={mappedDoc?.items}
                HandleQuantityChange={HandleQuantityChange}
                HandleDelete={HandleDelete}
                handleSearchProduct={handleSearchProduct}
                handleAddproduct={handleAddproduct}
                searchProduct={searchProduct}
                transfer={true}
                stockEntry={true}
                HandleUpdateQuantity={HandleUpdateQuantity}
                isPack={
                  selectedPurchaseOrderPackage.length != 0 &&
                  packagesWithItems.length != 0 &&
                  true
                }
              />
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t("common:annuler")}
          </Button>
          <Button
            disabled={mappedDoc?.items?.length < 1}
            color="primary"
            variant="contained"
            onClick={() => handleSubmit()}
          >
            {t("common:confirmer")}
          </Button>
        </DialogActions>
      </Dialog>
      <Modal
        open={openDetailsModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {puchaseInvoiceDoc ? (
          <PurchaseRecDetails
            puchaseInvoiceDoc={puchaseInvoiceDoc}
            handleCreatePurInvoice={handleCreatePurInvoice}
            handleCloseModal={handleCloseModal}
          />
        ) : (
          <div className={classes.paper}>
            <Loader />
          </div>
        )}
      </Modal>
      <AlertModal
        setOpenModal={handleCloseAlert}
        openModal={stateDelete.open}
        data={stateDelete.row}
        HandleDelete={HandleDeletepackage}
        handleCancel={handleCloseAlert}
        title={t("common:areYouSureYouWantToRemoveThisElement")}
      />
    </Box>
  );
};
