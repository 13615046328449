import React, { useEffect, useState } from "react";
import PersonIcon from "@material-ui/icons/Person";
import LocalGroceryStoreIcon from "@material-ui/icons/LocalGroceryStore";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import clsx from "clsx";
import { useStyles } from "../../style";
import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
} from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import {
  LoadFullfilmentsOrderById,
  SetFullfilmentsOrder,
  UpdateOrderStatus,
} from "../../../../store/fullfilment/fullfilment.action";
import { useDispatch, useSelector } from "react-redux";
import { formatDate, statusFormat } from "../../../../helpers/utils";
import { CreateStatusEmail } from "../../../../store/fullfilment/fullfilment.action";
import validator from "validator";
import { useTranslation } from "react-i18next";
import { GetOrderdProducts } from "../../../../helpers/helper";
import { useTableStyles } from "../../../../styles/table.style";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { Cancellation } from "./cancellation";
import { Loader } from "../../../../components/loader";
import useGetDoc from "../../../../hooks/useGetDoc";
import { fr } from "date-fns/locale";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { Invoicing } from "../products/Invoicing";
import { SetNotification } from "../../../../store/notification/notification.action";
import InfoIcon from "@material-ui/icons/Info";

export const FulfillementDetails = () => {
  const classes = useStyles();
  const tableClasses = useTableStyles();
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();
  const order = useSelector((state) => state.Fullfilment.order);
  const user = useSelector((state) => state.Login.user);
  const { t } = useTranslation(["order", "common", "fulfillment"]);
  const products = useSelector((state) => state.Product.productsPos);
  const [orderdProducts, setOrderdProducts] = useState([]);
  const [openModalCancellation, setOpenModalCancellation] = useState(false);
  const warehouses = useSelector((state) => state.Login.stores);

  const [loadingButton, setLoadingButton] = useState(false);

  const [openInputs, setOpenInputs] = useState(false);

  const {
    data: doc,
    isPending,
    refetch,
  } = useGetDoc(
    `${process.env.REACT_APP_API_URI}/api/details?doctype=Sales%20Order&name=${
      order ? order.order_id : null
    }`
  );

  const renderAddr = (address) => {
    if (!address) {
      return "-";
    } else if (address.includes("Email")) {
      return address.slice(0, address.indexOf("Email")).replace(/<[^>]*>/g, "");
    }
    return address?.replace(/<[^>]*>/g, "");
  };

  const [orderState, setOrderState] = useState({
    delivery_date: "",
    set_warehouse: "",
    items: [],
  });

  const handleChange = (e) => {
    if (order?.status !== "canceled") {
      setOrderState({
        ...orderState,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleDateChange = (date, event) => {
    if (!isNaN(Date.parse(date)) && order?.status !== "canceled") {
      setOrderState({
        ...orderState,
        delivery_date: date.toISOString().slice(0, 10),
      });
    }
  };

  const saveDoc = (status) => {
    setLoadingButton(true);
    let daItems = [];
    orderState.items.forEach((el) => {
      daItems.push({ ...el, delivery_date: orderState.delivery_date });
    });
    const data = {
      doc: {
        ...orderState,
        creation: doc.creation,
        modified: doc.modified,
        owner: doc.owner,
        modified_by: doc.owner,
        name: doc.name,
        items: daItems,
        naming_series: doc?.naming_series,
        doctype: "Sales Order",
        __unsaved: 1,
        docstatus: 0,
        status: "Draft",
        order_type: "Sales",
      },
      action: "Submit",
    };
    fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem("user"))?.token,
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        setLoadingButton(false);
        if (!res.ok) {
          dispatch(
            SetNotification({ code: "error", message: "Something went wrong" })
          );
        }
        return res.json();
      })
      .then((data) => {
        if (data?.success === false) {
          dispatch(
            SetNotification({ code: "warning", message: data?.message })
          );
        } else {
          dispatch(
            SetNotification({
              code: "success",
              message: `The order has been successfully saved.`,
            })
          );
        }

        refetch({});

        if (data?.docs?.[0]) {
          dispatch(
            UpdateOrderStatus({
              status,
              id,
              redirectToTab: 1,
              grand_total: data?.docs?.[0]?.grand_total,
              additional_discount_percentage:
                data?.docs?.[0]?.additional_discount_percentage,
              discount_amount: data?.docs?.[0]?.discount_amount,
              delivery_date: data?.docs?.[0]?.delivery_date,
              products: formatItems(data?.docs?.[0]?.items),
              point_of_sales: data?.docs?.[0]?.set_warehouse,
              total_amount: data?.docs?.[0]?.net_total,
            })
          );

          if (validator.isEmail(order.customer_email)) {
            const obj = {
              additional_discount_percentage:
                data?.docs?.[0]?.additional_discount_percentage,
              age: order.age,
              company: order.company,
              created_at: order.created_at,
              customer_email: order.customer_email,
              customer_name: order.customer_name,
              delivery_date: data?.docs?.[0]?.delivery_date,
              discount_amount: data?.docs?.[0]?.discount_amount,
              grand_total: data?.docs?.[0]?.grand_total,
              id: order.id,
              order_id: order.order_id,
              packs: order?.packs,
              point_of_sales: data?.docs?.[0]?.set_warehouse,
              products: formatItems(data?.docs?.[0]?.items),
              recipient: order.recipient,
              sales_date: order.sales_date,
              status: status,
              total_amount: data?.docs?.[0]?.net_total,
              updated_at: order.updated_at,
              delivery_address: order.delivery_address,
            };
            dispatch(CreateStatusEmail(obj.customer_email, obj));
          }
        }
      });
  };

  function formatItems(items) {
    if (!Array.isArray(items)) {
      return "[]";
    }
    let result = [];
    items?.forEach((el) => {
      result.push({ item_code: el?.item_code, quantity: el?.qty });
    });
    return JSON.stringify(result);
  }

  useEffect(() => {
    dispatch(LoadFullfilmentsOrderById(id));
  }, [id]);

  useEffect(() => {
    if (doc) {
      setOrderState({
        ...doc,
        delivery_date: doc?.delivery_date,
        set_warehouse: doc?.set_warehouse ? doc?.set_warehouse : "",
      });
    }
  }, [doc]);

  useEffect(() => {
    setOrderdProducts(
      order && order.products
        ? GetOrderdProducts([...products], order.products)
        : []
    );
  }, [order]);

  const Redirect = () => {
    dispatch(SetFullfilmentsOrder(null));
    if (user?.role.toLowerCase() == "cashier") {
      return history.push(`/checkout`);
    } else {
      if (order && order.status == "to verify") {
        return history.push(`/fulfillement?tab=0`);
      }
      if (order && order.status == "packed") {
        return history.push(`/fulfillement?tab=4`);
      }
      return history.push(`/fulfillement?tab=0`);
    }
  };

  const checkIsOrderReady = (orderdProducts) => {
    const itemIndex = orderdProducts.findIndex((el, i) => !el.actual_qty);
    if (itemIndex == -1) return true;
    return false;
  };

  if (isPending) return <Loader />;

  return (
    <Box className={classes.block}>
      <Box className={classes.titleblock}>
        <span
          onClick={() => Redirect()}
          className={clsx(classes.closeicon, "icon-close")}
        />
        <Typography
          align={"center"}
          className={classes.title}
          color={"primary"}
          variant="h5"
        >
          {t("common:order")} {order && order.order_id}
        </Typography>
      </Box>
      <Grid container spacing={2} className={classes.container}>
        <Grid item xs={12} md={4}>
          <Box className={clsx(classes.root, tableClasses.paper)}>
            <Typography className={classes.cardTitle}>
              <PersonIcon /> {t("fulfillment:custInfo")}
            </Typography>
            <div className={classes.item}>
              <Typography color={"primary"} className={classes.textLight}>
                {t("common:name")}:
              </Typography>
              <Typography className={classes.text}>{doc?.customer}</Typography>
            </div>
            <div className={classes.item}>
              <Typography color={"primary"} className={classes.textLight}>
                {t("common:email")}:
              </Typography>
              <Typography className={classes.text}>
                {doc?.contact_email ?? "-"}
              </Typography>
            </div>
            <div className={classes.item}>
              <Typography color={"primary"} className={classes.textLight}>
                {t("common:phone")}:
              </Typography>
              <Typography className={classes.text}>
                {doc?.contact_mobile ?? "-"}
              </Typography>
            </div>
            <div className={classes.item}>
              <Typography color={"primary"} className={classes.textLight}>
                {t("fulfillment:delAddr")}:
              </Typography>
              <Typography className={classes.text}>
                {renderAddr(doc?.shipping_address)}
              </Typography>
            </div>

            <Typography className={classes.cardTitle}>
              <LocalGroceryStoreIcon /> {t("fulfillment:OrderInfo")}
            </Typography>

            <div className={classes.item}>
              <Typography color={"primary"} className={classes.textLight}>
                {t("fulfillment:saleDate")}
              </Typography>
              <Typography className={classes.text}>
                {formatDate(doc?.transaction_date, "DD/MM/YYYY")}
              </Typography>
            </div>
            <div className={classes.item}>
              <Typography color={"primary"} className={classes.textLight}>
                Net Total
              </Typography>
              <Typography className={classes.text}>
                {parseFloat(doc?.net_total)?.toFixed(3)} {doc?.currency}
              </Typography>
            </div>
            <div className={classes.item}>
              <Typography color={"primary"} className={classes.textLight}>
                Taxes
              </Typography>
              <Typography className={classes.text}>
                {parseFloat(doc?.total_taxes_and_charges)?.toFixed(3)}{" "}
                {doc?.currency}
              </Typography>
            </div>
            <div className={classes.item}>
              <Typography color={"primary"} className={classes.textLight}>
                {t("common:grandTotal")}
              </Typography>
              <Typography className={classes.text}>
                {parseFloat(doc?.grand_total)?.toFixed(3)} {doc?.currency}
              </Typography>
            </div>
            <div className={classes.item}>
              <Typography
                color={"primary"}
                className={clsx(
                  classes.textLight,
                  tableClasses[statusFormat(order?.status).color]
                )}
              >
                {t("common:statut")}
              </Typography>
              <Typography className={classes.text}>
                {order && statusFormat(order?.status).value}
              </Typography>
            </div>
            <div className={classes.item}>
              <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
                <InputLabel className={classes.textLight}>
                  {t("fulfillment:delDate")}
                </InputLabel>
                <KeyboardDatePicker
                  disablePast={true}
                  disabled={order?.status !== "to verify"}
                  style={{ marginTop: 10 }}
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  name="delivery_date"
                  inputVariant="outlined"
                  id="date-picker-inline"
                  value={orderState.delivery_date}
                  onChange={
                    order?.status == "to verify" ? handleDateChange : () => {}
                  }
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  minDate={
                    order?.status == "to verify"
                      ? formatDate(new Date(), "YYYY-MM-DD")
                      : undefined
                  }
                  minDateMessage={
                    order?.status == "to verify"
                      ? t("common:minDateMessage")
                      : undefined
                  }
                />
              </MuiPickersUtilsProvider>
            </div>
            <div className={classes.item}>
              <InputLabel
                id="demo-simple-select-outlined-label"
                className={classes.textLight}
              >
                {t("fulfillment:sellFrom")}
              </InputLabel>
              <FormControl
                disabled={order?.status !== "to verify"}
                variant="outlined"
              >
                <Select
                  style={{ margin: "10px 0px" }}
                  error={
                    orderState.set_warehouse === "" || !orderState.set_warehouse
                  }
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={orderState.set_warehouse}
                  name="set_warehouse"
                  IconComponent={ExpandMore}
                  onChange={
                    order?.status == "to verify"
                      ? (e) => handleChange(e)
                      : () => {}
                  }
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  <MenuItem value={""}></MenuItem>
                  {warehouses &&
                    warehouses.map((row, index) => (
                      <MenuItem value={row.name} key={row.name}>
                        {row.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <Invoicing
            orderState={orderState}
            setOrderState={setOrderState}
            doc={doc}
            openInputs={openInputs}
            setOpenInputs={setOpenInputs}
            order={order}
          />
          {order &&
            (order?.status == "canceled" || order?.status == "cancel") && (
              <Box mt={2}>
                <Typography
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 5,
                    fontWeight: 600,
                  }}
                  variant="h5"
                  color="error"
                >
                  <InfoIcon /> {t("fulfillment:orderCancelled")}
                </Typography>
                <Typography>
                  <strong>{t("common:reason")}: </strong>
                  {order?.notes}
                </Typography>
              </Box>
            )}
          {user?.role.toLowerCase() == "admin" &&
            order &&
            order?.status == "to verify" && (
              <Box
                display="flex"
                justifyContent="flex-end"
                mt={2}
                style={{ gap: 5 }}
              >
                <Button
                  onClick={() => setOpenModalCancellation(true)}
                  variant="outlined"
                >
                  {t("common:annuler")}
                </Button>
                <Button
                  disabled={
                    openInputs ||
                    !checkIsOrderReady(orderdProducts) ||
                    orderState?.items?.length === 0
                  }
                  onClick={() => saveDoc("verified")}
                  variant={"contained"}
                  color={"primary"}
                >
                  {t("fulfillment:markVerif")}
                  {loadingButton ? (
                    <CircularProgress
                      style={{ color: "#fff", marginLeft: 5 }}
                      size={20}
                    />
                  ) : null}
                </Button>
              </Box>
            )}
        </Grid>
      </Grid>
      <Modal
        open={openModalCancellation}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Cancellation
          order={order}
          setOpenModalCancellation={setOpenModalCancellation}
          orderdProducts={orderdProducts}
          id={id}
        />
      </Modal>
    </Box>
  );
};
