import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    formControl: {
        marginBottom: 15,
        '& .MuiInputBase-root': {
            background: theme.palette.primary
        },
        '& .MuiOutlinedInput-input': {
            padding: '8.9px 14px',
            fontSize: 12
        }
    },
    label: {
        color: theme.grey500,
        top: -7
    },
    buttonController: {
        color: theme.palette.secondary.dark,
        border: '1px solid',
        borderColor: theme.palette.secondary.dark,
        fontSize: '1rem',
        borderRadius: 10,
        '&:hover': {
            // backgroundColor: '#f7f9ff',
            // borderColor: theme.palette.secondary.dark
        }
    },
    icon: {
        marginRight: '0.375rem'
    },
    button: {
        width: '100%',
        borderRadius: '10px',
        padding: '0.9375rem !important',
        fontWeight: '600 !important'
    },
    menu: {
        paddingTop: 10
    },
    list: {
        paddingRight: '0.5rem'
    },
    listItem: {
        borderLeft: '5px solid transparent',
        '&.Mui-selected': {
            borderLeftColor: theme.palette.blue.main,
            opacity: 1
        }
    },
    listCollapse: {
        opacity: 0.7,
        paddingBottom: 3,
        paddingTop: 3,
        marginBottom: 13,
        borderRadius: '0px 7px 7px 0px',
        borderLeft: '5px solid transparent',
        backgroundColor: 'transparent !important'
    },
    listItemText: {
        fontSize: '0.8125rem',
        color: theme.paper
    },
    inputLabel: {
        textAlign: 'right',
        color: '#2F334D',
        fontWeight: 500,
        textOverflow: 'inherit',
        whiteSpace: 'pre-wrap',
        [theme.breakpoints.down('sm')]: {
            // fontSize: 12
        }
    },
    formBlock: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            marginLeft: 40
        }
    },
    backLink: {
        fontSize: 30,
        cursor: 'pointer',
        marginTop: -20,
        marginBottom: 20,
        marginLeft: -20
    },
    backBox: {
        marginTop: '0.5rem',
        width: 'fit-content'
    },
    modalButton: {
        color: 'black'
    },
    success: {
        color: theme.palette.success.dark,
        fontWeight: 'bold'
    },
    error: {
        color: theme.palette.error.dark,
        fontWeight: 'bold'
    },
    card: {
        background: theme.palette.secondary.main,
        borderRadius: 10,
        border: 'none'
    },
    cardBoldText: {
        color: theme.palette.primary.main,
        fontWeight: 600,
        fontSize: 14
    },
    cardTitle: {
        fontSize: 16
    },
    cardTitleBlock: {
        borderBottom: '1px solid #e7eaef',
        padding: '10px 20px 17px'
    },
    cardContentBlock: {
        padding: '1.4375rem 1.5rem 2.0625rem'
    },
    usernameBlock: {
        background: '#FFF',
        borderRadius: 19,
        padding: 14,
        marginBottom: 17
    },
    username: {
        color: '#2F334D',
        fontSize: 20,
        fontWeight: 600
    },
    textLight: {
        color: theme.palette.gray.main,
        fontSize: 12,
        fontWeight: 500
    },
    text: {
        fontSize: 14,
        fontWeight: 600
    },
    item: {
        padding: '0rem 1.625rem',
        marginBottom: '1.4375rem'
    },
    linkIcon: {
        height: 10,
        width: 8
    },
    print: {
        display: 'none'
    },
    linkSection: {
        borderTop: '1px solid #f2f4f7'
    },
    addButton: {
        position: 'sticky',
        top: 0,
        zIndex: 2,
        background: theme.palette.background.paper,
        '&:hover': {
            background: theme.palette.background.paper
        },
        '&:focus': {
            background: theme.palette.background.paper
        },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 5
    },
    link: {
        display: 'flex',
        alignItems: 'center',
        fontSize: 14,
        justifyContent: 'center',
        color: theme.palette.blue.main
    },
    root: {
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ccc',
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[0],
        transition: theme.transitions.create(['box-shadow', 'border-color'], {
            duration: theme.transitions.duration.short
        }),
        '&:hover': {
            borderColor: theme.palette.primary.main,
            boxShadow: theme.shadows[4]
        }
    },
    avatar: {
        width: theme.spacing(4),
        height: theme.spacing(4),
        marginRight: theme.spacing(2),
        backgroundColor: theme.palette.grey[400]
    },
    username: {
        fontWeight: 'bold',
        marginBottom: theme.spacing(1)
    },
    divider: {
        margin: theme.spacing(2, 0)
    },
    title: {
        fontWeight: 'bold',
        marginBottom: theme.spacing(1)
    },
    text: {
        marginBottom: theme.spacing(1)
    },
    addAddresIcon: {
        backgroundColor: theme.palette.primary.main
    },
    orderDetailsRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        gap: 60
    },
    orderDetailsRowStart: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        gap: 60
    },
    orderDetails: {
        lineHeight: 1.5,
        fontWeight: 500,
        textAlign: 'right'
    },
    orderDetailsBold: {
        lineHeight: 1.5,
        fontWeight: 800,
        textAlign: 'right'
    },
    orderDetailsContainer: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#0766ff1a',
        padding: '13px 30px',
        borderRadius: 10,
        gap: 10
    },
    TableHead: {
        position: 'sticky',
        top: 0,
        background: theme.palette.secondary.light,
        zIndex: 10
    },
    simpleTable: {
        borderCollapse: 'unset',
        borderSpacing: 'unset',
        '& .MuiTableCell-root': {
            background: 'none',
            fontSize: '0.8rem',
            whiteSpace: 'nowrap',
            padding: '7px 10px',
            color: 'unset'
        },
        '& .MuiTableCell-head': {
            whiteSpace: 'nowrap',
            background: 'none',
            paddingBottom: 10,
            color: '#657288',
            fontSize: '0.7rem',
            borderBottom: 'none'
        },
        '& .MuiTableRow-root': {
            minHeight: 45,
            borderRadius: 0,
            '& .MuiTableCell-root:first-of-type': {
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0
            },
            '& .MuiTableCell-root:last-child': {
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0
            }
        }
    },
    redText: {
        color: theme.palette.error.main
    },
    greenText: {
        color: theme.palette.success.light
    },
    TableFooter: {
        position: 'sticky',
        backgroundColor: theme.palette.secondary.light,
        bottom: 0,
        zIndex: 10
    },
    TableFooterCell: {
        fontWeight: 700,
        textTransform: 'uppercase'
    }
}));
