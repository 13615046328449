

import Box from "@material-ui/core/Box";
 
import { SetNotification } from "../../../../../store/notification/notification.action";
import { Delete } from '@material-ui/icons';
import { useNavigate, useParams } from 'react-router';
import { Loader } from "../../../../../components/loader";
import axios from 'axios';
import { useTranslation } from "react-i18next";

import useGetAxios from "../../../../initialization/useGetAxios";
import AddBoxIcon from "@mui/icons-material/AddBox";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Grid,
  TextField,
  Collapse,
  LinearProgress,
  FormControl,
  InputAdornment,
  Link,
  Select,
  Modal,
  OutlinedInput,
  InputLabel,
  MenuItem,
} from "@mui/material";
import { Button } from "@material-ui/core";
import {DatePicker, KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import useGetDoc from "../../../../../hooks/useGetDoc";
import React, {useEffect, useState} from "react";
import {formatDate, timeout} from "../../../../../helpers/utils";
import {fr} from "date-fns/esm/locale";
import DateFnsUtils from "@date-io/date-fns";
import {SpaceBar} from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import AddItem from "../../../../../components/addItem/AddItem";
import { max } from "date-fns";
const  CreateSubscribtion = ({setCloseModal, id}) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation(["buying", "common"]);
    const [loadingButton, setLoadingButton] = useState(false);
 const [frequency, setFrequency] = useState(['','Daily', 'Weekly', 'Monthly', 'Quarterly', 'Half-Yearly', 'Yearly']);
 const [frequencySelected, setFrequencySelected] = useState('');
    const [error, setError] = useState({
        supplier: false,
        scheduleDate: false,
        scheduleDateTill: false,
        items: false,
        taxes: false,
    });
    const [scheduleDate, setScheduleDate] = useState(
        formatDate(new Date(), "YYYY-MM-DD")
    );
    const [scheduleDateTill, setScheduleDateTill] = useState(
        formatDate(new Date(), "YYYY-MM-DD")
    );
    const [state, setState] = useState({
        items: [],
        taxes: []
        
    }); 
    const handleSaveQuotation = () => {
        setLoadingButton(true);
         const data = {
            "doc": {
                "idx": 0,
                "docstatus": 0,
                "disabled": 0,
                "reference_doctype": "Purchase Receipt",
                "reference_document": id.name,
                "submit_on_creation": 0,
                "start_date": scheduleDate,
                "frequency": frequencySelected,
                "repeat_on_day": 0,
                "repeat_on_last_day": 0,
               // "next_schedule_date": "2024-04-25",
                "notify_by_email": 0,
                //"message": "Please find attached {{ doc.doctype }} #{{ doc.name }}",
                "status": "Active",
                "doctype": "Auto Repeat",
                "repeat_on_days": [],
                "end_date": scheduleDateTill
        },
           "action": "Save",
        }
          
         fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
           method: "POST",
           headers: {
             Accept: "application/json",
             "Content-Type": "application/json",
             "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
             Authorization: JSON.parse(localStorage.getItem("user"))?.token,
           },
           body: JSON.stringify(data),
         })
           .then((res) => {
             setLoadingButton(false);
           
             if (!res.ok) {
               dispatch(
                 SetNotification({ code: "error", message: "Something went wrong" })
               );
             }
             return res.json();
           })
           .then((data) => {
             if (data?.success === false) {
                 setLoadingButton(false);
               dispatch(
                 SetNotification({ code: "warning", message: data?.message })
               );
               
             } else {
                 setLoadingButton(false);
               dispatch(
                 SetNotification({
                   code: "success",
                   message: `${t("common:MakeStockEntryCreatedsuccessfully")}`
                 })
               );
               
               handleCancel();
               handleReload();                
             }
           });
       }; 

      const  handleReload = () => {
        window.location.reload();
       }
    
    
    
   
    const handleDateChange = (date, event) => {
        isNaN(Date.parse(date))
            ? setError({ ...error, scheduleDate: false })
            : setScheduleDate(date.toISOString().slice(0, 10));
    };
    const handleDateTillChange = (date, event) => {
        isNaN(Date.parse(date))
            ? setError({ ...error, scheduleDateTill: false })
            : setScheduleDateTill(date.toISOString().slice(0, 10));
    };
    const handleCancel = () => {
        setCloseModal(); // Call setCloseModal to close the modal
      };
       
      return (
        <Card sx={{ boxShadow: 20 , marginLeft :"-20px" , marginRight :"-20px", marginTop :"-5px" , marginBottom :"-12px"}}>
        <div style={{margin:"15px"}}>
         <Grid item xs={12} style={{ marginTop: 20, fontWeight: "bold" }}>
            <Box
              display={"flex"}
              alignItems={"center"}
              style={{ fontSize: "1.25rem", fontWeight: 800 }}
            >
              {/* <AddBoxIcon sx={{ mr: 1 }} style={{ color: "#0b68ff" }} /> */}
              <div style={{ color: "#2F4B7C" }}>
              {t("common:PurchaseReceiptId")}:{id.name}
              </div>
            </Box>
            
          </Grid>
         </div>
         <Divider orientation="horizontal" fullWidth/>
  
         <div style={{marginLeft:"40px"}}> 
  
        
         
        <Grid style={{ marginTop: 25 }}>
              <MuiPickersUtilsProvider
                padding={50}
                locale={fr}
                utils={DateFnsUtils}
              >
                <InputLabel className={"label"}>{ t('common:dateDebut') }</InputLabel>
                <KeyboardDatePicker
                  className={"date"}
                  style={{ marginTop: 10, width: "80%" }}
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  name="scheduleDateTill"
                  inputVariant="outlined"
                  id="date-till-picker-inline"
                  value={scheduleDate}
                  onChange={handleDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  minDate={formatDate(new Date(), "YYYY-MM-DD")}
                  minDateMessage={"common:minDateMessage"}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          



          <Grid style={{ marginTop: 25 }}>
              <MuiPickersUtilsProvider
                padding={50}
                locale={fr}
                utils={DateFnsUtils}
              >
                <InputLabel className={"label"}>{t('common:dateFin') }</InputLabel>
                <KeyboardDatePicker
                  className={"date"}
                  style={{ marginTop: 10, width: "80%" }}
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  name="scheduleDateTill"
                  inputVariant="outlined"
                  id="date-till-picker-inline"
                  value={scheduleDateTill}
                  onChange={handleDateTillChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  minDate={formatDate(new Date(), "YYYY-MM-DD")}
                  minDateMessage={"common:minDateMessage"}
                />
              </MuiPickersUtilsProvider>
            </Grid>

  
        
     
  
  
  
          <Grid item xs={12} style={{ margin: "20px", marginRight: "15%" }}>
            <Box style={{ display: "flex", justifyContent: "center" }}>
              <Button
                size="large"
                disabled={loadingButton}
                color="primary"
                variant="contained"
                onClick={handleSaveQuotation}
                style={{ marginRight: "16px" }} // Add margin to separate buttons
              >
                 {t("common:demender")}
              </Button>
              <Button
                size="large"
                color="error"
                variant="contained"
                onClick={handleCancel}
              >
                {t("common:annuler")}
              </Button>
            </Box>
          </Grid>
        </div>
      </Card>


    )
   
     
}

export default CreateSubscribtion