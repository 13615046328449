import axiosInstance from "../../history/axiosInstance";
import {
  LOYALTY_PROGRAM_LIST_LOADED,
  SET_LOYALTY_PROGRAM_DETAILS,
  UPDATE_APPLIED_LOY_CARD,
} from "./cart.type";

export const UpdateAppliedLoyCard = (payload) => {
  return {
    type: UPDATE_APPLIED_LOY_CARD,
    payload: payload,
  };
};

export const SetLoyaltyProgramDetails = (payload) => {
  return {
    type: SET_LOYALTY_PROGRAM_DETAILS,
    payload: payload,
  };
};

export const GetLoyaltyProgramDetails = (content) => {
  return async (dispatch) => {
    const header = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem("user")).token,
      },
    };
    const data = content;
    return axiosInstance
      .post(
        `${process.env.REACT_APP_API_URI}/api/get-loyalty-program-details`,
        data,
        header
      )
      .then(({ data }) => {
        dispatch(SetLoyaltyProgramDetails(data?.message));
      });
  };
};

export const GetLoyaltyProgramList = (payload) => {
  return {
    type: LOYALTY_PROGRAM_LIST_LOADED,
    payload: payload,
  };
};
