import {
  PRODUCTS_LOADED,
  GET_CATEGORIES,
  CATEGORIES_LOADED,
  LOAD_FILTRED_PRODUCTS,
  GET_PRODUCT_STOCK_BY_ID,
  PRODUCT_STOCK_LOADED,
  PRODUCTS_POS_LOADED,
  PRODUCTS_BUNDLE_LOADED,
  SET_SELECTED_PRODUCT_BUNDLE_DETAILS,
  ITEM_COLLECTION_LOADED,
  ITEM_TAX_LOADED,
  CREATE_PRODUCT,
  PRODUCT_CREATED,
  ALL_CATEGORIES_LOADED,
  PRODUCT_STOCK,
  IMAGES_LOADED,
} from "./product.type";
import { SetNotification } from "../notification/notification.action";
import axiosInstance from "../../history/axiosInstance";

export const CreateProduct = (content) => ({
  type: CREATE_PRODUCT,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${process.env.REACT_APP_API_URI}/api/products/create`,
        method: "POST",
        body: JSON.stringify({
          doc: content,
          action: "Save",
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
          Authorization: JSON.parse(localStorage.getItem("user")).token,
        },
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: PRODUCT_CREATED,
        meta: {
          then: (data) => (dispatch, getState) => {
            dispatch(
              SetNotification({
                code: "success",
                message: "notification:productCreated",
              })
            );
          },
        },
      },
    },
  },
});

export const LoadProducts = (products) => {
  return {
    type: PRODUCTS_LOADED,
    products: products,
  };
};

export const SetItemImages = (data) => {
  return {
    type: IMAGES_LOADED,
    images: data,
  };
};

export const LoadPosProducts = (products) => {
  return {
    type: PRODUCTS_POS_LOADED,
    productsPos: products,
  };
};
export const LoadProductsBundle = (products) => {
  return {
    type: PRODUCTS_BUNDLE_LOADED,
    productsBundle: products,
  };
};
export const LoadItemCollection = (itemCollection) => {
  return {
    type: ITEM_COLLECTION_LOADED,
    itemCollection: itemCollection,
  };
};

export const LoadItemTax = (itemTax) => {
  return {
    type: ITEM_TAX_LOADED,
    itemTax: itemTax,
  };
};

export const LoadItemCategories = (itemCategories) => {
  return {
    type: CATEGORIES_LOADED,
    itemCategories: itemCategories,
  };
};

export const LoadProductBundleByName = (name) => {
  return (dispatch) => {
    const header = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem("user")).token,
      },
    };
    return axiosInstance
      .get(
        `${process.env.REACT_APP_API_URI}/api/products-bundle/details?doctype=Product%20Bundle&name=${name}`,
        header
      )
      .then(({ data }) => {
        dispatch(LoadProductBundleDetails(data));
      });
  };
};

export function LoadProductBundleDetails(LoadProductBundleDetails) {
  return (dispatch, getState) => {
    dispatch({
      type: SET_SELECTED_PRODUCT_BUNDLE_DETAILS,
      payload: LoadProductBundleDetails,
    });
  };
}

export const LoadProductStockById = (id) => ({
  type: GET_PRODUCT_STOCK_BY_ID,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${process.env.REACT_APP_API_URI}/api/stock/dashboard`,
        method: "POST",
        body: JSON.stringify({
          item_code: id,
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
          Authorization: JSON.parse(localStorage.getItem("user")).token,
        },
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: PRODUCT_STOCK_LOADED,
        meta: "",
      },
    },
  },
});

export function ClearStockProduct() {
  return (dispatch, getState) => {
    dispatch({
      type: PRODUCT_STOCK_LOADED,
    });
  };
}

export const GetFiltredProducts = (resetFilter, category, key) => {
  return {
    type: LOAD_FILTRED_PRODUCTS,
    category: category,
    search: key,
    resetFilter: resetFilter,
  };
};

export const LoadAllItemCategories = (allItemCategories) => {
  return {
    type: ALL_CATEGORIES_LOADED,
    allItemCategories: allItemCategories,
  };
};
export function displayProductsStock(data) {
  return (dispatch, getState) => {
    dispatch({
      type: PRODUCT_STOCK,
      payload: data,
    });
  };
}
