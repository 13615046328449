import React from "react";
import { useStyles } from "./style";
import { InputLabel, FormControl, OutlinedInput, FormHelperText } from "@material-ui/core";

const FormInput = (props) => {
  const classes = useStyles();
  const { label, value, name, handleChange, error, type, disabled, required, messagehelpe } = props;
  return (
    <>
      <InputLabel required={required ? true : false} className={classes.label} error={error ?? error}>{label} :</InputLabel>
      <FormControl className={classes.packControl} variant="outlined">
        <OutlinedInput
          id="outlined-adornment-weight"
          placeholder={label}
          type={type ?? type}
          name={name}
          value={value}
          error={error}
          disabled={disabled ?? disabled}
          onChange={(e) => {
            handleChange(e);
          }}
          aria-describedby="outlined-weight-helper-text"
          inputProps={{
            "aria-label": "weight",
          }}
          labelWidth={0}
        />
        {error && messagehelpe && (<FormHelperText style={{ fontSize: '9px' }} error id="accountId-error">
          {messagehelpe}
        </FormHelperText>)}

      </FormControl>
    </>
  );
};

export default FormInput;
