import { CLEAR_STATE } from "../login/login.type";
import {
  REDIRECT_TO
} from "./page.type";
const initialState = {
  redirectTo: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case REDIRECT_TO:
      return {
        ...state,
        redirectTo: action.redirectTo,
      };
    case CLEAR_STATE:
      return (state = initialState);
    default:
      return state;
  }
};
export default reducer;
