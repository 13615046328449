export const PRODUCTS_LOADED = "PRODUCTS LOADED";
export const PRODUCTS_POS_LOADED = "PRODUCTS_POS_LOADED";
export const PRODUCTS_BUNDLE_LOADED = "PRODUCTS_BUNDLE_LOADED";
export const SET_SELECTED_PRODUCT_BUNDLE_DETAILS =
  "SET_SELECTED_PRODUCT_BUNDLE_DETAILS";

export const GET_CATEGORIES = "LOAD CATEGORIES";
export const CATEGORIES_LOADED = "CATEGORIES_LOADED";
export const LOAD_FILTRED_PRODUCTS = "LOAD_FILTRED_PRODUCTS";
export const ALL_CATEGORIES_LOADED = "ALL_CATEGORIES_LOADED";

export const GET_PRODUCT_STOCK_BY_ID = "GET_PRODUCT_STOCK_BY_ID";
export const PRODUCT_STOCK_LOADED = "PRODUCT_STOCK_LOADED";
export const PRODUCT_STOCK = "PRODUCT_STOCK";
export const ITEM_COLLECTION_LOADED = "ITEM_COLLECTION_LOADED";

export const ITEM_TAX_LOADED = "ITEM_TAX_LOADED";

export const PRODUCT_CREATED = "PRODUCT_CREATED";
export const CREATE_PRODUCT = "CREATE_PRODUCT";

export const IMAGES_LOADED = "IMAGES_LOADED";
