import { Satellite } from "@material-ui/icons";
import {
  SUPPLIER_DETAILS_LOADED,
  MATERIAL_REQ_LOADED,
  SET_REQUEST_DATA,
  SET_QUO_MAPPED_DOC,
  SUPPLIER_LIST_LOADED,
  QUOTATION_REQ_LOADED,
  SET_QUO_REQ_DETAILS,
  SET_SUPP_QUO_DOC,
  SUPPLIER_QUOTATIONS_LOADED,
  SET_PURCHASE_MAPPED_DOC,
  PURCHASE_REQ_LOADED,
  SET_PURCHASE_RECEIPT_MAPPED_DOC,
  PURCHASE_INVOICE_LOADED,
  PURCHASE_RECEIPT_LOADED,
  SET_PURCHASE_INVOICE_MAPPED_DOC,
  SET_PAYMENT_ENTRY_MAPPED_DOC,
  STOCKIST_SIGNATURE,
  SET_PURCHASE_ORDER_DETAILS,
  SET_SELECTED_PURCHASE_ORDER_PACKAGE,
  SET_PURCHASE_ORDER_PACKAGE_DOC,
  SET_PACKAGES_WITH_ITEMS_MATCHING_DBERP,
  AUTO_MAT_REQ_LOADED,
  AUTO_MAT_REQ_DETAILS_LOADED,
} from "./buying.type";

import { CLEAR_STATE } from "../login/login.type";

const initialState = {
  purchaseMatReq: null,
  purchaseRequestData: {
    set_warehouse: null,
    items: [],
  },
  quotationMappedDoc: null,
  supplierList: [],
  selectedSupplierDetails: null,
  quotationReqList: null,
  quotationReqDetails: null,
  supplierQuoDoc: null,
  supplierQuoList: null,
  supplierQuoDetails: null,
  purchaseOrderDoc: null,
  purchaseOrderList: null,
  purchaseReceiptDoc: null,
  purchaseInvoiceList: null,
  purchaseReceiptList: null,
  puchaseInvoiceDoc: null,
  paymentEntryDoc: null,
  stockistSignature: null,
  purchaseOrderDetails: null,
  purchaseReceivedMaterial: null,
  selectedPurchaseOrderPackage: [],
  purchaseOrderPackageDoc: null,
  packagesWithItemsMatchingDbErp: null,
  autoMatReqList: [],
  autoMatReqDetails: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case MATERIAL_REQ_LOADED:
      return {
        ...state,
        purchaseMatReq: action.payload,
      };
    case SET_REQUEST_DATA:
      return {
        ...state,
        purchaseRequestData: action.payload,
      };
    case SET_QUO_MAPPED_DOC:
      return {
        ...state,
        quotationMappedDoc: action.payload,
      };
    case SUPPLIER_LIST_LOADED:
      return {
        ...state,
        supplierList: action.payload,
      };
    case SUPPLIER_DETAILS_LOADED:
      return {
        ...state,
        selectedSupplierDetails: JSON.parse(action.payload).message,
      };
    case QUOTATION_REQ_LOADED:
      return {
        ...state,
        quotationReqList: action.payload,
      };
    case SET_QUO_REQ_DETAILS:
      return {
        ...state,
        quotationReqDetails: action.payload,
      };
    case SET_SUPP_QUO_DOC:
      return {
        ...state,
        supplierQuoDoc: action.payload,
      };
    case SUPPLIER_QUOTATIONS_LOADED:
      return {
        ...state,
        supplierQuoList: action.payload,
      };
    case SET_PURCHASE_MAPPED_DOC:
      return {
        ...state,
        purchaseOrderDoc: action.payload,
      };
    case PURCHASE_REQ_LOADED:
      return {
        ...state,
        purchaseOrderList: action.payload,
      };
    case SET_PURCHASE_RECEIPT_MAPPED_DOC:
      return {
        ...state,
        purchaseReceiptDoc: action.payload,
      };
    case PURCHASE_INVOICE_LOADED:
      return {
        ...state,
        purchaseInvoiceList: action.payload,
      };
    case PURCHASE_RECEIPT_LOADED:
      return {
        ...state,
        purchaseReceiptList: action.payload,
      };
    case SET_PURCHASE_INVOICE_MAPPED_DOC:
      return {
        ...state,
        puchaseInvoiceDoc: action.payload,
      };
    case SET_PAYMENT_ENTRY_MAPPED_DOC:
      return {
        ...state,
        paymentEntryDoc: action.payload,
      };
    case STOCKIST_SIGNATURE:
      return {
        ...state,
        stockistSignature: action.stockistSignature,
      };
    case SET_PURCHASE_ORDER_DETAILS:
      return {
        ...state,
        purchaseOrderDetails: action.payload,
        purchaseReceivedMaterial: action?.payload?.items,
      };
    case SET_SELECTED_PURCHASE_ORDER_PACKAGE:
      return {
        ...state,
        selectedPurchaseOrderPackage: action?.payload,
      };
    case SET_PURCHASE_ORDER_PACKAGE_DOC:
      return {
        ...state,
        purchaseOrderPackageDoc: action.payload,
      };
    case SET_PACKAGES_WITH_ITEMS_MATCHING_DBERP:
      return {
        ...state,
        packagesWithItemsMatchingDbErp: action.payload,
      };
    case AUTO_MAT_REQ_DETAILS_LOADED:
      return {
        ...state,
        autoMatReqDetails: action.payload,
      };
    case AUTO_MAT_REQ_LOADED:
      return {
        ...state,
        autoMatReqList: Array.isArray(action.payload) ? action.payload : [],
      };
    case CLEAR_STATE:
      return (state = initialState);
    default:
      return state;
  }
};

export default reducer;
