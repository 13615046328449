import React, { useEffect, useState, useRef } from "react";
import { Button, Checkbox } from "@material-ui/core";
import axios from 'axios';
import readXlsxFile from 'read-excel-file';
import { SetNotification } from "../../../../store/notification/notification.action";
import './QuotationComponent.css';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

const ImportPurchaseOrder = ({ setCloseModalImport }) => {
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const [checked, setChecked] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const { t } = useTranslation(["buying", "common"]);

  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    setLoadingButton(true);

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URI}/api/upload-purchase-order-file`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      dispatch(
        SetNotification({
          code: "success",
          message: `Purchase Order imported successfully`,
        })
      );
      setCloseModalImport(true);
    } catch (error) {
      dispatch(
        SetNotification({ code: "error", message: "Something went wrong" })
      );
      setCloseModalImport(true);
      console.error('Error uploading file:', error);
    } finally {
      setLoadingButton(false);
    }
  };

  const handleDownloadClick = () => {
    const fileUrl = process.env.PUBLIC_URL + '/assets/file/Purchase_Order.xlsx';
    window.open(fileUrl);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Button
          size="large"
          color="primary"
          variant="outlined"
          onClick={handleDownloadClick}
          startIcon={<CloudDownloadIcon />}
          style={{ marginBottom: '10px' }}
        >
          {t("Télécharger le modele pour importer le bon de commande")}
        </Button>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', textAlign: 'center', fontSize: '12px', marginBottom: '10px', marginTop: '5px', color: 'red' }}>
        <Checkbox
          checked={checked}
          onChange={(e) => setChecked(e.target.checked)}
          color="primary"
          inputProps={{ 'aria-label': 'secondary checkbox' }}
        />
        {t("common:respectFileRegulations")}
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Button
          disabled={loadingButton}
          size="large"
          color="primary"
          variant="outlined"
          onClick={handleIconClick}
          startIcon={<CloudUploadIcon />}
          style={{ marginBottom: '10px', visibility: checked ? 'visible' : 'hidden' }}
        >
          {t("common:importFilee")}
        </Button>
      </div>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        accept=".xlsx, .xls"
        onChange={handleFileChange}
      />
    </div>
  )
}

export default ImportPurchaseOrder;
