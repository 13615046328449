import {
    COUNTRY_LOADED,
    TERRITORY_LOADED,
    ADDRESS_LOADED,
    UPDATE_TABLE_CONFIG
  } from "./setting.type";

export const LoadCountry = (country) => {
    return {
      type: COUNTRY_LOADED,
      country: country,
    };
  };

  export const LoadTerritoy = (territory) => {
    return {
      type: TERRITORY_LOADED,
      territory: territory,
    };
  };
  
  export const LoadAddress = (address) => {
    return {
      type: ADDRESS_LOADED,
      address: address,
    };
  };

  export function updateTableConfig(data) {
    return (dispatch, getState) => {
      dispatch({
        type: UPDATE_TABLE_CONFIG,
        payload: data,
      });
    };
  };
  