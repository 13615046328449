import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography, Grid, Box, Paper, FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton } from "@material-ui/core";
import {useTranslation} from "react-i18next";
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: 7
  },
  paper: {
    borderRadius: 10,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: "78px"
  },
  couponInput: {
    justifyContent: "center",
    width: "100%"
  },
  couponLabel: {
    fontSize: 14,
    color :theme.palette.productsTable.button
  },
  totalPrice: {
    [theme.breakpoints.up('md')]: {
      fontSize :20,
    },
    color :theme.palette.productsTable.totalPrice,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    '&:hover': {
      overflow: "visible",
    },
  },
  button : {
    padding: "10px 50px",
    fontSize: 32,
    width: "100%",
    height: "100%",
    backgroundColor : theme.palette.productsTable.button,
    color : theme.palette.productsTable.buttonColor,
    '&:hover' : {
      backgroundColor : theme.palette.productsTable.button
    }
  },
  modalPaper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 10,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  }
}));

export const  TotalPrice = () => {
  const classes = useStyles();
  const {t} = useTranslation(["interfaceCustomization","common","checkout"]);
  
  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={6} sm={4}>
          <Button  className={classes.button} size={'large'} variant="contained">
            {t("common:payer")}
          </Button>
        </Grid>
        <Grid item xs={6} sm={4}>
          <Paper className={classes.paper}>
            <InputLabel style={{textAlign: "left", marginBottom: -10}} className={classes.couponLabel} >{t("checkout:couponCode")}</InputLabel>
            <FormControl className={classes.couponInput}>
              <OutlinedInput
                id="outlined-adornment-weight"
                placeholder={t("checkout:couponCode")}   
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  "aria-label": "weight",
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      disableRipple={true}
                      size="small"
                      edge="end"
                    >
                      <ArrowForwardIos />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Paper>
        </Grid>
        <Grid item xs={6} sm={4}>
          <Paper className={classes.paper} style={{display: "flex"}}>
            <Grid item xs={3} sm={6}>
              <Typography className={classes.couponLabel} align="left" variant="caption" display="block">
                {t("common:tax")}
              </Typography>
              <Typography className={classes.couponLabel} align="left" variant="caption" display="block">
                {t("common:remise")}
              </Typography>
              <Typography className={classes.totalPrice} align="left" variant="caption" display="block">
                {t("common:total")}
              </Typography>
            </Grid>
            <Grid item xs={3} sm={6}>
              <Typography className={classes.couponLabel} variant="caption" display="block" align="left">
                 13,000 DT
              </Typography>
              <Typography className={classes.couponLabel} variant="caption" display="block" align="left">
                 0 %
              </Typography>
              <Typography className={classes.totalPrice} variant="caption" display="block" align="left">
                 73,970 DT
              </Typography>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  )

}
