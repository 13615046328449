import React from "react";
import { Typography, Button, IconButton, makeStyles } from "@material-ui/core";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormControl from "@material-ui/core/FormControl";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import { Calculator } from "../../../../components/calculator";
import clsx from "clsx";
import { useStyles } from "../../style";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SetCushFund, logout } from "../../../../store/login/login.action";
import { OpenSession } from "../../../../store/pos/pos.action";
import { useTranslation } from "react-i18next";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { LoadStockDataReport } from "../../../../store/inventory/inventory.action";
import InfoIcon from "@material-ui/icons/Info";
import { useEffect } from "react";
import { timeout } from "../../../../helpers/utils";

const useStyless = makeStyles((theme) => ({
  root: {
    display: "flex",
    marginTop: "5%",
    flexDirection: "column",
    alignItems: "center",
  },
  paper: {
    borderRadius: 10,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    width: "30%",
    padding: theme.spacing(3),
    backgroundColor: "#EEF2F6",
  },
  logo: {
    width: "30%",
    aspectRatio: "3/2",
    objectFit: "contain",
    mixBlendMode: "darken",
  },
}));

export const CashFund = () => {
  const classes = useStyles();
  const classess = useStyless();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation(["common"]);
  const activeImageTheme = useSelector((state) => state.Theme.image);
  const cushFund = useSelector((state) => state.Login.cushFund);
  const selectedData = useSelector((state) => state.Login.selectedData);
  const open = useSelector((state) => state.Pos.open);
  const companyacounts = useSelector(
    (state) => state.Configuration.companyacounts
  );
  const globalDefaults = useSelector((state) => state.Login.globalDefaults);
  const cushFundmax = 999999999990;

  const handleLogout = async () => {
    await dispatch(logout());
    await timeout(2000);
    history.push("/login");
  };

  const HandleAmount = (price) => {
    if (!isNaN(price) || price === ".")
      dispatch(SetCushFund(parseFloat(price)));
  };
  const HandleSubmit = () => {
    if (globalDefaults && selectedData) {
      const data = {
        report_name: "Stock Projected Qty",
        filters: {
          company: globalDefaults?.default_company,
          warehouse: selectedData?.store,
          item_group: "",
        },
      };
      dispatch(LoadStockDataReport(data));
      dispatch(
        OpenSession(
          { pos: selectedData.cashier, amount: cushFund },
          globalDefaults?.default_company,
          companyacounts?.default_currency
        )
      );
    }
  };

  useEffect(() => {
    if (open) {
      history.push("/initialization");
    }
  }, [open]);

  return (
    <>
      <div style={{ position: "absolute" }}>
        <IconButton onClick={handleLogout}>
          <ExitToAppIcon color="error" />
        </IconButton>
      </div>
      <Box className={classess.root}>
        <Box className={classess.paper}>
          <img src={activeImageTheme} alt={"logo"} className={classess.logo} />
          <Box mt={2} width={"100%"}>
            <FormControl variant="outlined">
              <InputLabel id="demo-simple-select-outlined-label">
                Opening amount
              </InputLabel>
              <OutlinedInput
                type="number"
                id="outlined-adornment-weight"
                value={cushFund}
                onChange={(e) => {
                  HandleAmount(e.target.value);
                }}
                startAdornment={
                  <span className="icon-money" style={{ marginRight: 10 }} />
                }
                endAdornment={
                  <span className={classes.currency}>
                    {globalDefaults?.default_currency}
                  </span>
                }
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  "aria-label": "weight",
                }}
                label={"Opening amount"}
                style={{ backgroundColor: "#FFF" }}
              />
            </FormControl>
            {cushFund > cushFundmax && (
              <Box display={"flex"} alignItems={"center"}>
                <InfoIcon />
                <p style={{ fontSize: "13px", marginLeft: 10 }}>
                  {t("common:msghelpeopeningamount")} {cushFundmax}
                  {globalDefaults?.default_currency}
                </p>
              </Box>
            )}
            <Box>
              <Calculator
                isCalcul={false}
                amount={cushFund}
                HandleAmount={HandleAmount}
                basic={true}
                transparent={true}
              />
            </Box>
            <Box>
              <Button
                disabled={cushFund === "" || cushFund > cushFundmax}
                onClick={HandleSubmit}
                className={classes.button}
                size={"large"}
                variant="contained"
                color="primary"
              >
                {t("common:valider")}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
