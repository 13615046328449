import history from "../../history/history";

const checkAuthMiddleware = (store) => (next) => (action) => {
  if (action?.payload && action?.payload?.status === 401) {
    history.push("/login");
    window.location.reload();

    // clearing cookies
    window?.localStorage?.removeItem("user");
    localStorage?.clear();
    sessionStorage?.clear();
    indexedDB?.deleteDatabase("localforage");
  }

  return next(action);
};

export default checkAuthMiddleware;
