import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  highlightedRow: {
    border: `${theme.palette.blue.main} 2px solid`,
  },
  tableCell: {
    background: "#fff",
    fontSize: "0.8rem",
    padding: "8px 12px",
    color: theme.palette.primary.main,
  },
  tableRow: {
    height: 56,
    "& .MuiTableCell-root:first-child": {
      borderTopLeftRadius: 5,
      borderBottomLeftRadius: 5,
    },
    "& .MuiTableCell-root:last-child": {
      borderTopRightRadius: 5,
      borderBottomRightRadius: 5,
    },
  },
  tableBody: {
    display: "block",
    height: 225,
    overflow: "auto",
  },
  table: {
    background: theme.palette.productsTable.background,
    borderSpacing: "0 5px",
    borderCollapse: "separate",
    "& .MuiTableRow-root": {
      display: "table",
      width: "100%",
      tableLayout: "fixed",
    },
    marginBottom: 0,
  },
}));
