import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from 'react-redux';
import { formatDate } from '../../../../helpers/utils';

const useStyles = makeStyles((theme) => ({
  main: {
    padding: "0px 50px",
    fontSize: 12
  },
  header: {
    marginBottom: 30
  },
  logo: {
    textAlign: "center",
    marginBottom: 10
  },
  title: {
    borderTop: '1px solid  #5D6975',
    borderBottom: '1px solid  #5D6975',
    color: '#5D6975',
    fontSize: '30px',
    lineHeight: '1.4em',
    fontWeight: 'normal',
    textAlign: 'center',
    margin: '0 0 20px 0',
    marginBottom: '20px',
  },
  company: {
    float: 'right',
    textAlign: 'right',
    marginTop: 30
  },
  table: {
    width: '100%',
    marginTop: 20,
  },
  tableheader: {
    padding: "5px 5px",
    background: "#E5E5E5",
    border: "1px solid #C1CED9",
    fontWeight: 'normal',
    textAlign: "left"
  },
  tableColumn: {
    padding: 10,
    border: "1px solid #C1CED9",
    textAlign: "left"
  },
  ref: {
    width: '60%',
    marginTop: 5
  },
  headtable: {
    border: " 1px solid #C1CED9 ",
    background: "#E5E5E5",
    fontWeight: 'normal'
  },
  columntable: {
    padding: 10,
    textAlign: 'center',
    border: "1px solid #C1CED9",
  }
}));

export const ComponentToPrint = React.forwardRef(({ openingAmount, coffreBalance, getPaymentsBalance, invoices, pm, caisseBalance }, ref) => {
  const classes = useStyles();

  const activeImageTheme = useSelector(state => state.Theme.image);
  const globalDefaults = useSelector(state => state.Login.globalDefaults);
  const selectedData = useSelector(state => state.Login.selectedData);
  const coffreOperations = useSelector(state => state.Pos.coffreOperations);
  return (
    <div className={classes.main} ref={ref}>

      <header className={classes.header}>
        <div className={classes.logo}>
          <img src={activeImageTheme} alt="logo" />
        </div>
        <h1 className={classes.title}>Opérations de Coffre</h1>
        <div>
          <h3>Montant d'ouverture de Caisse: {openingAmount && openingAmount.toFixed(3)} {globalDefaults?.default_currency}</h3>
          {pm == "Cash" && (<h3>Balance de coffre: {coffreBalance && coffreBalance.toFixed(3)} {globalDefaults?.default_currency}</h3>)}
          <h3>Montant total des Transactions: {getPaymentsBalance(invoices, pm).toFixed(3)} {globalDefaults?.default_currency}</h3>
          {pm == "Cash" && (<h3>Balance de Caisse: {caisseBalance} {globalDefaults?.default_currency}</h3>)}
          <h3>Caissier: {selectedData?.cashier} </h3>
          <h3>Magasin: {selectedData?.store} </h3>
          <h3>Date: {formatDate(Date.now(), "DD/MM/YYYY HH:mm")}</h3>
        </div>
      </header>

      <table className={classes.table}>
        <thead>
          <tr>
            <th className={classes.tableheader}>Date</th>
            <th className={classes.tableheader}>Type</th>
            <th className={classes.tableheader}>Par</th>
            <th className={classes.tableheader}>Montant</th>
            <th className={classes.tableheader}>Caissier</th>
          </tr>
        </thead>
        <tbody>
          {coffreOperations.length != 0 && coffreOperations.map((o, i) => (
            o?.payment_method?.toLowerCase() == pm?.toLowerCase() && <tr index={i}>
              <td className={classes.tableColumn}>{formatDate(o.updated_at, "DD/MM/YYYY HH:mm")}</td>
              <td className={classes.tableColumn}>{o.type}</td>
              <td className={classes.tableColumn}>{o.payment_method}</td>
              <td className={classes.tableColumn}>{o.amount} {globalDefaults?.default_currency}</td>
              <td className={classes.tableColumn}>{o.cashier}</td>
            </tr>

          ))}
        </tbody>
      </table>
    </div>
  );
});