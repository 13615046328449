import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ProductItem } from "../../../../components/productItem";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import { Typography, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    maxHeight: "100%",
    overflow: "auto",
  },
  container: {
    flexGrow: 1,
    marginTop: 22
  },
  box: {
    color: theme.palette.secondary.main,
    display: "flex",
    alignItems: "center",
    marginBottom: 10,
    marginTop: 20,
  },
  autocomplete: {
    width: "100%",
    marginLeft: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      minWidth: 200,
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 10px) scale(1)",
      fontSize: 14,
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
      fontSize: 16,
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: theme.palette.white.main,
      padding: "0px 20px",
      "& input": {
        fontSize: 14,
      },
    },
  },
  title: {
    fontSize: 14,
    marginLeft: 12,
    color: theme.palette.products.text,
    marginRight: 20,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  icon: {
    marginLeft: 7,
    color: "#657288",
  },

  products: {
    padding: "0px 20px",
    background: theme.palette.products.background,
    borderColor: theme.palette.products.border,
    borderRadius: 19,
    marginBottom: 20,
    height: "100%",
    overflow: "hidden",
  },
  productsitem: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      maxWidth: 200,
    },
  },
}));

const categories = [
  { title: "The Shawshank Redemption", year: 1994 },
  { title: "The Godfather", year: 1972 },
  { title: "The Godfather: Part II", year: 1974 },
  { title: "The Dark Knight", year: 2008 },
];

export const Products = () => {
  const classes = useStyles();
  const products = [0, 1, 2, 4, 6, 7, 8, 9];
  const { t } = useTranslation(["interfaceCustomization", "common", "checkout"]);
  return (
    <Box className={classes.products}>
      <Box className={classes.box}>
        <Autocomplete
          id="combo-box-demo"
          options={categories}
          className={classes.autocomplete}
          popupIcon={<ExpandMoreIcon />}
          getOptionLabel={(option) => option.title}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t("common:allCategories")}
              variant="outlined"
            />
          )}
        ></Autocomplete>
        <span className={clsx(classes.icon, "icon-search")} />
        <Typography className={classes.title}> {t("common:rechercher")}</Typography>
      </Box>
      <div className={classes.container}>
        <Grid container spacing={2}>
          {products.map((p) =>
            <Grid item xs={3}>
              <ProductItem />
            </Grid>
          )}
        </Grid>
      </div>
    </Box>
  );
};
