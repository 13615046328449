import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 10,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  bodyTitle: {
    marginBottom: "20px",
  },
  formControl: {
    maxWidth: 300,
    [theme.breakpoints.down('xs')]: {
      marginTop: 10
    },
  },
  formBlock: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
    },
  },
  firstCol: {
    [theme.breakpoints.up("md")]: {
      marginRight: 30,
    },
  },
  title: {
    fontWeight: 700,
    fontSize: 20,
  },
  modalButton: {
    marginLeft: 5,
    marginRight: 5
  },
}));
