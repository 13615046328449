import React, {useEffect} from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
    main : {
        padding: "0px 50px"
    },
    header: {
        padding: "30px 0"
      },
      logo: {
        textAlign: "center",
        marginBottom: 10
      },
      title: {
        borderTop: '1px solid  #5D6975',
        borderBottom: '1px solid  #5D6975',
        color: '#5D6975',
        fontSize: '30px',
        lineHeight: '1.4em',
        fontWeight: 'normal',
        textAlign: 'center',
        margin: '0 0 20px 0'
      },
      orders: {
        float: 'center',
        textAlign: 'center'
      },
      projectitem :{
        color: '#5D6975',
        textAlign: 'left',
        width: '52px',
        marginRight: 10,
        display: 'inline-block',
        fontSize: '0.8em',
      },
      table: {
        width: '100%'
      },
      tableheader :{
        padding: "5px 20px",
        background: "#E5E5E5",
        border: "1px solid #C1CED9",
        fontWeight: 'normal'
      },
      tableColumn: {
        padding: 10,
        textAlign: 'right',
        border: "1px solid #C1CED9",
      },
      checkboxColumn: {
        padding: 10,
        textAlign: 'center',
        border: "1px solid #C1CED9",
      }
    }));  

export const FullfilmentComponentToPrint = React.forwardRef((props, ref) => {
    const classes = useStyles();
    const activeImageTheme = useSelector(state => state.Theme.image);
    const globalDefaults = useSelector(state => state.Login.globalDefaults);

    return (
      <div className={classes.main} ref={ref}>
            <header className={classes.header}>
      <div  className={classes.logo}>
        <img src={activeImageTheme}/>
      </div>
      <h1 className={classes.title}>{props.order && props.title} </h1>
      <div className={classes.orders}>
        <div><span className={classes.projectitem}>COMMANDES</span></div>
        <div><span>{props.header && props.header.join('/')}</span></div>
      </div>
    </header>
      <table className={classes.table}>
        <thead>
          <tr>
            <th className={classes.tableheader}>NOM</th>
            <th className={classes.tableheader}>DESCRIPTION</th>
            <th className={classes.tableheader}>PU</th>
            <th className={classes.tableheader}>QTY</th>
            <th className={classes.tableheader}>TOTAL</th>
          </tr> 
        </thead>
        <tbody>
          {props.order && props.order.map((p,index) => (
             <tr key={index}>
             <td className={classes.tableColumn}>{p.item_name}</td>
             <td className={classes.tableColumn}>{p.description}</td>
             <td className={classes.tableColumn}>{p.price_list_rate ?? 0} {globalDefaults?.default_currency}</td>
             <td className={classes.tableColumn}>{p.quantity}</td>
             <td className={classes.tableColumn}>{(p.price_list_rate ?? 0) * p.quantity} {globalDefaults?.default_currency}</td>
             <td className={classes.checkboxColumn}><input type="checkbox"/></td>
           </tr>
          ))}
        </tbody>
      </table>
      </div>
    );
  });
