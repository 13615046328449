import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Collapse from "@material-ui/core/Collapse";
import Box from "@material-ui/core/Box";
import ExpandMore from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import { StyledCheckbox } from "../../components/styledCheckbox";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { GlobalfilterData } from "../../helpers/helper";
import { fr } from 'date-fns/esm/locale';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { IconButton } from "@material-ui/core";
import RestoreFromTrashIcon from '@material-ui/icons/RestoreFromTrash';
import { getTransferRequests } from '../../store/transferRequest/transferRequest.action';
import moment from "moment";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
    "& span": {
      fontSize: 14,
      color: theme.palette.primary.main,
    },
  },
  button: {
    backgroundColor: "transparent",
    color: theme.palette.primary.main,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: 13,
  },
  box: {
    position: "relative",
  },
  formControlLabel: {
    width: "100%",
  },
  collapse: {
    position: "absolute",
    maxWidth: 228,
    borderRadius: 10,
    border: "1px solid #A6B4CB",
    backgroundColor: theme.palette.white.main,
    zIndex: 2,
    top: 35,
    left: -5,
    [theme.breakpoints.up("md")]: {
      minWidth: 200,
    },
    "& .MuiInputBase-root": {
      background: theme.palette.white.main,
      marginRight: 13,
    },
    "& .MuiOutlinedInput-input": {
      padding: "7.5px 14px",
      fontSize: 12,
    },
  },
  avatar: {
    height: 37,
    marginRight: 11,
    color: "#5A8BDB",
    fontSize: 12,
    backgroundColor: theme.palette.secondary.main,
  },
  collapseContent: {
    padding: "8px 11px",
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 10,
  },
  linkSection: {
    padding: "15px 5px",
    borderTop: "1px solid #f2f4f7",
  },
  link: {
    display: "flex",
    alignItems: "center",
    fontSize: 14,
    justifyContent: "center",
    color: theme.palette.blue.main,
  },
  expandicon: {
    color: theme.palette.gray.main,
  },
  number: {
    color: theme.palette.blue.main,
    marginLeft: "0.5rem",
  },
  BoldTitle: {
    fontWeight: 600,
  },
  ListItem: {
    maxHeight: 200,
    overflow: "auto",
  },
  formControl: {
    width: 175
  }
}));

export const Filter = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [dataToFilter, setDataToFilter] = React.useState([]);
  const [searchItem, setSearchItem] = React.useState([]);
  const { t } = useTranslation(["common"]);
  const handleClick = () => {
    setOpen(!open);
  };
  const handleClickAway = () => {
    setOpen(false);
  };
  const handleSearchItem = (event) => {
    setSearchItem(event.target.value);
    setDataToFilter(GlobalfilterData(props.data, event.target.value, ["label"]));

  };
  const handleCheckbox = (checked, item) => {
    var data = props.value;
    if (checked) {
      data.push(item);
    } else {
      data = data.filter((i) => i !== item);
    }
    props.HandleFilter(props.id, data);
  };

  useEffect(() => {
    setDataToFilter(props.data);
  }, [props.data]);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box className={classes.box}>
        <Button
          aria-controls="customized-menu"
          aria-haspopup="true"
          className={clsx({
            [classes.button]: true, //always applies
            [classes.active]: open, //only when open === true
            [classes.BoldTitle]: props.number !== 0,
          })}
          onClick={handleClick}
        >
          {" "}
          <Box display={"flex"} alignItems={"center"}>
            {props.title}
          </Box>
          <span className={classes.number}>{props.number}</span>
          <ExpandMore className={classes.expandicon} />
        </Button>
        <Collapse
          className={classes.collapse}
          in={open}
          timeout="auto"
          unmountOnExit
        >
          {props.date ?
            <Box
              className={classes.collapseContent}
              display={"flex"}
              alignItems={"center"}
            >
              <FormControl variant="outlined">
                <div className={classes.formControl}>
                  <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      className={classes.date}
                      disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      inputVariant="outlined"
                      id="date-picker-inline"
                      keyboardIcon={<span className={"icon-calendar"} />}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      onChange={props.handleDateChange}
                      value={props.selectedDate}
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </FormControl>
              <IconButton onClick={() => props.HandleDeleteAll()} color="primary" component="span">
                <RestoreFromTrashIcon />
              </IconButton>
            </Box>
            : <>
              <Box
                className={classes.collapseContent}
                display={"flex"}
                alignItems={"center"}
              >
                <FormControl variant="outlined">
                  <OutlinedInput
                    id="outlined-adornment-weight"
                    value={searchItem}
                    placeholder={t("common:rechercher")}
                    onChange={handleSearchItem}
                    endAdornment={
                      <InputAdornment position="end">
                        <span className="icon-search" />
                      </InputAdornment>
                    }
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      "aria-label": "weight",
                    }}
                    labelWidth={0}
                  />
                </FormControl>
              </Box>
              <List className={classes.ListItem} component="div" disablePadding>
                {dataToFilter.map((item, index) => (
                  <ListItem key={index} button>
                    <FormControlLabel
                      className={classes.formControlLabel}
                      control={
                        <StyledCheckbox
                          checked={props?.value?.includes(item?.value)}
                          onChange={(e) =>
                            handleCheckbox(e.target.checked, item?.value)
                          }
                        />
                      }
                      label={item.label}
                    />
                  </ListItem>
                ))}
              </List>
            </>
          }
        </Collapse>
      </Box>
    </ClickAwayListener>
  );
};
