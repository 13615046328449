import {
  Box,
  Typography,
  Paper,
  Grid,
  InputLabel,
  FormControl,
  OutlinedInput,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Modal,
  ClickAwayListener,
  IconButton,
} from "@material-ui/core";
import React, { useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  createCoffreOperation,
  loadCoffreOperation,
} from "../../../../store/pos/pos.action";
import moment from "moment";
import PrintIcon from "@material-ui/icons/Print";
import { useReactToPrint } from "react-to-print";
import { ComponentToPrint } from "../componentToPrint";
import { Loader } from "../../../../components/loader";
import { useTranslation } from "react-i18next";
import french from "simple-keyboard-layouts/build/layouts/french";
import Keyboard from "react-simple-keyboard";
import KeyboardIcon from "@material-ui/icons/Keyboard";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: 15,
    "& .MuiInputBase-root": {
      background: theme.palette.white.main,
    },
    "& .MuiOutlinedInput-input": {
      padding: "8.9px 14px",
      fontSize: 12,
    },
  },
  virtualKeyboard: {
    position: "fixed",
    width: "80%",
    boxShadow: theme.shadows[5],
    borderRadius: 10,
    top: "100%",
    left: "50%",
    transform: "translate(-50%, -105%)",
  },
  label: {
    fontSize: 12,
    marginBottom: 6,
    color: theme.palette.gray.main,
    paddingLeft: 9,
    justifyContent: "left",
  },
  text: {
    color: theme.palette.primary.main,
  },
  paper: {
    position: "absolute",
    width: 700,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 10,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  },
  warning: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 10,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  },
  paperBg: {
    background: theme.palette.productsTable.background,
    marginTop: -16,
  },
  print: {
    display: "none",
  },
  printIcon: {
    color: theme.palette.primary.main,
    height: 50,
    width: 50,
    marginTop: 5,
    cursor: "pointer",
  },
}));

export const CashTab = ({ pm, setOpenModal }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const keyboard = React.useRef();
  const componentRef = useRef();
  const companyacounts = useSelector(
    (state) => state.Configuration.companyacounts
  );
  const selectedData = useSelector((state) => state.Login.selectedData);
  const invoices = useSelector((state) => state.Pos.invoices);
  const posOpeningEntry = useSelector((state) => state.Pos.open);
  const coffreOperations = useSelector((state) => state.Pos.coffreOperations);
  const openingAmount = parseFloat(
    useSelector((state) => state.Login.cushFund)
  );
  const globalDefaults = useSelector((state) => state.Login.globalDefaults);
  const holdOrders = useSelector((state) => state.Checkout.holds);

  const { t } = useTranslation(["common", "session"]);
  const [state, setState] = React.useState({
    cashAmount: 0,
  });
  const [type, setType] = React.useState("Total");
  const [open, setOpen] = React.useState(false);
  const [printOpen, setPrintOpen] = React.useState(false);
  const [keyboardVisibility, setKeyboardVisibility] = React.useState(false);
  const [layout, setLayout] = React.useState("default");
  const [capsLockEnabled, setCapsLockEnabled] = React.useState(false);

  const onKeyPress = (button) => {
    if (button === "{shift}" || button === "{lock}") handleShift();
  };

  const handleShift = () => {
    const newLayoutName = layout === "default" ? "shift" : "default";
    setLayout(newLayoutName);
    setCapsLockEnabled(false);
  };

  const handleListTypeChange = (event) => {
    setType(event.target.value);
  };

  const handleChange = (e) => {
    if (
      isNaN(parseFloat(e.target.value)) ||
      (parseFloat(e.target.value) <= caisseBalance &&
        parseFloat(e.target.value) >= 0)
    ) {
      setState({ ...state, cashAmount: e.target.value });
    }
  };

  const onChange = (input) => {
    if (
      isNaN(parseFloat(input)) ||
      (parseFloat(input) <= caisseBalance && parseFloat(input) >= 0)
    ) {
      setState({ ...state, cashAmount: input });
    }
  };
  const getCashPaymentsBalance = (invoices) => {
    var ttlBalance = 0;
    invoices.length != 0 &&
      invoices.map((inv, i) => {
        inv.payments.map((p, i) => {
          if (p.mode_of_payment == "Cash") {
            ttlBalance += p.amount;
          }
        });
      });
    return ttlBalance;
  };

  const getChequePaymentsBalance = (invoices) => {
    var ttlBalance = 0;
    invoices.length != 0 &&
      invoices.map((inv, i) => {
        inv.payments.map((p, i) => {
          if (p.mode_of_payment == "Cheque") {
            ttlBalance += p.amount;
          }
        });
      });
    return ttlBalance;
  };

  const getPaymentsBalance = (invoices, pm) => {
    var ttlBalance = 0;
    invoices.length != 0 &&
      invoices.map((inv, i) => {
        inv.payments.map((p, i) => {
          if (p.mode_of_payment?.toLowerCase() == pm?.toLowerCase()) {
            ttlBalance += p.amount;
          }
        });
      });
    return ttlBalance;
  };

  const handleDeposit = async () => {
    const data = {
      payment_method: pm,
      creation_date: moment().format("YYYY-MM-DD HH:mm:ss"),
      type: type,
      amount:
        pm == "Cash"
          ? type == "Total"
            ? caisseBalance
            : parseFloat(state.cashAmount)
          : 0,
      transactions: null,
      cashier: selectedData.cashier,
      warehouse: selectedData.store,
      opening_entry: posOpeningEntry.name,
    };
    await dispatch(
      createCoffreOperation(data, companyacounts?.default_currency)
    );
    await dispatch(
      loadCoffreOperation({ opening_entry: posOpeningEntry.name })
    );
    setState({ ...state, cashAmount: 0 });
    setOpen(false);
    setTimeout(() => {
      setPrintOpen(true);
    }, 1500);
  };
  const coffreBalance =
    coffreOperations.length != 0
      ? coffreOperations
          ?.filter((el) => el?.payment_method?.toLowerCase() != "cheque")
          ?.reduce((a, v) => (a = a + parseFloat(v.amount)), 0)
      : 0;
  const caisseBalance = (
    getCashPaymentsBalance(invoices) +
    openingAmount -
    coffreBalance
  ).toFixed(3);

  const coffreBalanceCheque =
    coffreOperations.length != 0
      ? coffreOperations
          ?.filter((el) => el?.payment_method?.toLowerCase() != "cash")
          ?.reduce((a, v) => (a = a + parseFloat(v.amount)), 0)
      : 0;
  const caisseBalanceCheque = (
    getChequePaymentsBalance(invoices) - coffreBalanceCheque
  ).toFixed(3);

  const isValid = () => {
    if (pm != "Cash") return true;
    if (isNaN(parseFloat(state.cashAmount))) return true;
    if (
      parseFloat(state.cashAmount) > caisseBalance ||
      parseFloat(state.cashAmount) <= 0
    )
      return true;
    if (caisseBalance == 0) return true;
    if (type != "Total") {
      if (parseFloat(state.cashAmount) == 0) return true;
    }
    return false;
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforePrint: () => {
      dispatch(loadCoffreOperation({ opening_entry: posOpeningEntry.name }));
    },
    onAfterPrint: () => {
      setPrintOpen(false);
      setOpenModal(false);
    },
  });

  function getCaisseBalance(pm) {
    if (pm === "Cash") return caisseBalance;
    if(pm==="Espèce") return caisseBalance;
    if (pm === "Chèque") return caisseBalanceCheque;
  }

  function getCoffreBalance(pm) {
    if (pm === "Cash") return coffreBalance;
    if (pm === "Cheque") return coffreBalanceCheque;
  }

  if (!selectedData && !posOpeningEntry) return <Loader />;

  const printModal = (
    <ClickAwayListener onClickAway={() => setPrintOpen(false)}>
      <Box className={classes.warning}>
        <PrintIcon className={classes.printIcon} onClick={handlePrint} />
      </Box>
    </ClickAwayListener>
  );

  const alert = (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Box className={classes.warning}>
        <Typography align={"left"} color={"primary"}>
          Vous voulez continuer ?
        </Typography>
        <Grid
          container
          spacing={2}
          style={{ marginTop: 10 }}
          justifyContent="center"
        >
          <Grid item>
            <Button variant="contained" color="primary" onClick={handleDeposit}>
              {t("common:create")}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpen(false)}
            >
              {t("common:annuler")}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </ClickAwayListener>
  );

  const filteredData =
    holdOrders &&
    holdOrders.filter((item) => {
      return (
        (item.amount.depositAmount != 0 || item.amount.payments.length > 0) &&
        item.payment_status === "partly paid"
      );
    });

  function processPaymentTypes(pm) {
    if (filteredData.length > 0) {
      const totalPaymentCash = filteredData
        .flatMap((item) =>
          item.amount.payments
            .filter((payment) =>
              payment.mode_of_payment
                ?.toUpperCase()
                .includes("CASH"?.toUpperCase())
            )
            .map((payment) => payment.amount)
        )
        .reduce((prev, curr) => prev + curr, 0);

      const totalPaymentCheque = filteredData
        .flatMap((item) =>
          item.amount.payments
            .filter((payment) =>
              payment.mode_of_payment
                ?.toUpperCase()
                .includes("CHEQUE"?.toUpperCase())
            )
            .map((payment) => payment.amount)
        )
        .reduce((prev, curr) => prev + curr, 0);
      return pm === "Cash" ? totalPaymentCash : totalPaymentCheque;
    }
    return 0;
  }
  return (
    <Box mt={2}>
      <Paper style={{ padding: "10px 10px" }} className={classes.paperBg}>
        <Typography className={classes.text} align={"left"}>
          Montant d'ouverture de Caisse: {openingAmount.toFixed(3)}{" "}
          {globalDefaults?.default_currency}
        </Typography>
        <Typography className={classes.text} align={"left"}>
          Balance de coffre: {""}
          {(processPaymentTypes(pm) + getCoffreBalance(pm)).toFixed(3)}{" "}
          {globalDefaults?.default_currency}
        </Typography>
        <Typography className={classes.text} align={"left"}>
          Montant total des Transactions ({pm}):{" "}
          {getPaymentsBalance(invoices, pm).toFixed(3)}{" "}
          {globalDefaults?.default_currency}
        </Typography>
        <Typography className={classes.text} align={"left"}>
          {t("session:BalanceCaisse")} ({pm}): {getCaisseBalance(pm)}{" "}
          {globalDefaults?.default_currency}
        </Typography>
        {coffreOperations?.length > 0 && (
          <IconButton onClick={handlePrint}>
            <PrintIcon className={classes.text} />
          </IconButton>
        )}
      </Paper>
      <Box>
        <Box mb={2}>
          {pm == "Cash" && (
            <FormControl variant="outlined">
              <RadioGroup
                row
                aria-label="type"
                name="type"
                value={type}
                onChange={handleListTypeChange}
                style={{
                  justifyContent: "space-evenly",
                  marginTop: 8,
                  marginBottom: -5,
                }}
              >
                <FormControlLabel
                  value="Total"
                  control={<Radio color="primary" className={classes.radio} />}
                  label="Total"
                />
                <FormControlLabel
                  value="Partiel"
                  control={<Radio color="primary" className={classes.radio} />}
                  label="Partiel"
                />
              </RadioGroup>
            </FormControl>
          )}
        </Box>
        {pm == "Cash" && (
          <Grid container>
            <InputLabel required className={classes.label}>
              {t("common:amnt")} (DT)
            </InputLabel>
            <FormControl variant="outlined" className={classes.formControl}>
              <OutlinedInput
                type="number"
                autoComplete="off"
                id="outlined-adornment-weight"
                value={type == "Total" ? caisseBalance : state.cashAmount}
                name="cashAmount"
                onChange={handleChange}
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  "aria-label": "weight",
                }}
                labelWidth={0}
                endAdornment={
                  type == "Partiel" && (
                    <KeyboardIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => setKeyboardVisibility(true)}
                    />
                  )
                }
              />
            </FormControl>
          </Grid>
        )}
        <Grid container spacing={2} justifyContent="center">
          {pm == "Cash" && (
            <Grid item>
              <Button
                disabled={isValid()}
                variant="contained"
                color="primary"
                onClick={() => setOpen(true)}
              >
                {t("session:RemiseCoffre")}
              </Button>
            </Grid>
          )}
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setOpenModal(false)}
            >
              {t("common:annuler")}
            </Button>
          </Grid>
        </Grid>
        <Modal open={open}>{alert}</Modal>
        <Modal open={printOpen}>{printModal}</Modal>
        <Modal hideBackdrop={true} open={keyboardVisibility}>
          <ClickAwayListener onClickAway={() => setKeyboardVisibility(false)}>
            <Box className={classes.virtualKeyboard}>
              <Keyboard
                keyboardRef={(r) => (keyboard.current = r)}
                layoutName={layout}
                layout={french.layout}
                display={{
                  "{capslock}": capsLockEnabled ? "🔒" : "🔓",
                }}
                onChange={onChange}
                onKeyPress={onKeyPress}
              />
            </Box>
          </ClickAwayListener>
        </Modal>
      </Box>
      <div className={classes.print}>
        <ComponentToPrint
          openingAmount={openingAmount}
          coffreBalance={coffreBalance}
          caisseBalance={caisseBalance}
          getPaymentsBalance={getPaymentsBalance}
          invoices={invoices}
          pm={pm}
          ref={componentRef}
        />
      </div>
    </Box>
  );
};
