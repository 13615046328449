import React, { useEffect } from 'react';
import { Box, IconButton, Collapse } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        "& > * + *": {
            marginTop: theme.spacing(2),
        },
    },
}));
export const TransitionAlerts = (props) => {
    const classes = useStyles();
    const { severity, open, message, setAlert, timeout } = props;
    useEffect(() => {

        if (open) {
            let interval = setInterval(() => {
                setAlert({
                    open: false,
                    message: '',
                    severity: ''
                })
                clearInterval(interval);
            }, timeout);
            /*  // return () => clearInterval(interval); */

        }
    }, [open]);
    return (
        <Box className={classes.root}>
            <Collapse in={open}>
                <Alert
                    severity={severity}
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setAlert({
                                    open: false,
                                    message: '',
                                    severity: ''
                                })
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    sx={{ mb: 2 }}
                >
                    {message.split('\n').map((item) => {
                        return (<> {item}
                            <br /></>)
                    })}
                </Alert>
            </Collapse>
        </Box>
    );
}