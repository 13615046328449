import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Box from "@material-ui/core/Box";
import { Typography } from "@material-ui/core";
import { CirclePicker, HuePicker } from "react-color";
import { useDispatch, useSelector } from "react-redux";
import {
  freeButtonStyle,
  selectedProduct,
  productStyle,
  calculatorStyle,
} from "./../../../../store/theme/theme.action";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
  list: {
    minWidth: 250,
    padding: 15,
  },
  title: {
    fontWeight: 700,
    marginBottom: 30,
  },
  subtitle: {
    color: theme.palette.gray.main,
    fontWeight: 500,
    marginBottom: 20,
  },
  block: {
    marginBottom: 20,
  },
}));

export default function TemporaryDrawer(props) {
  const classes = useStyles();
  const [state, setState] = React.useState(false);
  const themeActive = useSelector((state) => state.Theme.activeTheme);
  const {t} = useTranslation(["interfaceCustomization","common"]);

  const globalColors = useSelector((state) => state.Theme.globalColors);
  const freeButtons = useSelector(
    (state) => state.Theme.freeButtonsStyles
  );

  const selectedProducts = useSelector(
    (state) => state.Theme.selectedProductStyle
  );

  const productsStyle = useSelector((state) => state.Theme.productsStyle);

  const calculatorStyles = useSelector((state) => state.Theme.calculatorStyle);

  const dispatch = useDispatch();
  const handleChangeComplete = (key, color) => {
  
    switch (props.drawer.item.key) {
      case "freeButton":
        var data = {
          ...freeButtons,
          [key]: color.hex,
        };
        dispatch(freeButtonStyle(data));
        break;
      case "products":
        var data = {
          ...productsStyle,
          [key]: color.hex,
        };

        dispatch(productStyle(data));

        break;

      case "calculator":
        var data = {
          ...calculatorStyles,
          [key]: color.hex,
        };
        dispatch(calculatorStyle(data));

        break;
      case "selectedProducts":
        var data = {
          ...selectedProducts,
          [key]: color.hex,
        };
        dispatch(selectedProduct(data));
        break;
    }
  };

  return (
    <div>
      <React.Fragment key={"right"}>
        <Drawer className={"interfacePage"} anchor={"right"} open={props.drawer.open} onClose={props.close}>
          <Box className={classes.list}>
            <Typography
              className={classes.title}
              align={"center"}
              color={"primary"}
            >
              {props.drawer.item && props.drawer.item.title}
            </Typography>
            <Box className={classes.block}>
              <Typography className={classes.subtitle}>{t("interfaceCustomization:Background")} :</Typography>
              <HuePicker
                color={
                  themeActive &&
                  themeActive.freeButtonsStyle &&
                  themeActive.freeButtonsStyle.background
                    ? themeActive.freeButtonsStyle.background
                    : "#FFFF"
                }
                onChangeComplete={(color) =>
                  handleChangeComplete("background", color)
                }
              />
            </Box>
            <Box className={classes.block}>
              <Typography className={classes.subtitle}>{t("common:texte")} :</Typography>
              <CirclePicker
                onChangeComplete={(color) =>
                  handleChangeComplete("text", color)
                }
              />
            </Box>
            <Box className={classes.block}>
              <Typography className={classes.subtitle}>{t("common:border")} :</Typography>
              <CirclePicker
                onChangeComplete={(color) =>
                  handleChangeComplete("border", color)
                }
              />
            </Box>
            {props.drawer.item && props.drawer.item.key == "selectedProducts" && (
              <Box>
                <Box className={classes.block}>
                  <Typography className={classes.subtitle}>
                    {" "}
                    {t("common:totalPrice")} :
                  </Typography>
                  <CirclePicker
                    onChangeComplete={(color) =>
                      handleChangeComplete("totalPrice", color)
                    }
                  />
                </Box>
                <Box className={classes.block}>
                  <Typography className={classes.subtitle}>{t("common:table")} :</Typography>
                  <CirclePicker
                    onChangeComplete={(color) =>
                      handleChangeComplete("tableCell", color)
                    }
                  />
                </Box>
                <Box className={classes.block}>
                  <Typography className={classes.subtitle}>{t("common:button")} :</Typography>
                  <CirclePicker
                    onChangeComplete={(color) =>
                      handleChangeComplete("button", color)
                    }
                  />
                </Box>
              </Box>
            )}
          </Box>
        </Drawer>
      </React.Fragment>
    </div>
  );
}
