import React, { useEffect, useState } from "react";
import Pagination from "@material-ui/lab/Pagination";
import Box from "@material-ui/core/Box";
import { useDispatch, useSelector } from "react-redux";
import { LoadQuotationRequests } from "../../../../store/buying/buying.action";
import { Loader } from "../../../../components/loader";
import { QuoReqTable } from "../quotationReqTable";
import { FormControl, InputAdornment, OutlinedInput } from "@material-ui/core";
import { Filter } from "../../../../components/filter";
import { useTableStyles } from "../../../../styles/table.style";
import { useStyles } from "../../style";
import {
  FilterBy,
  GetFilterItems,
  GlobalfilterData,
} from "../../../../helpers/helper";
import { useTranslation } from "react-i18next";

export const QuotationRequest = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const tableclasses = useTableStyles();
  const { t } = useTranslation(["buying"]);
  const globalDefaults = useSelector((state) => state.Login.globalDefaults);
  const selectedData = useSelector((state) => state.Login.selectedData);
  const quotationReqList = useSelector(
    (state) => state.Buying.quotationReqList
  );
  const [listQuotationReqList, setListQuotationReqList] = useState([]);
  const [valueSearch, setValueSearch] = useState("");
  const maxitems = 20;
  const [page, setPage] = React.useState(1);
  const handleSearch = (event) => {
    setPage(1);
    setValueSearch(event.target.value);
    setListQuotationReqList(
      GlobalfilterData(quotationReqList, event.target.value, [
        "name",
        "transaction_date",
      ])
    );
  };
  useEffect(() => {
    if (globalDefaults?.default_company)
      dispatch(LoadQuotationRequests(globalDefaults?.default_company));
  }, [globalDefaults?.default_company]);

  if (!quotationReqList) return <Loader />;

  return (
    <Box>
      <Box className={classes.header}>
        <FormControl className={classes.formControlSearch} variant="outlined">
          <OutlinedInput
            id="outlined-adornment-weight"
            value={valueSearch}
            placeholder={t("common:rechercher")}
            onChange={handleSearch}
            endAdornment={
              <InputAdornment position="end">
                <span className="icon-search" />
              </InputAdornment>
            }
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              "aria-label": "weight",
            }}
            labelWidth={0}
          />
        </FormControl>
      </Box>
      <Box className={tableclasses.filterBlock}>
        <Box className={tableclasses.filter}>
          {/*  <Filter
            data={GetFilterItems([
              ...new Set(quotationReqList?.map((item) => item?.status)),
            ])}
            title={t("common:statut")}
            id={"status"}
            HandleFilter={HandleFilter}
            value={filters.status}
            number={filters.status.length}
          /> */}
        </Box>
      </Box>
      <Box>
        <QuoReqTable
          quotationReqList={
            valueSearch
              ? listQuotationReqList?.slice(
                  (page - 1) * maxitems,
                  page * maxitems
                )
              : quotationReqList?.slice((page - 1) * maxitems, page * maxitems)
          }
        />
        {Math.ceil(
          (valueSearch
            ? listQuotationReqList?.length
            : quotationReqList?.length) / maxitems
        ) > 0 && (
          <Box display="flex" justifyContent="center" m={4}>
            <Pagination
              onChange={(event, newPage) => setPage(newPage)}
              defaultPage={page}
              count={Math.ceil(
                (valueSearch
                  ? listQuotationReqList?.length
                  : quotationReqList.length) / maxitems
              )}
              color="primary"
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};
