import {
  UPDATE_THEME_STYLE,
  LOAD_THEMES,
  UPDATE_THEMES,
  ACTIVE_THEME,
  GLOBAL_COLOR,
  FREE_BUTTON_STYLE,
  SELECTED_PRODUCT,
  PRODUCT_STYLE,
  CALCULATOR_STYLE,
  THEMES_LOADED,
  GET_THEMES,
  UPDATE_STEP_THEME,
  UPDATE_STEP_THEME_SUCCESS,
  UPDATE_STATUS_THEME_SUCCESS,
  UPDATE_STATUS_THEME,
  UPDATE_STATUS_THEME_POS,
  UPDATE_STATUS_THEME_SUCCESS_POS,
  LOGO_THEME,  
  GET_THEMES_DB,
  UPDATE_FREEBUTTONS_THEME,
  UPDATE_FREEBUTTONS_THEME_SUCCESS,
} from "./theme.type";
import { FAILED_ADD_DATA } from "../rollback/rollback.type";
import { loadThemesData } from "../../pages/initialization/apiconfig";

const themes = [
  {
    freeButton: null,
    products: null,
    calculator: null,
    selectedProducts: null,
    active: 1,
    theme_id: 0,
    image: "/img/log_100.png",
    globalColors: {
      primary: "#2F4B7C",
      secondary: "#ECF2F5",
      customColor: "#0766FF",
      menu: "#2F334D",
      activeMenu: "#21253C",
    },
  },

  {
    active: 0,
    theme_id: 1,
    image: "/img/log_100.png",
    freeButton: {
      text: "#000000",
      background: "#f2f2f2",
      border: "#f7f7f7",
    },
    products: {
      text: "#000000",
      border: "#f7f7f7",
      background: "#f2f2f2",
    },
    calculator: {
      background: "#f2f2f2",
      text: "#000000",
      border: "#d6d8de",
    },
    selectedProducts: {
      background: "#f2f2f2",
      text: "#303030",
      border: "#009688",
      totalPrice: "#010101",
      button: "#010101",
    },
    globalColors: {
      primary: "#2b2b2b",
      secondary: "#f7f7f7",
      customColor: "#f21916",
      menu: "#010101",
      activeMenu: "#1a1a1a",
    },
  },

  {
    active: 0,
    theme_id: 2,
    image: "/img/log_100.png",
    freeButton: {
      text: "#000000",
      background: "#ffffff",
      border: "#d0e7eb",
    },
    products: {
      text: "#000000",
      border: "#f7f7f7",
      background: "#d0e7eb",
    },
    calculator: {
      background: "#d0e7eb",
      text: "#000000",
      border: "#bfd3db",
    },
    selectedProducts: {
      background: "#d0e7eb",
      text: "#303030",
      border: "#009688",
      totalPrice: "#010101",
      button: "#010101",
    },
    globalColors: {
      primary: "#2b2b2b",
      secondary: "#f6f6f6",
      customColor: "#d0e7eb",
      menu: "#000000",
      activeMenu: "#191919",
    },
  },
];

export const UpdateTheme = (data) => {
  return {
    type: UPDATE_THEME_STYLE,
    activeTheme: data,
  };
};

export const GetThemes = () => {
  return {
    type: LOAD_THEMES,
    themes: themes,
  };
};
export const GetThemesDb = (themes) => {
  return {
    type: GET_THEMES_DB,
    payload: themes,
  };
};

export const UpdateStepThemes = (content) => ({
  type: UPDATE_STEP_THEME,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${process.env.REACT_APP_API_URI}/api/themes/step/update`,
        method: "POST",
        body: JSON.stringify({
          theme_id: content.theme_id,
          activated: content.activated,
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
          Authorization: JSON.parse(localStorage.getItem("user")).token,
        },
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: UPDATE_STEP_THEME_SUCCESS,
        meta: {
          notification: {
            code: "success",
            message: "notification:themeLevelChanged",
          },
        },
      },
    },
  },
});

export const UpdateThemeById = (content, url, doctype) => {
  const action = {
    type: UPDATE_STATUS_THEME,
    payload: content,
    meta: {
      offline: {
        // the network action to execute:
        effect: {
          url: `${process.env.REACT_APP_API_URI}/api/${url}`,
          method: "POST",
          body: JSON.stringify({
            doctype: doctype,
            action: "update",
            docnames: content.name,
            data: {
              theme_id: content.theme_id,
            },
          }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
            Authorization: JSON.parse(localStorage.getItem("user")).token,
          },
        },
        // action to dispatch when effect succeeds:
        commit: {
          type: UPDATE_STATUS_THEME_SUCCESS,
          meta: {
            notification: {
              code: "success",
              message: "notification:themeWarehouseAdded",
            },
          },
        },
        rollback: { type: FAILED_ADD_DATA },
      },
    },
  };
  action.meta.offline.rollback.meta = action;
  return (dispatch, getState) => {
    dispatch(action);
  };
};

export const UpdateThemes = (data) => {
  return {
    type: UPDATE_THEMES,
    themes: data,
  };
};

export const ActiveThemes = (data) => {
  return {
    type: ACTIVE_THEME,
    activeTheme: data,
  };
};

export const globalColor = (data) => {
  return {
    type: GLOBAL_COLOR,
    globalColors: data,
  };
};

export const freeButtonStyle = (data) => {
  return {
    type: FREE_BUTTON_STYLE,
    freeButtonsStyles: data,
  };
};

export const selectedProduct = (data) => {
  return {
    type: SELECTED_PRODUCT,
    selectedProductStyle: data,
  };
};

export const productStyle = (data) => {
  return {
    type: PRODUCT_STYLE,
    productsStyle: data,
  };
};

export const calculatorStyle = (data) => {
  return {
    type: CALCULATOR_STYLE,
    calculatorStyle: data,
  };
};

export const logoImage = (data) => {
  return {
    type: LOGO_THEME,
    image: data,
  };
};

export const UpdatefreebuttonsThemes = (content) => ({
  type: UPDATE_FREEBUTTONS_THEME,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${process.env.REACT_APP_API_URI}/api/themes/freebuttons/update`,
        method: 'POST',
        body: JSON.stringify({
          theme_id: content.theme_id,
          freebuttons: content.freebuttons,
        }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-API-Key': `${process.env.REACT_APP_API_KEY}`,
          Authorization: JSON.parse(localStorage.getItem('user')).token,
        },
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: UPDATE_FREEBUTTONS_THEME_SUCCESS,
        meta: {
          notification: {
            code: 'success',
            message: 'notification:themefreebuttonsChanged',
          },
          then: (data) => async (dispatch, getState) => {
            await loadThemesData().then(response => {
              dispatch(GetThemesDb(response?.data));
            });
          },
        },
      },
    },
  },
});