import { FormatNotifications } from "../../helpers/utils";
import { MARK_NOTIFICATION_READ_SUCCESS, NEW_NOTIFICATION, NOTIFICATIONS_LOADED } from "./broadcastNotification.type";

const initialState = {
  notifications: []
};

const reducer = (state = initialState, action) => {
  let notifications = state.notifications;
  switch (action.type) {
    case NOTIFICATIONS_LOADED:
      return {
        ...state,
        notifications: action.notifications
      };
    case NEW_NOTIFICATION:
      notifications.unshift(action.notification[0]);
      return {
        ...state,
        notifications: notifications
      };
    case MARK_NOTIFICATION_READ_SUCCESS:
      notifications.filter((notification, index) => {
        if (notification.id == action.payload.id)
          notifications[index] = FormatNotifications([action.payload])[0]
      });
      return {
        ...state,
        notifications: notifications
      };
    default:
      return state;
  }
};

export default reducer;