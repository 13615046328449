import { Badge } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";

const drawerWidth = 155;

export const useStyles = makeStyles((theme) => ({
  "@global": {
    "*::-webkit-scrollbar": {
      width: "4px",
    },
    "*::-webkit-scrollbar-thumb": {
      backgroundColor: "#d3dbe1",
      width: "4px",
      borderRadius: 10,
      opacity: 0.2,
    },
  },
  root: {
    display: "grid",
    gridTemplateColumns: "0.5fr 12.2fr",
    gridTemplateRows: "0fr 0fr 1fr",
    gap: "0px 0px",
    minHeight: "94.7vh",
    gridTemplateAreas:
      "'menu content content''menu content content''menu content content'",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    minHeight: 680,
    gridArea: "menu",
    "& .MuiDrawer-paperAnchorDockedLeft": {
      borderRight: 0,
    },
  },
  drawerOpen: {
    width: drawerWidth,
    border: 0,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    border: 0,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(7) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    gridArea: "content",
    paddingLeft: 14,
  },
  menu: {
    backgroundColor: theme.palette.menuColor,
    height: "100%",
    borderRadius: 19,
    color: theme.palette.white.main,
    marginTop: 8,
    paddingBottom: 74,
  },
  IconButton: {
    marginLeft: 0,
    marginBottom: 0,
    marginTop: "-20px",
  },
  listItemText: {
    fontSize: "0.8125rem",
  },
  button: {
    padding: "0.375rem 0.25rem",
    fontSize: "0.8125rem",
    borderBottom: "1px solid",
    borderBottomColor: theme.palette.white.main,
    borderRadius: 0,
    color: theme.palette.white.main,
    opacity: 0.4,
    "&:focus": {
      background: "none",
    },
  },
  active: {
    borderBottom: "2px solid",
    borderBottomColor: theme.palette.white.main,
    opacity: 1,
  },
  list: {
    paddingRight: "0.5rem",
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
  listItem: {
    paddingRight: 0,
    opacity: 0.7,
    paddingBottom: 3,
    paddingTop: 3,
    marginBottom: 0,
    borderRadius: "0px 7px 7px 0px",
    borderLeft: "5px solid transparent",
    "&.Mui-selected": {
      borderLeftColor: theme.palette.blue.main,
      backgroundColor: theme.palette.activeMenu,
      opacity: 1,
    },
  },
  tabs: {
    marginBottom: 14,
    marginTop: 10,
    display: "flex",
    justifyContent: "center",
  },
  userRoot: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    background: theme.palette.activeMenu,
    borderRadius: 19,
    padding: 10,
    minWidth: 115,
  },
  user: {
    marginTop: 12,
    marginBottom: 8,
    fontSize: 14,
    textTransform: "capitalize",
  },
  cashier: {
    fontSize: 12,
    opacity: 0.5,
  },
  pageTitleBadge: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 5,
    marginBottom: 10,
  },
  pageTitle: {},
  menuIcon: {
    minWidth: 27,
    color: "#657288",
  },
  menuIconExpand: {
    minWidth: 18,
    color: "#657288",
  },
  activeIcon: {
    color: "#FFFFFF",
  },
  dateTimmeContainer: {
    position: "relative",
    marginTop: -170,
    justifyContent: "flex-end",
  },
  dateTimeContainer: {
    position: "relative",
    marginTop: -38,
    justifyContent: "flex-end",
  },
  dateContainer: {
    position: "relative",
    justifyContent: "flex-end",
  },

  cursorIcon: {
    cursor: "pointer",
    marginTop: 10,
  },
  avatarOpen: {
    transition: "all 0.3s ease",
    textTransform: "uppercase",
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  avatarClose: {
    transition: "all 0.3s ease",
    textTransform: "uppercase",
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 10,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  dropDownContainer: {
    position: "relative",
    marginTop: -40,
    justifyContent: "flex-end",
  },
  box: {
    fontSize: 14,
    color: theme.palette.primary.main,
    [theme.breakpoints.up("md")]: {
      paddingLeft: 20,
    },
  },
  profile: {
    marginTop: "-20px",
  },
  icon: {
    color: theme.palette.primary.main,
    opacity: 1,
    cursor: "pointer",
    height: 30,
    width: 30,
    transition: "transform 0.2s ease-in-out",
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
  imageUpload: {
    display: "none",
  },
  openMenuIcon: {
    transition: "0.2s",
    height: 20,
  },
  openMenuIconTwo: {
    transition: "0.2s",
    transform: "rotate(180deg)",
    height: 20,
  },
  cashierChip: {
    marginBottom: 8,
    borderRadius: 6,
    backgroundColor: theme.palette.warning.main,
  },
  managerChip: {
    marginBottom: 8,
    borderRadius: 6,
    backgroundColor: theme.palette.success.main,
  },
  icons: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 5,
  },
  iconBtn: {},
  header: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    gap: 5,
  },
  infoHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    gap: 10,
  },
  infoHeaderCheckout: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: 10,
  },
  checkoutIconSelectors: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  checkoutISelectors: {
    display: "flex",
    flexDirection: "row",
    gap: 10,
  },
}));
