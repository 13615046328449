export const UPDATE_THEME_STYLE = "UPDATE_THEME_STYLE";
export const LOAD_THEMES = "LOAD_THEMES";
export const UPDATE_THEMES = "UPDATE_THEMES";
export const ACTIVE_THEME = "ACTIVE_THEME";
export const GLOBAL_COLOR = "GLOBAL_COLOR";
export const FREE_BUTTON_STYLE = "FREE_BUTTON_STYLE";
export const SELECTED_PRODUCT = "SELECTED_PRODUCT";
export const PRODUCT_STYLE = "PRODUCT_STYLE";
export const CALCULATOR_STYLE = "CALCULATOR_STYLE";
export const THEMES_LOADED = "THEMES_LOADED";
export const GET_THEMES = "GET_THEMES";
export const GET_THEMES_DB = "GET_THEMES_DB";
export const UPDATE_STEP_THEME = "UPDATE_STEP_THEME";
export const UPDATE_STEP_THEME_SUCCESS = "UPDATE_STEP_THEME_SUCCESS";
export const UPDATE_STATUS_THEME = "UPDATE_STATUS_THEME";
export const UPDATE_STATUS_THEME_SUCCESS = "UPDATE_STATUS_THEME_SUCCESS";
export const UPDATE_STATUS_THEME_POS = "UPDATE_STATUS_THEME_POS";
export const UPDATE_STATUS_THEME_SUCCESS_POS = "UPDATE_STATUS_THEME_SUCCESS_POS";
export const LOGO_THEME = "LOGO_THEME";
export const UPDATE_FREEBUTTONS_THEME="UPDATE_FREEBUTTONS_THEME";
export const UPDATE_FREEBUTTONS_THEME_SUCCESS="UPDATE_FREEBUTTONS_THEME_SUCCESS";