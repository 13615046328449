import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  main: {
    padding: "0px 50px",
  },
  header: {
    padding: "30px 0",
  },
  logo: {
    textAlign: "center",
    marginBottom: 10,
  },
  title: {
    borderTop: "1px solid  #5D6975",
    borderBottom: "1px solid  #5D6975",
    color: "#5D6975",
    fontSize: "30px",
    lineHeight: "1.4em",
    fontWeight: "normal",
    textAlign: "center",
    margin: "0 0 20px 0",
  },
  root: {
    flexGrow: 1,
  },
  card: {
    minWidth: 200,
  },
  titlecard: {
    borderTop: "1px solid  #5D6975",
    borderBottom: "1px solid  #5D6975",
    color: "#5D6975",
    fontSize: "20px",
    lineHeight: "1.4em",
    fontWeight: "normal",
    textAlign: "center",
    margin: "0 0 20px 0",
  },
  paper: {
    overflow: "auto",
    padding: "0px 10px",
  },
  status: {
    padding: "5px 10px",
    marginTop: 20,
  },
  company: {
    float: "left",
    textAlign: "left",
  },
}));
