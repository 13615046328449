import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  titleblock: {
    position: "relative",
    borderBottom: "0.0625rem solid #f3f4f6",
    padding: "0.8125rem 0rem 1.75rem 0rem",
  },
  backicon: {
    position: "absolute",
    top: 18,
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
  chequeInput: {
    height: 45,
    marginTop: 5,
  },
  block: {
    padding: "0rem 1.75rem",
    marginBottom: "6.9375rem",
  },
  title: {
    fontWeight: 600,
  },
  priceblock: {
    padding: "1.5625rem 0rem 2.125rem",
  },
  total: {
    fontSize: "1.8125rem",
    fontWeight: "bold",
  },
  buttonRoot: {
    "& > *": {
      margin: theme.spacing(1),
    },
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  icon: {
    width: 30,
    height: 30,
    color: theme.palette.freeButton.text,
  },

  button: {
    backgroundColor: theme.palette.freeButton.background,
    color: theme.palette.freeButton.text,
    border: "1px solid",
    borderColor: theme.palette.freeButton.border,
    padding: "1.25rem",
    width: "35%",
    fontSize: "1.0625rem",
    fontWeight: 600,
    "&:hover": {
      backgroundColor: theme.palette.freeButton.background,
    },
  },
  depositBlock: {
    display: "flex",
    justifyContent: "space-between",
    padding: 10,
    margin: 10,
    alignItems: "center",
    backgroundColor: theme.palette.freeButton.background,
    border: "1px solid",
    borderColor: theme.palette.freeButton.border,
    borderRadius: 10,
    boxShadow: `0px 1px 7px 1px ${theme.palette.freeButton.border}`,
  },
  depositItem: {
    justifyContent: "space-between",
    display: "flex",
    width: "100%",
    alignItems: "center",
    flexWrap: "wrap",
    borderLeft: `0.25rem solid ${theme.palette.freeButton.border}`,
    padding: "0px 10px",
  },
  totalItem: {
    borderLeft: `0.25rem solid ${theme.palette.freeButton.border}`,
    padding: "0px 10px",
  },
  depositItemText: {
    fontSize: "1.0625rem",
  },
  priceResult: {
    margin: "auto",
    marginTop: 0,
  },
  returnedAmount: {
    fontSize: "3.625rem",
    color: theme.palette.blue.main,
    marginTop: "0.4375rem",
    marginBottom: "10px",
  },
  footer: {
    marginLeft: "10px",
    marginRight: "10px",
    marginTop: "15px",
    padding: "12px 77px",
    fontSize: 23,
    backgroundColor: theme.palette.productsTable.button,
    color: theme.palette.productsTable.buttonColor,
    "&:hover": {
      backgroundColor: theme.palette.productsTable.button,
    },
  },
  priceResultBlock: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "row",
    gap: 20,
  },
  calculator: {
    [theme.breakpoints.up("md")]: {
      marginLeft: "1.875rem",
    },
    margin: 10,
  },
  totalLight: {
    color: "#042765",
    opacity: 0.38,
    fontSize: "1.0625rem",
  },
  numberLight: {
    fontSize: "1.4375rem",
  },
  ticketBlock: {
    backgroundColor: "#ffffff",
    textAlign: "center",
    boxShadow: "0px 5px 15px 4px #0000000F",
    [theme.breakpoints.up("md")]: {
      minWidth: 420,
    },
    fontFamily: "Courier Prime",
    color: "#707070",
    fontSize: 14,
    padding: "2rem 0.5rem",
    lineHeight: 2,
    borderRadius: 10,
  },
  ticketHeader: {
    marginBottom: "7px",
    textAlign: "center",
    fontWeight: "bold",
  },
  ticketProducts: {
    display: "flex",
    padding: "10px 38px 10px 28px",
  },
  productRef: {
    float: "left",
    width: "4rem",
    overflowWrap: "anywhere",
  },
  productTotal: {
    marginLeft: "1rem",
    float: "right",
  },
  productDetails: {
    marginLeft: "1rem",
  },
  productsborder: {
    borderBottom: "1px solid #707070",
    paddingBottom: "1.375rem",
  },
  ProductsNumber: {
    paddingLeft: "1.75rem",
  },
  ticketFooter: {
    marginTop: "2.375rem",
  },
  ticket: {
    margin: 20,
    position: "relative",
    padding: "8px 8px 32px 8px",
    borderRadius: 10,
    background: "#707070",
    "&:after": {
      background: `linear-gradient(-45deg, #ffffff 16px, transparent 0), 
                    linear-gradient(45deg, #ffffff 16px, transparent 0)`,
      backgroundRepeat: "repeat-x",
      backgroundSize: "32px 32px",
      content: '""',
      display: "block",
      position: "absolute",
      bottom: 0,
      left: 0,
      width: "100%",
      height: "32px",
    },
  },
  clearIcon: {
    cursor: "pointer",
  },
  resultPage: {
    margin: "0px 20px",
  },
  logo: {},
  tableCell: {
    padding: "7px 16px",
  },
  textLight: {
    color: theme.palette.gray.main,
    fontSize: 12,
    fontWeight: 500,
    marginLeft: 33,
    marginBottom: 10,
  },
  formControl: {
    // maxWidth: 300,
    marginLeft: 20,
    "& .MuiOutlinedInput-input": {
      padding: "8px 14px !important",
      fontSize: 12,
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: 10,
    },
  },
  formSelect: {
    marginBottom: 50,
    "& .MuiInputBase-root": {
      background: theme.palette.white.main,
    },
    "& .MuiOutlinedInput-input": {
      padding: "8.9px 14px",
      fontSize: 12,
    },
    width: "20%",
    // margin: theme.spacing(1),
  },
  label: {
    fontSize: 12,
    marginBottom: 50,
    color: theme.palette.gray.main,
  },
  paper: {
    position: "absolute",
    width: 600,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 10,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  print: {
    display: "none",
  },
  box: {
    color: "#2F4B7C",
    position: "absolute",
    marginLeft: "inherit",
  },
  displayed: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
    width: "400px",
    paddingTop: "10px",
  },
  duplicataLogo: {
    width: 150,
    height: 40,
  },
  root: {
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
  label: {
    fontSize: 12,
    color: theme.palette.gray.main,
    marginBottom: 6,
    textAlign: "start",
  },
  firstCol: {
    [theme.breakpoints.up("md")]: {
      marginRight: 37,
    },
  },
  formBlock: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
    },
  },
  formControls: {
    marginBottom: 15,
    "& .MuiInputBase-root": {
      background: theme.palette.white.main,
    },
    "& .MuiOutlinedInput-input": {
      padding: "8.9px 14px",
      fontSize: 12,
    },
  },
  alertColor: {
    color: theme.palette.blue.main,
  },
  modalPaper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 10,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  table: {
    borderCollapse: "separate",
    borderSpacing: "0 5px",
    "& .MuiTableRow-root": {
      display: "table",
      width: "100%",
      tableLayout: "fixed",
    },
  },
  tableBody: {
    display: "block",
    height: 200,
    overflow: "auto",
  },
  tableRow: {
    height: 25,
    "& .MuiTableCell-root:first-child": {
      borderTopLeftRadius: 5,
      borderBottomLeftRadius: 5,
    },
    "& .MuiTableCell-root:last-child": {
      borderTopRightRadius: 5,
      borderBottomRightRadius: 5,
    },
  },
}));
