import { GlobalfilterData } from "../../helpers/helper";
import { LOADING_CREDIT_NOTES } from "../checkout/checkout.type";
import { CLEAR_STATE } from "../login/login.type";
import {
  CREATE_CLIENT_SUCCESS,
  LOAD_FILTRED_CLIENTS,
  CLIENTS_LOADED,
  CLIENT_CREATED_BRODCAST,
  LOYALTY_PROGRAMS_LOADED,
  PAYMENT_ENTRIES_LOADED,
  RETURNS_LOADED,
  UNPAID_SALES_INVOICE_LOADED,
  SELECTED_CLIENT_LOADED,
  ORDERS_LOADED,
  LOYALTY_POINTS_LOADED,
  CURRENCIES_LOADED,
  ACCOUNT_BY_CURRENCY_LOADED,
  CUSTOMERS_GROUP_LOADED,
  CREATE_ADDRESS_CUSTOMER_SUCCESS,
  INVOICES_LOADED,
  SET_ORDER,
  CANCEL_ORDER,
  SET_INVOICE_DETAIL,
  LOADED_GIFT_CARDS,
  PERSONNAL_MSG_TOGGLED,
  CLIENT_COUPON_CODE_LOADED,
  SET_CLIENT_PRICING_RULE,
  COUPON_CODES_LOADED,
  SEC_SET_DETAIL_CLIENT,
  COUPON_CODES_LIST_LOADED,
  CUSTOMER_LEDGER_LOADED,
  CLIENT_AVOIR_ACCOUNT_LOADED,
  INITIALISATION_DETAILS_CLIENT,
} from "./client.type";
const initialState = {
  client: null,
  clients: [],
  filtredClients: [],
  loyaltyPrograms: [],
  clientLedger: null,
  paymentEntries: [],
  giftCard: [],
  createdGiftCard: null,
  returns: [],
  UnpaidsalesInvoice: [],
  orders: [],
  invoices: [],
  loyaltyPoints: [],
  currencies: [],
  partyAccount: [],
  customersgroup: [],
  addressCustomer: null,
  card: [],
  order: null,
  cards: [],
  showPersonnalMsg: false,
  stateCard: false,
  clientCreditNotes: [],
  detailCreditNote: [],
  couponCodes: [],
  avoirsAccount: null,
  pricingRuleClient: null,
  giftCardCouponCode: [],
};

const reducer = (state = initialState, action) => {
  var clients = state.clients;
  switch (action.type) {
    case CREATE_CLIENT_SUCCESS:
      clients.unshift(JSON.parse(action.payload).message);
      return {
        ...state,
        clients: clients,
        client: JSON.parse(action.payload),
      };
    case CLIENT_CREATED_BRODCAST:
      clients.unshift(action.client);
      return {
        ...state,
        clients: clients,
        client: action.client,
      };
    case CLIENTS_LOADED:
      return {
        ...state,
        clients: Array.isArray(action.clients) ? action.clients : [],
        filtredClients: action.clients,
      };
    case LOAD_FILTRED_CLIENTS:
      if (action.search !== "") {
        clients = GlobalfilterData(clients, action.search, [
          "email_id",
          "customer_name",
          "mobile_no",
        ]);
      }
      return {
        ...state,
        filtredClients: clients,
      };
    case LOYALTY_PROGRAMS_LOADED:
      return {
        ...state,
        loyaltyPrograms: action.loyaltyPrograms,
      };
    case PAYMENT_ENTRIES_LOADED:
      return {
        ...state,
        paymentEntries: action.payload,
      };
    case RETURNS_LOADED:
      return {
        ...state,
        returns: action.payload,
      };
    case UNPAID_SALES_INVOICE_LOADED:
      return {
        ...state,
        UnpaidsalesInvoice: action.payload,
      };
    case SELECTED_CLIENT_LOADED:
      return {
        ...state,
        client: action.client,
      };
    case ORDERS_LOADED:
      return {
        ...state,
        orders: action.payload,
      };
    case SET_ORDER:
      return {
        ...state,
        order: action.order,
      };
    case CANCEL_ORDER:
      return {
        ...state,
        order: null,
      };
    case LOYALTY_POINTS_LOADED:
      return {
        ...state,
        loyaltyPoints: action.payload,
      };
    case CURRENCIES_LOADED:
      return {
        ...state,
        currencies: action.currencies,
      };
    case ACCOUNT_BY_CURRENCY_LOADED:
      return {
        ...state,
        partyAccount: action.payload,
      };
    case CUSTOMERS_GROUP_LOADED:
      return {
        ...state,
        customersgroup: action.customersgroup,
      };
    case CREATE_ADDRESS_CUSTOMER_SUCCESS:
      return {
        ...state,
        addressCustomer: JSON.parse(action.payload).message,
      };
    case INVOICES_LOADED:
      return {
        ...state,
        invoices: action.payload,
      };
    case PERSONNAL_MSG_TOGGLED:
      return {
        ...state,
        showPersonnalMsg: action.showPersonnalMsg,
      };
    case SET_INVOICE_DETAIL:
      return {
        ...state,
        detailInvoice: action.detailInvoice,
      };
    case CLIENT_COUPON_CODE_LOADED:
      return {
        ...state,
        couponCodes: action.payload,
      };
    case CLIENT_AVOIR_ACCOUNT_LOADED:
      return {
        ...state,
        avoirsAccount: action.payload,
      };
    case SET_CLIENT_PRICING_RULE:
      return {
        ...state,
        pricingRuleClient: action.pricingRuleClient,
      };
    case COUPON_CODES_LOADED:
      return {
        ...state,
        giftCardCouponCode: action.giftCardCouponCode,
      };
    case COUPON_CODES_LIST_LOADED:
      return {
        ...state,
        giftCardCouponCode: action.payload,
      };
    case SEC_SET_DETAIL_CLIENT:
      return {
        ...state,
        client: action.detailClient,
      };
    case CUSTOMER_LEDGER_LOADED:
      return {
        ...state,
        clientLedger: action.payload,
      };
    case INITIALISATION_DETAILS_CLIENT:
      return {
        ...state,
        paymentEntries: [],
        orders: [],
        avoirsAccount: [],
        giftCardCouponCode: [],
        loyaltyPoints: [],
      };
    case CLEAR_STATE:
      return (state = initialState);
    default:
      return state;
  }
};

export default reducer;
