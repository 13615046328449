import { loadNotificationsData } from "../pages/initialization/api";
import {
  LoadNotifications,
  NewNotificationBrodcast,
} from "../store/broadcastNotification/broadcastNotification.action";
import { ClientCreatedBrodcast } from "../store/client/client.action";
import { OrderCreatedBrodcast } from "../store/fullfilment/fullfilment.action";

export const Brodcast = (dispatch) => {
  const BrodcastData = (data) => {
    switch (data.key) {
      case "customer created":
        dispatch(ClientCreatedBrodcast(data.response.message));
        break;
      case "sales order created":
        dispatch(OrderCreatedBrodcast(data.response.message));
        break;
      case "notification":
        loadNotificationsData()
          .then((response) => {
            dispatch(LoadNotifications(response.data));
          })
          .catch((err) => {
            console.log(err);
          });
        break;
      default:
        return;
    }
  };

  return { BrodcastData };
};
