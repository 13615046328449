import React from "react";
import {
  Grid,
  Tooltip,
  Card,
  CardContent,
  Box,
  Typography,
  IconButton,
} from "@material-ui/core";
import { useStyles } from "./style.js";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import AddIcon from "@material-ui/icons/Add";

const CardComponent = (props) => {
  const classes = useStyles();
  const { t } = useTranslation(["fulfillment"]);
  const { type, setDetails, pack, isAddPack, toggleMsg,isActive,setIsActive,id } = props;

  const handleClick = () => {
    setIsActive(id);
    pack && setDetails(pack);

  };
  const tooltip = (
    <Card>
      <CardContent>
        <Typography>{t("fulfillment:height")}: {pack?.height}</Typography>
        <Typography>{t("fulfillment:length")}: {pack?.length}</Typography>
        <Typography>{t("fulfillment:weight")}: {pack?.weight}</Typography>
        <Typography>{t("fulfillment:width")}: {pack?.width}</Typography>
      </CardContent>
    </Card>
  );

  return (
    <Grid
      item
      xs={12}
      sm={3}
      onClick={handleClick}
      className={isActive === id ? clsx(classes.selectCard,classes.cursor) : classes.cursor}
    >
      <Tooltip title={pack ? tooltip :""}>
        <Card
          className={clsx(classes.card, classes.cardBoldText)}
          variant="outlined"
        >
          <CardContent >
            <Box className={classes.cardTitleBlock}>
              <Typography
                className={clsx(classes.cardTitle, classes.cardBoldText)}
              >
                {type}
              </Typography>
              {isAddPack && (
                <Tooltip title="Ajouter un pack">
                  <IconButton className={classes.icon}>
                    <AddIcon
                      color="primary"
                      onClick={() => toggleMsg()}
                    />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </CardContent>
        </Card>
      </Tooltip>
    </Grid>
  );
};

export default CardComponent;
