import React, { useEffect, useRef } from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Button,
  Grid,
  Checkbox,
  FormControlLabel,
  Modal,
  FormGroup,
  Divider,
  InputLabel,
} from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import SystemUpdateAltIcon from "@material-ui/icons/SystemUpdateAlt";
import clsx from "clsx";
import { useHistory, useParams } from "react-router-dom";
import { useTableStyles } from "../../../../styles/table.style";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import ReactToPrint from "react-to-print";
import * as XLSX from "xlsx";
import {
  LoadInvListById,
  LoadStockDataReport,
  UpdateListStatus,
  SetListToEdit,
  UpdateList,
  CreateStockReconcialiation,
  UploadStockDataReport,
  SetCheckedListItem,
  UpdateParams,
} from "../../../../store/inventory/inventory.action";
import { useTheme } from "@material-ui/core/styles";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  MenuItem,
  Select,
  FormControl,
} from "@material-ui/core";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { InventoryDetailsTable } from "../InventoryDetailsTable";
import { Loader } from "../../../../components/loader";
import { SetNotification } from "../../../../store/notification/notification.action";

const useStyles = makeStyles((theme) => ({
  titleblock: {
    position: "relative",
    borderBottom: "0.0625rem solid #f3f4f6",
    padding: "0.8125rem 0rem 1.75rem 0rem",
  },
  closeicon: {
    position: "absolute",
    top: 18,
    color: "#042765",
    width: 23,
    height: 23,
    cursor: "pointer",
  },
  block: {
    padding: "0rem 1.75rem",
    marginBottom: "6.9375rem",
  },
  title: {
    fontWeight: 600,
  },
  tablePaper: {
    padding: "0rem 2rem !important",
  },
  discInput: {
    width: 100,
    "& .MuiInputBase-formControl": {
      marginTop: "-4px",
    },
    "& .MuiInputBase-input": {
      textAlign: "center",
    },
  },
  qtyControl: {
    position: "relative",
    width: "100px",
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
    display: "flex",
    justifyContent: "flex-end",
  },
  print: {
    display: "none",
  },
  editBtn: {
    marginLeft: 10,
  },
  adjstBtn: {
    height: 30,
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 10,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  formControl: {
    marginBottom: 15,
    "& .MuiInputBase-root": {
      background: theme.palette.white.main,
    },
    "& .MuiOutlinedInput-input": {
      padding: "8.9px 14px",
      fontSize: 12,
    },
  },
  select: {
    marginBottom: 15,
    "& .MuiInputBase-root": {
      background: theme.palette.white.main,
    },
    "& .MuiOutlinedInput-input": {
      padding: "8.9px 8px",
      fontSize: 12,
    },
  },
}));

export const InventoryDetails = () => {
  const classes = useStyles();
  const history = useHistory();
  const componentRef = useRef();
  const theme = useTheme();
  const tableclasses = useTableStyles();
  const globalDefaults = useSelector((state) => state.Login.globalDefaults);
  const selectedData = useSelector((state) => state.Login.selectedData);
  const companyacounts = useSelector(
    (state) => state.Configuration.companyacounts
  );
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const stockSummary = useSelector((state) => state.Inventory.stockQtyReport);
  const paramsList = useSelector((state) => state.Inventory.params);
  const products = useSelector((state) => state.Product.products);
  const itemlocation = useSelector((state) => state.Fullfilment.itemLocation);
  const { id } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation([
    "inventory",
    "common",
    "product",
    "fulfillment",
    "hold",
  ]);
  const invList = useSelector((state) => state.Inventory.invList);
  const [foundQuantity, setFoundQuantity] = React.useState([]);
  const [setting, setSetting] = React.useState({
    description: false,
    group: false,
    price: false,
    collection: false,
    location: false,
    openingStock: false,
    originCountry: false,
    isStock: false,
    ref: true,
    qteReel: true,
    qteProj: false,
    qteResv: false,
    magasin: true,
    qteTrouv: true,
    nameProd: true,
    all: false,
  });
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [motifs, setMotifs] = React.useState(t("inventory:Comptagephy"));
  const [importedData, setImportedData] = React.useState(null);
  const MotifReasonList = useSelector(
    (state) => state.Configuration.motifInventory
  );

  const ChangeMotifs = (event) => {
    setMotifs(event.target.value);
  };
  const [ecart, setEcart] = React.useState("");

  const handleChangeSelect = (event) => {
    setEcart(event.target.value);
  };
  const [filterList, SetFilterList] = React.useState([]);

  const handleEcartNegative = () => {
    var arr = [];
    var stockList = [];
    invList &&
      invList.products.forEach((element) => {
        const data = {
          item_code: element.item_code,
          qty_before: element.qty_before,
          found_qty: parseInt(element.found_qty),
          ecart: parseInt(element.found_qty) - element.qty_before,
        };
        arr.push(data);
      });
    var res = arr.sort((a, b) => a.ecart - b.ecart);
    var list = res.filter((filter) => filter.ecart < 0);
    list &&
      list.forEach((element) => {
        const stock = stockSummary.find(
          (s) => s.item_code == element.item_code
        );
        stockList.push(stock);
        SetFilterList(stockList);
      });
  };
  const handleEcartPositive = () => {
    var arrPos = [];
    var stockListPos = [];
    invList &&
      invList.products.forEach((element) => {
        const data = {
          item_code: element.item_code,
          qty_before: element.qty_before,
          found_qty: parseInt(element.found_qty),
          ecart: parseInt(element.found_qty) - element.qty_before,
        };
        arrPos.push(data);
      });
    var resPos = arrPos.sort((a, b) => a.ecart - b.ecart);
    var listPos = resPos.filter((filter) => filter.ecart > 0);
    listPos &&
      listPos.forEach((element) => {
        const stock = stockSummary.find(
          (s) => s.item_code == element.item_code
        );
        stockListPos.push(stock);
        SetFilterList(stockListPos);
      });
  };

  const emptyFilter = () => {
    SetFilterList(ecart);
  };

  const handleChange = (event) => {
    if (event.target.name == "all") {
      const data = { ...setting };
      Object.keys(data).forEach((key) => {
        data[key] = event.target.checked;
      });
      setSetting(data);
    } else {
      setSetting({ ...setting, [event.target.name]: event.target.checked });
    }
  };

  const handleChangecheck = () => {
    dispatch(UpdateParams(paramsList));
    setSetting(paramsList);
  };

  const handleClose = () => {
    setOpen(false);
    handleChangecheck();
  };
  const returnList = () => {
    const state = {
      description: false,
      group: false,
      price: false,
      collection: false,
      location: false,
      openingStock: false,
      originCountry: false,
      isStock: false,
      ref: true,
      qteReel: true,
      qteProj: false,
      qteResv: false,
      magasin: true,
      qteTrouv: true,
      nameProd: true,
      all: false,
    };
    dispatch(UpdateParams(state));
    history.push("/inventory");
  };

  const handleParams = () => {
    setOpen(false);
    dispatch(UpdateParams(setting));
  };

  const handleUpdateStatus = () => {
    const data = {
      list_id: id,
      status: "In Progress",
    };
    dispatch(UpdateListStatus(data));
    refreshPage();
  };
  const refreshPage = () => {
    setTimeout(() => {
      window.location.reload(false);
    }, 500);
  };
  const handleValidation = () => {
    const data = {
      list_id: id,
      products: foundQuantity,
      status: "Finished",
    };
    dispatch(UpdateListStatus(data));
    refreshPage();
  };

  const HandleFoundQuantity = (e, row) => {
    var data = {
      item_code: row,
      found_qty: e.target.value,
      qty_before: stockSummary.find((s) => s.item_code == row)
        ? stockSummary.find((s) => s.item_code == row).actual_qty
        : "",
    };
    var itemIndex = foundQuantity.findIndex((p) => p.item_code == row);
    if (itemIndex == -1) {
      setFoundQuantity([...foundQuantity, data]);
    } else {
      const state = [...foundQuantity];
      state[itemIndex].found_qty = e.target.value;
      setFoundQuantity(state);
    }
  };

  const handleEditList = () => {
    dispatch(SetListToEdit(invList));
    history.push("/inventory-list-generator");
  };

  const handleCancelValidation = () => {
    var items = [];
    invList.products.map((p, i) => {
      items.push(p.item_code);
    });
    const data = {
      list_id: id,
      products: items,
      status: "In Progress",
    };
    dispatch(UpdateListStatus(data));
    refreshPage();
  };
  const TotalStockAdjust = () => {
    const d = new Date();
    var items = [];
    invList.products.forEach((element) => {
      const item = {
        docstatus: 0,
        doctype: "Stock Reconciliation Item",
        __islocal: 1,
        __unsaved: 1,
        allow_zero_valuation_rate: 1,
        current_qty: element.qty_before,
        parentfield: "items",
        parenttype: "Stock Reconciliation",
        idx: 1,
        item_code: element.item_code,
        warehouse: invList && invList.warehouse,
        qty: parseInt(element.found_qty),
      };
      if (item.qty != item.current_qty) {
        items.push(item);
      }
    });
    const data = {
      doc: {
        docstatus: 0,
        doctype: "Stock Reconciliation",
        __islocal: 1,
        __unsaved: 1,
        naming_series: "MAT-RECO-.YYYY.-",
        company: `${globalDefaults?.default_company}`,
        purpose: "Stock Reconciliation",
        posting_date: d.getFullYear() + "-" + d.getMonth() + "-" + d.getDate(),
        set_posting_time: 0,
        items: items,
        reason: motifs,
        expense_account: `${companyacounts?.stock_adjustment_account}`,
        cost_center: `${companyacounts?.cost_center}`,
        posting_time:
          d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds(),
      },
      action: "Submit",
    };
    dispatch(CreateStockReconcialiation(data));
  };
  const handleStockAdjust = (row, motif) => {
    const d = new Date();
    const data = {
      doc: {
        docstatus: 0,
        doctype: "Stock Reconciliation",
        __islocal: 1,
        __unsaved: 1,
        naming_series: "MAT-RECO-.YYYY.-",
        company: `${globalDefaults?.default_company}`,
        purpose: "Stock Reconciliation",
        posting_date: d.getFullYear() + "-" + d.getMonth() + "-" + d.getDate(),
        set_posting_time: 0,
        items: [
          {
            docstatus: 0,
            doctype: "Stock Reconciliation Item",
            __islocal: 1,
            __unsaved: 1,
            allow_zero_valuation_rate: 1,
            current_qty: stockSummary.find((s) => s.item_code == row.item_code)
              ? stockSummary.find((s) => s.item_code == row.item_code)
                  .actual_qty
              : "",
            parentfield: "items",
            parenttype: "Stock Reconciliation",
            idx: 1,
            item_code: row.item_code,
            warehouse: invList && invList.warehouse,
            qty: parseInt(row.found_qty),
          },
        ],
        reason: motif,
        expense_account: `${companyacounts?.stock_adjustment_account}`,
        cost_center: `${companyacounts?.cost_center}`,
        posting_time:
          d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds(),
      },
      action: "Submit",
    };
    dispatch(CreateStockReconcialiation(data));
  };

  const handleClosingList = () => {
    const data = {
      list_id: id,
      status: "Closed",
    };
    dispatch(UpdateListStatus(data));
    refreshPage();
  };

  const fetchFromProd = (row) => {
    return products.find((s) => s.item_code == row)
      ? products.find((s) => s.item_code == row)
      : {};
  };

  const fetchPrice = (row) => {
    return products.find((s) => s.item_code == row)
      ? products.find((s) => s.item_code == row).standard_rate.toFixed(3) ||
          products.find((s) => s.item_code == row).price_list_rate.toFixed(3)
      : 0.0;
  };
  const ajust = (row) => {
    if (stockSummary.find((s) => s.item_code == row.item_code)) {
      if (
        stockSummary.find((s) => s.item_code == row.item_code).actual_qty -
          row.found_qty >=
        0
      ) {
        return (
          row.found_qty -
          stockSummary.find((s) => s.item_code == row.item_code).actual_qty
        );
      } else {
        return (
          "+" +
          (row.found_qty -
            stockSummary.find((s) => s.item_code == row.item_code).actual_qty)
        );
      }
    } else {
      return null;
    }
  };

  const handleDownloadList = () => {
    var table = document.getElementById("table-to-download");
    const arr = [...table.rows].map((r) =>
      [...r.querySelectorAll("td, th")].map((td) => td.textContent)
    );
    const data = {
      arr: JSON.stringify(arr),
      list_id: id,
    };
    dispatch(UploadStockDataReport(data));
  };

  const handleUploadList = (e) => {
    var file = e.target.files[0];
    if (!file) {
      dispatch(
        SetNotification({
          code: "error",
          message: "inventory:emptyFile",
        })
      );
      return;
    }
    if (
      file.type !=
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      dispatch(
        SetNotification({
          code: "error",
          message: "inventory:ValidateFile",
        })
      );
      return;
    }
    const reader = new FileReader();

    reader.onload = function (e) {
      var data = new Uint8Array(e.target.result);
      var workbook = XLSX.read(data, { type: "array" });
      var firstSheet = workbook.Sheets[workbook.SheetNames[0]];
      var result = XLSX.utils.sheet_to_json(firstSheet, {
        header: 1,
        raw: true,
        defval: null,
      });

      setImportedData(JSON.parse(JSON.stringify(result, null, 2)));
    };
    dispatch(
      SetNotification({
        code: "success",
        message: "inventory:ImportFile",
      })
    );
    reader.readAsArrayBuffer(file);
  };

  const ColoredPoint = ({ color }) => (
    <div id="red-points">
      <hr
        style={{
          color: color,
          borderRadius: 50,
          backgroundColor: color,
          height: 10,
          width: 10,
          border: "none",
          position: "relative",
          marginLeft: -20,
          marginTop: -40,
        }}
      />
    </div>
  );
  var redPoints = document.getElementById("red-points");

  const match = (itemCode) => {
    return stockSummary.find((s) => s.item_code == itemCode)
      ? stockSummary.find((s) => s.item_code == itemCode)
      : {};
  };

  useEffect(() => {
    if (!invList) return null;
    const data = {
      report_name: "Stock Projected Qty",
      filters: {
        company: globalDefaults?.default_company,
        warehouse: invList && invList.warehouse,
        item_group: "",
      },
    };
    dispatch(LoadStockDataReport(data));
  }, [invList]);

  useEffect(() => {
    if (!importedData) return;
    const fQtyIndex = importedData[0].findIndex(
      (p) => p == "Qte. Trouvée" || p == "Found Qty"
    );
    if (fQtyIndex != -1) {
      var test = [];
      importedData.map((r, i) => {
        var data = {
          item_code: String(r[0]),
          found_qty: r[fQtyIndex] ? String(r[fQtyIndex]) : "",
          qty_before: match(String(r[0])).actual_qty,
        };
        test.push(data);
      });
      test.shift();
      setFoundQuantity(test);
    } else {
      setFoundQuantity([]);
    }
  }, [importedData]);

  useEffect(() => {
    dispatch(LoadInvListById(id));
  }, []);

  if (!invList) return <Loader />;
  if (!stockSummary.length) return <Loader />;

  const params = (
    <div className={classes.paper}>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle align={"center"}>
          <Typography
            className={classes.title}
            align={"center"}
            color={"primary"}
          >
            {t("inventory:listSetting")}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box mt={2}>
            <Typography align={"left"} color={"primary"}>
              {t("common:DetailProd")}
            </Typography>
          </Box>
          <Divider />
          <Box mt={2}>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={setting.description}
                    onChange={handleChange}
                    name="description"
                    color="primary"
                  />
                }
                label="Description"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={setting.price}
                    onChange={handleChange}
                    name="price"
                    color="primary"
                  />
                }
                label={t("common:prix")}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={setting.group}
                    onChange={handleChange}
                    name="group"
                    color="primary"
                  />
                }
                label={t("common:ProductGroup")}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={setting.collection}
                    onChange={handleChange}
                    name="collection"
                    color="primary"
                  />
                }
                label={t("product:itemcollection")}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={setting.location}
                    onChange={handleChange}
                    name="location"
                    color="primary"
                  />
                }
                label={t("fulfillment:plcmnt")}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={setting.openingStock}
                    onChange={handleChange}
                    name="openingStock"
                    color="primary"
                  />
                }
                label={t("common:OpenStock")}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={setting.originCountry}
                    onChange={handleChange}
                    name="originCountry"
                    color="primary"
                  />
                }
                label={t("inventory:paysProv")}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={setting.isStock}
                    onChange={handleChange}
                    name="isStock"
                    color="primary"
                  />
                }
                label={t("common:isStock")}
              />
            </FormGroup>
          </Box>
          <Box mt={2}>
            <Typography align={"left"} color={"primary"}>
              {t("common:DetailStock")}
            </Typography>
          </Box>
          <Divider />
          <Box mt={2}>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={setting.nameProd}
                    onChange={handleChange}
                    name="nameProd"
                    color="primary"
                  />
                }
                label={t("common:produit")}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={setting.qteReel}
                    onChange={handleChange}
                    name="qteReel"
                    color="primary"
                  />
                }
                label={t("common:qteReel")}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={setting.qteProj}
                    onChange={handleChange}
                    name="qteProj"
                    color="primary"
                  />
                }
                label={t("common:qteProjetee")}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={setting.qteResv}
                    onChange={handleChange}
                    name="qteResv"
                    color="primary"
                  />
                }
                label={t("common:qteReservee")}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={setting.magasin}
                    onChange={handleChange}
                    name="magasin"
                    color="primary"
                  />
                }
                label={t("common:magasin")}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={setting.all}
                    onChange={handleChange}
                    name="all"
                    color="primary"
                  />
                }
                label={t("common:SelectAll")}
              />
            </FormGroup>
          </Box>
        </DialogContent>
        <Box
          mt={3}
          mb={3}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Button onClick={handleClose} color="primary" autoFocus>
            {t("common:fermer")}
          </Button>
          <Button
            style={{ marginLeft: 5 }}
            onClick={handleParams}
            color="primary"
            autoFocus
            variant="contained"
          >
            {t("common:parametrer")}
          </Button>
        </Box>
      </Dialog>
    </div>
  );
  const alert = (
    <div className={classes.paper}>
      <Typography align={"center"} color={"primary"}>
        {t("inventory:raisonAjust")}
      </Typography>
      <Box mt={3} justifyContent="center" display="flex">
        <FormControl variant="outlined" className={classes.formControl}>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={motifs}
            name="motifs"
            onChange={ChangeMotifs}
            IconComponent={ExpandMore}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
          >
            {Array.isArray(MotifReasonList)
              ? MotifReasonList?.map((reason) => (
                  <MenuItem key={reason?.name} value={reason?.name}>
                    {reason?.name}
                  </MenuItem>
                ))
              : null}
          </Select>
        </FormControl>
      </Box>
      <Box mt={3} justifyContent="right" display="flex">
        <Button color="primary" variant="contained" onClick={TotalStockAdjust}>
          {t("inventory:ajuster")}
        </Button>
        <Button color="primary" onClick={() => setOpen1(false)}>
          {t("common:annuler")}
        </Button>
      </Box>
    </div>
  );
  return (
    <Box className={classes.block}>
      <Box className={classes.titleblock}>
        <span
          onClick={returnList}
          className={clsx(classes.closeicon, "icon-close")}
        />
        <Typography
          align={"center"}
          className={classes.title}
          color={"primary"}
          variant="h5"
        >
          {invList.list_id}
        </Typography>
      </Box>
      {invList.status == "In Progress" && (
        <Grid container justifyContent="flex-end" style={{ marginTop: 20 }}>
          <Button
            variant="contained"
            component="label"
            className={classes.editBtn}
          >
            <SystemUpdateAltIcon
              variant="outlined"
              color="primary"
              style={{
                cursor: "pointer",
                height: 25,
                width: 25,
                marginRight: 10,
              }}
            />
            {t("common:importer")}
            <input
              onChange={handleUploadList}
              id="xlsx-input"
              type="file"
              hidden
            />
          </Button>
          <Button
            variant="contained"
            className={classes.editBtn}
            onClick={handleDownloadList}
          >
            <GetAppIcon
              variant="outlined"
              color="primary"
              style={{
                cursor: "pointer",
                height: 25,
                width: 25,
                marginRight: 10,
              }}
            />
            {t("common:telecharger")}
          </Button>
        </Grid>
      )}
      <Box>
        <Typography variant="caption" display="block">
          <span className={classes.title}>{t("common:createDate")}:</span>{" "}
          {invList.creation_date}
        </Typography>
        <Typography variant="caption" display="block">
          <span className={classes.title}>{t("common:name")}:</span>{" "}
          {invList.name}
        </Typography>
        <Typography variant="caption" display="block">
          <span className={classes.title}>{t("common:type")}:</span>{" "}
          {invList.list_type}
        </Typography>
        <Typography variant="caption" display="block">
          <span className={classes.title}>{t("common:statut")}:</span>{" "}
          {invList.status}
        </Typography>
        <Typography variant="caption" display="block">
          <span className={classes.title}>{t("common:par")}: </span>
          {invList.owner}
        </Typography>
        <Typography variant="caption" display="block">
          <span className={classes.title}>{t("common:depot")}: </span>
          {invList.warehouse}
        </Typography>
        {invList.inventory_period && (
          <Typography variant="caption" display="block">
            <span className={classes.title}>
              {t("inventory:InventoryPeriod")}:
            </span>{" "}
            {JSON.parse(invList.inventory_period)[0]} -{" "}
            {JSON.parse(invList.inventory_period)[1]}
          </Typography>
        )}
        {invList.pos && (
          <Typography variant="caption" display="block">
            {t("common:caisse")}: {invList.pos}
          </Typography>
        )}
        {invList.sales_person && (
          <Typography variant="caption" display="block">
            {t("common:vendorName")}: {invList.sales_person}
          </Typography>
        )}
      </Box>
      <Grid container direction="row">
        <Box style={{ marginTop: 20 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpen(true)}
          >
            {t("common:parametrer")}
          </Button>
        </Box>
        {invList.status == "Finished" && (
          <Box>
            <FormControl style={{ width: 150, marginLeft: 15, marginTop: 5 }}>
              <InputLabel>Ecart</InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={ecart}
                name="ecart"
                onChange={handleChangeSelect}
                IconComponent={ExpandMore}
                disableUnderline
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                <MenuItem onClick={emptyFilter} value={"Total"}>
                  {t("common:total")}
                </MenuItem>
                <MenuItem
                  onClick={handleEcartNegative}
                  value={"Ecart Negative"}
                >
                  {t("inventory:ENegative")}
                </MenuItem>
                <MenuItem
                  onClick={handleEcartPositive}
                  value={"Ecart Positive"}
                >
                  {t("inventory:EPositive")}
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
        )}
      </Grid>
      <Grid container justifyContent="flex-end" style={{ marginTop: -37 }}>
        {invList.status == "Closed" && (
          <ReactToPrint
            trigger={() => (
              <Button
                variant="contained"
                color="primary"
                className={classes.editBtn}
              >
                {t("common:imprimer")}
              </Button>
            )}
            content={() => componentRef.current}
          />
        )}
        {invList.status == "Draft" && (
          <Button
            className={classes.editBtn}
            variant="contained"
            color="primary"
            onClick={handleUpdateStatus}
          >
            {t("fulfillment:markPrep")}
          </Button>
        )}
        {invList.status == "In Progress" && (
          <Button
            className={classes.editBtn}
            disabled={
              foundQuantity.length < invList.products.length ||
              (foundQuantity.length != 0 &&
                foundQuantity.filter((e) => e.found_qty == "").length > 0)
            }
            variant="contained"
            color="primary"
            onClick={handleValidation}
          >
            {t("hold:finaliser")}
          </Button>
        )}
        {invList.status == "Draft" && (
          <Button
            className={classes.editBtn}
            variant="contained"
            color="primary"
            onClick={handleEditList}
          >
            {t("common:editer")}
          </Button>
        )}
        {invList.status == "Finished" && (
          <React.Fragment>
            <Button
              variant="contained"
              color="primary"
              className={classes.editBtn}
              disabled={redPoints ? false : true}
              onClick={() => setOpen1(true)}
            >
              {t("inventory:AjustTotal")}
            </Button>
            <Button
              className={classes.editBtn}
              disabled={redPoints ? false : true}
              variant="contained"
              color="primary"
              onClick={handleCancelValidation}
            >
              {t("inventory:AnnulerListe")}
            </Button>
            <Button
              className={classes.editBtn}
              disabled={redPoints ? true : false}
              variant="contained"
              color="primary"
              onClick={handleClosingList}
            >
              {t("inventory:CloturerListe")}
            </Button>
          </React.Fragment>
        )}
      </Grid>
      <Box style={{ paddingTop: 30 }}>
        <InventoryDetailsTable
          filterList={filterList}
          match={match}
          componentRef={componentRef}
          handleStockAdjust={handleStockAdjust}
          HandleFoundQuantity={HandleFoundQuantity}
          foundQuantity={foundQuantity}
          itemlocation={Array.isArray(itemlocation) ? itemlocation : []}
          ajust={ajust}
          fetchPrice={fetchPrice}
          fetchFromProd={fetchFromProd}
          invList={invList}
          stockSummary={stockSummary}
        />
      </Box>
      <Modal
        open={open}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {params}
      </Modal>
      <Modal
        open={open1}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {alert}
      </Modal>
    </Box>
  );
};
