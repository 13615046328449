import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import { makeStyles } from "@material-ui/core/styles";
import { ComponentToPrint } from '../componentToPrint';
import PrintIcon from "@material-ui/icons/Print";
import { FullfilmentComponentToPrint } from '../componentToPrint/fullfilment';
import { Button } from '@material-ui/core';
import { useSelector } from "react-redux";
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    print: {
        display: 'none'
    },
    icons: {
        color: theme.palette.gray.main,
        height: 17,
        marginRight: 4,
        marginTop: 3,
        cursor : 'pointer'
    },
    printIcon: {
      marginRight : "0.3125rem",
    }
}));

export const Invoice = (props) => {
  const componentRef = useRef();
  const classes = useStyles();
  const contactCompany= useSelector((state)=>state.Login.selectedData);
  return (
    <div>
      <ReactToPrint
        trigger={() => props.title == "Liste de picking" ?
          <Button
            disabled={props.order == 0}
            className={clsx(classes.formControl, classes.printIcon)}
            size={"small"}
            variant="outlined"
            color="primary"
          >
            <PrintIcon className={classes.icon} />&nbsp;Imprimer
          </Button>
        : 
          <PrintIcon className={classes.icons} />
        }
        content={() => componentRef.current}
      />
      <div className={classes.print}>
        {props.title == "Liste de picking" ?
            <FullfilmentComponentToPrint document={props.document} header={props.header} order={props.order} title= {props.title} ref={componentRef} />
          :
            <ComponentToPrint isFactureClient={false} isDeliveryDocument={true}  contactCompany={contactCompany && contactCompany.contact} document={props.document} order={props.order} title= {props.title} ref={componentRef} />
        }
      </div>
    </div>
  );
};