// productFinancier.action.js
import axiosInstance from "../../history/axiosInstance";
import { ITEM_FINANCIER_LIST_LOADED,ITEM_FINANCIER_POS_LOADED } from "./productFinancier.type";

export const LoadItemFinancierList = (items) => ({
  type: ITEM_FINANCIER_LIST_LOADED,
  itemFinancierList: items,
});

export const LoadItemFinancierPos = (items) => {
  return {
    type:  ITEM_FINANCIER_POS_LOADED,
    itemFinancierPos:  items,
  };
};
