import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import clsx from "clsx";
import TablePagination from "@material-ui/core/TablePagination";
import { useTableStyles } from "../../../../theme/table.style";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  LoadProductStockById,
  ClearStockProduct,
} from "../../../../store/product/product.action";
import {
  getStockEntriesByItem,
  LoadItemMovementsById,
} from "../../../../store/stockEntries/stockEntries.action";
import { formatDate, removeHTML } from "../../../../helpers/utils";
import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from "prop-types";
import { Empty } from "../../../../components/empty";
import { Loader } from "../../../../components/loader";
import { SimpleTable } from "../../../../components/simpleTable";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../../../history/axiosInstance";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

export const useStyles = makeStyles((theme) => ({
  card: {
    background: theme.palette.secondary.main,
    borderRadius: 10,
    border: "none",
    minHeight: 500,
  },
  cardBoldText: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: 14,
    lineHeight: 2,
  },
  cardTitle: {
    fontSize: 16,
    textAlign: "center",
  },
  description: {
    fontSize: 16,
    textAlign: "center",
  },
  cardTitleBlock: {
    borderBottom: "1px solid #e7eaef",
    padding: "10px 20px 17px",
  },
  cardContentBlock: {
    padding: "1.4375rem 1.5rem 2.0625rem",
  },
  titleblock: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    borderBottom: "0.0625rem solid #f3f4f6",
    padding: "0.8125rem 0rem 1.75rem 0rem",
  },
  closeicon: {
    position: "absolute",
    top: 18,
    left: 0,
    color: theme.palette.primary.main,
  },
  block: {
    padding: "0rem 1.75rem",
    marginBottom: "6.9375rem",
  },
  title: {
    fontWeight: 600,
    justifyContent: "center",
  },
  cardLightText: {
    lineHeight: 2,
    color: theme.palette.primary.main,
    fontSize: 14,
  },
  img: {
    display: "block",
    margin: "auto",
    width: 200,
    height: 200,
  },
  defaultImg: {
    opacity: 0.15,
    display: "block",
    margin: "auto",
  },
  ref: {
    display: "block",
    textAlign: "center",
    marginTop: 5,
    color: theme.palette.gray.main,
    fontSize: 12,
    fontWeight: 500,
  },
  listTitle: {
    textAlign: "center",
    marginTop: 15,
    color: theme.palette.primary.main,
    fontSize: 20,
    fontWeight: 500,
  },
  paper: {
    overflow: "auto",
    padding: "30px 0",
    marginLeft: 100,
  },
  root: {
    marginTop: 27,
  },
  tabs: {
    "& .MuiTabs-scroller": {
      padding: "0px 0px",
    },
    "& .MuiTabs-flexContainer": {
      borderBottom: "2px solid #ECF2F5",
    },
  },
  tab: {
    borderBottom: "none",
  },
  msg: {
    textAlign: "center",
    marginTop: "30px",
    color: theme.palette.primary.main,
  },
}));

export const StockDetails = () => {
  const classes = useStyles();
  const tableclasses = useTableStyles();
  const history = useHistory();
  const { id } = useParams();
  const { t } = useTranslation(["common", "product"]);
  const productCode = decodeURIComponent(id);
 // console.log("productCode:", productCode);
  const dispatch = useDispatch();
  const productStock = useSelector((state) => state.Product.productStock) || [];
  //console.log("productStock:", productStock);  
  const products = useSelector((state) => state.Product.products);
  const activeImageTheme = useSelector((state) => state.Theme.image);
  const globalDefaults = useSelector((state) => state.Login.globalDefaults);
  const imageList = useSelector((state) => state.Product.imagesData);
  //console.log(productStock, "productStock");
  //console.log(products, "products");

  const stores = useSelector((state) => state.Login.stores);

  const [productDetails, setProductDetails] = useState(null);
  const [variants, setVariants] = useState([]);
  const [variantsStock, setVariantsStock] = useState([]);
  const [loading, setLoading] = useState(false);
  const [posProfileList, setPosProfileList] = useState([]);
  const [warehouseList, setWarehouseList] = useState([]);
  const [allWarehouseProducts, setAllWarehouseProducts] = useState({});
  const [specificItemData, setSpecificItemData] = useState([]);
  const [hold, setHold] = useState([]);
  const holdSelector = useSelector((state) => state.Checkout.holds);

  const columns = [
    { key: "warehouse", title: "common:magasin" },
    { key: "actual_qty", title: "common:qteActuelle" },
    { key: "projected_qty", title: "common:qteProjetee" },
    { key: "reserved_qty", title: "common:qteReservee" },
  ];

  const stockEntriesColumns = [
    { key: "creation", title: "common:dateCreation" },
    { key: "name", title: "common:name" },
    { key: "purpose", title: "common:purpose" },
    { key: "t_warehouse", title: "common:mgsCible" },
    { key: "s_warehouse", title: "common:mgsSource" },
  ];

  const mouvementsColumns = [
    { key: "date", title: "common:dateCreation" },
    { key: "in_qty", title: "common:qtyEntree" },
    { key: "out_qty", title: "common:qtySortie" },
    { key: "qty_after_transaction", title: "common:qtyApresTransaction" },
    { key: "warehouse", title: "common:magasin" },
    { key: "voucher_type", title: "common:type" },
  ];

  const reconciliationsColumns = [
    { key: "date", title: "common:dateCreation" },
    { key: "in_qty", title: "common:qtyEntree" },
    { key: "out_qty", title: "common:qtySortie" },
    { key: "qty_after_transaction", title: "common:qtyApresTransaction" },
    { key: "warehouse", title: "common:magasin" },
    { key: "voucher_type", title: "common:type" },
  ];

  const getProductDetails = () => {
    var data = products.filter((p) => p.item_code == productCode);
    if (data && data[0]) setProductDetails(data[0]);
  };
  
  const header = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
    Authorization: JSON.parse(localStorage.getItem("user")).token,
  };

  const getWarehouseList = () => {
    fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
      method: "POST",
      headers: header,
      body: JSON.stringify({
        doctype: "Warehouse",
        fields: ['`tabWarehouse`.`name`'],
        filters: [
          ['Warehouse', 'is_storage', '=', 0],
          ['Warehouse', 'is_group', '=', 0]
      ],
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (Array.isArray(data)) {
          setWarehouseList(data);
        }
      });
  };

  useEffect(() => {
     getWarehouseList();
    }
    , []);

  //console.log(warehouseList, "warehouseList");

  const getPOSProfileList = () => {
    fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
      method: "POST",
      headers: header,
      body: JSON.stringify({
        doctype: "POS Profile",
        fields: ['`tabPOS Profile`.`name`', '`tabPOS Profile`.`warehouse`'],
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (Array.isArray(data)) {
          setPosProfileList(data);
        }
      });
  };

  useEffect(() => {
      getPOSProfileList();
    }
  , []);

  //console.log(posProfileList, "posProfileList");

  const mapFirstPOSToWarehouse = () => {
    const firstPOSPerWarehouse = warehouseList.reduce((acc, warehouse) => {
      const matchingPOS = posProfileList.find(pos => pos.warehouse === warehouse.name);
      if (matchingPOS) {
        acc[warehouse.name] = matchingPOS.name;
      }
      return acc;
    }, {});
  
    return firstPOSPerWarehouse;
  };
  
  useEffect(() => {
    if (warehouseList.length > 0 && posProfileList.length > 0) {
      const firstPOSMapping = mapFirstPOSToWarehouse();
      console.log(firstPOSMapping, "First POS per Warehouse");
    }
  }, [warehouseList, posProfileList]);

  const getPosProductsList = async (warehouse) => {
    try {
      const firstPOSProfile = posProfileList.find(profile => profile.warehouse === warehouse);
      
      if (!firstPOSProfile) {
        console.error('No POS profile found for the selected warehouse:', warehouse);
        return null;
      }
  
      const response = await fetch(`${process.env.REACT_APP_API_URI}/api/pos/products`, {
        method: "POST",
        headers: header,
        body: JSON.stringify({
          pos_profile: firstPOSProfile.name,
          price_list: "Standard Selling",
          item_group: "Nos",
          start: 0,
          page_length: 500,
          warehouse: warehouse
        }),
      });
  
      if (!response.ok) {
        throw new Error(`Failed to fetch Pos Products for ${firstPOSProfile.name}`);
      }
  
      const data = await response.json();
      const posProducts = {
        posProfile: firstPOSProfile.name,
        products: data.message.items
      };
  
      console.log(`POS Products for warehouse ${warehouse}:`, posProducts);
      return posProducts;
  
    } catch (error) {
      console.error('Error fetching Pos Products list:', error);
      return null;
    }
  };

  useEffect(() => {
    if (warehouseList.length > 0) {
      warehouseList.forEach(warehouse => {
        getPosProductsList(warehouse.name);
      });
    }
  }, [warehouseList]);

  useEffect(() => {
    if (warehouseList.length > 0 && posProfileList.length > 0) {
      const fetchAllProducts = async () => {
        const productsMap = {};
        for (const warehouse of warehouseList) {
          const products = await getPosProductsList(warehouse.name);
          if (products) {
            productsMap[warehouse.name] = products;
          }
        }
        setAllWarehouseProducts(productsMap);
      };
  
      fetchAllProducts();
    }
  }, [warehouseList, posProfileList]);
  
  useEffect(() => {
    console.log('All Warehouse Products:', allWarehouseProducts);
  }, [allWarehouseProducts]);

  const findSpecificItem = (productCode) => {
    const itemDataAcrossWarehouses = [];
  
    Object.entries(allWarehouseProducts).forEach(([warehouse, posData]) => {
      const matchingItem = posData.products.find(item => item.item_code === productCode);
      
      if (matchingItem) {
        itemDataAcrossWarehouses.push({
          warehouse: warehouse,
          posProfile: posData.posProfile,
          itemData: matchingItem
        });
      }
    });
  
    setSpecificItemData(itemDataAcrossWarehouses);
  };
  
  useEffect(() => {
    if (Object.keys(allWarehouseProducts).length > 0) {
      const productCode = decodeURIComponent(id);
      console.log("productCode:", productCode);
      findSpecificItem(productCode);
    }
  }, [allWarehouseProducts, id]);
  
  useEffect(() => {
    console.log('Specific Item Data:', specificItemData);
  }, [specificItemData]);

  useEffect(() => {
    if (holdSelector) {
      setHold(holdSelector);
    }
  }, [holdSelector]);

  const isReservedInPOS = (item_code) => {
    let totalQtyReserved = 0;
    hold &&
      hold
        ?.filter((element) => element?.payment_status === "partly paid")
        ?.forEach((element) => {
          if (element?.amount?.payments.length > 0) {
            const reservedProducts = element?.products?.filter(
              (el) => el.item_code === item_code
            );
  
            reservedProducts?.forEach((reservedProduct) => {
              totalQtyReserved += reservedProduct.qty;
            });
          }
        });
  
    return totalQtyReserved;
  };

  function getVariantsStockLevel(variantsList) {
    setLoading(true);
    const promises = variantsList.map((variant) => {
      return axiosInstance.post(
        `${process.env.REACT_APP_API_URI}/api/stock/dashboard`,
        {
          item_code: variant?.item_code,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
            Authorization: JSON.parse(localStorage.getItem("user")).token,
          },
        }
      );
    });

    Promise.all(promises)
      .then((responses) => {
        let result = [];
        responses.forEach(({ data }, index) => {
          if (data) {
            result.push(data?.message);
          }
        });
        setVariantsStock(result.flat());
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }
  const selectedData = useSelector((state) => state.Login.selectedData);
  const openDate = useSelector((state) => state.Login.posOpeningDate);

  const [stockEntries, setStockEntries] = useState([]);
  const [mouvements, setMouvements] = useState([]);
  const [reconciliations, setReconciliations] = useState([]);
  const [isProductStockLoading, setIsProductStockLoading] = useState(true);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const setStockEntriesById = () => {
    getStockEntriesByItem(productCode).then((response) => {
      response.data.length > 0 && setStockEntries(response.data);
    });

    const currentDate = new Date();
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

    LoadItemMovementsById(
      productCode,
      oneMonthAgo,
      currentDate,
      globalDefaults?.default_company
    ).then((response) => {
      if (response.data.length > 0) {
        let tabmvt = [];
        let tabrec = [];
        response.data.forEach((element) => {
          if (element?.voucher_type == "Stock Reconciliation") {
            tabrec.push(element);
          } else {
            tabmvt.push(element);
          }
          setReconciliations(tabrec);
          setMouvements(tabmvt);
        });
      }
    });
  };

  useEffect(() => {
    if (globalDefaults?.default_company) {
      setIsProductStockLoading(true);
      dispatch(LoadProductStockById(productCode));
      setIsProductStockLoading(false);
      setStockEntriesById();
    }
  }, [globalDefaults?.default_company]);

  useEffect(() => {
    const itemIndex = products?.findIndex(
      (el) => el?.item_code === productCode
    );
    if (itemIndex === -1) {
      dispatch(LoadProductStockById(productCode));
    } else {
      if (products[itemIndex].has_variants === 1) {
        const variantList = products?.filter(
          (el) => el?.variant_of === productCode
        );
        setVariants(variantList);
        getVariantsStockLevel(variantList);
      } else {
        dispatch(LoadProductStockById(productCode));
      }
    }
  }, []);

  useEffect(() => {
    getProductDetails();
  }, [products]);

  const [page, setPage] = useState(0);
  const [pageStock, setPageStock] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rowsPerPageStock, setRowsPerPageStock] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangePageStock = (event, newPage) => {
    setPageStock(newPage);
  };

  const handleChangeRowsPerPageStock = (event) => {
    setRowsPerPageStock(+event.target.value);
    setPageStock(0);
  };

  function getImgUrl(itemCode) {
    if (imageList && imageList?.itemImages) {
      const IIndex = imageList?.itemImages?.findIndex(
        (e) => e.itemCode === itemCode
      );
      if (IIndex !== -1) {
        return (
          `${process.env.REACT_APP_API_URI}` +
          imageList?.itemImages?.[IIndex]?.imgUrl
        );
      } else {
        return activeImageTheme;
      }
    } else {
      return activeImageTheme;
    }
  }

  const handleClose = async () => {
    await dispatch(ClearStockProduct());
    history.goBack();
  };

  function InventoryTable({ data, variants, specificItemData }) {
    const itemNames = Array.from(
      new Set(variants.map((item) => item.item_name))
    );
    const warehouses = Array.from(new Set(stores?.map((item) => item.name)));
  
    return (
      <div>
        <Table className={tableclasses.table} aria-label="simple table">
          <TableBody>
            {itemNames.map((itemName) => (
              <TableRow key={itemName} className={tableclasses.tableRow}>
                <TableCell className={tableclasses.tableCell}>
                  {itemName}
                </TableCell>
                {warehouses.map((warehouse) => {
                  const specificItem = specificItemData.find(
                    item => item.warehouse === warehouse
                  );
                  const item = data.find(
                    (item) =>
                      item.warehouse === warehouse &&
                      item.item_name === itemName
                  );
                  const itemCode = variants.find(v => v.item_name === itemName)?.item_code;
                  const reservedQty = isReservedInPOS(itemCode);
                  const actualQty = specificItem ? specificItem.itemData.actual_qty : (item ? item.actual_qty : 0);
                  const displayQty = Math.max(actualQty - reservedQty, 0);
                  return (
                    <TableCell
                      className={tableclasses.tableCell}
                      key={`${itemName}-${warehouse}`}
                    >
                      {displayQty}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    );
  }

  return (
    <Box className={classes.block}>
      <Box className={classes.titleblock}>
        <IconButton onClick={handleClose} className={classes.closeicon}>
          <span className={"icon-close"} />
        </IconButton>
        <Typography
          align={"center"}
          className={classes.title}
          color={"primary"}
          variant="h5"
        >
          {productDetails && productDetails.item_name}
          {productDetails && " - " + productDetails.item_group}
        </Typography>
      </Box>
      <Grid container spacing={2} className={classes.root}>
        <Grid item xs={5}>
          <Card
            className={clsx(classes.card, classes.cardBoldText)}
            variant="outlined"
          >
            <CardContent>
              <Box>
                <img
                  className={classes.img}
                  src={getImgUrl(productCode)}
                  alt={productCode}
                />
                <span className={classes.ref}>
                  {t("common:ref") + ": " + productCode}
                </span>
              </Box>
              <Box className={classes.cardTitleBlock}>
                <Typography
                  align={"center"}
                  variant="body2"
                  color="textSecondary"
                  component="p"
                >
                  {variants?.length ? variants?.length + " Variants" : null}
                </Typography>
                <Typography className={classes.description}>
                  {productDetails ? removeHTML(productDetails.description) : ""}
                </Typography>
              </Box>
              <Grid
                container
                spacing={3}
                className={classes.cardContentBlock}
                display="flex"
                flexWrap="wrap"
              >
                <Grid item xs={6}>
                  {productDetails?.item_name && (
                    <Typography
                      className={classes.cardLightText}
                      variant="caption"
                      display="block"
                    >
                      {t("product:nomArticle")}
                    </Typography>
                  )}
                  {productDetails?.item_group && (
                    <Typography
                      className={classes.cardLightText}
                      variant="caption"
                      display="block"
                    >
                      {t("product:itemgroup")}
                    </Typography>
                  )}
                  {productDetails?.item_collection && (
                    <Typography
                      className={classes.cardLightText}
                      variant="caption"
                      display="block"
                    >
                      {t("product:itemcollection")}
                    </Typography>
                  )}
                  {productDetails?.item_tax_template && (
                    <Typography
                      className={classes.cardLightText}
                      variant="caption"
                      display="block"
                    >
                      {t("product:tax")}
                    </Typography>
                  )}
                  <Typography
                    className={classes.cardLightText}
                    variant="caption"
                    display="block"
                  >
                    {t("product:stockInitial")}
                  </Typography>
                  {productDetails?.default_material_request_type && (
                    <Typography
                      className={classes.cardLightText}
                      variant="caption"
                      display="block"
                    >
                      {t("product:TypeCommande")}
                    </Typography>
                  )}
                  <Typography
                    className={classes.cardLightText}
                    variant="caption"
                    display="block"
                  >
                    {t("common:prix")}
                  </Typography>
                </Grid>
                {productDetails ? (
                  <Grid item xs={6}>
                    {productDetails?.item_name && (
                      <Typography
                        className={classes.cardBoldText}
                        variant="caption"
                        display="block"
                      >
                        {productDetails?.item_name}
                      </Typography>
                    )}
                    {productDetails?.item_group && (
                      <Typography
                        className={classes.cardBoldText}
                        variant="caption"
                        display="block"
                      >
                        {productDetails.item_group}
                      </Typography>
                    )}
                    {productDetails?.item_collection && (
                      <Typography
                        className={classes.cardBoldText}
                        variant="caption"
                        display="block"
                      >
                        {productDetails?.item_collection}
                      </Typography>
                    )}
                    {productDetails?.item_tax_template && (
                      <Typography
                        className={classes.cardBoldText}
                        variant="caption"
                        display="block"
                      >
                        {productDetails?.item_tax_template}
                      </Typography>
                    )}
                    {productDetails?.opening_stock && (
                      <Typography
                        className={classes.cardBoldText}
                        variant="caption"
                        display="block"
                      >
                        {productDetails?.opening_stock}
                      </Typography>
                    )}
                    {productDetails?.default_material_request_type && (
                      <Typography
                        className={classes.cardBoldText}
                        variant="caption"
                        display="block"
                      >
                        {productDetails?.default_material_request_type}
                      </Typography>
                    )}
                    <Typography
                      className={classes.cardBoldText}
                      variant="caption"
                      display="block"
                    >
                      {productDetails.standard_rate != null
                        ? productDetails.standard_rate.toFixed(3)
                        : productDetails.price_list_rate.toFixed(3)}{" "}
                      {globalDefaults?.default_currency}
                    </Typography>
                  </Grid>
                ) : (
                  ""
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={7}>
                                    {products?.filter((p) => p?.item_code === productCode)?.[0]
                                      ?.has_variants === 1 ? (
                                      <>
                                        {loading ? (
                                          <Loader />
                                        ) : (
                                          <InventoryTable 
                                            data={variantsStock} 
                                            variants={variants} 
                                            specificItemData={specificItemData}
                                          />
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        {isProductStockLoading ? (
                                          <Loader />
                                        ) : productStock ? (
                                          <SimpleTable
                                            title={"stockDetails"}
                                            columns={columns}
                                            data={(productStock || []).map(stock => {
                                              const specificItem = specificItemData.find(
                                                item => item.warehouse === stock.warehouse
                                              );
                                              const actualQty = specificItem ? specificItem.itemData.actual_qty : stock.actual_qty;
                                              const reservedQty = isReservedInPOS(productCode);
                                              const displayQty = Math.max(actualQty - reservedQty, 0);
                                              return {
                                                ...stock,
                                                actual_qty: displayQty
                                              };
                                            })}
                                          />
                                        ) : (
                                          <Typography>Aucune donnée sur les stocks disponible</Typography>
                                        )}
      {!productStock ? (
        <Loader />
      ) : (
        <Box mt={5}>
          <Tabs
            value={value}
            className={classes.tabs}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab
              className={classes.tab}
              label="Réceptions/Transferts"
              {...a11yProps(0)}
            />
            {mouvements?.length > 0 && (
              <Tab
                className={classes.tab}
                label="Mouvements"
                {...a11yProps(1)}
              />
            )}
            {reconciliations?.length > 0 && (
              <Tab
                className={classes.tab}
                label="Réconciliation"
                {...a11yProps(mouvements?.length > 0 ? 2 : 1)}
              />
            )}
          </Tabs>
          <TabPanel value={value} index={0}>
            <SimpleTable
              title={"stockEntries"}
              columns={stockEntriesColumns}
              data={stockEntries.slice(
                pageStock * rowsPerPageStock,
                pageStock * rowsPerPageStock + rowsPerPageStock
              )}
            />
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={stockEntries.length}
              rowsPerPage={rowsPerPageStock}
              page={pageStock}
              onPageChange={handleChangePageStock}
              onRowsPerPageChange={handleChangeRowsPerPageStock}
              labelRowsPerPage={""}
            />
          </TabPanel>
          {mouvements?.length > 0 && (
            <TabPanel value={value} index={1}>
              <SimpleTable
                title={"stockMovements"}
                columns={mouvementsColumns}
                data={mouvements.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )}
              />
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={mouvements.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={""}
              />
            </TabPanel>
          )}
          <TabPanel value={value} index={mouvements?.length > 0 ? 2 : 1}>
            <SimpleTable
              title={"stockReconciliations"}
              columns={reconciliationsColumns}
              data={reconciliations.slice(
                pageStock * rowsPerPageStock,
                pageStock * rowsPerPageStock + rowsPerPageStock
              )}
            />
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={reconciliations.length}
              rowsPerPage={rowsPerPageStock}
              page={pageStock}
              onPageChange={handleChangePageStock}
              onRowsPerPageChange={handleChangeRowsPerPageStock}
              labelRowsPerPage={""}
            />
          </TabPanel>
        </Box>
      )}
    </>
  )}
</Grid> 
      </Grid>
    </Box>
  );
};
