import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Box from "@material-ui/core/Box";
import clsx from "clsx";
import { useTableStyles } from "../../../../styles/table.style";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "1.0625rem 1rem",
  },
  price: {
    fontWeight: 600,
  },
  filterList: {
    borderTop: 'none'
  }
}));


export const Products = (props) => {
  const classes = useStyles();
  const tableClasses = useTableStyles();
  const globalDefaults = useSelector(state => state.Login.globalDefaults);
  const { t } = useTranslation(["common", "order"]);

  return (
    <Box className={clsx(classes.root, tableClasses.paper)}>
      <Table className={tableClasses.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={tableClasses.tablecellHeader} align="left">
              {t("common:ref")}
            </TableCell>
            <TableCell className={tableClasses.tablecellHeader} align="center">
              {t("common:name")}
            </TableCell>
            <TableCell className={tableClasses.tablecellHeader} align="center">
              {t("common:qty")}
            </TableCell>
            <TableCell className={tableClasses.tablecellHeader} align="center">
              {t("common:totalPrice")}
            </TableCell>
            {!props.isOrderDetail && (
              <>
                <TableCell className={tableClasses.tablecellHeader} align="center">
                  {t("common:mgsCible")}
                </TableCell>
                <TableCell className={tableClasses.tablecellHeader} align="center">
                  {t("common:mgsSource")}
                </TableCell>
              </>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.orders && (
            props.orders.map((row, index) => (
              <TableRow className={tableClasses.tableRow} key={index}>
                <TableCell className={tableClasses.tableCell} align="left">
                  {row.item_code}
                </TableCell>
                <TableCell className={tableClasses.tableCell} align="center">
                  {row.item_name}
                </TableCell>
                <TableCell className={tableClasses.tableCell} align="center">
                  {row.qty}
                </TableCell>
                <TableCell
                  className={clsx(tableClasses.tableCell, classes.price)}
                  align="center"
                >
                  {
                    row.amount != 0 ?
                      row.amount.toFixed(3)
                      :
                      props.products && props.products.find(p => row.item_code) ? row.qty * (props.products.find(p => row.item_code).price_list_rate).toFixed(3) : 0} {globalDefaults?.default_currency}
                </TableCell>
                {!props.isOrderDetail && (
                  <>
                    <TableCell className={tableClasses.tableCell} align="center">
                      {row.t_warehouse}
                    </TableCell>
                    <TableCell className={tableClasses.tableCell} align="center">
                      {row.s_warehouse}
                    </TableCell>
                  </>
                )}
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
    </Box>
  );
};
