import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  ActiveThemes,
  globalColor,
  freeButtonStyle,
  selectedProduct,
  productStyle,
  calculatorStyle,
  UpdateThemes,
  UpdateStepThemes,
  logoImage,
  UpdateThemeById,
} from "../../../../store/theme/theme.action";
import { useTranslation } from "react-i18next";
import {
  FormGroup,
  Checkbox,
  FormControlLabel,
  Modal,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
} from "@material-ui/core";
import ExpandMore from "@material-ui/icons/ExpandMore";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 272,
    margin: "1.25rem 1.875rem",
    boxShadow: "0rem 0.125rem 0.5rem #091F461D",
    borderRadius: 6,
    padding: "0.75rem 0.5rem",
    "& img": {
      height: 166,
    },
  },
  button: {
    background: "#F4F7F9",
    borderRadius: 5,
    fontSize: 14,
    padding: "0.0625rem 1.5625rem",
  },
  cardActions: {
    justifyContent: "center",
    marginTop: 10,
  },
  active: {
    border: "0.0625rem solid #0066FF",
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 10,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  formControl: {
    marginBottom: 10,
    width: "80%",
    "& .MuiInputBase-root": {
      background: theme.palette.white.main,
    },
    "& .MuiOutlinedInput-input": {
      padding: "8.9px 10px",
      fontSize: 12,
    },
  },
  label: {
    fontSize: 12,
    color: theme.palette.gray.main,
    marginTop: -6,
  },
}));

export const InterfaceCardItem = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(["common", "interfaceCustomization"]);
  const globalDefaults = useSelector((state) => state.Login.globalDefaults);
  var themes = useSelector((state) => state.Theme.themes);
  var themeActive = useSelector((state) => state.Theme.activeTheme);
  var globalColors = useSelector((state) => state.Theme.globalColors);
  var freeButton = useSelector((state) => state.Theme.freeButtonsStyles);
  var products = useSelector((state) => state.Theme.productsStyle);
  var calculator = useSelector((state) => state.Theme.calculatorStyle);
  var image = useSelector((state) => state.Theme.image);
  var selectedProducts = useSelector(
    (state) => state.Theme.selectedProductStyle
  );
  const selectedData = useSelector((state) => state.Login.selectedData);
  const stores = useSelector((state) => state.Login.stores);
  const pos = useSelector((state) => state.Login.pos);
  const [open, setOpen] = React.useState(false);
  const [warehouse, setWarehouse] = React.useState([]);
  const [caisse, setCaisse] = React.useState([]);

  const ChangeWarehouse = (event) => {
    setWarehouse(event.target.value);
  };
  const ChangeCaisse = (event) => {
    setCaisse(event.target.value);
  };
  const classes = useStyles();
  const [settingTheme, setSettingTheme] = React.useState({
    company: false,
    warehouse: false,
    caisse: false,
  });
  const handleChange = (event) => {
    setSettingTheme({
      ...settingTheme,
      [event.target.name]: event.target.checked,
    });
  };
  const Steps = [
    {
      statut: settingTheme.company,
      company: `${globalDefaults?.default_company}`,
    },
    {
      statut: settingTheme.warehouse,
      warehouse: warehouse,
    },
    {
      statut: settingTheme.caisse,
      caisse: caisse,
    },
  ];
  const handleThemeStep = async () => {
    const newThemeIndex = themes.findIndex(
      (obj) => obj.theme_id === props.theme.theme_id
    );
    const theme_id = themes[newThemeIndex].theme_id;
    const profile =
      Array.isArray(pos) &&
      pos.filter((pos) => pos.warehouse == warehouse).map((pos) => pos.name);
    const data = {
      activated: Steps,
      theme_id: theme_id,
    };

    caisse.length != 0 &&
      (await dispatch(
        UpdateThemeById(
          {
            theme_id: theme_id,
            name: caisse,
          },
          "pos/update",
          "POS Profile"
        )
      ));

    if (warehouse.length != 0) {
      await dispatch(
        UpdateThemeById(
          {
            theme_id: theme_id,
            name: warehouse,
          },
          "warehouses/update",
          "Warehouse"
        )
      );
      if (caisse.length == 0) {
        await dispatch(
          UpdateThemeById(
            {
              theme_id: null,
              name: profile,
            },
            "pos/update",
            "POS Profile"
          )
        );
      }
    }

    await dispatch(UpdateStepThemes(data));
    setOpen(false);
  };

  const handleSelectedTheme = () => {
    const lastThemeIndex = themes.findIndex((obj) => obj.active === 1);
    const newThemeIndex = themes.findIndex(
      (obj) => obj.theme_id === props.theme.theme_id
    );
    themes[lastThemeIndex].active = 0;
    props.theme.active = 1;
    themes[newThemeIndex] = props.theme;
    dispatch(UpdateThemes(themes));
  };

  const handleModifedTheme = () => {
    themeActive = props.theme;
    globalColors = props.theme.globalColors;
    freeButton = props.theme.freeButton;
    selectedProducts = props.theme.selectedProducts;
    products = props.theme.products;
    calculator = props.theme.calculator;
    image = props.theme.image;
    dispatch(globalColor(globalColors));
    dispatch(freeButtonStyle(freeButton));
    dispatch(selectedProduct(selectedProducts));
    dispatch(productStyle(products));
    dispatch(calculatorStyle(calculator));
    dispatch(logoImage(image));
    dispatch(
      ActiveThemes({
        ...themeActive,
        globalColors: globalColors,
        freeButton: freeButton,
        products: products,
        calculator: calculator,
        selectedProducts: selectedProducts,
        image: image,
      })
    );
  };
  const intersection = (pos, warehouse) => {
    const setA = new Set(warehouse);
    return pos.filter((item) => setA.has(item?.warehouse));
  };
  const alert = (
    <div className={classes.paper}>
      <Typography align={"center"} color={"primary"}>
        {t("interfaceCustomization:ParmsTheme")}
      </Typography>
      <FormGroup row>
        <FormControlLabel
          control={
            <Checkbox
              checked={settingTheme.company}
              onChange={handleChange}
              name="company"
              color="primary"
            />
          }
          label={t("interfaceCustomization:ParSoc")}
        />
      </FormGroup>
      {settingTheme.company && (
        <List component="div" disablePadding>
          <ListItem>
            <FormControlLabel
              control={
                <Checkbox
                  checked={settingTheme.warehouse}
                  onChange={handleChange}
                  name="warehouse"
                  color="primary"
                />
              }
              label={t("interfaceCustomization:ParMag")}
            />
          </ListItem>
        </List>
      )}
      {settingTheme.warehouse && (
        <Box mt={2} justifyContent="center" display="flex">
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel
              id="demo-simple-select-outlined-label"
              className={classes.label}
            >
              Magasin
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              IconComponent={ExpandMore}
              value={warehouse}
              multiple
              name="warehouse"
              onChange={ChangeWarehouse}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
            >
              {Array.isArray(stores) &&
                stores.map((store, index) => (
                  <MenuItem key={index} value={store.name}>
                    {store.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>
      )}
      {warehouse.length != 0 && (
        <List component="div" disablePadding>
          <ListItem style={{ marginLeft: 20 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={settingTheme.caisse}
                  onChange={handleChange}
                  name="caisse"
                  color="primary"
                />
              }
              label={t("interfaceCustomization:ParCaisse")}
            />
          </ListItem>
        </List>
      )}
      {settingTheme.caisse && (
        <Box mt={3} justifyContent="center" display="flex">
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel
              id="demo-simple-select-outlined-label"
              className={classes.label}
            >
              Caisse
            </InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              IconComponent={ExpandMore}
              value={caisse}
              name="caisse"
              onChange={ChangeCaisse}
              multiple
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
            >
              {Array.isArray(pos) &&
                intersection(pos, warehouse)?.map((pos, index) => (
                  <MenuItem key={index} value={pos.name}>
                    {pos.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>
      )}
      <Box mt={3} justifyContent="center" display="flex">
        <Button variant="contained" color="primary" onClick={handleThemeStep}>
          {t("common:activer")}
        </Button>
        <Button color="primary" onClick={() => setOpen(false)}>
          {t("common:annuler")}
        </Button>
      </Box>
    </div>
  );

  return (
    <div>
      <Card
        className={clsx({
          [classes.root]: true, //always applies
          [classes.active]: props.selected, //only when open === true
        })}
      >
        <CardActionArea>
          <CardMedia
            component="img"
            alt="Contemplative Reptile"
            height="140"
            image={props.img}
            title="Contemplative Reptile"
          />
        </CardActionArea>
        <CardActions className={classes.cardActions}>
          <Button
            // onClick={handleSelectedTheme}
            onClick={() => setOpen(true)}
            disabled={props.selected ? 1 : 0}
            className={classes.button}
            color="primary"
          >
            {t("common:activer")}
          </Button>
          <Link to={`/interfaces/customization/${props.theme.theme_id}`}>
            <Button
              onClick={handleModifedTheme}
              className={classes.button}
              color="primary"
            >
              {t("common:modifier")}
            </Button>
          </Link>
        </CardActions>
      </Card>
      <Modal
        open={open}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {alert}
      </Modal>
    </div>
  );
};
