import React, { useEffect, useState } from "react";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import {
  ClickAwayListener,
  Modal,
  Select,
  Typography,
  MenuItem,
  InputLabel,
  Button,
  OutlinedInput,
  Link,
  Box,
  Dialog,
  DialogActions,
  Grid,
  FormControl,
  IconButton,
  CircularProgress,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table,
  DialogContent,
  DialogTitle,
  InputAdornment,
} from "@material-ui/core";
import { ButtonsFunction } from "../../../../helpers/buttonsFunction";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {
  LoadCouponCodeById,
  getRecentOrders,
  manageReturn,
  SetSelectedClient,
  fetchAvoirList,
  SetTaxSales,
} from "../../../../store/checkout/checkout.action";
import {
  SendGiftCardEmail,
  loadCouponCodes,
  LoadPricingRuleByName,
} from "../../../../store/client/client.action";
import { GiftCardForm } from "../../../clients/components/carteCadeau/giftCardForm";
import { formatDate } from "../../../../helpers/utils";
import { useTableStyles } from "../../../../styles/table.style";
import { CadeauComponentToPrint } from "../../../../components/componentToPrint/carteCadeau";
import * as XLSX from "xlsx";
import { ReturnForm } from "./returnForm";
import { GlobalfilterData } from "../../../../helpers/helper";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { isValidEmail, motifNames } from "../../../../helpers/utils";
import { CustomAlert } from "../../../../components/alert";
import AlertModal from "../../../../components/common/alertModal";
import { RecentOrdersTable } from "./recentOrdersTable";
import LoyaltyOutlinedIcon from "@material-ui/icons/LoyaltyOutlined";
import InfoIcon from "@material-ui/icons/Info";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import { Alert, AlertTitle } from "@material-ui/lab";

export const Couponcode = (props) => {
  const {
    setOpenCouponcode,
    classes,
    pricingRules,
    codeCoupon,
    setCodeCoupon,
  } = props;
  const { t } = useTranslation(["common", "checkout", "clients"]);

  const dispatch = useDispatch();
  const [codeCouponIsvalid, setCodeCouponIsvalid] = useState(false);
  const [submit, setSubmit] = useState(false);
  const rows = useSelector((state) => state.Checkout.products);
  const selectedCoupon = useSelector((state) => state.Checkout.selectedCoupon);
  const notification = useSelector((state) => state.Notification.notification);

  const validationcodeCoupon = () => {
    var isvalid = false;
    rows.forEach((element) => {
      const myIndex = pricingRules?.items?.findIndex(
        (el) => el.item_code == element.item_code
      );
      if (myIndex > -1) isvalid = true;
    });
    if (codeCouponIsvalid != isvalid) {
      setCodeCouponIsvalid(isvalid);
    }
  };

  const UpdateCouponCode = (e) => {
    if (
      e.target.value !== selectedCoupon?.name &&
      !selectedCoupon?.pricing_rule
    ) {
      setCodeCoupon(e.target.value);
    }
  };
  const handleClickCouponCode = (e) => {
    setSubmit(true);
    dispatch(LoadCouponCodeById(codeCoupon));
  };
  useEffect(() => {
    if (pricingRules) validationcodeCoupon();
  }, [rows?.length, pricingRules]);
  useEffect(() => {
    if (
      notification?.message == "notification:couponNotExist" ||
      notification?.message == "notification:couponExpired"
    ) {
      setSubmit(false);
    }
  }, [notification]);
  return (
    <ClickAwayListener onClickAway={() => setOpenCouponcode(false)}>
      <div className={classes.paper}>
        <Typography
          align={"center"}
          color={"primary"}
          style={{ fontWeight: "bold", fontSize: 20, marginBottom: 20 }}
        >
          {t("checkout:couponCode")}
        </Typography>
        <Box mt={3}>
          <Grid container direction="row" spacing={2} justifyContent="center">
            {pricingRules && (
              <Grid item xs={12}>
                <Alert severity={codeCouponIsvalid ? "success" : "warning"}>
                  <AlertTitle></AlertTitle>
                  {codeCouponIsvalid
                    ? t("checkout:supcouponCode")
                    : t("checkout:helpcouponCode")}{" "}
                  <strong>!!</strong>
                </Alert>
                <br />
              </Grid>
            )}

            <Grid item container spacing={2} xs={12}>
              <Grid
                xs={12}
                sm={8}
                style={{ display: "flex", justifyContent: "center" }}
                item
              >
                <FormControl className={classes.couponInput}>
                  <OutlinedInput
                    autoComplete="off"
                    id="outlined-adornment-weight"
                    placeholder={t("checkout:couponCode")}
                    value={
                      selectedCoupon?.name ? selectedCoupon?.name : codeCoupon
                    }
                    name="coupon_code"
                    onChange={!submit && UpdateCouponCode}
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      "aria-label": "weight",
                    }}
                    endAdornment={
                      <InputAdornment
                        position="end"
                        style={{
                          display: "flex",
                          justifyContent: "right",
                          marginRight: -10,
                        }}
                      >
                        {pricingRules && codeCouponIsvalid == true && (
                          <DoneAllIcon
                            style={{ color: "#1b5e20", marginRight: 5 }}
                          />
                        )}
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Grid>
              <Grid
                xs={12}
                sm={3}
                item
                style={{ display: "flex", justifyContent: "center" }}
              >
                {submit && !pricingRules ? (
                  <CircularProgress />
                ) : (
                  <Button
                    style={{
                      width: "100%",
                      height: "100%",
                      boxSizing: "border-box",
                    }}
                    color={"primary"}
                    variant="contained"
                    className={classes.inputForm}
                    onClick={handleClickCouponCode}
                    disabled={pricingRules}
                  >
                    {t("common:appliquer")}
                  </Button>
                )}{" "}
              </Grid>
            </Grid>
            <Grid item container xs={12}>
              <Grid item>
                {!pricingRules || codeCouponIsvalid == false ? (
                  <InfoIcon color={"primary"} />
                ) : (
                  <CheckCircleIcon
                    style={{
                      color: codeCouponIsvalid == true ? "#1b5e20" : "#70480F",
                    }}
                  />
                )}
              </Grid>
              <Grid item>
                {!pricingRules && (
                  <Typography
                    align={"left"}
                    color={"primary"}
                    className={classes.couponInfo}
                  >
                    {t("checkout:MsgCouponCode")}
                  </Typography>
                )}
                {pricingRules /* && codeCouponIsvalid == true */ && (
                  <Typography
                    align={"left"}
                    style={{ color: "#2f4b7c" }}
                    className={classes.couponInfo}
                  >
                    {pricingRules?.rate_or_discount}:{" "}
                    {pricingRules?.rate_or_discount == "Discount Percentage"
                      ? pricingRules?.discount_percentage + " %"
                      : pricingRules?.discount_amount +
                        " " +
                        pricingRules?.currency}{" "}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </div>
    </ClickAwayListener>
  );
};
