export const GET_DISCOUNT = "GET_DISCOUNT";
export const GET_COMPANY_ACCOUNT = "GET_COMPANY_ACCOUNT";
export const GET_COMPANY_INFORMATIONS = "GET_COMPANY_INFORMATIONS";
export const GET_WAREHOUSE_PARENT = "GET_WAREHOUSE_PARENT";
export const GET_TERRITORY_PARENT = "GET_TERRITORY_PARENT";
export const GET_CUSTOMER_GROUP_PARENT = "GET_CUSTOMER_GROUP_PARENT";
export const GET_DEFAULT_WAREHOUSE = "GET_DEFAULT_WAREHOUSE";
export const GET_DEFAULT_TERRIOTRY = "GET_DEFAULT_TERRIOTRY";
export const GET_PRICE_LIST = "GET_PRICE_LIST";
export const GET_DEFAULT_CUSTOMER_GROUP = "GET_DEFAULT_CUSTOMER_GROUP";
export const GET_COMPANY_ITEM_GROUP = "GET_COMPANY_ITEM_GROUP";
export const GET_BUYING_PRICE_LIST = "GET_BUYING_PRICE_LIST";

export const LOAD_RETURN_REASONS = "LOAD_RETURN_REASONS";

export const LOAD_MOTIF_REASONS = "LOAD_MOTIF_REASONS";
