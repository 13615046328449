import React from "react";
import Button from "@material-ui/core/Button";
import { Box, Typography, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 10,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

export const FormCoffre = (props) => {
  const { HandlePayment, setOpencoffre } = props;
  const { t } = useTranslation(["common", "checkout"]);
  const classes = useStyles();

  const handleAccept = () => {
    setOpencoffre(false);
    HandlePayment(true);
  };

  const handleRefuse = () => {
    setOpencoffre(false);
    HandlePayment(false);
  };

  return (
    <div className={classes.paper}>
      <Typography
        style={{ padding: 5, fontWeight: 600 }}
        align={"center"}
        color={"primary"}
      >
        {t("checkout:qcoffre")}
      </Typography>
      <Box
        mt={2}
        style={{
          display: "flex",
          flexDirection: "row",
          gap: 5,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button variant="contained" color="primary" onClick={handleAccept}>
          {t("common:yes")}
        </Button>
        <Button color="primary" variant="outlined" onClick={handleRefuse}>
          {t("common:no")}
        </Button>
      </Box>
    </div>
  );
};
