import React, {useEffect} from "react";
import {
    makeStyles, withStyles, Box, Modal, Grid, OutlinedInput, FormControl, Checkbox, MenuItem, Select, Step,
    StepLabel, Stepper, Typography, InputLabel, Button, IconButton, StepConnector, ClickAwayListener
} from "@material-ui/core";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ContactsIcon from '@material-ui/icons/Contacts';
import Check from '@material-ui/icons/Check';
import VideoLabelIcon from '@material-ui/icons/VideoLabel';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import RoomIcon from '@material-ui/icons/Room';
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
import {
    KeyboardDatePicker, MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import validator from 'validator';
import clsx from 'clsx';
import {useDispatch, useSelector} from "react-redux";
import {
    CreateClient,
    getAccountByCurrency,
    LoadClients,
    CreateAddressCustomer
} from "../../../../store/client/client.action";
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min';
import DateFnsUtils from "@date-io/date-fns";
import {formatDate} from '../../../../helpers/utils';
import {fr} from 'date-fns/esm/locale';
import {useTranslation} from "react-i18next";
import PropTypes from 'prop-types';
import localforage from "localforage";
import {AddressForm} from '../../../address/components/createForm';
import moment from "moment-timezone";

const QontoConnector = withStyles({
    alternativeLabel: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    active: {
        '& $line': {
            borderColor: '#784af4',
        },
    },
    completed: {
        '& $line': {
            borderColor: '#784af4',
        },
    },
    line: {
        borderColor: '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1,
    },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
    root: {
        color: '#eaeaf0',
        display: 'flex',
        height: 22,
        alignItems: 'center',
    },
    active: {
        color: '#784af4',
    },
    circle: {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
    completed: {
        color: '#784af4',
        zIndex: 1,
        fontSize: 18,
    },
});
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '0px solid #fff',
    boxShadow: 24,
    borderRadius: '5px',
    p: 4,
};

function QontoStepIcon(props) {
    const classes = useQontoStepIconStyles();
    const {active, completed} = props;

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
            })}
        >
            {completed ? <Check className={classes.completed}/> : <div className={classes.circle}/>}
        </div>
    );
};

QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
};

const ColorlibConnector = withStyles((theme) => ({
    alternativeLabel: {
        top: 22,
    },
    active: {
        '& $line': {
            backgroundImage:
                `linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,${theme.palette.primary.main} 100%)`,
        },
    },
    completed: {
        '& $line': {
            backgroundImage:
                `linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,${theme.palette.primary.main} 100%)`,
        },
    },
    line: {
        height: 3,
        border: 0,
        backgroundColor: '#eaeaf0',
        borderRadius: 1,
    },
}))(StepConnector);

const useColorlibStepIconStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    active: {
        backgroundColor: theme.palette.primary.main,
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    completed: {
        backgroundColor: theme.palette.primary.main,
    },
}));

function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const {active, completed} = props;

    const icons = {
        1: <ContactPhoneIcon/>,
        2: <ContactsIcon/>,
        3: <RoomIcon/>,
        4: <VideoLabelIcon/>,
    };

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed,
            })}
        >
            {icons[String(props.icon)]}
        </div>
    );
};

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
    formControl: {
        marginBottom: 15,
        "& .MuiInputBase-root": {
            background: theme.palette.white.main,
        },
        "& .MuiOutlinedInput-input": {
            padding: "8.9px 14px",
            fontSize: 12,
        },
        width: "100%"
    },
    paper: {
        width: "100%",
        "& .MuiInputBase-root": {
            background: theme.palette.white.main,
        },
        "& .MuiOutlinedInput-input": {
            padding: "8.9px 14px",
            fontSize: 12,
        },
        "& .MuiSelect-root": {
            padding: "0.4938rem 0.875rem",
        },
    },
    label: {
        fontSize: 12,
        marginBottom: 6,
        color: theme.palette.gray.main,
        paddingLeft: 9,
    },
    checkbox: {
        fontSize: 12,
        marginBottom: 6,
        color: theme.palette.gray.main
    },
    date: {
        marginBottom: 15,
    },
    firstCol: {
        [theme.breakpoints.up("md")]: {
            marginRight: 37,
        },
    },
    formBlock: {
        display: "flex",
        [theme.breakpoints.down("sm")]: {
            flexWrap: "wrap",
        },
    },
    icon: {
        borderRadius: 3,
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: theme.palette.blue.main,
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: theme.palette.blue.main,
        },
    },
    buttonicon: {
        marginLeft: -15,
        marginTop: 10
    },
    button: {},
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    stepPaper: {
        width: "65%",
    },
    container: {
        display: "flex",
        width: "100%",
        justifyContent: "center"
    },
    welcomeIcon: {
        borderRadius: 10,
        height: 250,
        width: 250,
        color: theme.palette.primary.main,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
    },
    finalStep: {
        justifyContent: "center",
        display: "flex",
        width: "100%",
    },
    spanText: {
        fontSize: 35,
        color: theme.palette.primary.main,
        marginTop: 20
    },
    error: {
        color: theme.palette.error.main,
        fontSize: 11,
    },
    paperHeight: {
        height: 400
    }
}));

export const ClientForm = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const {t} = useTranslation(["common", "clients"]);
    const [open, setOpen] = React.useState(false);
    const [activeStep, setActiveStep] = React.useState(0);
    const [statusMsg, setStatusMsg] = React.useState('');
    const globalDefaults = useSelector((state) => state.Login.globalDefaults);
    const companyacounts = useSelector((state) => state.Configuration.companyacounts);
    const defaultterriotry = useSelector((state) => state.Configuration.defaultterriotry);
    const defaultcustomergroup = useSelector((state) => state.Configuration.defaultcustomergroup);
    const [state, setState] = React.useState({
        doctype: "Customer",
        customer_name: "",
        customer_type: "Individual",
        customer_group: defaultcustomergroup,
        territory: defaultterriotry,
        email_id: "",
        mobile_no: "",
        address: "",
        city: "",
        pincode: "",
        address_line2: "1",
        state: "",
        primary_address: "",
        country: globalDefaults?.country,
        cin: "",
        newsletter: false,
        loyalty_program: "",
        default_currency: companyacounts?.default_currency,
        billing_currency: companyacounts?.default_currency,
        accounts: [
            {
                account: `${companyacounts?.default_receivable_account}`,
                doctype: "Party Account",
                parentfield: "accounts",
                parenttype: "Customer",
            }
        ],
        used_data: "non",
        facebook: "",
        instagram: "",
        WhatsApp: "",
        type_doc: "Passport",
        number_doc: "",
        birth_date: "",
        issued_by: "",
        expiration_date_doc: "",
        birthDate: ""
    });
    const [flagAddAdr, setFlagAddAdr] = React.useState(false);
    const [stateadr, setStateAdr] = React.useState({
        doctype: "Address",
        name: "",
        address_type: "",
        address_title: "",
        address_line1: "",
        city: "",
        country: globalDefaults?.country,
        email_id: "",
        phone: "",
        link_doctype: "Customer",
        link_name: "",
    });
    const submitadr = () => {
        if (stateadr.address_type !== "" && stateadr.address_title !== "" && stateadr.address_line1 !== "" && stateadr.city !== "" && stateadr.country !== ""
            && (stateadr.email_id === "" || validator.isEmail(stateadr.email_id)) &&
            (stateadr.phone === "" || validator.isMobilePhone(stateadr.phone))) {
            setStateAdr({...stateadr, 'link_name': state.customer_name});
            setOpen(false);
            setFlagAddAdr(true);
        }
    }
    const [validity_card, setValidity_card] = React.useState(formatDate(new Date(), "YYYY-MM-DD"));
    const [birth_date, setBirth_date] = React.useState(formatDate(new Date(), "YYYY-MM-DD"));
    const [expiration_date_doc, setExpiration_date_doc] = React.useState(formatDate(new Date(), "YYYY-MM-DD"));

    const handleDateChange = (date) => {
        setValidity_card(date);
    };
    const handleExpirationDateChange = (date) =>{
        setExpiration_date_doc(date);
    }
    /*const handleBirthDateChange = (date) =>{
        setBirth_date(date);
    }*/
    const [birthDate, setBirthDate] = React.useState(formatDate(new Date(), "YYYY-MM-DD"));

    const handleBirthDateChange = (date) => {
        setBirth_date(date);
        setBirthDate(date);
        if (date) {
            const age = calculateAge(date);
            setError({ birthDate: age < 18 });
        }
    };

    const calculateAge = (birthday) => {
        const currentDate = new Date();
        const birthDate = new Date(birthday);
        let age = currentDate.getFullYear() - birthDate.getFullYear();
        const monthDiff = currentDate.getMonth() - birthDate.getMonth();

        if (monthDiff < 0 || (monthDiff === 0 && currentDate.getDate() < birthDate.getDate())) {
            age--;
        }

        return age;
    };
    const [error, setError] = React.useState({
        customer_name: false,
        customer_type: false,
        customer_group: false,
        territory: false,
        email_id: false,
        mobile_no: false,
        number_doc: false,
        birth_date: false,
        issued_by: false,
        expiration_date_doc: false,
        city: false,
        state: false,
        country: false,
        pincode: false,
        birthDate: false
    });

    const [errorMsg, setErrorMsg] = React.useState({
        customer_name: null,
        customer_type: null,
        customer_group: null,
        territory: null,
        email_id: null,
        mobile_no: null,
        number_doc: null,
        issued_by: null,
        birth_date: null,
        birthDate: false
    });

    const country = useSelector(state => state.Setting.country);
    const loyaltyPrograms = useSelector(state => state.Client.loyaltyPrograms);
    const currencies = useSelector(state => state.Client.currencies);
    const territory = useSelector(state => state.Setting.territory);
    const customersgroup = useSelector(state => state.Client.customersgroup);
    const partyAccount = useSelector(state => state.Client.partyAccount);

    const handleNext = (activeStep) => {
        if (activeStep === 0) {
            setError({
                customer_name: state.customer_name === "",
                customer_type: state.customer_type === "",
                customer_group: state.customer_group === "",
                territory: null,
                email_id: (state.email_id === "" || !(validator.isEmail(state.email_id))),
                mobile_no: (state.mobile_no.length < 8 || !(validator.isMobilePhone(state.mobile_no))),
                facebook: (state.facebook !== "" && !(validator.isURL(state.facebook))),
                instagram: (state.instagram !== "" && !(validator.isURL(state.instagram))),
            });
            setErrorMsg({
                customer_name: state.customer_name === "" ? t("clients:nameError") : null,
                customer_type: state.customer_type === "" ? t("clients:nameError") : null,
                customer_group: state.customer_group === "" ? t("clients:nameError") : null,
                city: state.city === "" ? t("clients:nameError") : null,
                territory: null,
                email_id: (state.email_id === "" || !(validator.isEmail(state.email_id))) ? t("clients:emailError") : null,
                mobile_no: (state.mobile_no.length < 8 || !(validator.isMobilePhone(state.mobile_no))) ? t("clients:numError") : null,
                facebook: (state.facebook !== "" && !(validator.isURL(state.facebook))) ? t("clients:linkError") : null,
                instagram: (state.instagram !== "" && !(validator.isURL(state.instagram))) ? t("clients:linkError") : null,
            });

            if ((state.customer_name !== "") && (state.customer_type !== "") && (state.customer_group !== "") &&
                (state.email_id !== "" && (validator.isEmail(state.email_id))) && (state.mobile_no.length > 7 && (validator.isMobilePhone(state.mobile_no)))) {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
        } else if (activeStep === 1) {
                var isValid = false;
                if (state.number_doc === "" && state.issued_by === "" && state.birth_date === "" )
                {
                    setError({
                        number_doc : state.number_doc === "" ,
                        issued_by : state.issued_by === "",
                        birth_date: state.birth_date === ""
                    });
                    setErrorMsg({
                        number_doc: state.number_doc === "" ? t("clients:nameError") : null,
                        issued_by: state.issued_by === "" ? t("clients:nameError") : null,
                        birth_date: state.birth_date === "" ? t("clients:nameError") : null
                    });
                    isValid = false;

                } else {
                    setError({...error, number_doc: false , issued_by: false , birth_date: false });
                    isValid = true;
                }
                if (isValid && (state.number_doc !== "" && state.birth_date !== birth_date  && state.issued_by !== "" )) {
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                }

        }
        else if (activeStep === 2) {
                var isValid = false;
                if (state.territory === "") {
                    setError({...error, territory: true , city: true , country: true , state: true , pincode: true});
                    setErrorMsg({...errorMsg, territory: state.territory === "" ? t("clients:territoryError") : null, city: state.city === "" ? t("clients:nameError") : null, country: t("clients:nameError"), state: t("clients:nameError"), pincode: t("clients:nameError")
                    });
                    isValid = false;

                } else {
                    setError({...error, territory: false, state: false, city: false, pincode: false, country: false });
                    isValid = true;
                }
                if (isValid && (state.territory !== "" && state.state !== "" && state.city !== "" && state.country !== "" && state.pincode !== "")) {
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                }

        }
        else {
                setError({
                    ...error,
                    facebook: (state.facebook !== "" && !(validator.isURL(state.facebook))),
                    instagram: (state.instagram !== "" && !(validator.isURL(state.instagram))),
                });
                setErrorMsg({
                    ...errorMsg,
                    facebook: (state.facebook !== "" && !(validator.isURL(state.facebook))) ? t("clients:linkError") : null,
                    instagram: (state.instagram !== "" && !(validator.isURL(state.instagram))) ? t("clients:linkError") : null,
                });
                if ((state.facebook === "" || (validator.isURL(state.facebook))) && (state.instagram === "" || (validator.isURL(state.instagram)))) {
                    handleSubmit();
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                }
        }
    };
    const handleBack = () => {
        if (activeStep === 0) {
            history.goBack();
            return;
        }
        ;
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const handleReset = () => {
        setActiveStep(0);
        flagsubmit = false;
        setStatusMsg('');
        setError({
            customer_name: false,
            customer_type: false,
            customer_group: false,
            territory: false,
            email_id: false,
            mobile_no: false,
            city: false,
            state: false,
            country: false,
            pincode: false,
            expiration_date_doc: false,
            birth_date: false,
            issued_by: false
        });
        setErrorMsg({
            customer_name: null,
            customer_type: null,
            customer_group: null,
            territory: null,
            email_id: null,
            mobile_no: null,
            city:null,
            number_doc: null,
            issued_by: null,
            birth_date: null,
        });
        setState({
            doctype: "Customer",
            customer_name: "",
            customer_type: "Individual",
            customer_group: defaultcustomergroup,
            territory: defaultterriotry,
            email_id: "",
            mobile_no: "",
            address: "",
            city: "",
            pincode: "",
            address_line2: "1",
            primary_address: "",
            state: "",
            country: globalDefaults?.country,
            cin: "",
            newsletter: false,
            loyalty_program: "",
            default_currency: companyacounts?.default_currency,
            billing_currency: companyacounts?.default_currency,
            accounts: [
                {
                    account: `${companyacounts?.default_receivable_account}`,
                    doctype: "Party Account",
                    parentfield: "accounts",
                    parenttype: "Customer",
                }
            ],
            used_data: "non",
            facebook: "",
            instagram: "",
            WhatsApp: ""
        });
        setStateAdr({
            doctype: "Address",
            name: "",
            address_type: "",
            address_title: "",
            address_line1: "",
            city: "",
            country: globalDefaults?.country,
            email_id: "",
            phone: "",
            link_doctype: "Customer",
            link_name: "",


        });
        setFlagAddAdr(false);
    };

    const handleExit = () => {
        history.push("/clients");
        localforage.getItem("reduxPersist:Client", function (err, value) {
            dispatch(LoadClients(JSON.parse(value)?.clients));
        });
    };


    const handleChange = (event) => {
        setError({...error, [event.target.name]: false});
        setErrorMsg({...errorMsg, [event.target.name]: null});
        setState({...state, [event.target.name]: event.target.value});
    };

   /* const handleCurrencyChange = (event) => {
        setError({...error, [event.target.name]: false});
        setState({...state, [event.target.name]: event.target.value, billing_currency: event.target.value});
        dispatch(getAccountByCurrency(globalDefaults?.default_company, event.target.value));
    };*/

    const handleCheckbox = (event) => {
        setState({...state, newsletter: !state.newsletter});
    };
    var flagsubmit = false;

    const notification = useSelector((state) => state.Notification.notification);
    const handleSubmit = (event) => {
        const data = {
            doctype: state.doctype,
            customer_name: state.customer_name,
            customer_type: state.customer_type,
            customer_group: state.customer_group,
            territory: state.territory,
            email_id: state.email_id,
            mobile_no: state.mobile_no,
            mobile_phone: state.mobile_no,
            address: state.address,
            city: state.city,
            pincode: state.pincode,
            address_line2: state.address_line2,
            state: state.state,
            country: state.country,
            primary_address:state.primary_address,
            newsletter: state.newsletter,
            loyalty_program: state.loyalty_program,
            default_currency: state.default_currency,
            billing_currency: state.billing_currency,
            accounts: state?.accounts?.length != 0 ? state?.accounts : [
                {
                    account: `${companyacounts?.default_receivable_account}`,
                    doctype: "Party Account",
                    parentfield: "accounts",
                    parenttype: "Customer",
                }
            ],
            used_data: state.used_data,
            validity_card: formatDate(validity_card, "YYYY-MM-DD"),
            facebook: state.facebook,
            instagram: state.instagram,
            WhatsApp: state.WhatsApp,
            type_doc: state.type_doc,
            number_doc: state.number_doc,
            birth_date: formatDate(birth_date, "YYYY-MM-DD"),
            issued_by: state.issued_by,
            expiration_date_doc: formatDate(expiration_date_doc, "YYYY-MM-DD"),
            non_resident: "1"
        }
        dispatch(CreateClient(data));
        flagsubmit = true;

    };

    useEffect(() => {
        if (!flagsubmit) {
            let newAccount = [{
                account: partyAccount.length !== 0 ? partyAccount[0].name : `${companyacounts?.default_receivable_account}`,
                doctype: "Party Account",
                parentfield: "accounts",
                parenttype: "Customer"
            }]
            setState({...state, accounts: newAccount});
        }
    }, [partyAccount]);

    useEffect(() => {
        if (notification) {
            if (notification.code === 'success') {
                if (flagAddAdr === true) {
                    dispatch(CreateAddressCustomer(stateadr))
                    setFlagAddAdr(false)
                }
                setStatusMsg(t("common:opsuccess"))
            } else {
                setStatusMsg(t("common:opfailed"))
            }
        }
    }, [notification]);

    function getStepContent(step) {
        switch (step) {
            case 0:
                return (
                    <Box>
                        <Grid>
                            <InputLabel error={error.customer_name} required className={classes.label}>
                                {t("common:name")} <span
                                className={classes.error}>{errorMsg?.customer_name && "(" + errorMsg.customer_name + ")"}</span>
                            </InputLabel>
                            <FormControl variant="outlined" className={classes.formControl}>
                                <OutlinedInput
                                    id="outlined-adornment-weight"
                                    value={state.customer_name}
                                    error={error.customer_name}
                                    name="customer_name"
                                    onChange={handleChange}
                                    aria-describedby="outlined-weight-helper-text"
                                    inputProps={{
                                        "aria-label": "weight",
                                    }}
                                    labelWidth={0}
                                />
                            </FormControl>
                            <InputLabel error={error.customer_group} required className={classes.label}>
                                {t("common:customerGroup")} <span
                                className={classes.error}>{errorMsg?.customer_group && "(" + errorMsg.customer_group + ")"}</span>
                            </InputLabel>
                            <FormControl variant="outlined" className={classes.formControl}>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    className={classes.select}
                                    value={state.customer_group}
                                    name="customer_group"
                                    error={error.customer_group}
                                    IconComponent={ExpandMore}
                                    onChange={handleChange}
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left",
                                        },
                                        getContentAnchorEl: null,
                                    }}
                                >
                                    {Array.isArray(customersgroup) && customersgroup.map((row1, index) => (
                                        <MenuItem value={row1.value} key={row1.value}>
                                            {row1.title}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <InputLabel error={error.customer_type} required className={classes.label}>
                                {t("common:customerType")}
                            </InputLabel>
                            <FormControl variant="outlined" className={classes.formControl}>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    className={classes.select}
                                    value={state.customer_type}
                                    error={error.customer_type}
                                    name="customer_type"
                                    IconComponent={ExpandMore}
                                    onChange={handleChange}
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left",
                                        },
                                        getContentAnchorEl: null,
                                    }}
                                >
                                    <MenuItem value={"Company"}> {t("common:company")}</MenuItem>
                                    <MenuItem value={"Individual"}> {t("common:Indiv")}</MenuItem>
                                </Select>
                            </FormControl>
                            <InputLabel error={error.email_id} required
                                        className={classes.label}>{t("common:email")}<span
                                className={classes.error}>{errorMsg?.email_id && "(" + errorMsg.email_id + ")"}</span>
                            </InputLabel>
                            <FormControl className={classes.formControl} variant="outlined">
                                <OutlinedInput
                                    id="outlined-adornment-weight"
                                    value={state.email_id}
                                    error={error.email_id}
                                    name="email_id"
                                    onChange={handleChange}
                                    aria-describedby="outlined-weight-helper-text"
                                    inputProps={{
                                        "aria-label": "weight",
                                    }}
                                    labelWidth={0}
                                />
                            </FormControl>
                            <InputLabel error={error.mobile_no} required className={classes.label}>{t("common:phone")}
                                <span
                                    className={classes.error}>{errorMsg?.mobile_no && "(" + errorMsg.mobile_no + ")"}</span>
                            </InputLabel>
                            <FormControl className={classes.formControl} variant="outlined">
                                <OutlinedInput
                                    id="outlined-adornment-weight"
                                    value={state.mobile_no}
                                    error={error.mobile_no}
                                    name="mobile_no"
                                    onChange={handleChange}
                                    aria-describedby="outlined-weight-helper-text"
                                    inputProps={{
                                        "aria-label": "weight",
                                    }}
                                    labelWidth={0}
                                />
                            </FormControl>
                            {/*
                            <InputLabel className={classes.label} required>{t("common:currency")}</InputLabel>
                            <FormControl variant="outlined" className={classes.formControl}>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    className={classes.select}
                                    value={state.default_currency}
                                    IconComponent={ExpandMore}
                                    onChange={handleCurrencyChange}
                                    name="default_currency"
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left",
                                        },
                                        getContentAnchorEl: null,
                                    }}
                                >
                                    {Array.isArray(currencies) && currencies.map((row, index) => (
                                        <MenuItem value={row.name} key={row.name}>
                                            {row.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl> */}
                        </Grid>
                    </Box>
                );
            case 1:
                return (
                    <Box>
                        <Grid>
                            <InputLabel className={classes.label} >{t("common:Doc")}</InputLabel>
                            <FormControl className={classes.formControl} variant="outlined">
                                <OutlinedInput
                                    id="outlined-adornment-weight"
                                    value={state.type_doc}
                                    name="type_doc"
                                    disabled
                                    onChange={handleChange}
                                    aria-describedby="outlined-weight-helper-text"
                                    inputProps={{
                                        "aria-label": "weight",
                                    }}
                                    labelWidth={0}
                                />
                            </FormControl>
                            <InputLabel className={classes.label} error={error.number_doc} required>{t("common:Num_Doc")}<span
                                className={classes.error}>{errorMsg?.number_doc && "(" + errorMsg.number_doc + ")"}</span></InputLabel>
                            <FormControl className={classes.formControl}  variant="outlined">
                                <OutlinedInput
                                    id="outlined-adornment-weight"
                                    value={state.number_doc}
                                    error={error.number_doc}
                                    name="number_doc"
                                    onChange={handleChange}
                                    aria-describedby="outlined-weight-helper-text"
                                    inputProps={{
                                        "aria-label": "weight",
                                    }}
                                    labelWidth={0}
                                />
                            </FormControl>
                            <InputLabel className={classes.label} error={error.birth_date} required >{t("common:birth_day")}<span
                                className={classes.error}>{errorMsg?.birth_date && "(" + errorMsg.birth_date + ")"}</span></InputLabel>
                            <FormControl className={classes.formControl}   variant="outlined">
                                <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils} >
                                    <KeyboardDatePicker
                                        value={birth_date}
                                        error={error.birthDate}
                                        helperText={error.birthDate ? 'Age must be 18 or older' : ''}
                                        onChange={handleBirthDateChange}
                                        disableToolbar
                                        variant="inline"
                                        format="dd/MM/yyyy"
                                        inputVariant="outlined"
                                        id="date-picker-inline"
                                        keyboardIcon={<span className={"icon-calendar"} />}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </FormControl>
                            <InputLabel className={classes.label} required >{t("common:expiration")}</InputLabel>
                            <FormControl className={classes.formControl} required variant="outlined">
                                <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils} >
                                    <KeyboardDatePicker
                                        value={expiration_date_doc}
                                        onChange={handleExpirationDateChange}
                                        disablePast
                                        disableToolbar
                                        variant="inline"
                                        format="dd/MM/yyyy"
                                        inputVariant="outlined"
                                        id="date-picker-inline"
                                        keyboardIcon={<span className={"icon-calendar"}/>}
                                        KeyboardButtonProps={{
                                            "aria-label": "change date",
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </FormControl>
                            <InputLabel  className={classes.label} error={error.issued_by} required>{t("common:issued_by")}<span
                                className={classes.error}>{errorMsg?.issued_by && "(" + errorMsg.issued_by + ")"}</span></InputLabel>
                            <FormControl className={classes.formControl} variant="outlined">
                                <OutlinedInput
                                    id="outlined-adornment-weight"
                                    value={state.issued_by}
                                    error={error.issued_by}
                                    name="issued_by"
                                    onChange={handleChange}
                                    aria-describedby="outlined-weight-helper-text"
                                    inputProps={{
                                        "aria-label": "weight",
                                    }}
                                    labelWidth={0}
                                />
                            </FormControl>
                        </Grid>
                    </Box>
                );
            case 2:
                return (
                    <Box>
                        <Grid>
                            <InputLabel error={error.territory} required className={classes.label}>
                                {t("clients:territory")}<span
                                className={classes.error}>{errorMsg?.territory && "(" + errorMsg.territory + ")"}</span>
                            </InputLabel>
                            <FormControl variant="outlined" className={classes.formControl}>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    required
                                    className={classes.select}
                                    value={state.territory}
                                    error={error.territory}
                                    IconComponent={ExpandMore}
                                    onChange={handleChange}
                                    name="territory"
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left",
                                        },
                                        getContentAnchorEl: null,
                                    }}
                                >
                                    {Array.isArray(territory) && territory.map((row, index) => (
                                        <MenuItem value={row.value} key={row.value}>
                                            {row.title}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <InputLabel className={classes.label} error={error.city} required>{t("common:city")}<span
                                className={classes.error}>{errorMsg?.city && "(" + errorMsg.city + ")"}</span></InputLabel>
                            <FormControl className={classes.formControl} variant="outlined">
                                <OutlinedInput
                                    id="outlined-adornment-weight"
                                    required
                                    value={state.city}
                                    error={error.city}
                                    name="city"
                                    onChange={handleChange}
                                    aria-describedby="outlined-weight-helper-text"
                                    inputProps={{
                                        "aria-label": "weight",
                                    }}
                                    labelWidth={0}
                                />
                            </FormControl>
                            <InputLabel className={classes.label} error={error.state} required>{t("clients:state")}<span
                                className={classes.error}>{errorMsg?.state && "(" + errorMsg.state + ")"}</span></InputLabel>
                            <FormControl className={classes.formControl} variant="outlined">
                                <OutlinedInput
                                    id="outlined-adornment-weight"
                                    required
                                    value={state.state}
                                    error={error.state}
                                    name="state"
                                    onChange={handleChange}
                                    aria-describedby="outlined-weight-helper-text"
                                    inputProps={{
                                        "aria-label": "weight",
                                    }}
                                    labelWidth={0}
                                />
                            </FormControl>
                            <InputLabel className={classes.label} error={error.country} required>{t("clients:country")}<span
                                className={classes.error}>{errorMsg?.country && "(" + errorMsg.country + ")"}</span></InputLabel>
                            <FormControl variant="outlined" className={classes.formControl}>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    required
                                    className={classes.select}
                                    value={state.country}
                                    error={error.country}
                                    IconComponent={ExpandMore}
                                    onChange={handleChange}
                                    name="country"
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left",
                                        },
                                        getContentAnchorEl: null,
                                    }}
                                >
                                    {Array.isArray(country) && country.map((row, index) => (
                                        <MenuItem value={row.country_name} key={row.country_name}>
                                            {row.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <InputLabel error={error.pincode} className={classes.label} required>{t("clients:zip")}<span
                                className={classes.error}>{errorMsg?.pincode && "(" + errorMsg.pincode + ")"}</span></InputLabel>
                            <FormControl className={classes.formControl} variant="outlined">
                                <OutlinedInput
                                    id="outlined-adornment-weight"
                                    required
                                    value={state.pincode}
                                    error={error.pincode}
                                    name="pincode"
                                    error={error.pincode}
                                    type="number"
                                    onChange={handleChange}
                                    aria-describedby="outlined-weight-helper-text"
                                    inputProps={{
                                        "aria-label": "weight",
                                    }}
                                    labelWidth={0}
                                />
                            </FormControl>
                        </Grid>

                    </Box>
                );
            case 3:
                return (
                    <Box>
                        <Grid>
                            <InputLabel className={classes.label}>{t("clients:loyaltyPrgrm")}</InputLabel>
                            <FormControl variant="outlined" className={classes.formControl}>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    className={classes.select}
                                    value={state.loyalty_program}
                                    IconComponent={ExpandMore}
                                    onChange={handleChange}
                                    name="loyalty_program"
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left",
                                        },
                                        getContentAnchorEl: null,
                                    }}
                                >
                                    {Array.isArray(loyaltyPrograms) && loyaltyPrograms.map((row, index) => (
                                        <MenuItem value={row.name} key={row.name}>
                                            {row.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <InputLabel
                                error={error.facebook} className={classes.label}>
                                {t("clients:fb")}<span
                                className={classes.error}>{errorMsg?.facebook && "(" + errorMsg.facebook + ")"}</span>
                            </InputLabel>
                            <FormControl className={classes.formControl} variant="outlined">
                                <OutlinedInput
                                    id="outlined-adornment-weight"
                                    value={state.facebook}
                                    error={error.facebook}
                                    name="facebook"
                                    onChange={handleChange}
                                    aria-describedby="outlined-weight-helper-text"
                                    inputProps={{
                                        "aria-label": "weight",
                                    }}
                                    labelWidth={0}
                                />
                            </FormControl>
                            <InputLabel error={error.instagram} className={classes.label}>
                                {t("clients:insta")}<span
                                className={classes.error}>{errorMsg?.instagram && "(" + errorMsg.instagram + ")"}</span>
                            </InputLabel>
                            <FormControl className={classes.formControl} variant="outlined">
                                <OutlinedInput
                                    id="outlined-adornment-weight"
                                    value={state.instagram}
                                    error={error.instagram}
                                    name="instagram"
                                    onChange={handleChange}
                                    aria-describedby="outlined-weight-helper-text"
                                    inputProps={{
                                        "aria-label": "weight",
                                    }}
                                    labelWidth={0}
                                />
                            </FormControl>
                            <InputLabel className={classes.label}>
                                {t("clients:dataUsage")}
                            </InputLabel>
                            <FormControl variant="outlined" className={classes.formControl}>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    className={classes.select}
                                    value={state.used_data}
                                    IconComponent={ExpandMore}
                                    onChange={handleChange}
                                    name="used_data"
                                    MenuProps={{
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left",
                                        },
                                        getContentAnchorEl: null,
                                    }}
                                >
                                    <MenuItem value="oui">{t("common:yes")}</MenuItem>
                                    <MenuItem value="partielle">{t("clients:partielle")}</MenuItem>
                                    <MenuItem value="non">{t("common:no")}</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl className={classes.formControl} variant="outlined">
                                <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
                                    <Typography className={classes.label}>{t("clients:dataValDate")}</Typography>
                                    <KeyboardDatePicker
                                        value={validity_card}
                                        onChange={handleDateChange}
                                        disableToolbar
                                        variant="inline"
                                        format="dd/MM/yyyy"
                                        inputVariant="outlined"
                                        id="date-picker-inline"
                                        keyboardIcon={<span className={"icon-calendar"}/>}
                                        KeyboardButtonProps={{
                                            "aria-label": "change date",
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </FormControl>
                            <InputLabel className={classes.checkbox}>
                                <Checkbox
                                    className={classes.root}
                                    disableRipple
                                    value={state.newsletter}
                                    name="newsletter"
                                    color="default"
                                    onClick={handleCheckbox}
                                    checked={state.newsletter}
                                    checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)}/>}
                                    icon={<span className={classes.icon}/>}
                                    inputProps={{'aria-label': 'decorative checkbox'}}
                                />
                                {t("clients:newslttr")}
                            </InputLabel>
                        </Grid>
                    </Box>
                );
           default:
                return 'Unknown step';
        }
    };

    const steps = getSteps();

    function getSteps() {
        return [t("clients:nameContact"), t("plus d'information"), t("clients:addresses"), t("clients:lyty&social")];
    };

    return (
        <Box className={classes.paper}>

            <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector/>}>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <div>

                {activeStep === steps.length ? (
                    <div className={classes.container}>
                        <div className={classes.stepPaper}>
                            <div className={classes.paperHeight}>
                                <Box className={classes.finalStep}>
                                    <AccessibilityNewIcon className={classes.welcomeIcon}/>
                                </Box>
                                <Box className={classes.finalStep}>
                                    <div
                                        className={classes.spanText}>{statusMsg === '' ? t("clients:customer") + ' ' + state.customer_name + ' ' + t("clients:onboard") : statusMsg}</div>
                                </Box>
                            </div>
                            <Grid container justifyContent="space-between">
                                <Button variant="outlined" color="primary" onClick={handleReset}
                                        className={classes.button}>
                                    {t("clients:addotherclnt")}
                                </Button>
                                <Button variant="contained" color="primary" onClick={handleExit}
                                        className={classes.button}>
                                    {t("clients:exit")}
                                </Button>
                            </Grid>
                        </div>
                    </div>
                ) : (
                    <div className={classes.container}>
                        <div className={classes.stepPaper}>
                            <div className={classes.paperHeight}>{getStepContent(activeStep)}</div>
                            <Grid container justifyContent="space-between">
                                <Button color="primary" onClick={handleBack} className={classes.button}
                                        variant="outlined">
                                    {t("common:back")}
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleNext(activeStep)}
                                    className={classes.button}
                                >
                                    {activeStep === steps.length - 1 ? t("common:create") : t("clients:next")}
                                </Button>
                            </Grid>
                        </div>
                    </div>
                )}

                <Modal
                    open={open}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <Box sx={style}>
                        <Typography align={"center"} color={"primary"}>
                            {t("common:addadr")}
                        </Typography>
                        <br/> <br/>
                        <Box className={classes.root} display="flex">
                            <AddressForm state={stateadr} setState={setStateAdr} submit={submitadr}
                                         closingModal={setOpen}/>
                        </Box>
                    </Box>
                </Modal>

            </div>

        </Box>
    );
};
