import React, { useEffect } from "react";
import {
  Button,
  makeStyles,
  InputAdornment,
  IconButton,
  Typography,
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Box from "@material-ui/core/Box";
import InputLabel from "@material-ui/core/InputLabel";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Select from "@material-ui/core/Select";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import {
  GetPOSList,
  GetStores,
  SetSelectedStoreAndCashier,
  logout,
} from "../../../../store/login/login.action";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { timeout } from "../../../../helpers/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    marginTop: "5%",
    flexDirection: "column",
    alignItems: "center",
  },
  paper: {
    borderRadius: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "30%",
    padding: theme.spacing(3),
    backgroundColor: "#EEF2F6",
  },
  logo: {
    width: "30%",
    aspectRatio: "3/2",
    objectFit: "contain",
    mixBlendMode: "darken",
  },
  button: {
    width: "100%",
    marginTop: 21,
    padding: "0.9375rem !important",
    fontWeight: "600 !important",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

export const Coffer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation(["login", "common"]);

  const stores = useSelector((state) => state.Login.stores);
  const selectedData = useSelector((state) => state.Login.selectedData);
  const activeImageTheme = useSelector((state) => state.Theme.image);
  const globalDefaults = useSelector((state) => state.Login.globalDefaults);
  const userDetails = useSelector((state) => state.Login.userDetails);
  const user = useSelector((state) => state.Login.user);

  const handleChange = (event) => {
    const contactData = stores.filter((s) => s.name === event.target.value);
    dispatch(
      SetSelectedStoreAndCashier({
        store: event.target.value,
        contact: contactData,
        cashier: null,
      })
    );
  };

  const handleValidateWarehouse = (event) => {
    history.push("/initialization");
  };

  const handleLogout = async () => {
    await dispatch(logout());
    await timeout(2000);
    history.push("/login");
  };

  useEffect(() => {
    if (globalDefaults?.default_company) {
      dispatch(GetStores(globalDefaults?.default_company, user));
      dispatch(GetPOSList());
    }
  }, [globalDefaults]);

  return (
    <>
      <div style={{ position: "absolute" }}>
        <IconButton onClick={handleLogout}>
          <ExitToAppIcon color="error" />
        </IconButton>
      </div>
      <Box className={classes.root}>
        <Box className={classes.paper}>
          <img src={activeImageTheme} alt={"logo"} className={classes.logo} />
          <Typography color="textSecondary">
            Bonjour, {userDetails?.full_name}
          </Typography>
          <Box mt={2} width={"100%"}>
            <FormControl variant="outlined" style={{ marginBottom: 20 }}>
              <InputLabel id="demo-simple-select-outlined-label">
                Store
              </InputLabel>
              <Select
                startAdornment={
                  <InputAdornment position="start">
                    <span className="icon-shop" />
                  </InputAdornment>
                }
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={selectedData ? selectedData.store : ""}
                IconComponent={ExpandMoreIcon}
                style={{ backgroundColor: "#FFF" }}
                onChange={handleChange}
                label="Store"
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
                input={
                  <OutlinedInput
                    label="Store"
                    name="Store"
                    id="demo-simple-select-outlined-label"
                  />
                }
              >
                {Array.isArray(stores) &&
                  stores.map((store, index) => (
                    <MenuItem key={index} value={store.name}>
                      {store.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
          <Box mt={2} width={"100%"}>
            <Button
              disabled={!selectedData?.store}
              onClick={handleValidateWarehouse}
              className={classes.button}
              size={"large"}
              variant="contained"
              color="primary"
            >
              {t("common:valider")}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};
