import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: "2rem",
        display: "flex"
    },
    formControl: {
        marginBottom: 15,
        "& .MuiInputBase-root": {
            background: theme.palette.white.main,
        },
        "& .MuiOutlinedInput-input": {
            padding: "8.9px 14px",
            fontSize: 12,
        },
        width: "100%"
    },
    paper: {
        width: "100%",
        "& .MuiInputBase-root": {
            background: theme.palette.white.main,
        },
        "& .MuiOutlinedInput-input": {
            padding: "8.9px 14px",
            fontSize: 12,
        },
        "& .MuiSelect-root": {
            padding: "0.4938rem 0.875rem",
        },
    },
    label: {
        fontSize: 12,
        marginBottom: 6,
        color: theme.palette.gray.main,
        paddingLeft: 9,
    },
    checkbox: {
        fontSize: 12,
        marginBottom: 6,
        color: theme.palette.gray.main
    },
    date: {
        marginBottom: 15,
    },
    firstCol: {
        [theme.breakpoints.up("md")]: {
            marginRight: 37,
        },
    },
    formBlock: {
        display: "flex",
        [theme.breakpoints.down("sm")]: {
            flexWrap: "wrap",
        },
    },
    icon: {
        borderRadius: 3,
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: theme.palette.blue.main,
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: theme.palette.blue.main,
        },
    },
    buttonicon: {
        marginLeft: -15,
        marginTop: 10
    },
    button: {},
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    stepPaper: {
        width: "50%",
    },
    container: {
        display: "flex",
        width: "100%",
        justifyContent: "center"
    },
    welcomeIcon: {
        borderRadius: 10,
        height: 250,
        width: 250,
        color: theme.palette.primary.main,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
    },
    finalStep: {
        justifyContent: "center",
        display: "flex",
        width: "100%",
    },
    spanText: {
        fontSize: 35,
        color: theme.palette.primary.main,
        marginTop: 20
    },
    error: {
        color: theme.palette.error.main,
        fontSize: 11,
    },
    paperHeight: {
        height: 400
    }
}));
