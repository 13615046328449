import { makeStyles } from '@material-ui/core/styles';

export const  useStyles = makeStyles((theme) => ({
  root : {
    padding :"1.0625rem 1.5rem",
  },
  price: {
    fontWeight: 600,
  },
  filterList : {
      borderTop : 'none'
  },
  paper: {
    position: "absolute",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 10,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  },
  button: {
    marginLeft: 10,
    height:50
  },
  input: {
    marginRight: 10,
    marginBottom: 15,
    "& .MuiInputBase-root": {
      background: theme.palette.white.main,
    },
    "& .MuiOutlinedInput-input": {
      padding: "8.9px 14px",
      fontSize: 12,
    },
  },
  total: {
    marginTop: 20,
    fontSize: 20
  },
  addButton: {
    marginTop: 20,
  },
  backLink : {
    fontSize : 12,
    color : '#2F334D',
    display :"flex",
    alignItems : "center",
    cursor :"pointer"
},
backBox : {
  marginTop : "0.5rem",
  width : "fit-content"
},
button: {
  fontSize: 14,
  backgroundColor: theme.palette.productsTable.button,
  color: theme.palette.productsTable.buttonColor,
  "&:hover": {
    backgroundColor: theme.palette.productsTable.button,
  },
},
print: {
  display: "none",
},
positive:{
  color: "green"
},
negative:{
  color: "red"
},
ticket : {
  '&:after' : {
    content: '""',
    display: "block",
    height: "70px",
    backgroundImage: "url(/img/border.png)",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center bottom",
    marginTop : '-1.8625rem',
  }
},
ticketBlock : {
  textAlign : "center",
  boxShadow: "0px 5px 15px 4px #0000000F",
  [theme.breakpoints.up('md')]: {
    minWidth : 420
  },
  fontFamily: 'Courier Prime',
  color : "#707070",
  fontSize : 14,
  padding : "2rem 0.5rem",
  lineHeight: 2
},
ticketHeader : {
  marginBottom: "7px",
  textAlign : "center",
  fontWeight: "bold"
},
}));