import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  result: {
    padding: "1.5625rem 0rem",
    fontSize: "2.8125rem",
    borderBottom: "2px solid",
    borderBottomColor: "#e3e9ed",
    marginBottom: 7,
    textAlign: "center",
  },
}));

export const Result = (props) => {
  const classes = useStyles();
  const amount = useSelector((state) => state.Checkout.amount);
  const exchangeRate = useSelector((state) => state.Checkout.exchangeRate);

  return (
    <Box className={classes.result}>
      {parseFloat(amount?.depositAmount) % 1 !== 0
        ? parseFloat(amount?.depositAmount).toFixed(3)
        : parseFloat(amount?.depositAmount)}
      {amount?.depositAmount?.toString().slice(-1) == "." && "."}
    </Box>
  );
};
