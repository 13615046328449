import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  card : {
    background: theme.palette.secondary.main,
    borderRadius: 10,
    border : "none"
  },
  cardBoldText : {
    color: theme.palette.primary.main,
  },
  cardTitleBlock : {
    borderBottom: "1px solid #e7eaef",
    padding: "10px 20px 17px"
  },
  cardTitle : {
    textAlign : 'center'
  },
  cardBoldText : {
    color: theme.palette.primary.main,
  },
  cardLightText : {
    color: theme.palette.primary.main,
    fontSize : 14
  },
  cardBoldText : {
    color: theme.palette.primary.main,
    fontWeight : 600,
    fontSize : 14
  },

})
)