import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    main : {
        padding: "0px 50px"
    },
    header: {
        padding: "30px 0"
      },
    logo: {
      textAlign: "center",
      marginBottom: 10
    },
    title: {
      borderTop: '1px solid  #5D6975',
      borderBottom: '1px solid  #5D6975',
      color: '#5D6975',
      fontSize: '30px',
      lineHeight: '1.4em',
      fontWeight: 'normal',
      textAlign: 'center',
      margin: '0 0 20px 0'
    },
    orders: {
      float: 'center',
      textAlign: 'center'
    },
    project: {
      float: 'left',
      marginTop: 30,
      marginBottom: 100
    },

  projectitem:{
      color: '#5D6975',
      textAlign: 'left',
      width: '52px',
      fontSize: '0.8em',
      marginRight: 10,
  },

  paper: {
    position: "absolute",
    width: "auto",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 0.5),
    borderRadius: 10,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
    border: `${theme.palette.blue.main} 2px solid`,
  },
}));