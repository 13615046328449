import { createStore, applyMiddleware, compose } from "redux";
import { offline } from "@redux-offline/redux-offline";
import thunk from "redux-thunk";
import { rootReducer } from "./reducers";

import { NotificationMiddlware } from "./middlewares/notification";
import { CustomofflineConfig } from "./offlineConfig";
import offlineChain from "redux-offline-chain";
import checkAuthMiddleware from "./middlewares/checkAuthMiddleware";

let composeEnhancers = compose;
composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(
  rootReducer,
  composeEnhancers(
    offline(CustomofflineConfig),
    applyMiddleware(
      NotificationMiddlware,
      offlineChain,
      thunk,
      checkAuthMiddleware
    )
  )
);
