import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  Typography,
  Box,
  Grid,
  CardMedia,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import clsx from "clsx";
import { useTableStyles } from "../../../../theme/table.style";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  LoadProductStockById,
  ClearStockProduct,
} from "../../../../store/product/product.action";
import { Loader } from "../../../../components/loader";
import { SimpleTable } from "../../../../components/simpleTable";
import { useTranslation } from "react-i18next";
import { removeHTML } from "../../../../helpers/utils";
import axiosInstance from "../../../../history/axiosInstance";

export const useStyles = makeStyles((theme) => ({
  card: {
    background: theme.palette.secondary.main,
    borderRadius: 10,
    border: "none",
    minHeight: 500,
  },
  cardBoldText: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: 14,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    "&:hover": {
      overflow: "visible",
    },
  },
  cardTitle: {
    fontSize: 16,
    textAlign: "center",
  },
  description: {
    fontSize: 16,
    textAlign: "center",
  },
  cardTitleBlock: {
    borderBottom: "1px solid #e7eaef",
    padding: "10px 20px 17px",
  },
  cardContentBlock: {
    padding: "1.4375rem 1.5rem 2.0625rem",
  },
  titleblock: {
    position: "relative",
    borderBottom: "0.0625rem solid #f3f4f6",
    padding: "0.8125rem 0rem 1.75rem 0rem",
  },
  closeicon: {
    position: "absolute",
    top: 18,
    color: theme.palette.primary.main,
    width: 23,
    height: 23,
    cursor: "pointer",
  },
  block: {
    padding: "0rem 1.75rem",
    marginBottom: "6.9375rem",
  },
  title: {
    fontWeight: 600,
  },
  cardLightText: {
    color: theme.palette.primary.main,
    fontSize: 14,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    "&:hover": {
      overflow: "visible",
    },
  },
  img: {
    marginLeft: "auto",
    marginRight: "auto",
    height: "100%",
    width: "50%",
    aspectRatio: "3/3",
    objectFit: "contain",
  },
  imgBox: {
    padding: "30px 0px",
  },
  defaultImg: {
    opacity: 0.15,
    display: "block",
    margin: "auto",
  },
  ref: {
    display: "block",
    textAlign: "center",
    marginTop: 5,
    color: theme.palette.gray.main,
    fontSize: 12,
    fontWeight: 500,
  },
  listTitle: {
    textAlign: "center",
    marginTop: 15,
    color: theme.palette.primary.main,
    fontSize: 20,
    fontWeight: 500,
  },
  paper: {
    overflow: "auto",
    padding: "30px 0",
    marginLeft: 100,
  },
  root: {
    marginTop: 27,
  },
}));

export const StockProduct = () => {
  const classes = useStyles();
  const tableclasses = useTableStyles();
  const history = useHistory();
  const { t } = useTranslation(["common", "product"]);
  const { id } = useParams();
  const productCode = decodeURIComponent(id);
  const dispatch = useDispatch();
  const productStock = useSelector((state) => state.Product.productStock);
  const products = useSelector((state) => state.Product.products);
  const activeImageTheme = useSelector((state) => state.Theme.image);
  const globalDefaults = useSelector((state) => state.Login.globalDefaults);

  const stores = useSelector((state) => state.Login.stores);
  const imageList = useSelector((state) => state.Product.imagesData);

  const [productDetails, setProductDetails] = useState(null);
  const [variants, setVariants] = useState([]);
  const [variantsStock, setVariantsStock] = useState([]);
  const [loading, setLoading] = useState(false);

  const columns = [
    { key: "warehouse", title: "common:magasin" },
    { key: "actual_qty", title: "common:qteActuelle" },
    { key: "projected_qty", title: "common:qteProjetee" },
    { key: "reserved_qty", title: "common:qteReservee" },
  ];

  const getProductDetails = () => {
    var data = products.filter((p) => p.item_code == productCode);
    if (data && data[0]) setProductDetails(data[0]);
  };

  function getVariantsStockLevel(variantsList) {
    setLoading(true);
    const promises = variantsList.map((variant) => {
      return axiosInstance.post(
        `${process.env.REACT_APP_API_URI}/api/stock/dashboard`,
        {
          item_code: variant?.item_code,
        },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
            Authorization: JSON.parse(localStorage.getItem("user")).token,
          },
        }
      );
    });

    Promise.all(promises)
      .then((responses) => {
        let result = [];
        responses.forEach(({ data }, index) => {
          if (data) {
            result.push(data?.message);
          }
        });
        setVariantsStock(result.flat());
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    const itemIndex = products?.findIndex(
      (el) => el?.item_code === productCode
    );
    if (itemIndex === -1) {
      dispatch(LoadProductStockById(productCode));
    } else {
      if (products[itemIndex].has_variants === 1) {
        const variantList = products?.filter(
          (el) => el?.variant_of === productCode
        );
        setVariants(variantList);
        getVariantsStockLevel(variantList);
      } else {
        dispatch(LoadProductStockById(productCode));
      }
    }
  }, []);

  function getImgUrl(itemCode) {
    if (imageList && imageList?.itemImages) {
      const IIndex = imageList?.itemImages?.findIndex(
        (e) => e.itemCode === itemCode
      );
      if (IIndex !== -1) {
        return (
          `${process.env.REACT_APP_API_URI}` +
          imageList?.itemImages?.[IIndex]?.imgUrl
        );
      } else {
        return activeImageTheme;
      }
    } else {
      return activeImageTheme;
    }
  }

  useEffect(() => {
    getProductDetails();
  }, [products]);

  const handleClose = async () => {
    await dispatch(ClearStockProduct());
    history.goBack();
  };

  function InventoryTable({ data, variants }) {
    const itemNames = Array.from(
      new Set(variants.map((item) => item.item_name))
    );
    const warehouses = Array.from(new Set(stores?.map((item) => item.name)));

    return (
      <div>
        <Table className={tableclasses.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={tableclasses.tablecellHeader}>
                Item
              </TableCell>
              {warehouses.map((warehouse) => (
                <TableCell
                  className={tableclasses.tablecellHeader}
                  key={warehouse}
                >
                  {warehouse}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {itemNames.map((itemName) => (
              <TableRow key={itemName} className={tableclasses.tableRow}>
                <TableCell className={tableclasses.tableCell}>
                  {itemName}
                </TableCell>
                {warehouses.map((warehouse) => {
                  const item = data.find(
                    (item) =>
                      item.warehouse === warehouse &&
                      item.item_name === itemName
                  );
                  return (
                    <TableCell
                      className={tableclasses.tableCell}
                      key={`${itemName}-${warehouse}`}
                    >
                      {item ? item.actual_qty : "-"}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    );
  }

  return (
    <Box className={classes.block}>
      <Box className={classes.titleblock}>
        <span
          onClick={handleClose}
          className={clsx(classes.closeicon, "icon-close")}
        />
        <Typography
          align={"center"}
          className={classes.title}
          color={"primary"}
          variant="h5"
        >
          {productDetails && productDetails.item_name}
          {productDetails && " - " + productDetails.item_group}
        </Typography>
      </Box>
      <Grid container spacing={2} className={classes.root}>
        <Grid item xs={5}>
          <Card
            className={clsx(classes.card, classes.cardBoldText)}
            variant="outlined"
          >
            <CardMedia
              title={productDetails?.item_name}
              className={classes.img}
              image={getImgUrl(productDetails?.item_code)}
            />
            <CardContent>
              <Box className={classes.cardTitleBlock}>
                <Typography
                  className={clsx(classes.cardTitle, classes.cardBoldText)}
                >
                  {t("common:ref") + ": " + productCode}
                </Typography>
                <Typography
                  align={"center"}
                  variant="body2"
                  color="textSecondary"
                  component="p"
                >
                  {variants?.length ? variants?.length + " Variants" : null}
                </Typography>
                <Typography className={classes.description}>
                  {productDetails ? removeHTML(productDetails.description) : ""}
                </Typography>
              </Box>
              <Grid
                container
                spacing={3}
                className={classes.cardContentBlock}
                display="flex"
                flexWrap="wrap"
              >
                <Grid item xs={5}>
                  {productDetails?.item_name && (
                    <Typography
                      className={classes.cardLightText}
                      variant="caption"
                      display="block"
                    >
                      {t("product:nomArticle")}
                    </Typography>
                  )}
                  {productDetails?.item_group && (
                    <Typography
                      className={classes.cardLightText}
                      variant="caption"
                      display="block"
                    >
                      {t("product:itemgroup")}
                    </Typography>
                  )}
                  {productDetails?.item_collection && (
                    <Typography
                      className={classes.cardLightText}
                      variant="caption"
                      display="block"
                    >
                      {t("product:itemcollection")}
                    </Typography>
                  )}
                  {productDetails?.item_tax_template && (
                    <Typography
                      className={classes.cardLightText}
                      variant="caption"
                      display="block"
                    >
                      {t("product:tax")}
                    </Typography>
                  )}
                  <Typography
                    className={classes.cardLightText}
                    variant="caption"
                    display="block"
                  >
                    {t("product:stockInitial")}
                  </Typography>
                  {productDetails?.default_material_request_type && (
                    <Typography
                      className={classes.cardLightText}
                      variant="caption"
                      display="block"
                    >
                      {t("product:TypeCommande")}
                    </Typography>
                  )}
                  {productDetails?.is_stock_item && (
                    <Typography
                      className={classes.cardLightText}
                      variant="caption"
                      display="block"
                    >
                      {t("product:disponibilite")}
                    </Typography>
                  )}
                  <Typography
                    className={classes.cardLightText}
                    variant="caption"
                    display="block"
                  >
                    {t("common:prix")}
                  </Typography>
                </Grid>
                {productDetails ? (
                  <Grid item xs={7}>
                    {productDetails?.item_name && (
                      <Typography
                        className={classes.cardBoldText}
                        variant="caption"
                        display="block"
                      >
                        {productDetails?.item_name}
                      </Typography>
                    )}
                    {productDetails?.item_group && (
                      <Typography
                        className={classes.cardBoldText}
                        variant="caption"
                        display="block"
                      >
                        {productDetails.item_group}
                      </Typography>
                    )}
                    {productDetails?.item_collection && (
                      <Typography
                        className={classes.cardBoldText}
                        variant="caption"
                        display="block"
                      >
                        {productDetails?.item_collection}
                      </Typography>
                    )}
                    {productDetails?.item_tax_template && (
                      <Typography
                        className={classes.cardBoldText}
                        variant="caption"
                        display="block"
                      >
                        {productDetails?.item_tax_template}
                      </Typography>
                    )}
                    {productDetails?.opening_stock && (
                      <Typography
                        className={classes.cardBoldText}
                        variant="caption"
                        display="block"
                      >
                        {productDetails?.opening_stock}
                      </Typography>
                    )}
                    {productDetails?.default_material_request_type && (
                      <Typography
                        className={classes.cardBoldText}
                        variant="caption"
                        display="block"
                      >
                        {productDetails?.default_material_request_type}
                      </Typography>
                    )}
                    {productDetails?.is_stock_item && (
                      <Typography
                        className={classes.cardBoldText}
                        variant="caption"
                        display="block"
                      >
                        {productDetails?.is_stock_item == 1
                          ? t("product:inStock")
                          : t("product:outOfStock")}
                      </Typography>
                    )}
                    <Typography
                      className={classes.cardBoldText}
                      variant="caption"
                      display="block"
                    >
                      {productDetails.standard_rate != null
                        ? productDetails.standard_rate.toFixed(3)
                        : productDetails.price_list_rate.toFixed(3)}{" "}
                      {globalDefaults?.default_currency}
                    </Typography>
                  </Grid>
                ) : (
                  ""
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={7}>
          {products?.filter((p) => p?.item_code === productCode)?.[0]
            ?.has_variants === 1 ? (
            <>
              {loading ? (
                <Loader />
              ) : (
                <InventoryTable data={variantsStock} variants={variants} />
              )}
            </>
          ) : (
            <>
              {!productStock ? (
                <Loader />
              ) : (
                <SimpleTable
                  title={"stockDetails"}
                  columns={columns}
                  data={productStock}
                />
              )}
            </>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
