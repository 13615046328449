import { RedirectTo } from "../page/page.action";

export const NotificationMiddlware = (store) => (next) => (action) => {
  let result = next(action);
  if (typeof action === "object") {
    if (
      !window.navigator.onLine &&
      (action.type.indexOf("CREATE") !== -1 ||
        action.type.indexOf("GET") !== -1)
    ) {
      store.dispatch({
        type: "SET_NOTIFICATION",
        notification: {
          code: "warning",
          message: "notification:offlineChangeSaved",
        },
      });
    }
    if (action && action.type && action.type.indexOf("SUCCESS") !== -1) {
      store.dispatch({
        type: "SET_NOTIFICATION",
        notification: action.meta?.notification,
      });
    }
    if (action && action.meta && action.meta.redirectTo) {
      store.dispatch(RedirectTo(action.meta.redirectTo));
    }
    // if (action && action.type && action.type.indexOf("FAIL") !== -1) {
    //   store.dispatch({
    //     type: "SET_NOTIFICATION",
    //     notification: action.meta.notification,
    //   });
    // }
  }

  return result;
};
