import React, { useEffect, useRef, useState } from "react";
import Box from "@material-ui/core/Box";
import { useStyles } from "../../style";
import {
  Button,
  Modal,
  Typography,
  OutlinedInput,
  InputLabel,
  FormControl,
  IconButton,
} from "@material-ui/core";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import clsx from "clsx";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useHistory, useParams } from "react-router-dom";
import { useTableStyles } from "../../../../styles/table.style";
import { useSelector, useDispatch } from "react-redux";
import { GetOrderdProducts } from "../../../../helpers/helper";
import {
  CreateOrderPack,
  CreateOrderInPacking,
  LoadFullfilmentsOrderById,
  CreateStatusEmail,
  createdeliverynote,
  SetMappedDeliveryNote,
} from "../../../../store/fullfilment/fullfilment.action";
import { SetNotification } from "../../../../store/notification/notification.action";
import { useTranslation } from "react-i18next";
import Barcode from "react-barcode";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import validator from "validator";
import FormModal from "../../../../components/common/formModal";
import axiosInstance from "../../../../history/axiosInstance";
import PrintIcon from "@material-ui/icons/Print";
import PrintInvoiceTemplate from "../../../../components/componentToPrint/PrintInvoiceTemplate";

export const FulfillementPacking = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams();
  const componentRef = useRef();
  const { t } = useTranslation(["order", "common", "fulfillment"]);
  const tableclasses = useTableStyles();
  const dispatch = useDispatch();
  const products = useSelector((state) => state.Product.products);
  const order = useSelector((state) => state.Fullfilment.order);
  const globalDefaults = useSelector((state) => state.Login.globalDefaults);
  const clients = useSelector((state) => state.Client.clients);
  const companyacounts = useSelector(
    (state) => state.Configuration.companyacounts
  );
  const mappedSalesDeliveryNote = useSelector(
    (state) => state.Fullfilment.mappedSalesDeliveryNote
  );

  const [open, setOpen] = useState(false);
  const [orderdProducts, setOrderdProducts] = useState([]);
  const [salesorder, setSalesorder] = useState({});
  const [customerDetails, setCustomerDetails] = useState({});
  const [deliverynote, setDeliverynote] = useState(null);
  const [barcodeValue, setBarcodeValue] = useState("");
  const [packDetails, setPackDetails] = useState({
    length: 0,
    height: 0,
    width: 0,
    weight: 0,
  });

  const header = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
    Authorization: JSON.parse(localStorage.getItem("user"))?.token,
  };

  const SaveDeliveryNote = () => {
    let client = null;
    clients.forEach((cl) => {
      if (cl?.name === order?.customer_name) {
        client = cl;
      }
    });
    setCustomerDetails(client);
    const selectedItems = [];
    const taxes = [];

    orderdProducts?.forEach((el) => {
      if (el?.quantityToPack && el?.quantityToPack > 0) {
        selectedItems.push({
          doctype: "Delivery Note Item",
          item_group: el?.item_group,
          item_code: el?.item_code,
          item_name: el?.item_name,
          description: el?.description,
          warehouse: order?.point_of_sales,
          item_tax_template: el?.item_tax_template,
          against_sales_order: order?.order_id,
          brand: null,
          qty: el?.quantityToPack,
        });
      }
    });

    return {
      doctype: "Delivery Note",
      __unsaved: 1,
      __islocal: 1,
      company: globalDefaults?.default_company,
      currency: globalDefaults?.default_currency,
      selling_price_list: companyacounts?.pricelist,
      ignore_pricing_rule: 1,
      group_same_items: 0,
      items: selectedItems,
      taxes: salesorder?.taxes,
      customer: client?.name,
      contact_email: client?.email_id,
      contact_mobile: client?.mobile_no,
      customer_name: client?.customer_name,
      customer_group: client?.customer_group,
      address_display: order?.delivery_address,
      territory: client?.territory,
      is_internal_customer: 0,
      set_warehouse: order?.point_of_sales,
      set_posting_time: 1,
    };
  };

  const getsalesorder = () => {
    return axiosInstance
      .get(
        `${process.env.REACT_APP_API_URI}/api/details?doctype=Sales+Order&name=${order?.order_id}`,
        { headers: header }
      )
      .then(({ data }) => {
        if (Array.isArray(data?.docs)) {
          setSalesorder(data?.docs[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const makeMappedDeliveryNote = (id) => {
    fetch(`${process.env.REACT_APP_API_URI}/api/make-mapped-doc`, {
      method: "POST",
      headers: header,
      body: JSON.stringify({
        method:
          "erpnext.selling.doctype.sales_order.sales_order.make_delivery_note",
        source_name: id,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data?.message) {
          dispatch(SetMappedDeliveryNote(data?.message));
        }
      });
  };

  useEffect(() => {
    setOrderdProducts(
      order && order.products
        ? GetOrderdProducts([...products], order.products)
        : []
    );
    order?.order_id && makeMappedDeliveryNote(order?.order_id);
    order?.order_id && getsalesorder();
  }, [order]);

  useEffect(async () => {
    if (globalDefaults?.default_company) {
      await dispatch(LoadFullfilmentsOrderById(id));
    }
  }, [globalDefaults?.default_company]);

  const CheckQuantityPacked = () => {
    const data = orderdProducts.filter(
      (p) => p.quantityToPack && p.quantityToPack !== 0
    );
    return data.length == 0;
  };

  const handleSubmit = async () => {
    const ProductsToPack = [];
    var AllProductsIsPacked = true;
    orderdProducts.forEach((element) => {
      var qty2Pack = parseInt(element.quantityToPack);
      element.quantityPacked = element.quantityPacked
        ? element.quantityPacked
        : 0;
      element.quantityPacked = qty2Pack
        ? element.quantityPacked + qty2Pack
        : element.quantityPacked;
      ProductsToPack.push({
        item_code: element.item_code,
        quantity: element.quantity,
        quantityPacked: element.quantityPacked,
      });
      if (element.quantityPacked != element.quantity) {
        AllProductsIsPacked = false;
      }
    });
    const data = {
      order_id: order.order_id,
      products: JSON.stringify(ProductsToPack),
      status: AllProductsIsPacked ? "packed" : "in packing",
      redirectToTab: AllProductsIsPacked ? 4 : 3,
      packDetails: JSON.stringify(packDetails),
    };

    let selectedItems = [];
    orderdProducts?.forEach((el) => {
      if (el?.quantityToPack && el?.quantityToPack > 0) {
        mappedSalesDeliveryNote?.items?.forEach((item) => {
          selectedItems.push({
            ...item,
            qty: el?.quantityToPack,
          });
        });
      }
    });

    const deliveryNoteData = {
      ...mappedSalesDeliveryNote,
      items: selectedItems,
    };
    if (AllProductsIsPacked) {
      await dispatch(CreateOrderPack(data, deliveryNoteData));
    } else {
      await dispatch(CreateOrderInPacking(data, deliveryNoteData));
    }
    if (validator.isEmail(order.customer_email)) {
      const pack = {
        order_id: order.order_id,
        products: JSON.stringify(ProductsToPack),
        status: AllProductsIsPacked ? "packed" : "in packing",
        additional_discount_percentage: order.additional_discount_percentage,
        age: order.age,
        company: order.company,
        created_at: order.created_at,
        customer_email: order.customer_email,
        customer_name: order.customer_name,
        delivery_date: order.delivery_date,
        discount_amount: order.discount_amount,
        grand_total: order.grand_total,
        id: order.id,
        packs: order.packs,
        point_of_sales: order.point_of_sales,
        recipient: order.recipient,
        sales_date: order.sales_date,
        total_amount: order.total_amount,
        updated_at: order.updated_at,
      };
      await dispatch(CreateStatusEmail(pack.customer_email, pack));
    }
    setOpen(false);
    setPackDetails({
      length: 0,
      height: 0,
      width: 0,
      weight: 0,
    });
  };

  const HandleQuantityChange = (onAdd, id) => {
    const ItemIndex = orderdProducts.findIndex((s) => s.item_code == id);
    const data = [...orderdProducts];
    if (ItemIndex != -1) {
      data[ItemIndex].quantityToPack = data[ItemIndex].quantityToPack
        ? data[ItemIndex].quantityToPack
        : 0;
      var LocalQuantityToPack = onAdd
        ? data[ItemIndex].quantityPacked + data[ItemIndex].quantityToPack
        : data[ItemIndex].quantityPacked - data[ItemIndex].quantityToPack;
      LocalQuantityToPack =
        isNaN(LocalQuantityToPack) || !LocalQuantityToPack
          ? 0
          : LocalQuantityToPack;
      if (data[ItemIndex].quantity - LocalQuantityToPack > 0) {
        data[ItemIndex].quantityToPack = onAdd
          ? data[ItemIndex].quantityToPack + 1
          : Math.sign(data[ItemIndex].quantityToPack - 1) == 1
          ? data[ItemIndex].quantityToPack - 1
          : 0;
        setOrderdProducts(data);
      }
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    setPackDetails({ ...packDetails, [e.target.name]: e.target.value });
  };

  const presetWeight = () => {
    setOpen(true);
    const selectedProducts = orderdProducts.filter((obj) => {
      return obj.quantityToPack != undefined;
    });

    const totalWeight = selectedProducts.reduce(
      (a, v) =>
        (a = a + parseInt(v.quantityToPack) * parseFloat(v.weight_per_unit)),
      0
    );
    setPackDetails({ ...packDetails, weight: totalWeight });
  };

  const getdeliverynote = async (barcodeval, ideliverynote) => {
    setBarcodeValue(barcodeval);
    fetch(
      `${process.env.REACT_APP_API_URI}/api/details?doctype=Delivery%20Note&name=` +
        ideliverynote,
      {
        method: "GET",
        headers: header,
      }
    )
      .then((res) => {
        if (!res.ok) {
          dispatch(
            SetNotification({
              code: "error",
              message: t("common:problemRefresh"),
            })
          );
        } else {
          return res.json();
        }
      })
      .then((data) => {
        setDeliverynote(data?.docs[0]);
        handlePrint();
      });
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,

    onAfterPrint: () => {
      setDeliverynote(null);
      setBarcodeValue("");
    },
  });
  return (
    <Box className={classes.block}>
      <Box className={classes.titleblock}>
        <span
          onClick={() => history.push(`/fulfillement?tab=3`)}
          className={clsx(classes.closeicon, "icon-close")}
        />
        <Typography
          align={"center"}
          className={classes.title}
          color={"primary"}
          variant="h5"
        >
          {t("common:order")} {order ? order.order_id : ""}
        </Typography>
      </Box>
      <Box /* ref={componentRef} */ display={"flex"} flexWrap="wrap">
        {order &&
          order.packs &&
          order.packs.map((pack, index) => (
            <Box key={index} m={8}>
              <Typography className={classes.title} variant="h5">
                Pack {index + 1 + "/" + order.packs.length}
              </Typography>
              <Barcode
                width={1}
                value={order ? order.order_id + "-000" + pack.id : ""}
              />
              <IconButton
                disabled={!pack?.deliverynote_id}
                color={"primary"}
                onClick={() =>
                  getdeliverynote(
                    pack?.order_id + "-000" + pack.id,
                    pack?.deliverynote_id
                  )
                }
              >
                <PrintIcon /> {t("common:imprimer")}
              </IconButton>
            </Box>
          ))}
      </Box>
      <Box className={clsx(classes.productPaper, tableclasses.paper)}>
        <Table className={tableclasses.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={tableclasses.tablecellHeader} align="left">
                {t("common:produit")}
              </TableCell>
              <TableCell className={tableclasses.tablecellHeader} align="left">
                {t("common:ref")}
              </TableCell>
              <TableCell className={tableclasses.tablecellHeader} align="left">
                {t("common:itemGroup")}
              </TableCell>
              <TableCell className={tableclasses.tablecellHeader} align="left">
                {t("common:weightUnit")}
              </TableCell>
              <TableCell className={tableclasses.tablecellHeader} align="left">
                {t("fulfillment:toPackQty")}
              </TableCell>
              <TableCell className={tableclasses.tablecellHeader} align="left">
                {t("fulfillment:toPackQtyRest")}
              </TableCell>
              <TableCell
                className={tableclasses.tablecellHeader}
                align="center"
              >
                Pack
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orderdProducts?.map((row, index) => (
              <TableRow
                className={clsx({
                  [tableclasses.tableRow]: true, //always applies
                })}
                key={index}
              >
                <TableCell className={tableclasses.tableCell} align="left">
                  {row.item_name}
                </TableCell>
                <TableCell className={tableclasses.tableCell} align="left">
                  {row.item_code}
                </TableCell>
                <TableCell className={tableclasses.tableCell} align="left">
                  {row.item_group}
                </TableCell>
                <TableCell className={tableclasses.tableCell} align="left">
                  {row.weight_per_unit} {row.weight_uom}
                </TableCell>
                <TableCell className={tableclasses.tableCell} align="left">
                  {row.quantity}
                </TableCell>
                <TableCell className={tableclasses.tableCell} align="left">
                  {row.quantity - (row.quantityPacked ?? 0)}
                </TableCell>
                <TableCell className={tableclasses.tableCell} align="left">
                  <Box display="flex" justifyContent="center">
                    <Button
                      className={classes.quantityButton}
                      onClick={() => HandleQuantityChange(false, row.item_code)}
                      disabled={row.quantityToPack == 0}
                    >
                      -
                    </Button>
                    <Typography className={classes.quantityText}>
                      {row.quantityToPack ? row.quantityToPack : 0}
                    </Typography>
                    <Button
                      color={"primary"}
                      className={classes.quantityButton}
                      onClick={() => HandleQuantityChange(true, row.item_code)}
                      disabled={
                        row.quantityToPack >= row.quantity ||
                        row.quantity -
                          row.quantityPacked +
                          row.quantityToPack <=
                          0
                      }
                    >
                      +
                    </Button>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
      <Box mt={8} display="flex" justifyContent="flex-end">
        <Button
          style={{ marginLeft: 10 }}
          variant={"contained"}
          disabled={CheckQuantityPacked()}
          color={"primary"}
          onClick={() => presetWeight()}
        >
          {t("fulfillment:addPack")}
        </Button>
        <FormModal
          open={open}
          setOpen={setOpen}
          setDetails={setPackDetails}
          title={t("fulfillment:packConfig")}
          value={packDetails}
          handleChange={handleChange}
          orderdProducts={orderdProducts}
          handleSubmit={handleSubmit}
        />
      </Box>
      {deliverynote && (
        <Box display={"none"}>
          <PrintInvoiceTemplate
            ref={componentRef}
            doc={deliverynote}
            barcodeValue={barcodeValue}
            customerDetails={customerDetails}
            isDeliveryNote
          />
        </Box>
      )}
    </Box>
  );
};
