export const CREATE_TRANSFER_REQUEST = "CREATE_TRANSFER_REQUEST";
export const CREATE_TRANSFER_SUCCESS = "CREATE_TRANSFER_SUCCESS";
export const UPDATE_STATUS = "UPDATE_STATUS";
export const UPDATE_STATUS_SUCCESS = "UPDATE_STATUS_SUCCESS";
export const SET_SELECTED_TRANSFERT_REQUEST = "SET_SELECTED_TRANSFERT_REQUEST";

export const SET_TRANSFERT_DATA = "SET_TRANSFERT_DATA";

export const DOCUMENTS_COUNT_LOADED = "DOCUMENTS_COUNT_LOADED";

export const UPDATE_ITEMS_REQUEST = "UPDATE_ITEMS_REQUEST";
export const SET_SELECTED_WAREHOUSE_CIBLE = "SET_SELECTED_WAREHOUSE_CIBLE";
export const GET_MATERIAL_REQUEST_PURCHASE = "GET_MATERIAL_REQUEST_PURCHASE";
export const MATERIAL_REQUEST_PURCHASE_LOADED = "MATERIAL_REQUEST_PURCHASE_LOADED";
export const SET_PRODUCTS_STOCK = "SET_PRODUCTS_STOCK";
