import React, { useEffect, useRef } from "react";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Box from "@material-ui/core/Box";
import clsx from "clsx";
import { useTableStyles } from "../../../../styles/table.style";
import { useSelector } from "react-redux";
import { formatDate } from "../../../../helpers/utils";
import ExpandMore from "@material-ui/icons/ExpandMore";
import {
  ClickAwayListener,
  Modal,
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@material-ui/core";
import { GiftCardForm } from "../carteCadeau/giftCardForm";
import {
  CreateAdvancePayment,
  loadClientPaymentEntries,
  LoadOrderByName,
  CancelOrder,
} from "../../../../store/client/client.action";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { CommandeClient } from "../../../../components/componentToPrint/commandeClient";
import { useTranslation } from "react-i18next";
import { symbol } from "../../../../helpers/utils";
import AlertModal from "../../../../components/common/alertModal";
import { Pagination } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "1.0625rem 1.5rem",
  },
  price: {
    fontWeight: 600,
  },
  filterList: {
    borderTop: "none",
  },
  textLight: {
    color: theme.palette.gray.main,
    fontSize: 12,
    fontWeight: 500,
    marginLeft: 420,
  },
  item: {
    padding: "0rem 1.625rem",
    marginBottom: "1rem",
  },
  formControl: {
    maxWidth: 300,
    marginLeft: 420,
    "& .MuiOutlinedInput-input": {
      padding: "8px 14px !important",
      fontSize: 12,
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: 10,
    },
  },
  paper: {
    position: "absolute",
    width: "auto",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 10,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  },
  input: {
    marginRight: 10,
    marginBottom: 15,
    "& .MuiInputBase-root": {
      background: theme.palette.white.main,
    },
    "& .MuiOutlinedInput-input": {
      padding: "8.9px 14px",
      fontSize: 12,
    },
  },
  montant: {
    fontSize: 40,
    color: theme.palette.productsTable.button,
    marginBottom: 10,
  },
  print: {
    display: "none",
  },
}));

export const ClientOrders = (props) => {
  const classes = useStyles();
  const tableClasses = useTableStyles();
  const dispatch = useDispatch();
  const [action, setAction] = React.useState(0);
  const [openCard, setOpenCard] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [openCommande, setOpenCommande] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const componentRefC = useRef();
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [page, setPage] = React.useState(1);

  let { id } = useParams();
  const { t } = useTranslation(["common", "clients", "order"]);
  const companyacounts = useSelector(
    (state) => state.Configuration.companyacounts
  );
  const orders = useSelector((state) => state.Client.orders);
  const client = useSelector((state) => state.Client.client);
  const order = useSelector((state) => state.Client.order);
  const currencies = useSelector((state) => state.Client.currencies);
  const globalDefaults = useSelector((state) => state.Login.globalDefaults);

  const currencySymbol = (row) => {
    return row
      ? symbol(row.currency, currencies)?.symbol
      : globalDefaults?.default_currency;
  };

  const handlePrint = useReactToPrint({
    content: () => componentRefC.current,
    onAfterPrint: () => setSelectedRow(null),
  });
  const handleChangeAction = (e) => {
    setAction(e.target.value);
  };
  const handleClick = () => {
    const data = {
      customer: id,
      amount: parseFloat(value),
    };
    if (value !== 0) {
      dispatch(
        CreateAdvancePayment(
          data,
          globalDefaults?.default_company,
          companyacounts?.default_receivable_account,
          companyacounts?.default_currency
        )
      );

      setValue(0);
    }
    if (data) {
      dispatch(loadClientPaymentEntries(client));
    }
    setOpen(false);
  };
  const handleChange = (e) => {
    e.preventDefault();
    setValue(e.target.value);
  };
  const handleClose = () => {
    setOpenCard(false);
  };

  const body = (
    <div className={classes.paper}>
      <Typography align={"center"} color={"primary"}>
        {t("clients:addGiftCard")}
      </Typography>
      <Box mt={3} justifyContent="center" display="flex">
        <GiftCardForm
          isClient={true}
          isCheckout={false}
          handleClose={handleClose}
          customer={id}
        />
      </Box>
    </div>
  );

  return (
    <>
      {/*<Box mt={3} justifyContent="right" display="flex">
        <FormControl className={classes.formControl}>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            defaultValue={action}
            IconComponent={ExpandMore}
            onChange={handleChangeAction}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
            input={<OutlinedInput id="demo-simple-select-outlined-label" />}
          >
            <MenuItem disabled value={0}>
              {t("common:Action")}
            </MenuItem>
            <MenuItem
              value={2}
              onClick={() => {
                setOpenCard(true);
              }}
            >
              {t("checkout:giftCard")}
            </MenuItem>
          </Select>
        </FormControl>
      </Box>*/}
      <Box mt={3} className={clsx(classes.root, tableClasses.paper)}>
        <Table className={tableClasses.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={tableClasses.tablecellHeader} align="left">
                {t("common:date")}
              </TableCell>
              <TableCell className={tableClasses.tablecellHeader} align="left">
                {t("common:ref")}
              </TableCell>
              <TableCell className={tableClasses.tablecellHeader} align="left">
                Client
              </TableCell>
              <TableCell className={tableClasses.tablecellHeader} align="left">
                {t("common:total")}
              </TableCell>
              <TableCell className={tableClasses.tablecellHeader} align="left">
                {t("common:statut")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(orders) &&
              orders.slice((page - 1) * 20, page * 20).map((row, index) => (
                <>
                  <TableRow className={tableClasses.tableRow} key={index}>
                    <TableCell className={tableClasses.tableCell} align="left">
                      {formatDate(row.creation, "YYYY-MM-DD")}
                    </TableCell>
                    <TableCell className={tableClasses.tableCell} align="left">
                      {row.name}
                    </TableCell>
                    <TableCell className={tableClasses.tableCell} align="left">
                      {row.customer_name}
                    </TableCell>
                    <TableCell
                      className={clsx(tableClasses.tableCell, classes.price)}
                      align="left"
                    >
                      {row.grand_total.toFixed(3)} {currencySymbol(row)}
                    </TableCell>
                    <TableCell className={tableClasses.tableCell} align="left">
                      {row.status}
                    </TableCell>
                    <TableCell className={tableClasses.tableCell} align="right">
                      <Button
                        size="small"
                        color="primary"
                        variant="contained"
                        onClick={() => {
                          setOpenCommande(true);
                          dispatch(LoadOrderByName(row.name, "POS%20Invoice"));
                        }}
                        onClickCapture={() => setSelectedRow(row)}
                      >
                        Imprimer
                      </Button>
                    </TableCell>
                  </TableRow>
                  {selectedRow && (
                    <div className={classes.print}>
                      <CommandeClient
                        client={client}
                        detail={order}
                        document={selectedRow}
                        order={selectedRow}
                        title={"Commande"}
                        ref={componentRefC}
                      />
                    </div>
                  )}
                </>
              ))}
          </TableBody>
        </Table>
        {Array.isArray(orders) && Math.ceil(orders?.length / 20) > 0 && (
          <Box mt={2} display={"flex"} justifyContent={"right"}>
            <Pagination
              onChange={(event, newPage) => setPage(newPage)}
              defaultPage={page}
              count={Math.ceil(orders.length / 20)}
              color="primary"
            />
          </Box>
        )}
        <AlertModal
          title={t("common:msgOrderAlert")}
          openModal={openCommande}
          setOpenModal={setOpenCommande}
          handleCancel={handlePrint}
          order={order}
        />
        <Modal open={openCard}>{body}</Modal>
      </Box>
    </>
  );
};
