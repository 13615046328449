import React, { useEffect } from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";

import { Button, Grid, Typography, Modal } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { OrderDetails } from "../orderDetails";
import { Products } from "../products";
import { ButtonsFunction } from "../../../../helpers/buttonsFunction";
import {
  CreateDeliveryDocument,
  CreateInvoiceDocument,
  CreatePaymentEntryDocument,
  LoadDocumentsNumber,
  LoadMappedDocument,
  LoadMappedPaymentDocument,
  LoadOrderById,
  UpdateProducts,
  UpdateClient,
  UpdatePayment,
} from "../../../../store/checkout/checkout.action";
import { DeliveryDocument } from "../deliveryDocument";
import { PaymentDocument } from "../paymentDocument";
import { Invoice } from "../../../../components/invoice";
import { GetOrderdProducts } from "../../../../helpers/helper";
import { setFullfilment } from "../../../../store/fullfilment/fullfilment.action";
import { useTranslation } from "react-i18next";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 27,
  },
  addButton: {
    width: 28,
    height: 25,
    background: theme.palette.secondary.main,
    borderRadius: 5,
    fontSize: 21,
    paddingTop: 2,
    marginLeft: 8,
  },
  paper: {
    position: "absolute",
    width: 600,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 10,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  },
  title: {
    fontWeight: 700,
    fontSize: 20,
  },
  number: {
    fontWeight: 700,
    marginLeft: 10,
  },
  status: {
    cursor: "pointer",
  },
  modal: {
    position: "absolute",
    top: "10%",
    left: "10%",
    overflow: "scroll",
    height: "100%",
    maxHeight: 500,
    display: "block",
  },
}));

export const OrderDetailPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [title, setTitle] = React.useState("");
  const [key, setKey] = React.useState("");
  const [isPaymentModal, setIsPaymentModal] = React.useState(false);
  const talonProps = ButtonsFunction();
  const { SetPercentDiscount } = talonProps;
  const { t } = useTranslation(["common", "order"]);

  const dispatch = useDispatch();
  const companyacounts = useSelector(
    (state) => state.Configuration.companyacounts
  );
  const order = useSelector((state) => state.Checkout.selectedorder);
  const products = useSelector((state) => state.Product.products);
  const paymentDocument = useSelector(
    (state) => state.Checkout.paymentDocument
  );
  const deliveryDocument = useSelector(
    (state) => state.Checkout.deliveryDocument
  );
  const invoiceDocument = useSelector(
    (state) => state.Checkout.invoiceDocument
  );
  const docCreated = useSelector((state) => state.Checkout.docCreated);
  const payementsMethods = useSelector(
    (state) => state.Checkout.payementsMethods
  );
  const documentNumber = useSelector((state) => state.Checkout.documentNumber);
  let { id } = useParams();

  const fullfilments = useSelector((state) => state.Fullfilment.fullfilments);
  const fullfilment = useSelector((state) => state.Fullfilment.fullfilment);
  const filtredClients = useSelector((state) => state.Client.filtredClients);
  const amount = useSelector((state) => state.Checkout.amount);

  const [payement_method, setPayement_method] = React.useState("");
  const [fullfilmentOrder, setFullfilmentOrder] = React.useState([]);
  var [orderdProducts, setOrderdProducts] = React.useState([]);
  var [orderdClient, setOrderdClient] = React.useState([]);

  useEffect(() => {
    const selectedOrderClient =
      filtredClients && fullfilment && fullfilment.customer_name
        ? filtredClients.find(
            (h) => h.customer_name == fullfilment.customer_name
          )
        : "";
    setOrderdClient(selectedOrderClient);
  }, [fullfilment]);

  useEffect(() => {
    const selectedOrder = fullfilments.find((h) => h.order_id == id);
    dispatch(setFullfilment(selectedOrder));
  }, [fullfilments]);

  useEffect(() => {
    setOrderdProducts(
      fullfilment && fullfilment.products
        ? GetOrderdProducts([...products], fullfilment.products)
        : []
    );
  }, [order]);
  useEffect(() => {
    const data = {
      discount:
        fullfilment && fullfilment.additional_discount_percentage
          ? fullfilment.additional_discount_percentage
          : 0,
      amount: {
        totalAmount:
          fullfilment && fullfilment.grand_total ? fullfilment.grand_total : 0,
      },
    };
    setFullfilmentOrder(data);
  }, [fullfilment]);

  const HandleSetToCheckout = (data) => {
    if (documentNumber && documentNumber[2].count !== 0) {
      var filteredProduct = [];
      dispatch(UpdateClient(orderdClient));
      dispatch(UpdatePayment({ ...amount, isOrder: true }));
      SetPercentDiscount(data.discount);
      orderdProducts.forEach((o) => {
        const prod = {
          item_name: o.item_name,
          item_code: o.item_code,
          name: o.name,
          discount: o.discount,
          net_amount: o.net_amount,
          net_rate: o.net_rate,
          quantity: o.quantity,
          qty: o.quantity,
          standard_rate: o.standard_rate,
          price_list_rate: o.standard_rate,
          // price_list_rate:parseInt(fullfilment.grand_total)
        };
        filteredProduct.push(prod);
      });
      dispatch(UpdateProducts(filteredProduct));
      history.push("/checkout");
    }
  };

  useEffect(() => {
    dispatch(LoadOrderById(id));
    dispatch(LoadDocumentsNumber(id));
    dispatch(LoadMappedPaymentDocument(id, "Sales Order"));
    dispatch(
      LoadMappedDocument(
        id,
        "erpnext.selling.doctype.sales_order.sales_order.make_sales_invoice"
      )
    );
    dispatch(
      LoadMappedDocument(
        id,
        "erpnext.selling.doctype.sales_order.sales_order.make_delivery_note"
      )
    );
  }, []);

  useEffect(() => {
    dispatch(LoadDocumentsNumber(id));
  }, [docCreated]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSetDocument = (key, title, isPaymentDoc) => {
    setTitle(title);
    setKey(key);
    if (isPaymentDoc) {
      setIsPaymentModal(true);
    } else {
      setIsPaymentModal(false);
    }
    setOpen(true);
  };

  const handleSaveDocument = () => {
    switch (key) {
      case "delivery":
        dispatch(CreateDeliveryDocument(deliveryDocument));
        break;
      case "invoice":
        dispatch(CreateInvoiceDocument(invoiceDocument));
        break;
      case "payment":
        dispatch(
          CreatePaymentEntryDocument(
            paymentDocument,
            companyacounts?.default_cash_account,
            payement_method
          )
        );
        break;
      default:
        break;
    }
    setOpen(false);
    dispatch(LoadOrderById(id));
  };

  const body = (
    <div className={classes.paper}>
      <Typography className={classes.title} align={"center"} color={"primary"}>
        {title}
      </Typography>
      <Typography align={"center"} variant="caption" color={"primary"}>
        {order ? order.name : ""}
      </Typography>
      <Box align={"left"} mt={4}>
        {!isPaymentModal ? (
          <DeliveryDocument
            details={key == "delivery" ? deliveryDocument : invoiceDocument}
          />
        ) : (
          <PaymentDocument
            payement_method={payement_method}
            setPayement_method={setPayement_method}
            details={paymentDocument}
            payementsMethods={payementsMethods}
          />
        )}
      </Box>
      <Box mt={3} justifyContent="center" display="flex">
        <Button
          disabled={payement_method == "" && isPaymentModal}
          color="primary"
          onClick={handleSaveDocument}
          variant="contained"
        >
          {t("common:valider")}
        </Button>
      </Box>
    </div>
  );
  return (
    <Box>
      <Box display="flex" ml={4} mt={2}>
        <Typography
          align={"center"}
          color={"primary"}
          variant="caption"
          className={classes.backLink}
          onClick={() => history.goBack()}
        >
          <ArrowBackIcon style={{ cursor: "pointer" }} />
        </Typography>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        flexWrap="wrap"
      >
        <Box mr={8} display="flex" flexWrap="wrap" alignItems="center">
          <Typography className={classes.status} color={"primary"}>
            {t("order:bonLivraison")}
          </Typography>
          {documentNumber && documentNumber[1].count !== 0 ? (
            <Box display="flex" alignItems="center">
              <Typography className={classes.number} color={"primary"}>
                {documentNumber[1].count}
              </Typography>
              <Invoice
                title={t("order:bonLivraison")}
                order={order}
                document={deliveryDocument}
              />
            </Box>
          ) : (
            <Button
              onClick={() =>
                handleSetDocument("delivery", "Bon de livraison", false)
              }
              color={"primary"}
              className={classes.addButton}
            >
              +
            </Button>
          )}
        </Box>
        <Box mr={8} display="flex" flexWrap="wrap">
          <Typography className={classes.status} color={"primary"}>
            {t("order:factureVente")}
          </Typography>
          {documentNumber && documentNumber[0].count !== 0 ? (
            <Box display="flex" alignItems="center">
              <Typography className={classes.number} color={"primary"}>
                {documentNumber[0].count}
              </Typography>
              <Invoice
                title={t("order:facture")}
                order={order}
                document={invoiceDocument}
              />
            </Box>
          ) : (
            <Button
              disabled={documentNumber && documentNumber[1].count == 0}
              onClick={() =>
                handleSetDocument("invoice", "Facture de vente", false)
              }
              color={"primary"}
              className={classes.addButton}
            >
              +
            </Button>
          )}
        </Box>
        <Box display="flex" flexWrap="wrap">
          <Typography
            className={classes.status}
            color={
              "primary"
            } /*onClick={()=>HandleSetToCheckout(fullfilmentOrder)}*/
          >
            {t("order:paymentEntry")}
          </Typography>
          {documentNumber && documentNumber[2].count !== 0 ? (
            <Typography className={classes.number} color={"primary"}>
              {documentNumber[2].count}
            </Typography>
          ) : (
            <Button
              disabled={documentNumber && documentNumber[0].count == 0}
              onClick={() =>
                handleSetDocument("payment", "Ecriture de paiement", true)
              }
              color={"primary"}
              className={classes.addButton}
            >
              +
            </Button>
          )}
        </Box>
      </Box>
      <Grid
        container
        direction="row"
        spacing={2}
        className={classes.root}
        display="flex"
      >
        <Grid item xs={12} md={3}>
          <OrderDetails details={order} />
        </Grid>
        <Grid item xs={12} md={9}>
          <Products isOrderDetail={true} orders={order ? order.items : []} />
        </Grid>
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{ overflow: "scroll" }}
      >
        <div
          style={{
            position: "absolute",
            top: "20%",
            left: "50%",
            height: "100%",
            maxHeight: 500,
            display: "block",
          }}
        >
          {body}
        </div>
      </Modal>
    </Box>
  );
};
