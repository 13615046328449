import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles((theme)=> ({
  label: {
    fontSize: 12,
    marginBottom: 6,
    color: theme.palette.gray.main,
    paddingLeft: 9,
  },
  packControl: {
    marginBottom: 15,
    "& .MuiInputBase-root": {
      background: theme.palette.white.main,
    },
    "& .MuiOutlinedInput-input": {
      padding: "8.9px 14px",
      fontSize: 12,
    },
  },
}))