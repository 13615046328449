import React, {useEffect} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box } from '@material-ui/core';
import { PosDetail } from '../posDetail';
import { PosOrders } from '../orders';
import { useDispatch, useSelector } from 'react-redux';
import {useParams} from "react-router-dom";
import { LoadClosedPosById, SetClosedPOS } from '../../../../store/pos/pos.action';
import { Loader } from '../../../../components/loader';
import { Header } from '../../../stockEntry/components/header';

const useStyles = makeStyles((theme) => ({
 root : {
    marginTop: 30
 }
}));

export const ClosePosDetail = () => {
    const classes = useStyles();
    const Fragment = React.Fragment;
    const dispatch = useDispatch();
    const closedPos = useSelector(state => state.Pos.closedPostoShow)
    let { id } = useParams();

    useEffect(async () => {
        await dispatch(SetClosedPOS(null));
        await dispatch(LoadClosedPosById(id));
    }, []);

    if (!closedPos) return <Loader/>;
    
    return (
        <Box>
            <Header/>
            <Grid container direction="row" spacing={2} className={classes.root} display="flex">
                <Grid item xs={12} md={3}>
                    <PosDetail details={closedPos}/>
                </Grid>
                <Grid item xs={12} md={9}>
                    <PosOrders orders={closedPos ? closedPos.pos_transactions : []}/>
                </Grid>
            </Grid>
        </Box>
    )
}
