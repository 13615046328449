import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  IconButton,
  Button,
  TextField,
  Modal,
  Typography,
  MenuItem,
  Grid,
  FormControl,
  Select,
} from "@material-ui/core";
import clsx from "clsx";
import { useTableStyles } from "../../../../styles/table.style";
import { useTranslation } from "react-i18next";
import { InventoryToPrint } from "../../../../components/componentToPrint/Inventaire";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import { UpdateListStatus } from "../../../../store/inventory/inventory.action";

const useStyles = makeStyles((theme) => ({
  tablePaper: {
    padding: "0rem 2rem !important",
  },
  discInput: {
    width: 100,
    "& .MuiInputBase-formControl": {
      marginTop: "-4px",
    },
    "& .MuiInputBase-input": {
      textAlign: "center",
    },
  },
  qtyControl: {
    position: "relative",
    width: "100px",
  },
  print: {
    display: "none",
  },
  adjstBtn: {
    height: 30,
  },
  EcartPositive: {
    color: "green",
  },
  EcartNegative: {
    color: "red",
  },
  EcartNeutre: {
    color: "primary",
  },
  formControl: {
    minWidth: "170px",
    "& .MuiInputBase-root": {
      background: theme.palette.white.main,
    },
    "& .MuiOutlinedInput-input": {
      padding: "8.9px 14px",
      fontSize: 12,
    },
  },
  paper: {
    position: "absolute",
    width: 600,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 10,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  formControl: {
    marginBottom: 15,
    "& .MuiInputBase-root": {
      background: theme.palette.white.main,
    },
    "& .MuiOutlinedInput-input": {
      padding: "8.9px 14px",
      fontSize: 12,
    },
  },
}));

export const InventoryDetailsTable = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useParams();
  const tableClasses = useTableStyles();
  const { t } = useTranslation([
    "inventory",
    "common",
    "product",
    "fulfillment",
  ]);
  const history = useHistory();
  const params = useSelector((state) => state.Inventory.params);
  const globalDefaults = useSelector((state) => state.Login.globalDefaults);

  const [editedQuantity, setEditedQuantity] = useState(-1);
  const [editQteTrouvee, setEditQteTrouvee] = useState(false);
  const [motifs, setMotifs] = useState("Comptage physique");
  const [state, setState] = useState({
    open: false,
    row: null,
  });
  const handleChange = (event) => {
    setMotifs(event.target.value);
  };
  const refreshPage = () => {
    setTimeout(() => {
      window.location.reload(false);
    }, 500);
  };
  const handleStock = () => {
    state.row && props.handleStockAdjust(state.row, motifs);
  };

  const ColoredPoint = ({ color }) => (
    <div id="red-points">
      <hr
        style={{
          color: color,
          borderRadius: 50,
          backgroundColor: color,
          height: 10,
          width: 10,
          border: "none",
          position: "relative",
          marginLeft: -20,
          marginTop: -40,
        }}
      />
    </div>
  );

  function removeHTML(str) {
    var tmp = document.createElement("DIV");
    tmp.innerHTML = str;
    return tmp.textContent || tmp.innerText || "";
  }

  const MotifReasonList = useSelector(
    (state) => state.Configuration.motifInventory
  );

  const handleOpen = (row) => () => {
    setState({ open: true, row: row });
  };
  const handleClose = () => {
    setEditQteTrouvee(false);
    setState({ open: false });
  };

  const ajustFilter = (row) => {
    if (
      props.invList.products?.find((s) => s?.item_code == row?.item_code)
        ?.found_qty -
        row?.actual_qty >
      0
    ) {
      return (
        "+" +
        (props.invList.products?.find((s) => s?.item_code == row?.item_code)
          ?.found_qty -
          row?.actual_qty)
      );
    } else {
      return (
        props.invList.products?.find((s) => s?.item_code == row?.item_code)
          ?.found_qty - row?.actual_qty
      );
    }
  };
  const HandleClickEditQteTrouvee = () => {
    if (editQteTrouvee) {
      var tbaQty = [...props?.invList?.products];
      const index = tbaQty.findIndex(
        (element) => element?.item_code === state?.row?.item_code
      );
      if (tbaQty[index].found_qty != editedQuantity) {
        tbaQty[index].found_qty = editedQuantity;
        const data = {
          list_id: id,
          products: tbaQty,
        };
        dispatch(UpdateListStatus(data));
        refreshPage();
      }
    } else {
      setEditedQuantity(state?.row?.found_qty);
      setEditQteTrouvee(true);
    }
  };
  const alert = (
    <div className={classes.paper}>
      <Box mt={3} justifyContent="center" display="flex">
        <Typography align={"center"} color={"primary"}>
          {t("common:ref")} {t("common:produit")}:{" "}
          {state.row && state.row?.item_code}
        </Typography>
      </Box>
      <Grid container spacing={3}>
        <Grid mt={3} item xs={6}>
          <Box mt={3} justifyContent="center" display="flex">
            <Typography align={"center"} color={"primary"}>
              {t("inventory:alertInv")}
            </Typography>
          </Box>
          <Box mt={3} justifyContent="center" display="flex">
            <Typography
              align={"center"}
              className={
                state?.row &&
                props?.stockSummary?.find(
                  (s) => s?.item_code == state.row?.item_code
                )?.actual_qty -
                  state.row?.found_qty >
                  0
                  ? classes.EcartNegative
                  : classes.EcartPositive
              }
            >
              {t("inventory:ecart")} : {state.row && props.ajust(state.row)}
            </Typography>
          </Box>
          <Box mt={3} justifyContent="center" display="flex">
            <FormControl variant="outlined" className={classes.formControl}>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={motifs}
                name="motifs"
                onChange={handleChange}
                IconComponent={ExpandMore}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                  getContentAnchorEl: null,
                }}
              >
                {Array.isArray(MotifReasonList)
                  ? MotifReasonList?.map((reason) => (
                      <MenuItem key={reason?.name} value={reason?.name}>
                        {reason?.name}
                      </MenuItem>
                    ))
                  : null}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box mt={3} ml={2} justifyContent="center" display="flex">
            <Grid container wrap="nowrap" spacing={2}>
              <Grid item>
                <Typography align={"center"} color={"primary"}>
                  <strong>{t("common:magasin")}:</strong>
                </Typography>
              </Grid>
              <Grid item xs zeroMinWidth>
                <Typography color={"primary"}>
                  {state.row && props.match(state.row?.item_code).warehouse}{" "}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box mt={3} ml={2} justifyContent="center" display="flex">
            <Grid container wrap="nowrap" spacing={2}>
              <Grid item>
                <Typography align={"center"} color={"primary"}>
                  <strong>{t("common:qteReel")}:</strong>
                </Typography>
              </Grid>
              <Grid item xs zeroMinWidth>
                <Typography color={"primary"}>
                  {state.row && state.row?.qty_before}{" "}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box mt={3} ml={2} justifyContent="center" display="flex">
            <Grid container wrap="nowrap" spacing={2}>
              <Grid item>
                <Typography align={"center"} color={"primary"}>
                  <strong>{t("common:qteTrouvee")}:</strong>
                </Typography>
              </Grid>
              <Grid item>
                {editQteTrouvee ? (
                  <TextField
                    className={classes.discInput}
                    type="number"
                    name="quantityFound"
                    value={editedQuantity}
                    onChange={(e) => setEditedQuantity(e.target.value)}
                  />
                ) : (
                  <Typography color={"primary"}>
                    {state.row && state.row?.found_qty}{" "}
                  </Typography>
                )}
              </Grid>
              <Grid item xs zeroMinWidth>
                <Button
                  onClick={HandleClickEditQteTrouvee}
                  variant={
                    editQteTrouvee && state?.row?.found_qty != editedQuantity
                      ? "contained"
                      : "outlined"
                  }
                  color="primary"
                  startIcon={
                    editQteTrouvee ? (
                      <CheckCircleOutlineOutlinedIcon />
                    ) : (
                      <EditOutlinedIcon />
                    )
                  }
                ></Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <Box mt={3} justifyContent="right" display="flex">
        <Button
          disabled={editQteTrouvee}
          color="primary"
          variant="contained"
          onClick={handleStock}
        >
          {t("inventory:ajuster")}
        </Button>
        <Button color="primary" onClick={handleClose}>
          {t("common:annuler")}
        </Button>
      </Box>
    </div>
  );
  return (
    <Box className={clsx(classes.tablePaper, tableClasses.paper)}>
      <Table
        className={tableClasses.table}
        aria-label="simple table"
        id="table-to-download"
      >
        <TableHead>
          <TableRow>
            {params.ref && (
              <TableCell className={tableClasses.tablecellHeader} align="left">
                {t("common:ref")}
              </TableCell>
            )}
            {params.nameProd && (
              <TableCell className={tableClasses.tablecellHeader} align="left">
                {t("common:produit")}
              </TableCell>
            )}
            {params.qteReel && (
              <TableCell className={tableClasses.tablecellHeader} align="left">
                {t("common:qteReel")}
              </TableCell>
            )}
            {params.qteProj && (
              <TableCell className={tableClasses.tablecellHeader} align="left">
                {t("common:qteProjetee")}
              </TableCell>
            )}
            {params.qteResv && (
              <TableCell className={tableClasses.tablecellHeader} align="left">
                {t("common:qteReservee")}
              </TableCell>
            )}
            {params.magasin && (
              <TableCell className={tableClasses.tablecellHeader} align="left">
                {t("common:magasin")}
              </TableCell>
            )}
            {params.group && (
              <TableCell className={tableClasses.tablecellHeader} align="left">
                {t("common:ProductGroup")}
              </TableCell>
            )}
            {params.price && (
              <TableCell className={tableClasses.tablecellHeader} align="left">
                {t("common:prix")} {t("common:produit")}
              </TableCell>
            )}
            {params.collection && (
              <TableCell className={tableClasses.tablecellHeader} align="left">
                {t("product:itemcollection")}
              </TableCell>
            )}
            {params.openingStock && (
              <TableCell className={tableClasses.tablecellHeader} align="left">
                {t("common:stockOuverture")}
              </TableCell>
            )}
            {params.location && (
              <TableCell className={tableClasses.tablecellHeader} align="left">
                {t("fulfillment:plcmnt")}
              </TableCell>
            )}
            {params.originCountry && (
              <TableCell className={tableClasses.tablecellHeader} align="left">
                {t("inventory:paysProv")}
              </TableCell>
            )}
            {params.description && (
              <TableCell className={tableClasses.tablecellHeader} align="left">
                {t("common:Description")}
              </TableCell>
            )}
            {params.isStock && (
              <TableCell className={tableClasses.tablecellHeader} align="left">
                {t("common:isStock")}
              </TableCell>
            )}
            {params.qteTrouv && props.invList.status != "Draft" && (
              <TableCell
                className={clsx(
                  tableClasses.tablecellHeader,
                  classes.qtyControl
                )}
                align="center"
              >
                {t("common:qteTrouvee")}
              </TableCell>
            )}
            {props.invList.status == "Finished" && (
              <TableCell
                className={tableClasses.tablecellHeader}
                align="center"
              >
                {t("inventory:ajustement")}
              </TableCell>
            )}
            {props.invList.status == "Closed" && (
              <TableCell className={tableClasses.tablecellHeader} align="left">
                {t("inventory:qtyAvantAjust")}
              </TableCell>
            )}
            {props.invList.status == "Closed" && (
              <TableCell className={tableClasses.tablecellHeader} align="left">
                {t("inventory:diff")}
              </TableCell>
            )}
            {props.invList.status == "Closed" && (
              <TableCell className={tableClasses.tablecellHeader} align="left">
                {t("inventory:diffMontant")}
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.invList.status != "Finished" &&
          props.invList.status != "Closed"
            ? props.invList.products.length != 0 &&
              props.invList.products.map((row, index) => (
                <TableRow className={tableClasses.tableRow} key={index}>
                  {params.ref && (
                    <TableCell className={tableClasses.tableCell} align="left">
                      {row}
                    </TableCell>
                  )}
                  {params.nameProd && (
                    <TableCell className={tableClasses.tableCell} align="left">
                      {props.match(row).item_name}
                    </TableCell>
                  )}
                  {params.qteReel && (
                    <TableCell className={tableClasses.tableCell} align="left">
                      {props.match(row).actual_qty}
                    </TableCell>
                  )}
                  {params.qteProj && (
                    <TableCell className={tableClasses.tableCell} align="left">
                      {props.match(row).projected_qty}
                    </TableCell>
                  )}
                  {params.qteResv && (
                    <TableCell className={tableClasses.tableCell} align="left">
                      {props.match(row).reserved_qty}
                    </TableCell>
                  )}
                  {params.magasin && (
                    <TableCell className={tableClasses.tableCell} align="left">
                      {props.match(row).warehouse}
                    </TableCell>
                  )}
                  {params.group && (
                    <TableCell className={tableClasses.tableCell} align="left">
                      {props.fetchFromProd(row).item_group}
                    </TableCell>
                  )}
                  {params.price && (
                    <TableCell className={tableClasses.tableCell} align="left">
                      {props.fetchPrice(row)} {globalDefaults?.default_currency}
                    </TableCell>
                  )}
                  {params.collection && (
                    <TableCell className={tableClasses.tableCell} align="left">
                      {props.fetchFromProd(row).item_collection}
                    </TableCell>
                  )}
                  {params.openingStock && (
                    <TableCell className={tableClasses.tableCell} align="left">
                      {props.fetchFromProd(row).opening_stock}
                    </TableCell>
                  )}
                  {params.location && (
                    <TableCell className={tableClasses.tableCell} align="left">
                      {props.itemlocation.find((s) => s?.item_code == row)
                        ? props.itemlocation.find((s) => s?.item_code == row)
                            .item_location
                        : ""}
                    </TableCell>
                  )}
                  {params.originCountry && (
                    <TableCell className={tableClasses.tableCell} align="left">
                      {props.fetchFromProd(row).country_of_origin}
                    </TableCell>
                  )}
                  {params.description && (
                    <TableCell className={tableClasses.tableCell} align="left">
                      {removeHTML(props.fetchFromProd(row).description)}
                    </TableCell>
                  )}
                  {params.isStock && (
                    <TableCell className={tableClasses.tableCell} align="left">
                      {props.fetchFromProd(row).is_stock_item == "1"
                        ? "Oui"
                        : "Non"}
                    </TableCell>
                  )}
                  {props.invList.status == "In Progress" && (
                    <TableCell
                      className={clsx(
                        tableClasses.tableCell,
                        classes.qtyControl
                      )}
                      align="center"
                    >
                      <TextField
                        className={classes.discInput}
                        type="number"
                        name="quantityFound"
                        value={
                          props.foundQuantity.find((f) => f?.item_code == row)
                            ? props.foundQuantity.find(
                                (f) => f?.item_code == row
                              )?.found_qty
                            : ""
                        }
                        onChange={(e) => props.HandleFoundQuantity(e, row)}
                      />
                    </TableCell>
                  )}
                </TableRow>
              ))
            : props.filterList.length != 0 && Array.isArray(props.filterList)
            ? Array.isArray(props.filterList) &&
              props.filterList.map((row, index) => (
                <React.Fragment>
                  <TableRow className={tableClasses.tableRow} key={index}>
                    {params.ref && (
                      <TableCell
                        className={tableClasses.tableCell}
                        align="left"
                      >
                        {row?.item_code}
                      </TableCell>
                    )}
                    {params.nameProd && (
                      <TableCell
                        className={tableClasses.tableCell}
                        align="left"
                      >
                        {props.match(row?.item_code)?.item_name}
                      </TableCell>
                    )}
                    {params.qteReel && (
                      <TableCell
                        className={tableClasses.tableCell}
                        align="left"
                      >
                        {props.match(row?.item_code)?.actual_qty}
                      </TableCell>
                    )}
                    {params.qteProj && (
                      <TableCell
                        className={tableClasses.tableCell}
                        align="left"
                      >
                        {props.match(row?.item_code)?.projected_qty}
                      </TableCell>
                    )}
                    {params.qteResv && (
                      <TableCell
                        className={tableClasses.tableCell}
                        align="left"
                      >
                        {props.match(row?.item_code)?.reserved_qty}
                      </TableCell>
                    )}
                    {params.magasin && (
                      <TableCell
                        className={tableClasses.tableCell}
                        align="left"
                      >
                        {props.match(row?.item_code)?.warehouse}
                      </TableCell>
                    )}
                    {params.group && (
                      <TableCell
                        className={tableClasses.tableCell}
                        align="left"
                      >
                        {props.fetchFromProd(row?.item_code)?.item_group}
                      </TableCell>
                    )}
                    {params.price && (
                      <TableCell
                        className={tableClasses.tableCell}
                        align="left"
                      >
                        {props.fetchPrice(row?.item_code)}{" "}
                        {globalDefaults?.default_currency}
                      </TableCell>
                    )}
                    {params.collection && (
                      <TableCell
                        className={tableClasses.tableCell}
                        align="left"
                      >
                        {props.fetchFromProd(row?.item_code)?.item_collection}
                      </TableCell>
                    )}
                    {params.openingStock && (
                      <TableCell
                        className={tableClasses.tableCell}
                        align="left"
                      >
                        {props.fetchFromProd(row?.item_code)?.opening_stock}
                      </TableCell>
                    )}
                    {params.location && (
                      <TableCell
                        className={tableClasses.tableCell}
                        align="left"
                      >
                        {props.itemlocation.find(
                          (s) => s?.item_code == row?.item_code
                        )
                          ? props.itemlocation?.find(
                              (s) => s?.item_code == row?.item_code
                            )?.item_location
                          : ""}
                      </TableCell>
                    )}
                    {params.originCountry && (
                      <TableCell
                        className={tableClasses.tableCell}
                        align="left"
                      >
                        {props.fetchFromProd(row?.item_code)?.country_of_origin}
                      </TableCell>
                    )}
                    {params.description && (
                      <TableCell
                        className={tableClasses.tableCell}
                        align="left"
                      >
                        {removeHTML(
                          props.fetchFromProd(row?.item_code)?.description
                        )}
                      </TableCell>
                    )}
                    {params.isStock && (
                      <TableCell
                        className={tableClasses.tableCell}
                        align="left"
                      >
                        {props.fetchFromProd(row?.item_code)?.is_stock_item ==
                        "1"
                          ? "Oui"
                          : "Non"}
                      </TableCell>
                    )}
                    {params.qteTrouv && (
                      <TableCell
                        className={tableClasses.tableCell}
                        align="center"
                      >
                        {
                          props.invList.products?.find(
                            (s) => s?.item_code == row?.item_code
                          )?.found_qty
                        }
                      </TableCell>
                    )}
                    {props.invList.status == "Finished" && (
                      <TableCell
                        className={clsx(
                          tableClasses.tableCell,
                          props.invList.products?.find(
                            (s) => s?.item_code == row?.item_code
                          )?.found_qty -
                            row?.actual_qty <
                            0
                            ? classes.EcartNegative
                            : props.invList.products?.find(
                                (s) => s?.item_code == row?.item_code
                              )?.found_qty -
                                row?.actual_qty ==
                              0
                            ? classes.EcartNeutre
                            : classes.EcartPositive
                        )}
                        align="center"
                      >
                        {ajustFilter(row)}
                      </TableCell>
                    )}
                    {props.invList.status == "Finished" && (
                      <TableCell
                        className={tableClasses.tableCell}
                        align="right"
                      >
                        <Button
                          disabled={
                            props?.stockSummary.find(
                              (s) => s?.item_code == row?.item_code
                            ) &&
                            props?.stockSummary?.find(
                              (s) => s?.item_code == row?.item_code
                            )?.actual_qty == row?.found_qty
                          }
                          variant="outlined"
                          color="primary"
                          onClick={handleOpen(
                            props?.invList?.products.find(
                              (s) => s?.item_code == row?.item_code
                            )
                          )}
                          className={classes.adjstBtn}
                        >
                          Ajuster
                        </Button>
                      </TableCell>
                    )}
                    {props.invList.status == "Closed" && (
                      <TableCell
                        className={tableClasses.tableCell}
                        align="left"
                      >
                        {+row?.actual_qty}
                      </TableCell>
                    )}
                    {props.invList.status == "Closed" && (
                      <TableCell
                        className={tableClasses.tableCell}
                        align="left"
                      >
                        {+props.invList.products?.find(
                          (s) => s?.item_code == row?.item_code
                        )?.found_qty -
                          +props.invList.products?.find(
                            (s) => s?.item_code == row?.item_code
                          )?.qty_before}
                      </TableCell>
                    )}
                    {props.invList.status == "Closed" && (
                      <TableCell
                        className={tableClasses.tableCell}
                        align="left"
                      >
                        {props.fetchFromProd(row?.item_code)?.valuation_rate !=
                        0
                          ? (
                              props.fetchFromProd(row?.item_code)
                                ?.valuation_rate *
                              (+props?.invList?.products?.find(
                                (s) => s?.item_code == row?.item_code
                              )?.found_qty -
                                +props?.invList?.products?.find(
                                  (s) => s?.item_code == row?.item_code
                                )?.qty_before)
                            ).toFixed(3) +
                            ` ${globalDefaults?.default_currency}`
                          : t("common:product_valuation_rate") + "!"}
                      </TableCell>
                    )}
                  </TableRow>
                  {props?.invList?.status == "Finished" &&
                    props?.stockSummary.find(
                      (s) => s?.item_code == row?.item_code
                    ) &&
                    props?.stockSummary?.find(
                      (s) => s?.item_code == row?.item_code
                    )?.actual_qty !=
                      props?.invList?.products?.find(
                        (s) => s?.item_code == row?.item_code
                      )?.found_qty && <ColoredPoint color="red" />}
                  <>
                    <Modal
                      open={state.open}
                      aria-labelledby="simple-modal-title"
                      aria-describedby="simple-modal-description"
                    >
                      {alert}
                    </Modal>
                  </>
                </React.Fragment>
              ))
            : props?.invList?.products?.length != 0 &&
              props?.invList?.products?.map((row, index) => (
                <React.Fragment>
                  <TableRow className={tableClasses.tableRow} key={index}>
                    {/* initial stat */}
                    {params.ref && (
                      <TableCell
                        className={tableClasses.tableCell}
                        align="left"
                      >
                        {row?.item_code}
                      </TableCell>
                    )}
                    {params.nameProd && (
                      <TableCell
                        className={tableClasses.tableCell}
                        align="left"
                      >
                        {props.match(row?.item_code)?.item_name}
                      </TableCell>
                    )}
                    {params.qteReel && (
                      <TableCell
                        className={tableClasses.tableCell}
                        align="left"
                      >
                        {props.match(row?.item_code)?.actual_qty}
                      </TableCell>
                    )}
                    {params.qteProj && (
                      <TableCell
                        className={tableClasses.tableCell}
                        align="left"
                      >
                        {props.match(row?.item_code)?.projected_qty}
                      </TableCell>
                    )}
                    {params.qteResv && (
                      <TableCell
                        className={tableClasses.tableCell}
                        align="left"
                      >
                        {props.match(row?.item_code)?.reserved_qty}
                      </TableCell>
                    )}
                    {params.magasin && (
                      <TableCell
                        className={tableClasses.tableCell}
                        align="left"
                      >
                        {props.match(row?.item_code)?.warehouse}
                      </TableCell>
                    )}
                    {params.group && (
                      <TableCell
                        className={tableClasses.tableCell}
                        align="left"
                      >
                        {props.fetchFromProd(row?.item_code)?.item_group}
                      </TableCell>
                    )}
                    {params.price && (
                      <TableCell
                        className={tableClasses.tableCell}
                        align="left"
                      >
                        {props.fetchPrice(row?.item_code)}{" "}
                        {globalDefaults?.default_currency}
                      </TableCell>
                    )}
                    {params.collection && (
                      <TableCell
                        className={tableClasses.tableCell}
                        align="left"
                      >
                        {props.fetchFromProd(row?.item_code)?.item_collection}
                      </TableCell>
                    )}
                    {params.openingStock && (
                      <TableCell
                        className={tableClasses.tableCell}
                        align="left"
                      >
                        {props.fetchFromProd(row?.item_code)?.opening_stock}
                      </TableCell>
                    )}
                    {params.location && (
                      <TableCell
                        className={tableClasses.tableCell}
                        align="left"
                      >
                        {props.itemlocation.find(
                          (s) => s?.item_code == row?.item_code
                        )
                          ? props?.itemlocation?.find(
                              (s) => s?.item_code == row?.item_code
                            )?.item_location
                          : ""}
                      </TableCell>
                    )}
                    {params.originCountry && (
                      <TableCell
                        className={tableClasses.tableCell}
                        align="left"
                      >
                        {props.fetchFromProd(row?.item_code)?.country_of_origin}
                      </TableCell>
                    )}
                    {params.description && (
                      <TableCell
                        className={tableClasses.tableCell}
                        align="left"
                      >
                        {removeHTML(
                          props.fetchFromProd(row?.item_code)?.description
                        )}
                      </TableCell>
                    )}
                    {params.isStock && (
                      <TableCell
                        className={tableClasses.tableCell}
                        align="left"
                      >
                        {props.fetchFromProd(row?.item_code)?.is_stock_item ==
                        "1"
                          ? "Oui"
                          : "Non"}
                      </TableCell>
                    )}
                    {params.qteTrouv && (
                      <TableCell
                        className={tableClasses.tableCell}
                        align="center"
                      >
                        {row?.found_qty}
                      </TableCell>
                    )}
                    {props?.invList?.status == "Finished" && (
                      <TableCell
                        className={clsx(
                          tableClasses.tableCell,
                          props.stockSummary.find(
                            (s) => s?.item_code == row?.item_code
                          )?.actual_qty -
                            row?.found_qty >
                            0
                            ? classes.EcartNegative
                            : props.stockSummary.find(
                                (s) => s?.item_code == row?.item_code
                              )?.actual_qty -
                                row?.found_qty ==
                              0
                            ? classes.EcartNeutre
                            : classes.EcartPositive
                        )}
                        align="center"
                      >
                        {props.ajust(row)}
                      </TableCell>
                    )}
                    {props?.invList?.status == "Finished" && (
                      <TableCell
                        className={tableClasses.tableCell}
                        align="right"
                      >
                        <Button
                          disabled={
                            props.stockSummary.find(
                              (s) => s?.item_code == row?.item_code
                            ) &&
                            props.stockSummary?.find(
                              (s) => s?.item_code == row?.item_code
                            )?.actual_qty == row?.found_qty
                          }
                          variant="outlined"
                          color="primary"
                          onClick={handleOpen(row)}
                          className={classes.adjstBtn}
                        >
                          {t("inventory:ajuster")}
                        </Button>
                      </TableCell>
                    )}
                    {props.invList.status == "Closed" && (
                      <TableCell
                        className={tableClasses.tableCell}
                        align="left"
                      >
                        {+row?.qty_before}
                      </TableCell>
                    )}
                    {props.invList.status == "Closed" && (
                      <TableCell
                        className={tableClasses.tableCell}
                        align="left"
                      >
                        {+row?.found_qty - +row?.qty_before}
                      </TableCell>
                    )}
                    {props.invList.status == "Closed" && (
                      <TableCell
                        className={tableClasses.tableCell}
                        align="left"
                      >
                        {props.fetchFromProd(row?.item_code)?.valuation_rate !=
                        0
                          ? (
                              props.fetchFromProd(row?.item_code)
                                ?.valuation_rate *
                              (+row?.found_qty - +row?.qty_before)
                            ).toFixed(3) +
                            ` ${globalDefaults?.default_currency}`
                          : t("common:product_valuation_rate") + "!"}
                      </TableCell>
                    )}
                  </TableRow>
                  {props.invList.status == "Finished" &&
                    props.stockSummary.find(
                      (s) => s?.item_code == row?.item_code
                    ) &&
                    props.stockSummary?.find(
                      (s) => s?.item_code == row?.item_code
                    )?.actual_qty != row?.found_qty && (
                      <ColoredPoint color="red" />
                    )}

                  <>
                    <Modal
                      open={state.open}
                      aria-labelledby="simple-modal-title"
                      aria-describedby="simple-modal-description"
                    >
                      {alert}
                    </Modal>
                  </>
                </React.Fragment>
              ))}
        </TableBody>
      </Table>
      <div className={classes.print}>
        <InventoryToPrint
          itemlocation={props.itemlocation}
          fetchPrice={props.fetchPrice}
          fetchFromProd={props.fetchFromProd}
          invList={props.invList}
          inventory={props.stockSummary}
          title={t("inventory:title")}
          ref={props.componentRef}
        />
      </div>
    </Box>
  );
};
