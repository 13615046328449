import React from "react";
import { useStyles } from "./style";
import { useSelector } from "react-redux";
import { symbol, removeHTML } from "../../../helpers/utils";

export const CommandeClient = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const currencies = useSelector((state) => state.Client.currencies);
  const activeImageTheme = useSelector((state) => state.Theme.image);
  const globalDefaults = useSelector((state) => state.Login.globalDefaults);
  const currencySymbol =
    props.order && props.order.currency
      ? symbol(props.order.currency, currencies)?.symbol
      : globalDefaults?.default_currency;

  return (
    <div className={classes.main} ref={ref}>
      <header className={classes.header}>
        <div className={classes.logo}>
          <img src={activeImageTheme} />
        </div>
        <h1 className={classes.title}>
          {props.order && props.title + " " + props.order.name}{" "}
        </h1>
        <div className={classes.company}>
          <div>
            <span className={classes.item}>Date de livraison</span>{" "}
            {props.detail && props.detail.delivery_date}
          </div>
          <div>
            <span className={classes.item}>Date de la transaction</span>{" "}
            {props.detail && props.detail.transaction_date}
          </div>
        </div>
        <div className={classes.project}>
          <div>
            <span className={classes.projectitem}>CLIENT</span>{" "}
            {props.client && props.client.customer_name}
          </div>
          <div>
            <span className={classes.projectitem}>Téléphone</span>
            {props.client && props.client.mobile_no === ""
              ? "-"
              : props.client.mobile_no}
          </div>
          <div>
            <span className={classes.projectitem}>EMAIL</span>{" "}
            {props.client && props.client.email_id === ""
              ? "-"
              : props.client.email_id}
          </div>
          <div>
            <span className={classes.projectitem}>Adresse</span>{" "}
            {props.detail && removeHTML(props.detail.address_display)}
          </div>
        </div>
      </header>
      <table className={classes.table}>
        <thead>
          <tr>
            <th className={classes.tableheader}>Nom</th>
            <th className={classes.tableheader}>Description</th>
            <th className={classes.tableheader}>Quantité</th>
            <th className={classes.tableheader}>Pourcentage de la remise</th>
            <th className={classes.tableheader}>Montant de la remise</th>
            <th className={classes.tableheader}>Prix</th>
            <th className={classes.tableheader}>Montant</th>
          </tr>
        </thead>
        <tbody>
          {props.detail &&
            props.detail.items.map((p, index) => (
              <tr key={index}>
                <td className={classes.tableColumn}>{p.item_name}</td>
                <td className={classes.tableColumn}>
                  {removeHTML(p.description)}
                </td>
                <td className={classes.tableColumn}>{p.qty}</td>
                <td className={classes.tableColumn}>
                  {p.discount_percentage} %
                </td>
                <td className={classes.tableColumn}>
                  {p.discount_amount.toFixed(3)} {currencySymbol}
                </td>
                <td className={classes.tableColumn}>
                  {p.price_list_rate.toFixed(3)} {currencySymbol}
                </td>
                <td className={classes.tableColumn}>
                  {p.net_amount.toFixed(3)} {currencySymbol}
                </td>
              </tr>
            ))}

          <tr>
            <td className={classes.tableColumn} colspan="6">
              Quantité total
            </td>
            <td className={classes.tableColumn}>
              {props.detail && props.detail.total_qty}{" "}
            </td>
          </tr>
          <tr>
            <td className={classes.tableColumn} colspan="6">
              Taxe: {props.detail && props.detail.taxes_and_charges}
            </td>
            <td className={classes.tableColumn}>
              {props.detail && props.detail.total_taxes_and_charges.toFixed(3)}{" "}
              {currencySymbol}{" "}
            </td>
          </tr>
          <tr>
            <td className={classes.tableColumn} colspan="6">
              Grand Total
            </td>
            <td className={classes.tableColumn}>
              {props.detail && props.detail.grand_total.toFixed(3)}{" "}
              {currencySymbol}{" "}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
});
