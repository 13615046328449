import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    main : {
      padding: "0px 50px"
    },
    header: {
      padding: "60px 0",
      marginBottom: 60
    },
    logo: {
      textAlign: "center",
      marginBottom: 10
    },
    title: {
      borderTop: '1px solid  #5D6975',
      borderBottom: '1px solid  #5D6975',
      color: '#5D6975',
      fontSize: '30px',
      lineHeight: '1.4em',
      fontWeight: 'normal',
      textAlign: 'center',
      margin: '0 0 20px 0'
    },
    company: {
      float: 'right',
      textAlign: 'right',
      marginTop: 30
    },
    project: {
      float: 'left',
      marginTop: 30,
      marginBottom: 60
    },
    address:{
      float: 'left',
      marginTop: -30,
      marginBottom:50
    },
    projectitem :{
      color: '#5D6975',
      textAlign: 'left',
      width: '52px',
      marginRight: 10,
      display: 'inline-block',
      fontSize: '0.8em',
    },
    item :{
      color: '#5D6975',
      textAlign: 'left',
     
      fontSize: '0.8em',
    },
    
    table: {
      width: '80%'
    },
    tableheader :{
      padding: "5px 15px",
      background: "#E5E5E5",
      border: "1px solid #C1CED9",
      fontWeight: 'normal'
    },
    tableColumn: {
      padding: 10,
      textAlign: 'right',
      border: "1px solid #C1CED9",
    }
    }));  