import React, {useEffect, useState} from 'react'
import Box from '@material-ui/core/Box';
import {
    Button,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Typography,
} from "@material-ui/core";
import { Alert } from '@material-ui/lab';
import ExpandMore from "@material-ui/icons/ExpandMore";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import validator from "validator";
import {isUnique} from "../../helpers/utils";
import {CreateGuestClient} from "../../store/client/client.action";
import {useStyles} from "./style";
import {GetThemes} from "../../store/theme/theme.action";
import {
    guestLoadCustomerGroupParentData,
    guestLoadTerritoryParentData
} from "../initialization/apiconfig";
import {guestloadTerritoryData} from "../initialization/api";
import ReCAPTCHA from "react-google-recaptcha";

export const Register = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {t} = useTranslation(["common", "clients", "register"]);

    const activeImageTheme = useSelector(state => state.Theme.image);
    const clientCreated = useSelector((state) => state.Client.client);

    const [loadingButton, setLoadingButton] = useState(false);
    const [error, setError] = useState({
        customer_name: false,
        customer_type: false,
        customer_group: false,
        territory: false,
        email_id: false,
        mobile_no: false,
    });
    const [createClientForm, setCreateClientForm] = useState({
        customer_name: "",
        customer_type: "",
        customer_group: "",
        territory: "",
        email_id: "",
        mobile_no: "",
    });
    const [errorMsg, setErrorMsg] = React.useState({
        email_id: null,
        mobile_no: null,
    });
    const [contactPhone, setContactPhone] = useState([]);
    const [customersgroup, setCustomersgroup] = useState([]);
    const [territory, setTerritory] = useState([]);
    const [isVerified, setIsVerified] = useState(false);

    useEffect(async () => {
        dispatch(GetThemes());
        await guestLoadCustomerGroupParentData().then((response) => {
            setCustomersgroup(response?.data || []);
        });
        await guestLoadTerritoryParentData()
            .then(async (response) => {
                await guestloadTerritoryData(response?.data[0].name)
                    .then((response) => {
                        setTerritory(response.data.message)
                    })
            });
    }, []);

    useEffect(() => {
        if (clientCreated) {
            setLoadingButton(false);
            setCreateClientForm({
                customer_name: "",
                customer_type: "",
                customer_group: "",
                territory: "",
                email_id: "",
                mobile_no: "",
            });
        }
    }, [clientCreated]);


    const handleChange = (event) => {
        setError({...error, [event.target.name]: false});
        setErrorMsg({...errorMsg, [event.target.name]: null});
        setCreateClientForm({
            ...createClientForm,
            [event.target.name]: event.target.value,
        });
    };

    const handleCreateClient = () => {
        setError({
            customer_name: createClientForm.customer_name === "" || createClientForm.customer_name.length > 30,
            customer_type: createClientForm.customer_type === "",
            customer_group: createClientForm.customer_group === "",
            territory: createClientForm.territory === "",
            email_id:
                createClientForm.email_id === "" ||
                !validator.isEmail(createClientForm.email_id),
            mobile_no:
                createClientForm.mobile_no.length < 8 ||
                !validator.isMobilePhone(createClientForm.mobile_no) ||
                !isUnique(createClientForm.mobile_no, contactPhone),
        });

        setErrorMsg({
            customer_name: createClientForm.customer_name === "" ||
            createClientForm.customer_name.length > 30
                ? t("clients:nameError")
                : null,
            email_id:
                createClientForm.email_id === "" ||
                !validator.isEmail(createClientForm.email_id)
                    ? t("clients:emailError")
                    : null,
            mobile_no:
                createClientForm.mobile_no.length < 8 ||
                !validator.isMobilePhone(createClientForm.mobile_no)
                    ? t("clients:numError")
                    : !isUnique(createClientForm.mobile_no, contactPhone)
                        ? t("clients:uniqueNum")
                        : null,
        });

        if (
            createClientForm.customer_name !== "" && createClientForm.customer_name.length <= 30 &&
            createClientForm.customer_type !== "" &&
            createClientForm.customer_group !== "" &&
            createClientForm.territory !== "" &&
            createClientForm.email_id !== "" &&
            validator.isEmail(createClientForm.email_id) &&
            createClientForm.mobile_no.length > 7 &&
            validator.isMobilePhone(createClientForm.mobile_no) &&
            isUnique(createClientForm.mobile_no, contactPhone)
        ) {
            const data = {
                doctype: "Customer",
                customer_name: createClientForm.customer_name,
                customer_type: createClientForm.customer_type,
                customer_group: createClientForm.customer_group,
                territory: createClientForm.territory,
                email_id: createClientForm.email_id,
                mobile_no: createClientForm.mobile_no,
            };
            setLoadingButton(true);
            dispatch(CreateGuestClient(data));
        }
    };

    const handleChangeReCaptcha = (value) => {
        !!value && setIsVerified(true);
    }

    return (
        <>
            <Box>
                <Box display="flex" justifyContent="flex-start">
                    <img for="file-input" style={{width: 72, height: 72}} src={activeImageTheme} alt={"logo"}/>
                </Box>
                <Box className={classes.root}>
                    <Box className={classes.paper}>
                        <div className={classes.container}>
                            <div className={classes.stepPaper}>
                                <div className={classes.paperHeight}>
                                    <Box>
                                        <Typography align={"center"} color={"primary"}>
                                            {t("common:addClient")}
                                        </Typography>
                                        <InputLabel
                                            className={classes.label}
                                            error={error.customer_name}
                                            required
                                        >
                                            {t("common:customerName")}
                                        </InputLabel>
                                        <FormControl variant="outlined" className={classes.formControl}>
                                            <OutlinedInput
                                                autoComplete="off"
                                                error={error.customer_name}
                                                className={classes.customerNameInput}
                                                placeholder={t("common:customerName")}
                                                value={createClientForm.customer_name}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                }}
                                                name="customer_name"
                                            />
                                        </FormControl>
                                        <FormHelperText
                                            className={classes.error}
                                            style={{marginBottom: 10, marginTop: -10}}
                                        >
                                            {errorMsg?.customer_name ?? errorMsg.customer_name}
                                        </FormHelperText>
                                        <InputLabel
                                            className={classes.label}
                                            required
                                            error={error.customer_type}
                                        >
                                            {t("common:customerType")}
                                        </InputLabel>
                                        <FormControl variant="outlined" className={classes.formControl}>
                                            <Select
                                                error={error.customer_type}
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                className={classes.select}
                                                value={createClientForm.customer_type}
                                                name="customer_type"
                                                IconComponent={ExpandMore}
                                                onChange={(e) => handleChange(e)}
                                                style={{textAlign: "left"}}
                                                MenuProps={{
                                                    anchorOrigin: {
                                                        vertical: "bottom",
                                                        horizontal: "left",
                                                    },
                                                    getContentAnchorEl: null,
                                                }}
                                            >
                                                <MenuItem style={{fontSize: 12}} key={0} value={"Company"}>
                                                    {t("common:company")}
                                                </MenuItem>
                                                <MenuItem style={{fontSize: 12}} key={1} value={"Individual"}>
                                                    {t("common:Indiv")}
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                        <InputLabel
                                            className={classes.label}
                                            required
                                            error={error.customer_group}
                                        >
                                            {t("common:customerGroup")}
                                        </InputLabel>
                                        <FormControl variant="outlined" className={classes.formControl}>
                                            <Select
                                                error={error.customer_group}
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                className={classes.select}
                                                value={createClientForm.customer_group}
                                                name="customer_group"
                                                style={{textAlign: "left"}}
                                                IconComponent={ExpandMore}
                                                onChange={(e) => handleChange(e)}
                                                MenuProps={{
                                                    anchorOrigin: {
                                                        vertical: "bottom",
                                                        horizontal: "left",
                                                    },
                                                    getContentAnchorEl: null,
                                                }}
                                            >
                                                {Array.isArray(customersgroup) &&
                                                    customersgroup.map((row, index) => (
                                                        <MenuItem
                                                            style={{fontSize: 12}}
                                                            value={row.name}
                                                            key={row.name}
                                                        >
                                                            {row.name}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </FormControl>
                                        <InputLabel className={classes.label} required error={error.territory}>
                                            {t("clients:territory")}
                                        </InputLabel>
                                        <FormControl variant="outlined" className={classes.formControl}>
                                            <Select
                                                error={error.territory}
                                                labelId="demo-simple-select-outlined-label"
                                                id="demo-simple-select-outlined"
                                                className={classes.select}
                                                value={createClientForm.territory}
                                                style={{textAlign: "left"}}
                                                IconComponent={ExpandMore}
                                                onChange={(e) => handleChange(e)}
                                                name="territory"
                                                MenuProps={{
                                                    anchorOrigin: {
                                                        vertical: "bottom",
                                                        horizontal: "left",
                                                    },
                                                    getContentAnchorEl: null,
                                                }}
                                            >
                                                {Array.isArray(territory) &&
                                                    territory.map((row, index) => (
                                                        <MenuItem
                                                            style={{fontSize: 12}}
                                                            value={row.value}
                                                            key={row.value}
                                                        >
                                                            {row.title}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </FormControl>
                                        <InputLabel
                                            className={classes.label}
                                            required
                                            error={error.email_id}
                                        >
                                            {t("common:email")}
                                        </InputLabel>
                                        <FormControl variant="outlined" className={classes.formControl}>
                                            <OutlinedInput
                                                error={error.email_id}
                                                autoComplete="off"
                                                className={classes.formControll}
                                                placeholder={t("common:email")}
                                                value={createClientForm.email_id}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                }}
                                                name="email_id"
                                            />
                                        </FormControl>
                                        <FormHelperText
                                            className={classes.error}
                                            style={{marginBottom: 0}}
                                        >
                                            {errorMsg?.email_id ?? errorMsg.email_id}
                                        </FormHelperText>
                                        <InputLabel
                                            className={classes.label}
                                            required
                                            error={error.mobile_no}
                                        >
                                            {t("common:numTel")}
                                        </InputLabel>
                                        <FormControl variant="outlined" className={classes.formControl}>
                                            <OutlinedInput
                                                error={error.mobile_no}
                                                autoComplete="off"
                                                className={classes.formControll}
                                                placeholder={t("common:numTel")}
                                                value={createClientForm.mobile_no}
                                                onChange={(e) => {
                                                    handleChange(e);
                                                }}
                                                name="mobile_no"
                                            />
                                        </FormControl>
                                        <FormHelperText
                                            className={classes.error}
                                            style={{marginBottom: 0}}
                                        >
                                            {errorMsg?.mobile_no ?? errorMsg.mobile_no}
                                        </FormHelperText>
                                        <Alert sx={{my: 2}} severity="warning">{t("register:alert")}</Alert>
                                        <ReCAPTCHA
                                            sitekey="6LerGPsnAAAAAP4nZBUoQ01ItBD-dn1YYOvgBm5B"
                                            onChange={handleChangeReCaptcha}
                                        />
                                        <Box display="flex" justifyContent="flex-end">
                                            {/*<LoadingButton
                                                // disabled={!isVerified}
                                                variant="contained"
                                                color="primary"
                                                type={"button"}
                                                onClick={() => handleCreateClient()}
                                                loading={loadingButton}
                                            >
                                                {t("common:ajouter")}
                                            </LoadingButton>*/}
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => handleCreateClient()}
                                                className={classes.button}
                                                disabled={!isVerified}
                                            >
                                                {t("common:ajouter")}
                                            </Button>
                                        </Box>
                                    </Box>
                                </div>
                            </div>
                        </div>
                    </Box>
                </Box>
            </Box>
        </>
    )
}
