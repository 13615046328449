import React, { useEffect, useState, useLayoutEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from "react-router-dom";
import { Profil } from '../components/Profil';
import { Button, Grid } from '@material-ui/core';
import { ClientDetailsTabs } from '../components/tabs';
import { useSelector, useDispatch } from 'react-redux';
import { LoadDetailClient } from "../../../store/checkout/checkout.action";
import {
    SecondLoadDetailClient,
    SecondSetDetailClient,
    setLedgerByCustomer
} from '../../../store/client/client.action';
import {
    LoadSelectedClient,
    loadClientPaymentEntries,
    loadOutstandingByClient,
    loadOrdersByClient,
    loadLoyaltyPointsByClient,
    loadClientGiftCard,
    loadClientCouponCode,
    loadClientAvoirAccount
} from '../../../store/client/client.action';
import { Loader } from '../../../components/loader';
import CustomerLedgerReport from '../components/report';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: -40
    },
    reportBtn: {
        marginBottom: 21
    }
}));

export const ClientDetails = () => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const [loading, setLoading] = useState(true);
    const [toggleView, setToggleView] = useState(true);
    const [totalAvoirs, setTotalAvoirs] = useState({
        totalAmount: 0,
        nbAvoirs: 0
    });
    const globalDefaults = useSelector((state) => state.Login.globalDefaults);
    const client = useSelector(state => state.Client.client);
    const avoirsAccount = useSelector((state) => state.Client.avoirsAccount);

    let { id } = useParams();
    const initializationlayout = async () => {
        dispatch(LoadSelectedClient(null));
        dispatch(setLedgerByCustomer(null));
        setLoading(true);
        await dispatch(SecondLoadDetailClient(id));
        setLoading(false);
    }
    useLayoutEffect(() => {
        initializationlayout();
    }, []);
    const initialization = () => {
        dispatch(loadClientAvoirAccount(client));
        dispatch(loadOutstandingByClient(client, globalDefaults?.default_company));
        dispatch(loadClientPaymentEntries(client));
        dispatch(loadOrdersByClient(client, globalDefaults?.default_company));
        dispatch(loadLoyaltyPointsByClient(client));
        dispatch(loadClientCouponCode(client));
    }
    useEffect(() => {
        if (client && client?.name === id && globalDefaults?.default_company) {
            initialization();
        }
    }, [client, globalDefaults?.default_company])
    useEffect(() => {
        if (Array.isArray(avoirsAccount)) {
            let sum = 0
            avoirsAccount?.forEach(avoirAccount => {
                sum += avoirAccount?.credit_amount
            });
            setTotalAvoirs({
                totalAmount: sum,
                nbAvoirs: avoirsAccount?.length
            });
        }
    }, [avoirsAccount]);
    return (
        loading ? <Loader /> :
            <Grid container direction="row" spacing={2} className={classes.root} display="flex">
                <Grid item xs={12} md={3}>
                    {client && <Profil client={client} totalAvoirs={totalAvoirs} />}
                </Grid>
                <Grid item xs={12} md={9}>
                    <Button variant='contained' color='primary' className={classes.reportBtn} onClick={() => setToggleView(!toggleView)} >
                        {toggleView ? "Rapport" : "Détails"}
                    </Button>
                    {toggleView ? client && <ClientDetailsTabs client={client} setTotalAvoirs={setTotalAvoirs} /> : client && <CustomerLedgerReport client={client} />}
                </Grid>
            </Grid>
    )
}
