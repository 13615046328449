import React from "react";
import {
  Box,
  Dialog,
  DialogContentText,
  DialogContent,
  DialogTitle,
  Typography,
  Stepper,
  Step,
  StepLabel,
  IconButton,
  Button,
  Modal,
  ClickAwayListener,
} from "@material-ui/core";
import ConfirmButton from "../../confirmButton";
import { useStyles } from "../style";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useTranslation } from "react-i18next";
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
const DialogModal = (props) => {
  const classes = useStyles();
  const {
    handleClose,
    open,
    title,
    getStepContent,
    steps,
    activeStep,
    handleNextStep,
    handleBack,
    handleSubmit,
  } = props;
  const { t } = useTranslation(["common"]);
  return (
    <Modal
      open={open}
      onClose={!open}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >

      <div className={classes.paper}>
        <Typography
          align={"center"}
          color={"primary"}
          style={{ fontWeight: "bold", fontSize: 20, marginBottom: 20 }}
        >
          {title}
        </Typography>

        <Box mt={2}>

          <Box mt={-1} className={classes.roott}>
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            <div>
              <Typography
                style={{ marginTop: -5 }}
                className={classes.instructions}
              >
                {getStepContent(activeStep)}
              </Typography>
            </div>
          </Box>

          <Box mt={1} justifyContent="center" display="flex">
            <Button className={classes.modalButton} color="secondary" variant="contained" onClick={handleClose}>

              {t("common:annuler")}
            </Button>
            {activeStep == 0 && (
              <Button className={classes.modalButton} variant="contained" color="primary" onClick={handleNextStep}>
                {t("common:next")}
              </Button>
            )}
            {activeStep == 1 && (
              <Button
                className={classes.modalButton}
                variant="contained"
                color="primary"
                onClick={handleSubmit}
              // disabled={Object.values(error).indexOf(true) != -1}
              >
                {t("common:finish")}
              </Button>
            )}
          </Box>
        </Box>
      </div>
    </Modal>
  );
};

export default DialogModal;
