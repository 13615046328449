import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import { ClientOrders } from "../orders";
import { ClientPaiments } from "../paiments";
import { ClientLoyaltyDetails } from "../loyaltyDetails";
import { ClientAvoirDetails } from "../avoirDetails";
import { ClientCadeauDetails } from "../carteCadeau/cadeauDetails";
import { ClientPricingRuleDetails } from "../carteCadeau/cadeauDetails/ClientPricingRuleDetails";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  tabs: {
    "& .MuiTabs-scroller": {
      padding: "0px 30px",
    },
    "& .MuiTabs-flexContainer": {
      borderBottom: "2px solid #ECF2F5",
    },
  },
  tab: {
    borderBottom: "none",
  },
}));

export const ClientDetailsTabs = (props) => {
  const { client } = props;
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const paymentEntries = useSelector((state) => state.Client.paymentEntries);
  const giftCard = useSelector((state) => state.Client.giftCard);
  const { t } = useTranslation(["common", "clients", "order"]);

  const filteredPayments = Array.isArray(paymentEntries)
    ? paymentEntries.filter((p) => {
      return p.unallocated_amount != 0 && p.status == "Submitted";
    })
    : [];
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Box position="static" color="default">
        <Tabs
          value={value}
          className={classes.tabs}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab
            className={classes.tab}
            label={t("common:commande")}
            {...a11yProps(0)}
          />
          <Tab
            className={classes.tab}
            label={t("clients:payments")}
            {...a11yProps(1)}
          />
          <Tab
            className={classes.tab}
            label={t("clients:loyalty")}
            {...a11yProps(2)}
          />
          <Tab
            className={classes.tab}
            label={t("clients:avoir")}
            {...a11yProps(3)}
          />
          <Tab
            className={classes.tab}
            label={t("checkout:PricingRule")}
            {...a11yProps(4)}
          />
          {Array.isArray(giftCard) && Array.isArray(giftCard).length !== 0 ? (
            <Tab
              className={classes.tab}
              label={t("checkout:giftCard")}
              {...a11yProps(5)}
            />
          ) : null}
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <ClientOrders client={client} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ClientPaiments client={client} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ClientLoyaltyDetails client={props.client} />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <ClientAvoirDetails client={client} />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <ClientPricingRuleDetails />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <ClientCadeauDetails />
      </TabPanel>
    </div>
  );
};
