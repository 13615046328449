import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import Box from "@material-ui/core/Box";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import PrintIcon from "@material-ui/icons/Print";
import GetAppIcon from "@material-ui/icons/GetApp";

const useStyles = makeStyles((theme) => ({
  box: {
    position: "relative",
  },

  collapse: {
    position: "absolute",
    borderRadius: 10,
    border: "1px solid #A6B4CB",
    backgroundColor: theme.palette.white.main,
    [theme.breakpoints.up("md")]: {
      width: "154px",
    },
    zIndex: 2,
    "& .MuiInputBase-root": {
      background: theme.palette.white.main,
      marginRight: 13,
    },
    "& .MuiOutlinedInput-input": {
      padding: "7.5px 14px",
      fontSize: 12,
    },
  },
  collapseContent: {
    padding: "8px 11px",
    backgroundColor: theme.palette.secondary.main,
  },
  nested: {
    paddingLeft: 3,
    "& .MuiTypography-root": {
      fontSize: "0.875rem",
      color: theme.palette.primary.main,
    },
  },
  icons: {
    color: theme.palette.gray.main,
    height: 15,
    marginRight: 4,
  },
  dotsIcon: {
    color: theme.palette.gray.main,
    marginLeft: 13,
    marginTop: 5,
    cursor: "pointer",
  },
}));

export const Dropdown = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Box className={classes.box}>
      <MoreHorizIcon className={classes.dotsIcon} onClick={handleClick} />

      <Collapse
        className={classes.collapse}
        in={open}
        timeout="auto"
        unmountOnExit
      >
        <List component="div" disablePadding>
          <ListItem button>
            <FileCopyIcon className={classes.icons} />
            <ListItemText
              className={classes.nested}
              color={"primary"}
              primary="Dupliquer"
            />
          </ListItem>
          <ListItem button>
            <PrintIcon className={classes.icons} />
            <ListItemText className={classes.nested} primary="Imprimer" />
          </ListItem>
          <ListItem button>
            <GetAppIcon className={classes.icons} />
            <ListItemText className={classes.nested} primary="Export" />
          </ListItem>
        </List>
      </Collapse>
    </Box>
  );
};
