import { SvgIcon } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

function SafeIcon(props) {
  const theme = useTheme();
  return (
    <SvgIcon {...props} viewBox="0 0 58 58">
      <g>
        <rect
          x="29"
          y="3.083"
          style={{ fill: theme.palette.primary.main }}
          width="8"
          height="52"
        />
        <g>
          <path
            style={{ fill: theme.palette.primary.main }}
            d="M29,6.083H0.944C0.422,6.083,0,6.506,0,7.027V51.14c0,0.521,0.422,0.944,0.944,0.944H29V6.083z"
          />
          <path
            style={{ fill: theme.palette.primary.main }}
            d="M45.889,6.083l7.111,4v38l-7.111,4h11.168c0.521,0,0.944-0.422,0.944-0.944V7.027 c0-0.521-0.422-0.944-0.944-0.944H45.889z"
          />
        </g>
        <path
          style={{ fill: theme.palette.primary.main }}
          d="M37,1.201v55.598c0,0.09,0.097,0.146,0.175,0.102L53,48V10L37.175,1.098 C37.097,1.054,37,1.111,37,1.201z"
        />
        <path
          style={{ fill: theme.palette.calculator.background }}
          d="M29,10.083H5.345C4.602,10.083,4,10.686,4,11.429v35.309c0,0.743,0.602,1.345,1.345,1.345H29V10.083z"
        />
        <g>
          <path
            style={{ fill: theme.palette.calculator.background }}
            d="M52,16.083h3c0.553,0,1-0.447,1-1s-0.447-1-1-1h-3c-0.553,0-1,0.447,1,1S51.447,16.083,52,16.083z"
          />
          <path
            style={{ fill: theme.palette.calculator.background }}
            d="M55,17.083h-3c-0.553,0-1,0.447-1,1s0.447,1,1,1h3c0.553,0,1-0.447,1-1S55.553,17.083,55,17.083z"
          />
          <path
            style={{ fill: theme.palette.calculator.background }}
            d="M55,39.083h-3c-0.553,0-1,0.447-1,1s0.447,1,1,1h3c0.553,0,1-0.447,1-1S55.553,39.083,55,39.083z"
          />
          <path
            style={{ fill: theme.palette.calculator.background }}
            d="M55,42.083h-3c-0.553,0-1,0.447-1,1s0.447,1,1,1h3c0.553,0,1-0.447,1-1S55.553,42.083,55,42.083z"
          />
        </g>
        <rect
          x="2"
          y="52.083"
          style={{ fill: theme.palette.primary.main }}
          width="8"
          height="4"
        />
        <rect
          x="48"
          y="52.083"
          style={{ fill: theme.palette.primary.main }}
          width="8"
          height="4"
        />
        <g>
          <circle style={{ fill: "#556080" }} cx="33" cy="6" r="1" />
          <circle style={{ fill: "#556080" }} cx="33" cy="12" r="1" />
          <circle style={{ fill: "#556080" }} cx="33" cy="46" r="1" />
          <circle style={{ fill: "#556080" }} cx="33" cy="40" r="1" />
          <circle style={{ fill: "#556080" }} cx="33" cy="18" r="1" />
          <circle style={{ fill: "#556080" }} cx="33" cy="52" r="1" />
        </g>
        <path
          style={{ fill: "#B5C3C6" }}
          d="M40,19v4c1.66,0,3.011,1.351,3.011,3.011v5.979C43.011,33.649,41.66,35,40,35v4 c3.866,0,7.011-3.145,7.011-7.011v-5.979C47.011,22.144,43.866,19,40,19z"
        />
        <path
          style={{ fill: "#CFE1E5" }}
          d="M41.063,20.583h-0.838v-0.028H40v1.028h1.063c1.62,0,3.937,1.888,3.937,3.507v7.818 c0,1.62-2.318,3.656-3.937,3.656H40v0.987h1.195C43.304,37.48,46,35.035,46,32.909v-7.818C46,22.92,43.234,20.583,41.063,20.583z"
        />
        <rect
          x="8"
          y="36.083"
          style={{ fill: "#71C285" }}
          width="18"
          height="12"
        />
        <rect
          x="8"
          y="44.083"
          style={{ fill: "#4C8056" }}
          width="6"
          height="2"
        />
        <rect
          x="8"
          y="41.083"
          style={{ fill: "#4C8056" }}
          width="6"
          height="2"
        />
        <rect
          x="8"
          y="38.083"
          style={{ fill: "#4C8056" }}
          width="6"
          height="2"
        />
        <rect
          x="20"
          y="44.083"
          style={{ fill: "#4C8056" }}
          width="6"
          height="2"
        />
        <rect
          x="20"
          y="41.083"
          style={{ fill: "#4C8056" }}
          width="6"
          height="2"
        />
        <rect
          x="20"
          y="38.083"
          style={{ fill: "#4C8056" }}
          width="6"
          height="2"
        />
        <rect
          x="14"
          y="36.083"
          style={{ fill: "#D1D4D1" }}
          width="6"
          height="12"
        />
        <rect
          x="8"
          y="17.083"
          style={{ fill: "#71C285" }}
          width="18"
          height="12"
        />
        <rect
          x="8"
          y="25.083"
          style={{ fill: "#4C8056" }}
          width="6"
          height="2"
        />
        <rect
          x="8"
          y="22.083"
          style={{ fill: "#4C8056" }}
          width="6"
          height="2"
        />
        <rect
          x="8"
          y="19.083"
          style={{ fill: "#4C8056" }}
          width="6"
          height="2"
        />
        <rect
          x="20"
          y="25.083"
          style={{ fill: "#4C8056" }}
          width="6"
          height="2"
        />
        <rect
          x="20"
          y="22.083"
          style={{ fill: "#4C8056" }}
          width="6"
          height="2"
        />
        <rect
          x="20"
          y="19.083"
          style={{ fill: "#4C8056" }}
          width="6"
          height="2"
        />
        <rect
          x="14"
          y="17.083"
          style={{ fill: "#D1D4D1" }}
          width="6"
          height="12"
        />
        <rect
          x="4"
          y="29.083"
          style={{ fill: "#B5C3C6" }}
          width="25"
          height="2"
        />
      </g>
    </SvgIcon>
  );
}

export default SafeIcon;
