import React, { useEffect, useState, useCallback } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import MainCard from "../cards/MainCard";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import {
  Button,
  Grid,
  Modal,
  Typography,
  Box,
  IconButton,
} from "@material-ui/core";
import {
  formatColumnsHeaders,
  formatData,
  formatColumnsString,
} from "../../utils/formatColumnsHeaders";
import { CustomNoRowsOverlay } from "../noRowsOverlay/CustomNoRowsOverlay";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  border: "none",
};

const useStyles = makeStyles({
  root: {
    "& .MuiCircularProgress-svg": {
      color: "#0766FF",
    },
    "& .MuiDataGrid-footerContainer": {
      borderTop: "none",
    },
    "& .MuiDataGrid-columnHeaders": {
      borderBottom: "none",
    },
    /*'& .MuiDataGrid-columnHeaderCheckbox': {
            '& .MuiDataGrid-columnHeaderTitleContainer': {
                // display: 'none'
            }
        },
        '&.MuiDataGrid-columnSeparator': {
            display: 'none'
        },
        '.MuiDataGrid-columnSeparator--sideRight': {
            display: 'none'
        },*/
    "&.MuiDataGrid-root": {
      backgroundColor: "rgb(238 242 246)",
      // borderRadius: 10,
      // border: 'none',
      // color: '#2F4B7C',
      // '& .Mui-selected': {},
      // '&.Mui-selected:hover': {}
    },
    overflow: "auto",
    padding: "0px 4px",
    "& .MuiDataGrid-cell": {
      fontWeight: 600,
      fontSize: "0.8rem",
      borderBottom: "none",
      outline: 0,
    },
    "& .MuiDataGrid-menuIcon": {
      display: "none",
    },
    "& .MuiDataGrid-cell:first-of-type": {
      borderTopLeftRadius: 10,
      borderBottomLeftRadius: 10,
    },
    "& .MuiDataGrid-cell:last-child": {
      borderTopRightRadius: 10,
      borderBottomRightRadius: 10,
    },
    "& .MuiDataGrid-cell:focus-within": {
      outline: "none",
    },
    "& .MuiDataGrid-columnHeader:focus-within": {
      outline: "none",
    },
    "& .MuiDataGrid-row": {
      background: "#fff !important;",
      borderRadius: 10,
      marginBottom: 12,
      cursor: "pointer",
    },
    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
      width: "0.6em",
      height: "0.6em",
    },
    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
      background: "#EEF2F6",
      borderRadius: 10,
    },
    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
      backgroundColor: "#CDD5DF",
      borderRadius: 10,
    },
    "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover": {
      background: "#1e88e5",
    },
    "& .MuiDataGrid-columnHeadersInner": {
      background: "none",
      paddingBottom: 0,
      color: "#657288",
      fontSize: 12,
      borderBottom: "none",
    },
  },
});

export default function DataRender(props) {
  const {
    data,
    loading,
    title,
    selectionModel,
    setSelectionModel,
    handleEdit,
    handleDelete,
    hasCheckBoxes,
    noActions,
    isPrintable,
    componentRef,
    handlePreview,
    rowId,
    showDetails,
    handleShowDetails,
    ischild,
    notshow,
    isViewAction,
    isSales,
    refetch,
    isDisable,
    tag,
    columnsDef = [],
    columnVisibilityModel = {},
  } = props;
  const classes = useStyles();
  const { t } = useTranslation([
    "columns",
    "dashboard",
    "product",
    "common",
    "inventory",
    "fulfillment",
    "hold",
  ]);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState(columnsDef);
  const [idToDelete, setIdToDelete] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!loading && data && data?.length > 0) {
      setRows(formatData(data));
      setColumns(getColumns(data));
    }
  }, [data]);

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setOpen(false);
    setIdToDelete(null);
  };

  const handleTheDelete = useCallback(
    (id) => () => {
      setIdToDelete(id);
      handleOpen();
    },
    []
  );

  const deleteActionCaptured = async () => {
    handleDelete && (await handleDelete([idToDelete]));
    refetch && refetch({});
    handleClose();
  };

  const handleTheEdit = useCallback(
    (id) => () => {
      handleEdit && handleEdit(id);
    },
    []
  );

  const deleteConfirm = (
    <Box sx={modalStyle}>
      <MainCard divider title={isSales ? "Confirm" : "Confirmer"}>
        <Typography id="modal-modal-description" sx={{ fontSize: 14 }}>
          {isSales
            ? "Delete permanently"
            : isDisable
            ? "Désactiver"
            : "Supprimer définitivement"}
          <strong>{idToDelete}</strong> ?
        </Typography>
        <Box display={"flex"} justifyContent={"flex-end"} mt={3}>
          <Box>
            <Grid container spacing={1}>
              <Grid item>
                <Button
                  variant="outlined"
                  color="blue"
                  size="large"
                  onClick={handleClose}
                >
                  {isSales ? "No" : "Non"}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  color="blue"
                  size="large"
                  onClick={() => deleteActionCaptured()}
                  variant="contained"
                >
                  {isSales ? "Yes" : "oui"}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </MainCard>
    </Box>
  );

  const actions = {
    field: "actions",
    type: "actions",
    width: 140,
    align: "right",
    headerAlign: "right",
    renderCell: (params) => {
      let result = [];
      if (handleEdit) {
        result.push(
          <IconButton
            color="primary"
            aria-label="Edit"
            component="span"
            onClick={handleTheEdit(params.id)}
          >
            {isViewAction ? (
              <VisibilityIcon color="primary" />
            ) : (
              <EditIcon color="primary" />
            )}
          </IconButton>
        );
      }
      if (handleDelete) {
        result.push(
          <IconButton
            color="error"
            aria-label="Delete"
            component="span"
            onClick={handleTheDelete(params.id)}
          >
            <DeleteIcon color="error" />
          </IconButton>
        );
      }

      return result;
    },
  };

  const showdetails = {
    field: "Actions",
    type: "actions",
    width: 140,
    align: "right",
    headerAlign: "right",
    getActions: (params) => [
      <Button
        startIcon={<VisibilityIcon />}
        variant="outlined"
        color="blue"
        onClick={() => handleShowDetails(params.id)}
        /*  sx={{ marginTop: -3 }} */
      >
        Details
      </Button>,
    ],
  };

  const getColumns = (data) => {
    let result = [];
    if (data && Array.isArray(data) && data?.length > 0) {
      if (columns.length === 0) {
        Object.keys(data[0]).map((column) => {
          var showthiscol = true;
          if (notshow && Array.isArray(notshow)) {
            const index = notshow.findIndex((el) => el == column);
            if (index != -1) {
              showthiscol = false;
            }
          }
          if (showthiscol) {
            result.push({
              field: column,
              headerName: isSales
                ? formatColumnsString(column)
                : t(formatColumnsHeaders(column, tag)),
              flex: 1,
            });
          }
        });
      } else {
        result = [...result, ...columns];
      }

      !noActions && result.push(actions);
      showDetails && result.push(showdetails);
    }

    return result;
  };

  return (
    <Box>
      <Box sx={{ height: ischild ? 500 : 823 }}>
        <DataGrid
          classes={{ root: classes.root }}
          checkboxSelection={hasCheckBoxes}
          onRowDoubleClick={() =>
            !hasCheckBoxes && handleEdit && handleEdit(selectionModel)
          }
          onRowSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel);
          }}
          rowSelectionModel={selectionModel}
          experimentalFeatures={{ newEditingApi: true }}
          loading={loading}
          rows={rows}
          columns={columns}
          getRowId={(row) => row.name}
          // localeText={frLocal}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          slots={{ toolbar: GridToolbar, noRowsOverlay: CustomNoRowsOverlay }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
              printOptions: { disableToolbarButton: true },
            },
          }}
          disableColumnSelector
          disableDensitySelector
          disableColumnMenu
          density="standard"
          columnVisibilityModel={columnVisibilityModel}
          components={{
            Toolbar: (props) => (
              <>
                <GridToolbar {...props} />
              </>
            ),
          }}
        />
      </Box>
      <Modal onClose={handleClose} open={open}>
        {deleteConfirm}
      </Modal>
    </Box>
  );
}
