import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    ticket : {
      '&:after' : {
        content: '""',
        display: "block",
        height: "70px",
        backgroundImage: "url(/img/border.png)",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center bottom",
        marginTop : '-1.8625rem',
      }
    },
    ticketBlock : {
      textAlign : "center",
      boxShadow: "0px 5px 15px 4px #0000000F",
      [theme.breakpoints.up('md')]: {
        minWidth : 420
      },
      fontFamily: 'Courier Prime',
      color : "#707070",
      fontSize : 14,
      padding : "2rem 0.5rem",
      lineHeight: 2
    },
    ticketHeader : {
      marginBottom: "7px",
      textAlign : "center",
      fontWeight: "bold"
    },
    ticketProducts : {
      display: "flex",
      padding: "50px 38px 10px 350px"
    },
    ticketFooter : {
      marginTop : "2.875rem",
    },
    productsborder : {
      borderBottom: "1px solid #707070",
      paddingBottom: "1.375rem",
      width:"345px",
      marginLeft:"370px"
    },

  
}));