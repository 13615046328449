import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { symbol } from "../../../../helpers/utils";

export const useStyles = makeStyles((theme) => ({
  card: {
    background: theme.palette.secondary.main,
    borderRadius: 10,
    border: "none",
  },
  cardBoldText: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: 14,
  },
  cardTitle: {
    fontSize: 16,
  },
  cardTitleBlock: {
    borderBottom: "1px solid #e7eaef",
    padding: "10px 20px 17px",
  },
  cardContentBlock: {
    padding: "1.4375rem 1.5rem 2.0625rem",
  },
  avatar: {
    width: 46,
    height: 46,
    marginRight: 11,
    color: "#5A8BDB",
    fontSize: 12,
    backgroundColor: theme.palette.secondary.main,
  },
  usernameBlock: {
    background: "#FFF",
    borderRadius: 19,
    padding: 14,
    marginBottom: 17,
  },
  username: {
    color: "#2F334D",
    fontSize: 20,
    fontWeight: 600,
  },
  textLight: {
    color: theme.palette.gray.main,
    fontSize: 12,
    fontWeight: 500,
  },
  text: {
    fontSize: 14,
    fontWeight: 600,
  },
  item: {
    padding: "0rem 1.625rem",
    marginBottom: "1rem",
  },
  backLink: {
    fontSize: 12,
    color: "#2F334D",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    marginTop: 14,
    marginLeft: 22,
  },
  linkIcon: {
    height: 10,
    width: 8,
  },
}));

export const Profil = (props) => {
  const { client, totalAvoirs } = props;
  const classes = useStyles();
  const history = useHistory();
  const orders = useSelector((state) => state.Client.orders);
  const LoyaltyPoints = useSelector((state) => state.Client.loyaltyPoints);
  const UnpaidsalesInvoice = useSelector(
    (state) => state.Client.UnpaidsalesInvoice
  );
  const detailClient = useSelector((state) => state.Checkout.detailClient);
  const globalDefaults = useSelector((state) => state.Login.globalDefaults);
  const sumall = Array.isArray(UnpaidsalesInvoice)
    ? UnpaidsalesInvoice.map((item) => item.outstanding_amount)
        .reduce((prev, curr) => prev + curr, 0)
        .toFixed(3)
    : "0.000";
  const { t } = useTranslation(["clients", "common", "order", "checkout"]);
  const currencies = useSelector((state) => state.Client.currencies);
  const currencySymbol = detailClient?.__onload.dashboard_info[0]
    ? symbol(detailClient?.__onload?.dashboard_info[0]?.currency, currencies)
        ?.symbol
    : symbol(globalDefaults?.default_currency, currencies)?.symbol;
  return (
    <Box mt={7}>
      <Card
        className={clsx(classes.card, classes.cardBoldText)}
        variant="outlined"
      >
        <Typography
          align={"center"}
          color={"primary"}
          variant="caption"
          className={classes.backLink}
          onClick={() => history.push(`/clients`)}
        >
          <ArrowBackIosIcon className={classes.linkIcon} />
          {t("common:clients")}
        </Typography>
        <CardContent>
          <Box
            className={classes.usernameBlock}
            display={"flex"}
            alignItems={"center"}
          >
            <Avatar className={classes.avatar}>
              {client.customer_name && client.customer_name[0]?.toUpperCase()}
            </Avatar>
            <Typography className={classes.username}>
              {client.customer_name && client.customer_name}
            </Typography>
          </Box>
          <Box>
            <Box className={classes.item}>
              <Typography color={"primary"} className={classes.textLight}>
                {t("common:name")}:{" "}
              </Typography>
              <Typography className={classes.text}>
                {client.customer_name.split(" ")[0]}
              </Typography>
            </Box>
            <Box className={classes.item}>
              <Typography color={"primary"} className={classes.textLight}>
                {t("Date de naissance")}:
              </Typography>
              <Typography className={classes.text}>
                {!client?.birth_date ? "-" : client.birth_date}
              </Typography>
            </Box>
            <Box className={classes.item}>
              <Typography color={"primary"} className={classes.textLight}>
                {t("common:phone")}:{" "}
              </Typography>
              <Typography className={classes.text}>
                {!client?.mobile_no ? "-" : client.mobile_no}
              </Typography>
            </Box>
            <Box className={classes.item}>
              <Typography color={"primary"} className={classes.textLight}>
                {t("common:email")}:
              </Typography>
              <Typography className={classes.text}>
                {!client?.email_id ? "-" : client.email_id}
              </Typography>
            </Box>
            <Box className={classes.item}>
              <Typography color={"primary"} className={classes.textLight}>
                {t("SOCIOS")}:
              </Typography>
              <Typography className={classes.text}>
                {!client?.socios ? "-" : client.socios}
              </Typography>
            </Box>
            <Box className={classes.item}>
              <Typography color={"primary"} className={classes.textLight}>
                {t("N° SOCIOS")}:
              </Typography>
              <Typography className={classes.text}>
                {!client?.numero ? "-" : client.numero}
              </Typography>
            </Box>
            <Box className={classes.item}>
              <Typography color={"primary"} className={classes.textLight}>
                {t("common:customerGroup")}:{" "}
              </Typography>
              <Typography className={classes.text}>
                {client?.customer_group}
              </Typography>
            </Box>
            <Box className={classes.item}>
              <Typography color={"primary"} className={classes.textLight}>
                {t("common:commande")}:{" "}
              </Typography>
              <Typography className={classes.text}>
                {Array.isArray(orders) ? orders.length : 0}
              </Typography>
            </Box>
            <Box className={classes.item}>
              <Typography color={"primary"} className={classes.textLight}>
                {t("checkout:dashboardInfoBilling")}:{" "}
              </Typography>
              <Typography className={classes.text}>
                {client.__onload?.dashboard_info[0]?.billing_this_year?.toFixed(
                  3
                ) ?? (0).toFixed(3)}{" "}
                {currencySymbol}
              </Typography>
            </Box>
            <Box className={classes.item}>
              <Typography color={"primary"} className={classes.textLight}>
                {t("checkout:totalUnpaid")}:{" "}
              </Typography>
              <Typography className={classes.text}>
                {client?.__onload?.dashboard_info[0]?.total_unpaid?.toFixed(
                  3
                ) ?? (0).toFixed(3)}{" "}
                {currencySymbol}{" "}
              </Typography>
            </Box>
            <Box className={classes.item}>
              <Typography color={"primary"} className={classes.textLight}>
                {t("clients:lyltyPoints")}:{" "}
              </Typography>
              <Typography className={classes.text}>
                {client?.__onload?.dashboard_info[0]?.loyalty_points ?? 0}
              </Typography>
            </Box>
            <Box className={classes.item}>
              <Typography color={"primary"} className={classes.textLight}>
                {t("clients:numberAvoirs")}:{" "}
              </Typography>
              <Typography className={classes.text}>
                {totalAvoirs?.nbAvoirs ?? 0}
              </Typography>
            </Box>
            <Box className={classes.item}>
              <Typography color={"primary"} className={classes.textLight}>
                {t("clients:amountOfAvoirs")}:{" "}
              </Typography>
              <Typography className={classes.text}>
                {(totalAvoirs?.totalAmount ?? 0)?.toFixed(3)} {currencySymbol}
              </Typography>
            </Box>
            <Box className={classes.item}>
              <Typography color={"primary"} className={classes.textLight}>
                {t("clients:newslttr")}:{" "}
              </Typography>
              <Typography className={classes.text}>
                {client.newsletter === 0 ? (
                  <>{t("common:no")}</>
                ) : (
                  <>{t("common:yes")}</>
                )}
              </Typography>
            </Box>
            <Box className={classes.item}>
              <Typography color={"primary"} className={classes.textLight}>
                {t("clients:country")}
              </Typography>
              <Typography className={classes.text}>
                {client.territory === "Tunisia" ? (
                  <>{t("common:tn")}</>
                ) : (
                  client?.territory
                )}
              </Typography>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};
