import React, { useEffect, useRef } from "react";
import Box from "@material-ui/core/Box";
import { useStyles } from "../../style";
import { PriceResult } from "../priceResult";
import { Ticket } from "../ticket";
import Grid from "@material-ui/core/Grid";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import {
  CreateEmail,
  UpdateEmailClient,
  CancelCheckout,
  LoadDetailClient,
  SetAvoirToPrint,
} from "../../../../store/checkout/checkout.action";
import { useDispatch, useSelector } from "react-redux";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Hold from "../../../../components/hold";
import clsx from "clsx";
import ReactToPrint from "react-to-print";
import IconButton from "@material-ui/core/IconButton";
import { Typography, Modal, TextField } from "@material-ui/core";
import { ComponentToPrint } from "../../../../components/componentToPrint";
import { LoyaltyPointPrint } from "../../../../components/componentToPrint/loyaltyPointClient";
import {
  loadLoyaltyPointsByClient,
  loadClientPaymentEntries,
} from "../../../../store/client/client.action";
import validator from "validator";
import { useTranslation } from "react-i18next";
import CloseIcon from "@material-ui/icons/Close";
import { UpdateAppliedLoyCard } from "../../../../store/cart/cart.action";

export const Result = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation(["payement", "common"]);
  const componentRef = useRef();
  const componentRefFacture = useRef();
  const componentRefBonAchat = useRef();
  const componentRefAvoir = useRef();
  const [action, setAction] = React.useState(0);
  const order = useSelector((state) => state.Checkout.order);
  const client = useSelector((state) => state.Checkout.client);
  const detailClient = useSelector((state) => state.Checkout.detailClient);
  const loyaltyPoints = useSelector((state) => state.Client.loyaltyPoints);
  const paymentEntries = useSelector((state) => state.Client.paymentEntries);
  const contactCompany = useSelector((state) => state.Login.selectedData);
  const notification = useSelector((state) => state.Notification.notification);

  const appliedLoyaltyCard = useSelector(
    (state) => state.Cart.appliedLoyaltyCard
  );

  var themes = useSelector((state) => state.Theme.themes);
  const [errorEmail, setErrorEmail] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [hold, setHold] = React.useState(false);
  const filteredPayments = Array.isArray(paymentEntries)
    ? paymentEntries.filter((p) => {
        return p.unallocated_amount != 0 && p.status == "Submitted";
      })
    : [];
  useEffect(() => {
    /* {code: 'error', message: 'Cost Center None not found'} */
    if (notification && notification.code == "error") {
      setHold(true);
    }
  }, [notification]);
  const handleChangeMessage = (e) => {
    e.preventDefault();
    setMessage(e.target.value);
  };
  const SendEmail = () => {
    const activeTheme = themes.findIndex((obj) => obj.active === 1);
    const theme_id = themes[activeTheme].theme_id;
    if (
      order &&
      Array.isArray(order.docs) &&
      order.docs.length != 0 &&
      order.docs[0].contact_email
    ) {
      dispatch(
        CreateEmail(order.docs[0].contact_email, message, order.docs, theme_id)
      );
    } else if (order && detailClient && detailClient.email_id) {
      dispatch(
        CreateEmail(detailClient.email_id, message, order.docs, theme_id)
      );
    }
    setOpen(false);
  };

  const BackCheckout = () => {
    if (order) {
      dispatch(CancelCheckout());
    }
    if (appliedLoyaltyCard?.redeem_points_amount) {
      dispatch(UpdateAppliedLoyCard(null));
    }
    dispatch(SetAvoirToPrint(null));
    history.push("/checkout");
  };
  const transaction = () => {
    history.push("/transaction");
    // setOpen2(false);
  };
  useEffect(() => {
    if (client) {
      // dispatch(loadLoyaltyPointsByClient(client));
      // dispatch(loadClientPaymentEntries(client));
      dispatch(LoadDetailClient(client.customer_name));
    }
  }, [client]);

  const [state, setState] = React.useState({
    email: "",
  });
  const handleChange = (e) => {
    if (e.target.name == "email") {
      if (validator.isEmail(e.target.value)) {
        setState({ ...state, email: e.target.value });
        setErrorEmail(false);
      } else {
        setState({ ...state, email: e.target.value });
        setErrorEmail(true);
      }
    } else {
      setState({ ...state, [e.target.name]: e.target.value });
    }
  };
  const handleSubmit = () => {
    const data = {
      customer: client.customer_name,
      value: state.email,
    };
    dispatch(UpdateEmailClient(data));
    SendEmail();
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (detailClient && detailClient?.email_id !== "") {
      setState({ ...state, email: detailClient.email_id });
      setErrorEmail(false);
    }
  }, [detailClient]);

  const alertEmail = (
    <div className={classes.paper}>
      <Typography align={"center"} color={"primary"}>
        {t("payement:verifieEmail")}
      </Typography>
      <Box mt={3} justifyContent="center" display="flex">
        <TextField
          required
          error={errorEmail}
          id="outlined-basic"
          label={t("common:email")}
          name="email"
          value={state.email}
          onChange={handleChange}
          variant="outlined"
        />
      </Box>
      <Box mt={3} justifyContent="center" display="flex">
        <TextField
          multiline
          rows={5}
          id="outlined-basic"
          label="Message"
          name="message"
          value={message}
          onChange={handleChangeMessage}
          variant="outlined"
        />
      </Box>
      <Box mt={3} justifyContent="center" display="flex">
        <div className={classes.root}>
          <Button
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            disabled={errorEmail}
          >
            {" "}
            {detailClient && detailClient.email_id
              ? t("common:verif/env")
              : t("common:ajout/env")}{" "}
          </Button>
          <Button color="primary" onClick={handleClickAway}>
            {" "}
            {t("common:annuler")}{" "}
          </Button>
        </div>
      </Box>
    </div>
  );
  return (
    <div>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        className={clsx(classes.titleblock, classes.resultPage)}
      >
        <IconButton onClick={() => BackCheckout()}>
          <CloseIcon
            style={{ position: "initial" }}
            className={classes.backicon}
          />
        </IconButton>
        <Box>{hold && <Hold />}</Box>
      </Box>
      <Box className={clsx(classes.titleblock, classes.resultPage)}>
        <Box mt={3} mr={3} justifyContent="right" display="flex">
          <Grid
            container
            justifyContent="flex-start"
            spacing={1}
            mt={3}
            mr={3}
            display="flex"
          >
            <Grid item container xs={6}>
              <Grid item>
                <ReactToPrint
                  trigger={() => (
                    <Button
                      style={{ marginRight: 10, marginLeft: 10 }}
                      color="primary"
                      variant="contained"
                      disabled={!Array.isArray(loyaltyPoints)}
                      value={1}
                    >
                      {t("payement:bonAchat")}
                    </Button>
                  )}
                  content={() => componentRefBonAchat.current}
                />
                <div className={classes.print}>
                  <LoyaltyPointPrint
                    document={loyaltyPoints}
                    order={loyaltyPoints}
                    title={t("payement:bonAchat")}
                    ref={componentRefBonAchat}
                  />
                </div>
              </Grid>
              <Grid item>
                <ReactToPrint
                  trigger={() => (
                    <Button
                      style={{ marginRight: 10, marginLeft: 10 }}
                      color="primary"
                      variant="contained"
                      value={2}
                    >
                      {t("common:facture")}
                    </Button>
                  )}
                  content={() => componentRefFacture.current}
                />
                <div className={classes.print}>
                  {order ? (
                    <ComponentToPrint
                      isFactureClient={true}
                      isDeliveryDocument={false}
                      document={order.docs[0]}
                      order={order.docs[0]}
                      title={t("common:facture")}
                      ref={componentRefFacture}
                      contactCompany={contactCompany && contactCompany.contact}
                    />
                  ) : (
                    <ComponentToPrint
                      isFactureClient={true}
                      isDeliveryDocument={false}
                      title={t("common:facture")}
                      ref={componentRefFacture}
                      contactCompany={contactCompany && contactCompany.contact}
                    />
                  )}
                </div>
              </Grid>
              <Grid item>
                <Button
                  style={{ marginRight: 10, marginLeft: 10 }}
                  color="primary"
                  variant="contained"
                  value={5}
                  onClick={() => setOpen(true)}
                >
                  {t("payement:EnvoyerFacture")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
           {/* <Grid item>
                <Button
                  style={{ marginRight: 17 }}
                  color="primary"
                  variant="contained"
                  value={5}
                  onClick={transaction}
                >
                  Transactions ClearTax
                </Button>
              </Grid> */}
          <ReactToPrint
            trigger={() => (
              <Button
                style={{ marginRight: -17 }}
                color="primary"
                autoFocus
                variant="contained"
              >
                {t("common:imprimer")}
              </Button>
            )}
            content={() => componentRef.current}
          />
        </Box>
      </Box>
      <div className={classes.priceResultBlock}>
        <div style={{ width: "50%" }}>
          <PriceResult />
        </div>
        <div style={{ width: "50%" }}>
          <Ticket ref={componentRef} isDuplicata={false} />
        </div>
      </div>
      <Modal
        open={open}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {alertEmail}
      </Modal>
    </div>
  );
};
