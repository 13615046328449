import { CLEAR_STATE } from "../login/login.type";
import {
  SET_CURRENT_PAGE
} from "./initialization.type";

const initialState = {
  currentPage: {
    clients: 0,
    products: 0,
    orders: 0,
    payementMethods: 0,
    inventory: 0,
    posProducts: 0
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.currentPage,
      };
    case CLEAR_STATE:
      return (state = initialState);
    default:
      return state;
  }
};

export default reducer;
