import React, { useEffect, useState } from "react";
import {
  ClickAwayListener,
  Select,
  Typography,
  MenuItem,
  InputLabel,
  Button,
  OutlinedInput,
  Box,
  FormControl,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Table,
  InputAdornment,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ExpandMore from "@material-ui/icons/ExpandMore";
import KeyboardIcon from "@material-ui/icons/Keyboard";
import { loadInvoicesReturnedData } from "../../../initialization/apiconfig";
import { Loader } from "../../../../components/loader";
import subMilliseconds from "date-fns/subMilliseconds/index";

export const Salesreturnsmanagement = (props) => {
  const {
    setOpenReturnMngModal,
    searchTerm,
    classes,
    tableClasses,
    handleStatusChange,
    handleOpen,
    handleChangeSearchTerm,
    status,
    motifs,
    ChangeMotifs,
    recentOrdersList,
    filteredRecentOrders,
    handleCloseModal,
    handleClick,
    listInvoicesReturned,
    loading,
    isOthers,
  } = props;

  const invoices = useSelector((state) => state.Pos.invoices);
  const openingAmount = parseFloat(
    useSelector((state) => state.Login.cushFund)
  );

  const returnReasonList = useSelector(
    (state) => state.Configuration.returnReason
  );

  const { t } = useTranslation(["common", "checkout"]);

  const [sumReturned, setSumReturned] = useState([]);

  const getCashPaymentsBalance = (invoices) => {
    var ttlBalance = 0;
    invoices.length != 0 &&
      invoices.map((inv, i) => {
        inv.payments.map((p, i) => {
          if (p.mode_of_payment == "Cash") {
            ttlBalance += p.amount;
          }
        });
      });
    return ttlBalance;
  };
  const caisseBalance = (
    getCashPaymentsBalance(invoices) + openingAmount
  ).toFixed(3);

  const verificationsReturne = async () => {
    try {
      let tab = [];
      recentOrdersList.forEach((recentOrder) => {
        let sum = 0;
        listInvoicesReturned.forEach((invoiceReturned) => {
          if (invoiceReturned?.return_against === recentOrder?.name) {
            sum += invoiceReturned?.grand_total;
          }
          /* base_grand_total: -22.42
                    company: "Petit Bateau"
                    currency: "TND"
                    customer: "1547"
                    customer_name: "1547"
                    grand_total: -22.42
                    is_return: 1
                    name: "ACC-PSINV-2023-00276"
                    paid_amount: -22.42
                    return_against: "ACC-PSINV-2023-00275"
                    status: "Return"
                    title: "1547" */
        });
        tab.push({ name: recentOrder?.name, returnesum: sum });
      });
      setSumReturned(tab);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!loading) verificationsReturne();
  }, [loading]);

  if (loading) {
    return (
      <div className={classes.paper}>
        <Loader />
      </div>
    );
  }
  return (
    <div className={classes.paper}>
      <Typography
        align={"center"}
        color={"primary"}
        style={{ fontWeight: "bold", fontSize: 20, marginBottom: 20 }}
      >
        {t("checkout:GVente")}
      </Typography>
      <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
        <FormControl variant="outlined" size="small" color="primary">
          <InputLabel>{t("common:rechercher")}</InputLabel>
          <OutlinedInput
            label={t("common:rechercher")}
            autoComplete="off"
            id="outlined-adornment-weight"
            value={searchTerm}
            name="SearchTerm"
            placeholder="Client, Ref, Date, Grand Total..."
            endAdornment={
              <>
                <KeyboardIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => handleOpen("return")}
                />
                <InputAdornment position="end">
                  <span className="icon-search" />
                </InputAdornment>
              </>
            }
            onChange={handleChangeSearchTerm}
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              "aria-label": "weight",
            }}
          />
        </FormControl>
        <FormControl size="small" variant="outlined" color="primary">
          <InputLabel id="demo-simple-select-label">
            {t("common:statut")}
          </InputLabel>
          <Select
            name="Statut"
            value={status}
            onChange={handleStatusChange}
            id="demo-multiple-name"
            IconComponent={ExpandMore}
            input={
              <OutlinedInput
                labelId="demo-simple-select-label"
                align="left"
                name="Statut"
                label="Statut"
                id="demo-simple-select-outlined-label"
              />
            }
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
          >
            <MenuItem value="Paid">{t("checkout:payé")}</MenuItem>
            <MenuItem value="Consolidated">{t("checkout:consolidé")}</MenuItem>
          </Select>
        </FormControl>
        <FormControl size="small" variant="outlined" color="primary">
          <InputLabel id="demo-simple-select-label">
            {t("checkout:RRetour")}
          </InputLabel>
          <Select
            id="demo-multiple-name"
            value={motifs}
            name="motifs"
            onChange={ChangeMotifs}
            IconComponent={ExpandMore}
            input={
              <OutlinedInput
                labelId="demo-simple-select-label"
                align="left"
                name="motifs"
                label={t("checkout:RRetour")}
                id="demo-simple-select-outlined-label"
              />
            }
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
          >
            {Array.isArray(returnReasonList)
              ? returnReasonList.map((reason) => (
                  <MenuItem key={reason?.name} value={reason?.name}>
                    {reason?.name}
                  </MenuItem>
                ))
              : null}
            <MenuItem
              key={"others"}
              style={{ color: "GrayText" }}
              value={"Others"}
            >
              Other...
            </MenuItem>
          </Select>
        </FormControl>
        {isOthers ? (
          <FormControl variant="outlined" size="small" color="primary">
            <InputLabel>{t("checkout:RRetour")}</InputLabel>
            <OutlinedInput
              label={t("checkout:RRetour")}
              autoComplete="off"
              id="outlined-adornment-weight"
              value={motifs}
              name="SearchTerm"
              placeholder="Others..."
              onChange={ChangeMotifs}
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                "aria-label": "weight",
              }}
            />
          </FormControl>
        ) : null}
        <Box className={tableClasses.paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow className={classes.tableRow}>
                <TableCell className={tableClasses.tablecellHeader}>
                  {t("common:ref")}
                </TableCell>
                <TableCell className={tableClasses.tablecellHeader}>
                  {t("common:client")}
                </TableCell>
                <TableCell className={tableClasses.tablecellHeader}>
                  {t("common:MontTotal")}
                </TableCell>
                <TableCell className={tableClasses.tablecellHeader}>
                  {t("common:dateAchat")}
                </TableCell>
                {status != "Draft" && status != "Return" && (
                  <TableCell
                    className={tableClasses.tablecellHeader}
                  ></TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBody}>
              {searchTerm == ""
                ? recentOrdersList &&
                  recentOrdersList
                    .filter(
                      (r, i) =>
                        Math.abs(
                          new Date(r.posting_date).getTime() -
                            new Date().getTime()
                        ) /
                          (24 * 60 * 60 * 1000) <
                        30
                    )
                    .map((row) => (
                      <TableRow key={row.name} className={classes.tableRow}>
                        <TableCell className={tableClasses.tableCell}>
                          {row.name}
                        </TableCell>
                        <TableCell className={tableClasses.tableCell}>
                          {row.customer}
                        </TableCell>
                        <TableCell className={tableClasses.tableCell}>
                          {row.grand_total.toFixed(3)} {row.currency}
                        </TableCell>
                        <TableCell className={tableClasses.tableCell}>
                          {row.posting_date}
                        </TableCell>
                        {status != "Draft" && status != "Return" && (
                          <TableCell
                            className={tableClasses.tableCell}
                            align="right"
                          >
                            <Button
                              disabled={
                                Math.abs(
                                  sumReturned.find(
                                    (element) => element?.name === row?.name
                                  )?.returnesum
                                ) >= Math.abs(row?.grand_total)
                              }
                              /*   row.grand_total <= 0 ||
                                                          (Array.isArray(avoirList) &&
                                                              avoirList?.findIndex(
                                                                  (item) => item.return_against == row.name
                                                              ) != -1) ||
                                                          row.grand_total > caisseBalance */

                              variant="contained"
                              color="primary"
                              onClick={() => handleClick(row.name)}
                            >
                              {t("checkout:Return")}
                            </Button>
                          </TableCell>
                        )}
                      </TableRow>
                    ))
                : filteredRecentOrders &&
                  filteredRecentOrders
                    .filter(
                      (r, i) =>
                        Math.abs(
                          new Date(r.posting_date).getTime() -
                            new Date().getTime()
                        ) /
                          (24 * 60 * 60 * 1000) <
                        30
                    )
                    .map((row) => (
                      <TableRow key={row.name} className={classes.tableRow}>
                        <TableCell className={tableClasses.tableCell}>
                          {row.name}
                        </TableCell>
                        <TableCell className={tableClasses.tableCell}>
                          {row.customer}
                        </TableCell>
                        <TableCell className={tableClasses.tableCell}>
                          {row.grand_total} {row.currency}
                        </TableCell>
                        <TableCell className={tableClasses.tableCell}>
                          {row.posting_date}
                        </TableCell>
                        {status != "Draft" && status != "Return" && (
                          <TableCell
                            className={tableClasses.tableCell}
                            align="right"
                          >
                            <Button
                              disabled={row.grand_total <= 0}
                              variant="contained"
                              color="primary"
                              onClick={() => handleClick(row.name)}
                            >
                              {t("checkout:Return")}
                              {/* verificationsReturne */}
                            </Button>
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
            </TableBody>
          </Table>
        </Box>

        <Button
          variant="contained"
          color="secondary"
          onClick={handleCloseModal}
        >
          {t("common:fermer")}
        </Button>
      </div>
    </div>
  );
};
