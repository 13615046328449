import React, { useRef, useEffect, useState,useMemo } from "react";
import clsx from "clsx";
import { useTableStyles } from "../../../../../styles/table.style";
import { useSelector } from "react-redux";
import { formatDate } from "../../../../../helpers/utils";
import DeleteIcon from "@material-ui/icons/Delete";
import { useDispatch } from "react-redux";
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Modal,
  OutlinedInput,
} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { LoadPricingRuleByName } from "../../../../../store/client/client.action";
import { CadeauComponentToPrint } from "../../../../../components/componentToPrint/carteCadeau";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { useParams } from "react-router-dom";
import { Button } from '@material-ui/core';
import {
  FormControl,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { GiftCardForm } from "../giftCardForm";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "1.0625rem 1.5rem",
  },
  price: {
    fontWeight: 600,
  },
  filterList: {
    borderTop: "none",
  },
  textLight: {
    color: theme.palette.gray.main,
    fontSize: 12,
    fontWeight: 500,
    marginLeft: 420,
  },
  item: {
    padding: "0rem 1.625rem",
    marginBottom: "1rem",
  },
  formControl: {
    maxWidth: 300,
    marginLeft: 420,
    "& .MuiOutlinedInput-input": {
      padding: "8px 14px !important",
      fontSize: 12,
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: 10,
    },
  },
  paper: {
    position: "absolute",
    width: "auto",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 10,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  },
  input: {
    marginRight: 10,
    marginBottom: 15,
    "& .MuiInputBase-root": {
      background: theme.palette.white.main,
    },
    "& .MuiOutlinedInput-input": {
      padding: "8.9px 14px",
      fontSize: 12,
    },
  },
  montant: {
    fontSize: 40,
    color: theme.palette.productsTable.button,
    marginBottom: 10,
  },
  print: {
    display: "none",
  },
}));


export const ClientCadeauDetails = () => {
  let { id } = useParams();
  const classes = useStyles();
  const tableClasses = useTableStyles();
  const dispatch = useDispatch();
  const [openCard, setOpenCard] = React.useState(false);
  //const [couponCodes, setCouponCodes] = useState([]);
  const [action, setAction] = React.useState(0);
  // const [open, setOpen] = React.useState(false);
  const client = useSelector((state) => state.Client.client);
  const couponCodes = useSelector((state) => state.Client.couponCodes);
  //console.log(couponCodes,'couponCodes');
  const currencies = useSelector((state) => state.Client.currencies);
  const globalDefaults = useSelector((state) => state.Login.globalDefaults);
  const { t } = useTranslation(["common", "clients", "order"]);

  const [pricingruleList, setPricingRuleList] = useState([]);

  const header = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
    Authorization: JSON.parse(localStorage.getItem("user")).token,
  };

  const getPricingRuleList = () => {
    fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
      method: "POST",
      headers: header,
      body: JSON.stringify({
        doctype: "Pricing Rule",
        fields: ['`tabPricing Rule`.`name`', '`tabPricing Rule`.`discount_amount`'],
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (Array.isArray(data)) {
          setPricingRuleList(data);
        }
      });
  };

  useEffect(() => {
    getPricingRuleList();
    }
    , []);

  //console.log(pricingruleList, "pricingruleList");

  const symbol = (currency) => {
    const x = currencies.find(function (c, i) {
      if (c.name === currency) return true;
    });
    return x?.symbol;
  };

  const currencySymbol =
    client && client.default_currency
      ? symbol(client.default_currency)
      : globalDefaults?.default_currency;
  // const handleClickOpen = () => {
  //   setOpen(true);
  // };
  // const HandleClose = () => {
  //   setOpen(false);
  // };

  const [state, setState] = React.useState({
    open: false,
    row: null,
  });

  const handleChangeAction = (e) => {
    setAction(e.target.value);
  };

  const handleClickOpen = (row) => () => {
    setState({ open: true, row: row });
    dispatch(LoadPricingRuleByName(row?.pricing_rule));
  };
  const handleClose = () => {
    setState({ open: false });
  };
  const handleCloseGift = () => {
    setOpenCard(false);
  };

  const getDiscountAmount = useMemo(() => {
    const discountMap = new Map(pricingruleList.map(rule => [rule.name, rule.discount_amount]));
    return (pricingRuleName) => discountMap.get(pricingRuleName) || '-';
  }, [pricingruleList]);

  const body = (
    <div className={classes.paper}>
      <Typography align={"center"} color={"primary"}>
        {t("clients:addGiftCard")}
      </Typography>
      <Box mt={3} justifyContent="center" display="flex">
        <GiftCardForm
          isClient={true}
          isCheckout={false}
          handleClose={handleCloseGift}
          customer={id}
        />
      </Box>
    </div>
  );

  const PreviewGiftCard = (
    <div>
      <CadeauComponentToPrint handleClose={handleClose} state={state} />
    </div>
  );
  return (
    <Box>
       <Box mt={3} display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpenCard(true)}
          style={{ minWidth: '120px', marginBottom: '10px' }}
        >
          {t("clients:addGiftCard")}
        </Button>
      </Box>
      <Box className={clsx(classes.root, tableClasses.paper)}>
        <Table className={tableClasses.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={tableClasses.tablecellHeader} align="left">
                {t("common:name")}
              </TableCell>
              <TableCell className={tableClasses.tablecellHeader} align="left">
                {t("checkout:couponCode")}
              </TableCell>
              {/* <TableCell className={tableClasses.tablecellHeader} align="left">
              <TableCell className={tableClasses.tablecellHeader} align="left">
                {t("checkout:PricingRule")}
              </TableCell> */}
              <TableCell className={tableClasses.tablecellHeader} align="left">
                {t("common:Amount")}
              </TableCell>
              <TableCell className={tableClasses.tablecellHeader} align="left">
                {t("checkout:ValidFrom")}
              </TableCell>
              <TableCell className={tableClasses.tablecellHeader} align="left">
                {t("checkout:ValidUntil")}
              </TableCell>
              <TableCell className={tableClasses.tablecellHeader} align="left">
                {t("common:statut")}
              </TableCell>
              <TableCell className={tableClasses.tablecellHeader} align="left">
                {t("common:imprimer")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(couponCodes) &&
              couponCodes
                .filter((s) => s.coupon_type == "Gift Card")
                .map((row, index) => (
                  <>
                    <TableRow className={tableClasses.tableRow} key={index}>
                      <TableCell
                        className={tableClasses.tableCell}
                        align="left"
                      >
                        {row.coupon_name}
                      </TableCell>
                      <TableCell
                        className={tableClasses.tableCell}
                        align="left"
                      >
                        {row.coupon_code}
                      </TableCell>
                     {/* <TableCell
                        className={tableClasses.tableCell}
                        align="left"
                      >
                        {row.pricing_rule}
                      </TableCell>*/}
                      <TableCell className={tableClasses.tableCell} align="left">
                      {getDiscountAmount(row.pricing_rule)}
                      </TableCell>
                      <TableCell
                        className={tableClasses.tableCell}
                        align="left"
                      >
                        {formatDate(row.valid_from, "YYYY-MM-DD")}
                      </TableCell>
                      <TableCell
                        className={tableClasses.tableCell}
                        align="left"
                      >
                        {formatDate(row.valid_upto, "YYYY-MM-DD")}
                      </TableCell>
                      <TableCell
                        className={clsx(
                          tableClasses.tableCell,
                          row.used == 0 ? classes.positive : classes.negative
                        )}
                        align="left"
                      >
                        {row.used == 0 ? "activée" : "consommée"}
                      </TableCell>
                      <TableCell
                        className={tableClasses.tableCell}
                        align="left"
                      >
                        <IconButton aria-label="print">
                          <VisibilityIcon
                            color="primary"
                            onClick={handleClickOpen(row)}
                          />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  </>
                ))}
          </TableBody>
        </Table>
      </Box>
      <Modal
        open={state.open}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {PreviewGiftCard}
      </Modal>
      <Modal open={openCard}>{body}</Modal>
    </Box>
  );
};
