import React from "react";
import { Card, Grid, CardContent, Typography, Box } from "@material-ui/core";
import clsx from "clsx";
import { useStyles } from "./style";
import {useTranslation} from "react-i18next";

const MessageStock = ({ productStock }) => {
  const {t} = useTranslation(["common","fulfillment"]);
  const classes = useStyles();
  return (
    <div>
      <Card
        className={clsx(classes.card, classes.cardBoldText)}
        variant="outlined"
      >
        <CardContent>
          <Box className={classes.cardTitleBlock}>
            <Typography
              className={clsx(classes.cardTitle, classes.cardBoldText)}
            >
              Magasins
            </Typography>
          </Box>
          {productStock && productStock.length !== 0 && (
            <>
              {productStock &&
                productStock.map((row, index) => (
                  <Grid container spacing={3} display="flex" flexWrap="wrap" key={index}>
                    <Grid item xs={5}>
                      <Typography
                        className={classes.cardLightText}
                        variant="caption"
                        display="block"
                      >
                        Magasin
                      </Typography>
                      <Typography
                        className={classes.cardLightText}
                        variant="caption"
                        display="block"
                      >
                       {t("fulfillment:actualQty")} 
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography
                        className={classes.cardBoldText}
                        variant="caption"
                        display="block"
                      >
                        {row.warehouse}
                      </Typography>
                      <Typography
                        className={classes.cardBoldText}
                        variant="caption"
                        display="block"
                      >
                        {row.actual_qty}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
            </>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default MessageStock;
