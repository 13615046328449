import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    "& > *": {
      marginBottom: "25px !important",
    },
    formControl: {
      margin: theme.spacing(1),
    },
  },
  resize: {
    fontSize: 15,
  },
  icon: {
    position: "absolute",
    top: 15,
    left: 12,
    "& span": {
      fontSize: 24,
      color: theme.palette.gray.main,
    },
    zIndex: 100,
  },
  box: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    textAlign: "center",
  },
  typography: {
    width: "100%",
    marginBottom: "25px !important",
    marginTop: "5px !important",
  },
  button: {
    width: "100%",
    marginTop: 21,
    padding: "0.9375rem !important",
    fontWeight: "600 !important",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  currency: {
    color: theme.palette.primary.main,
  },
  img: {
    marginBottom: 20,
  },
  ticketBlock: {
    textAlign: "center",
    boxShadow: "0px 5px 15px 4px #0000000F",
    [theme.breakpoints.up("md")]: {
      minWidth: 420,
    },
    fontFamily: "Courier Prime",
    color: "#707070",
    fontSize: 14,
    padding: "2rem 0.5rem",
    lineHeight: 2,
  },
  ticketHeader: {
    marginBottom: "7px",
    textAlign: "center",
    fontWeight: "bold",
  },
  paper: { minWidth: "500px" },
  ticketX: {
    display: "flex",
    padding: "20px 38px 10px 40px",
  },
  ticket: {
    "&:before": {
      content: '""',
      display: "block",
      height: "70px",
      backgroundImage: "url(/img/border.png)",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center bottom",
      marginTop: "-1.8625rem",
    },
  },
  signaturePad: {
    border: "1px solid gray",
    padding: "5px 10px",
  },
  hidden: {
    display: "none",
  },
}));

export const useOutlinedInputStyles = makeStyles((theme) => ({
  root: {
    "& $notchedOutline": {
      borderColor: "#A6B4CB",
    },
    "&:hover $notchedOutline": {
      borderColor: theme.palette.primary.main,
    },
    "&$focused $notchedOutline": {
      borderColor: theme.palette.primary.main,
    },
  },
  focused: {
    "&.shrink": {
      backgroundColor: "grey",
    },
  },
  notchedOutline: {},
}));
