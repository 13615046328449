import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import Badge from "@material-ui/core/Badge";
import MainCard from "../../../components/extended/MainCard";
import Drawer from "@material-ui/core/Drawer";
import {
  Avatar,
  Box,
  Chip,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  SvgIcon,
  Tooltip,
  Typography,
  styled,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import {
  LoadNotifications,
  MarkAsRead,
} from "../../../store/broadcastNotification/broadcastNotification.action";
import { loadNotificationsData } from "../../../pages/initialization/api";

function NotificationIcon(props) {
  return (
    <SvgIcon {...props} viewBox="100 -50 600 600" fontSize="inherit">
      <path
        style={{ fill: "#DFC136" }}
        d="M400,0c-18.645,0-33.758,15.114-33.758,33.758v123.458h67.516V33.758C433.758,15.114,418.645,0,400,0z"
      />
      <path
        style={{ fill: "#FEDC3D" }}
        d="M567.34,202.831l28.875,219.429h-45.011l-22.247-219.429 c-8.316-82.078-62.081-145.566-127.415-146.556C486.534,57.029,556.526,120.607,567.34,202.831z"
      />
      <path
        style={{ fill: "#BFA52E" }}
        d="M400,25.319c-4.661,0-8.44,3.778-8.44,8.44v33.758h16.879V33.758 C408.44,29.096,404.662,25.319,400,25.319z"
      />
      <path
        style={{ fill: "#FFE777" }}
        d="M528.957,202.831l22.247,219.429H203.786l28.875-219.429 c10.881-82.719,81.648-146.567,167.338-146.567c0.518,0,1.035,0,1.542,0.011C495.875,57.265,549.641,120.753,528.957,202.831z"
      />
      <path
        style={{ fill: "#BFA52E" }}
        d="M591.736,388.22h-33.758l33.758,67.517h33.758v-33.758 C625.494,403.411,610.303,388.22,591.736,388.22z"
      />
      <path
        style={{ fill: "#FEDC3D" }}
        d="M429.955,421.978l-28.683,90.011c41.118-0.45,76.946-22.967,96.2-56.252L429.955,421.978z"
      />
      <path
        style={{ fill: "#FFE777" }}
        d="M302.529,455.736l80.592-45.011l80.592,45.011c-12.513,33.106-35.75,55.555-62.441,56.252 C400.855,512,400.428,512,400,512C358.354,512,321.985,489.371,302.529,455.736z"
      />
      <path
        style={{ fill: "#DFC136" }}
        d="M591.736,421.978v33.758H174.506v-33.758c0-18.567,15.191-33.758,33.758-33.758h349.713 C576.544,388.22,591.736,403.411,591.736,421.978z"
      />
      <circle style={{ fill: "#FEDC3D" }} cx="454.812" cy="180.325" r="8.44" />
      <circle style={{ fill: "#FEDC3D" }} cx="499.823" cy="202.831" r="8.44" />
      <circle style={{ fill: "#FEDC3D" }} cx="300.177" cy="315.358" r="8.44" />
    </SvgIcon>
  );
}

const useStyles = makeStyles((theme) => ({
  margin: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  box: {
    fontSize: 13,
    color: theme.palette.primary.main,
  },
  icon: {
    color: theme.palette.primary.main,
    opacity: 1,
    float: "right",
    cursor: "pointer",
    height: 30,
    width: 30,
    transition: "transform 0.2s ease-in-out",
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
  adminIcon: {
    color: theme.palette.primary.main,
    opacity: 1,
    float: "right",
    cursor: "pointer",
    transition: "transform 0.2s ease-in-out",
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
  iconBtn: {},
  badge: {
    color: theme.palette.primary.main,
  },
  unreadBadge: {
    color: theme.palette.primary.main,
    marginRight: 10,
    fontWeight: "bold",
  },
  list: {
    width: "100%",
    maxWidth: 330,
    padding: 0,
  },
  title: {
    fontWeight: 700,
    marginBottom: 30,
    paddingTop: 5,
  },
  listItem: {
    marginBottom: 10,
  },
  badge: {
    height: "12px",
    "&.MuiBadge-badge": {
      fontSize: "10px",
    },
  },
  icon1: {
    color: theme.palette.warning.dark,
    backgroundColor: theme.palette.warning.light,
    border: "none",
    borderColor: theme.palette.warning.main,
  },
  icon2: {
    color: theme.palette.success.dark,
    backgroundColor: theme.palette.success.light,
    border: "none",
    borderColor: theme.palette.success.main,
  },
  icon3: {
    color: theme.palette.success.dark,
    backgroundColor: theme.palette.success.light,
    border: "none",
    borderColor: theme.palette.success.main,
  },
  icon4: {
    color: theme.palette.success.dark,
    backgroundColor: theme.palette.success.light,
    border: "none",
    borderColor: theme.palette.success.main,
  },
  icon5: {
    color: theme.palette.success.dark,
    backgroundColor: theme.palette.success.light,
    border: "none",
    borderColor: theme.palette.success.main,
  },
  icon6: {
    color: theme.palette.error.dark,
    backgroundColor: theme.palette.error.light,
    border: "none",
    borderColor: theme.palette.error.main,
  },
  icon7: {
    color: theme.palette.success.dark,
    backgroundColor: theme.palette.success.light,
    border: "none",
    borderColor: theme.palette.success.main,
  },
  icon8: {
    color: theme.palette.success.dark,
    backgroundColor: theme.palette.success.light,
    border: "none",
    borderColor: theme.palette.success.main,
  },
  icon9: {
    color: theme.palette.success.dark,
    backgroundColor: theme.palette.success.light,
    border: "none",
    borderColor: theme.palette.success.main,
  },
  chip: {
    color: theme.palette.background.default,
    backgroundColor: theme.palette.primary.main,
  },
}));

const ListItemWrapper = styled("div")(({ theme }) => ({
  cursor: "pointer",
  padding: 16,
  borderRadius: 10,
  "&:hover": {
    background: theme.palette.background.paper,
  },
  "& .MuiListItem-root": {
    padding: 0,
  },
}));

export const Notifications = ({ size }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const notificationsRetreived = useSelector(
    (state) => state.BroadcastNotification.notifications
  );

  const selectedData = useSelector((state) => state.Login.selectedData);
  const user = useSelector((state) => state.Login.user);
  const stores = useSelector((state) => state.Login.stores);

  const [filter, setFilter] = useState("All");

  const [open, setOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);

  const typeNotificationCashier = [
    "Stock Entry",
    "Fulfillment",
    "Fullfilment Packing",
    "Hold",
  ];

  const typeNotificationAdmin = [
    "Stock Entry",
    "Material Request",
    "Fulfillment",
    "Hold",
    "re_order",
    // "coffre", the stock manager won't get the coffre-op notification
  ];

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const handleOpenNotification = (notification) => {
    let currentUserId = JSON.parse(localStorage.getItem("user"))?.id;
    const notifIndex = notifications?.findIndex(
      (el) => el?.id === notification?.id
    );

    if (notifIndex !== -1 && currentUserId) {
      const isRead = notifications[notifIndex]?.users?.some(
        (user) => user?.id === currentUserId
      );
      if (!isRead) {
        dispatch(MarkAsRead(notification.id, currentUserId));
        setTimeout(() => {
          loadNotificationsData().then((response) => {
            dispatch(LoadNotifications(response.data));
          });
        }, 3000);
      }

      const url =
        user?.role == "Cashier"
          ? notification?.url?.replaceAll("stock-entry", "notification/details")
          : notification?.url;
      history.push(url);
    }
  };

  function readByCurrentUser(users, currentUserId) {
    return users?.some((user) => user.id === currentUserId);
  }

  function getUnreadNotifsPerUser() {
    let currentUserId = JSON.parse(localStorage.getItem("user"))?.id;
    if (!notifications || !currentUserId || notifications?.length === 0) {
      return null;
    } else {
      const notificationRows = notifications?.filter(
        (e) => e != null && e != undefined
      );
      let resultRows = [];
      notificationRows?.forEach((el) => {
        if (!readByCurrentUser(el.users, currentUserId)) {
          resultRows.push(el);
        }
      });

      if (resultRows?.length) {
        return resultRows?.length;
      } else {
        return null;
      }
    }
  }

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpen(!open);
  };

  useEffect(() => {
    let central_warehouse = [];
    stores?.forEach((element) => {
      if (element && element?.is_storage == 1) {
        central_warehouse.push(element?.name);
      }
    });
    if (notificationsRetreived && selectedData && user) {
      let tab = notificationsRetreived.filter(
        (element) =>
          element &&
          ((user?.role == "Admin" &&
            typeNotificationAdmin?.includes(element?.doctype)) ||
            (user?.role == "Cashier" &&
              typeNotificationCashier?.includes(element?.doctype))) &&
          (element?.warehouseCible ||
            element?.warehouseTarget ||
            element?.doctype == "re_order") &&
          (selectedData?.store === element?.warehouseCible ||
            selectedData?.store === element?.warehouseTarget ||
            (user?.role == "Admin" &&
              (central_warehouse?.includes(element?.warehouseCible) ||
                central_warehouse?.includes(element?.warehouseTarget) ||
                element?.doctype == "re_order")))
      );
      setNotifications(tab);
    }
  }, [notificationsRetreived, selectedData?.store, user?.role]);

  const getIcon = (type) => {
    switch (type) {
      case "Material Request Material Transfer":
        return (
          <Avatar className={classes.icon1}>
            <span className="icon-switch" />
          </Avatar>
        );
      case "Material Request Purchase":
        return (
          <Avatar className={classes.icon2}>
            <span className="icon-shop" />
          </Avatar>
        );
      case "Stock Entry":
        return (
          <Avatar className={classes.icon3}>
            <span className="icon-package" />
          </Avatar>
        );
      case "Fulfillment":
        return (
          <Avatar className={classes.icon4}>
            <span className="icon-package-2" />
          </Avatar>
        );
      case "POS Opening Entry":
        return (
          <Avatar className={classes.icon5}>
            <span className="icon-list" />
          </Avatar>
        );
      case "POS Closing Entry":
        return (
          <Avatar className={classes.icon6}>
            <span className="icon-list" />
          </Avatar>
        );
      case "POS Invoice":
        return (
          <Avatar className={classes.icon7}>
            <span className="icon-list" />
          </Avatar>
        );
      case "Inventory":
        return (
          <Avatar className={classes.icon8}>
            <span className="icon-package" />
          </Avatar>
        );

      default:
        return (
          <Avatar className={classes.icon9}>
            <span className="icon-package" />
          </Avatar>
        );
    }
  };

  function formatRelativeTime(created_at) {
    let now = new Date();
    let createdDate = new Date(created_at);

    let diffInMs = now - createdDate;
    let diffInSeconds = Math.floor(diffInMs / 1000);
    let diffInMinutes = Math.floor(diffInSeconds / 60);
    let diffInHours = Math.floor(diffInMinutes / 60);
    let diffInDays = Math.floor(diffInHours / 24);

    if (diffInDays > 0) {
      return `${diffInDays} day${diffInDays > 1 ? "s" : ""} ago`;
    } else if (diffInHours > 0) {
      return `${diffInHours} hour${diffInHours > 1 ? "s" : ""} ago`;
    } else if (diffInMinutes > 0) {
      return `${diffInMinutes} minute${diffInMinutes > 1 ? "s" : ""} ago`;
    } else {
      return `${diffInSeconds} second${diffInSeconds > 1 ? "s" : ""} ago`;
    }
  }

  useEffect(() => {
    let currentUserId = JSON.parse(localStorage.getItem("user"))?.id;
    if (notificationsRetreived) {
      const notificationRows = notificationsRetreived?.filter(
        (e) => e != null && e != undefined
      );
      if (filter === "Unread") {
        let rows = notificationRows;
        let resultRows = [];
        rows?.forEach((el) => {
          if (!readByCurrentUser(el.users, currentUserId)) {
            resultRows.push(el);
          }
        });
        setNotifications(resultRows);
      } else {
        setNotifications(notificationRows);
      }
    }
  }, [filter]);

  return (
    <Box>
      {/* Icon */}
      <Tooltip title="Notifications" placement="top-start">
        <IconButton onClick={handleDrawerOpen} size={!size ? null : "small"}>
          <Badge
            badgeContent={getUnreadNotifsPerUser()}
            color="error"
            classes={size ? { badge: classes.badge } : {}}
          >
            <NotificationIcon
              className={!size ? classes.icon : classes.adminIcon}
            />
          </Badge>
        </IconButton>
      </Tooltip>

      {/* Drawer */}
      <Drawer
        variant="temporary"
        anchor={"right"}
        style={{ left: "auto" }}
        open={open}
        onClose={toggleDrawer("right", true)}
      >
        <MainCard
          title={
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                variant="h5"
                style={{ fontWeight: 800, color: "black" }}
              >
                Notifications
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Chip
                  component={"button"}
                  onClick={() => setFilter("All")}
                  size="small"
                  label={"All"}
                  style={{
                    marginLeft: 5,
                    cursor: "pointer",
                  }}
                />
                <Chip
                  component={"button"}
                  onClick={() => setFilter("Unread")}
                  size="small"
                  label={"Unread"}
                  className={classes.chip}
                  style={{
                    marginLeft: 5,
                    cursor: "pointer",
                  }}
                />
              </div>
            </div>
          }
          border={false}
          elevation={16}
          content={false}
          boxShadow
          style={{ overflow: "auto", height: "100%" }}
        >
          <List className={classes.list} component="div" disablePadding>
            {notifications.map((notification, index) => (
              <div key={notification?.id} style={{ minWidth: 330 }}>
                <ListItemWrapper
                  style={{ margin: 5 }}
                  onClick={(e) => handleOpenNotification(notification)}
                  components={"button"}
                >
                  <ListItem alignItems="center">
                    <ListItemAvatar>
                      {getIcon(notification?.doctype)}
                    </ListItemAvatar>
                    <ListItemText
                      style={{ maxWidth: "55%" }}
                      primary={
                        <Typography variant="subtitle1">
                          {notification?.read == true ? (
                            notification?.doctype
                          ) : (
                            <strong>{notification?.doctype}</strong>
                          )}
                        </Typography>
                      }
                    />
                    <ListItemSecondaryAction>
                      <Typography variant="caption" display="block">
                        {formatRelativeTime(notification?.created_at)}
                      </Typography>
                    </ListItemSecondaryAction>
                  </ListItem>
                  <div>
                    <Typography variant="subtitle2">
                      {notification?.read == true ? (
                        notification?.text
                      ) : (
                        <strong>{notification?.text}</strong>
                      )}
                      .
                    </Typography>
                  </div>
                </ListItemWrapper>
              </div>
            ))}
          </List>
        </MainCard>
      </Drawer>
    </Box>
  );
};
