import React, { useEffect } from "react";
import {
  InputLabel,
  Button,
  Typography,
  FormControl,
  OutlinedInput,
  Grid,
  Box,
  TextField,
  IconButton,
  Stepper,
  Step,
  StepLabel,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { useStyles } from "../../../style";
import { formatDate } from "../../../../../helpers/utils";
import { v4 as uuid } from "uuid";
import { fr } from "date-fns/esm/locale";
import {
  togglePersonnalMsg,
  SendMultipleEmail,
} from "../../../../../store/client/client.action";
import {
  SetSelectedClient,
  CreatePricingRules,
  CancelPricingRule,
  CreateCouponCode,
} from "../../../../../store/checkout/checkout.action";
import TextFieldsIcon from "@material-ui/icons/TextFields";
import { CustomAlert } from "../../../../../components/alert";
import { useTranslation } from "react-i18next";

export const GiftCardForm = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const globalDefaults = useSelector((state) => state.Login.globalDefaults);
  const selectedData = useSelector (state => state.Login.selectedData);
  const companyacounts = useSelector((state) => state.Configuration.companyacounts);
  const { t } = useTranslation(["common","clients","interfaceCustomization","checkout"]);
  const selectedClient = useSelector((state) => state.Checkout.client);
  const defaultClient = useSelector((state) => state.Checkout.defaultClient);
  const showPersonnalMsg = useSelector(
    (state) => state.Client.showPersonnalMsg
  );
  const selectClientInfo = useSelector(
    (state) => state.Checkout.selectClientInfo
  );
  const pricingRule = useSelector((state) => state.Checkout.pricingRule);
  const couponCodes = useSelector((state) => state.Client.giftCardCouponCode);

  const unique_id = uuid();
  const small_id = unique_id.slice(0, 8);
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const [errorMsg, setErrorMsg] = React.useState("");
  const [errorAmount, setErrorAmount] = React.useState("");

  const [valid_from_CC, setValid_from_CC] = React.useState(
    formatDate(new Date(), "YYYY-MM-DD")
  );
  const [valid_upto, setValid_upto] = React.useState(
    formatDate(new Date(), "YYYY-MM-DD")
  );
  const [state, setState] = React.useState({
    doctype: "Gift Card",
    naming_series: "GC-GCINV-.YYYY.-",
    name1: "",
    card_number: uuid(),
    owner: "",
    amount: "",
    status: "activée",
    personnal_msg: "",
    qty: 1,
  });

  const [error, setError] = React.useState({
    name1: false,
    customer: false,
    amount: false,
  });

  const [statePR, setStatePR] = React.useState({
    doctype: "Pricing Rule",
    naming_series: "PRLE-.####",
    price_or_product_discount: "Price",
    apply_on: "Transaction",
    coupon_code_based: 1,
    company: `${globalDefaults?.default_company}`,
    title: `UNTITLED-GIFT-CARD-${small_id}`,
    rate_or_discount: "Discount Amount",
    discount_amount: "",
    currency: `${companyacounts?.default_currency}`,
    selling: 1,
    buying: 0,
  });

  const [errorPR, setErrorPR] = React.useState({
    title: false,
    discount_amount: false,
  });
  const [stateCouponCode, setStateCouponCode] = React.useState({
    doctype: "Coupon Code",
    valid_from: formatDate(new Date(), "YYYY-MM-DD"),
    valid_upto: formatDate(new Date(), "YYYY-MM-DD"),
    coupon_type: "Gift Card",
    customer: props.isClient ? props?.customer : (props.isCheckout ? (selectedClient
      ? selectedClient?.customer_name
      : defaultClient?.customer_name) : ""),
    pricing_rule: "",
    coupon_name: "",
    description: "",
  });

  const [errorCouponCode, setErrorCouponCode] = React.useState({
    coupon_type: false,
    pricing_rule: false,
    coupon_name: false,
    customer: false,
  });
  useEffect(() => {
    selectClientInfo.length != 0
      ? setState({ ...state, qty: selectClientInfo.length })
      : setState({ ...state });
  }, [selectClientInfo]);

  const handleChangePR = (event) => {
    if (event.target.name == "discount_amount") {
      if (event.target.value <= "1" && event.target.value != "") {
        setErrorAmount("Le montant de carte doit être différent de 0 TND.");
      } else {
        setErrorAmount("");
      }
    }
    setErrorPR({ ...errorPR, [event.target.name]: false });
    setStatePR({ ...statePR, [event.target.name]: event.target.value });
  };

  const handleSubmitPR = (event) => {
    var isValid = true;
    if (statePR.title === "") {
      isValid = false;
      setErrorPR({ ...errorPR, ["title"]: true });
    }
    if (statePR.discount_amount === "" || statePR.discount_amount < "1") {
      isValid = false;
      setErrorPR({ ...errorPR, ["discount_amount"]: true });
      if (statePR.discount_amount != "") {
        setErrorAmount("Le montant de carte doit être différent de 0 TND.");
      }
    }
    if (isValid) {
      const data = {
        doctype: statePR.doctype,
        naming_series: statePR.naming_series,
        price_or_product_discount: statePR.price_or_product_discount,
        apply_on: statePR.apply_on,
        coupon_code_based: statePR.coupon_code_based,
        company: statePR.company,
        title: statePR.title,
        rate_or_discount: statePR.rate_or_discount,
        discount_amount: statePR.discount_amount,
        currency: statePR.currency,
        selling: statePR.selling,
        buying: statePR.buying,
      };
      dispatch(CreatePricingRules(data));
    }
  };

  const handleChange = (event) => {
    setError({ ...error, [event.target.name]: false });
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const toggleMsg = () => {
    dispatch(togglePersonnalMsg(!showPersonnalMsg));
  };

  const close = () => {
    props.isClient && props.handleClose();
    props.isCheckout && props.handleClose();
    dispatch(SetSelectedClient([]));
  };

  const closeModal = () => {
    props?.isClient && props.handleClose();
    props?.isCheckout && props.handleClose();
    if (pricingRule != null) {
      dispatch(CancelPricingRule());
    }
  };

  const handleChangeValidFromCC = (date) => {
    setValid_from_CC(date);
  };
  const handleChangeValidUptoCC = (date) => {
    var d1 = Date.parse(valid_from_CC);
    var d2 = Date.parse(date);
    if (d1 <= d2) {
      setValid_upto(date);
    }
  };

  const handleChangeCouponCode = (event) => {
    if (event.target.name == "coupon_name") {
      var errorDuplicateName =
        Array.isArray(couponCodes) &&
        couponCodes.filter(
          (s) =>
            s.coupon_name?.toUpperCase() === event.target.value?.toUpperCase()
        );
      if (errorDuplicateName.length == 0) {
        setErrorMsg("");
      } else {
        setErrorMsg("existe déjà. Sélectionnez un autre nom.");
      }
    }
    setErrorCouponCode({ ...errorCouponCode, [event.target.name]: false });
    setStateCouponCode({
      ...stateCouponCode,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmitCouponCode = async (event) => {
    var isValid = true;
  
    if (stateCouponCode.coupon_type === "") {
      isValid = false;
      setErrorCouponCode({ ...errorCouponCode, ["coupon_type"]: true });
    }
    if (stateCouponCode.pricing_rule === "") {
      isValid = false;
      setErrorCouponCode({ ...errorCouponCode, ["pricing_rule"]: true });
    }
    if (stateCouponCode.coupon_name === "") {
      isValid = false;
      setErrorCouponCode({ ...errorCouponCode, ["coupon_name"]: true });
    }
    if (stateCouponCode.customer === "") {
      isValid = false;
      setErrorCouponCode({ ...errorCouponCode, ["customer"]: true });
    }
  
    var errorDuplicateName =
      Array.isArray(couponCodes) &&
      couponCodes.filter(
        (s) =>
          s.coupon_name?.toUpperCase() === stateCouponCode.coupon_name?.toUpperCase()
      );
    if (errorDuplicateName.length !== 0) {
      isValid = false;
      setErrorCouponCode({ ...errorCouponCode, ["coupon_name"]: true });
      setErrorMsg("existe déjà. Sélectionnez un autre nom.");
    }
  
    if (isValid) {
      try {
        await dispatch(
          CreateCouponCode({
            doctype: stateCouponCode.doctype,
            coupon_type: stateCouponCode.coupon_type,
            customer: stateCouponCode.customer,
            pricing_rule: stateCouponCode.pricing_rule,
            valid_from: formatDate(valid_from_CC, "YYYY-MM-DD"),
            valid_upto: formatDate(valid_upto, "YYYY-MM-DD"),
            coupon_name: stateCouponCode.coupon_name,
            description: stateCouponCode.description,
          })
        );
        closeModal();
        window.location.reload();
      } catch (error) {
        console.error("Error creating coupon code:", error);
      }
    }
  };

  const handleNext = () => {
    if (pricingRule == null) {
      handleSubmitPR();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  function getSteps() {
    return ["Règle de tarification", "Code Coupon"];
  }

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return (
          <Box>
            <Box className={classes.formBlock}>
              <Grid className={classes.firstCol} item xs={12} md={6}>
                <InputLabel
                  error={errorPR.title}
                  required
                  className={classes.label}
                >
                  {t("interfaceCustomization:titre")}                  
                </InputLabel>
                <FormControl
                  variant="outlined"
                  error={errorPR.title}
                  required
                  className={classes.formControl}
                >
                  <OutlinedInput
                    id="outlined-adornment-weight"
                    value={statePR.title}
                    name="title"
                    onChange={handleChangePR}
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      "aria-label": "weight",
                    }}
                    labelWidth={0}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <InputLabel
                  error={errorPR.discount_amount}
                  required
                  className={classes.label}
                >
                  {t("common:amnt")}
                </InputLabel>
                <FormControl
                  variant="outlined"
                  error={errorPR.discount_amount}
                  required
                  className={classes.formControl}
                >
                  <OutlinedInput
                    id="outlined-adornment-weight"
                    value={statePR.discount_amount}
                    error={errorPR.discount_amount}
                    name="discount_amount"
                    type="number"
                    onChange={handleChangePR}
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      "aria-label": "weight",
                    }}
                    labelWidth={0}
                  />
                  {errorAmount ? (
                    <Box mt={0.5} className={classes.errorLabel}>
                      {errorAmount}
                    </Box>
                  ) : (
                    ""
                  )}
                </FormControl>
              </Grid>
            </Box>
          </Box>
        );
      case 1:
        return (
          <Box>
            <Box className={classes.formBlock}>
              <Grid className={classes.firstCol} item xs={12} md={6}>
                <InputLabel
                  error={errorCouponCode.coupon_name}
                  required
                  className={classes.label}
                >
                   {t("clients:couponName")}
                </InputLabel>
                <FormControl variant="outlined" className={classes.formControl}>
                  <OutlinedInput
                    id="outlined-adornment-weight"
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      "aria-label": "weight",
                    }}
                    labelWidth={0}
                    name="coupon_name"
                    value={stateCouponCode.coupon_name}
                    error={errorCouponCode.coupon_name}
                    onChange={handleChangeCouponCode}
                  />
                </FormControl>
                {errorMsg && (<CustomAlert severity={"error"} message={stateCouponCode.coupon_name + ' ' + errorMsg} />)}
                <FormControl className={classes.formControl} variant="outlined">
                  <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
                    <Typography className={classes.label}>
                      {t("checkout:ValidFrom")}
                    </Typography>
                    <KeyboardDatePicker
                      value={valid_from_CC}
                      name="valid_from_CC"
                      onChange={handleChangeValidFromCC}
                      disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      inputVariant="outlined"
                      id="date-picker-inline"
                      keyboardIcon={<span className={"icon-calendar"} />}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </FormControl>
                <Box justifyContent="flex-start" display="flex">
                  <IconButton aria-label="print">
                    <TextFieldsIcon
                      color="primary"
                      onClick={() => toggleMsg()}
                    />
                  </IconButton>
                </Box>
                {showPersonnalMsg && (
                  <>
                    <InputLabel className={classes.label}>
                     {t("common:Description")}
                    </InputLabel>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <TextField
                        value={stateCouponCode.description}
                        name="description"
                        onChange={handleChangeCouponCode}
                        id="outlined-multiline-static"
                        multiline
                        rows={3}
                        variant="outlined"
                      />
                    </FormControl>
                  </>
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <InputLabel
                  error={errorCouponCode.customer}
                  required
                  className={classes.label}
                >                  
                  {t("common:client")}
                </InputLabel>
                <FormControl
                  error={errorCouponCode.customer}
                  required
                  variant="outlined"
                  className={classes.formControl}
                >
                  <OutlinedInput
                    id="outlined-adornment-weight"
                    error={errorCouponCode.customer}
                    value={stateCouponCode.customer}
                    name="customer"
                    onChange={handleChangeCouponCode}
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      "aria-label": "weight",
                    }}
                    labelWidth={0}
                  />
                </FormControl>
                <FormControl className={classes.formControl} variant="outlined">
                  <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
                    <Typography className={classes.label}>
                      {t("checkout:ValidUntil")}
                    </Typography>
                    <KeyboardDatePicker
                      value={valid_upto}
                      name="valid_upto"
                      onChange={handleChangeValidUptoCC}
                      disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      inputVariant="outlined"
                      id="date-picker-inline"
                      keyboardIcon={<span className={"icon-calendar"} />}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </FormControl>
              </Grid>
            </Box>
          </Box>
        );
      default:
        return null;
    }
  }

  useEffect(() => {

    props.isClient &&
      setStateCouponCode({ ...stateCouponCode, customer: props?.customer });
    props.isCheckout &&
      setStateCouponCode({
        ...stateCouponCode,
        customer: selectedClient
          ? selectedClient?.customer_name
          : defaultClient?.customer_name,
      });
  }, [props, selectedClient]);

  useEffect(() => {
    if (pricingRule) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setStateCouponCode({
        ...stateCouponCode,
        pricing_rule: pricingRule.name,
      });
    }
  }, [pricingRule]);
  return (
    <div>
      <Box>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            return (
              <Step key={label}>
                <StepLabel className={classes.label}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <div>
          {getStepContent(activeStep)}
        </div>
      </Box>
      <Box mt={1} justifyContent="center" display="flex">
        <Button className={classes.modalButton} color="secondary" variant="contained" onClick={closeModal}>
        {t("common:annuler")}
        </Button>
        {activeStep == 0 && (
          <Button className={classes.modalButton} variant="contained" color="primary" onClick={handleNext}>
            {t("clients:next")}
          </Button>
        )}
        {activeStep == 1 && (
          <Button
            className={classes.modalButton}
            variant="contained"
            color="primary"
            onClick={handleSubmitCouponCode}
            disabled={Object.values(error).indexOf(true) != -1}
          >
           {t("common:create")}
          </Button>
        )}
      </Box>
    </div>
  );
};
