import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import { Filter } from "../../../components/filter";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import ClearIcon from "@material-ui/icons/Clear";
import { Typography } from "@material-ui/core";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import { useTableStyles } from "../../../styles/table.style";
import { getStockEntries } from "../../../store/stockEntries/stockEntries.action";
import {
  FilterBy,
  GetFilterItems,
  GlobalfilterData,
} from "../../../helpers/helper";
import Pagination from "@material-ui/lab/Pagination";
import { Loader } from "../../../components/loader";
import { useTranslation } from "react-i18next";
import { SimpleTable } from "../../../components/simpleTable";
import { useHistory } from "react-router";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "0.75rem 0px 0.8125rem",
    flexWrap: "wrap",
    "& .MuiInputBase-root": {
      background: theme.palette.white.main,
    },
    "& .MuiOutlinedInput-input": {
      padding: "8.9px 14px",
      fontSize: 12,
    },
  },
  formControl: {
    maxWidth: 300,
    [theme.breakpoints.down("xs")]: {
      marginTop: 10,
    },
  },
  button: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.blue.main,
    border: "1px solid",
    borderColor: theme.palette.blue.main,
    fontSize: "0.875rem",
    borderRadius: 10,
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
      borderColor: theme.palette.blue.main,
    },
  },
  icon: {
    marginRight: "0.375rem",
  },
}));
export const ListStockEntry = () => {
  const classes = useStyles();
  const tableclasses = useTableStyles();
  const { t } = useTranslation(["common"]);
  const history = useHistory();

  const [rows, setRows] = useState([]);
  const [filtredEntryStock, setFiltredEntryStock] = useState([]);
  const [entryStock, setEntryStock] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    t_warehouse: [],
    s_warehouse: [],
    creation: [],
  });

  const [selectedDate, setSelectedDate] = useState(
    moment().format("YYYY-MM-DD")
  );

  const mouvementsColumns = [
    { key: "creation", title: "common:dateCreation" },
    { key: "name", title: "common:ref" },
    { key: "purpose", title: "common:purpose" },
    { key: "t_warehouse", title: "common:mgsCible" },
    { key: "s_warehouse", title: "common:mgsSource" },
  ];

  const handleRedirection = (name) => {
    return history.push(`/stock-entry/${name}`);
  };

  const handleSearchEntryStock = (event) => {
    setEntryStock(event.target.value);
    setFiltredEntryStock(
      GlobalfilterData(rows, event.target.value, [
        "name",
        "t_warehouse",
        "s_warehouse",
        "purpose",
        "stock_entry_type",
      ])
    );
  };

  const HandleFilter = (key, item) => {
    const FilterDataBy = { ...filters, [key]: item };
    setFilters(FilterDataBy);
    setFiltredEntryStock(rows.filter(FilterBy(FilterDataBy)));
  };
  const HandleDeleteAll = (field) => {
    const data = {
      t_warehouse: [],
      s_warehouse: [],
      creation: [],
    };
    setSelectedDate(moment().format("YYYY-MM-DD"));
    setFilters(data);
    setFiltredEntryStock(rows.filter(FilterBy(data)));
  };

  const HandleDeleteAllSwarehouse = (field) => {
    const data = {
      t_warehouse: filters?.t_warehouse,
      s_warehouse: [],
      creation: filters?.creation,
    };
    setFilters(data);
    setFiltredEntryStock(rows.filter(FilterBy(data)));
  };
  const HandleDeleteTwarehouse = (field) => {
    const data = {
      t_warehouse: [],
      s_warehouse: filters?.s_warehouse,
      creation: filters?.creation,
    };
    setFilters(data);
    setFiltredEntryStock(rows.filter(FilterBy(data)));
  };
  const HandleDeleteCreation = () => {
    const data = {
      t_warehouse: filters?.t_warehouse,
      s_warehouse: filters?.s_warehouse,
      creation: [],
    };
    setSelectedDate(moment().format("YYYY-MM-DD"));
    setFilters(data);
    setFiltredEntryStock(rows.filter(FilterBy(data)));
  };

  const HandleCloseChip = (item, key) => {
    const FilterDataBy = {
      ...filters,
      [key]: filters[key].filter((f) => f !== item),
    };
    setFilters(FilterDataBy);
    setFiltredEntryStock(rows.filter(FilterBy(FilterDataBy)));
  };

  const setStockEntries = async () => {
    await getStockEntries().then((response) => {
      response.data?.length > 0 && setRows(response.data);
    });
  };
  const handleFilterByDate = (date) => {
    const data = rows.filter((item) => {
      let CreationDate = moment(item.creation).format("YYYY-MM-DD");
      return CreationDate == date;
    });
    setFiltredEntryStock(data);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    if (date && date != "Invalid Date") {
      handleFilterByDate(moment(date).format("YYYY-MM-DD"));
    }
  };
  const init = async () => {
    await setStockEntries();
    setLoading(false);
  };
  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    setFiltredEntryStock(rows);
  }, [rows]);
  return (
    <Box>
      <Box className={classes.header}>
        <FormControl className={classes.formControl} variant="outlined">
          <OutlinedInput
            id="outlined-adornment-weight"
            value={entryStock}
            placeholder={t("common:rechercher")}
            onChange={handleSearchEntryStock}
            endAdornment={
              <InputAdornment position="end">
                <span className="icon-search" />
              </InputAdornment>
            }
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              "aria-label": "weight",
            }}
            labelWidth={0}
          />
        </FormControl>
      </Box>
      <Box className={tableclasses.filterBlock}>
        <Box className={tableclasses.filter}>
          <Filter
            data={GetFilterItems([
              ...new Set(
                rows.map((item) => item.t_warehouse && item.t_warehouse)
              ),
            ])}
            title={t("common:mgsCible")}
            id={"t_warehouse"}
            HandleFilter={HandleFilter}
            value={filters.t_warehouse}
            number={filters.t_warehouse?.length}
          />
          <Filter
            data={GetFilterItems([
              ...new Set(
                rows.map((item) => item.s_warehouse && item.s_warehouse)
              ),
            ])}
            title={t("common:mgsSource")}
            id={"s_warehouse"}
            HandleFilter={HandleFilter}
            value={filters.s_warehouse}
            number={filters.s_warehouse?.length}
          />
          <Filter
            title={t("common:createDate")}
            id={"creation"}
            HandleFilter={HandleFilter}
            selectedDate={selectedDate}
            setRows={setRows}
            date={true}
            handleDateChange={handleDateChange}
            setSelectedDate={setSelectedDate}
            HandleDeleteAll={HandleDeleteCreation}
          />
        </Box>
      </Box>
      <Box display="flex" flexWrap="wrap" alignItems="center">
        {filters?.t_warehouse?.length > 0 && (
          <Typography variant="overline" color="textSecondary">
            {t("common:mgsCible") + ":  "}
          </Typography>
        )}
        {filters?.t_warehouse?.map((filter) => (
          <Chip
            className={tableclasses.chip}
            label={filter}
            onDelete={() => HandleCloseChip(filter, "t_warehouse")}
            color="secondary"
            size={"small"}
            deleteIcon={<ClearIcon />}
          />
        ))}
        {filters.t_warehouse?.length > 0 && (
          <Typography
            onClick={HandleDeleteTwarehouse}
            className={tableclasses.remove}
          >
            {t("common:delete")}
          </Typography>
        )}
      </Box>
      <Box display="flex" flexWrap="wrap" alignItems="center">
        {filters?.s_warehouse?.length > 0 && (
          <Typography variant="overline" color="textSecondary">
            {t("common:mgsSource")}:{"   "}
          </Typography>
        )}
        {filters?.s_warehouse?.map((filter) => (
          <Chip
            className={tableclasses.chip}
            label={filter}
            onDelete={() => HandleCloseChip(filter, "s_warehouse")}
            color="secondary"
            size={"small"}
            deleteIcon={<ClearIcon />}
          />
        ))}
        {filters?.s_warehouse?.length > 0 && (
          <Typography
            onClick={HandleDeleteAllSwarehouse}
            className={tableclasses.remove}
          >
            {t("common:delete")}
          </Typography>
        )}
      </Box>
      <Box>
        {loading ? (
          <Loader />
        ) : (
          <>
            <SimpleTable
              title={"mouvements"}
              data={filtredEntryStock.slice((page - 1) * 20, page * 20)}
              columns={mouvementsColumns}
              hasPaper
              isRowClickable={true}
              handleRowClick={handleRedirection}
              rowPrimaryKey={"name"}
            />
            {Math.ceil(filtredEntryStock?.length / 20) > 0 && (
              <Box display="flex" justifyContent="center" m={4}>
                <Pagination
                  onChange={(event, newPage) => setPage(newPage)}
                  page={page}
                  count={Math.ceil(filtredEntryStock?.length / 20)}
                  color="primary"
                />
              </Box>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};
