import React from "react";
import ReactDOM from "react-dom";
import {Provider} from "react-redux";
import {Router} from "react-router-dom";
import "./styles/index.scss";
import App from "./App";
import {store} from "./store/configureStore";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import "./i18n";
import history from "./history/history";

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <Router history={history}>
                <App/>
            </Router>
        </Provider>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
    onUpdate: (registration) => {
        const waitingServiceWorker = registration.waiting;
        console.log(waitingServiceWorker);
        if (waitingServiceWorker) {
            waitingServiceWorker.addEventListener("statechange", (event) => {
                if (event.target.state === "activated") {
                    if (
                        window.confirm(
                            "there is a new version of the app ready, Please reload to update."
                        )
                    ) {
                        window.location.reload();
                    }
                }
            });
            waitingServiceWorker.postMessage({type: "SKIP_WAITING"});
        }
    },
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
