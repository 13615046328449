import React from "react";
import { useTranslation } from "react-i18next";
import { Typography, Box, Modal, Grid, Tooltip, IconButton } from "@material-ui/core";
import { useStyles } from "./style";
import { packGrand, packMoyenne, packPetit } from "../../../helpers/utils";
import CardComponent from "../card";
import FormInput from "../formInput";
import ConfirmButton from "../confirmButton";
import AddIcon from "@material-ui/icons/Add";

const FormModal = (props) => {
  const {
    open,
    title,
    setOpen,
    value,
    handleChange,
    orderdProducts,
    setDetails,
    handleSubmit,
  } = props;
  const { t } = useTranslation(["fulfillment"]);
  const classes = useStyles();
  const toggleMsg = () => {
    setShowMessage(!showMessage);
  };
  const [showMessage, setShowMessage] = React.useState(false);
  const [isActive, setIsActive] = React.useState(null);
  const handleClose = () => {
    setOpen(false);
    setShowMessage(false);
    setDetails({
      length: 0,
      height: 0,
      width: 0,
      weight: 0,
    });
    setIsActive(null);
  };
  const packs = [
    { title: "Grand Pack", dimensions: packGrand, id:1 },
    { title: "Pack Moyenne", dimensions: packMoyenne , id:2},
    { title: "Petit Pack", dimensions: packPetit, id:3 },
  ];
  return (
    <Modal open={open}>
      <div className={classes.paper}>
        <Typography
          color={"primary"}
          className={classes.bodyTitle}
          align="center"
        >
          {title}
        </Typography>
        <Box align={"center"} display={"flex"} flexDirection={"column"} mb={4}>
          {orderdProducts &&
            orderdProducts.map((el, i) => (
              <Typography align="center" key={i}>
                {el.quantityToPack && el.quantityToPack + " X " + el.item_name}
              </Typography>
            ))}
        </Box>
        <Box>
          <Grid container spacing={2} justifyContent="center" display="flex">
            {packs.map((pack, i) => (
              <CardComponent
                key={i}
                id={pack.id}
                setDetails={setDetails}
                type={pack.title}
                pack={pack.dimensions}
                isActive={isActive}
                setIsActive={setIsActive}
              />
            ))}
            <Grid item style={{display: "flex", justifyContent: "center"}}>
              <Tooltip title="Ajouter un pack">
                <IconButton className={classes.icon}>
                  <AddIcon
                    color="primary"
                    onClick={() => toggleMsg()}
                  />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Box>
        {showMessage && (
          <Box mt={3} align={"left"} className={classes.formBlock}>
            <Grid className={classes.firstCol} item xs={12} md={6}>
              <FormInput
                label={t("fulfillment:length") + " " + "(cm)"}
                value={value.length}
                name="length"
                handleChange={handleChange}
                type="number"
              />
              <FormInput
                label={t("fulfillment:height") + " " + "(cm)"}
                value={value.height}
                name="height"
                handleChange={handleChange}
                type="number"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormInput
                label={t("fulfillment:width") + " " + "(cm)"}
                value={value.width}
                name="width"
                handleChange={handleChange}
                type="number"
              />
              <FormInput
                label={t("fulfillment:weight") + " " + "(kg)"}
                value={value.weight}
                name="weight"
                handleChange={handleChange}
                type="number"
              />
            </Grid>
          </Box>
        )}
        <Box mt={3} justifyContent="center" display="flex">
          <ConfirmButton handleSubmit={handleSubmit} handleClose={handleClose}/>
        </Box>
      </div>
    </Modal>
  );
};

export default FormModal;
