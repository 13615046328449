import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import {
  Modal,
  Typography,
  Tabs,
  Tab,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { useSelector, useDispatch } from "react-redux";
import {
  loadCoffreOperation,
  LoadPOSInvoices,
} from "../../store/pos/pos.action";
import { CashTab } from "./tabs/cashTab";
import * as localforage from "localforage";
import SafeIcon from "./components/SafeIcon";
import { loadHolds } from "../../store/hold/hold.action";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 700,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 10,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  },
  icon: {
    color: theme.palette.primary.main,
    opacity: 1,
    cursor: "pointer",
    height: 30,
    width: 30,
    transition: "transform 0.2s ease-in-out",
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
  badge: {
    color: theme.palette.primary.main,
  },
  button: {
    marginRight: "10px",
    marginLeft: "10px",
  },
  input: {
    marginTop: "20px",
  },
  table: {
    background: theme.palette.productsTable.background,
    borderSpacing: "3 0px",
    borderCollapse: "separate",
    "& .MuiTableRow-root": {
      display: "table",
      width: "100%",
      tableLayout: "fixed",
    },
    marginBottom: "15px",
  },
  tableRow: {},
  tableCell: {
    background: theme.palette.productsTable.tableCell,
    padding: "7px 16px",
    color: theme.palette.primary.main,
  },
  tablecellHeader: {
    paddingBottom: 0,
    color: theme.palette.productsTable.tablecellHeader,
    fontSize: 12,
    borderBottom: "none",
  },
  tableBody: {
    display: "block",
    height: 100,
    overflow: "auto",
  },
  formControl: {
    marginBottom: 15,
    marginRight: "10px",
    "& .MuiInputBase-root": {
      background: theme.palette.white.main,
    },
    "& .MuiOutlinedInput-input": {
      padding: "8.9px 14px",
      fontSize: 12,
    },
  },
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  iconBtn: {},
}));

const SafeBox = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = React.useState(false);
  const [tabIndex, setTabIndex] = React.useState(0);
  const payementsMethods = useSelector(
    (state) => state.Checkout.payementsMethods
  );
  const posOpeningEntry = useSelector((state) => state.Pos.open);
  const user = useSelector((state) => state.Login.user);
  const selectedData = useSelector((state) => state.Login.selectedData);
  const globalDefaults = useSelector((state) => state.Login.globalDefaults);
  const { t } = useTranslation(["common", "session"]);

  const handleClickAway = () => {
    setOpenModal(false);
  };

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleOpen = () => {
    setOpenModal(true);
    posOpeningEntry &&
      dispatch(loadCoffreOperation({ opening_entry: posOpeningEntry.name }));
    Promise.all([localforage.getItem("reduxPersist:Pos")]).then(function (
      results
    ) {
      user &&
        dispatch(LoadPOSInvoices(JSON.parse(results[0]).open, user.email));
    });
    globalDefaults &&
      selectedData &&
      dispatch(loadHolds(globalDefaults?.default_company, selectedData?.store));
  };

  const body = (
    <div className={classes.paper}>
      <Typography
        align={"center"}
        color={"primary"}
        style={{ fontWeight: "bold", fontSize: 20, marginBottom: 20 }}
      >
        Coffre de Caisse
      </Typography>
      <Box className={classes.root}>
        <Box position="relative" color="default">
          <Tabs
            centered
            value={tabIndex}
            onChange={handleChange}
            textColor="primary"
            indicatorColor="primary"
            aria-label="tabs"
          >
            {payementsMethods.length != 0 &&
              [{ name: t('session:cash') }, { name: t('session:cheque') }].map((p, i) => (
                <Tab
                  key={i}
                  className={classes.tab}
                  label={p.name}
                  {...a11yProps(i)}
                />
              ))}
          </Tabs>
        </Box>
        {payementsMethods.length != 0 &&
          [{ name: t('session:cash') }, { name: t('session:cheque') }].map((p, i) => (
            <TabPanel key={i} value={tabIndex} index={i}>
              <CashTab pm={p.name} setOpenModal={setOpenModal} />
            </TabPanel>
          ))}
      </Box>
    </div>
  );
 
  return (
    <div>
      <Tooltip title={t('session:safebox')} placement="top-start">
        <IconButton onClick={handleOpen} className={classes.iconBtn}>
          <SafeIcon className={classes.icon} />
        </IconButton>
      </Tooltip>
      <Modal
        open={openModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
};

export default SafeBox;
