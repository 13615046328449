import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, ClickAwayListener, Typography, FormControl, InputLabel, OutlinedInput } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Select, MenuItem } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { createStckEntry } from "../../../../store/inventory/inventory.action";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { useHistory } from 'react-router-dom';
import {useTranslation} from "react-i18next";
import { SimpleTable } from "../../../../components/simpleTable";

const useStyles = makeStyles((theme) => ({
  table: {
    borderCollapse: 'separate',
    borderSpacing: "0 10px",
  },
  tableRow: {
    boxShadow: "0px 2px 8px #091F461D",
    minHeight : 45,
    borderRadius: 6,
    "& .MuiTableCell-root:first-child": {
      borderTopLeftRadius: 6,
      borderBottomLeftRadius: 6
    },
    "& .MuiTableCell-root:last-child": {
      borderTopRightRadius: 6,
      borderBottomRightRadius: 6
    },
    cursor: "pointer",
  },
  avatar : {
    width: 24,
    height: 24
  },
  avatarCell : {
    width :24
  },
  chartCell : {
    background : "#fff",
    fontSize : "0.875rem",
    padding : "8px 12px",
    color : theme.palette.primary.main,
    width: "500px",
    height: "80px"
  },
  myContainer: {
    marginTop : 20,
    width: "80%",
    height: "80%",
    paddingBottom : 0,
    color : "#657288",
    fontSize : 12,
    borderBottom : 'none',
    paddingLeft : 13
  },
  color: {
    fill : theme.palette.primary.main,
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 10,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  formControl: {
    marginBottom: 15,
    "& .MuiInputBase-root": {
      background: theme.palette.white.main,
    },
    "& .MuiOutlinedInput-input": {
      padding: "8.9px 14px",
      fontSize: 12,
    },
  },
  label: {
    fontSize: 12,
    marginBottom: 6,
    color: theme.palette.gray.main,
    paddingLeft: 9,
  },
  title : {
    marginBottom: 30,
    fontSize: 20
  },
  noWrap: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    '&:hover': {
      overflow: "visible",
    },
  }
}));

export const  SummaryTable = ({stockQtyReport, selectedFilters, selectedCashier, isFiltered, listType}) => {
  const classes = useStyles();
  const history = useHistory();
  const {t} = useTranslation(["inventory","common"]);
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = React.useState(false);
  const [openMoveModal, setOpenMoveModal] = React.useState(false);
  const [addState, setAddState] = React.useState({
    qty: 0,
    rate:0,
  });
  const [moveState, setMoveState] = React.useState({
    target: "",
    qty:0,
  });
  const [selectedRow, setSelectedRow] = React.useState({});
  const stores = useSelector(state => state.Login.stores);
  const selectedWarehouse = useSelector(state => state.Login.selectedData);

  const restOfStores = Array.isArray(stores) && selectedWarehouse && stores.filter(s => s.name != selectedWarehouse.store)

  

  const handleMoveChange = (event) => {
    if (event.target.name == "target") {
      setMoveState({ ...moveState, [event.target.name]: event.target.value })
    } else if (event.target.name == "qty") {
      setMoveState({ ...moveState, [event.target.name]: parseInt(event.target.value) })
    }
  };

  const handleChange = (event) => {

    if(!isNaN(event.target.value)) {
      setAddState({ ...addState, [event.target.name]: parseInt(event.target.value) });
    } 
  };

  const handleSubmit = () => {
    const data = {
      item_code: selectedRow.itemCode,
      target: selectedRow.target,
      qty: addState.qty,
      rate: addState.rate
    };

    dispatch(createStckEntry(data));
    setOpenModal(false);
    setAddState({qty: 1, rate:0,});
  }

  const handleSubmitMove = () => {
    const data = {
      item_code: selectedRow.itemCode,
      source: selectedRow.warehouse,
      target: moveState.target,
      qty: moveState.qty
    };
    
    dispatch(createStckEntry(data));
    setOpenMoveModal(false);
    setMoveState({qty: 1, target: ""});
  }

  const handleCancel = () => {
    setOpenModal(false);
    setOpenMoveModal(false);
    setAddState({qty: 0, rate:0,})
    setMoveState({target:"", qty:0})
  }

  const chartData = (row) => {
    return (
      <div className={classes.myContainer}>
        <ResponsiveContainer  width="100%" height="100%" >
          <BarChart
            data={[{name:"", actual_qty:row.actual_qty, projected_qty: row.projected_qty, amt: row.actual_qty > row.projected_qty ? row.actual_qty : row.projected_qty , reserved_qty:row.reserved_qty}]}
            layout="vertical"
            margin={{top: 5, right: 0, left: 12, bottom: 5}}
          >
            <Tooltip />
            <XAxis type="number"/>
            <YAxis type="category" dataKey="name" />
            <Bar dataKey="projected_qty" name="Quantité Projetée"  fill="#657288" barSize={10}/>
            <Bar dataKey="actual_qty" name="Quantité Réelle" className={classes.color} barSize={10}/>
            <Bar dataKey="reserved_qty" name="Quantité Réservée" fill="#54A9C5" barSize={0}/>
          </BarChart>
        </ResponsiveContainer> 
      </div>
    )
  }

  const handleRedirection = (item_code) => {
    return history.push(`/stock/${item_code}`);
  }

  const body = (
    <ClickAwayListener onClickAway={() => setOpenModal(false)}>
      <div className={classes.paper}>
        <Typography align={"center"} color={"primary"} className={classes.title}>
          {t("common:AjoutProd")}
        </Typography>
        <Box>
          <InputLabel required className={classes.label}>
          {t("common:produit")}
          </InputLabel>
          <FormControl variant="outlined" className={classes.formControl}>
            <OutlinedInput
              readOnly
              disabled
              id="outlined-adornment-weight"
              value={selectedRow.itemCode} 
              name="item_code"
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                "aria-label": "weight",
              }}
              labelWidth={0}
            />
          </FormControl>
          <InputLabel required className={classes.label}>
            {t("common:mgsCible")}
          </InputLabel>
          <FormControl variant="outlined" className={classes.formControl}>
            <OutlinedInput
              readOnly
              disabled
              id="outlined-adornment-weight"
              value={selectedRow.target}
              name="target"
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                "aria-label": "weight",
              }}
              labelWidth={0}
            />
          </FormControl>
          <InputLabel required className={classes.label}>
            {t("common:qty")}
          </InputLabel>
          <FormControl variant="outlined" className={classes.formControl}>
            <OutlinedInput
              id="outlined-adornment-weight"
              value={addState.qty}
              name="qty"
              onChange={handleChange}
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                "aria-label": "weight",
              }}
              labelWidth={0}
            />
          </FormControl>
          <InputLabel required className={classes.label}>
          {t("common:taux")}
          </InputLabel>
          <FormControl variant="outlined" className={classes.formControl}>
            <OutlinedInput
              id="outlined-adornment-weight"
              value={addState.rate}
              name="rate"
              onChange={handleChange}
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                "aria-label": "weight",
              }}
              labelWidth={0}
            />
          </FormControl>
        </Box>
        <Box mt={3} justifyContent="center" display="flex">
          <Button variant="contained" color="primary" onClick={() => handleSubmit()}>
          {t("common:continuer")}
          </Button>
          <Button color="primary" onClick={() => handleCancel()}>
          {t("common:annuler")}
          </Button>
        </Box>
      </div>
    </ClickAwayListener>
  );

  const bodyMove = (
    
      <div className={classes.paper}>
        <Typography align={"center"} color={"primary"} className={classes.title}>
          {t("common:transfertProd")}
        </Typography>
        <Box>
          <InputLabel required className={classes.label}>
             {t("common:produit")}
          </InputLabel>
          <FormControl variant="outlined" className={classes.formControl}>
            <OutlinedInput
              readOnly
              disabled
              id="outlined-adornment-weight"
              value={selectedRow.itemCode} 
              name="item_code"
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                "aria-label": "weight",
              }}
              labelWidth={0}
            />
          </FormControl>
          <InputLabel required className={classes.label}>
          {t("common:mgsSource")}
          </InputLabel>
          <FormControl variant="outlined" className={classes.formControl}>
            <OutlinedInput
              readOnly
              disabled
              id="outlined-adornment-weight"
              value={selectedRow.warehouse}
              name="target"
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                "aria-label": "weight",
              }}
              labelWidth={0}
            />
          </FormControl>
          <InputLabel required className={classes.label}>
          {t("common:mgsCible")}
          </InputLabel>
          <FormControl variant="outlined" className={classes.formControl}>
          <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              className={classes.select}
              value={moveState.target}
              name="target"
              IconComponent ={ExpandMoreIcon}
              onChange={handleMoveChange}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
              input={
                
                <OutlinedInput
                    name="stores"
                    id="demo-simple-select-outlined"
                    placeholder="Selectionner Magasin Cible"
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      "aria-label": "weight",
                    }}
                    labelWidth={0}
                />}
            >
              {restOfStores && restOfStores.map((store, index) => (
                <MenuItem key = {index} value={store.name}>
                  {store.name}
                </MenuItem>   
              ))}
          </Select>
          </FormControl>
          <InputLabel required className={classes.label}>
          {t("common:qty")}
          </InputLabel>
          <FormControl variant="outlined" className={classes.formControl}>
            <OutlinedInput
              id="outlined-adornment-weight"
              value={moveState.qty}
              name="qty"
              onChange={handleMoveChange}
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                "aria-label": "weight",
              }}
              labelWidth={0}
            />
          </FormControl>
        </Box>
        <Box mt={3} justifyContent="center" display="flex">
          <Button variant="contained" color="primary" onClick={() => handleSubmitMove()}>
          {t("common:continuer")}
          </Button>
          <Button color="primary" onClick={() => handleCancel()}>
          {t("common:annuler")}
          </Button>
        </Box>
      </div>
    
  );

  const handleOpenModal = (itemCode, target) => {
    setSelectedRow({itemCode: itemCode, target: target})
    setOpenModal(true)
  }

  const handleOpenMoveModal = (itemCode, target) => {
    setSelectedRow({itemCode: itemCode, warehouse: target})
    setOpenMoveModal(true)
  }

  const inventoryColumns = [
    {key: "item_code", title: "common:ref" },
    {key: "item_name", title: "common:produit" },
    {key: "actual_qty", title: "common:qteReel" },
    {key: "projected_qty", title: "common:qteProjetee" },
    {key: "reserved_qty", title: "common:qteReservee" },
  ]

  return (
      <SimpleTable title={"inventoryItems"} listType={listType} data={stockQtyReport} columns={inventoryColumns} hasPaper={true} />
  );
}