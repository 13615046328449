import React, {useEffect} from 'react';
import {useStyles} from "./style";
import Box from '@material-ui/core/Box';
import { Grid } from '@material-ui/core';
import Barcode from "react-barcode";
import { useSelector } from "react-redux";

export const LoyaltyPointComponentToPrint = React.forwardRef((props, ref) => {
    const classes = useStyles();
    const activeImageTheme = useSelector(state => state.Theme.image);
    const globalDefaults = useSelector(state => state.Login.globalDefaults);
    return (

      <Box className={classes.ticket} ref={ref}>
        <Box className={classes.ticketBlock}>
          <Box className={classes.ticketHeader}>
              <div className={classes.logo}>
              <img
              alt="" src={activeImageTheme}
              style={{
                display: 'block',
                marginLeft: '40%',
                marginBottom: '30px',
                width: '15%'
            }}
              
              />
              </div>
              <Box className={classes.title} ><strong>{props.order && props.title + ' ' +  props.order.name}</strong></Box>
              <Barcode width={1} height={20} value={props.order.name} />  
          </Box>
         
        
              <Grid className={classes.productsborder} item md={12}>
                       
              <span align={"center"} fontWeight={"bold"} fontSize={"15px"} fontFamily={"Courier Prime"} color={"#707070"}> <strong>Client: </strong> </span>  
              <span align={"center"} fontWeight={"bold"} fontSize={"15px"} fontFamily={"Courier Prime"} color={"#707070"}><strong> {props.document && props.document.customer}</strong></span> 
              
               <div ><span align={"center"} fontWeight={"bold"} fontSize={"15px"} fontFamily={"Courier Prime"} color={"#707070"} >Points de fidélité:</span> {props.document && props.document.loyalty_points}</div>
               </Grid>
                   
               <Grid className={classes.productsborder} item md={12}>
          <Box  align={"center"} fontWeight={"bold"} fontSize={"25px"} fontFamily={"Courier Prime"} color={"#707070"}>Montant : {props.document && props.document.purchase_amount} {globalDefaults?.default_currency}</Box>
          </Grid>
          <Box marginTop={1} align={"center"} fontWeight={"bold"} fontSize={"15px"} fontFamily={"Courier Prime"} color={"#707070"}>Cachet et Signature</Box>
          
          <Box className={classes.ticketFooter} textAlign= "center">Merci de votre visite</Box>
         
        </Box>
      </Box>
    );
  });
