import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  titleblock: {
    position: "relative",
    borderBottom: "0.0625rem solid #f3f4f6",
    padding: "0.8125rem 0rem 1.75rem 0rem",
  },
  closeicon: {
    position: "absolute",
    top: 18,
    color: theme.palette.primary.main,
    width: 23,
    height: 23,
    cursor: "pointer",
  },
  block: {
    padding: "0rem 1.75rem",
    marginBottom: "6.9375rem",
  },
  title: {
    fontWeight: 600,
  },
  name: {
    fontWeight: 600,
    marginTop: 20,
  },
  paper: {
    overflow: "auto",
    width: "100%",
  },
  roott: {
    width: "100%",
    height: 545,
  },
  firstCol: {
    [theme.breakpoints.up("md")]: {
      marginRight: 30,
    },
  },
}));
