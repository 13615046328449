import { statusFormat } from "./utils";

export const GlobalfilterData = (array, keyword, keys) => {
  const lowercasedValue = keyword.toLowerCase().trim();
  if (lowercasedValue === "") return array;
  else {
    console.log("array", array);
    console.log("keyword", keyword);
    console.log("keys", keys);
    const filteredData = array.filter((item) => {
      return Object.keys(item).some((key) => {
        if (item[key] !== null) {
          return keys.includes(key)
            ? item[key]
                ?.toString()
                ?.toLowerCase()
                ?.match(new RegExp(lowercasedValue, "g"))
            : false;
        } else {
          return false;
        }
      });
    });
    return filteredData;
  }
};
export const FilterBy = (obj) => {
  const keys = Object.keys(obj);

  const allParameters = (item) => {
    let shouldReturn = true;
    for (let i = 0; i < keys.length; i++) {
      let notEqualToSearch = false;
      if (obj[keys[i]].length > 0) {
        notEqualToSearch = !obj[keys[i]].includes(item[keys[i]]);
      }

      if (notEqualToSearch) {
        shouldReturn = false;
      }
    }

    return shouldReturn;
  };

  return allParameters;
};

export const GetFilterItems = (arr, condition) => {
  if (!Array.isArray(arr)) return [];
  const data = [];

  for (const elem of arr) {
    let x = {
      label: condition
        ? statusFormat(condition[elem]).value
        : statusFormat(elem).value,
      value: elem,
    };
    elem && data.push(x);
  }
  return data;
};
export const GetOrderdProducts = (products, summaryProducts) => {
  const parsedData = JSON.parse(summaryProducts);
  var OrderedProducts = [];
  parsedData.forEach((element) => {
    var data = products.find((p) => p.item_code == element.item_code);
    data = {
      ...data,
      item_code: element.item_code,
      quantity: element.quantity,
      quantityPacked: element.quantityPacked,
    };
    OrderedProducts.push(data);
  });
  return OrderedProducts;
};

export const ProductsAreEquals = (ordersProducts, productsSelected) => {
  const parsedData = ordersProducts ? ordersProducts : [];

  var equals = parsedData.length == productsSelected.length;
  if (!equals) {
    return false;
  }
  parsedData.forEach((element) => {
    var data = productsSelected.findIndex(
      (p) => p.item_code == element.item_code && p.quantity == element.quantity
    );
    if (data == -1) {
      equals = false;
      return equals;
    }
  });
  return equals;
};

export const GetActivePage = (url) => {
  const items = url.split("/");
  return items.length > 0 ? `/${items[1]}` : "/";
};

export const GetTotalAmount = (products, tax = 0) => {
  var total = 0;
  products.map((p) => {
    total += (p.price_list_rate ?? 0) * p.quantity;
  });

  if (tax) {
    total += (total * tax) / 100;
  }

  return total;
};

export function CurrencyFormatter(props) {
  const formatter = new Intl.NumberFormat(props.locale, {
    style: "currency",
    currency: props?.currency,
    minimumFractionDigits:
      props?.currency === "AED" ? 2 : props?.minimumFractionDigits,
    maximumFractionDigits:
      props?.currency === "AED" ? 2 : props?.minimumFractionDigits,
  });

  const formattedValue = formatter.format(props?.value);

  return <span>{formattedValue}</span>;
}

export function formatNumber(value, decimals = 3) {
  if (typeof value !== "number") {
    return "Valeur invalide";
  }

  return value.toLocaleString(undefined, {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  });
}
