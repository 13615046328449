import { CLEAR_STATE } from "../login/login.type";
import {
  SET_SELECTED_STOCK_ENTRY,
  STOCK_BALANCE_LOADED,
} from "./stockEntries.type";

const initialState = {
  selectedStockEntry: null,
  stockBalance: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_STOCK_ENTRY:
      return {
        ...state,
        selectedStockEntry: action.payload,
      };
    case STOCK_BALANCE_LOADED:
      return {
        ...state,
        stockBalance: action.stockBalance.message,
      };
    case CLEAR_STATE:
      return (state = initialState);

    default:
      return state;
  }
};

export default reducer;
