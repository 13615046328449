import React, { useEffect } from "react";
import { useStyles } from "./style";
import {
  Grid,
  Box,
  Card,
  CardContent,
  Typography,
  TableCell,
  TableRow,
} from "@material-ui/core";
import { useTableStyles } from "../../../styles/table.style";
import { Products } from "../../../pages/transfertRequest/components/products";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { formatDate } from "../../../helpers/utils";
import { SimpleTable } from "../../../components/simpleTable";

export const StockMvtComponentToPrint = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const tableClasses = useTableStyles();
  const activeImageTheme = useSelector((state) => state.Theme.image);
  const { t } = useTranslation(["common"]);

  return (
    <div className={classes.main} ref={ref}>
      <header className={classes.header}>
        <div className={classes.logo}>
          <img src={activeImageTheme} />
        </div>
        <h1 className={classes.title}>
          {props?.data && props.title + " - " + props?.data?.name}{" "}
        </h1>
      </header>

      <Box className={classes.root} style={{ marginTop: 20 }} display="flex">
        <Grid item xs={12} md={12}>
          <Card className={classes.card}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Typography color={"primary"} className={classes.textLight}>
                    {t("common:movementtype")}
                  </Typography>
                  <Typography color={"primary"} className={classes.textLight}>
                    {t("common:ref")}
                  </Typography>
                  <Typography
                    color={"primary"}
                    className={classes.textLight}
                    style={{}}
                  >
                    {t("common:user")}
                  </Typography>
                  {props?.data?.items[0].t_warehouse && (
                    <Typography color={"primary"} className={classes.textLight}>
                      {t("common:mgsCible")}
                    </Typography>
                  )}
                  {props?.data?.items[0].s_warehouse && (
                    <Typography color={"primary"} className={classes.textLight}>
                      {t("common:mgsSource")}
                    </Typography>
                  )}
                  <Typography color={"primary"} className={classes.textLight}>
                    {t("common:date")}
                  </Typography>
                  <Typography color={"primary"} className={classes.textLight}>
                    {t("common:company")}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  {/* Contenu de la deuxième colonne */}
                  <Typography className={classes.text}>
                    {props?.data && props?.data?.stock_entry_type}
                  </Typography>
                  <Typography className={classes.text}>
                    {props?.data?.name}
                  </Typography>
                  <Typography className={classes.text}>
                    {props?.data?.owner ? props?.data?.owner : "_"}
                  </Typography>
                  {props?.data?.items[0].t_warehouse && (
                    <Typography className={classes.text}>
                      {props?.data?.items[0].t_warehouse}
                    </Typography>
                  )}
                  {props?.data?.items[0].s_warehouse && (
                    <Typography className={classes.text}>
                      {props?.data?.items[0].s_warehouse}
                    </Typography>
                  )}
                  <Typography className={classes.text}>
                    {formatDate(props?.data?.creation, "DD/MM/YYYY HH:mm")
                      ? formatDate(props?.data?.creation, "DD/MM/YYYY HH:mm")
                      : "_"}
                  </Typography>
                  <Typography className={classes.text}>
                    {props?.data?.company ? props?.data?.company : "_"}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Box>
      <Box className={classes.paper}>
        <Typography style={{ paddingTop: 50 }} color={"primary"}>
          {t("common:DetailProd")}
        </Typography>
        <br />
        <SimpleTable
          title={"stockEntryDetail"}
          hasPaper={true}
          data={props?.data ? props?.data?.items : []}
          columns={props?.columns}
        />
      </Box>
    </div>
  );
});
