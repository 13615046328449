import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { Loader } from "../../../../components/loader";
import { makeStyles } from "@material-ui/core/styles";
import { Divider } from "@mui/material";
import { useTranslation } from "react-i18next";
import i18n from "./../../../../i18n";

const useStyles = makeStyles((theme) => ({
  greyTableHeadCell: {
    backgroundColor: "#f0f0f0",
  },
  printButton: {
    textAlign: "right",
  },
  tableRow: {
    pageBreakInside: "auto",
    breakAfter: "auto",
    breakBefore: "auto",
    breakInside: "auto",
    border: "1px solid black",
    borderCollapse: "collapse",
    padding: "5px",
    width: "50%",
    display: "table",
    tableLayout: "fixed",
  },
  tableCell: {
    pageBreakInside: "auto",
  },
  table: {
    maxWidth: "100%",
    width: "50%",
    "@media print": {
      maxWidth: "50%",
      width: "50%",
    },
  },
}));

const PrintPurchaseReceipt = ({ id }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const [supplierList, setSupplierList] = useState([]);
  const [addressSupplier, setAddressSupplier] = useState([]);
  const [itemList, setItemList] = useState([]);
  const [contactNumber, setContactNumber] = useState("");
  const [quotationNo, setQuotationNo] = useState("");
  const [supplierSelected, setSupplierSelected] = useState("");
  const [companySelected, setCompanySelected] = useState("");
  const [currencySelected, setCurrencySelected] = useState("");
  const [priceSelected, setPriceSelected] = useState("");
  const [purchaseTaxTemplateSelected, setPurchaseTaxTemplateSelected] = useState("");
  const [taxCategorySelected, setTaxCategorySelected] = useState("");
  const [addressSelected, setAddressSelected] = useState("");
  const [contactSelected, setContactSelected] = useState("");
  const [transactionDate, setTransactionDate] = useState("");
  const [state, setState] = useState({
    items: [],
    taxes: [],
  });

  const componentRef = useRef();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URI}/api/details`,
          {
            params: {
              doctype: "Purchase Receipt",
              name: id.name,
            },
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
              Authorization: JSON.parse(localStorage.getItem("user")).token,
            },
          }
        );

        const fetchedData = response.data["docs"];
        if (fetchedData.length > 0) {
          const item = fetchedData[0];
          setState({
            items: item.items || [],
            taxes: item.taxes || [],
          });
          setContactNumber(item.contact_mobile);
          setQuotationNo(item.name);
          setSupplierSelected(item.supplier);
          setCompanySelected(item.company);
          setCurrencySelected(item.currency);
          setPriceSelected(item.price_list);
          setPurchaseTaxTemplateSelected(item.purchase_tax_template);
          setTaxCategorySelected(item.tax_category);
          setAddressSelected(item.supplier_address);
          setContactSelected(item.contact_person);
          setTransactionDate(item.transaction_date);
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des données :", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  const getSupplierList = () => {
    fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        doctype: "Supplier",
        fields: [
          "`tabSupplier`.`name`",
          "`tabSupplier`.`supplier_name`",
          "`tabSupplier`.`mobile_no`",
        ],
        filters: [["Supplier", "supplier_name", "like", id.supplier]],
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (Array.isArray(data)) {
          setSupplierList(data);
        }
      });
  };

  const getAddressSupplierList = () => {
    fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        doctype: "Address",
        fields: [
          "`tabAddress`.`name`",
          "`tabAddress`.`city`",
          "`tabAddress`.`address_line1`",
          "`tabAddress`.`address_line2`",
        ],
        filters: [
          ["Address", "name", "like", supplierList[0]?.supplier_primary_address],
        ],
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (Array.isArray(data)) {
          setAddressSupplier(data);
        }
      });
  };

  useEffect(() => {
    getSupplierList();
  }, []);

  useEffect(() => {
    if (supplierList.length > 0) {
      getAddressSupplierList();
    }
  }, [supplierList]);

  const convertirMontantEnLettres = (montant) => {
    let unites = [];
    let dizaines = [];
    if (i18n.language === "fr") {
      unites = [
        "",
        "un",
        "deux",
        "trois",
        "quatre",
        "cinq",
        "six",
        "sept",
        "huit",
        "neuf",
      ];
      dizaines = [
        "",
        "dix",
        "vingt",
        "trente",
        "quarante",
        "cinquante",
        "soixante",
        "soixante-dix",
        "quatre-vingts",
        "quatre-vingt-dix",
      ];
    } else if (i18n.language === "en") {
      unites = [
        "",
        "one",
        "two",
        "three",
        "four",
        "five",
        "six",
        "seven",
        "eight",
        "nine",
      ];
      dizaines = [
        "",
        "ten",
        "twenty",
        "thirty",
        "forty",
        "fifty",
        "sixty",
        "seventy",
        "eighty",
        "ninety",
      ];
    } else {
      unites = [
        "",
        "واحد",
        "اثنين",
        "ثلاثة",
        "اربعة",
        "خمسة",
        "ستة",
        "سبعة",
        "ثمانية",
        "تسعة",
      ];
      dizaines = [
        "",
        "عشرة",
        "عشرون",
        "ثلاثون",
        "اربعون",
        "خمسون",
        "ستون",
        "سبعون",
        "ثمانون",
        "تسعون",
      ];
    }

    const convertirGroupeEnLettres = (nombre) => {
      let resultat = "";

      const centaines = Math.floor(nombre / 100);
      const dizainesEtUnites = nombre % 100;

      if (i18n.language === "fr") {
        if (centaines > 0) {
          resultat += unites[centaines] + " cent ";
        }

        if (dizainesEtUnites >= 10 && dizainesEtUnites <= 19) {
          resultat += "et " + unites[dizainesEtUnites - 10] + " ";
        } else if (dizainesEtUnites >= 20) {
          resultat += dizaines[Math.floor(dizainesEtUnites / 10)] + " ";
          if (dizainesEtUnites % 10 !== 0) {
            resultat += unites[dizainesEtUnites % 10] + " ";
          }
        } else if (dizainesEtUnites > 0) {
          resultat += unites[dizainesEtUnites] + " ";
        }

        return resultat.trim();
      } else if (i18n.language === "en") {
        if (centaines > 0) {
          resultat += unites[centaines] + " hundred ";
        }

        if (dizainesEtUnites >= 10 && dizainesEtUnites <= 19) {
          resultat += "and " + unites[dizainesEtUnites - 10] + " ";
        } else if (dizainesEtUnites >= 20) {
          resultat += dizaines[Math.floor(dizainesEtUnites / 10)] + " ";
          if (dizainesEtUnites % 10 !== 0) {
            resultat += unites[dizainesEtUnites % 10] + " ";
          }
          return resultat.trim();
        } else if (dizainesEtUnites > 0) {
          resultat += unites[dizainesEtUnites] + " ";
        }

        return resultat.trim();
      } else {
        if (centaines > 0) {
          resultat += unites[centaines] + " مائة ";
        }

        if (dizainesEtUnites >= 10 && dizainesEtUnites <= 19) {
          resultat += "و " + unites[dizainesEtUnites - 10] + " ";
        } else if (dizainesEtUnites >= 20) {
          resultat += dizaines[Math.floor(dizainesEtUnites / 10)] + " ";
          if (dizainesEtUnites % 10 !== 0) {
            resultat += unites[dizainesEtUnites % 10] + " ";
          }
        } else if (dizainesEtUnites > 0) {
          resultat += unites[dizainesEtUnites] + " ";
        }

        return resultat.trim();
      }
    };

    const montantEntier = Math.floor(montant);
    const montantDecimal = Math.round((montant - montantEntier) * 1000);

    const milliards = Math.floor(montantEntier / 1000000000);
    const millions = Math.floor(
      (montantEntier - milliards * 1000000000) / 1000000
    );
    const milliers = Math.floor(
      (montantEntier - milliards * 1000000000 - millions * 1000000) / 1000
    );
    const restant = montantEntier % 1000;

    let resultat = "";

    if (i18n.language === "fr") {
      if (milliards > 0) {
        resultat += convertirGroupeEnLettres(milliards) + " milliard ";
      }

      if (millions > 0) {
        resultat += convertirGroupeEnLettres(millions) + " million ";
      }

      if (milliers > 0) {
        resultat += convertirGroupeEnLettres(milliers) + " mille ";
      }

      if (restant > 0) {
        resultat += convertirGroupeEnLettres(restant);
      }

      resultat += " dinars";

      if (montantDecimal > 0) {
        resultat +=
          " et " + convertirGroupeEnLettres(montantDecimal) + " millimes";
      }

      return resultat;
    } else if (i18n.language === "en") {
      if (milliards > 0) {
        resultat += convertirGroupeEnLettres(milliards) + " billion ";
      }

      if (millions > 0) {
        resultat += convertirGroupeEnLettres(millions) + " million ";
      }

      if (milliers > 0) {
        resultat += convertirGroupeEnLettres(milliers) + " thousand ";
      }

      if (restant > 0) {
        resultat += convertirGroupeEnLettres(restant);
      }

      resultat += " dinars";

      if (montantDecimal > 0) {
        resultat +=
          " and " + convertirGroupeEnLettres(montantDecimal) + " millims";
      }

      return resultat;
    } else {
      if (milliards > 0) {
        resultat += convertirGroupeEnLettres(milliards) + " مليار ";
      }

      if (millions > 0) {
        resultat += convertirGroupeEnLettres(millions) + " مليون ";
      }

      if (milliers > 0) {
        resultat += convertirGroupeEnLettres(milliers) + " ألف ";
      }

      if (restant > 0) {
        resultat += convertirGroupeEnLettres(restant);
      }

      resultat += " دينار";

      if (montantDecimal > 0) {
        resultat += " و " + convertirGroupeEnLettres(montantDecimal) + " مليم";
      }

      return resultat;
    }
  };

  const handlePrint = () => {
    if (componentRef.current) {
      window.print();
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div
      className="supplier-quotation"
      style={{
        width: "200mm",
        height: "297mm",
        margin: "auto",
      }}
    >
      <div style={{ margin: "15px" }}>
        <div
          style={{
            fontSize: "24px",
            fontWeight: 500,
            textAlign: "right",
            color: "rgb(102, 102, 102)",
            lineHeight: "31.2px",
            fontFamily: "Helvetica Neue",
            textRendering: "optimizeLegibility",
            textSizeAdjust: "100%",
            textTransform: "uppercase",
            marginTop: "85px",
            marginRight: "20px",
          }}
        >
          {t("buying:PurchaseReceipt")}
        </div>
      </div>
      <Divider orientation="horizontal" fullWidth />
      <div
        style={{ marginTop: "30px" }}
        onClick={() => {
          // var bouton = document.getElementById("monBouton");
          // bouton.parentNode.removeChild(bouton);
          window.print();
        }}
      >
        <svg
          className="print-icon"
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          id="monBouton"
          height="40"
          viewBox="0 0 40 40"
        >
          <path d="M19 8H5c-1.66 0-3 1.34-3 3v6h4v4h12v-4h4v-6c0-1.66-1.34-3-3-3zm-3 11H8v-5h8v5zm3-7c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm-1-9H6v4h12V3z" />
        </svg>
      </div>
      <div style={{ marginTop: "25px", marginLeft: "20px", width: "100%" }}>
        <div>
          <span style={{ fontSize: "15px", fontWeight: "bold" }}>
            {t("common:name")} :
          </span>
          <span style={{ marginLeft: 50 }}>{supplierSelected}</span>
        </div>
        <br />
        <br />
        <div>
          <span style={{ fontSize: "15px", fontWeight: "bold" }}>
            {t("common:date")} :
          </span>
          <span style={{ marginLeft: 50 }}>{id.posting_date}</span>
        </div>
        <br />
        <br />
        <div>
          <span style={{ fontSize: "14px", fontWeight: "bold" }}>
            {t("common:addr")} :
          </span>{" "}
          <span style={{ marginLeft: 30 }}>
          {" "}            
         {addressSelected}
          </span>
        </div>
        <br />
        <br />
        <div>
          <span style={{ fontSize: "14px", fontWeight: "bold" }}>
            {t("common:contact")} :
          </span>{" "}
          <span style={{ marginLeft: 30 }}>{contactSelected}</span>
        </div>
        <br />
        <br />
        <div>
          <span style={{ fontSize: "14px", fontWeight: "bold" }}>
            {t("common:phone")} :
          </span>
          <span style={{ marginLeft: 30 }}>{supplierList[0]?.mobile_no}</span>
        </div>
        <br />
        <br />
      </div>
      <div
        className="items"
        style={{
          marginTop: "40px",
          marginLeft: "20px",
          marginRight: "40px",
          width: "95%",
        }}
      >
        <table style={{ borderCollapse: "collapse" }}>
          <thead style={{ backgroundColor: "#eeeeee", color: "black" }}>
            <tr>
              <th style={{ border: "1px solid black" }}>Sr</th>
              <th style={{ border: "1px solid black" }}>
                {t("common:barcode")}
              </th>
              <th style={{ border: "1px solid black" }}>
                {t("product:itemcode")}
              </th>
              <th style={{ border: "1px solid black" }}>
                {t("common:Description")}
              </th>
              <th style={{ border: "1px solid black" }}>Quantité Acceptée</th>
              <th style={{ border: "1px solid black" }}>
                {t("common:remise")}
              </th>
              <th style={{ border: "1px solid black" }}>
                Taux avec marge (devise de l'entreprise)
              </th>
              <th style={{ border: "1px solid black" }}>{t("common:taux")}</th>
              <th style={{ border: "1px solid black" }}>
                {t("common:montant")}
              </th>
              <th style={{ border: "1px solid black" }}>Rate of Stock UOM</th>
              <th style={{ border: "1px solid black" }}>Compte de dépense</th>
            </tr>
          </thead>
          <tbody>
            {state.items.map((item, index) => (
              <tr key={index}>
                <td style={{ border: "1px solid black", textAlign: "center" }}>
                  {index + 1}
                </td>
                <td style={{ border: "1px solid black", textAlign: "center" }}>
                  {item.item_code}
                </td>
                <td style={{ border: "1px solid black", textAlign: "center" }}>
                  {item.item_code}
                </td>
                <td style={{ border: "1px solid black", textAlign: "center" }}>
                  {item.item_name}
                </td>
                <td style={{ border: "1px solid black", textAlign: "center" }}>
                  {item.qty}
                </td>
                <td style={{ border: "1px solid black", textAlign: "center" }}>
                  {item.discount}
                </td>
                <td style={{ border: "1px solid black", textAlign: "center" }}>
                  {item.rate + index}
                </td>
                <td style={{ border: "1px solid black", textAlign: "center" }}>
                  {item.rate}
                </td>
                <td style={{ border: "1px solid black", textAlign: "center" }}>
                  {item.amount}
                </td>
                <td style={{ border: "1px solid black", textAlign: "center" }}>
                  {item.stock_uom}
                </td>
                <td style={{ border: "1px solid black", textAlign: "center" }}>
                  {item.expense_account}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div style={{ marginLeft: "10px" }}>
        <div
          className="grand-total"
          style={{ fontSize: "14px", fontWeight: "bold" }}
        >
          <div style={{ textDecoration: "underline" }}>
            {t("common:qtyTotal")} :
          </div>
          <div>
            {state.items.reduce((total, item) => total + item.qty, 0)}
          </div>
        </div>
        <div
          className="grand-total"
          style={{ fontSize: "14px", fontWeight: "bold" }}
        >
          <div style={{ textDecoration: "underline" }}>
            {t("common:grandTotal")} :
          </div>
          <div>
            {state.items
              .reduce((total, item) => total + parseFloat(item.amount), 0)
              .toFixed(3)}{" "}
            &nbsp;{" "}
            {i18n.language === "fr" || i18n.language === "en"
              ? "TND"
              : "دينار"}
          </div>
        </div>
        <div className="grand-total">
          <div
            style={{
              fontSize: "14px",
              fontWeight: "bold",
              textDecoration: "underline",
            }}
          >
            Montant en Lettres :
          </div>
          <div style={{ fontSize: "13px", fontWeight: "bold" }}>
            {convertirMontantEnLettres(
              state.items
                .reduce((total, item) => total + parseFloat(item.amount), 0)
                .toFixed(3)
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrintPurchaseReceipt;
