import { FormatNotifications } from "../../helpers/utils";
import {
  MARK_NOTIFICATION_READ,
  MARK_NOTIFICATION_READ_SUCCESS,
  NEW_NOTIFICATION,
  NOTIFICATIONS_LOADED,
} from "./broadcastNotification.type";
import { FAILED_ADD_DATA } from "../rollback/rollback.type";

export const LoadNotifications = (notifications) => {
  return {
    type: NOTIFICATIONS_LOADED,
    notifications: FormatNotifications(notifications)?.filter((el) => el),
  };
};

export const NewNotificationBrodcast = (notification) => {
  let listnotifications = FormatNotifications([notification])?.filter(
    (el) => el
  );
  if (listnotifications && listnotifications?.length > 0) {
    return {
      type: NEW_NOTIFICATION,
      notification: listnotifications,
    };
  }
  return;
};

export const MarkAsRead = (id, currentUserId) => {
  const action = {
    type: MARK_NOTIFICATION_READ,
    payload: id,
    meta: {
      offline: {
        // the network action to execute:
        effect: {
          url: `${process.env.REACT_APP_API_URI}/api/mark-as-read`,
          method: "POST",
          body: JSON.stringify({
            id: id,
            user_id: currentUserId,
          }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
            Authorization: JSON.parse(localStorage.getItem("user")).token,
          },
        },
        // action to dispatch when effect succeeds:
        commit: {
          type: MARK_NOTIFICATION_READ_SUCCESS,
          meta: {},
        },
        rollback: { type: FAILED_ADD_DATA },
      },
    },
  };
  action.meta.offline.rollback.meta = action;
  return (dispatch, getState) => {
    dispatch(action);
  };
};
