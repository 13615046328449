import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    ticketHeader : {
      marginBottom: "7px",
      textAlign : "center",
      fontWeight: "bold",
      marginTop: "20px",
    },
   table: {
      background: theme.palette.productsTable.background,
      borderSpacing: "3 0px",
      borderCollapse: "separate",
      "& .MuiTableRow-root": {
        display: "table",
        width: "80%",
        tableLayout: "fixed",
      },
      marginTop: "15px",
      marginBottom: "15px",
    },
    tableRow: {
      
    },
    tableCell: {
      background: theme.palette.productsTable.tableCell,
      padding: "7px 16px",
      color: theme.palette.primary.main,
    },
    root : {
      marginTop: 27
   },
    tabHead : {
      padding :"1.0625rem 1.5rem",
    },
    paper :  {
      overflow : "auto",
      padding: "0px 5%"
    },
    company: {
      float: 'right',
      textAlign: 'right',
      marginTop: 30,
      marginRight:80
    },
    float:{
      width: "40%",
      height: "50%",
      marginRight:-40,
      marginTop:20
    }
}));