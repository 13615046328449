import React, { useEffect } from "react";
import Box from "@material-ui/core/Box";
import { useStyles } from "../../style";
import { Button, Typography } from "@material-ui/core";
import clsx from "clsx";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useHistory, useParams } from "react-router-dom";
import { useTableStyles } from "../../../../styles/table.style";
import { useDispatch, useSelector } from "react-redux";
import { GetOrderdProducts } from "../../../../helpers/helper";
import {
  LoadFullfilmentsOrderById,
  UpdateOrderStatus,
} from "../../../../store/fullfilment/fullfilment.action";
import { SetTransfertData } from "../../../../store/transferRequest/transferRequest.action";
import { StyledCheckbox } from "../../../../components/styledCheckbox";
import Barcode from "react-barcode";
import { CreateStatusEmail } from "../../../../store/fullfilment/fullfilment.action";
import validator from "validator";
import { useTranslation } from "react-i18next";

export const FulfillementPrepare = () => {
  const classes = useStyles();
  const history = useHistory();
  const tableclasses = useTableStyles();
  const products = useSelector((state) => state.Product.productsPos);
  const order = useSelector((state) => state.Fullfilment.order);
  const warehousee = useSelector((state) => state.Login.selectedData);
  const { t } = useTranslation(["order", "common", "fulfillment", "product"]);

  const [orderdProducts, setOrderdProducts] = React.useState([]);
  const [itemList, setItemList] = React.useState([]);
  const { id } = useParams();
  const dispatch = useDispatch();

  const CheckQuantity = () => {
    const data = orderdProducts.filter((p) => p.actual_qty < p.quantity);
    return data.length > 0;
  };

  const CheckRowQuantity = (item_code) => {
    const rowIndex = orderdProducts.findIndex(
      (el, i) => el.item_code == item_code
    );
    if (rowIndex == -1) return false;
    if (
      orderdProducts[rowIndex]?.actual_qty < orderdProducts[rowIndex]?.quantity
    ) {
      return false;
    } else if (orderdProducts[rowIndex]?.actual_qty == 0) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    dispatch(LoadFullfilmentsOrderById(id));
  }, []);

  useEffect(() => {
    setOrderdProducts(
      order && order.products
        ? GetOrderdProducts([...products], order.products)
        : []
    );
  }, [order]);

  const handleUpdateProducts = (status) => {
    const tab = status == "prepared" ? 2 : 1;
    dispatch(UpdateOrderStatus({ status, id: order.id, redirectToTab: tab }));
    if (validator.isEmail(order.customer_email)) {
      const data = {
        additional_discount_percentage: order.additional_discount_percentage,
        age: order.age,
        company: order.company,
        created_at: order.created_at,
        customer_email: order.customer_email,
        customer_name: order.customer_name,
        delivery_date: order.delivery_date,
        discount_amount: order.discount_amount,
        grand_total: order.grand_total,
        id: order.id,
        order_id: order.order_id,
        packs: order.packs,
        point_of_sales: order.point_of_sales,
        products: order.products,
        recipient: order.recipient,
        sales_date: order.sales_date,
        status: status,
        total_amount: order.total_amount,
        updated_at: order.updated_at,
      };
      dispatch(CreateStatusEmail(data.customer_email, data));
    }
  };
  const handleSubmitProducts = (row) => {
    const warehouse = warehousee.store;
    row.qty = row.quantity - row.actual_qty;
    row.quantity = row.quantity - row.actual_qty;
    row.name = row.item_code;
    const data = {
      set_warehouse: warehouse,
      items: [row],
    };
    dispatch(SetTransfertData(data));
    history.push("/transfer-request/create");
  };

  const handleCheckbox = (checked, row) => {
    if (checked) {
      setItemList([...itemList, row]);
    } else {
      setItemList(itemList.filter((i) => i !== row));
    }
  };

  const handleCreateSelectedTransfert = () => {
    const warehouse = warehousee.store;
    itemList.forEach((x) => {
      x.qty = x.quantity - x.actual_qty;
      x.quantity = x.quantity - x.actual_qty;
      x.name = x.item_code;
    });
    const data = {
      set_warehouse: warehouse,
      items: itemList,
    };
    dispatch(SetTransfertData(data));
    history.push("/transfer-request/create");
  };

  return (
    <Box className={classes.block}>
      <Box className={classes.titleblock}>
        <span
          onClick={() => history.push(`/fulfillement?tab=1`)}
          className={clsx(classes.closeicon, "icon-close")}
        />
        <Typography
          align={"center"}
          className={classes.title}
          color={"primary"}
          variant="h5"
        >
          {t("common:order")} {order ? order.order_id : ""}
        </Typography>
      </Box>

      <Box className={clsx(classes.productPaper, tableclasses.paper)}>
        <Table className={tableclasses.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={tableclasses.tablecellHeader}> </TableCell>
              <TableCell
                className={tableclasses.tablecellHeader}
                align="center"
              >
                {t("common:produit")}
              </TableCell>
              <TableCell
                className={tableclasses.tablecellHeader}
                align="center"
              >
                {t("common:ref")}
              </TableCell>
              <TableCell
                className={tableclasses.tablecellHeader}
                align="center"
              >
                {t("common:stock")}
              </TableCell>
              <TableCell
                className={tableclasses.tablecellHeader}
                align="center"
              >
                {t("fulfillment:actualQty")}
              </TableCell>
              <TableCell
                className={tableclasses.tablecellHeader}
                align="center"
              >
                {t("fulfillment:qtyToPrep")}
              </TableCell>
              <TableCell className={tableclasses.tablecellHeader}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orderdProducts.map((row, index) => (
              <TableRow
                className={clsx({
                  [tableclasses.tableRow]: true, //always applies
                })}
                key={index}
              >
                <TableCell className={tableclasses.tableCell} align="center">
                  {row.actual_qty < row.quantity && (
                    <StyledCheckbox
                      checked={row.checked}
                      onChange={(e) => handleCheckbox(e.target.checked, row)}
                    />
                  )}
                </TableCell>

                <TableCell
                  className={
                    CheckRowQuantity(row.item_code)
                      ? tableclasses.tableCell
                      : clsx(tableclasses.tableCell, classes.error)
                  }
                  align="center"
                >
                  {row.item_name}
                </TableCell>
                <TableCell
                  className={
                    CheckRowQuantity(row.item_code)
                      ? tableclasses.tableCell
                      : clsx(tableclasses.tableCell, classes.error)
                  }
                  align="center"
                >
                  {row.item_code}
                </TableCell>
                <TableCell
                  className={
                    CheckRowQuantity(row.item_code)
                      ? tableclasses.tableCell
                      : clsx(tableclasses.tableCell, classes.error)
                  }
                  align="center"
                >
                  {row.is_stock_item
                    ? t("product:inStock")
                    : t("product:outOfStock")}
                </TableCell>
                <TableCell
                  className={
                    CheckRowQuantity(row.item_code)
                      ? tableclasses.tableCell
                      : clsx(tableclasses.tableCell, classes.error)
                  }
                  align="center"
                >
                  {row.actual_qty}
                </TableCell>
                <TableCell
                  className={
                    CheckRowQuantity(row.item_code)
                      ? tableclasses.tableCell
                      : clsx(tableclasses.tableCell, classes.error)
                  }
                  align="center"
                >
                  {row.quantity}
                </TableCell>
                <TableCell className={tableclasses.tableCell} align="right">
                  {!CheckRowQuantity(row.item_code) && (
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        handleSubmitProducts(row);
                      }}
                      className={classes.remove}
                    >
                      {t("common:transReq")}
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Box mt={4} mb={3} display="flex" justifyContent="flex-end">
          {CheckQuantity() && (
            <Button
              onClick={() => handleCreateSelectedTransfert()}
              disabled={itemList.length === 0}
              className={classes.customButtonSec}
              size={"small"}
              variant="outlined"
              color="secondary"
            >
              {t("common:transReq")}
            </Button>
          )}
          <Button
            onClick={() => handleUpdateProducts("prepared")}
            disabled={CheckQuantity()}
            className={classes.customButton}
            size={"small"}
            variant="outlined"
            color="primary"
          >
            {t("fulfillment:prepReq")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
