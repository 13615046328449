import React from "react";
import Box from "@material-ui/core/Box";
import { useStyles } from "../../style";
import { IconButton, Typography } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import { useSelector } from "react-redux";
export const Deposit = (props) => {
  const classes = useStyles();
  const globalDefaults = useSelector((state) => state.Login.globalDefaults);
  const holdselected = useSelector((state) => state.Checkout.holdselected);
  return (
    <Box className={classes.depositBlock} key={props.payments.name}>
      <Box className={classes.depositItem}>
        <Typography className={classes.depositItemText} color={"primary"}>
          {props.payments.name?.toUpperCase()}:
        </Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            gap: 10,
          }}
        >
          <Typography className={classes.depositItemText} color={"primary"}>
            <b>{props?.payments?.amount?.toFixed(3)}</b>{" "}
            {globalDefaults?.default_currency}
          </Typography>
          <IconButton
            onClick={() => {
              props.HandleDeletePayment(props.payments);
            }}
            disabled={props?.isReserved && holdselected}
          >
            <ClearIcon color="error" />
          </IconButton>
        </div>
      </Box>
    </Box>
  );
};
