import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import { Button, InputLabel, MenuItem, Select } from "@material-ui/core";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { useDispatch, useSelector } from "react-redux";
import { GlobalfilterData } from "../../../../helpers/helper";
import { BasicTable } from "../../../checkout/components/basictable";
import { CreateStockEntry } from "../../../../store/stockEntries/stockEntries.action";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  blockTitle: {
    fontSize: 14,
    marginBottom: 8,
    paddingLeft: 10,
  },
  formControl: {
    marginBottom: 15,
    "& .MuiInputBase-root": {
      background: theme.palette.white.main,
    },
    "& .MuiOutlinedInput-input": {
      padding: "8.9px 14px",
      fontSize: 12,
    },
  },
  paper: {
    paddingLeft: 10,
    "& .MuiInputBase-root": {
      background: theme.palette.white.main,
    },
    "& .MuiOutlinedInput-input": {
      padding: "8.9px 14px",
      fontSize: 12,
    },
    "& .MuiSelect-root": {
      padding: "0.4938rem 0.875rem",
    },
  },
  label: {
    fontSize: 12,
    color: theme.palette.gray.main,
    paddingLeft: 9,
    marginBottom: 6,
  },
  date: {
    marginBottom: 15,
  },
  firstCol: {
    [theme.breakpoints.up("md")]: {
      marginRight: 37,
    },
  },
  formBlock: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
    },
  },
  customButton: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.blue.main,
    border: "1px solid",
    borderColor: theme.palette.blue.main,
    fontSize: "0.875rem",
    borderRadius: 10,
    margin: "0rem 1.375rem",
    padding: "0.1875rem 1.4375rem",
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
      borderColor: theme.palette.blue.main,
    },
  },
  cancelButton: {
    color: "#909BAB",
  },
}));

export const Form = () => {
  const classes = useStyles();
  const stores = useSelector((state) => state.Login.stores);
  const products = useSelector((state) => state.Product.products);
  const [initialLines, setInitialLines] = React.useState([0, 1, 2, 3]);
  const [suggestion, setsuggestion] = React.useState([]);
  const [searchProduct, setSearchProduct] = React.useState("");
  const {t} = useTranslation(["common"])
  const dispatch = useDispatch();
  const [state, setState] = React.useState({
    doctype: "Stock Entry",
    stock_entry_type: "Material Receipt",
    posting_date: new Date().toISOString().slice(0, 10),
    to_warehouse: "",
    items: [],
  });

  const handleSearchProduct = (e) => {
    setSearchProduct(e.target.value);
    if (e.target.value == "") {
      setsuggestion([]);
      return;
    }
    const data = GlobalfilterData(
      products?.filter((p) => p?.is_stock_item == 1), e.target.value, [
      "item_code",
      "item_name",
      "description",
      "item_group"
    ]);

    setsuggestion(data);
  };

  const handleAddproduct = (id) => {
    var data = suggestion.find((s) => s.item_name == id);
    data.doctype = "Stock Entry Detail";
    data.t_warehouse = state.to_warehouse;
    const ItemIndex = state.items.findIndex((s) => s.item_name == id);
    if (ItemIndex != -1) {
      state.items[ItemIndex].quantity = state.items[ItemIndex].quantity + 1;
      state.items[ItemIndex].qty = state.items[ItemIndex].qty + 1;
      state.items[ItemIndex].stock_qty = state.items[ItemIndex].stock_qty + 1;
    } else {
      data.qty = 1;
      data.quantity = 1;
      data.stock_qty = 1;
      state.items.push(data);
      setState({ ...state, items: state.items });

      initialLines.splice(-1);
    }
    setsuggestion([]);
    setSearchProduct("");
  };

  const HandleQuantityChange = (onAdd, id) => {
    const ItemIndex = state.items.findIndex((s) => s.item_code == id);
    const data = [...state.items];
    if (ItemIndex != -1) {
      data[ItemIndex].quantity = onAdd
        ? parseInt(data[ItemIndex].quantity) + 1
        : Math.sign(data[ItemIndex].quantity - 1) == 1
        ? data[ItemIndex].quantity - 1
        : 0;
        data[ItemIndex].qty = data[ItemIndex].quantity;
        data[ItemIndex].stock_qty = data[ItemIndex].quantity
      setState({ ...state, items: data });
    }
  };

  const HandleUpdateQuantity = (e, id) => {
    const ItemIndex = state.items.findIndex((s) => s.item_code == id);
    const data = [...state.items];
    if (ItemIndex != -1) {
      data[ItemIndex].quantity = e.target.value;
      data[ItemIndex].qty = e.target.value;
      data[ItemIndex].stock_qty = e.target.value;
      setState({ ...state, items: data });
    } 
  }

  const HandleDelete = (id) => {
    const data = state.items.filter((s) => s.item_code !== id);
    setState({ ...state, items: data });
  };

  const [error, setError] = React.useState({
    to_warehouse: false,
    items: false,
  });

  const handleDateChange = (date) => {
    setState({ ...state, schedule_date: date.toISOString().slice(0, 10) });
  };

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
    setError({ ...error, [event.target.name]: false });
    if (event.target.name == "to_warehouse") {
      updateWarehouse(event.target.value);
    }
  };

  const updateWarehouse = (value) => {
    state.items.map((item) => {
      item.warehouse = value;
    });
  };

  const handleSubmit = (event) => {
    var isValid = true;
    if (state.to_warehouse === "") {
      setError({ ...error, ["to_warehouse"]: true });
      isValid = false;
    }
    if (isValid) {
      dispatch(CreateStockEntry(state));
    }
  };

  return (
    <Box className={classes.paper}>
      <Box className={classes.formBlock}>
        <Grid className={classes.firstCol} item xs={12} md={6}>
          <InputLabel
            error={error.to_warehouse}
            required
            className={classes.label}
          >
             {t("common:mgsCible")}
          </InputLabel>
          <FormControl variant="outlined" className={classes.formControl}>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              className={classes.select}
              value={state.to_warehouse}
              error={error.to_warehouse}
              IconComponent={ExpandMore}
              onChange={handleChange}
              name="to_warehouse"
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
            >
              {Array.isArray(stores) && stores.map((row, index) => (
                <MenuItem value={row.name} key={row.name}>
                  {row.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Box>
      <Typography
        className={classes.blockTitle}
        color={"primary"}
        variant="caption"
        display="block"
      >
         {t("common:article")} *
      </Typography>
      <BasicTable
        HandleQuantityChange={HandleQuantityChange}
        HandleDelete={HandleDelete}
        suggestion={suggestion}
        initialLines={initialLines}
        rows={state.items}
        handleSearchProduct={handleSearchProduct}
        handleAddproduct={handleAddproduct}
        searchProduct={searchProduct}
        HandleUpdateQuantity={HandleUpdateQuantity}
        transfer={true}
      />
      <Box display="flex" justifyContent="flex-end">
        <Button className={classes.cancelButton}>Annuler</Button>
        <Button
          onClick={handleSubmit}
          className={classes.customButton}
          size={"small"}
          variant="outlined"
          color="primary"
        >
           {t("common:save")}
        </Button>
      </Box>
    </Box>
  );
};
