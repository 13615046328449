import React from "react";
import Box from "@material-ui/core/Box";
import { useParams } from "react-router-dom";
import { PayementMethod } from "./components/payementMethod";
import { Result } from "./components/result";
import { useSelector } from "react-redux";

export const Payement = () => {
  let { step } = useParams();
  const payementsMethods = useSelector(
    (state) => state.Checkout.payementsMethods
  );
  let page = <PayementMethod payementsMethods={payementsMethods} />;
  if (step == 2) {
    page = <Result />;
  }

  return <Box key={step}>{page}</Box>;
};
