import {
  CREATE_HOLD_SUCCESS,
  CREATE_HOLD,
  GET_HOLDS,
  HOLDS_LOADED,
  DELETE_HOLDS_BY_ID,
  HOLDS_DELETED,
  SAVE_HOLD,
  CREATE_BULK_HOLD_SUCCESS,
  SET_HOLD_SELECTED,
  RELOAD_HOLD,
  GET_HOLD_BY_ID,
  HOLD_LOADED,
  UPDATE_PAYMENT_STATUS_SUCCESS,
  UPDATE_PAYMENT_STATUS,
} from "./hold.type";
import { FAILED_ADD_DATA } from "../rollback/rollback.type";

export const CreateHold = (content, contentinf) => {
  const action = {
    type: CREATE_HOLD,
    payload: content,
    meta: {
      offline: {
        // the network action to execute:
        effect: {
          url: `${process.env.REACT_APP_API_URI}/api/holds/create`,
          method: "POST",
          body: JSON.stringify({
            content,
            contentinf,
          }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
            Authorization: JSON.parse(localStorage.getItem("user")).token,
          },
        },
        // action to dispatch when effect succeeds:
        commit: {
          type: CREATE_HOLD_SUCCESS,
          meta: {
            notification: {
              code: "success",
              message: "notification:holdAdded",
            },
            redirectTo: "/checkout",
          },
        },
        rollback: { type: FAILED_ADD_DATA },
      },
    },
  };
  action.meta.offline.rollback.meta = action;
  return (dispatch) => {
    dispatch(action);
  };
};

export const loadHolds = (company, warehouse) => ({
  type: GET_HOLDS,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${process.env.REACT_APP_API_URI}/api/holds`,
        method: "POST",
        body: JSON.stringify({
          company: `${company}`,
          warehouse: `${warehouse}`,
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
          Authorization: JSON.parse(localStorage.getItem("user")).token,
        },
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: HOLDS_LOADED,
        meta: "",
      },
    },
  },
});

export const deleteHoldById = (id) => ({
  type: DELETE_HOLDS_BY_ID,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${process.env.REACT_APP_API_URI}/api/holds/delete/${id}`,
        method: "POST",
        body: JSON.stringify({
          id: id,
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
          Authorization: JSON.parse(localStorage.getItem("user")).token,
        },
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: HOLDS_DELETED,
        meta: "",
      },
    },
  },
});

export const saveHold = (content) => {
  return {
    type: SAVE_HOLD,
    payload: content,
  };
};
export const reloadHold = (content) => {
  return {
    type: RELOAD_HOLD,
    payload: content,
  };
};
export const setHoldSelected = (holdselected) => {
  return {
    type: SET_HOLD_SELECTED,
    holdselected: holdselected,
  };
};
export const CreatebulkHold = (content) => {
  const action = {
    type: CREATE_HOLD,
    payload: content,
    meta: {
      offline: {
        // the network action to execute:
        effect: {
          url: `${process.env.REACT_APP_API_URI}/api/holds/createbulk`,
          method: "POST",
          body: JSON.stringify({
            content,
          }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
            Authorization: JSON.parse(localStorage.getItem("user")).token,
          },
        },
        // action to dispatch when effect succeeds:
        commit: {
          type: CREATE_BULK_HOLD_SUCCESS,
          meta: {
            notification: {
              code: "success",
              message: "notification:holdAdded",
            },
          },
        },
        rollback: { type: FAILED_ADD_DATA },
      },
    },
  };
  action.meta.offline.rollback.meta = action;
  return (dispatch) => {
    dispatch(action);
  };
};

export const LoadHoldById = (id) => ({
  type: GET_HOLD_BY_ID,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${process.env.REACT_APP_API_URI}/api/holds/details`,
        method: "POST",
        body: JSON.stringify({
          id: id,
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
          Authorization: JSON.parse(localStorage.getItem("user")).token,
        },
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: HOLD_LOADED,
        meta: {
          then: (data) => (dispatch, getState) => {
            dispatch(setHoldSelected(data));
          },
        },
      },
    },
  },
});

export const UpdatePaymentStatusHoldById = (content) => ({
  type: UPDATE_PAYMENT_STATUS,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${process.env.REACT_APP_API_URI}/api/holds/update`,
        method: "POST",
        body: content,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
          Authorization: JSON.parse(localStorage.getItem("user")).token,
        },
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: UPDATE_PAYMENT_STATUS_SUCCESS,
        meta: {},
      },
    },
  },
});
