import React, { useEffect, useState } from "react";
import Badge from "@material-ui/core/Badge";
import PauseCircleFilledIcon from "@material-ui/icons/PauseCircleFilled";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Collapse, IconButton, SvgIcon, Tooltip } from "@material-ui/core";
import { Button, Modal, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { useSelector, useDispatch } from "react-redux";
import { CreateHold, saveHold } from "../../store/hold/hold.action";
import { useHistory } from "react-router-dom";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useTranslation } from "react-i18next";
import { updateItemDetails } from "../../store/checkout/checkout.action";
import {
  SetLoyaltyProgramDetails,
  UpdateAppliedLoyCard,
} from "../../store/cart/cart.action";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 10,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  icon: {
    color: theme.palette.primary.main,
    opacity: 1,
    cursor: "pointer",
    height: 30,
    width: 30,
    transition: "transform 0.2s ease-in-out",
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
  badge: {
    color: theme.palette.primary.main,
  },
  iconBtn: {},
}));

const Hold = (props) => {
  const { has2Redirect } = props;
  const dispatch = useDispatch();
  const theme = useTheme();
  const { t } = useTranslation(["interfaceCustomization", "common"]);
  const history = useHistory();
  const [openHoldModal, setOpenHoldModal] = useState(false);
  const [alertflag, setAlertflag] = useState(false);
  const classes = useStyles();
  const hold = useSelector((state) => state.Checkout.holds);
  const temporaryHolds = useSelector((state) => state.Checkout.temporaryHolds);
  const products = useSelector((state) => state.Checkout.products);
  const client = useSelector((state) => state.Checkout.client);
  const amount = useSelector((state) => state.Checkout.amount);
  const discount = useSelector((state) => state.Checkout.discount);
  const coupon = useSelector((state) => state.Checkout.selectedCoupon);
  const notification = useSelector((state) => state.Notification.notification);
  const itemDetails = useSelector((state) => state.Checkout.itemDetails);
  const selectedData = useSelector((state) => state.Login.selectedData);
  const globalDefaults = useSelector((state) => state.Login.globalDefaults);

  const appliedLoyaltyCard = useSelector(
    (state) => state.Cart.appliedLoyaltyCard
  );
  const loyaltyProgramDetails = useSelector(
    (state) => state.Cart.loyaltyProgramDetails
  );
  const ignorePricingRule = useSelector(
    (state) => state.Checkout.ignorePricingRules
  );

  const addHolds = () => {
    const updatedProducts = products.map((product) => {
      // Find the corresponding item in itemDetails
      const matchingItemDetail = itemDetails.find(
        (itemDetail) => itemDetail.item_code === product.item_code
      );

      const updatedProduct = {
        ...product,
        price_or_product_discount: matchingItemDetail
          ? matchingItemDetail.price_or_product_discount
          : "",
        pricing_rule_for: matchingItemDetail
          ? matchingItemDetail.pricing_rule_for
          : "",
        pricing_rules: matchingItemDetail
          ? matchingItemDetail.pricing_rules
          : "",
        discount_percentage: matchingItemDetail
          ? matchingItemDetail.discount_percentage
          : 0,
        discount_amount: matchingItemDetail
          ? matchingItemDetail.discount_amount
          : 0,
      };

      const filteredItemDetails = itemDetails?.filter(
        (s) => s.item_code !== product.item_code
      );
      dispatch(updateItemDetails(filteredItemDetails));
      return updatedProduct;
    });

    const updateAmount = {
      ...amount,
      isReserved: true,
    };
    const data = {
      products: updatedProducts,
      client: client?.customer_name,
      ref_client: client?.name,
      amount: updateAmount,
      discount,
      coupon: coupon?.name,
      pos_profile: selectedData?.cashier,
      ignore_pricing_rule: ignorePricingRule,
      warehouse: selectedData?.store,
    };
    if (products.length > 0) {
      setAlertflag(true);
      const datainf = {
        warehouse: selectedData?.store ?? selectedData?.store,
        company:
          globalDefaults?.default_company ?? globalDefaults?.default_company,
      };
      dispatch(CreateHold(data, datainf));
    }
  };

  const saveHolds = async () => {
    const updatedProducts = products.map((product) => {
      const matchingItemDetail = itemDetails.find(
        (itemDetail) => itemDetail.item_code === product.item_code
      );

      const updatedProduct = {
        ...product,
        price_or_product_discount: matchingItemDetail
          ? matchingItemDetail.price_or_product_discount
          : "",
        pricing_rule_for: matchingItemDetail
          ? matchingItemDetail.pricing_rule_for
          : "",
        pricing_rules: matchingItemDetail
          ? matchingItemDetail.pricing_rules
          : "",
        discount_percentage: matchingItemDetail
          ? matchingItemDetail.discount_percentage
          : 0,
        discount_amount: matchingItemDetail
          ? matchingItemDetail.discount_amount
          : 0,
      };

      const filteredItemDetails = itemDetails?.filter(
        (s) => s.item_code !== product.item_code
      );
      dispatch(updateItemDetails(filteredItemDetails));
      return updatedProduct;
    });
    const data = {
      index:
        temporaryHolds?.length === 0
          ? temporaryHolds?.length
          : temporaryHolds[temporaryHolds?.length - 1]?.index + 1,
      products: updatedProducts,
      client: client.customer_name,
      ref_client: client?.name,
      amount,
      discount,
      coupon: coupon?.name,
      pos_profile: selectedData?.cashier,
      ignore_pricing_rule: ignorePricingRule,
      warehouse: selectedData?.store,
    };
    if (products.length > 0) {
      setAlertflag(true);
      await dispatch(saveHold(data));
      setAlertflag(false);
      setOpenHoldModal(false);
      appliedLoyaltyCard && dispatch(UpdateAppliedLoyCard(null));
      loyaltyProgramDetails && dispatch(SetLoyaltyProgramDetails(null));
      has2Redirect && history.push("/checkout");
    }
  };
  const openModal = () => {
    if (products.length > 0) {
      setOpenHoldModal(true);
    } else {
      history.push("/pos-orders");
    }
  };

  const alert = (
    <div className={classes.paper}>
      <Collapse in={alertflag} timeout={15}>
        <Alert severity="success">
          <AlertTitle>Success</AlertTitle>
          <strong>{t("common:opsuccess")}</strong>
        </Alert>
      </Collapse>
      {!alertflag && (
        <>
          <Typography align={"center"} color={"primary"}>
            {t("common:msgContinue")}
          </Typography>
          <Box mt={3} justifyContent="center" display="flex">
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                amount.payments &&
                amount.payments.length > 0 &&
                amount.depositAmount === 0
                  ? addHolds()
                  : saveHolds()
              }
            >
              {t("common:yes")}
            </Button>
            <Button color="primary" onClick={() => setOpenHoldModal(false)}>
              {t("common:no")}
            </Button>
          </Box>
        </>
      )}
    </div>
  );

  return (
    <div>
      <Tooltip
        title={
          amount.payments && amount.payments.length > 0
            ? t("common:reserve")
            : "Hold"
        }
        placement="top-start"
      >
        <IconButton onClick={() => openModal()} className={classes.iconBtn}>
          <Badge
            badgeContent={hold?.length + temporaryHolds?.length}
            color="error"
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <SvgIcon viewBox="30 30 420 420" className={classes.icon}>
              <polygon
                style={{ fill: theme.palette.primary.main }}
                points="346.4,260 346.4,285.6 330.4,300.8 330.4,282.4 330.4,282.4 330.4,82.4 282.4,82.4 282.4,210.4 274.4,210.4 274.4,58.4 226.4,58.4 226.4,210.4 218.4,210.4 218.4,66.4 170.4,66.4 170.4,210.4 162.4,210.4 162.4,114.4 122.4,114.4 122.4,282.4 122.4,282.4 122.4,354.4 122.4,354.4 173.6,418.4 301.6,418.4 378.4,335.2 378.4,239.2"
              />
              <polygon
                style={{ fill: theme.palette.primary.main }}
                points="346.4,260 346.4,285.6 330.4,300.8 330.4,282.4 330.4,282.4 330.4,82.4 282.4,82.4 282.4,210.4 274.4,210.4 274.4,58.4 226.4,58.4 226.4,210.4 226.4,210.4 226.4,418.4 301.6,418.4 378.4,335.2 378.4,239.2"
              />
            </SvgIcon>
          </Badge>
        </IconButton>
      </Tooltip>

      <Modal
        open={openHoldModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {alert}
      </Modal>
    </div>
  );
};

export default Hold;
