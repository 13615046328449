import { CLEAR_STATE } from "../login/login.type";
import {
  SET_LOYALTY_PROGRAM_DETAILS,
  UPDATE_APPLIED_LOY_CARD,
  LOYALTY_PROGRAM_LIST_LOADED,
} from "./cart.type";

const initialState = {
  appliedLoyaltyCard: null,
  loyaltyProgramDetails: null,
  loyaltyPrograms: [],
  // obj: {
  //   loyalty_amount: 50,
  //   loyalty_points: 25,
  //   loyalty_program: "BLACK SABBATH",
  //   loyalty_redemption_account: "Cost of Goods Sold - K",
  //   loyalty_redemption_cost_center: "Main - K",
  // },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_APPLIED_LOY_CARD:
      return {
        ...state,
        appliedLoyaltyCard: action.payload,
      };
    case SET_LOYALTY_PROGRAM_DETAILS:
      return {
        ...state,
        loyaltyProgramDetails: action.payload,
      };
    case LOYALTY_PROGRAM_LIST_LOADED:
      return {
        ...state,
        loyaltyPrograms: Array.isArray(action.payload) ? action.payload : [],
      };
    case CLEAR_STATE:
      return (state = initialState);
    default:
      return state;
  }
};

export default reducer;
