import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { useSelector } from "react-redux";
import { symbol } from "../../../helpers/utils";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  box: {
    padding: 5,
    display: "flex",
    flexDirection: "row",
    background: theme.palette.secondary.main,
    borderRadius: 5,
    fontSize: 10,
    fontWeight: 600,
    color: theme.palette.primary.main,
    gap: 10,
  },
  margin: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 5,
  },
}));

export const OrderInformation = () => {
  const classes = useStyles();
  const { t } = useTranslation(["common", "checkout"]);

  const detailClient = useSelector((state) => state.Checkout.detailClient);
  const currencies = useSelector((state) => state.Client.currencies);
  const globalDefaults = useSelector((state) => state.Login.globalDefaults);

  const currency =
    globalDefaults?.default_currency &&
    symbol(globalDefaults?.default_currency, currencies)?.symbol;

  return (
    <Box className={classes.box}>
      <Box className={classes.margin}>
        <span className={"icon-money"} />
        <span>
          {t("checkout:dashboardInfoBilling")}:{" "}
          {detailClient?.__onload?.dashboard_info[0]?.billing_this_year
            ? detailClient?.__onload?.dashboard_info[0]?.billing_this_year.toFixed(
                3
              )
            : "0.000"}{" "}
          {currency}
        </span>
      </Box>
      <Box className={classes.margin}>
        <span className={"icon-money"} />
        <span>
          {t("checkout:totalUnpaid")}:{" "}
          {detailClient?.__onload?.dashboard_info[0]?.total_unpaid
            ? detailClient?.__onload?.dashboard_info[0]?.total_unpaid.toFixed(3)
            : "0.000"}{" "}
          {currency}
        </span>
      </Box>
      <Box className={classes.margin}>
        <span className={"icon-gift"} />
        <span>
          {t("clients:lyltyPoints")}:{" "}
          {detailClient?.__onload?.dashboard_info[0]?.loyalty_points
            ? detailClient?.__onload?.dashboard_info[0]?.loyalty_points
            : 0}
        </span>
      </Box>
    </Box>
  );
};
