import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGOUT_USER,
    LOAD_STORES,
    SET_SELECTED_CASHIER,
    SET_CUSH_FUND,
    LOAD_POS,
    LOAD_POS_SUCCESS,
    LOAD_STORES_SUCCESS,
    LOAD_POS_OPENINGS_SUCCESS,
    LOAD_POS_OPENINGS,
    SET_POS_OPENED_DATE,
    LOGOUT,
    SET_SIGNATURE,
    OPEN_SIGNATURE,
    SET_USER,
    SET_GLOBAL_DEFAULTS,
    SET_USER_DETAILS,
    CLEAR_STATE,
} from "./login.type";
import {RESET_CLOSE_ENTRY} from "../pos/pos.type";
import axiosInstance from "../../history/axiosInstance";

export const UserLogin = (content, history) => ({
    type: LOGIN_USER,
    payload: content,
    meta: {
        offline: {
            // the network action to execute:
            effect: {
                url: `${process.env.REACT_APP_API_URI}/api/login`,
                method: "POST",
                body: JSON.stringify({
                    email: content.email,
                    password: content.password,
                }),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
                },
            },
            // action to dispatch when effect succeeds:
            commit: {
                type: LOGIN_USER_SUCCESS,
                meta: {
                    then: (data) => async (dispatch, getState) => {
                        await dispatch(LoadUserDetails(data?.email, data?.token));
                        await dispatch(LoadGlobalDefaults(data?.token));
                        data?.role === "Admin" || data?.role === "Admin Warehouse"
                            ? history.push("/user/store-selection")
                            : history.push("/user/cashier-selection");
                    },
                },
            },
            // action to dispatch if network action fails permanently:
        },
    },
});

export const SetUser = (data) => {
    return (dispatch) => {
        dispatch({
            type: LOGIN_USER_SUCCESS,
            payload: data,
        });
    };
};

export const logoutUser = () => {
    return (dispatch) => {
        try {
            localStorage.removeItem("user");
            dispatch({
                type: LOGOUT,
            });
        } catch (error) {
        }
    };
};
export const logout = () => {
    return async (dispatch) => {
        const header = {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
                Authorization: JSON.parse(localStorage.getItem("user")).token,
            },
        };
        await axiosInstance.post(
            `${process.env.REACT_APP_API_URI}/api/logout`,
            {},
            header
        );
        dispatch({
            type: RESET_CLOSE_ENTRY,
        });
        dispatch({
            type: LOGOUT_USER,
        });
        dispatch({
            type: CLEAR_STATE,
        });
    };
};

export const GetStores = (company, user = {}) => {
    let filters = [
        ["Warehouse", "company", "=", `${company}`],
        ["Warehouse", "disabled", "=", 0],
        ["Warehouse", "is_group", "=", 0],
    ];

    if (user?.role.toLowerCase() === "admin warehouse")
    {
        filters.push(["Warehouse", "name", "in", user?.warehouses]);
    }

    return {
        type: LOAD_STORES,
        meta: {
            offline: {
                // the network action to execute:
                effect: {
                    url: `${process.env.REACT_APP_API_URI}/api/warehouses`,
                    method: "post",
                    body: JSON.stringify({
                        doctype: "Warehouse",
                        fields: [
                            "`tabWarehouse`.`name`",
                            "`tabWarehouse`.`default_customer`",
                            "`tabWarehouse`.`warehouse_name`",
                            "`tabWarehouse`.`address_line_1`",
                            "`tabWarehouse`.`address_line_2`",
                            "`tabWarehouse`.`city`",
                            "`tabWarehouse`.`phone_no`",
                            "`tabWarehouse`.`mobile_no`",
                            "`tabWarehouse`.`email`",
                            "`tabWarehouse`.`theme_id`",
                            "`tabWarehouse`.`company`",
                            "`tabWarehouse`.`is_storage`",
                        ],
                        filters: filters,
                    }),
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: JSON.parse(localStorage.getItem("user")).token,
                        "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
                    },
                },
                // action to dispatch when effect succeeds:
                commit: {type: LOAD_STORES_SUCCESS, meta: ""},
                // action to dispatch if network action fails permanently:
            },
        },
    }
};

export const GetPOSList = () => ({
    type: LOAD_POS,
    meta: {
        offline: {
            // the network action to execute:
            effect: {
                url: `${process.env.REACT_APP_API_URI}/api/pos`,
                method: "post",
                body: JSON.stringify({
                    doctype: "POS Profile",
                    fields: [
                        "`tabPOS Profile`.`name`",
                        "`tabPOS Profile`.`company`",
                        "`tabPOS Profile`.`warehouse`",
                        "`tabPOS Profile`.`disabled`",
                        "`tabPOS Profile`.`theme_id`",
                        "`tabPOS Profile`.`sales_person`",
                    ],
                }),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: JSON.parse(localStorage.getItem("user")).token,
                    "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
                },
            },
            // action to dispatch when effect succeeds:
            commit: {type: LOAD_POS_SUCCESS, meta: ""},
            // action to dispatch if network action fails permanently:
        },
    },
});

export const SetSelectedStoreAndCashier = (data) => {
    return {
        type: SET_SELECTED_CASHIER,
        selectedData: data,
    };
};

export const SetCushFund = (data) => {
    return {
        type: SET_CUSH_FUND,
        cushFund: data,
    };
};

export const SetPOSOpeningDate = (data) => {
    return {
        type: SET_POS_OPENED_DATE,
        posOpeningDate: data,
    };
};

export const GetPOSOpenings = () => ({
    type: LOAD_POS_OPENINGS,
    meta: {
        offline: {
            // the network action to execute:
            effect: {
                url: `${process.env.REACT_APP_API_URI}/api/pos-opening-entry`,
                method: "post",
                body: JSON.stringify({
                    doctype: "POS Opening Entry",
                    fields: [
                        "`tabPOS Opening Entry`.`name`",
                        "`tabPOS Opening Entry`.`owner`",
                        "`tabPOS Opening Entry`.`modified`",
                        "`tabPOS Opening Entry`.`status`",
                        "`tabPOS Opening Entry`.`pos_profile`",
                    ],
                    filters: [["POS Opening Entry", "status", "=", "Open"]],
                }),
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: JSON.parse(localStorage.getItem("user")).token,
                    "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
                },
            },
            // action to dispatch when effect succeeds:
            commit: {type: LOAD_POS_OPENINGS_SUCCESS, meta: ""},
            // action to dispatch if network action fails permanently:
        },
    },
});

export const SetSignature = (signature) => {
    return {
        type: SET_SIGNATURE,
        signature: signature,
    };
};

export const signatureOpen = (openSignature) => {
    return {
        type: OPEN_SIGNATURE,
        openSignature: openSignature,
    };
};

export const setUser = (consumer) => {
    return {
        type: SET_USER,
        consumer: consumer,
    };
};

export const LoadGlobalDefaults = (token) => {
    return (dispatch) => {
        const header = {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
                Authorization: token,
            },
        };
        return axiosInstance
            .get(
                `${process.env.REACT_APP_API_URI}/api/details?doctype=Global%20Defaults&name=Global%20Defaults`,
                header
            )
            .then(async ({data}) => {
                await dispatch(setGlobalDefaults(data?.docs[0]));
            });
    };
};

export const LoadUserDetails = (user, token) => {
    return (dispatch) => {
        const header = {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
                Authorization: token,
            },
        };
        return axiosInstance
            .get(
                `${process.env.REACT_APP_API_URI}/api/details?doctype=User&name=${user}`,
                header
            )
            .then(({data}) => {
                dispatch(setUserDetails(data?.docs[0]));
            });
    };
};

export const setUserDetails = (userDetails) => {
    return {
        type: SET_USER_DETAILS,
        userDetails: userDetails,
    };
};

export const setGlobalDefaults = (globalDefaults) => {
    return {
        type: SET_GLOBAL_DEFAULTS,
        globalDefaults: globalDefaults,
    };
};
