import React, { useEffect, useState, useRef } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Box from "@material-ui/core/Box";
import clsx from "clsx";
import { useTableStyles } from "../../../../styles/table.style";
import { useStyles } from "./style";
import { useSelector } from "react-redux";
import { formatDate } from "../../../../helpers/utils";
import {
  Button,
  ClickAwayListener,
  Modal,
  OutlinedInput,
  Typography,
} from "@material-ui/core";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  CreateAdvancePayment,
  cancelPaymentEntry,
  loadClientPaymentEntries,
  SetInvoice,
  LoadPOSInvoiceByName,
  LoadPricingRuleByName,
  SetPricingRulesClient,
} from "../../../../store/client/client.action";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router-dom";
import ReactToPrint from "react-to-print";
import { AvoirComponentToPrint } from "../../../../components/componentToPrint/avoir";
import { isValid } from "date-fns";
import { useTranslation } from "react-i18next";
import { useReactToPrint } from "react-to-print";
import { Pagination } from "@material-ui/lab";

export const ClientAvoirDetails = (props) => {
  const classes = useStyles();
  const tableClasses = useTableStyles();
  const maxElement=20;
  const history = useHistory();
  const componentRef = useRef();
  const selectedData = useSelector((state) => state.Login.selectedData);
  const companyacounts = useSelector(
    (state) => state.Configuration.companyacounts
  );
  const paymentEntries = useSelector((state) => state.Client.paymentEntries);
  const globalDefaults = useSelector((state) => state.Login.globalDefaults);
  const couponCodes = useSelector((state) => state.Client.couponCodes);
  const avoirsAccount = useSelector((state) => state.Client.avoirsAccount);
  const client = useSelector((state) => state.Client.client);
  const detailInvoice = useSelector((state) => state.Client.detailInvoice);
  const pricingRuleClient = useSelector(
    (state) => state.Client.pricingRuleClient
  );
  const currencies = useSelector((state) => state.Client.currencies);
  const { t } = useTranslation(["common", "clients", "order"]);
  const [openAvoir, setOpenAvoir] = useState(false);
  const componentRefA = useRef();
  const [selectedRow, setSelectedRow] = useState(null);

  const [page, setPage] = useState(1);

  const handlePrint = useReactToPrint({
    content: () => componentRefA.current,
    onAfterPrint: () => setSelectedRow(null),
  });
  const filteredPayments = Array.isArray(paymentEntries)
    ? paymentEntries.filter((p) => {
      return p.unallocated_amount != 0 && p.status == "Submitted";
    })
    : [];
  const total_unallocated_amount = filteredPayments.reduce(
    (a, v) => (a = a + parseFloat(v.unallocated_amount)),
    0
  );
  const dispatch = useDispatch();
  let { id } = useParams();
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(0);

  const handleChange = (e) => {
    e.preventDefault();
    setValue(e.target.value);
  };

  const handleDetails = (row) => {
    setOpenAvoir(true);
    dispatch(LoadPOSInvoiceByName(row.pos_invoice));
    dispatch(LoadPricingRuleByName(row.pricing_rule));
  };

  const handleClick = () => {
    const data = {
      customer: id,
      amount: parseFloat(value),
    };
    if (value !== 0) {
      dispatch(
        CreateAdvancePayment(
          data,
          globalDefaults?.default_company,
          companyacounts?.default_receivable_account,
          companyacounts?.default_currency
        )
      );
      setValue(0);
    }
    if (data) {
      dispatch(loadClientPaymentEntries(client));
    }
    setOpen(false);
  };

  const body = (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div className={classes.paper}>
        <Typography color={"primary"}>{t("common:amnt")}: </Typography>
        <OutlinedInput
          className={classes.input}
          placeholder="DT"
          type="number"
          value={value == 0 ? "" : value}
          onChange={(e) => {
            handleChange(e);
          }}
          name="User"
          id="demo-simple-select-outlined-label"
        />
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={() => handleClick()}
        >
          Ajouter{t("")}
        </Button>
      </div>
    </ClickAwayListener>
  );

  const avoir = (
    <ClickAwayListener
      onClickAway={() => {
        setOpenAvoir(false);
        dispatch(SetInvoice(null));
      }}
    >
      <div className={classes.paper}>
        <Typography align={"center"} color={"primary"}>
          Voulez-vous imprimer l'avoir
        </Typography>
        <Box mt={3} justifyContent="center" display="flex">
          <Button
            className={classes.button}
            disabled={!detailInvoice || !pricingRuleClient}
            onClick={handlePrint}
            variant="contained"
            color="primary"
          >
            Imprimer
          </Button>
          <Button
            onClick={() => {
              setOpenAvoir(false);
              dispatch(SetInvoice(null));
              dispatch(SetPricingRulesClient(null));
            }}
            color="primary"
          >
            Annuler
          </Button>
        </Box>
      </div>
    </ClickAwayListener>
  );

  return (
    <Box className={classes.root}>
      <Table className={tableClasses.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={tableClasses.tablecellHeader} align="left">
              {t("common:createDate")}
            </TableCell>
            <TableCell className={tableClasses.tablecellHeader} align="left">
              {t("common:ref")}
            </TableCell>
            <TableCell className={tableClasses.tablecellHeader} align="left">
              Client
            </TableCell>
            <TableCell className={tableClasses.tablecellHeader} align="left">
              {t("common:montant")}
            </TableCell>
            <TableCell className={tableClasses.tablecellHeader} align="left">
              {t("common:statut")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.isArray(avoirsAccount) &&
            avoirsAccount?.slice((page - 1) * maxElement, page * maxElement)?.map((row, index) => (
              <>
                <TableRow className={tableClasses.tableRow} key={index}>
                  <TableCell
                    className={clsx(tableClasses.tableCell, classes.price)}
                    align="left"
                  >
                    {row?.creation_date}
                  </TableCell>
                  <TableCell className={tableClasses.tableCell} align="left">
                    {row?.name}
                  </TableCell>
                  <TableCell className={tableClasses.tableCell} align="left">
                    {row?.customer}
                  </TableCell>
                  <TableCell className={tableClasses.tableCell} align="left">
                    {row?.credit_amount} {globalDefaults?.default_currency}
                  </TableCell>
                  <TableCell className={tableClasses.tableCell} align="left">
                    {row?.docstatus == "1" ? "Valide" : "Consommé"}
                  </TableCell>
                  {false && (
                    <TableCell className={tableClasses.tableCell} align="right">
                      <Button
                        size="small"
                        color="primary"
                        variant="contained"
                        onClick={() => handleDetails(row)}
                        onClickCapture={() => setSelectedRow(row)}
                      >
                        Imprimer
                      </Button>
                    </TableCell>
                  )}
                </TableRow>
                {selectedRow && detailInvoice && (
                  <div className={classes.print}>
                    <AvoirComponentToPrint
                      creditNoteCoupon={selectedRow}
                      detailInvoice={detailInvoice}
                      pricingRuleClient={pricingRuleClient}
                      title={"AVOIR"}
                      isClient={true}
                      ref={componentRefA}
                    />
                  </div>
                )}
              </>
            ))}
        </TableBody>
      </Table>
      {Array.isArray(avoirsAccount) && Math.ceil(avoirsAccount?.length / maxElement) > 0 && (
        <Box mt={2} display={"flex"} justifyContent={"right"}>
          <Pagination
            onChange={(event, newPage) => setPage(newPage)}
            defaultPage={page}
            count={Math.ceil(avoirsAccount.length / maxElement)}
            color="primary"
          />
        </Box>
      )}
      <Modal open={open}>{body}</Modal>
      <Modal open={openAvoir}>{avoir}</Modal>
    </Box>
  );
};
