import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.blue.main,
    border: "1px solid",
    borderColor: theme.palette.blue.main,
    fontSize: "0.875rem",
    borderRadius: 5,
    width: 103,
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
      borderColor: theme.palette.blue.main,
    },
  },
  paper: {
    position: "absolute",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 10,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  input: {
    marginTop: "2px",
    marginBottom: "10px",
    marginRight: 10,
    height: 40,
  },
  buttonForBody: {
    height: 40,
  },
  bodyTitle: {
    marginBottom: "20px",
  },
  buttonFulfill: {
    backgroundColor: "#F4F7F9",
    color: theme.palette.purple.main,
    border: "1px solid",
    borderColor: theme.palette.purple.main,
  },
  buttonDetails: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.gray.dark,
    border: "1px solid",
    borderColor: theme.palette.gray.dark,
  },
  remove: {
    fontSize: "0.8125rem",
    cursor: "pointer",
  },
  error: {
    color: theme.palette.error.main,
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    margin: "0.75rem 0px 0.8125rem",
    flexWrap: "wrap",
    addingLeft: 10,
    "& .MuiInputBase-root": {
      background: theme.palette.white.main,
    },
    "& .MuiOutlinedInput-input": {
      padding: "8.9px 14px",
      fontSize: 12,
    },
  },
  formControl: {
    maxWidth: 300,
    [theme.breakpoints.down("xs")]: {
      marginTop: 10,
    },
  },
  container: {
    paddingTop: 10,
  },
  pickIcon: {
    marginRight: "0.3125rem",
  },
  titleblock: {
    position: "relative",
    borderBottom: "0.0625rem solid #f3f4f6",
    padding: "0.8125rem 0rem 1.75rem 0rem",
  },
  closeicon: {
    position: "absolute",
    top: 18,
    color: theme.palette.primary.main,
    width: 23,
    height: 23,
    cursor: "pointer",
  },
  sorticon: {
    position: "relative",
    top: 3,
    color: theme.palette.primary.main,
    width: 13,
    height: 13,
    cursor: "pointer",
  },
  block: {
    padding: "0rem 1.75rem",
    marginBottom: "6.9375rem",
  },
  title: {
    fontWeight: 600,
  },
  textLight: {
    color: theme.palette.gray.main,
    fontSize: 12,
    fontWeight: 400,
  },
  text: {
    fontSize: 14,
    fontWeight: 600,
  },
  scanInput: {
    maxWidth: 625,
    marginTop: 50,
    marginBottom: 25,
    "& .MuiInputBase-root": {
      background: theme.palette.white.main,
      marginTop: 20,
    },
    "& .MuiOutlinedInput-input": {
      padding: "7.5px 14px",
      fontSize: 12,
    },
  },
  nbScannerButton: {
    width: 28,
    height: 25,
    background: theme.palette.secondary.main,
    borderRadius: 5,
    fontSize: 21,
    paddingTop: 2,
  },
  nbScannerText: {
    fontSize: 16,
    margin: "0rem 0.9375rem",
  },
  productPaper: {
    padding: "0rem 5rem !important",
    marginTop: 25,
  },
  card: {
    background: theme.palette.secondary.main,
    borderRadius: 10,
    border: "none",
    margin: "0rem 0.125rem",
  },
  item: {
    padding: "7px 15px",
  },
  verifyButton: {
    marginRight: "0.2rem",
  },
  Customcard: {
    background: theme.palette.secondary.main,
    borderRadius: 10,
    border: "none",
    margin: "1.875rem 0",
  },
  cardBoldText: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: 14,
  },
  cardLightText: {
    color: theme.palette.primary.main,
    fontSize: 14,
  },
  cardTitle: {
    backgroundColor: theme.palette.secondary.light,
    borderBottom: "1px solid #e7eaef",
    borderRadius: 6,
    color: theme.palette.primary.main,
    fontSize: 16,
    fontWeight: 700,
    padding: "10px",
    marginTop: 4,
    display: "flex",
    alignContent: "center",
    gap: 10,
  },
  cardTitleBlock: {
    borderBottom: "1px solid #e7eaef",
    padding: "10px 20px 17px",
  },
  cardContentBlock: {
    padding: "1.4375rem 1.5rem 2.0625rem",
  },
  customButton: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.blue.main,
    border: "1px solid",
    borderColor: theme.palette.blue.main,
    fontSize: "0.875rem",
    borderRadius: 10,
  },
  customButtonSec: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.blue.main,
    border: "1px solid",
    borderColor: theme.palette.blue.main,
    fontSize: "0.875rem",
    borderRadius: 10,
    marginRight: 10,
  },
  cancelButton: {
    color: "#909BAB",
  },
  autocomplete: {
    width: "100%",
    marginLeft: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      minWidth: 200,
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 10px) scale(1)",
      fontSize: 14,
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
      fontSize: 16,
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: theme.palette.white.main,
      padding: "0px 20px",
      "& input": {
        fontSize: 14,
      },
    },
  },
  packSelect: {
    maxWidth: 200,
    "& .MuiInputBase-root": {
      background: theme.palette.white.main,
    },
    "& .MuiOutlinedInput-input": {
      padding: "7.5px 14px",
      fontSize: 12,
    },
  },
  icon: {
    marginRight: "0.3125rem",
    height: 16,
    marginLeft: -3,
  },
  refusedButton: {
    background: "#FFF0F0",
    border: "2px solid #FF0000",
    color: "#FF0000",
    "&:hover": {
      background: "#FFF0F0",
      border: "2px solid #FF0000",
      color: "#FF0000",
    },
  },
  select: {
    marginRight: 10,
    "& .MuiSelect-root": {
      padding: "7px 14px",
    },
  },
  quantityButton: {
    width: 28,
    height: 25,
    background: theme.palette.secondary.main,
    borderRadius: 5,
    fontSize: 21,
    paddingTop: 2,
  },
  quantityText: {
    fontSize: 16,
    margin: "0rem 0.9375rem",
  },
  packControl: {
    marginBottom: 15,
    "& .MuiInputBase-root": {
      background: theme.palette.white.main,
    },
    "& .MuiOutlinedInput-input": {
      padding: "8.9px 14px",
      fontSize: 12,
    },
  },
  label: {
    fontSize: 12,
    marginBottom: 6,
    color: theme.palette.gray.main,
    paddingLeft: 9,
  },
}));
