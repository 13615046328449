export const PAYMENTS_METHODS_LOADED = "PAYMENTS METHODS LOADED";
export const GET_PAYMENTS_METHODS = "LOAD METHODS PAYMENTS";
export const UPDATE_PAYMENT = "UPDATE PAYMEMENT";
export const UPDATE_PRODUCTS = "UPDATE_PRODUCTS";
export const UPDATE_ITEM_FINANCIER = "UPDATE_ITEM_FINANCIER";
export const CREATE_ORDER = "CREATE_ORDER";
export const CREATE_ORDER_SUCCESS = "CREATE_ORDER_SUCCESS";
export const CREATE_EMAIL_SUCCESS = "CREATE_EMAIL_SUCCESS";
export const SEND_EMAIL = "SEND_EMAIL";
export const UPDATE_SELECTED_CLIENT = "UPDATE SELECTED CLIENT";
export const GET_ORDERS = "GET_ORDERS";
export const ORDERS_LOADED = "ORDERS_LOADED";

export const CREATE_DELIVERY_DOCUMENT = "CREATE_DELIVERY_DOCUMENT";
export const CREATE_INVOICE_DOCUMENT = "CREATE_INVOICE_DOCUMENT";
export const CREATE_PAYMENT_DOCUMENT = "CREATE_PAYMENT_DOCUMENT";

export const CREATE_DOCUMENT_SUCCESS = "CREATE_DOCUMENT_SUCCESS";

export const SET_PAYMENT_METHOD = "SET_PAYMENT_METHOD";
export const SET_PAYMENT_METHOD_SUCCESS = "SET_PAYMENT_METHOD_SUCCESS";

export const DOCUMENT_NUMBER_LODED = "DOCUMENT_NUMBER_LODED";

export const SET_DISCOUNT_LODED = "SET_DISCOUNT_LODED";

export const CANCEL_CHECKOUT = "CANCEL_CHECKOUT";

export const SET_DOCUMENT_PAYMENT = "SET_DOCUMENT_PAYMENT";
export const SET_DOCUMENT_DELIVERY = "SET_DOCUMENT_DELIVERY";
export const SET_DOCUMENT_INVOICE = "SET_DOCUMENT_INVOICE";

export const Order_LODED = "Order_LODED";

export const SET_HOLD_DATA = "SET_HOLD_DATA";

export const SET_COUPON_CODE = "SET_COUPON_CODE";
export const SET_PRICING_RULES = "SET_PRICING_RULES";

export const SET_DEFAULT_TAX = "SET_DEFAULT_TAX";

export const SALES_PERSON_LOADED = "SALES_PERSON_LOADED";
export const UPDATE_SELECTED_SALESPERSON = "UPDATE_SELECTED_SALESPERSON";

export const SET_DEFAULT_CLIENT = "SET_DEFAULT_CLIENT";
export const UPDATE_CURRENCY_RATE = "UPDATE_CURRENCY_RATE";
export const LOADING_CURRENCY_RATE = "LOADING_CURRENCY_RATE";
export const SET_DETAIL_CLIENT = "SET_DETAIL_CLIENT";

export const LOAD_ITEM_DETAILS = "LOAD_ITEM_DETAILS";
export const ITEM_DETAILS_LOADED = "ITEM_DETAILS_LOADED";
export const UPDATE_ITEM_DETAILS = "UPDATE_ITEM_DETAILS";

export const CALCULATOR_TOGGLED = "CALCULATOR_TOGGLED";

export const GET_RECENT_ORDERS = "GET_RECENT_ORDERS";
export const RECENT_ORDERS_LOADED = "RECENT_ORDERS_LOADED";

export const CUSTOM_REF_LOADED = "CUSTOM_REF_LOADED";

export const CREATE_SALES_PERSON = "CREATE_SALES_PERSON";
export const CREATE_SALES_PERSON_SUCCESS = "CREATE_SALES_PERSON_SUCCESS";

export const LOAD_RETURN = "LOAD_RETURN";
export const RETURN_LOADED = "RETURN_LOADED";
export const UPDATE_RETURNS = "UPDATE_RETURNS";

export const CREATE_RETURN = "CREATE_RETURN";
export const CREATE_RETURN_SUCCESS = "CREATE_RETURN_SUCCESS";
export const SET_RATE = "SET_RATE";
export const SELECT_CURRENCY = "SELECT_CURRENCY";
export const SELECT_CLIENT_INFO = "SELECT_CLIENT_INFO";

export const CREATE_CREDIT_NOTE = "CREATE_CREDIT_NOTE";
export const CREATE_CREDIT_NOTE_SUCCESS = "CREATE_CREDIT_NOTE_SUCCESS";
export const CANCEL_CREDIT_NOTE = "CANCEL_CREDIT_NOTE";
export const LOADING_CREDIT_NOTES = "LOADING_CREDIT_NOTES";
export const CREDIT_NOTES_LOADED = "CREDIT_NOTES_LOADED";

export const CREATE_PRICING_RULE = "CREATE_PRICING_RULE";
export const CREATE_PRICING_RULE_SUCCESS = "CREATE_PRICING_RULE_SUCCESS";
export const CANCEL_PRICING_RULE = "CANCEL_PRICING_RULE";

export const CREATE_COUPON_CODE = "CREATE_COUPON_CODE";
export const CREATE_COUPON_CODE_SUCCESS = "CREATE_COUPON_CODE_SUCCESS";
export const SET_GIFT_CARD_COUPON_CODE = "SET_GIFT_CARD_COUPON_CODE";
export const SET_RETURN = "SET_RETURN";

export const SET_POS_2_PRINT = "SET_POS_2_PRINT";

export const CREATE_AVOIR_ENTRY = "CREATE_AVOIR_ENTRY";
export const AVOIR_ENTRY_CREATED = "AVOIR_ENTRY_CREATED";

export const SET_AVOIR_2_PRINT = "SET_AVOIR_2_PRINT";

export const AVOIR_LIST_LOADED = "AVOIR_LIST_LOADED";
export const LOADING_AVOIR_LIST = "LOADING_AVOIR_LIST";

export const SET_TAX_SALES = "SET_TAX_SALES";

export const SET_TPE_TICKET_NUMBER = "SET_TPE_TICKET_NUMBER";

/* export const SET_INVOICES_RETURNED = "SET_INVOICES_RETURNED"; */

export const IGNORE_PRICING_RULES = "IGNORE_PRICING_RULES";
export const FAILED_PRICING_RULES_DATA = "FAILED_PRICING_RULES_DATA";
export const SET_DISCOUNT_AMOUNT_PR = "SET_DISCOUNT_AMOUNT_PR";

export const SAVE_CHEQUE_BULK = "SAVE_CHEQUE_BULK";
export const SAVE_CHEQUE_BULK_SUCCESS = "SAVE_CHEQUE_BULK_SUCCESS";

export const SEND_RESERVED_COMMAND_EMAIL = "SEND_RESERVED_COMMAND_EMAIL";
export const CREATE_RESERVED_COMMAND_EMAIL_SUCCESS =
  "CREATE_RESERVED_COMMAND_EMAIL_SUCCESS";
