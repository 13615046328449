import React from "react";
import { Button } from "@material-ui/core";
import { useStyles } from "./style";
import { useTranslation } from "react-i18next";

const ConfirmButton = (props) => {
  const classes = useStyles();
  const { t } = useTranslation(["common"]);
  const { handleSubmit, handleClose, activeStep, isAddProduct } = props;
  return (
    <>
      <Button className={classes.button} color="primary" onClick={handleClose}>
        {t("common:annuler")}
      </Button>
      {activeStep == 1 && (
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleSubmit()}
          className={classes.button}
        >
          {t("common:create")}
        </Button>
      )}
      {!isAddProduct && (
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={() => handleSubmit()}
        >
          {t("common:valider")}
        </Button>
      )}
    </>
  );
};

export default ConfirmButton;
