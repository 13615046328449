import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { Products } from "../../../transfertRequest/components/products";
import { useSelector } from "react-redux";
import {useTranslation} from "react-i18next";
import { SimpleTable } from "../../../../components/simpleTable";

export const useStyles = makeStyles((theme) => ({
      card : {
        background: theme.palette.secondary.main,
        borderRadius: 10,
        border : "none",
     },
     cardBoldText : {
        color: theme.palette.primary.main,
        fontWeight : 600,
        fontSize : 14
     },
     cardTitle : {
         fontSize : 16
     },
     cardTitleBlock : {
        borderBottom: "1px solid #e7eaef",
        padding: "10px 20px 17px"
     },
     cardContentBlock : {
        padding: "1.4375rem 1.5rem 2.0625rem"
     },
    usernameBlock : {
        background: '#FFF',
        borderRadius: 19,
        padding : 14,
        marginBottom : 17
    },
    username : {
        color: "#2F334D",
        fontSize: 20,
        fontWeight: 600
    },
    textLight : {
        color: theme.palette.gray.main,
        fontSize: 12,
        fontWeight: 500
    },
    text : {
        fontSize : 14,
        fontWeight: 600
    },
    item : {
        padding: "0rem 1.625rem"
    },
    backLink : {
        fontSize : 12,
        color : '#2F334D',
        display :"flex",
        alignItems : "center",
        cursor :"pointer",
        marginTop: 14,
        marginLeft: 22
    },
    linkIcon : {
        height: 10,
        width: 8
    }
  }));
  
export const StockEntry = (props) => {
  const classes = useStyles();
  const historyYY = useHistory();
  const {t} = useTranslation(["common", "order"]);
  const transferRequest = useSelector((state) => state.Transfert.selectedTransfertRequest);
  const columns = [
    {key: "item_code", title: "common:ref"},
    {key: "item_name", title: "common:name"},
    {key: "qty", title: "transfert:qtyDemandé"},
    {key: "ordered_qty", title: "transfert:qtyRecu"},
    {key: "warehouse", title: "common:mgsCible"}
  ]
  return (
    <Box>
       {props.details && (
           <Card
           className={clsx(classes.card, classes.cardBoldText)}
           variant="outlined"
         > 
           <CardContent>
               <Box display="flex">
                   <Grid item md={6}>
                        <Box className={classes.item}>
                            <Typography color={'primary'} className={classes.textLight}>{t("common:mgsSource")}</Typography>
                            <Typography className={classes.text}>{props.details.set_from_warehouse || transferRequest.set_from_warehouse}</Typography>
                        </Box>
                   </Grid>
                   <Grid item md={6}>
                        <Box className={classes.item}>
                            <Typography color={'primary'} className={classes.textLight}>{t("common:mgsCible")}</Typography>
                            <Typography className={classes.text}>{props.details.set_warehouse || transferRequest.set_warehouse}</Typography>
                        </Box>
                   </Grid>
               </Box>
               <Box>
                    <SimpleTable title={"StockReception"} columns={columns} data={props.details} />
               </Box>
           </CardContent>
         </Card> 
       )} 
    </Box>
  );
};
