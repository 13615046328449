import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import clsx from "clsx";
import { formatDate, statusFormat } from "../../../../helpers/utils";
import {useTranslation} from "react-i18next";
import { Loader } from "../../../../components/loader";
import { useTableStyles } from "../../../../styles/table.style";

export const useStyles = makeStyles((theme) => ({
      card : {
        background: theme.palette.secondary.main,
        borderRadius: 10,
        border : "none",
        minHeight : 1000
     },
     cardBoldText : {
        color: theme.palette.primary.main,
        fontWeight : 600,
        fontSize : 14
     },
     cardTitle : {
         fontSize : 16
     },
     cardTitleBlock : {
        borderBottom: "1px solid #e7eaef",
        padding: "10px 20px 17px"
     },
     cardContentBlock : {
        padding: "1.4375rem 1.5rem 2.0625rem"
     },
    usernameBlock : {
        background: '#FFF',
        borderRadius: 19,
        padding : 14,
        marginBottom : 17
    },
    username : {
        color: "#2F334D",
        fontSize: 20,
        fontWeight: 600
    },
    textLight : {
        color: theme.palette.gray.main,
        fontSize: 12,
        fontWeight: 500
    },
    text : {
        fontSize : 14,
        fontWeight: 600
    },
    item : {
        padding: "0rem 1.625rem",
        marginBottom:"1.4375rem"
    },
    backLink : {
        fontSize : 12,
        color : '#2F334D',
        display :"flex",
        alignItems : "center",
        cursor :"pointer",
        marginTop: 14,
        marginLeft: 22
    },
    linkIcon : {
        height: 10,
        width: 8
    }
  }));
  
export const OrderDetails = (props) => {
  const classes = useStyles();
  const tableClasses = useTableStyles();
  const {t} = useTranslation(["common", "order"]);
  if(!props.details) return (<Loader/>);

  return (
    <Box>
       {props.details && (
           <Card
           className={clsx(classes.card, classes.cardBoldText)}
           variant="outlined"
         > 
           <CardContent>
               <Box>
                   <Box className={classes.item}>
                       <Typography color={'primary'} className={classes.textLight}>Ref.</Typography>
                       <Typography className={classes.text}>{props.details.name}</Typography>
                   </Box>
                   <Box className={classes.item}>
                       <Typography color={'primary'} className={classes.textLight}>{t("common:client")}</Typography>
                       <Typography className={classes.text}>{props.details.customer}</Typography>
                   </Box>
                   <Box className={classes.item}>
                       <Typography color={'primary'} className={clsx(classes.textLight, tableClasses[statusFormat(props.details.status).color])}>{t("common:statut")}</Typography>
                       <Typography className={classes.text}>{statusFormat(props.details.status).value}</Typography>
                   </Box>
                   <Box className={classes.item}>
                       <Typography color={'primary'} className={clsx(classes.textLight, tableClasses[statusFormat(props.details.delivery_status).color])}>{t("order:statutLivraison")}</Typography>
                       <Typography className={classes.text}>{statusFormat(props.details.delivery_status).value}</Typography>
                   </Box>
                   <Box className={classes.item}>
                       <Typography color={'primary'} className={clsx(classes.textLight, tableClasses[statusFormat(props.details.billing_status).color])}>{t("order:statutPay")}</Typography>
                       <Typography className={classes.text}>{statusFormat(props.details.billing_status).value}</Typography>
                   </Box>
                   <Box className={classes.item}>
                       <Typography color={'primary'} className={classes.textLight}>{t("common:total")}</Typography>
                       <Typography className={classes.text}>{props.details.grand_total.toFixed(3)} {props.details.currency}</Typography>
                   </Box>

                   <Box className={classes.item}>
                       <Typography color={'primary'} className={classes.textLight}>Date</Typography>
                       <Typography className={classes.text}>{formatDate(props.details.creation, "DD/MM/YYYY HH:mm")}</Typography>
                   </Box>
                   <Box className={classes.item}>
                       <Typography color={'primary'} className={classes.textLight}>{t("order:dateLivraison")}</Typography>
                       <Typography className={classes.text}>{formatDate(props.details.delivery_date, "DD/MM/YYYY HH:mm")}</Typography>
                   </Box>
                   <Box className={classes.item}>
                       <Typography color={'primary'} className={classes.textLight}>{t("common:company")}</Typography>
                       <Typography className={classes.text}>{props.details.company}</Typography>
                   </Box>
               </Box>
           </CardContent>
         </Card> 
       )} 
    </Box>
  );
};
