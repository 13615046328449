import { createTheme } from "@material-ui/core/styles";

export const LightTheme = (theme) => {
  const $secondary =
    theme && theme.globalColors && theme.globalColors.secondary
      ? theme.globalColors.secondary
      : "#ECF2F5";
  const $blue =
    theme && theme.globalColors && theme.globalColors.customColor
      ? theme.globalColors.customColor
      : "#0766FF";
  const $primary =
    theme && theme.globalColors && theme.globalColors.primary
      ? theme.globalColors.primary
      : "#2F4B7C";
  const $white = "#FFFFFF";
  const $gray = "#657288";
  const font = "'Quicksand', sans-serif";
  return createTheme({
    typography: {
      fontFamily: font,
      fontWeightBold: 700,
      fontWeightLight: 300,
      fontWeightMedium: 500,
      fontWeightRegular: 500,
    },
    palette: {
      type: "light",
      primary: {
        light: "#3C4162",
        main: $primary,
        dark: "#21253C",
        textcolor: "#2F4B7C",
        contrastText: $white,
      },
      secondary: {
        light: "#f7f9ff",
        main: $secondary,
        dark: "#ECF2F5",
        contrastText: "#657288",
      },
      error: {
        light: "#e57373",
        main: "#f44336",
        dark: "#d32f2f",
        contrastText: $white,
      },
      warning: {
        light: "#ffb74d",
        main: "#ff9800",
        dark: "#f57c00",
        contrastText: $white,
      },
      info: {
        light: "#64b5f6",
        main: "#2196f3",
        dark: "#1976d2",
        contrastText: $white,
      },
      success: {
        light: "#81c784",
        main: "#4caf50",
        dark: "#388e3c",
        contrastText: $white,
      },
      purple: {
        ligth: "#6200FFCC",
        main: "#6200FFCC",
        dark: "#6200FFCC",
        contrastText: "#6200FFCC",
      },
      gray: {
        ligth: "#657288",
        main: "#657288",
        dark: "#AAB3BF",
        contrastText: "#657288",
      },
      blue: {
        ligth: $blue,
        main: $blue,
        dark: $blue,
        contrastText: $blue,
      },
      white: {
        ligth: $white,
        main: $white,
        dark: $white,
        contrastText: $white,
      },
      menuColor:
        theme && theme.globalColors && theme.globalColors.menu
          ? theme.globalColors.menu
          : "#2F334D",
      activeMenu:
        theme && theme.globalColors && theme.globalColors.activeMenu
          ? theme.globalColors.activeMenu
          : "#21253C",
      freeButton: {
        border:
          theme && theme.freeButton && theme.freeButton.border
            ? theme.freeButton.border
            : $primary,
        text:
          theme && theme.freeButton && theme.freeButton.text
            ? theme.freeButton.text
            : $primary,
        icon:
          theme && theme.freeButton && theme.freeButton.text
            ? theme.freeButton.text
            : $primary,
        background:
          theme && theme.freeButton && theme.freeButton.background
            ? theme.freeButton.background
            : $secondary,
      },

      calculator: {
        text:
          theme && theme.calculator && theme.calculator.text
            ? theme.calculator.text
            : $primary,
        icon: "#9CA4B1",
        background:
          theme && theme.calculator && theme.calculator.background
            ? theme.calculator.background
            : $secondary,
        border:
          theme && theme.calculator && theme.calculator.border
            ? theme.calculator.border
            : "#e3e9ed",
        backgroundPrice: "#D9E1E5",
      },

      productsTable: {
        totalPrice:
          theme && theme.selectedProducts && theme.selectedProducts.totalPrice
            ? theme.selectedProducts.totalPrice
            : $blue,
        button:
          theme && theme.selectedProducts && theme.selectedProducts.button
            ? theme.selectedProducts.button
            : $primary,
        tableCell:
          theme && theme.selectedProducts && theme.selectedProducts.tableCell
            ? theme.selectedProducts.tableCell
            : $white,
        tablecellHeader:
          theme && theme.selectedProducts && theme.selectedProducts.text
            ? theme.selectedProducts.text
            : $gray,
        background:
          theme && theme.selectedProducts && theme.selectedProducts.background
            ? theme.selectedProducts.background
            : $secondary,
        buttonColor: $white,
      },
      products: {
        border:
          theme && theme.products && theme.products.border
            ? theme.products.border
            : "#CCD8E8",
        text:
          theme && theme.products && theme.products.text
            ? theme.products.text
            : $primary,
        background:
          theme && theme.products && theme.products.background
            ? theme.products.background
            : $secondary,
      },
    },
    overrides: {
      MuiListItemIcon: {
        root: {
          color: $white,
          minWidth: "33px",
        },
      },
      MuiListItemText: {
        root: {
          overflow: "hidden",
          whiteSpace: "initial",
        },
        primary: {
          color: $white,
          marginLeft: 5,
        },
      },
      MuiListItem: {
        root: {
          color: $primary,
          fontSize: 14,
          paddingBottom: 3,
          paddingTop: 3,
          borderRadius: "0px 7px 7px 0px",
          borderLeft: "5px solid transparent",
          "&.Mui-selected": {
            borderLeftColor: $blue,
            opacity: 1,
          },
        },
        gutters: {
          paddingLeft: 14,
        },
      },
      MuiButton: {
        root: {
          borderRadius: 7,
          minWidth: "auto",
          textTransform: "capitalize",
          "&:hover": {
            opacity: 0.9,
          },
        },
        contained: {
          boxShadow: "0rem 0.2rem 0.875rem #0621524E",
        },
      },
      MuiFormLabel: {
        root: {
          "& .MuiInputLabel-asterisk": {
            color: "#f44336",
          },
        },
      },
      MuiInputBase: {
        input: {
          color: $primary,
          "&::placeholder": {
            fontSize: "0.875rem",
          },
          "&.Mui-disabled": {
            "-webkit-text-fill-color": "unset !important",
          },
        },
      },
      MuiInput: {
        underline: {
          "&:before": {
            borderBottomColor: $blue,
          },
          "&:hover:not(.Mui-disabled):before": {
            borderBottomColor: $blue,
          },
          "&:after": {
            borderBottomColor: $blue,
          },
        },
      },
      MuiOutlinedInput: {
        root: {
          width: "100%",
          background: $white,
          borderRadius: 7,
          "& .MuiOutlinedInput-notchedOutline": {
            borderWidth: 1,
          },
          "& fieldset": {},
        },
        input: {
          fontWeight: 400,
          "&.MuiInputBase-inputSizeSmall": {
            "&.MuiInputBase-inputAdornedStart": {
              paddingLeft: 0,
            },
          },
        },
        inputAdornedStart: {
          paddingLeft: 4,
        },
      },
      MuiSvgIcon: {},
      MuiFormControl: {
        root: {
          width: "100%",
        },
      },
      MuiSelect: {
        root: {
          color: $primary,
        },
        select: { fontWeight: 600 },
        icon: {
          color: $blue,
        },
      },
      MuiTab: {
        root: {
          borderBottom: "2px solid",
          borderBottomColor: $secondary,
          textTransform: "none",
        },
        textColorInherit: {
          "&.Mui-selected": {
            color: $blue,
            "& .MuiBadge-badge ": {
              backgroundColor: $blue,
            },
          },
          "& .MuiTab-wrapper": {
            flexDirection: "row",
            "& .MuiBadge-root": {
              marginBottom: 0,
              marginRight: 18,
            },
          },
          color: $primary,
          opacity: 1,
        },
      },
      MuiTabs: {
        indicator: {
          backgroundColor: $blue,
        },
        root: {
          "& .MuiTabs-fixed": {
            justifyContent: "center",
            display: "flex",
          },
        },
      },
      MuiDrawer: {
        paper: {
          position: "relative",
        },
      },
    },
  });
};
