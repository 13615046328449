import React, {useEffect, useState} from "react";
import {Typography, Button, InputAdornment, CircularProgress} from "@material-ui/core";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormControl from "@material-ui/core/FormControl";
import Box from "@material-ui/core/Box";
import { useDispatch, useSelector } from "react-redux";
import InputLabel from "@material-ui/core/InputLabel";
import { useStyles } from "../../style";
import { UserLogin } from "../../../../store/login/login.action";
import { useHistory } from "react-router-dom";
import Alert from "@material-ui/lab/Alert";
import { useTranslation } from "react-i18next";
import { GetThemes } from "../../../../store/theme/theme.action";

export const Cashier = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation(["login", "common"]);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const user = useSelector((state) => state.Login.user);

  const notification = useSelector((state) => state.Notification.notification);
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async () => {
    setIsLoading(true);
    await dispatch(UserLogin({ email, password }, history));
  };

  useEffect(async () => {
    if (user) {
      localStorage.setItem("user", JSON.stringify(user));
      dispatch(GetThemes());
    }
  }, [user]);

  return (
    <Box p={2} width={"100%"} mt={2}>
      {notification && notification.code === "error" && (
        <div style={{ marginBottom: 20 }}>
          <Alert severity="error">{notification.message}</Alert>
        </div>
      )}
      <>
        <FormControl variant="outlined" style={{ marginBottom: 20, width: "100%" }}>
          <InputLabel id="demo-simple-select-outlined-label">
            {t("login:login")}
          </InputLabel>
          <OutlinedInput
            startAdornment={
              <InputAdornment position="start">
                <span className="icon-person" />
              </InputAdornment>
            }
            label={t("login:login")}
            id="outlined-adornment-weight"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              "aria-label": "weight",
            }}
            style={{ backgroundColor: "#fff" }}
          />
        </FormControl>
        <FormControl variant="outlined" style={{ marginBottom: 20, width: "100%" }}>
          <InputLabel id="demo-simple-select-outlined-label">
            {t("login:mdp")}
          </InputLabel>
          <OutlinedInput
            startAdornment={
              <InputAdornment position="start">
                <span className="icon-padlock" />
              </InputAdornment>
            }
            id="outlined-adornment-weight"
            type={"password"}
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              "aria-label": "weight",
              style: { fontSize: 14 },
            }}
            label={t("login:mdp")}
            style={{ backgroundColor: "#fff" }}
          />
        </FormControl>
      </>
      <Box>
        <Button
            onClick={handleClick}
            className={classes.button}
            size={"large"}
            variant="contained"
            color="primary"
            disabled={isLoading }
        >
          {t("login:connexion")}
        </Button>
      </Box>
    </Box>
  );
};
