
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
        margin: theme.spacing(1),
        },
    },
    block : {
        backgroundColor: theme.palette.secondary.main,
        padding: 10,
        borderRadius: 19
    },
    section : {
        display : "flex",
        height : "25%"
    },
    Button : {
      color: theme.palette.calculator.text,
      fontWeight: "bold",
      fontSize : "1.8125rem",
      "@media (max-width:780px)": {
        fontSize : "1.125rem",
        },
        
      borderRadius : 0,
      width: "33.33%",
    "&:not(:first-child)": {
        borderLeft: "2px solid",
        borderLeftColor : theme.palette.calculator.border
     }
    },
    border : {
        borderBottom: "2px solid",
        borderBottomColor : theme.palette.calculator.border,
    },
    price : {
        borderBottom: "2px solid",
        borderBottomColor : theme.palette.calculator.border,
        padding : "20px 0px",
    },
    priceSize : {
        width: "100% !important",
        backgroundColor: theme.palette.calculator.backgroundPrice,
        fontWeight: 600
    },
    lastPrice : {
        borderBottomRightRadius: 19
    },
    currency : {
        fontSize : "1.4375rem",
        "@media (max-width:780px)": {
            fontSize : "1.125rem",
        },
        marginLeft : 4,
        fontWeight: 500
    },
    icon : {
        fontSize : 30
    },
    resultCard:{
        marginTop: 15,
        borderRadius: 20,
        padding:"30px 20px",
        color: theme.palette.blue.main,
        fontWeight: "bold",
        fontSize : "1.8125rem",
    },
    keyPadContainer: {
        height: "55%"
    }
}));