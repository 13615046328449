import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Checkbox, IconButton, Menu, ListItem, FormControlLabel, ListItemIcon } from '@material-ui/core';
import { Container, Draggable } from "react-smooth-dnd";
import { arrayMoveImmutable } from "array-move";
import DragHandleIcon from "@material-ui/icons/DragHandle";
import { useSelector, useDispatch } from 'react-redux';
import { updateTableConfig } from '../../store/setting/setting.action';
import { useTranslation } from "react-i18next";

const ITEM_HEIGHT = 48;
const useStyles = makeStyles((theme) => ({
    noWrap: {
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
        '&:hover': {
          overflow: "visible",
        },
    },
    root: {
        display: "flex",
        justifyContent: "flex-end",
        marginBottom: -60,
    },
    nested: {
        height: 40,
        '& span' :{
            fontSize :14,
            color : theme.palette.primary.main,
        },
        position: "flex",
        justifyContent: "space-between"
    },
    dragIconChild: {
      height: 15,
      color: theme.palette.primary.main,
      cursor: "pointer"
    }
}));

export const ColumnCustomizer = ({tableConfig, setData, tableConfigIndex}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const tableConfigs = useSelector((state) => state.Setting.tableConfigs);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const {t} = useTranslation(["common", "product", "inventory", "clients", "transfert"]);


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCheck = (event, index) => {
        var columnsData = [...tableConfigs[tableConfigIndex].columns];
        
        if (event.target.checked) {
            columnsData[index].isInView = 1;
        } else {
            const notAttRows = columnsData?.filter((e) => e.isInView === undefined)?.length;
            if (notAttRows == 0 && columnsData?.filter((e) => e.isInView === 1).length == 1 ) return;
            if (columnsData?.filter((e) => e.isInView === 0).length == columnsData?.length - 1 ) return;
            columnsData[index].isInView = 0;
        }
        const result = {...tableConfigs[tableConfigIndex], columns: columnsData};
        const rest = tableConfigs.filter((el, i) => el.tableName != tableConfigs[tableConfigIndex].tableName);
        dispatch(updateTableConfig([...rest, result]));
    };

    const onDrop = ({ removedIndex, addedIndex }) => {
        const result = {...tableConfigs[tableConfigIndex], columns: arrayMoveImmutable(tableConfigs[tableConfigIndex]?.columns, removedIndex, addedIndex)};
        const rest = tableConfigs.filter((el, i) => el.tableName != tableConfigs[tableConfigIndex].tableName);
        dispatch(updateTableConfig([...rest, result]));
    };

    return (
        <div className={classes.root}>
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                component="div"
                id="long-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: ITEM_HEIGHT * 4.5,
                        width: '30ch',
                    }
                }}
            >
              <Container dragHandleSelector=".drag-handle" lockAxis="y" onDrop={onDrop}>
                {tableConfigs[tableConfigIndex]?.columns?.map((row, index) => (
                  <Draggable key={index}>
                    <ListItem key={index} className={classes.nested}>
                      <FormControlLabel
                          key={index}
                          value={row?.key}
                          control={
                              <Checkbox
                                  value={row?.key} 
                                  onChange={(e) => handleCheck(e, index)}
                                  checked={row?.isInView === 0 ? false : true} 
                                  color={'primary'} 
                              />
                          }
                          label={t(row?.title)}
                          labelPlacement="end"
                      />
                      <ListItemIcon className="drag-handle">
                        <DragHandleIcon className={classes.dragIconChild}/>
                      </ListItemIcon>
                    </ListItem>
                  </Draggable> 
                ))}
              </Container>
            </Menu>
        </div>
    ); 
};