import { makeStyles } from "@material-ui/core/styles";

export const useTableStyles = makeStyles((theme) => ({
  table: {
    borderCollapse: "separate",
    borderSpacing: "0 10px",
  },
  tableRow: {
    boxShadow: "0px 2px 8px #091F461D",
    minHeight: 45,
    borderRadius: 6,
    background: "#fff",
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
    "& .MuiTableCell-root:first-child": {
      borderTopLeftRadius: 6,
      borderBottomLeftRadius: 6,
    },
    "& .MuiTableCell-root:last-child": {
      borderTopRightRadius: 6,
      borderBottomRightRadius: 6,
    },
  },
  tableCell: {
    fontSize: "0.8rem",
    padding: "8px 12px",
    color: theme.palette.primary.main,
  },
  tableCellError: {
    background: "#fff",
    fontSize: "0.8rem",
    padding: "8px 12px",
    color: theme.palette.error.main,
  },
  tableCellMsg: {
    background: "#fff",
    fontSize: "0.8rem",
    padding: "8px 12px",
    color: theme.palette.error.main,
  },
  paper: {
    overflow: "auto",
    padding: "0px 4px",
    backgroundColor: theme.palette.productsTable.background,
    borderRadius: 10,
  },
  tablecellHeader: {
    paddingBottom: 0,
    color: "#657288",
    fontSize: 12,
    borderBottom: "none",
    paddingLeft: 13,
  },
  active: {
    boxShadow: "0px 2px 8px #091F4643",
    fontWeight: 600,
    "& .MuiTableCell-root": {
      fontWeight: 600,
    },
  },
  filterBlock: {
    borderTop: "1px solid #eef0f2",
    borderBottom: "1px solid #eef0f2",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  filter: {
    display: "flex",
    flexWrap: "wrap",
  },
  sort: {
    fontSize: "0.8125rem",
    color: "#042765",
  },
  chip: {
    borderRadius: 6,
    color: "#042765",
    marginTop: 5,
    marginBottom: 5,
    marginRight: 12,
  },
  remove: {
    padding: theme.spacing(0.25),
    color: "#FFF",
    background: theme.palette.error.main,
    fontSize: "0.8125rem",
    borderRadius: 6,
    marginTop: 5,
    marginBottom: 5,
    cursor: "pointer",
  },
  success: {
    color: theme.palette.success.main,
    fontWeight: "bold",
  },
  info: {
    color: theme.palette.info.main,
    fontWeight: "bold",
  },
  error: {
    color: theme.palette.error.main,
    fontWeight: "bold",
  },
  warning: {
    color: theme.palette.warning.main,
    fontWeight: "bold",
  },
}));
