import offlineConfig from "@redux-offline/redux-offline/lib/defaults";
import * as localforage from "localforage";
import { store } from "./configureStore";
import { useTranslation } from "react-i18next";
import axios from "axios";

export const CustomofflineConfig = {
  ...offlineConfig,
  persistOptions: { storage: localforage, blacklist: ["Page", "Notification"] },

  discard: (error, _action, _retries) => {
    if (error?.status) {
      // localforage.getItem("reduxPersist:offline", function (err, value) {
      //   var item = JSON.parse(value);
      //   item.outbox = [];
      //   localforage.setItem('reduxPersist:offline', item );
      // });
      store.dispatch({
        type: "SET_NOTIFICATION",
        notification: {
          code: "error",
          message:
            error.response && error.response.message
              ? error.response.message
                  ?.toString()
                  ?.replace(/<\/?[^>]+(>|$)/g, " ")
              : "Une erreur s'est produite",
        },
      });
    }
    return false;
  },
  retry: (action, retries) => {
    return null;
  },
};
