
import {
  REDIRECT_TO,
  } from "./page.type";
  
export const RedirectTo = (page) => {
    return {
      type: REDIRECT_TO,
      redirectTo: page,
    };
  };