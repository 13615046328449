import axiosInstance from "../history/axiosInstance";

export const GetData = (url, data) => {
  return axiosInstance.post(url, data, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
      Authorization: JSON.parse(localStorage.getItem("user")).token,
    },
  });
};
