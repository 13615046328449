import React, { useEffect } from "react";
import Box from "@material-ui/core/Box";
import { Filter } from "../../../components/filter";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import ClearIcon from "@material-ui/icons/Clear";
import {
  ClickAwayListener,
  Typography,
  Modal,
  Grid,
  InputLabel,
} from "@material-ui/core";
import { TransferRequestsTable } from "../components/table";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import { useTableStyles } from "../../../theme/table.style";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import {
  getTransferRequests,
  LoadMaterialRequestPurchase,
} from "../../../store/transferRequest/transferRequest.action";
import {
  FilterBy,
  GetFilterItems,
  GlobalfilterData,
} from "../../../helpers/helper";
import Pagination from "@material-ui/lab/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { formatDate, statusFormat } from "../../../helpers/utils";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import clsx from "clsx";
import { Loader } from "../../../components/loader";
import { useTranslation } from "react-i18next";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "0.75rem 0px 0.8125rem",
    flexWrap: "wrap",
    "& .MuiInputBase-root": {
      background: theme.palette.white.main,
    },
    "& .MuiOutlinedInput-input": {
      padding: "8.9px 14px",
      fontSize: 12,
    },
  },
  formControl: {
    maxWidth: 300,
    [theme.breakpoints.down("xs")]: {
      marginTop: 10,
    },
  },
  button: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.blue.main,
    border: "1px solid",
    borderColor: theme.palette.blue.main,
    fontSize: "0.875rem",
    borderRadius: 10,
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
      borderColor: theme.palette.blue.main,
    },
  },
  icon: {
    marginRight: "0.375rem",
  },
  link: {
    fontSize: 14,
    color: theme.palette.blue.main,
  },
  paper: {
    height: 500,
    position: "absolute",
    width: 1000,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 10,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  received: {
    fontWeight: 600,
    color: "#45f248",
  },
  pending: {
    fontWeight: 600,
    color: "#ffc921",
  },
  formControlRech: {
    marginBottom: 5,
    "& .MuiInputBase-root": {
      background: theme.palette.white.main,
    },
    "& .MuiOutlinedInput-input": {
      padding: "8.9px 14px",
      fontSize: 12,
    },
  },
}));

export const ListTransferRequest = () => {
  const classes = useStyles();
  const tableclasses = useTableStyles();
  const dispatch = useDispatch();
  const globalDefaults = useSelector((state) => state.Login.globalDefaults);
  const { t } = useTranslation([
    "common",
    "transfert",
    "interfaceCustomization",
  ]);
  const [selectedDate, setSelectedDate] = React.useState(
    moment().format("YYYY-MM-DD")
  );
  const [selectedRequisDate, setSelectedRequisDate] = React.useState(
    moment().format("YYYY-MM-DD")
  );
  const [filtredTransferStock, setFiltredTransferStock] = React.useState([]);
  const [transfertStock, setTransfertStock] = React.useState("");
  const [rows, setRows] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [purchaseRequest, setPurchaseRequest] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [filteredPurchaseTransfert, setFilteredPurchaseTransfert] =
    React.useState([]);
  const selectedData = useSelector((state) => state.Login.selectedData);
  const PurchaseMatReqList = useSelector(
    (state) => state.Transfert.PurchaseMatReqList
  );

  const handleChangeSearchTerm = (event) => {
    setSearchTerm(event.target.value);
    Array.isArray(PurchaseMatReqList) &&
      setFilteredPurchaseTransfert(
        GlobalfilterData(PurchaseMatReqList, event.target.value, [
          "status",
          "name",
          "set_warehouse",
        ])
      );
  };

  const handleSearchTransfertStock = (event) => {
    setTransfertStock(event.target.value);

    const frows = rows?.filter(
      (s) =>
        s?.set_warehouse == selectedData?.store ||
        s?.set_from_warehouse == selectedData?.store
    );
    setFiltredTransferStock(
      GlobalfilterData(frows, event.target.value, [
        "name",
        "title",
        "material_request_type",
        "schedule_date",
        "transaction_date",
      ])
    );
  };

  const setTransferRequests = async () => {
    await getTransferRequests(globalDefaults?.default_company).then(
      (response) => {
        response.data.length > 0 && setRows(response.data);
      }
    );
  };
  const [filters, setFilters] = React.useState({
    status: [],
    set_warehouse: [],
    set_from_warehouse: [],
    transaction_date: [],
    schedule_date: [],
  });

  const HandleFilter = (key, item) => {
    const FilterDataBy = { ...filters, [key]: item };
    setFilters(FilterDataBy);
    const frows = rows?.filter(
      (s) =>
        s?.set_warehouse == selectedData?.store ||
        s?.set_from_warehouse == selectedData?.store
    );
    setFiltredTransferStock(frows.filter(FilterBy(FilterDataBy)));
  };
  const HandleDeleteAll = () => {
    const data = {
      status: [],
      set_warehouse: [],
      set_from_warehouse: [],
      schedule_date: [],
    };
    setSelectedDate(moment().format("YYYY-MM-DD"));
    setFilters(data);
    const frows = rows?.filter(
      (s) =>
        s?.set_warehouse == selectedData?.store ||
        s?.set_from_warehouse == selectedData?.store
    );
    setFiltredTransferStock(frows.filter(FilterBy(data)));
  };

  const HandleCloseChip = (item, key) => {
    const FilterDataBy = {
      ...filters,
      [key]: filters[key].filter((f) => f !== item),
    };
    setFilters(FilterDataBy);
    const frows = rows?.filter(
      (s) =>
        s?.set_warehouse == selectedData?.store ||
        s?.set_from_warehouse == selectedData?.store
    );
    setFiltredTransferStock(frows.filter(FilterBy(FilterDataBy)));
  };

  const handleFilterByDate = (date) => {
    const data = rows.filter((item) => {
      let TransactionDate = moment(item.transaction_date).format("YYYY-MM-DD");
      return TransactionDate == date;
    });
    const fdata = data?.filter(
      (s) =>
        s?.set_warehouse == selectedData?.store ||
        s?.set_from_warehouse == selectedData?.store
    );

    setFiltredTransferStock(fdata);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    if (date && date != "Invalid Date") {
      handleFilterByDate(moment(date).format("YYYY-MM-DD"));
    }
  };

  const handleFilterByRequisDate = (date) => {
    const data = rows.filter((item) => {
      let TransactionDate = moment(item.schedule_date).format("YYYY-MM-DD");
      return TransactionDate == date;
    });
    const fdata = data?.filter(
      (s) =>
        s?.set_warehouse == selectedData?.store ||
        s?.set_from_warehouse == selectedData?.store
    );
    setFiltredTransferStock(fdata);
  };
  const handleDateRequisChange = (date) => {
    setSelectedRequisDate(date);
    if (date && date != "Invalid Date") {
      handleFilterByRequisDate(moment(date).format("YYYY-MM-DD"));
    }
  };
  const initialisation = async () => {
    if (globalDefaults?.default_company) {
      await setTransferRequests();
      setLoading(false);
    }
  };
  useEffect(() => {
    initialisation();
  }, [globalDefaults?.default_company]);

  useEffect(() => {
    const frows = rows?.filter(
      (s) =>
        s?.set_warehouse == selectedData?.store ||
        s?.set_from_warehouse == selectedData?.store
    );
    setFiltredTransferStock(frows);
  }, [rows]);

  const materialRequestPurchase = (
    <div className={classes.paper}>
      <Typography align={"center"} color={"primary"}>
        {t("transfert:DemandeAchat")}
      </Typography>
      <Grid item>
        <InputLabel
          style={{
            textAlign: "left",
            fontSize: 12,
            marginLeft: 5,
            marginBottom: 5,
            marginTop: 10,
          }}
        >
          {t("common:rechercher")}
        </InputLabel>
        <FormControl variant="outlined" className={classes.formControlRech}>
          <OutlinedInput
            autoComplete="off"
            id="outlined-adornment-weight"
            value={searchTerm}
            name="SearchTerm"
            placeholder={t("common:ref")}
            endAdornment={
              <InputAdornment position="end">
                <span className="icon-search" />
              </InputAdornment>
            }
            onChange={handleChangeSearchTerm}
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              "aria-label": "weight",
            }}
            labelWidth={0}
          />
        </FormControl>
      </Grid>
      <Box mt={3} className={tableclasses.paper} style={{ height: 325 }}>
        <Table
          className={clsx(tableclasses.table, classes.selectInput)}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell className={tableclasses.tablecellHeader} align="left">
                {t("common:createDate")}
              </TableCell>
              <TableCell className={tableclasses.tablecellHeader} align="left">
                {t("common:ref")}
              </TableCell>
              <TableCell className={tableclasses.tablecellHeader} align="left">
                {t("common:purpose")}
              </TableCell>
              <TableCell className={tableclasses.tablecellHeader} align="left">
                {t("common:statut")}
              </TableCell>
              <TableCell className={tableclasses.tablecellHeader} align="left">
                {t("transfert:requise")}
              </TableCell>
              <TableCell className={tableclasses.tablecellHeader} align="left">
                {t("transfert:VersMagasin")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {searchTerm == ""
              ? Array.isArray(PurchaseMatReqList) &&
                PurchaseMatReqList.map((row, index1) => (
                  <TableRow className={tableclasses.tableRow} key={index1}>
                    <TableCell className={tableclasses.tableCell} align="left">
                      {formatDate(row.transaction_date, "DD/MM/YYYY")}
                    </TableCell>
                    <TableCell className={tableclasses.tableCell} align="left">
                      {row.name}
                    </TableCell>
                    <TableCell className={tableclasses.tableCell} align="left">
                      {row.title}
                    </TableCell>
                    <TableCell
                      className={
                        row.status == "Received"
                          ? clsx(tableclasses.tableCell, classes.received)
                          : row.status == "Pending"
                          ? clsx(tableclasses.tableCell, classes.pending)
                          : tableclasses.tableCell
                      }
                      align="left"
                    >
                      {row.status}
                    </TableCell>
                    <TableCell className={tableclasses.tableCell} align="left">
                      {formatDate(row.schedule_date, "DD/MM/YYYY")}
                    </TableCell>
                    <TableCell className={tableclasses.tableCell} align="left">
                      {row.set_warehouse}
                    </TableCell>
                  </TableRow>
                ))
              : Array.isArray(filteredPurchaseTransfert) &&
                filteredPurchaseTransfert.map((row, index2) => (
                  <TableRow className={tableclasses.tableRow} key={index2}>
                    <TableCell className={tableclasses.tableCell} align="left">
                      {formatDate(row.transaction_date, "DD/MM/YYYY")}
                    </TableCell>
                    <TableCell className={tableclasses.tableCell} align="left">
                      {row.name}
                    </TableCell>
                    <TableCell className={tableclasses.tableCell} align="left">
                      {row.title}
                    </TableCell>
                    <TableCell
                      className={
                        row.status == "Received"
                          ? clsx(tableclasses.tableCell, classes.received)
                          : row.status == "Pending"
                          ? clsx(tableclasses.tableCell, classes.pending)
                          : tableclasses.tableCell
                      }
                      align="left"
                    >
                      {row.status}
                    </TableCell>
                    <TableCell className={tableclasses.tableCell} align="left">
                      {formatDate(row.schedule_date, "DD/MM/YYYY")}
                    </TableCell>
                    <TableCell className={tableclasses.tableCell} align="left">
                      {row.set_warehouse}
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </Box>
      <Box mt={3} justifyContent="right" display="flex">
        <Button
          color="primary"
          variant="contained"
          onClick={() => setPurchaseRequest(false)}
        >
          {t("common:annuler")}
        </Button>
      </Box>
    </div>
  );

  return (
    <Box>
      <Box className={classes.header}>
        <Link to={"/transfer-request/create"}>
          <Button
            className={classes.button}
            size={"small"}
            variant="outlined"
            color="primary"
          >
            <AddIcon className={classes.icon} /> {t("transfert:AjtDemande")}
          </Button>
        </Link>
        <FormControl className={classes.formControl} variant="outlined">
          <OutlinedInput
            id="outlined-adornment-weight"
            value={transfertStock}
            placeholder={t("common:Rechercher")}
            onChange={handleSearchTransfertStock}
            endAdornment={
              <InputAdornment position="end">
                <span className="icon-search" />
              </InputAdornment>
            }
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              "aria-label": "weight",
            }}
            labelWidth={0}
          />
        </FormControl>
      </Box>
      <Box className={tableclasses.filterBlock}>
        <Box className={tableclasses.filter}>
          <Filter
            data={GetFilterItems([...new Set(rows.map((item) => item.status))])}
            title={t("common:statut")}
            id={"status"}
            HandleFilter={HandleFilter}
            value={filters.status}
            number={filters.status.length}
          />
          <Filter
            data={GetFilterItems([
              ...new Set(rows.map((item) => item.set_warehouse)),
            ])}
            title={t("common:mgsCible")}
            id={"set_warehouse"}
            HandleFilter={HandleFilter}
            value={filters.set_warehouse}
            number={filters.set_warehouse.length}
          />
          <Filter
            data={GetFilterItems([
              ...new Set(rows.map((item) => item.set_from_warehouse)),
            ])}
            title={t("common:mgsSource")}
            id={"set_from_warehouse"}
            HandleFilter={HandleFilter}
            value={filters.set_from_warehouse}
            number={filters.set_from_warehouse.length}
          />
          <Filter
            title={t("common:createDate")}
            id={"transaction_date"}
            HandleFilter={HandleFilter}
            selectedDate={selectedDate}
            setRows={setRows}
            date={true}
            handleDateChange={handleDateChange}
            setSelectedDate={setSelectedDate}
            HandleDeleteAll={HandleDeleteAll}
          />
          <Filter
            title={t("transfert:Daterequise")}
            id={"schedule_date"}
            HandleFilter={HandleFilter}
            selectedDate={selectedRequisDate}
            setRows={setRows}
            date={true}
            handleDateChange={handleDateRequisChange}
            setSelectedDate={setSelectedRequisDate}
            HandleDeleteAll={HandleDeleteAll}
          />
        </Box>
        <Box className={tableclasses.filterBlock}>
          <Box className={tableclasses.filter}>
            <Button
              color="primary"
              variant="text"
              className={classes.link}
              onClick={() => {
                dispatch(
                  LoadMaterialRequestPurchase(globalDefaults?.default_company)
                );
                setPurchaseRequest(true);
              }}
            >
              {t("transfert:DemandeAchat")}
            </Button>
          </Box>
        </Box>
      </Box>
      <Box display="flex" flexWrap="wrap" alignItems="center" mb={1}>
        {filters.status.map((filter) => (
          <Chip
            className={tableclasses.chip}
            label={filter}
            onDelete={() => HandleCloseChip(filter, "status")}
            color="secondary"
            size={"small"}
            deleteIcon={<ClearIcon />}
          />
        ))}
        {filters.set_warehouse.map((filter) => (
          <Chip
            className={tableclasses.chip}
            label={filter}
            onDelete={() => HandleCloseChip(filter, "set_warehouse")}
            color="secondary"
            size={"small"}
            deleteIcon={<ClearIcon />}
          />
        ))}
        {filters.set_from_warehouse.map((filter) => (
          <Chip
            className={tableclasses.chip}
            label={filter}
            onDelete={() => HandleCloseChip(filter, "set_from_warehouse")}
            color="secondary"
            size={"small"}
            deleteIcon={<ClearIcon />}
          />
        ))}
        {(filters.status.length > 0 ||
          filters.set_warehouse.length > 0 ||
          filters.set_from_warehouse.length > 0) && (
          <Typography onClick={HandleDeleteAll} className={tableclasses.remove}>
            {t("common:delete")}
          </Typography>
        )}
      </Box>
      {loading ? (
        <Loader />
      ) : (
        <Box>
          <TransferRequestsTable
            setTransferRequests={setTransferRequests}
            rows={filtredTransferStock.slice((page - 1) * 20, page * 20)}
          />
          {Math.ceil(filtredTransferStock.length / 20) > 0 && (
            <Box display="flex" justifyContent="center" m={4}>
              <Pagination
                onChange={(event, newPage) => setPage(newPage)}
                page={page}
                count={Math.ceil(filtredTransferStock.length / 20)}
                color="primary"
              />
            </Box>
          )}
        </Box>
      )}
      <Modal open={purchaseRequest}>{materialRequestPurchase}</Modal>
    </Box>
  );
};
