import { GlobalfilterData } from "../../helpers/helper";
import { CLEAR_STATE } from "../login/login.type";
import {
  GET_DISCOUNT,
  GET_COMPANY_ACCOUNT,
  GET_COMPANY_INFORMATIONS,
  GET_WAREHOUSE_PARENT,
  GET_TERRITORY_PARENT,
  GET_CUSTOMER_GROUP_PARENT,
  GET_DEFAULT_WAREHOUSE,
  GET_PRICE_LIST,
  GET_DEFAULT_TERRIOTRY,
  GET_DEFAULT_CUSTOMER_GROUP,
  GET_COMPANY_ITEM_GROUP,
  LOAD_RETURN_REASONS,
  GET_BUYING_PRICE_LIST,
  LOAD_MOTIF_REASONS,
} from "./configuration.type";

const initialState = {
  configurations: [],
  discounts: [],
  companyacounts: null,
  companyinformations: null,
  warehouseparent: "",
  territoryparent: "",
  customergroupparent: "",
  defaultwarehouse: "",
  pricelist: "",
  buyingpricelist: "",
  defaultterriotry: "",
  defaultcustomergroup: "",
  returnReason: [],
  motifInventory: [],
  itemgroup: "",
};

const reducer = (state = initialState, action) => {
  var configurations = state.configurations;

  switch (action.type) {
    case GET_DISCOUNT:
      return {
        ...state,
        discounts: action.payload,
      };
    case GET_COMPANY_ACCOUNT:
      return {
        ...state,
        companyacounts: action.payload,
      };
    case GET_COMPANY_INFORMATIONS:
      return {
        ...state,
        companyinformations: action.payload,
      };
    case GET_WAREHOUSE_PARENT:
      return {
        ...state,
        warehouseparent: action.payload,
      };
    case GET_TERRITORY_PARENT:
      return {
        ...state,
        territoryparent: action.payload,
      };
    case GET_CUSTOMER_GROUP_PARENT:
      return {
        ...state,
        customergroupparent: action.payload,
      };
    case GET_DEFAULT_WAREHOUSE:
      return {
        ...state,
        defaultwarehouse: action.payload,
      };
    case GET_PRICE_LIST:
      return {
        ...state,
        pricelist: action.payload,
      };
    case GET_BUYING_PRICE_LIST:
      return {
        ...state,
        buyingpricelist: action.payload,
      };
    case GET_DEFAULT_TERRIOTRY:
      return {
        ...state,
        defaultterriotry: action.payload,
      };
    case GET_DEFAULT_CUSTOMER_GROUP:
      return {
        ...state,
        defaultcustomergroup: action.payload,
      };
    case GET_COMPANY_ITEM_GROUP:
      return {
        ...state,
        itemgroup: action.payload,
      };
    case LOAD_RETURN_REASONS:
      return {
        ...state,
        returnReason: action.payload,
      };
    case LOAD_MOTIF_REASONS:
      return {
        ...state,
        motifInventory: action.payload,
      };
    case CLEAR_STATE:
      return (state = initialState);
    default:
      return state;
  }
};

export default reducer;
