import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { Grid, makeStyles } from "@material-ui/core";
import Barcode from "react-barcode";
import { useTranslation } from "react-i18next";
import { removeHTML } from "../../../helpers/utils";

const useStyles = makeStyles((theme) => ({
  main: {
    padding: "0px 50px",
    fontSize: 12,
  },
  header: {
    padding: "60px 0",
    marginBottom: 20,
  },
  logo: {
    textAlign: "center",
    marginBottom: 10,
  },
  title: {
    borderTop: "1px solid  #5D6975",
    borderBottom: "1px solid  #5D6975",
    color: "#5D6975",
    fontSize: "30px",
    lineHeight: "1.4em",
    fontWeight: "normal",
    textAlign: "center",
    margin: "0 0 20px 0",
  },
  project: {
    float: "left",
    marginTop: 30,
    marginBottom: 60,
  },
  table: {
    width: "100%",
  },
  tableheader: {
    padding: "5px 5px",
    background: "#E5E5E5",
    border: "1px solid #C1CED9",
    fontWeight: "normal",
  },
  tableColumn: {
    padding: 10,
    textAlign: "right",
    border: "1px solid #C1CED9",
  },
  ref: {
    width: "60%",
    marginTop: 5,
  },
  headtable: {
    border: " 1px solid #C1CED9 ",
    background: "#E5E5E5",
    fontWeight: "normal",
  },
  columntable: {
    padding: 10,
    textAlign: "center",
    border: "1px solid #C1CED9",
  },
  pay: {
    width: "60%",
    display: "inline-table",
    tableLayout: "fixed",
  },
  comp: {
    width: "80%",
  },
  address: {
    paddingTop: "40px",
  },
  item: {
    color: "#5D6975",
    textAlign: "left",
    width: "52px",
    fontSize: "0.8em",
  },
  company: {
    float: "left",
    marginTop: 30,
    marginBottom: 60,
  },
  float: {
    width: "40%",
    height: "50%",
    marginRight: -60,
    textAlign: "left",
  },
}));

const PrintInvoiceTemplate = React.forwardRef(
  ({ doc, customerDetails, isDeliveryNote, docinfo, barcodeValue }, ref) => {
    const classes = useStyles();
    const { t } = useTranslation([
      "common",
      "product",
      "order",
      "clients",
      "transfert",
    ]);
    const companyinformations = useSelector(
      (state) => state.Configuration.companyinformations
    );

    const activeImageTheme = useSelector((state) => state.Theme.image);
    const validatetaxtable = () => {
      let res = false;
      if (doc?.taxes) console.log(doc.items);
      doc?.taxes?.forEach((element) => {
        if (element?.tax_amount > 0) {
          res = true;
        }
      });
      return res;
    };
    return (
      <div className={classes.main} ref={ref}>
        <header className={classes.header}>
          <div className={classes.logo}>
            <img src={activeImageTheme} />
          </div>
          <h1 className={classes.title}>
            {doc?.doctype === "Sales Invoice" && doc?.status != "Partly Paid"
              ? "Tax Invoice"
              : doc?.doctype === "Sales Invoice" &&
                doc?.status === "Partly Paid"
              ? "Deposit Invoice"
              : doc?.doctype === "Delivery Note"
              ? t("order:bonLivraison")
              : doc?.doctype}
            {": "}
            {doc?.name}
          </h1>
          <Grid container spacing={2}>
            <Grid item style={{ width: "70%" }}>
              <div>
                <strong>
                  {t("common:client")} {": "}
                </strong>
                {customerDetails?.customer_type
                  ? customerDetails?.salutation
                    ? customerDetails?.salutation + ". "
                    : "Mrs. "
                  : " "}
                {doc?.customer_name}
              </div>
              <div>
                <strong>
                  {t("common:addr")}
                  {": "}
                </strong>
                {customerDetails?.primary_address
                  ? customerDetails?.primary_address
                  : doc?.address_display
                  ? doc?.address_display
                  : " -"}
              </div>
              <div>
                <strong>
                  {t("common:phone")} {": "}
                </strong>
                {doc?.contact_mobile ?? " -"}
              </div>
              <div>
                <strong>
                  {t("common:email")} {": "}
                </strong>
                {customerDetails?.email_id ?? " -"}
              </div>
            </Grid>
            <Grid item style={{ width: "30%" }}>
              {doc?.company && <div>{doc?.company}</div>}
              {companyinformations?.phone_no && (
                <div>{companyinformations?.phone_no}</div>
              )}
              {companyinformations?.email && (
                <div>{companyinformations?.email}</div>
              )}
              {companyinformations?.country && (
                <div>{companyinformations?.country}</div>
              )}
              {companyinformations?.website && (
                <div>{companyinformations?.website}</div>
              )}
            </Grid>
          </Grid>
        </header>
        <Grid
          style={{ width: "100%" }}
          container
          direction="row"
          alignItems="center"
          spacing={2}
        >
          <Grid item style={{ width: "100%" }}>
            <p
              style={{
                margin: 0,
                lineHeight: 1.5,
                fontWeight: "bold",
                fontSize: 15,
              }}
            >
              {t("common:produits")}
            </p>
            <table className={classes.table}>
              <thead>
                <tr>
                  <th className={classes.tableheader}>{t("common:ref")}</th>
                  <th className={classes.tableheader}>{t("common:name")}</th>
                  <th className={classes.tableheader}>P.U</th>
                  <th className={classes.tableheader}>
                    {t("common:Taxation")}
                  </th>
                  <th className={classes.tableheader}>
                    {t("common:remise")} (%)
                  </th>
                  <th className={classes.tableheader}>{t("common:qty")}</th>
                  <th className={classes.tableheader}>{t("common:total")}</th>
                </tr>
              </thead>
              <tbody>
                {doc?.items &&
                  doc?.items?.map((row, index) => [
                    <tr key={index}>
                      <td
                        className={classes.tableColumn}
                        style={{ textAlign: "left" }}
                      >
                        {row?.item_code}
                      </td>
                      <td
                        className={classes.tableColumn}
                        style={{ textAlign: "left" }}
                      >
                        {row?.item_name}
                      </td>
                      <td
                        className={classes.tableColumn}
                        style={{ textAlign: "left" }}
                      >
                        {row?.price_list_rate?.toFixed(3)} {doc?.currency}
                      </td>
                      <td
                        className={classes.tableColumn}
                        style={{ textAlign: "left" }}
                      >
                        {row?.item_tax_rate != "" &&
                          Object.keys(JSON.parse(row?.item_tax_rate))}
                      </td>
                      <td
                        className={classes.tableColumn}
                        style={{ textAlign: "left" }}
                      >
                        {row?.discount_amount?.toFixed(3)} {doc?.currency}
                      </td>
                      <td
                        className={classes.tableColumn}
                        style={{ textAlign: "left" }}
                      >
                        {row?.qty}
                      </td>
                      <td
                        className={classes.tableColumn}
                        style={{ textAlign: "left" }}
                      >
                        {row?.amount.toFixed(3)} {doc?.currency}
                      </td>
                    </tr>,
                  ])}
              </tbody>
            </table>
          </Grid>
          {validatetaxtable() && (
            <Grid item style={{ width: "100%" }}>
              <p
                style={{
                  margin: 0,
                  lineHeight: 1.5,
                  fontWeight: "bold",
                  fontSize: 15,
                }}
              >
                {t("common:supplementaryInformation")}
              </p>
              <br />
              <table className={classes.table}>
                <thead>
                  <tr>
                    <th className={classes.tableheader}>{t("common:titre")}</th>
                    <th className={classes.tableheader}>
                      {t("common:montant")}
                    </th>
                  </tr>
                </thead>
                <tbody className={classes.tableBody}>
                  {doc?.taxes &&
                    doc?.taxes?.map((row, index) => [
                      row?.tax_amount > 0 && (
                        <tr key={index} className={classes.tableRow}>
                          <td
                            className={classes.tableColumn}
                            style={{ textAlign: "left" }}
                          >
                            {row?.account_head}
                          </td>
                          <td
                            className={classes.tableColumn}
                            style={{ textAlign: "left" }}
                          >
                            {row?.tax_amount?.toFixed(3)}
                          </td>
                        </tr>
                      ),
                    ])}
                </tbody>
              </table>
            </Grid>
          )}
          <Grid item style={{ width: "100%" }}>
            <p
              style={{
                margin: 0,
                lineHeight: 1.5,
                fontWeight: "bold",
                fontSize: 15,
              }}
            >
              {t("common:MontTotal")}: {doc?.base_grand_total?.toFixed(3)}
            </p>
          </Grid>
          <Grid item style={{ width: "100%" }}>
            <Barcode width={1} value={barcodeValue} />
          </Grid>
        </Grid>
      </div>
    );
  }
);

export default PrintInvoiceTemplate;
