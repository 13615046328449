import React from "react";
import { useStyles } from "../../style";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import { Chip, Typography } from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../../store/login/login.action";
import { UpdateThemes } from "../../../store/theme/theme.action";
import AlertModal from "../../../components/common/alertModal";
import { useTranslation } from "react-i18next";
import { timeout } from "../../../helpers/utils";

export const User = (props) => {
  const user = useSelector((state) => state.Login.user);
  const openSession = useSelector((state) => state.Pos.open);
  const userDetails = useSelector((state) => state.Login.userDetails);
  const selectedData = useSelector((state) => state.Login.selectedData);
  const classes = useStyles();
  const { t } = useTranslation(["common"]);
  let history = useHistory();
  const dispatch = useDispatch();
  const [open2, setOpen2] = React.useState(false);
  const themes = useSelector((state) => state.Theme.themes);

  const defaultTheme = () => {
    const lastThemeIndex = themes.findIndex((obj) => obj.active === 1);
    const newThemeIndex = 0;
    let theme = themes.find((s) => s.theme_id == newThemeIndex);
    themes[lastThemeIndex].active = 0;
    theme.active = 1;
    themes[newThemeIndex] = theme;
    setTimeout(() => {
      dispatch(UpdateThemes(themes));
    }, 3000);
  };

  const logoutHandler = async () => {
    if (user.role == "Admin") {
      await dispatch(logout());
      await timeout(2000);
      await defaultTheme();
    } else {
      if (openSession !== null) {
        setOpen2(true);
      } else {
        await dispatch(logout());
        await timeout(2000);
        history.push("/login");
      }
    }
  };

  const redirection = () => {
    history.push("/session");
    setOpen2(false);
  };

  return (
    <div
      style={!props.show ? { backgroundColor: "transparent" } : {}}
      className={classes.userRoot}
    >
      <Avatar
        src={`${process.env.REACT_APP_IMAGE_URL}` + userDetails?.user_image}
        className={!props.show ? classes.avatarClose : classes.avatarOpen}
      >
        {user && !userDetails?.user_image && user.full_name.charAt(0)}
      </Avatar>

      {props.show && (
        <Box display="flex" flexDirection={"column"} align={"center"}>
          <Typography className={classes.user}>
            {user && user.full_name}
          </Typography>
          <Chip
            label={
              user?.role == "Cashier"
                ? t("common:cashier")
                : t("common:manager")
            }
            className={
              user?.role == "Cashier"
                ? classes.cashierChip
                : classes.managerChip
            }
          />
          <Typography className={classes.cashier}>
            {openSession ? openSession.pos_profile : selectedData?.store}
          </Typography>
          <Box>
            <ExitToAppIcon
              className={classes.cursorIcon}
              fontSize="small"
              onClick={logoutHandler}
            />
          </Box>
        </Box>
      )}
      <AlertModal
        title={t("common:discErrorMsg")}
        openModal={open2}
        setOpenModal={setOpen2}
        handleCancel={redirection}
      />
    </div>
  );
};
