import React from 'react';
import RemoveFromQueueIcon from '@material-ui/icons/RemoveFromQueue';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
    },
    gooey : {
        display: "flex",
        justifyContent: "center",
        padding: theme.spacing(1),
    },
    icon: {
        color: theme.palette.productsTable.background,
        height: 200,
        width: 200
    },
    iconWhite: {
        color: "white",
        height: 200,
        width: 200
    },
    text: {
        display: "flex", 
        justifyContent: "center"
    }
}));

export const Empty = ({text, hasPaper}) => {
    const Fragment = React.Fragment;
    const classes = useStyles();
    return (
        <Box className={classes.root}>
            <div className={classes.gooey}>
                <RemoveFromQueueIcon className={hasPaper ? classes.iconWhite : classes.icon} />
            </div>
            <Typography color={"primary"} className={classes.text} >{text ?? "AUCUNE DONNÉE TROUVÉE"}</Typography>
        </Box>
    )
}