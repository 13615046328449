import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { ProductItem } from "../../components/productItem";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import { useSelector, useDispatch } from "react-redux";
import FormControl from "@material-ui/core/FormControl";
import {
  Box,
  Dialog,
  DialogContentText,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import {
  GetFiltredProducts,
  CreateProduct,
} from "../../store/product/product.action";
import clsx from "clsx";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import {
  Typography,
  Modal,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stepper,
  Step,
  StepLabel,
  IconButton,
} from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { useTranslation } from "react-i18next";
import DialogModal from "../../components/common/formModal/DialogModalStepper";
import FormInput from "../../components/common/formInput";
import Pagination from "@material-ui/lab/Pagination";
import { GlobalfilterData } from "../../helpers/helper";
import CachedIcon from "@material-ui/icons/Cached";
import * as XLSX from "xlsx";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Box display="flex" flexWrap="wrap">
            {children}
          </Box>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  productItem: {
    marginBottom: "1.75rem",
    width: 220,
  },
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 10,
    whiteSpace: "nowrap",
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    "& > *": {
      margin: theme.spacing(1),
    },
    flexWrap: "wrap",
    "& .MuiInputBase-root": {
      background: theme.palette.white.main,
    },
    "& .MuiOutlinedInput-input": {
      fontSize: 12,
    },
  },
  formControl: {
    maxWidth: 300,
    [theme.breakpoints.down("xs")]: {
      marginTop: 10,
    },
  },
  collection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    margin: "0.75rem 0px 0.8125rem",
    flexWrap: "wrap",
    "& .MuiInputBase-root": {
      background: theme.palette.white.main,
    },
    "& .MuiOutlinedInput-input": {
      padding: "8.9px 14px",
      fontSize: 12,
    },
  },
  paper: {
    width: "auto",
    height: "auto",
    maxHeight: "90%",
    maxWidth: "90vw",
    textAlign: "center",
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 10,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  button: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.blue.main,
    border: "1px solid",
    borderColor: theme.palette.blue.main,
    fontSize: "0.875rem",
    borderRadius: 10,
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
      borderColor: theme.palette.blue.main,
    },
  },
  icon: {
    marginRight: "0.375rem",
  },
  formBlock: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
    },
  },
  firstCol: {
    [theme.breakpoints.up("md")]: {
      marginRight: 37,
    },
  },
  label: {
    fontSize: 12,
    marginBottom: 6,
    color: theme.palette.gray.main,
    paddingLeft: 9,
  },
  modalFormControl: {
    marginBottom: 15,
    "& .MuiInputBase-root": {
      background: theme.palette.white.main,
    },
    "& .MuiOutlinedInput-input": {
      padding: "8.9px 14px",
      fontSize: 12,
    },
  },
  roott: {
    width: "100%",
    height: 545,
  },
  buttonn: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export const Products = () => {
  const classes = useStyles();
  const { t } = useTranslation(["product", "checkout", "common"]);
  const [value, setValue] = React.useState(0);
  const [searchProduct, setSearchProduct] = React.useState("");
  const [selectedCategory, setSelectedCategory] = React.useState(null);
  const [products, setProducts] = React.useState([]);
  const selectedData = useSelector((state) => state.Login.selectedData);
  const pricelist = useSelector((state) => state.Configuration.pricelist);
  const defaultwarehouse = useSelector(
    (state) => state.Configuration.defaultwarehouse
  );
  const companyacounts = useSelector(
    (state) => state.Configuration.companyacounts
  );
  const warehouseparent = useSelector(
    (state) => state.Configuration.warehouseparent
  );

  const categories = useSelector((state) => state.Product.categories);
  const itemCollection = useSelector((state) => state.Product.itemCollection);
  const itemTaxList = useSelector((state) => state.Product.itemTax);
  const filtredProducts = useSelector((state) => state.Product.filtredProducts);
  const globalDefaults = useSelector((state) => state.Login.globalDefaults);
  const user = useSelector((state) => state.Login.user);
  const max_elemnts = 20;
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = React.useState(true);
  const [page, setPage] = React.useState(1);
  const [open, setOpen] = useState(false);

  const [state, setState] = React.useState({
    item_code: "",
    item_group: "",
    item_name: "",
    valuation_rate: 0,
    opening_stock: 0,
    standard_rate: 0,
    weight_per_unit: 0,
    warehouse_reorder_level: 0,
    warehouse_reorder_qty: 0,
    item_tax_template: "",
    item_collection: "",
  });

  const [error, setError] = React.useState({
    item_code: false,
    item_group: false,
    item_name: false,
    valuation_rate: false,
    opening_stock: false,
    standard_rate: false,
    item_tax_template: false,
  });
  const handleFormChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
    setError({ ...error, [event.target.name]: false });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const SearchProduct = (event) => {
    setSearchProduct(event.target.value);
    const data = GlobalfilterData(filtredProducts, event.target.value, [
      "name",
      "new_item_code",
      "description",
      "standard_rate",
    ]);
    setProducts(data);
  };
  const handleSelectedCategoty = (data) => {
    setPage(1);
    setSelectedCategory(data);
    setSearchProduct("");
    dispatch(GetFiltredProducts(false, data, ""));
  };
  useEffect(() => {
    dispatch(GetFiltredProducts(true, selectedCategory, searchProduct));
  }, []);
  useEffect(() => {
    setProducts(filtredProducts);
  }, [filtredProducts]);
  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
    setState({
      item_code: "",
      item_group: "",
      item_name: "",
      valuation_rate: 0,
      opening_stock: 0,
      standard_rate: 0,
      weight_per_unit: 0,
      warehouse_reorder_level: 0,
      warehouse_reorder_qty: 0,
      item_tax_template: "",
    });
    setError({
      item_code: false,
      item_group: false,
      item_name: false,
      valuation_rate: false,
      opening_stock: false,
      standard_rate: false,
      item_tax_template: false,
    });
    setActiveStep(0);
  };

  const handleNextStep = () => {
    var isValid = true;
    if (
      state.item_code === "" &&
      state.item_name === "" &&
      state.item_group === "" &&
      state.item_tax_template === "" &&
      (state.opening_stock === 0 || state.opening_stock === "") &&
      (state.standard_rate === 0 || state.standard_rate === "") &&
      (state.valuation_rate === 0 || state.valuation_rate === "")
    ) {
      setError({
        ...error,
        ["item_code"]: true,
        ["item_name"]: true,
        ["item_group"]: true,
        ["item_tax_template"]: true,
        ["opening_stock"]: true,
        ["standard_rate"]: true,
        ["valuation_rate"]: true,
      });
      isValid = false;
    } else {
      if (state.item_code === "") {
        setError({ ...error, ["item_code"]: true });
        isValid = false;
      }
      if (state.item_name === "") {
        setError({ ...error, ["item_name"]: true });
        isValid = false;
      }
      if (state.item_group === "") {
        setError({ ...error, ["item_group"]: true });
        isValid = false;
      }
      if (state.item_tax_template === "") {
        setError({ ...error, ["item_tax_template"]: true });
        isValid = false;
      }
      if (state.opening_stock == 0 || state.opening_stock == "") {
        setError({ ...error, ["opening_stock"]: true });
        isValid = false;
      }
      if (state.standard_rate == 0 || state.standard_rate == "") {
        setError({ ...error, ["standard_rate"]: true });
        isValid = false;
      }
      if (state.valuation_rate == 0 || state.valuation_rate == "") {
        setError({ ...error, ["valuation_rate"]: true });
        isValid = false;
      }
    }
    if (isValid) {
      handleNext();
    }
  };

  const handleSubmit = async () => {
    const data = {
      docstatus: 0,
      doctype: "Item",
      owner: "Administrator",
      naming_series: "STO-ITEM-.YYYY.-",
      stock_uom: "Nos",
      disabled: 0,
      allow_alternative_item: 0,
      is_stock_item: 1,
      sync_with_shopify: 1,
      sync_qty_with_shopify: 1,
      include_item_in_manufacturing: 1,
      is_fixed_asset: 0,
      auto_create_assets: 0,
      end_of_life: "2099-12-31",
      default_material_request_type: "Purchase",
      valuation_method: "",
      has_batch_no: 0,
      create_new_batch: 0,
      has_expiry_date: 0,
      retain_sample: 0,
      has_serial_no: 0,
      has_variants: 0,
      variant_based_on: "Item Attribute",
      is_purchase_item: 1,
      min_order_qty: 0,
      is_customer_provided_item: 0,
      delivered_by_supplier: 0,
      country_of_origin: globalDefaults?.country,
      is_sales_item: 1,
      grant_commission: 1,
      enable_deferred_revenue: 0,
      enable_deferred_expense: 0,
      inspection_required_before_purchase: 0,
      inspection_required_before_delivery: 0,
      is_sub_contracted_item: 0,
      publish_in_hub: 0,
      synced_with_hub: 0,
      published_in_website: 0,
      item_template: "",
      item_code: state.item_code,
      item_group: state.item_group,
      create_variant: 0,
      item_name: state.item_name,
      valuation_rate: parseFloat(state.valuation_rate),
      opening_stock: parseInt(state.opening_stock),
      standard_rate: parseFloat(state.standard_rate),
      weight_per_unit: parseFloat(state.weight_per_unit),
      item_collection: state.item_collection,
      item_defaults: [
        {
          company: `${globalDefaults?.default_company}`,
          docstatus: 0,
          doctype: "Item Default",
          default_warehouse: `${defaultwarehouse}`,
          parenttype: "Item",
          parentfield: "item_defaults",
          default_price_list: `${pricelist}`,
        },
      ],
      weight_uom: "Kg",
      reorder_levels: [
        {
          docstatus: 0,
          doctype: "Item Reorder",
          material_request_type: "Purchase",
          parentfield: "reorder_levels",
          parenttype: "Item",
          warehouse_group: `${warehouseparent}`,
          warehouse: `${defaultwarehouse}`,
          warehouse_reorder_level: parseInt(state.warehouse_reorder_level),
          warehouse_reorder_qty: parseInt(state.warehouse_reorder_qty),
        },
      ],
      uoms: [
        {
          docstatus: 0,
          doctype: "UOM Conversion Detail",
          parentfield: "uoms",
          parenttype: "Item",
          uom: "Nos",
          conversion_factor: 1,
        },
      ],
      taxes: [
        {
          docstatus: 0,
          doctype: "Item Tax",
          parentfield: "taxes",
          parenttype: "Item",
          item_tax_template: state.item_tax_template,
          minimum_net_rate: 0,
          maximum_net_rate: 0,
        },
      ],
    };
    await dispatch(CreateProduct(data));
    handleCloseModal();
  };

  function getSteps() {
    return ["Fondamentaux", "Supplémentaires"];
  }

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <Box mt={3} align={"left"} className={classes.formBlock}>
            <Grid className={classes.firstCol} item xs={12} md={6}>
              <FormInput
                label={t("product:itemcode")}
                required={true}
                value={state.item_code}
                error={error.item_code}
                name="item_code"
                handleChange={handleFormChange}
              />
              <InputLabel
                style={{ display: "flex", justifyContent: "left" }}
                error={error.item_group}
                required
                className={classes.label}
              >
                {t("product:itemgroup")}
              </InputLabel>
              <FormControl
                variant="outlined"
                className={classes.modalFormControl}
              >
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  className={classes.select}
                  style={{ textAlign: "left" }}
                  value={state.item_group}
                  error={error.item_group}
                  name="item_group"
                  IconComponent={ExpandMore}
                  onChange={handleFormChange}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  {Array.isArray(categories) &&
                    categories.map((c, i) => (
                      <MenuItem
                        className={classes.label}
                        value={c.value}
                        key={i}
                      >
                        {c.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormInput
                label={t("product:itemname")}
                required={true}
                value={state.item_name}
                error={error.item_name}
                name="item_name"
                handleChange={handleFormChange}
              />
              <FormInput
                label={
                  t("product:valuationrate") +
                  " " +
                  companyacounts?.default_currency
                }
                messagehelpe={
                  t("product:valuationrate") + t("common:msghelpegreater") + "0"
                }
                required={true}
                value={state.valuation_rate}
                error={error.valuation_rate}
                name="valuation_rate"
                handleChange={handleFormChange}
                type="number"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormInput
                label={t("product:stockInitial")}
                messagehelpe={
                  t("product:openingstock") + t("common:msghelpegreater") + "0"
                }
                required={true}
                value={state.opening_stock}
                error={error.opening_stock}
                name="opening_stock"
                handleChange={handleFormChange}
                type="number"
              />
              <FormInput
                label={
                  t("product:standardrate") +
                  " " +
                  companyacounts?.default_currency
                }
                messagehelpe={
                  t("product:standardrate") + t("common:msghelpegreater") + "0"
                }
                required={true}
                value={state.standard_rate}
                error={error.standard_rate}
                name="standard_rate"
                handleChange={handleFormChange}
                type="number"
              />
              <InputLabel
                style={{ display: "flex", justifyContent: "left" }}
                error={error.item_tax_template}
                required
                className={classes.label}
              >
                {t("product:itemtaxtemplate")}
              </InputLabel>
              <FormControl
                variant="outlined"
                className={classes.modalFormControl}
              >
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  className={classes.select}
                  style={{ textAlign: "left" }}
                  value={state.item_tax_template}
                  error={error.item_tax_template}
                  name="item_tax_template"
                  IconComponent={ExpandMore}
                  onChange={handleFormChange}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  {Array.isArray(itemTaxList) &&
                    itemTaxList.map((c, i) => (
                      <MenuItem
                        className={classes.label}
                        value={c.name}
                        key={i}
                      >
                        {c.title}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          </Box>
        );
      case 1:
        return (
          <Box mt={3} align={"left"} className={classes.formBlock}>
            <Grid className={classes.firstCol} item xs={12} md={6}>
              <InputLabel
                style={{ display: "flex", justifyContent: "left" }}
                className={classes.label}
              >
                {t("product:itemcollection")}
              </InputLabel>
              <FormControl
                variant="outlined"
                className={classes.modalFormControl}
              >
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  className={classes.select}
                  style={{ textAlign: "left" }}
                  value={state.item_collection}
                  name="item_collection"
                  IconComponent={ExpandMore}
                  onChange={handleFormChange}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  {Array.isArray(itemCollection) &&
                    itemCollection.map((c, i) => (
                      <MenuItem
                        className={classes.label}
                        value={c.name}
                        key={i}
                      >
                        {c.collection_name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormInput
                label={t("product:warehousereorderqty")}
                value={state.warehouse_reorder_qty}
                name="warehouse_reorder_qty"
                handleChange={handleFormChange}
                type="number"
              />
              <FormInput
                label={t("product:warehousereorderlevel")}
                value={state.warehouse_reorder_level}
                name="warehouse_reorder_level"
                handleChange={handleFormChange}
                type="number"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormInput
                label={t("product:weightperunit")}
                value={state.weight_per_unit}
                name="weight_per_unit"
                handleChange={handleFormChange}
                type="number"
              />
              <FormInput
                disabled={true}
                label={t("product:defaultdepot")}
                value={`${defaultwarehouse}`}
                name="default_warehouse"
                handleChange={handleFormChange}
              />
            </Grid>
          </Box>
        );
      default:
        return null;
    }
  }

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const steps = getSteps();

  const handleNext = () => {
    let newSkipped = skipped;

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleOnExport = () => {
    var wb = XLSX.utils.book_new();
    var ws = XLSX.utils.json_to_sheet(products)
    XLSX.utils.book_append_sheet(wb, ws, "sheet1")
    XLSX.writeFile(wb, "myfile.xlsx")
  }

  return (
    <div className={classes.root}>
      <Box
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Box className={classes.header}>
          {/* {(user && user.role === "Admin") && <Button
            onClick={handleOpenModal}
            className={classes.button}
            size={"small"}
            variant="outlined"
            color="primary"
          >
            {t("product:ajtProduit")}
          </Button>} */}
          <TextField
            id="outlined-adornment-weight"
            value={searchProduct}
            placeholder={t("common:rechercher")}
            onChange={SearchProduct}
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              "aria-label": "weight",
            }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.push("/product/product-bundle")}
          >
            {t("product:produitNomenclatures")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.push("/product/product-collection")}
          >
            {t("common:collections")}
          </Button>
          <Button
              variant="contained"
              color="primary"
              onClick={handleOnExport}
          >
            {t("Exporter")}
          </Button>
          {user?.role?.toLowerCase() === "admin" && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => history.push("/stock")}
              style={{
                width: "150px",
              }}
              startIcon={<CachedIcon />}
            >
              {t("common:stock")}
            </Button>
          )}
        </Box>
      </Box>
      <Box style={{ paddingTop: 10 }}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab
            key={1}
            onClick={() => handleSelectedCategoty(null)}
            label={t("common:allCategories")}
            {...a11yProps(1)}
          />
          {categories.map((c, index) => (
            <Tab
              onClick={() => handleSelectedCategoty(c?.name)}
              key={index + 2}
              label={c?.name}
              {...a11yProps(0)}
            />
          ))}
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Box
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: 20,
          }}
        >
          {products
            ?.slice((page - 1) * max_elemnts, page * max_elemnts)
            ?.map((p, ligne) => (
              <ProductItem product={p} key={ligne} />
            ))}
        </Box>
      </TabPanel>

      {categories.map((c, index) => (
        <TabPanel value={value} index={index + 1} key={index}>
          <Box
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: 20,
            }}
          >
            {products
              ?.slice((page - 1) * max_elemnts, page * max_elemnts)
              ?.map((p, ligne) => (
                <ProductItem product={p} key={ligne} />
              ))}
          </Box>
        </TabPanel>
      ))}
      {Math.ceil(products?.length / max_elemnts) > 0 && (
        <Box display="flex" justifyContent="center" m={4}>
          <Pagination
            onChange={(event, newPage) => setPage(newPage)}
            page={page}
            count={Math.ceil(products?.length / max_elemnts)}
            color="primary"
          />
        </Box>
      )}
      <DialogModal
        handleClose={handleCloseModal}
        open={open}
        title={t("product:AddNProd")}
        getStepContent={getStepContent}
        activeStep={activeStep}
        steps={steps}
        handleBack={handleBack}
        handleNextStep={handleNextStep}
        handleSubmit={handleSubmit}
      />
    </div>
  );
};
