import React, { useEffect } from 'react'
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import AddIcon from "@material-ui/icons/Add";
import { InterfaceCardItem } from "./components/interfaceCardItem";
import { useSelector, useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import { GlobalfilterData } from '../../helpers/helper';
const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "0.75rem 0px 0.8125rem",
    flexWrap: "wrap",
    "& .MuiInputBase-root": {
      background: theme.palette.white.main,
    },
    "& .MuiOutlinedInput-input": {
      padding: "8.9px 14px",
      fontSize: 12,
    },
  },
  formControl: {
    maxWidth: 300,
    [theme.breakpoints.down("xs")]: {
      marginTop: 10,
    },
  },
  button: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.blue.main,
    border: "1px solid",
    borderColor: theme.palette.blue.main,
    fontSize: "0.875rem",
    borderRadius: 10,
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
      borderColor: theme.palette.blue.main,
    },
  },

  icon: {
    marginRight: "0.375rem",
  },
}));

export const Interfaces = () => {

  const dispatch = useDispatch();
  const classes = useStyles();
  const themes = useSelector((state) => state.Theme.themes);
  const { t } = useTranslation(["common"]);
  const [filtredThemes, setFiltredThemes] = React.useState([]);
  const [searchTheme, setSearchTheme] = React.useState("");
  useEffect(() => {
    setFiltredThemes(themes)
  }, [themes]);
  const HandleSearchs = (event) => {
    setSearchTheme(event.target.value);
    try {
      setFiltredThemes(GlobalfilterData(themes, event.target.value, ["theme_id"]))
    } catch (error) {
      console.log("error in src/pages/index.js=====>", error)
    }

  };
  return (
    <Box>
      <Box className={classes.header}>


        <Button
          className={classes.button}
          size={"small"}
          variant="outlined"
          color="primary"
        >
          <AddIcon className={classes.icon} /> {t("common:Ninterface")}
        </Button>
        <FormControl className={classes.formControl} variant="outlined">
          <OutlinedInput
            id="outlined-adornment-weight"
            value={searchTheme}
            placeholder={t("common:rechercher")}
            onChange={(event) => { HandleSearchs(event) }}
            endAdornment={
              <InputAdornment position="end">
                <span className="icon-search" />
              </InputAdornment>
            }
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              "aria-label": "weight",
            }}
            labelWidth={0}
          />
        </FormControl>
      </Box>

      <Box display="flex" flexWrap="wrap" justifyContent="center">
        {filtredThemes.map((theme, index) => (
          <InterfaceCardItem
            key={index}
            theme={theme}
            selected={theme.active}
            img={`img/theme${index}.png`}
          />
        ))}
      </Box>
    </Box>
  );
};
