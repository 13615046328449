import React, { useEffect } from "react";
import moment from "moment";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import { SummaryTable } from "../table";
import { useSelector, useDispatch } from "react-redux";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { LoadStockSummary } from "../../../../store/inventory/inventory.action";
import {
  LoadStockDataReport,
  UploadStockDataReport,
  CreateStockReconcialiation,
  CreateInvList,
  SetListToEdit,
  UpdateList,
} from "../../../../store/inventory/inventory.action";
import {
  Button,
  Modal,
  Typography,
  InputLabel,
  OutlinedInput,
  Checkbox,
} from "@material-ui/core";
import { SearchClient } from "../../../../pages/checkout/components/searchClient";
import { Grid } from "@material-ui/core";
import { GlobalfilterData } from "../../../../helpers/helper";
import { useTranslation } from "react-i18next";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { fr } from "date-fns/esm/locale";
import { formatDate } from "../../../../helpers/utils";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { useTableStyles } from "../../../../styles/table.style";
import { Loader } from "../../../../components/loader";
import { Header } from "../../../stockEntry/components/header";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import axiosInstance from "../../../../history/axiosInstance";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 10,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  form: {
    backgroundColor: theme.palette.secondary.main,
    padding: theme.spacing(2, 4, 3),
    boxShadow: theme.shadows[5],
    borderRadius: 10,
  },
  tableRow: {
    boxShadow: "0px 2px 8px #091F461D",
    minHeight: 45,
    borderRadius: 6,
    "& .MuiTableCell-root:first-child": {
      borderTopLeftRadius: 6,
      borderBottomLeftRadius: 6,
    },
    "& .MuiTableCell-root:last-child": {
      borderTopRightRadius: 6,
      borderBottomRightRadius: 6,
    },
    cursor: "pointer",
  },
  header: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    "& .MuiInputBase-root": {
      background: theme.palette.white.main,
    },
    "& .MuiOutlinedInput-input": {
      padding: "9px 14px",
      fontSize: 12,
    },
  },
  button: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.blue.main,
    border: "1px solid",
    borderColor: theme.palette.secondary.main,
    fontSize: "0.875rem",
    borderRadius: 10,
  },
  icon: {
    marginRight: "0.375rem",
  },
  label: {
    fontSize: 12,
    color: theme.palette.gray.main,
    marginLeft: 5,
    marginBottom: 5,
    marginTop: 8,
  },
  dateLable: {
    color: theme.palette.gray.main,
    fontSize: 12,
    marginLeft: 5,
    marginTop: 15,
    marginBottom: 2,
  },
  filterBlock: {
    "& .MuiOutlinedInput-input": {
      padding: "5.9px 14px !important",
      fontSize: 12,
    },
  },
  root: {
    position: "relative",
    "& > *": {
      marginBottom: "25px !important",
    },
    formControl: {
      margin: theme.spacing(1),
    },
  },
  gridd: {
    marginRight: 10,
    width: 170,
  },
  exportButton: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.blue.main,
    border: "1px solid",
    borderColor: theme.palette.blue.main,
    fontSize: 12,
    borderRadius: 10,
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
      borderColor: theme.palette.blue.main,
    },
  },
  recButton: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.main,
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    fontSize: 12,
    borderRadius: 10,
    padding: "0.1875rem 1.4375rem",
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
    },
  },
  recQty: {
    width: "100%",
    height: "40px",
  },
  datePickers: {
    marginRight: 20,
    width: 142,
    marginTop: 10,
  },
  radioBtn: {
    alignItems: "center",
    marginTop: 20,
  },
  nameInput: {
    fontSize: 12,
    height: 40,
    width: "100%",
  },
  hidden: {
    display: "none",
  },
  tablePaper: {
    height: 300,
  },
  buttonContainer: {
    marginBottom: 10,
    display: "flex",
    justifyContent: "space-between",
  },
  tableContainer: {
    height: 560,
    overflow: "scroll",
  },
}));

export const ListGenerator = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const tableClasses = useTableStyles();
  const globalDefaults = useSelector((state) => state.Login.globalDefaults);
  const companyacounts = useSelector(
    (state) => state.Configuration.companyacounts
  );
  const invLists = useSelector((state) => state.Inventory.invLists);
  const warehouses = useSelector((state) => state.Login.stores);
  const salesPersons = useSelector((state) => state.Checkout.salesPersonList);
  const selectedData = useSelector((state) => state.Login.selectedData);
  const products = useSelector((state) => state.Product.products);
  const listToEdit = useSelector((state) => state.Inventory.listToEdit);
  const stockSummary = useSelector((state) => state.Inventory.stockQtyReport);
  const checkedItems = useSelector((state) => state.Inventory.checkedItems);
  const pos = useSelector((state) => state.Login.pos);
  const owner = useSelector((state) => state.Login.user);
  const { t } = useTranslation(["inventory", "common", "buying"]);
  const [selectedFilters, setSelectedFilters] = React.useState({
    selectedItem: "Tous les Produits",
    selectedItemGroup: "",
    selectedSalesPerson: null,
  });
  const [transReqData, setTransReqData] = React.useState([]);
  const [transReqItems, setTransReqItems] = React.useState([]);
  const [selectedCashier, setSelectedCashier] = React.useState("Aucun");
  const [searchProduct, setSearchProduct] = React.useState("");
  const [filtredProducts, setFiltredProducts] = React.useState([]);
  const [categories, setCategories] = React.useState([]);
  const [searchItemGroup, setSearchItemGroup] = React.useState("");
  const [filtredItemGroup, setFiltredItemGroup] = React.useState([]);
  const [searchWarehouse, setSearchWarehouse] = React.useState("");
  const [filtredWarehouse, setFiltredWarehouse] = React.useState([]);
  const [searchPos, setSearchPos] = React.useState("");
  const [filtredPos, setFiltredPos] = React.useState([]);
  const [searchSalesPerson, setSearchSalesPerson] = React.useState("");
  const [filtredSalesPerson, setFiltredSalesPerson] = React.useState([]);
  const [isUpdate, setIsUpdate] = React.useState(false);
  const [period, setPeriod] = React.useState({
    start_date: formatDate(new Date(), "yyyy-MM-DD").toString(),
    end_date: formatDate(new Date(), "yyyy-MM-DD").toString(),
  });
  const filtredPosByWarehouse = pos
    ? pos.filter(
        (p) =>
          p.warehouse ==
          (selectedFilters.selectedFWarehouse
            ? selectedFilters.selectedFWarehouse
            : selectedData.store)
      )
    : [];

  const [openModal, setOpenModal] = React.useState(false);
  const [state, setState] = React.useState({
    item_code: "",
    warehouse: "",
    qty: 0,
    current_qty: 0,
  });
  const [filteredList, setFilteredList] = React.useState([]);

  const [listName, setListName] = React.useState(
    `UNTITLED-LIST-N°${invLists.length + 1}`
  );

  const [listType, setListType] = React.useState("Total");

  const [showList, setShowList] = React.useState(true);

  const [verifModal, setVerifModal] = React.useState(false);

  const [checked, setChecked] = React.useState(false);

  const [createBtn, setCreateBtn] = React.useState(true);

  const handleListTypeChange = (event) => {
    setListType(event.target.value);
  };

  const handleSearchProduct = (event) => {
    setSearchProduct(event.target.value);
    setFiltredProducts(
      GlobalfilterData(
      products?.filter((p) => p?.is_stock_item == 1), event.target.value, [
        "item_code",
        "item_name",
        "item_group",
        "description",
      ])
    );
  };

  const handleSearchWarehouse = (event) => {
    setSearchWarehouse(event.target.value);
    setFiltredWarehouse(
      GlobalfilterData(warehouses, event.target.value, ["name"])
    );
  };

  const handleSearchSalesPerson = (event) => {
    setSearchSalesPerson(event.target.value);
    setFiltredSalesPerson(
      GlobalfilterData(salesPersons, event.target.value, ["sales_person_name"])
    );
  };

  const handleSearchPos = (event) => {
    setSearchPos(event.target.value);
    setFiltredPos(
      GlobalfilterData(pos, event.target.value, ["name", "warehouse"])
    );
  };

  const handleSearchItemGroup = (event) => {
    setSearchItemGroup(event.target.value);
    setFiltredItemGroup(
      GlobalfilterData(categories, event.target.value, ["title"])
    );
  };

  const handleProductChange = (row) => {
    if (row == "Tous les Produits") {
      setSelectedFilters({ ...selectedFilters, selectedItem: "" });
    } else {
      setSelectedFilters({ ...selectedFilters, selectedItem: row.item_code });
    }
    setSearchProduct("");
  };

  const handleItemGroupChange = (row) => {
    if (row == "Toutes les Catégories") {
      setSelectedFilters({ ...selectedFilters, selectedItemGroup: "" });
      setFiltredProducts([]);
    } else {
      setSelectedFilters({ ...selectedFilters, selectedItemGroup: row.title });
      setFiltredProducts(GlobalfilterData(
      products?.filter((p) => p?.is_stock_item == 1), row.title, ["item_group"]));
    }
    setSearchItemGroup("");
  };

  const handleWarehouseChange = (row) => {
    setSelectedFilters({ ...selectedFilters, selectedFWarehouse: row.name });
    setSearchWarehouse("");
  };

  const handleSalesPersonChange = (row) => {
    if (row == "Aucun") {
      setSelectedFilters({ ...selectedFilters, selectedSalesPerson: null });
    } else {
      setSelectedFilters({
        ...selectedFilters,
        selectedSalesPerson: row.sales_person_name,
      });
    }
    setSearchSalesPerson("");
  };

  const handlePosChange = (row) => {
    if (row == "Aucun") {
      setSelectedCashier("Aucun");
    } else {
      setSelectedCashier(row.name);
    }
    setSearchPos("");
  };

  const handleRecProductChange = (row) => {
    setState({ ...state, item_code: row.item_code });
    setSearchProduct("");
  };

  const handleRecWarehouseChange = (row) => {
    setState({ ...state, warehouse: row.name });
    setSearchWarehouse("");
  };

  const handleRecQtyChange = (e) => {
    setState({ ...state, qty: parseInt(e.target.value) });
  };
  const loadProductsCategories = async () => {
    const catList = [];
    products &&
      products.length != 0 &&
      products.map((p, i) => {
        p.item_group &&
          catList.push({ title: p.item_group, value: p.item_group });
      });
    const uniqueIds = [];
    const unique = catList.filter((element) => {
      const isDuplicate = uniqueIds.includes(element.value);

      if (!isDuplicate) {
        uniqueIds.push(element.value);

        return true;
      }

      return false;
    });

    setCategories(unique);
  };

  const onPageLoad = async () => {
    if (!selectedData) return null;
    const data = {
      report_name: "Stock Projected Qty",
      filters: {
        company: globalDefaults?.default_company,
        warehouse: selectedFilters.selectedFWarehouse
          ? selectedFilters.selectedFWarehouse
          : selectedData.store,
        item_group:
          selectedFilters.selectedItemGroup == "Toutes les Catégories"
            ? ""
            : selectedFilters.selectedItemGroup,
      },
    };
    dispatch(LoadStockDataReport(data));
  };

  const createInvList = () => {
    var items = [];
    var wareHouse = stockSummary.length
      ? stockSummary[0].warehouse
      : selectedFilters.selectedFWarehouse
      ? selectedFilters.selectedFWarehouse
      : selectedData.store;
    filteredList.length
      ? filteredList.flat().map((item, index) => {
          return item.item_code ? items.push(item.item_code) : null;
        })
      : stockSummary.map((item, index) => {
          return item.item_code ? items.push(item.item_code) : null;
        });
    const data = {
      name: listName,
      products: JSON.stringify(items),
      warehouse: wareHouse,
      owner: owner.email,
      creation_date: moment().format("YYYY-MM-DD HH:mm:ss"),
      status: "Draft",
      list_id: `INV-LIST-${moment().format("YYYYMMDDHHmmss")}`,
      company: `${globalDefaults?.default_company}`,
      list_type: listType,
      pos: selectedCashier == "Aucun" ? null : selectedCashier,
      sales_person: selectedFilters?.selectedSalesPerson,
      inventory_period: JSON.stringify([period.start_date, period.end_date]),
      item_group:
        selectedFilters.selectedItemGroup == "Toutes les Catégories"
          ? ""
          : selectedFilters.selectedItemGroup,
    };
    dispatch(CreateInvList(data));
  };
  const handleStartDateChange = (date) => {
    setPeriod({
      ...period,
      start_date: formatDate(date, "yyyy-MM-DD").toString(),
    });
  };
  const handleEndDateChange = (date) => {
    var d1 = Date.parse(period.start_date);
    var d2 = Date.parse(date);
    if (d1 <= d2) {
      setPeriod({
        ...period,
        end_date: formatDate(date, "yyyy-MM-DD").toString(),
      });
    }
  };

  const handleListNameChange = (e) => {
    setListName(e.target.value);
  };

  const handleSubmit = () => {
    const d = new Date();
    const data = {
      doc: {
        docstatus: 0,
        doctype: "Stock Reconciliation",
        __islocal: 1,
        __unsaved: 1,
        naming_series: "MAT-RECO-.YYYY.-",
        company: `${globalDefaults?.default_company}`,
        purpose: "Stock Reconciliation",
        posting_date: d.getFullYear() + "-" + d.getMonth() + "-" + d.getDate(),
        set_posting_time: 0,
        items: [
          {
            docstatus: 0,
            doctype: "Stock Reconciliation Item",
            __islocal: 1,
            __unsaved: 1,
            allow_zero_valuation_rate: 0,
            current_qty: state.current_qty,
            parentfield: "items",
            parenttype: "Stock Reconciliation",
            idx: 1,
            item_code: state.item_code,
            warehouse: state.warehouse,
            qty: state.qty,
          },
        ],
        expense_account: `${companyacounts?.stock_adjustment_account}`,
        cost_center: `${companyacounts?.cost_center}`,
        posting_time:
          d.getHours() + ":" + d.getMinutes() + ":" + d.getSeconds(),
      },
      action: "Submit",
    };
    dispatch(CreateStockReconcialiation(data));
    setSelectedFilters({
      ...selectedFilters,
      selectedItem: state.item_code,
      selectedFWarehouse: state.warehouse,
    });
    setState({
      item_code: "",
      warehouse: "",
      qty: 0,
      current_qty: 0,
    });
    setOpenModal(false);
  };
  const handleCancel = () => {
    setOpenModal(false);
    setState({
      item_code: "",
      warehouse: "",
      qty: 0,
      current_qty: 0,
    });
  };

  const getTransferRequests = async () => {
    const data = {
      doctype: "Material Request",
      fields: [
        "`tabMaterial Request`.`name`",
        "`tabMaterial Request`.`creation`",
        "`tabMaterial Request`.`material_request_type`",
        "`tabMaterial Request`.`status`",
        "`tabMaterial Request`.`schedule_date`",
        "`tabMaterial Request`.`transaction_date`",
        "`tabMaterial Request`.`title`",
        "`tabMaterial Request`.`set_warehouse`",
        "`tabMaterial Request`.`set_from_warehouse`",
      ],
      filters: [
        [
          "Material Request",
          "company",
          "=",
          `${globalDefaults?.default_company}`,
        ],
        ["Material Request", "material_request_type", "=", "Material Transfer"],
        ["Material Request", "status", "=", "Pending"],
      ],
    };
    const url = `${process.env.REACT_APP_API_URI}/api/transfer-requests`;

    return axiosInstance
      .post(url, data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
          Authorization: JSON.parse(localStorage.getItem("user")).token,
        },
      })
      .then(async (response) => {
        response.data.length > 0 && setTransReqData(response.data);
        let items = [];
        Array.isArray(response.data) &&
          response.data.map(async (t) => {
            const dataa = {
              doctype: "Material Request",
              fields: [
                "`tabMaterial Request Item`.`item_code`",
                "`tabMaterial Request Item`.`item_name`",
                "`tabMaterial Request Item`.`qty`",
                "`tabMaterial Request Item`.`parent`",
              ],
              filters: [["Material Request Item", "parent", "=", `${t.name}`]],
            };
            const url = `${process.env.REACT_APP_API_URI}/api/transfer-requests`;

            return axiosInstance
              .post(url, dataa, {
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
                  Authorization: JSON.parse(localStorage.getItem("user")).token,
                },
              })
              .then((response) => {
                response.data.map((r) => items.push(r));
              });
          });
        setCreateBtn(false);
        setTransReqItems(items);
      });
  };

  const handleUpdateList = () => {
    var items = [];
    var wareHouse = stockSummary.length
      ? stockSummary[0].warehouse
      : selectedFilters.selectedFWarehouse
      ? selectedFilters.selectedFWarehouse
      : selectedData.store;
    filteredList.length && listType == "Partiel"
      ? filteredList.flat().map((item, index) => {
          return item.item_code ? items.push(item.item_code) : null;
        })
      : stockSummary.map((item, index) => {
          return item.item_code ? items.push(item.item_code) : null;
        });

    const data = {
      name: listName,
      products: JSON.stringify(items),
      warehouse: wareHouse,
      owner: listToEdit.owner,
      creation_date: listToEdit.creation_date,
      status: listToEdit.status,
      list_id: listToEdit.list_id,
      company: listToEdit.company,
      list_type: listType,
      pos: selectedCashier == "Aucun" ? null : selectedCashier,
      sales_person: selectedFilters.selectedSalesPerson,
      inventory_period: JSON.stringify([period.start_date, period.end_date]),
      item_group:
        selectedFilters.selectedItemGroup == "Toutes les Catégories"
          ? ""
          : selectedFilters.selectedItemGroup,
    };
    dispatch(UpdateList(data));
  };

  const isEmpty = () => {
    var items = [];
    filteredList.length
      ? filteredList.flat().map((item, index) => {
          return item.item_code ? items.push(item.item_code) : null;
        })
      : stockSummary.map((item, index) => {
          return item.item_code ? items.push(item.item_code) : null;
        });

    if (listType == "Partiel") {
      if (filteredList.flat().length == 0) {
        return true;
      }
    } else {
      if (filteredList.flat().length == 0) {
        if (stockSummary.length == 0) {
          return true;
        }
      }
    }
    if (period.end_date < period.start_date) return true;
    return false;
  };

  const handleCreateButton = () => {
    if (isUpdate) {
      handleUpdateList();
    } else {
      if (
        transReqData &&
        Array.isArray(transReqData) &&
        transReqData.find(
          (transReq) =>
            transReq.set_from_warehouse == selectedData.store ||
            transReq.set_warehouse == selectedData.store
        )
      ) {
        setVerifModal(true);
      } else {
        createInvList();
      }
    }
  };

  useEffect(() => {
    if (globalDefaults?.default_company) {
      onPageLoad();
      loadProductsCategories();
      getTransferRequests();
    }
  }, [globalDefaults?.default_company]);

  useEffect(() => {
    if (listToEdit) {
      setIsUpdate(true);
      setListName(listToEdit.name);
      setListType(listToEdit.list_type);
      setSelectedCashier(listToEdit.pos);
      setSelectedFilters({
        ...selectedFilters,
        selectedSalesPerson: listToEdit.sales_person,
        selectedFWarehouse: listToEdit.warehouse,
        selectedItemGroup: listToEdit.item_group,
      });
      setPeriod({
        start_date: formatDate(
          JSON.parse(listToEdit.inventory_period)[0],
          "yyyy-MM-DD"
        ).toString(),
        end_date: formatDate(
          JSON.parse(listToEdit.inventory_period)[1],
          "yyyy-MM-DD"
        ).toString(),
      });
      listToEdit.item_group &&
        setFiltredProducts(
          GlobalfilterData(
      products?.filter((p) => p?.is_stock_item == 1), listToEdit.item_group, ["item_group"])
        );
    }
  }, []);

  useEffect(() => {
    onPageLoad();
  }, [selectedFilters]);

  useEffect(() => {
    const data =
      stockSummary &&
      stockSummary.filter(
        (row) =>
          row.warehouse == state.warehouse && row.item_code == state.item_code
      );

    if (data && data.length != 0) {
      setState({
        ...state,
        qty: data[0].actual_qty,
        current_qty: data[0].actual_qty,
      });
    } else {
      setState({ ...state, qty: 0, current_qty: 0 });
    }
  }, [state.item_code, state.warehouse]);

  const CustomGlobalFilter = (array, keyword, keys) => {
    const lowercasedValue = keyword?.toLowerCase().trim();
    if (lowercasedValue === "") return array;
    else {
      const filteredData = array.filter((item) => {
        return Object.keys(item).some((key) => {
          if (item[key] !== null) {
            return keys.includes(key)
              ? item[key]
                  ?.toString()
                  ?.toLowerCase()
                  ?.match(new RegExp(lowercasedValue, "g"))
              : false;
          } else {
            return false;
          }
        });
      });
      return filteredData.filter((f) => f.item_code == keyword);
    }
  };

  useEffect(() => {
    let itemList = checkedItems.map((itemCode) =>
      CustomGlobalFilter(stockSummary, itemCode, ["item_code"])
    );
    setFilteredList(itemList);
  }, [checkedItems, stockSummary]);

  if (!stockSummary) return <Loader />;
  if (!selectedData) return <Loader />;

  const body = (
    <div className={classes.paper}>
      <Typography align={"center"} color={"primary"} className={classes.title}>
        {t("inventory:reconciliation")}
      </Typography>
      <Box>
        <InputLabel
          required
          className={classes.label}
          id="demo-simple-select-outlined-label"
        >
          {t("common:produit")}
        </InputLabel>
        <SearchClient
          isClient={false}
          products={products}
          isProducts={true}
          isRec={true}
          searchProduct={searchProduct}
          handleSearchProduct={handleSearchProduct}
          filtredProducts={filtredProducts}
          handleProductChange={handleProductChange}
          selectedFilters={selectedFilters}
          state={state}
          handleRecProductChange={handleRecProductChange}
        />

        <InputLabel
          required
          className={classes.label}
          id="demo-simple-select-outlined-label"
        >
          {t("common:magasin")}
        </InputLabel>
        <SearchClient
          isClient={false}
          isProducts={false}
          isCategory={false}
          isWarehouse={true}
          isRecWarehouse={true}
          selectedFilters={selectedFilters}
          warehouses={warehouses}
          searchWarehouse={searchWarehouse}
          handleSearchWarehouse={handleSearchWarehouse}
          filtredWarehouse={filtredWarehouse}
          handleWarehouseChange={handleWarehouseChange}
          state={state}
          handleRecWarehouseChange={handleRecWarehouseChange}
        />
        <InputLabel required className={classes.label}>
          {t("common:qty")}
        </InputLabel>

        <OutlinedInput
          type="number"
          id="outlined-adornment-weight"
          value={state.qty ?? 0}
          className={classes.recQty}
          name="qty"
          onChange={handleRecQtyChange}
          aria-describedby="outlined-weight-helper-text"
          inputProps={{
            "aria-label": "weight",
          }}
          labelWidth={0}
        />
      </Box>
      <Box mt={3} justifyContent="center" display="flex">
        <Button
          disabled={
            !state.item_code ||
            !state.warehouse ||
            state.qty == 0 ||
            isNaN(state.qty)
          }
          variant="contained"
          color="primary"
          onClick={() => handleSubmit()}
        >
          {t("common:continuer")}
        </Button>
        <Button color="primary" onClick={() => handleCancel()}>
          {t("common:annuler")}
        </Button>
      </Box>
    </div>
  );

  const verif = (
    <div className={classes.paper}>
      <Typography align={"center"} color={"primary"} className={classes.title}>
        Demandes de transfert en attente liée à votre Magasin
      </Typography>
      <Box mt={3}>
        <Box className={clsx(classes.tablePaper, tableClasses.paper)}>
          <Table className={tableClasses.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  className={tableClasses.tablecellHeader}
                  align="left"
                >
                  {t("common:createDate")}
                </TableCell>
                <TableCell
                  className={tableClasses.tablecellHeader}
                  align="left"
                >
                  {t("common:type")}
                </TableCell>
                <TableCell
                  className={tableClasses.tablecellHeader}
                  align="left"
                >
                  {t("inventory:fromStore")}
                </TableCell>
                <TableCell
                  className={tableClasses.tablecellHeader}
                  align="left"
                >
                  {t("inventory:toStore")}
                </TableCell>
                <TableCell
                  className={tableClasses.tablecellHeader}
                  align="left"
                >
                  {t("inventory:articlesDemandés")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transReqData.length != 0 &&
                transReqData.map((t) => {
                  if (
                    t.set_from_warehouse ==
                      (selectedFilters.selectedFWarehouse
                        ? selectedFilters.selectedFWarehouse
                        : selectedData.store) ||
                    t.set_warehouse ==
                      (selectedFilters.selectedFWarehouse
                        ? selectedFilters.selectedFWarehouse
                        : selectedData.store)
                  ) {
                    return (
                      <TableRow className={tableClasses.tableRow}>
                        <TableCell
                          className={tableClasses.tableCell}
                          align="left"
                        >
                          {t.transaction_date}
                        </TableCell>
                        <TableCell
                          className={tableClasses.tableCell}
                          align="left"
                        >
                          {t.material_request_type}
                        </TableCell>
                        <TableCell
                          className={tableClasses.tableCell}
                          align="left"
                        >
                          {t.set_from_warehouse}
                        </TableCell>
                        <TableCell
                          className={tableClasses.tableCell}
                          align="left"
                        >
                          {t.set_warehouse}
                        </TableCell>
                        <TableCell
                          className={tableClasses.tableCell}
                          align="left"
                        >
                          {transReqItems.length != 0 &&
                            transReqItems.map((i) => {
                              if (i.parent == t.name) {
                                return (
                                  <>
                                    {i.item_code} ({i.qty}){", "}
                                  </>
                                );
                              }
                            })}
                        </TableCell>
                      </TableRow>
                    );
                  }
                })}
            </TableBody>
          </Table>
        </Box>
      </Box>
      {/* <Box ml={-1.5} mt={2}>
        <Checkbox checked={checked} onChange={() => setChecked(!checked)} color="primary"/>Prendre en Considération ?
      </Box> */}
      <Box mt={3} justifyContent="center" display="flex">
        <Button variant="contained" color="primary" onClick={createInvList}>
          {t("common:continuer")}
        </Button>
        <Button color="primary" onClick={() => setVerifModal(false)}>
          {t("common:annuler")}
        </Button>
      </Box>
    </div>
  );

  return (
    <Box>
      <Box mb={5}>
        <Header />
      </Box>
      <Grid container xs={12} spacing={2}>
        <Grid item xs={4}>
          <Box className={classes.form}>
            <InputLabel
              id="demo-simple-select-outlined-label"
              className={classes.label}
            >
              {t("inventory:Intitulé")}
            </InputLabel>
            <OutlinedInput
              className={classes.nameInput}
              value={listName}
              onChange={handleListNameChange}
            />
            <Grid item xs={12}>
              <Grid item>
                <FormControl variant="outlined">
                  <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
                    <Typography className={classes.dateLable}>
                      {t("common:dateDebut")}
                    </Typography>
                    <KeyboardDatePicker
                      value={period.start_date}
                      onChange={handleStartDateChange}
                      name={"start_date"}
                      disableToolbar
                      variant="inline"
                      format="yyyy-MM-dd"
                      inputVariant="outlined"
                      id="date-picker-inline"
                      keyboardIcon={<span className={"icon-calendar"} />}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      InputProps={{
                        style: {
                          fontSize: 12,
                          height: 40,
                        },
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl variant="outlined">
                  <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
                    <Typography className={classes.dateLable}>
                      {t("common:dateFin")}
                    </Typography>
                    <KeyboardDatePicker
                      value={period.end_date}
                      onChange={handleEndDateChange}
                      name={"end_date"}
                      disableToolbar
                      variant="inline"
                      format="yyyy-MM-dd"
                      inputVariant="outlined"
                      id="date-picker-inline"
                      keyboardIcon={<span className={"icon-calendar"} />}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      InputProps={{
                        style: {
                          fontSize: 12,
                          height: 40,
                        },
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl variant="outlined">
                  <RadioGroup
                    row
                    aria-label="type"
                    name="type"
                    value={listType}
                    onChange={handleListTypeChange}
                    style={{
                      justifyContent: "space-evenly",
                      marginTop: 8,
                      marginBottom: -5,
                    }}
                  >
                    <FormControlLabel
                      value="Total"
                      control={
                        <Radio color="primary" className={classes.radio} />
                      }
                      label="Total"
                    />
                    <FormControlLabel
                      value="Partiel"
                      control={
                        <Radio color="primary" className={classes.radio} />
                      }
                      label="Partiel"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            <InputLabel
              id="demo-simple-select-outlined-label"
              className={classes.label}
            >
              {t("common:categorie")}
            </InputLabel>
            <FormControl variant="outlined" className={classes.formControl}>
              <SearchClient
                isInventory={true}
                isClient={false}
                isProducts={false}
                isCategory={true}
                selectedFilters={selectedFilters}
                categories={categories}
                searchItemGroup={searchItemGroup}
                handleSearchItemGroup={handleSearchItemGroup}
                filtredItemGroup={filtredItemGroup}
                handleItemGroupChange={handleItemGroupChange}
              />
            </FormControl>
            {listType == "Partiel" && (
              <>
                <InputLabel
                  id="demo-simple-select-outlined-label"
                  className={classes.label}
                >
                  {t("common:selectArticles")}
                </InputLabel>
                <FormControl variant="outlined" className={classes.formControl}>
                  <SearchClient
                    isInventory={true}
                    isClient={false}
                    products={products}
                    isProducts={true}
                    searchProduct={searchProduct}
                    handleSearchProduct={handleSearchProduct}
                    filtredProducts={filtredProducts}
                    handleProductChange={handleProductChange}
                    selectedFilters={selectedFilters}
                  />
                </FormControl>
              </>
            )}
            <InputLabel
              id="demo-simple-select-outlined-label"
              className={classes.label}
            >
              {t("common:magasin")}
            </InputLabel>
            <FormControl variant="outlined" className={classes.formControl}>
              <SearchClient
                isInventory={true}
                isClient={false}
                isProducts={false}
                isCategory={false}
                isWarehouse={true}
                selectedFilters={selectedFilters}
                warehouses={warehouses}
                searchWarehouse={searchWarehouse}
                handleSearchWarehouse={handleSearchWarehouse}
                filtredWarehouse={filtredWarehouse}
                handleWarehouseChange={handleWarehouseChange}
              />
            </FormControl>
            <InputLabel
              id="demo-simple-select-outlined-label"
              className={classes.label}
            >
              {t("common:caisse")}
            </InputLabel>
            <FormControl variant="outlined" className={classes.formControl}>
              <SearchClient
                isInventory={true}
                isClient={false}
                isProducts={false}
                isCategory={false}
                isWarehouse={false}
                isPos={true}
                selectedFilters={selectedFilters}
                pos={pos}
                searchPos={searchPos}
                handleSearchPos={handleSearchPos}
                filtredPos={filtredPos}
                handlePosChange={handlePosChange}
                selectedCashier={selectedCashier}
                filtredPosByWarehouse={filtredPosByWarehouse}
              />
            </FormControl>
            <InputLabel
              id="demo-simple-select-outlined-label"
              className={classes.label}
            >
              {t("common:vendeurs")}
            </InputLabel>
            <FormControl variant="outlined" className={classes.formControl}>
              <SearchClient
                isInventory={true}
                isClient={false}
                isProducts={false}
                isCategory={false}
                isWarehouse={false}
                isPos={false}
                isSalesPerson={true}
                selectedFilters={selectedFilters}
                salesPersons={salesPersons}
                searchSalesPerson={searchSalesPerson}
                handleSearchSalesPerson={handleSearchSalesPerson}
                filtredSalesPerson={filtredSalesPerson}
                handleSalesPersonChange={handleSalesPersonChange}
              />
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Grid container className={classes.buttonContainer} spacing={2}>
            <Grid item>
              <Button
                disabled={isEmpty()}
                className={classes.exportButton}
                variant="outlined"
                size={"small"}
                color="primary"
                onClick={handleCreateButton}
              >
                {isUpdate
                  ? t("inventory:update1Button")
                  : t("inventory:update2Button")}
              </Button>
            </Grid>
            <Grid item>
              <Button
                className={classes.exportButton}
                variant="outlined"
                size={"small"}
                color="primary"
                onClick={() => setShowList(!showList)}
              >
                {showList
                  ? t("inventory:showlist1Button")
                  : t("inventory:showlist2Button")}
              </Button>
            </Grid>
          </Grid>
          <Box className={classes.tableContainer}>
            {listType == "Partiel" ? (
              <div className={!showList ? classes.hidden : null}>
                <SummaryTable
                  listType={listType}
                  stockQtyReport={filteredList.flat()}
                  isFiltered={true}
                  selectedFilters={selectedFilters}
                  selectedCashier={selectedCashier}
                />
              </div>
            ) : (
              <div className={!showList ? classes.hidden : null}>
                <SummaryTable
                  listType={listType}
                  className={classes.hidden}
                  stockQtyReport={stockSummary}
                  selectedFilters={selectedFilters}
                  selectedCashier={selectedCashier}
                />
              </div>
            )}
          </Box>
        </Grid>
      </Grid>
      <Modal open={openModal}>{body}</Modal>
      <Modal open={verifModal}>{verif}</Modal>
    </Box>
  );
};
