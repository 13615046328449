import moment from "moment";
import * as localforage from "localforage";
import axiosInstance from "../../history/axiosInstance";
export const postData = (Url, data) => {
  return axiosInstance.post(Url, data, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
      Authorization: JSON.parse(localStorage.getItem("user")).token,
    },
  });
};
export const guestPostData = (Url, data) => {
  return axiosInstance.post(Url, data, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
      Authorization: "token 665e7014b6be6ce:86de0a52a04f612",
    },
  });
};
export const getData = (Url) => {
  return axiosInstance.get(Url, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
      Authorization: JSON.parse(localStorage.getItem("user")).token,
    },
  });
};

/* **********RETAIL-530 : discount synchronization****************  */
export const loadDiscountData = (warehouse) => {
  const data = {
    doctype: "Discount",
    fields: [
      "`tabDiscount`.`name`",
      "`tabDiscount`.`discount_value`",
      "`tabDiscount`.`enabled`",
    ],
    filters: [
      ["Discount", "warehouse", "=", warehouse],
      ["Discount", "enabled", "=", 1],
    ],
  };
  const url = `${process.env.REACT_APP_API_URI}/api/get-list`;

  return postData(url, data);
};

/* **********RETAIL-530 : synchronization of company account configurations****************  */
/* Get company details  */
export const loadcompanyaccountData = (company) => {
  const url =
    `${process.env.REACT_APP_API_URI}/api/details?doctype=Company&name=` +
    company;

  return getData(url);
};

/* **********RETAIL-530 : Warehouse parent synchronization****************  */
export const loadWarehouseParentData = (company) => {
  const data = {
    doctype: "Warehouse",
    fields: ["`tabWarehouse`.`name`"],
    filters: [
      ["Warehouse", "is_group", "=", 1],
      ["Warehouse", "company", "=", company],
    ],
    order_by: "`tabWarehouse`.`modified` asc",
    start: 0,
    page_length: 1,
  };
  const url = `${process.env.REACT_APP_API_URI}/api/get-list`;

  return postData(url, data);
};

/* **********RETAIL-530 : Territory parent synchronization****************  */
export const loadTerritoryParentData = () => {
  const data = {
    doctype: "Territory",
    fields: ["`tabTerritory`.`name`"],
    filters: [["Territory", "is_group", "=", 1]],
  };
  const url = `${process.env.REACT_APP_API_URI}/api/get-list`;

  return postData(url, data);
};

/* **********RETAIL-530 : Guest Territory parent synchronization****************  */
export const guestLoadTerritoryParentData = () => {
  const data = {
    doctype: "Territory",
    fields: ["`tabTerritory`.`name`"],
    filters: [["Territory", "is_group", "=", 1]],
  };
  const url = `${process.env.REACT_APP_API_URI}/api/guest/get-list`;

  return guestPostData(url, data);
};

/* **********RETAIL-530 : Guest Customer Group parent synchronization****************  */
export const guestLoadCustomerGroupParentData = () => {
  const data = {
    doctype: "Customer Group",
    fields: ["`tabCustomer Group`.`name`"],
    filters: [],
  };
  const url = `${process.env.REACT_APP_API_URI}/api/guest/get-list`;

  return guestPostData(url, data);
};

/* **********RETAIL-530 : Customer Group parent synchronization****************  */
export const loadCustomerGroupParentData = () => {
  const data = {
    doctype: "Customer Group",
    fields: ["`tabCustomer Group`.`name`"],
    filters: [["Customer Group", "is_group", "=", 1]],
  };
  const url = `${process.env.REACT_APP_API_URI}/api/get-list`;

  return postData(url, data);
};

/* **********RETAIL-530 : Default Warehouse && Item Group synchronization****************  */
export const loadStockSettingsData = () => {
  const url = `${process.env.REACT_APP_API_URI}/api/details?doctype=Stock+Settings&name=Stock+Settings`;
  return getData(url);
};

/* **********RETAIL-530 : Default Terriotry synchronization****************  */
/* export const loadDefaultTerriotryData = (country) => {
    const data = {
        doctype: "Territory",
        fields: [
            "`tabTerritory`.`name`"
        ],
        filters: [["Territory", "territory_name", "like", `%${country}%`], ["Territory", "is_group", "=", 0]],
        order_by: "`tabTerritory`.`modified` asc",
        start: 0,
        page_length: 1
    };
    const url = `${process.env.REACT_APP_API_URI}/api/get-list`;

    return postData(url, data);
}; */

/* **********RETAIL-530 : Price List && default terriotry && default customer group  synchronization****************  */
export const loadSellingSettingsData = () => {
  const url = `${process.env.REACT_APP_API_URI}/api/details?doctype=Selling+Settings&name=Selling+Settings`;
  return getData(url);
};
/* **********RETAIL-677 : Buying Settings  synchronization****************  */
export const loadBuyingSettingsData = () => {
  const url = `${process.env.REACT_APP_API_URI}/api/details?doctype=Buying+Settings&name=Buying+Settings`;
  return getData(url);
};
/* **********RETAIL-657 : Load Themes Data****************  */
export const loadThemesData = () => {
  const url = `${process.env.REACT_APP_API_URI}/api/themes`;
  return getData(url);
};


/* **********RETAIL-673 : Load Invoices Returned Data****************  */
export const loadInvoicesReturnedData = (company) => {
  const url = `${process.env.REACT_APP_API_URI}/api/get-list`;
  const request = {
    doctype: 'POS Invoice',
    fields: ['`tabPOS Invoice`.`name`',
      '`tabPOS Invoice`.`grand_total`',
      '`tabPOS Invoice`.`paid_amount`',
      '`tabPOS Invoice`.`status`',
      '`tabPOS Invoice`.`title`',
      '`tabPOS Invoice`.`customer`',
      '`tabPOS Invoice`.`customer_name`',
      '`tabPOS Invoice`.`base_grand_total`',
      '`tabPOS Invoice`.`company`',
      '`tabPOS Invoice`.`currency`',
      '`tabPOS Invoice`.`is_return`',
      '`tabPOS Invoice`.`return_against`']
    , filters: [['POS Invoice', 'company', '=', company], ['POS Invoice', 'status', '=', 'Return']]
    , order_by: '`tabPOS Invoice`.`creation` desc'
  }
  return postData(url, JSON.stringify(request));
};
