
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({

    root: {
        display: "flex",
        boxShadow: "0px 0.3125rem 0.5625rem #091f461f",
        border: "2px solid #CCD8E8",
        padding: "0.9375rem 0.625rem",
        borderRadius: 15,
        opacity: 1,
        margin: theme.spacing(0.5),
        background: "#FFFFFF",
        borderColor: theme.palette.products.border,
        text: theme.palette.products.text,
        minWidth: 168,
      },
    title: {
      color: theme.palette.primary.main,
      textAlign:"center",
      marginLeft:10,
      fontWeight: 700,
      whiteSpace: "nowrap",
      width: "120px",
      textOverflow: "ellipsis",
      overflow: "hidden",
      '&:hover': {
        overflow: "visible",
      },
    },
    pos: {
      color: theme.palette.gray.main,
      fontSize: 12,
      marginTop: 9,
    },
    button:{
      marginLeft:-60,
      marginTop:100,
      padding: "5px 7px",
      fontSize: 15,
      backgroundColor : theme.palette.productsTable.button,
      '&:hover' : {
        backgroundColor : theme.palette.productsTable.button
    }}
}));
