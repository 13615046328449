import React, { useEffect, useRef, useState } from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import {
  CircularProgress,
  ClickAwayListener,
  Grid,
  IconButton,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import PrintIcon from "@material-ui/icons/Print";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import clsx from "clsx";
import { Voucher } from "../../../../components/componentToPrint/avoir/voucher";
import CloseIcon from "@material-ui/icons/Close";
import { useStyles } from "../../../payement/style";
import { SetAvoirToPrint } from "../../../../store/checkout/checkout.action";

const useLocalStyles = makeStyles((theme) => ({
  delete: {
    cursor: "pointer",
  },
  warning: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 10,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  },
  paperBg: {
    background: theme.palette.productsTable.background,
    marginTop: -16,
  },
  print: {
    display: "none",
  },
  printIcon: {
    color: theme.palette.primary.main,
    height: 50,
    width: 50,
    marginTop: 5,
    cursor: "pointer",
  },
}));
export const PrintAvoirPage = (props) => {
  const { timeout, closePrint } = props;
  const classesLocal = useLocalStyles();
  const classes = useStyles();
  const doc2Print = useRef();
  const dispatch = useDispatch();

  const avoir2Print = useSelector((state) => state.Checkout.avoir2Print);

  const [avoirPrinted, setAvoirPrinted] = useState(-1);
  //const [open, setOpen] = useState(false);

  useEffect(() => {
    if (avoirPrinted === 1 && avoir2Print) {
      setAvoirPrinted(0);
      //setOpen(false);
      handlePrint();
      waitSyncronisation();
    }
    if (avoirPrinted === -1 && avoir2Print) {
      setAvoirPrinted(1);
    }
  }, [avoir2Print, avoirPrinted]);
  const waitSyncronisation = async () => {
    await timeout(10000);
  };
  const handlePrint = useReactToPrint({
    content: () => avoir2Print && doc2Print.current,
    onAfterPrint: () => {
      dispatch(SetAvoirToPrint(null));
      closePrint()
    },
  });

  return (
    <div>
      {/* <ClickAwayListener onClickAway={() => setPrintOpen(false)}> */}

      <Box className={classesLocal.warning}>
        {avoir2Print ? (
          <Box style={{ display: "flex", flexDirection: "column" }}>
            <PrintIcon
              className={classesLocal.printIcon}
              onClick={handlePrint}
            />
            <IconButton
              onClick={(e) => {
                closePrint();
              }}
            >
              <CloseIcon
                style={{ position: "initial" }}
                className={classes.backicon}
              />
            </IconButton>
          </Box>
        ) : (
          <Box style={{ display: "flex", flexDirection: "column" }}>
            <CircularProgress />
          </Box>
        )}
      </Box>
      <Grid item style={{ marginTop: 5 }}>
        {avoir2Print && (
          <ReactToPrint
            trigger={() => (
              <PrintIcon color="primary" className={classesLocal.delete} />
            )}
            content={() => avoir2Print && doc2Print.current}
          />
        )}
      </Grid>
      <div className={classesLocal.print}>
        {avoir2Print && (
          <Voucher document={avoir2Print} title={"Avoir"} ref={doc2Print} />
        )}
      </div>
    </div>
    /*   </ClickAwayListener> */
  );
};
