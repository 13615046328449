export const CREATE_CLIENT = "CREATE_CLIENT";
export const CREATE_CLIENT_SUCCESS = "CREATE_CLIENT_SUCCESS";
export const CLIENTS_LOADED = "CLIENTS_LOADED";
export const LOAD_FILTRED_CLIENTS = "LOAD_FILTRED_CLIENTS";
export const CLIENT_CREATED_BRODCAST = "CLIENT_CREATED_BRODCAST";
export const LOYALTY_PROGRAMS_LOADED = "LOYALTY_PROGRAMS_LOADED";
export const PAYMENT_ENTRIES_LOADED = "PAYMENT_ENTRIES_LOADED";
export const LOADING_PAYMENT_ENTRIES = "LOADING_PAYMENT_ENTRIES";
export const RETURNS_LOADED = "RETURNS_LOADED";
export const LOADING_RETURNS = "LOADING_RETURNS";
export const UNPAID_SALES_INVOICE_LOADED = "UNPAID_SALES_INVOICE_LOADED";
export const LOADING_UNPAID_SALES_INVOICE = "LOADING_UNPAID_SALES_INVOICE";
export const SELECTED_CLIENT_LOADED = "SELECTED_CLIENT_LOADED";
export const LOADING_ORDERS = "LOADING_ORDERS";
export const ORDERS_LOADED = "ORDERS_LOADED";
export const LOYALTY_POINTS_LOADED = "LOYALTY_POINTS_LOADED";
export const LOADING_LOYALTY_POINTS = "LOADING_LOYALTY_POINTS";
export const CURRENCIES_LOADED = "CURRENCIES_LOADED";
export const LOADING_ACCOUNT_BY_CURRENCY = "LOADING_ACCOUNT_BY_CURRENCY";
export const ACCOUNT_BY_CURRENCY_LOADED = "ACCOUNT_BY_CURRENCY_LOADED";
export const CUSTOMERS_GROUP_LOADED = "CUSTOMERS_GROUP_LOADED";
export const CREATE_ADDRESS_CUSTOMER = "CREATE_ADDRESS_CUSTOMER";
export const CREATE_ADDRESS_CUSTOMER_SUCCESS = "CREATE_ADDRESS_CUSTOMER_SUCCESS";

export const ADVANCE_PAYMENT_CREATED = "ADVANCE_PAYMENT_CREATED";
export const CREATE_ADVANCE_PAYMENT = "CREATE_ADVANCE_PAYMENT";

export const CANCEL_PAYMENT_ENTRY = "CANCEL_PAYMENT_ENTRY";
export const PAYMENT_ENTRY_CANCELED = "PAYMENT_ENTRY_CANCELED";
export const INVOICES_LOADED = "INVOICES_LOADED";
export const SET_ORDER = "SET_ORDER";
export const CANCEL_ORDER = "CANCEL_ORDER";
export const SET_INVOICE_DETAIL = "SET_INVOICE_DETAIL";

export const PERSONNAL_MSG_TOGGLED = "PERSONNAL_MSG_TOGGLED";
export const SEND_GIFT_CARD_EMAIL = "SEND_GIFT_CARD_EMAIL";
export const CREATE_GIFT_CARD_EMAIL_SUCCESS = "CREATE_GIFT_CARD_EMAIL_SUCCESS";
export const GET_CREDIT_NOTE = "GET_CREDIT_NOTE";

export const LOADING_CLIENT_COUPON_CODE = "LOADING_CLIENT_COUPON_CODE";
export const CLIENT_COUPON_CODE_LOADED = "CLIENT_COUPON_CODE_LOADED";
export const CLIENT_AVOIR_ACCOUNT_LOADED = "CLIENT_AVOIR_ACCOUNT_LOADED";
export const SET_CLIENT_PRICING_RULE = "SET_CLIENT_PRICING_RULE";
export const LOADING_COUPON_CODES = "LOADING_COUPON_CODES";
export const COUPON_CODES_LOADED = "COUPON_CODES_LOADED";
export const UPDATE_CARD = "UPDATE_CARD";
export const UPDATE_CARD_SUCCESS = "UPDATE_CARD_SUCCESS";

export const SEC_SET_DETAIL_CLIENT = "SEC_SET_DETAIL_CLIENT";

export const COUPON_CODES_LIST_LOADED = "COUPON_CODES_LIST_LOADED";

export const CUSTOMER_LEDGER_LOADED = "CUSTOMER_LEDGER_LOADED";
export const LOADING_CUSTOMER_LEDGER = "LOADING_CUSTOMER_LEDGER";
export const INITIALISATION_DETAILS_CLIENT = "INITIALISATION_DETAILS_CLIENT";