import axios from "axios";
import history from "./history";

const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      history.push("/login");
      window.location.reload();

      // clearing cookies
      window?.localStorage?.removeItem("user");
      localStorage?.clear();
      sessionStorage?.clear();
      indexedDB?.deleteDatabase("localforage");
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
