import React, { useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Box from "@material-ui/core/Box";
import clsx from "clsx";
import { useTableStyles } from "../../../../theme/table.style";
import PrintIcon from "@material-ui/icons/Print";
import CircularProgress from '@mui/material/CircularProgress';
import DialogContentText from '@mui/material/DialogContentText';
import {
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  ButtonGroup,
  Button,
  Modal,
  CardContent,
  Card,
  Typography,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import ExpandMore from "@material-ui/icons/ExpandMore";
import {
  LoadTransferRequestById,
  updateSelectedTransferRequest,
  updateTransferRequestStatus,
} from "../../../../store/transferRequest/transferRequest.action";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { formatDate } from "../../../../helpers/utils";
import { Empty } from "../../../../components/empty";
import { useTranslation } from "react-i18next";
import { statusFormat } from "../../../../helpers/utils";
import { Products } from "../products";
import { SimpleTable } from "../../../../components/simpleTable";
import { useState } from "react";
import { Loader } from "../../../../components/loader";
import ReactToPrint from "react-to-print";
import BonDeTransfert from "../../../../components/componentToPrint/bonTransfert";
import { SetNotification } from "../../../../store/notification/notification.action";

const useStyles = makeStyles((theme) => ({
  modalTitle: {
    borderTop: "1px solid  #5D6975",
    borderBottom: "1px solid  #5D6975",
    color: "#5D6975",
    fontSize: "30px",
    lineHeight: "1.4em",
    fontWeight: "normal",
    textAlign: "center",
    margin: "0 0 20px 0",
  },
  root: {
    flexGrow: 1,
    position: "absolute",
    width: 700,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    borderRadius: 10,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  },
  card: {
    minWidth: 200,
  },
  titlecard: {
    borderTop: "1px solid  #5D6975",
    borderBottom: "1px solid  #5D6975",
    color: "#5D6975",
    fontSize: "20px",
    lineHeight: "1.4em",
    fontWeight: "normal",
    textAlign: "center",
    margin: "0 0 20px 0",
  },
  paper: {
    overflow: "auto",
    padding: "0px 10px",
  },
  status: {
    padding: "5px 10px",
    marginTop: 20,
  },
  company: {
    float: "left",
    textAlign: "left",
  },
  title: {
    fontWeight: "bold",
    fontSize: 20,
    marginBottom: theme.spacing(2),
  },
  subtitle: {
    fontSize: 15,
    marginBottom: theme.spacing(1),
  },
  avatar: {
    width: 24,
    height: 24,
  },
  avatarCell: {
    width: 24,
  },
  selectInput: {
    "& .MuiInputBase-root": {
      background: "#F4F7F9",
      borderRadius: 5,
      border: 0,
    },
    "& .MuiOutlinedInput-input": {
      padding: "2.9px 14px",
      fontSize: 14,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

export const TransferRequestsTable = (props) => {
  const classes = useStyles();
  const tableClasses = useTableStyles();
  const { t } = useTranslation(["transfert", "common"]);
  const selectedData = useSelector((state) => state.Login.selectedData);
  const componentRef = useRef();
  const [loadingButton, setLoadingButton] = useState(false);
  //const [pickLitsList, setPickListList] = useState([]);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [selectedMaterialRequest, setSelectedMaterialRequest] = useState(null);
  const [linkedPickLists, setLinkedPickLists] = useState([]);

  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pickListDoc, setPickListDoc] = useState(null);

  const transferRequest = useSelector(
    (state) => state.Transfert.selectedTransfertRequest
  );

  const handleOpen = async (row) => {
    await dispatch(LoadTransferRequestById(row?.name));
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    dispatch(updateSelectedTransferRequest(null));
  };

  const header = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
    Authorization: JSON.parse(localStorage.getItem("user")).token,
  };

  const handleChangeStatus = async (name, status) => {
    if (status === "submit") {
      setLoading(true);
      setPickListDoc(null);
      fetch(`${process.env.REACT_APP_API_URI}/api/update-status`, {
        method: "POST",
        headers: header,
        body: JSON.stringify({
          doctype: "Material Request",
          action: status,
          docnames: [name],
        }),
      })
        .then((res) => {
          if (!res.ok) {
            throw Error("Something went wrong");
          }
          return res.json();
        })
        .then((resp) => {
          fetch(`${process.env.REACT_APP_API_URI}/api/make-mapped-doc`, {
            method: "POST",
            headers: header,
            body: JSON.stringify({
              method:
                "erpnext.stock.doctype.material_request.material_request.create_pick_list",
              source_name: name,
            }),
          })
            .then((res) => res.json())
            .then((data) => {
              let reqData = {
                doc: data?.message,
                action: "Submit",
              };
              fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
                method: "POST",
                headers: header,
                body: JSON.stringify(reqData),
              })
                .then((res) => res.json())
                .then((doc) => {
                  setLoading(false);
                  setPickListDoc(doc?.docs?.[0]);
                });
            });
        });
    } else {
      let payload = {
        doctype: "Material Request",
        action: status,
        docs: [name],
      };
      await dispatch(updateTransferRequestStatus(payload));
      props.setTransferRequests();
    }
  };

  const getPickListList = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
        method: "POST",
        headers: header,
        body: JSON.stringify({
          doctype: "Pick List",
          fields: ["*"],
        }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to fetch pick lists');
      }
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching pick lists:', error);
      return [];
    }
  };

  const handleCancelMaterialRequest = async (row) => {
    try {
      const pickLists = await getPickListList();
      const linkedPickLists = pickLists.filter(
        (pickList) => pickList.material_request === row?.name
      );
      setLinkedPickLists(linkedPickLists);
      setSelectedMaterialRequest(row);
      setOpenConfirmModal(true);
    } catch (error) {
      dispatch(SetNotification({ code: "error", message: error.message }));
    }
  };

  const handleCancelAllDocuments = async () => {
    setLoadingButton(true);
    try {
      for (const pickList of linkedPickLists) {
        const cancelPickListResponse = await fetch(
          `${process.env.REACT_APP_API_URI}/api/cancel-doc`,
          {
            method: "POST",
            headers: header,
            body: JSON.stringify({
              doctype: "Pick List",
              name: pickList.name,
            }),
          }
        );
  
        if (!cancelPickListResponse.ok) {
          throw new Error(`Failed to cancel Pick List ${pickList.name}`);
        }
      }
  
      const cancelMaterialRequestResponse = await fetch(
        `${process.env.REACT_APP_API_URI}/api/cancel-doc`,
        {
          method: "POST",
          headers: header,
          body: JSON.stringify({
            doctype: "Material Request",
            name: selectedMaterialRequest?.name,
          }),
        }
      );
  
      if (!cancelMaterialRequestResponse.ok) {
        throw new Error("Failed to cancel Material Request");
      }
  
      const responseData = await cancelMaterialRequestResponse.json();
  
      setLoadingButton(false);
      setOpenConfirmModal(false);
  
      if (responseData.success === false) {
        dispatch(SetNotification({ code: "warning", message: responseData.message }));
      } else {
        dispatch(
          SetNotification({
            code: "success",
            message: `${t("Material Request Successfully Canceled")}`,
          })
        );
  
        handleReload();
      }
    } catch (error) {
      setLoadingButton(false);
      dispatch(SetNotification({ code: "error", message: error.message }));
    }
  };

  const handleReload = () => {
    window.location.reload();
  };

  const transReqDetailsColumns = [
    { key: "item_code", title: "common:ref" },
    { key: "item_name", title: "common:nom" },
    { key: "qty", title: "transfert:qtyDemandé" },
    { key: "ordered_qty", title: "transfert:qtyRecu" },
    { key: "warehouse", title: "common:mgsCible" },
  ];

  return (
    <React.Fragment>
      <Box className={tableClasses.paper}>
        <Table
          className={clsx(tableClasses.table, classes.selectInput)}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell className={tableClasses.tablecellHeader} align="left">
                {t("common:createDate")}
              </TableCell>
              <TableCell className={tableClasses.tablecellHeader} align="left">
                {t("common:ref")}
              </TableCell>
              <TableCell className={tableClasses.tablecellHeader} align="left">
                {t("transfert:title")}
              </TableCell>
              <TableCell className={tableClasses.tablecellHeader} align="left">
                {t("transfert:requise")}
              </TableCell>
              <TableCell className={tableClasses.tablecellHeader} align="left">
                {t("common:mgsSource")}
              </TableCell>
              <TableCell className={tableClasses.tablecellHeader} align="left">
                {t("common:mgsCible")}
              </TableCell>
              <TableCell className={tableClasses.tablecellHeader} align="left">
                {t("common:statut")}
              </TableCell>
              <TableCell
                className={tableClasses.tablecellHeader}
                align="right"
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.rows &&
              Array.isArray(props.rows) &&
              props.rows
                .filter(
                  (trq) =>
                    trq.set_warehouse == selectedData?.store ||
                    trq.set_from_warehouse == selectedData?.store
                )
                .map((row, index) => (
                  <TableRow className={tableClasses.tableRow} key={index}>
                    <TableCell
                      component={Link}
                      to={`/transfer-requests/${row.name}`}
                      className={tableClasses.tableCell}
                      align="left"
                    >
                      {formatDate(row.transaction_date, "YYYY-MM-DD")}
                    </TableCell>
                    <TableCell
                      component={Link}
                      to={`/transfer-requests/${row.name}`}
                      className={tableClasses.tableCell}
                      align="left"
                    >
                      {row.name}
                    </TableCell>
                    <TableCell
                      component={Link}
                      to={`/transfer-requests/${row.name}`}
                      className={tableClasses.tableCell}
                      align="left"
                    >
                      {row.title.replace(
                        "Material Transfer Request for",
                        t("transfert:MatTransfReqtfor")
                      )}
                    </TableCell>
                    <TableCell
                      component={Link}
                      to={`/transfer-requests/${row.name}`}
                      className={tableClasses.tableCell}
                      align="left"
                    >
                      {formatDate(row.schedule_date, "YYYY-MM-DD")}
                    </TableCell>
                    <TableCell
                      component={Link}
                      to={`/transfer-requests/${row.name}`}
                      className={tableClasses.tableCell}
                      align="left"
                    >
                      {row.set_from_warehouse}
                    </TableCell>
                    <TableCell
                      component={Link}
                      to={`/transfer-requests/${row.name}`}
                      className={tableClasses.tableCell}
                      align="left"
                    >
                      {row.set_warehouse}
                    </TableCell>
                    <TableCell
                      component={Link}
                      to={`/transfer-requests/${row.name}`}
                      className={clsx(
                        tableClasses.tableCell,
                        tableClasses[statusFormat(row.status).color]
                      )}
                      align="left"
                    >
                      {statusFormat(row.status).value}
                    </TableCell>
                    <TableCell className={tableClasses.tableCell} align="right">
                      {row.status !== "Cancelled" && (
                        <ButtonGroup
                          color="primary"
                          aria-label="outlined primary button group"
                        >
                          <Button
                            variant="outlined"
                            disabled={
                              row.status == "Transferred" ||
                              row.status == "Cancelled" ||
                              row.status == "Draft"
                            }
                            onClick={() => handleCancelMaterialRequest(row)
                            }
                          >
                            {t("common:annuler")}
                          </Button>
                          <Button
                            variant="contained"
                            disabled={
                              row.status == "Transferred" ||
                              row.status == "Pending" ||
                              row.status == "Cancelled" ||
                              selectedData?.store !== row.set_from_warehouse
                            }
                            onClick={() => handleOpen(row)}
                          >
                            {t("common:Soumise")}
                          </Button>
                        </ButtonGroup>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </Box>
      {props.rows && Array.isArray(props.rows) && props.rows?.length === 0 && (
        <Empty text={t("transfert:emptyTable")} />
      )}
      <Dialog
  open={openConfirmModal}
  onClose={() => setOpenConfirmModal(false)}
  aria-labelledby="confirm-cancel-dialog-title"
  aria-describedby="confirm-cancel-dialog-description"
>
  <DialogTitle id="confirm-cancel-dialog-title">
    {t("common:Confirmcancellation")}
  </DialogTitle>
  <DialogContent>
    <DialogContentText id="confirm-cancel-dialog-description">
    {t("common:MaterialRequest")} {selectedMaterialRequest?.name} {t("common:islinked")}
      <br /><br />
      {linkedPickLists.map((pickList, index) => (
        <React.Fragment key={index}>
          {t("common:Picklist")}: {pickList.name}<br />
        </React.Fragment>
      ))}
      <br />
      {t("common:linkedcancel")}
    </DialogContentText>
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setOpenConfirmModal(false)} color="primary">
      {t("common:cancel")}
    </Button>
    <Button 
      onClick={handleCancelAllDocuments} 
      color="primary" 
      variant="contained"
      disabled={loadingButton}
    >
      {loadingButton ? <CircularProgress size={24} /> : t("common:CancelAll")}
    </Button>
  </DialogActions>
</Dialog>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle align={"center"}>
          <Typography
            align={"center"}
            color={"primary"}
            className={classes.title}
          >
            {t("transfert:msgNotif")} {transferRequest?.name} ?
          </Typography>
          <Typography
            align={"center"}
            color={"primary"}
            className={classes.subtitle}
          >
            {t("transfert:dateTransaction")}:
            {transferRequest?.transaction_date ?? "_"}
          </Typography>
          <Typography
            align={"center"}
            color={"primary"}
            className={classes.subtitle}
          >
            {t("transfert:schedule_date")}:
            {transferRequest?.schedule_date ?? "_"}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box display="flex">
            <Grid item xs={12} md={6}>
              <Typography
                variant="h5"
                component="h2"
                className={classes.titlecard}
              >
                De:{" "}
                {transferRequest?.set_from_warehouse ??
                  transferRequest?.items?.[0]?.from_warehouse}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography className={classes.titlecard}>
                A:{" "}
                {transferRequest?.set_warehouse ??
                  transferRequest?.items?.[0]?.warehouse}
              </Typography>
            </Grid>
          </Box>
          <Box className={classes.paper} style={{ marginTop: 10 }}>
            <Typography color="primary">
              {t("transfert:ProduitDemande")}
            </Typography>
            <SimpleTable
              title={"transfertReqDetails"}
              data={transferRequest?.items}
              columns={transReqDetailsColumns}
              hasPaper={true}
            />
            <Box style={{ paddingLeft: 30 }}>
              <TableRow style={{ paddingLeft: 30 }}>
                <TableCell colSpan={1} className={tableClasses.tableCell}>
                  {t("transfert:totalQtyDemande")}
                </TableCell>
                <TableCell align="left" className={tableClasses.tableCell}>
                  {Array.isArray(transferRequest?.items)
                    ? transferRequest?.items
                        .map((item) => item.qty)
                        .reduce((prev, curr) => prev + curr, 0)
                    : 0}
                </TableCell>
              </TableRow>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 5,
            }}
          >
            {pickListDoc && (
              <ReactToPrint
                trigger={() => (
                  <Button
                    startIcon={<PrintIcon />}
                    variant="outlined"
                    color="primary"
                  >
                    Imprimer Bon de Transfert
                  </Button>
                )}
                content={() => componentRef.current}
                onAfterPrint={() => {
                  props.setTransferRequests();
                  handleClose();
                }}
              />
            )}
            <Button
              style={{ ml: 2 }}
              variant="contained"
              color="primary"
              disabled={loading}
              onClick={() =>
                handleChangeStatus(transferRequest?.name, "submit")
              }
            >
              Accepter
            </Button>
            <Button
              style={{ ml: 2 }}
              disabled={loading}
              color="primary"
              onClick={handleClose}
            >
              {t("common:fermer")}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <div style={{ display: "none" }}>
        <BonDeTransfert
          ref={componentRef}
          data={transferRequest}
          doc={pickListDoc}
        />
      </div>
    </React.Fragment>
  );
};
