import { GetData } from "../../helpers/request";
import {
  CREATE_TRANSFER_REQUEST,
  CREATE_TRANSFER_SUCCESS,
  UPDATE_STATUS,
  UPDATE_STATUS_SUCCESS,
  SET_TRANSFERT_DATA,
  SET_SELECTED_TRANSFERT_REQUEST,
  DOCUMENTS_COUNT_LOADED,
  UPDATE_ITEMS_REQUEST,
  SET_SELECTED_WAREHOUSE_CIBLE,
  GET_MATERIAL_REQUEST_PURCHASE,
  MATERIAL_REQUEST_PURCHASE_LOADED,
  SET_PRODUCTS_STOCK,
} from "./transferRequest.type";
import { FAILED_ADD_DATA } from "../rollback/rollback.type";
import axiosInstance from "../../history/axiosInstance";

export const CreateTransferRequest = (content) => {
  const action = {
    type: CREATE_TRANSFER_REQUEST,
    payload: content,
    meta: {
      offline: {
        // the network action to execute:
        effect: {
          url: `${process.env.REACT_APP_API_URI}/api/transfer-requests/create`,
          method: "POST",
          body: JSON.stringify({
            action: "Save",
            doc: {
              doctype: "Material Request",
              material_request_type: "Material Transfer",
              transaction_date: new Date().toISOString().slice(0, 10),
              schedule_date: "2024-06-30",
              ...content,
            },
          }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
            Authorization: JSON.parse(localStorage.getItem("user")).token,
          },
        },
        // action to dispatch when effect succeeds:
        commit: {
          type: CREATE_TRANSFER_SUCCESS,
          meta: {
            notification: {
              code: "success",
              message: "notification:transReqCreated",
            },
            redirectTo: "/transfer-requests",
          },
        },
        rollback: { type: FAILED_ADD_DATA },
      },
    },
  };
  action.meta.offline.rollback.meta = action;
  return (dispatch, getState) => {
    dispatch(action);
  };
};

export const getTransferRequests = (company) => {
  const data = {
    doctype: "Material Request",
    fields: [
      "`tabMaterial Request`.`name`",
      "`tabMaterial Request`.`creation`",
      "`tabMaterial Request`.`modified`",
      "`tabMaterial Request`.`material_request_type`",
      "`tabMaterial Request`.`status`",
      "`tabMaterial Request`.`schedule_date`",
      "`tabMaterial Request`.`transaction_date`",
      "`tabMaterial Request`.`title`",
      "`tabMaterial Request`.`set_warehouse`",
      "`tabMaterial Request`.`set_from_warehouse`",
    ],
    filters: [
      ["Material Request", "company", "=", `${company}`],
      ["Material Request", "material_request_type", "=", "Material Transfer"],
    ],
    order_by: "`tabMaterial Request`.`modified` DESC",
  };
  const url = `${process.env.REACT_APP_API_URI}/api/transfer-requests`;

  return GetData(url, data);
};

export const LoadMaterialRequestPurchase = (company) => ({
  type: GET_MATERIAL_REQUEST_PURCHASE,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${process.env.REACT_APP_API_URI}/api/transfer-requests`,
        method: "POST",
        body: JSON.stringify({
          doctype: "Material Request",
          fields: [
            "`tabMaterial Request`.`name`",
            "`tabMaterial Request`.`creation`",
            "`tabMaterial Request`.`material_request_type`",
            "`tabMaterial Request`.`status`",
            "`tabMaterial Request`.`schedule_date`",
            "`tabMaterial Request`.`transaction_date`",
            "`tabMaterial Request`.`title`",
            "`tabMaterial Request`.`set_warehouse`",
            "`tabMaterial Request`.`set_from_warehouse`",
          ],
          filters: [
            ["Material Request", "company", "=", `${company}`],
            ["Material Request", "material_request_type", "=", "Purchase"],
          ],
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
          Authorization: JSON.parse(localStorage.getItem("user")).token,
        },
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: MATERIAL_REQUEST_PURCHASE_LOADED,
        meta: "",
      },
    },
  },
});

export const updateTransferRequestStatus = (content) => {
  const action = {
    type: UPDATE_STATUS,
    payload: content,
    meta: {
      offline: {
        // the network action to execute:
        effect: {
          url: `${process.env.REACT_APP_API_URI}/api/update-status`,
          method: "POST",
          body: JSON.stringify({
            doctype: content.doctype,
            action: content.action,
            docnames: content.docs,
          }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
            Authorization: JSON.parse(localStorage.getItem("user")).token,
          },
        },
        // action to dispatch when effect succeeds:
        commit: {
          type: UPDATE_STATUS_SUCCESS,
          meta: {
            notification: {
              code: "success",
              message: "notification:statusChanged",
            },
            then: (data) => (dispatch, getState) => {
              window.location.reload();
            },
          },
        },
        rollback: { type: FAILED_ADD_DATA },
      },
    },
  };
  action.meta.offline.rollback.meta = action;
  return (dispatch, getState) => {
    dispatch(action);
  };
};

export const SetTransfertData = (content) => {
  return {
    type: SET_TRANSFERT_DATA,
    payload: content,
  };
};

export const LoadTransferRequestById = (id) => {
  return (dispatch) => {
    const header = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem("user")).token,
      },
    };
    return axiosInstance
      .get(
        `${process.env.REACT_APP_API_URI}/api/details?doctype=Material%20Request&name=${id}`,
        header
      )
      .then(async ({ data }) => {
        await dispatch(updateSelectedTransferRequest(data));
      });
  };
};

export function updateSelectedTransferRequest(transferRequest) {
  return (dispatch, getState) => {
    dispatch({
      type: SET_SELECTED_TRANSFERT_REQUEST,
      payload: transferRequest,
    });
  };
}

export const LoadDocuments = (type, id) => {
  return (dispatch) => {
    const header = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem("user")).token,
      },
    };
    return axiosInstance
      .get(
        `${process.env.REACT_APP_API_URI}/api/order/doc-numbers?doctype=${type}&name=${id}&items=%5B%22Stock%20Entry%22%5D`,
        header
      )
      .then(({ data }) => {
        dispatch(SetTransferRequestDocumentsNumber(data));
      });
  };
};

export function SetTransferRequestDocumentsNumber(data) {
  return (dispatch, getState) => {
    dispatch({
      type: DOCUMENTS_COUNT_LOADED,
      documentNumber: data,
    });
  };
}

export const updateCurrentRequest = (content) => {
  return {
    type: UPDATE_ITEMS_REQUEST,
    payload: content,
  };
};

export const LoadWarehouseCibleByName = (name) => {
  return (dispatch) => {
    const header = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem("user")).token,
      },
    };
    return axiosInstance
      .get(
        `${process.env.REACT_APP_API_URI}/api/details?doctype=Warehouse&name=${name}`,
        header
      )
      .then(({ data }) => {
        dispatch(updateSelectedWarehouseCible(data));
      });
  };
};

export function updateSelectedWarehouseCible(cible) {
  return (dispatch, getState) => {
    dispatch({
      type: SET_SELECTED_WAREHOUSE_CIBLE,
      payload: cible,
    });
  };
}

export const LoadProductsStock = async (id) => {
  const header = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
      Authorization: JSON.parse(localStorage.getItem("user")).token,
    },
  };
  return axiosInstance
    .post(
      `${process.env.REACT_APP_API_URI}/api/stock/dashboard`,
      JSON.stringify({
        item_code: id,
      }),
      header
    )
    .catch((error) => {
      console.log(error);
    });
};
export function SetProductsStock(data) {
  return (dispatch, getState) => {
    dispatch({
      type: SET_PRODUCTS_STOCK,
      payload: data,
    });
  };
}
