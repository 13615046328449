import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Box from "@material-ui/core/Box";
import BarChartIcon from "@material-ui/icons/BarChart";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import clsx from "clsx";
import { useTableStyles } from "../../../../theme/table.style";
import { useSelector } from "react-redux";
import { GetOrderdProducts } from "../../../../helpers/helper";
import { useTranslation } from "react-i18next";
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  IconButton,
  Popover,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import axiosInstance from "../../../../history/axiosInstance";
import { formatDate } from "../../../../helpers/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "1.0625rem 0.2rem",
  },
  price: {
    fontWeight: 600,
  },
  filterList: {
    borderTop: "none",
  },
}));

export const Invoicing = (props) => {
  const classes = useStyles();
  const tableClasses = useTableStyles();
  const globalDefaults = useSelector((state) => state.Login.globalDefaults);
  const { t } = useTranslation(["order", "common", "fulfillment"]);
  const stockQtyReport = useSelector((state) => state.Inventory.stockQtyReport);
  const items = useSelector((state) => state.Product.products);

  const [loading, setLoading] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const [filteredItems, setFilteredItems] = useState([]);
  const [item, setItem] = useState("");

  const handleClick = (event, itemCode) => {
    setAnchorEl(event.currentTarget);
    setItem(itemCode);
    const filtered = stockQtyReport?.filter(
      (item) => item?.item_code === itemCode
    );
    setFilteredItems(filtered);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);
  const id = openPopover ? "simple-popover" : undefined;

  const [itemState, setItemState] = useState(null);

  async function getItemDetails(item) {
    setLoading(true);
    const response = await axiosInstance.post(
      `${process.env.REACT_APP_API_URI}/api/item/item-details`,
      {
        args: {
          item_code: item,
          company: props?.doc?.company,
          doctype: "Sales Order",
          customer: props?.doc?.customer,
          order_type: "Sales",
          conversion_rate: 1,
          conversion_factor: 0,
          currency: props.doc?.currency,
          update_stock: 0,
          set_warehouse: props.orderState?.set_warehouse,
          warehouse: props.orderState?.set_warehouse,
          name: props.doc?.name,
          price_list: props.doc?.selling_price_list,
          price_list_currency: props.doc?.price_list_currency,
          plc_conversion_rate: 1,
          is_pos: 0,
          is_return: 0,
          ignore_pricing_rule: 0,
        },
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
          Authorization: JSON.parse(localStorage.getItem("user")).token,
        },
      }
    );
    if (response.data?.message) {
      setLoading(false);
      let item = {
        ...response.data?.message,
        doctype: "Sales Order Item",
        item_group: response.data.message?.item_group,
        stock_uom: response.data.message?.stock_uom,
        parentfield: "items",
        parenttype: "Sales Order",
        schedule_date: props?.orderState?.delivery_date
          ? props?.orderState?.delivery_date
          : formatDate(new Date(), "YYYY-MM-DD"),
        item_code: response.data.message?.item_code,
        uom: response.data.message?.uom,
        item_name: response.data.message?.item_name,
        description: response.data.message?.description,
        warehouse:
          props?.orderState.set_warehouse !== ""
            ? props?.orderState.set_warehouse
            : response.data.message?.warehouse,
        income_account: response.data.message?.income_account,
        expense_account: response.data.message?.expense_account,
        cost_center: response.data.message?.cost_center,
        qty: 1,
        stock_qty: 1,
        conversion_factor: 1,
      };
      setItemState(item);
    } else {
      setLoading(false);
    }
  }

  const handleItemChange = (e) => {
    setItemState({ ...itemState, [e.target.name]: e.target.value });
  };

  const handleItemInsert = (item) => {
    if (item) {
      getItemDetails(item.item_code);
      setItem(item.item_code);
    }
  };

  const handleInsert = () => {
    props?.setOrderState({
      ...props?.orderState,
      items: [
        ...props?.orderState?.items,
        {
          ...itemState,
          docstatus: 0,
          __unsaved: 1,
          __unedited: false,
          parent: props?.orderState?.name,
          transaction_date: props?.orderState?.transaction_date,
          name: "new-sales-order-item-1",
          __islocal: 1,
          delivery_date: props?.orderState?.delivery_date,
          idx: props?.orderState?.items?.length + 1,
        },
      ],
    });
    setItemState(null);
    setItem("");
    props.setOpenInputs(false);
  };

  const handleRemoveRow = (idx) => {
    let newItems = [...props.orderState?.items];
    newItems.splice(idx, 1);
    props?.setOrderState({ ...props.orderState, items: newItems });
  };

  function isInsertDisabled() {
    if (!itemState) return true;
    if (!itemState?.qty) return true;
    return false;
  }

  return (
    <Box>
      {props.order?.status === "to verify" && (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            paddingBottom: "10px",
          }}
        >
          <Button
            onClick={() => props.setOpenInputs(!props.openInputs)}
            color="primary"
            variant="text"
            size="small"
          >
            <AddIcon />
          </Button>
        </div>
      )}
      <Table className={tableClasses.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell
              className={tableClasses.tablecellHeader}
              align="left"
              width={200}
            >
              {t("common:ref")}
            </TableCell>
            <TableCell className={tableClasses.tablecellHeader} align="center">
              {t("common:name")}
            </TableCell>
            <TableCell className={tableClasses.tablecellHeader} align="center">
              {t("common:qtyOrdered")}
            </TableCell>
            <TableCell className={tableClasses.tablecellHeader} align="center">
              {t("common:prix")} H.T
            </TableCell>
            <TableCell className={tableClasses.tablecellHeader} align="center">
              {t("common:totalPrice")} H.T
            </TableCell>
            <TableCell className={tableClasses.tablecellHeader} width={20}>
              Actual Qty
            </TableCell>
            <TableCell
              className={tableClasses.tablecellHeader}
              width={20}
            ></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.orderState?.items?.map((row, index) => (
            <TableRow className={tableClasses.tableRow} key={index}>
              <TableCell
                className={tableClasses.tableCell}
                align="left"
                width={200}
              >
                <span
                  onClick={(e) => handleClick(e, row.item_code)}
                  style={{
                    fontWeight: 700,
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  aria-describedby={id}
                >
                  {row.item_code}
                </span>
              </TableCell>
              <TableCell className={tableClasses.tableCell} align="center">
                {row.item_name ?? "-"}
              </TableCell>
              <TableCell className={tableClasses.tableCell} align="center">
                {row.qty}
              </TableCell>
              <TableCell
                className={clsx(tableClasses.tableCell, classes.price)}
                align="center"
              >
                {`${parseFloat(row?.price_list_rate)?.toFixed(3)} ${
                  props.doc?.currency
                }`}
              </TableCell>
              <TableCell
                className={clsx(tableClasses.tableCell, classes.price)}
                align="center"
              >
                {`${parseFloat(row?.price_list_rate * row?.qty)?.toFixed(3)} ${
                  props.doc?.currency
                }`}
              </TableCell>
              <TableCell className={tableClasses.tableCell} width={20}>
                {row?.warehouse + ": "}
                <strong>{row?.actual_qty + " " + row?.stock_uom}</strong>
              </TableCell>
              <TableCell
                className={tableClasses.tableCell}
                width={20}
                align="right"
              >
                {props.order?.status === "to verify" ? (
                  <Tooltip title="Delete">
                    <IconButton
                      disabled={props.order?.status === "canceled"}
                      size="small"
                      onClick={() => handleRemoveRow(index)}
                    >
                      <DeleteIcon color="error" />
                    </IconButton>
                  </Tooltip>
                ) : null}
              </TableCell>
            </TableRow>
          ))}
          {(props.openInputs || props.orderState?.items?.length === 0) && (
            <TableRow className={tableClasses.tableRow}>
              <TableCell width={200} className={tableClasses.tableCell}>
                <Autocomplete
                  size="small"
                  style={{ width: "100%" }}
                  id="combo-box-demo"
                  onChange={(event, newValue) => handleItemInsert(newValue)}
                  options={items}
                  getOptionLabel={(option) => option.item_code}
                  onInputChange={(event, newInputValue) => {
                    if (!newInputValue) {
                      setItemState(null);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {itemState ? (
                              <Tooltip title={t("common:stcklvl")}>
                                <IconButton
                                  size="small"
                                  onClick={(e) => handleClick(e, item)}
                                  aria-describedby={id}
                                >
                                  <BarChartIcon color="primary" />
                                </IconButton>
                              </Tooltip>
                            ) : null}
                            {loading ? (
                              <CircularProgress color="primary" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </TableCell>
              <TableCell className={tableClasses.tableCell} align="center">
                {itemState?.item_name ?? "-"}
              </TableCell>
              <TableCell className={tableClasses.tableCell} width={120}>
                <TextField
                  value={itemState?.qty ?? 0}
                  error={!itemState?.qty}
                  onChange={handleItemChange}
                  name="qty"
                  size="small"
                  type="number"
                  variant="outlined"
                  InputProps={
                    itemState?.item_code && {
                      startAdornment: (
                        <>
                          <IconButton
                            style={{ marginLeft: -10 }}
                            onClick={() =>
                              itemState?.qty > 1 &&
                              setItemState({
                                ...itemState,
                                qty: itemState?.qty - 1,
                                stock_qty: itemState?.stock_qty - 1,
                              })
                            }
                            size="small"
                          >
                            <RemoveIcon />
                          </IconButton>
                        </>
                      ),
                      endAdornment: (
                        <>
                          <IconButton
                            style={{ marginRight: -10 }}
                            onClick={() =>
                              itemState?.qty >= 1 &&
                              setItemState({
                                ...itemState,
                                qty: itemState?.qty + 1,
                                stock_qty: itemState?.stock_qty + 1,
                              })
                            }
                            size="small"
                          >
                            <AddIcon />
                          </IconButton>
                        </>
                      ),
                    }
                  }
                />
              </TableCell>
              <TableCell className={tableClasses.tableCell} align="center">
                {itemState?.price_list_rate?.toFixed(3) ?? "-"}{" "}
                {props.doc?.currency}
              </TableCell>
              <TableCell className={tableClasses.tableCell} align="center">
                {itemState?.price_list_rate
                  ? (itemState?.price_list_rate * itemState?.qty)?.toFixed(3)
                  : "-"}{" "}
                {props.doc?.currency}
              </TableCell>
              <TableCell className={tableClasses.tableCell} width={50}>
                {itemState
                  ? itemState?.warehouse +
                    ": " +
                    itemState?.actual_qty +
                    " " +
                    itemState?.stock_uom
                  : "-"}
              </TableCell>
              <TableCell
                className={tableClasses.tableCell}
                width={50}
                align="right"
              >
                <Tooltip title="Insert">
                  <IconButton
                    disabled={isInsertDisabled()}
                    onClick={handleInsert}
                  >
                    <DoneIcon
                      color={isInsertDisabled() ? "disabled" : "primary"}
                    />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Popover
        id={id}
        open={openPopover}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Card style={{ minWidth: 275 }}>
          <CardContent>
            <Typography
              style={{
                fontWeight: 600,
                fontSize: 14,
                display: "flex",
                alignItems: "center",
                gap: 10,
              }}
              color="primary"
              gutterBottom
            >
              {t("common:stcklvl")} <BarChartIcon color="primary" />
            </Typography>
            <Typography
              variant="h5"
              style={{ fontWeight: 600 }}
              component="div"
            >
              {t("common:itemCode")}: {item}
            </Typography>
            {filteredItems?.map((el, i) => (
              <Typography
                variant="body2"
                key={i}
                style={{ paddingTop: "10px" }}
              >
                <strong>{el.warehouse}</strong>
                <Divider style={{ marginBottom: 4 }} />
                <li>
                  {t("common:actual_qty")}: <strong>{el?.actual_qty}</strong>
                </li>
                <li>
                  {t("common:projected_qty")}:{" "}
                  <strong>{el?.projected_qty}</strong>
                </li>
                <li>
                  {t("common:reservedqty")}: <strong>{el?.reserved_qty}</strong>
                </li>
              </Typography>
            ))}
          </CardContent>
        </Card>
      </Popover>
    </Box>
  );
};
