export const MATERIAL_REQUEST =
  "Une nouvelle demande de transfert name vers l'entrepôt set_warehouse a été créée";
export const STOCK_ENTRY =
  "Le stock de to_warehouse a été mis à jour par l'entrée name";
export const FULFILLMENT = "Un client a passé une nouvelle commande name";
export const FULLFILMENT_PACKING =
  "Un nouveau pack de commande order_id a été mis en place";
export const HOLD =
  "Une commande a été reservée au nom du client name_client dans le magasin to_warehouse";

export const AUTO_REORDER =
  "Une nouvelle demande de réassortiment name a été automatiquement générée";
export const COFFRE =
  "La caisse cashier a déposé amount (payment_method) - warehouse";
