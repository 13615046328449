import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
  makeStyles,
} from "@material-ui/core";

import CardMembershipIcon from "@material-ui/icons/CardMembership";
import DeleteIcon from "@material-ui/icons/Delete";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import InfoIcon from "@material-ui/icons/Info";

import axiosInstance from "../../../../../history/axiosInstance";

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  GetLoyaltyProgramDetails,
  SetLoyaltyProgramDetails,
  UpdateAppliedLoyCard,
} from "../../../../../store/cart/cart.action";
import { UpdateClient } from "../../../../../store/checkout/checkout.action";

const useStyles = makeStyles((theme) => ({
  button: {
    width: "100%",
    height: 90,
    backgroundColor: theme.palette.secondary.main,
    fontSize: 16,
    fontWeight: "bold",
    border: "3px solid",
    borderColor: theme.palette.blue.main,
    "&:hover": {
      backgroundColor: theme.palette.productsTable.button,
      color: theme.palette.freeButton.background,
      border: "3px solid",
      borderColor: theme.palette.secondary.main,
    },
  },
  activatedButton: {
    color: theme.palette.freeButton.background,
    width: "100%",
    height: 90,
    fontSize: 16,
    fontWeight: "bold",
    backgroundColor: theme.palette.productsTable.button,
    border: "3px solid",
    borderColor: theme.palette.secondary.main,
    "&:hover": {
      color: theme.palette.productsTable.button,
      backgroundColor: theme.palette.secondary.main,
      border: "3px solid",
      borderColor: theme.palette.blue.main,
    },
  },
}));

function LoyaltyCard() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation(["checkout"]);

  /* STORE */
  const globalDefaults = useSelector((state) => state.Login.globalDefaults);
  const detailClient = useSelector((state) => state.Checkout.detailClient);
  const clients = useSelector((state) => state.Client.clients);
  const appliedLoyaltyCard = useSelector(
    (state) => state.Cart.appliedLoyaltyCard
  );
  const loyaltyProgramDetails = useSelector(
    (state) => state.Cart.loyaltyProgramDetails
  );

  const [open, setOpen] = useState(false);
  const [form, setForm] = useState({
    card_id: appliedLoyaltyCard?.name ?? "",
    redeem_points_amount: appliedLoyaltyCard?.redeem_points_amount ?? 0,
  });

  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(null);

  const [redeem, setRedeem] = useState(
    appliedLoyaltyCard?.redeem_points_amount ? true : false
  );

  const getLoyCardById = async (cardId) => {
    setIsPending(true);
    return axiosInstance
      .get(
        `${process.env.REACT_APP_API_URI}/api/details?doctype=Loyalty%20Card&name=${cardId}`,
        {
          headers: {
            "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
            Authorization: JSON.parse(localStorage.getItem("user"))?.token,
          },
        }
      )
      .then(({ data }) => {
        if (!data?.docs?.[0]) {
          setIsPending(false);
          setError({
            message: t("checkout:cardnotfound"),
          });
          return;
        }
        dispatch(UpdateAppliedLoyCard(data?.docs[0]));
        dispatch(
          GetLoyaltyProgramDetails({
            customer: data?.docs[0].customer,
            loyalty_program: data?.docs[0].loyalty_program,
          })
        );

        const customerIndex = clients?.findIndex(
          (client) => client.name === data?.docs[0].customer
        );

        // update selected customer
        if (clients?.[customerIndex] && customerIndex !== -1) {
          dispatch(UpdateClient(clients[customerIndex]));
        }

        setIsPending(false);
        setError(null);
        setForm({
          card_id: "",
          redeem_points_amount: 0,
        });
      })
      .catch((err) => {
        setIsPending(false);
        setError(err.message);
      });
  };

  const getAmount = () => {
    if (
      !detailClient?.__onload?.dashboard_info[0]?.loyalty_points ||
      !loyaltyProgramDetails?.conversion_factor
    ) {
      return 0;
    }
    return (
      detailClient?.__onload?.dashboard_info[0]?.loyalty_points *
      loyaltyProgramDetails?.conversion_factor
    );
  };

  return (
    <>
      <Grid item xs={6}>
        <Button
          onClick={() => setOpen(true)}
          className={
            !appliedLoyaltyCard ? classes.button : classes.activatedButton
          }
          variant="outlined"
          color="primary"
        >
          <Grid container direction="column" alignContent="center">
            <Grid item>
              <CardMembershipIcon />
            </Grid>
            <Grid item>{t("checkout:lyty")}</Grid>
          </Grid>
        </Button>
      </Grid>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="sm"
        fullWidth
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle align={"center"}>
          {/* <Typography
            align={"center"}
            color={"primary"}
            style={{ fontWeight: "bold", fontSize: 20 }}
          >
            {t("checkout:applp")}
          </Typography> */}
          {/* <Typography
            align={"center"}
            color={"primary"}
            style={{ fontWeight: "thin", fontSize: 12 }}
          >
            {t("checkout:lcid")}
          </Typography> */}
          {appliedLoyaltyCard ? (
            <Typography
              align={"center"}
              color={"primary"}
              style={{ fontWeight: "bold", fontSize: 14 }}
            >
              {t("checkout:customer")}: {appliedLoyaltyCard?.customer}
            </Typography>
          ) : null}
          {appliedLoyaltyCard ? (
            <Box
              style={{ marginTop: 20 }}
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              p={1}
              borderRadius={10}
              bgcolor={"#4caf50"}
            >
              <Typography
                style={{
                  fontSize: 15,
                  color: "white",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 10,
                }}
              >
                <InfoIcon />
                {t("checkout:appliedcard")} : {appliedLoyaltyCard?.name} /{" "}
                {appliedLoyaltyCard?.loyalty_program}
              </Typography>
              <IconButton
                style={{ margin: 0 }}
                onClick={() => {
                  dispatch(UpdateAppliedLoyCard(null));
                  dispatch(SetLoyaltyProgramDetails(null));
                }}
              >
                <DeleteIcon style={{ color: "white" }} />
              </IconButton>
            </Box>
          ) : error ? (
            <Box
              style={{ marginTop: 20 }}
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              p={1}
              borderRadius={10}
              bgcolor={"#f44336"}
            >
              <Typography
                style={{
                  fontSize: 15,
                  color: "white",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 10,
                }}
              >
                <InfoIcon />
                {error.message ? error.message : t("checkout:sww")}
              </Typography>
              <IconButton style={{ margin: 0 }} onClick={() => setError(null)}>
                <CloseIcon style={{ color: "white" }} />
              </IconButton>
            </Box>
          ) : null}
        </DialogTitle>
        <DialogContent
          style={{
            marginBottom: 15,
            display: "flex",
            flexDirection: "column",
            gap: 20,
          }}
        >
          {/* form */}

          {!appliedLoyaltyCard ? (
            <FormControl disabled={isPending} variant="outlined">
              <InputLabel id="demo-simple-select-outlined-label">
                {t("checkout:lcardid")}
              </InputLabel>
              <OutlinedInput
                endAdornment={
                  <Button
                    disabled={isPending || !form.card_id || appliedLoyaltyCard}
                    color="primary"
                    startIcon={<DoneIcon />}
                    variant="contained"
                    onClick={async () => await getLoyCardById(form.card_id)}
                  >
                    {t("checkout:app")}
                  </Button>
                }
                onKeyPress={async (e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    await getLoyCardById(form.card_id);
                  }
                }}
                startAdornment={
                  <InputAdornment position="start">
                    <CardMembershipIcon />
                  </InputAdornment>
                }
                id="outlined-adornment-weight"
                value={form.card_id}
                onChange={(e) => {
                  setForm({ ...form, card_id: e.target.value });
                }}
                aria-describedby="outlined-weight-helper-text"
                placeholder="LOY-CRD-YYYY-XXXXX"
                inputProps={{
                  "aria-label": "weight",
                  style: { fontSize: 14 },
                }}
                label={t("checkout:lcardid")}
                style={{ backgroundColor: "#fff" }}
              />
            </FormControl>
          ) : null}

          {detailClient?.name === appliedLoyaltyCard?.customer ? (
            <>
              <Box
                style={{
                  paddingBottom: 5,
                  paddingLeft: 15,
                  paddingRight: 15,
                  paddingTop: 5,
                }}
                bgcolor={"#AAB3BF"}
                borderRadius={10}
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <FormControlLabel
                  disabled={
                    !detailClient?.__onload?.dashboard_info[0]?.loyalty_points
                  }
                  control={
                    <Checkbox
                      disabled={
                        !detailClient?.__onload?.dashboard_info[0]
                          ?.loyalty_points
                      }
                      checked={redeem}
                      onChange={() => setRedeem(!redeem)}
                      name="redeem"
                      color="primary"
                    />
                  }
                  label={
                    <span style={{ fontWeight: "bold", fontSize: 14 }}>
                      {t("checkout:redeem")}
                    </span>
                  }
                />
                <div
                  style={{
                    display: "flex",
                    gap: 12,
                    backgroundColor: "white",
                    paddingBottom: 5,
                    paddingLeft: 15,
                    paddingRight: 15,
                    paddingTop: 5,
                    borderRadius: 10,
                  }}
                >
                  <p
                    style={{
                      fontSize: 14,
                      fontWeight: "bolder",
                      padding: 0,
                      margin: 0,
                    }}
                  >
                    {t("checkout:ttlpoints")}
                    <br />
                    {detailClient?.__onload?.dashboard_info[0]
                      ?.loyalty_points ?? 0}
                  </p>
                  <Divider orientation="vertical" flexItem />
                  <p
                    style={{
                      fontSize: 14,
                      fontWeight: "bolder",
                      padding: 0,
                      margin: 0,
                    }}
                  >
                    {t("checkout:lypnts")}
                    <br />
                    {getAmount()} {globalDefaults?.default_currency}
                  </p>
                </div>
              </Box>
              {redeem &&
              detailClient?.__onload?.dashboard_info[0]?.loyalty_points > 0 ? (
                <div>
                  <FormControl disabled={isPending} variant="outlined">
                    <InputLabel id="demo-simple-select-outlined-label">
                      {t("checkout:lypnts")} : (
                      {globalDefaults?.default_currency})
                    </InputLabel>
                    <OutlinedInput
                      type="number"
                      id="outlined-adornment-weight"
                      value={form.redeem_points_amount}
                      onChange={(e) => {
                        if (
                          e.target.value <= getAmount() &&
                          e.target.value >= 0
                        ) {
                          setForm({
                            ...form,
                            redeem_points_amount: e.target.value,
                          });
                        }
                      }}
                      aria-describedby="outlined-weight-helper-text"
                      placeholder={t("checkout:muchpoints")}
                      inputProps={{
                        "aria-label": "weight",
                        style: { fontSize: 14 },
                      }}
                      label={
                        t("checkout:lypnts") +
                        " " +
                        `(${globalDefaults?.default_currency})`
                      }
                      style={{ backgroundColor: "#fff" }}
                    />
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: 2,
                        padding: "4px 0px",
                      }}
                    >
                      <InfoIcon style={{ width: 18, height: 18 }} />
                      <span style={{ fontSize: 12 }}>
                        You can redeem upto{" "}
                        <span style={{ fontWeight: "bold" }}>
                          {getAmount()} {globalDefaults?.default_currency}
                        </span>
                      </span>
                    </div>
                  </FormControl>
                </div>
              ) : null}
            </>
          ) : null}
        </DialogContent>
        <DialogActions>
          {appliedLoyaltyCard ? (
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                if (appliedLoyaltyCard) {
                  dispatch(
                    UpdateAppliedLoyCard({
                      ...appliedLoyaltyCard,
                      redeem_points_amount: parseFloat(
                        form.redeem_points_amount
                      ),
                    })
                  );
                } else {
                  setForm({
                    card_id: "",
                    redeem_points_amount: 0,
                  });
                  setError(null);
                }
                setOpen(false);
              }}
            >
              {t("checkout:accpt")}
            </Button>
          ) : null}
        </DialogActions>
      </Dialog>
    </>
  );
}

export default LoyaltyCard;
