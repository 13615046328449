export const INVENTORY_LOADED = "INVENTORY_LOADED";
export const STOCK_SUMMARY_LOADED = "STOCK_SUMMARY_LOADED";
export const GET_STOCK_SUMMARY = "GET_STOCK_SUMMARY";
export const STOCK_ENTRY_CREATED = "STOCK_ENTRY_CREATED";
export const CREATE_STOCK_ENTRY = "CREATE_STOCK_ENTRY";
export const STOCK_REPORT_LOADED = "STOCK_REPORT_LOADED";
export const GET_STOCK_REPORT = "GET_STOCK_REPORT";
export const GET_STOCK_QTY_DATA = "GET_STOCK_QTY_DATA";
export const STOCK_QTY_DATA_LOADED = "STOCK_QTY_DATA_LOADED";
export const STOCK_REPORT_DATA_LOADED = "STOCK_REPORT_DATA_LOADED";
export const CREATE_STOCK_REC = "CREATE_STOCK_REC";
export const STOCK_REC_CREATED = "STOCK_REC_CREATED";
export const SET_CHECKED_ITEMS = "SET_CHECKED_ITEMS";
export const CREATE_INV_LIST_SUCCESS = "CREATE_INV_LIST_SUCCESS";
export const CREATE_INV_LIST = "CREATE_INV_LIST";

export const INV_LISTS_LOADED = "INV_LISTS_LOADED";
export const GET_INV_LISTS = "GET_INV_LISTS";

export const GET_INV_LIST_BY_ID = "GET_INV_LIST_BY_ID";
export const INV_LIST_LOADED = "GET_INV_LIST_BY_ID";

export const UPDATE_LIST_STATUS_SUCCESS = "UPDATE_LIST_STATUS_SUCCESS";
export const UPDATE_LIST_STATUS = "UPDATE_LIST_STATUS";

export const SET_LIST_EDIT = "SET_LIST_EDIT";
export const UPDATE_SELECTED_PARAMS = "UPDATE_SELECTED_PARAMS";
