import React, { useEffect } from 'react';
import Box from '@material-ui/core/Box';
import { useStyles } from './style';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import { useSelector } from "react-redux";
import { symbol } from "../../helpers/utils";

export const SuggestionPrice = (props) => {
  const classes = useStyles();
  const client = useSelector((state) => state.Checkout.client);
  const currencies = useSelector((state) => state.Client.currencies);
  const globalDefaults = useSelector(state => state.Login.globalDefaults);
  const bank = client && client.default_currency ? currencies.find(((s) => s.name == client.default_currency)) : currencies.find(((s) => s.name == globalDefaults?.default_currency));
  const notes = bank && bank.bank_notes && bank.bank_notes.split(',');
  /*  const banknotes = notes && notes.length != 0 && notes.forEach(str => {
     return Number(str);
   }); */
  const calculbanknotes = () => {
    var bn = []
    if (notes && notes.length != 0) {
      notes.forEach(str => {
        bn.push(Number(str));
      })
    }
    return bn;
  };
  const banknotes = calculbanknotes();
  const exchangeRate = useSelector(state => state.Checkout.exchangeRate);
  // const banknotes = [50, 20, 10, 5];
  const [paySuggestions, setSuggestions] = React.useState([]);

  const currencySymbol = client && client.default_currency ?
    symbol(client.default_currency, currencies)?.symbol :
    props.currency ? symbol(props.currency, currencies)?.symbol
      : globalDefaults?.default_currency;

  const CalculatePaySuggestions = (amount) => {
    var suggestions = [props.rest];
    banknotes && banknotes.length != 0 && banknotes.forEach(banknote => {
      var data = Math.ceil(props.rest / banknote) * banknote;
      if (!suggestions.includes(data) && data) suggestions.push(data)
    });

    if (suggestions.length < 3 && !suggestions.includes(props.rest) && props.rest) suggestions.push(props.rest)
    setSuggestions(suggestions)
  }

  const HandleClickPaySuggestion = (paySuggestionValue) => {
    props.HandleAmount(paySuggestionValue);
  };

  useEffect(() => {
    CalculatePaySuggestions()
  }, [props.rest]);

  return (
    <div>
      {paySuggestions.map((paySuggestion, index) => (
        <Box className={classes.section}>
          <Button key={index} className={clsx(classes.Button, classes.border, classes.priceSize)} name="2" onClick={(e) => HandleClickPaySuggestion(paySuggestion)}>{client?.default_currency && client?.default_currency != globalDefaults?.default_currency ? Intl.NumberFormat('de-DE', { style: 'currency', currency: client.default_currency }).format(paySuggestion) : (paySuggestion / Object.values(exchangeRate)).toFixed(3) + " " + currencySymbol}</Button>
        </Box>
      ))}
    </div>
  );
}
