export const GET_MATERIAL_REQ = "GET_MATERIAL_REQ";
export const MATERIAL_REQ_LOADED = "MATERIAL_REQ_LOADED";

export const CREATE_PURCHASE_REQUEST_SUCCESS =
  "CREATE_PURCHASE_REQUEST_SUCCESS";
export const CREATE_PURCHASE_REQUEST = "CREATE_PURCHASE_REQUEST";

export const SET_REQUEST_DATA = "SET_REQUEST_DATA";

export const SET_QUO_MAPPED_DOC = "SET_QUO_MAPPED_DOC";

export const SUPPLIER_LIST_LOADED = "SUPPLIER_LIST_LOADED";
export const GET_SUPPLIER_LIST = "GET_SUPPLIER_LIST";

export const GET_SUPPLIER_DETAILS = "GET_SUPPLIER_DETAILS";
export const SUPPLIER_DETAILS_LOADED = "SUPPLIER_DETAILS_LOADED";

export const CREATE_QUO_REQUEST_SUCCESS = "CREATE_QUO_REQUEST_SUCCESS";
export const CREATE_QUO_REQUEST = "CREATE_QUO_REQUEST";

export const QUOTATION_REQ_LOADED = "QUOTATION_REQ_LOADED";
export const GET_QUOTATION_REQ = "GET_QUOTATION_REQ";

export const SET_QUO_REQ_DETAILS = "SET_QUO_REQ_DETAILS";

export const SET_SUPP_QUO_DOC = "SET_SUPP_QUO_DOC";

export const CREATE_SUPPLIER_QUO_SUCCESS = "CREATE_SUPPLIER_QUO_SUCCESS";
export const CREATE_SUPPLIER_QUO = "CREATE_SUPPLIER_QUO";

export const GET_SUPPLIER_QUOTATIONS = "GET_SUPPLIER_QUOTATIONS";
export const SUPPLIER_QUOTATIONS_LOADED = "SUPPLIER_QUOTATIONS_LOADED";

export const CREATE_PURCHASE_ORDER_SUCCESS = "CREATE_PURCHASE_ORDER_SUCCESS";
export const CREATE_PURCHASE_ORDER = "CREATE_PURCHASE_ORDER";

export const SET_SUPP_QUO_REQ_DETAILS = "SET_SUPP_QUO_REQ_DETAILS";

export const SET_PURCHASE_MAPPED_DOC = "SET_PURCHASE_MAPPED_DOC";

export const GET_PURCHASE_REQ = "GET_PURCHASE_REQ";
export const PURCHASE_REQ_LOADED = "PURCHASE_REQ_LOADED";

export const SET_PURCHASE_RECEIPT_MAPPED_DOC =
  "SET_PURCHASE_RECEIPT_MAPPED_DOC";

export const CREATE_PURCHASE_RECEIPT = "CREATE_PURCHASE_RECEIPT";
export const CREATE_PURCHASE_RECEIPT_SUCCESS =
  "CREATE_PURCHASE_RECEIPT_SUCCESS";

export const GET_PURCHASE_RECEIPT = "GET_PURCHASE_RECEIPT";
export const PURCHASE_RECEIPT_LOADED = "PURCHASE_RECEIPT_LOADED";

export const GET_PURCHASE_INVOICE = "GET_PURCHASE_INVOICE";
export const PURCHASE_INVOICE_LOADED = "PURCHASE_INVOICE_LOADED";

export const SET_PURCHASE_INVOICE_MAPPED_DOC =
  "SET_PURCHASE_INVOICE_MAPPED_DOC";

export const CREATE_PURCHASE_INVOICE = "CREATE_PURCHASE_INVOICE";
export const CREATE_PURCHASE_INVOICE_SUCCESS =
  "CREATE_PURCHASE_INVOICE_SUCCESS";

export const SET_PAYMENT_ENTRY_MAPPED_DOC = "SET_PAYMENT_ENTRY_MAPPED_DOC";

export const CREATE_PAYMENT_ENTRY_SUCCESS = "CREATE_PAYMENT_ENTRY_SUCCESS";
export const CREATE_PAYMENT_ENTRY = "CREATE_PAYMENT_ENTRY";

export const STOCKIST_SIGNATURE = "STOCKIST_SIGNATURE";
export const SET_PURCHASE_ORDER_DETAILS = "SET_PURCHASE_ORDER_DETAILS";

export const UPDATE_SIGNATURE_PURCHASE_ORDER =
  "UPDATE_SIGNATURE_PURCHASE_ORDER";
export const UPDATE_SIGNATURE_PURCHASE_ORDER_SUCCESS =
  "UPDATE_SIGNATURE_PURCHASE_ORDER_SUCCESS";

export const CREATE_NEW_PURCHASE_ORDER = "CREATE_NEW_PURCHASE_ORDER";
export const CREATE_NEW_PURCHASE_ORDER_SUCCESS =
  "CREATE_NEW_PURCHASE_ORDER_SUCCESS";

export const CREATE_NEW_PURCHASE_INVOICE = "CREATE_NEW_PURCHASE_INVOICE";
export const CREATE_NEW_PURCHASE_INVOICE_SUCCESS =
  "CREATE_NEW_PURCHASE_INVOICE_SUCCESS";
export const SET_SELECTED_PURCHASE_ORDER_PACKAGE =
  "SET_SELECTED_PURCHASE_ORDER_PACKAGE";
export const SET_PURCHASE_ORDER_PACKAGE_DOC = "SET_PURCHASE_ORDER_PACKAGE_DOC";

export const SET_PACKAGES_WITH_ITEMS_MATCHING_DBERP =
  "SET_PACKAGES_WITH_ITEMS_MATCHING_DBERP";
export const GET_AUTO_MAT_REQ = "GET_AUTO_MAT_REQ";
export const AUTO_MAT_REQ_LOADED = "AUTO_MAT_REQ_LOADED";
export const AUTO_MAT_REQ_DETAILS_LOADED = "AUTO_MAT_REQ_DETAILS_LOADED";
