import { CLEAR_STATE } from "../login/login.type";
import {
  ADDRESS_LOADED,
  COUNTRY_LOADED,
  TERRITORY_LOADED,
  UPDATE_TABLE_CONFIG
} from "./setting.type";


const initialState = {
  country: [],
  territory : [],
  address: [],
  tableConfigs: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case COUNTRY_LOADED:
      return {
        ...state,
        country: action.country,
      };
    case TERRITORY_LOADED:
      return {
        ...state,
        territory: action.territory,
      };
    case ADDRESS_LOADED:
      return {
        ...state,
        address: action.address,
      };
    case UPDATE_TABLE_CONFIG:
      return {
        ...state,
        tableConfigs: action.payload
      }
      case CLEAR_STATE:
          return initialState;
    default:
      return state;
  }
};

export default reducer;
