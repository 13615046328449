import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import {
  UpdateProducts,
  UpdatePayment,
  loadItemDetail,
} from "../../store/checkout/checkout.action";
import {
  Typography,
  Card,
  CardContent,
  Box,
} from "@material-ui/core";
import { useSelector,useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { formatDate } from "../../helpers/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    margin: 10,
    width: 200,
    flexGrow: 1,
    borderRadius: 10,
    position: "relative",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.2)",
  },
  smallRoot: {
    display: "flex",
    flexDirection: "column",
    margin: 5,
    width: 150,
    flexGrow: 1,
    borderRadius: 10,
    position: "relative",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.2)",
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: 14,
    fontWeight: 600,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    marginBottom: 0,
  },
  price: {
    color: theme.palette.gray.main,
    fontSize: 12,
  },
}));

const ProductFinancierCheckout = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation(["common", "checkout"]);

const itemFinancierList = useSelector((state) => state.itemFinancierList.itemFinancierList);
const productsPos = useSelector((state) => state.itemFinancierList.itemFinancierPos);
const suggestion = useSelector((state) => state.itemFinancierList.itemFinancierList);
const rows = useSelector((state) => state.Checkout.itemFinancier);
const amount = useSelector((state) => state.Checkout.amount);
const user = useSelector((state) => state.Login.user);
const pricingRules = useSelector((state) => state.Checkout.pricingRules);
const defaultClient = useSelector((state) => state.Checkout.defaultClient);
const selectedClient = useSelector((state) => state.Checkout.client);
const store = useSelector((state) => state.Login.selectedData?.store);
const cashier = useSelector((state) => state.Login.selectedData?.cashier);
const globalDefaults = useSelector((state) => state.Login.globalDefaults);
const hold = useSelector((state) => state.Checkout.holds);
const imageList = useSelector((state) => state.Product);
const currencyExchangeRate = useSelector(
  (state) => state.Checkout.currencyExchangeRate
);

const isReservedInPOS = (name) => {
  let totalQtyReserved = 0;
  hold &&
    hold
      ?.filter((element) => element?.payment_status === "partly paid")
      ?.forEach((element) => {
        if (element?.amount?.payments.length > 0) {
          const reservedProducts = element?.products?.filter(
            (el) => el.name === name
          );

          reservedProducts?.forEach((reservedProduct) => {
            totalQtyReserved += reservedProduct.qty;
          });
        }
      });

  return totalQtyReserved;
};
console.log("itemFinancierList", itemFinancierList)
console.log('props.itemFinancierList.name',  rows)
const loadItemDetails = (items) => {
  items?.forEach((item) => {
    let doc = {
      doctype: "POS Invoice",
      company: globalDefaults.default_company,
      posting_date: formatDate(new Date(), "YYYY-MM-DD"),
      currency: globalDefaults.default_currency,
      price_list_currency: globalDefaults.default_currency,
      ignore_pricing_rule: 0,
      party_account_currency: globalDefaults.default_currency,
      items: [
        {
          doctype: "POS Invoice Item",
          item_group: item.item_group,
          parentfield: "items",
          parenttype: "POS Invoice",
          qty: item.qty,
          name: item.name,
        },
      ],
      pos_profile: cashier,
      set_warehouse: store,
      customer_name: selectedClient?.customer_name
        ? selectedClient?.customer_name
        : defaultClient?.customer_name,
      customer: selectedClient?.customer_name
        ? selectedClient?.customer_name
        : defaultClient?.customer_name,
    };
    let args = {
      name: item.name,
      set_warehouse: store,
      customer: selectedClient?.customer_name
        ? selectedClient?.customer_name
        : defaultClient?.customer_name,
      currency: globalDefaults.default_currency,
      price_list_currency: globalDefaults.default_currency,
      company: globalDefaults.default_company,
      ignore_pricing_rule: 0,
      doctype: "POS Invoice",
      pos_profile: cashier,
    };
    dispatch(loadItemDetail(args, doc));
  });
};
const isExistInPOS = (name) => {
  if (
    productsPos &&
    productsPos.find((p) => p.name == name)
  ) {
    return true;
  } else {
    return false;
  }
};

const handleAddProduct = async () => {
    console.log("rows", rows);
  if (props.isCheckout) { 
      var discountedProducts =
        pricingRules && pricingRules.items
          ? pricingRules.items.map((i) => i.name)
          : null;
      var data = suggestion.find(
        (s) => s.name == props.itemFinancierList.name
      );
      console.log("data", data);
      if (data) {
        const match = productsPos.find(
          (pos) => pos.name === data.name
        );

        if (match) {
          data = {
            ...data,
            ...match,
          };
        }
      }

      const ItemIndex = rows.findIndex(
        (s) => s.name == props.itemFinancierList.name
      );
      console.log("ItemIndex", ItemIndex);
      
      if (ItemIndex != -1) {
       
       
          rows[ItemIndex].quantity = " - ";
          rows[ItemIndex].qty = " - ";
          rows[ItemIndex].price_list_rate =  " - ";
          rows[ItemIndex].net_rate = " - "
         
      } else {
        data.discount = 0;
        data.quantity = 1;
        data.qty = 1;
        data.price_list_rate = data.price_list_rate
          ? parseFloat(data.price_list_rate)
          : parseFloat(data.standard_rate);
        data.net_rate =
          (data.price_list_rate -
            (data.price_list_rate * parseFloat(data.discount)) / 100) /
          currencyExchangeRate;
        data.net_amount = data.net_rate * data.quantity;
        data.discount_amount =
          (data.price_list_rate - data.net_rate) * data.quantity;
        if (
          discountedProducts &&
          discountedProducts.includes(data.name) &&
          pricingRules?.discount_percentage != 0
        ) {
          data.discount = pricingRules?.discount_percentage;
          data.net_rate =
            (data.price_list_rate -
              (data.price_list_rate *
                parseFloat(pricingRules?.discount_percentage)) /
                100) /
            currencyExchangeRate;
          data.net_amount = data.net_rate * data.quantity;
        } else if (
          discountedProducts &&
          discountedProducts.includes(data.name) &&
          pricingRules?.discount_amount != 0
        ) {
          data.discount_amount = pricingRules?.discount_amount;
          data.net_rate =
            data.price_list_rate - pricingRules?.discount_amount;
          data.net_amount = data.net_rate * data.quantity;
        }
        rows.push(data);
        props.initialLines.splice(-1);
      }
      dispatch(UpdateProducts(rows));
      loadItemDetails(rows);
      const totalAmount = props.GetTotalPrice(rows);
      const taxAmount =
        totalAmount -
        rows.reduce((a, v) => (a = a + parseFloat(v.net_amount)), 0);
      dispatch(UpdatePayment({ ...amount, totalAmount, taxAmount }));
     
  } else {
  //  history.push(`/stock-product/${props?.product?.name}`);
  }
};

// const handleAddProduct = async () => {
    
//   // if (props.isCheckout) {
//   //   if (isExistInPOS(props.product.name)) {
//   //     var discountedProducts =
//   //       pricingRules && pricingRules.items
//   //         ? pricingRules.items.map((i) => i.name)
//   //         : null;
//       var data = suggestion.find(
//         (s) => s.name ==  itemFinancierList.name
//       );

//       if (data) {
//         const match = productsPos.find(
//           (pos) => pos.name === data.name
//         );

//         if (match) {
//           data = {
//             ...data,
//             ...match,
//           };
//         }
//       }

//       const ItemIndex = rows.findIndex(
//         (s) => s.name == props.product.name
//       );
      
      
//   //     if (ItemIndex != -1) {
//   //       const teststock = await availableStock(rows[ItemIndex]?.name);
//   //       if (teststock) {
//   //         rows[ItemIndex].quantity = parseInt(rows[ItemIndex].quantity) + 1;
//   //         rows[ItemIndex].qty = parseInt(rows[ItemIndex].qty) + 1;
//   //         rows[ItemIndex].price_list_rate = parseFloat(
//   //           rows[ItemIndex].price_list_rate
//   //             ? rows[ItemIndex].price_list_rate
//   //             : rows[ItemIndex].standard_rate
//   //         );
//   //         rows[ItemIndex].net_rate =
//   //           (rows[ItemIndex].price_list_rate -
//   //             (rows[ItemIndex].price_list_rate *
//   //               parseFloat(rows[ItemIndex].discount)) /
//   //               100) /
//   //           currencyExchangeRate;
//   //         rows[ItemIndex].net_amount =
//   //           rows[ItemIndex].net_rate * rows[ItemIndex].quantity;
//   //         rows[ItemIndex].discount_amount =
//   //           (rows[ItemIndex].price_list_rate - rows[ItemIndex].net_rate) *
//   //           rows[ItemIndex].quantity;
//   //       }
//   //     } else {
//   //       data.discount = 0;
//   //       data.quantity = 1;
//   //       data.qty = 1;
//   //       data.price_list_rate = data.price_list_rate
//   //         ? parseFloat(data.price_list_rate)
//   //         : parseFloat(data.standard_rate);
//   //       data.net_rate =
//   //         (data.price_list_rate -
//   //           (data.price_list_rate * parseFloat(data.discount)) / 100) /
//   //         currencyExchangeRate;
//   //       data.net_amount = data.net_rate * data.quantity;
//   //       data.discount_amount =
//   //         (data.price_list_rate - data.net_rate) * data.quantity;
//   //       if (
//   //         discountedProducts &&
//   //         discountedProducts.includes(data.name) &&
//   //         pricingRules?.discount_percentage != 0
//   //       ) {
//   //         data.discount = pricingRules?.discount_percentage;
//   //         data.net_rate =
//   //           (data.price_list_rate -
//   //             (data.price_list_rate *
//   //               parseFloat(pricingRules?.discount_percentage)) /
//   //               100) /
//   //           currencyExchangeRate;
//   //         data.net_amount = data.net_rate * data.quantity;
//   //       } else if (
//   //         discountedProducts &&
//   //         discountedProducts.includes(data.name) &&
//   //         pricingRules?.discount_amount != 0
//   //       ) {
//   //         data.discount_amount = pricingRules?.discount_amount;
//   //         data.net_rate =
//   //           data.price_list_rate - pricingRules?.discount_amount;
//   //         data.net_amount = data.net_rate * data.quantity;
//   //       }
//   //       rows.push(data);
//   //       props.initialLines.splice(-1);
//   //     }
//   //     dispatch(UpdateProducts(rows));
//   //     loadItemDetails(rows);
//   //     const totalAmount = props.GetTotalPrice(rows);
//   //     const taxAmount =
//   //       totalAmount -
//   //       rows.reduce((a, v) => (a = a + parseFloat(v.net_amount)), 0);
//   //     dispatch(UpdatePayment({ ...amount, totalAmount, taxAmount }));
//   //   }
//   // } else {
//     history.push(`/stock-product/${props?.itemFinancierList?.name}`);
//   // }
// };


  return (
    <Box display="flex" flexWrap="wrap">
      {itemFinancierList.map((item) => (
        <Card
          key={item.name}
          className={props?.isCheckout ? classes.smallRoot : classes.root}
        >
          <CardContent
            style={{ cursor: "pointer" }}
            onClick={() => handleAddProduct(item)}
          >
            <Typography
              gutterBottom
              color="primary"
              style={{ lineHeight: 0.8, fontWeight: 600 }}
              variant="body1"
              component="h2"
              className={classes.title}
            >
              {item.libelle ? item.libelle : ""}
            </Typography>
            <Typography variant="caption" color="textSecondary" component="p">
              {item.code}
            </Typography>
            <Typography variant="caption" color="textSecondary" component="p">
              {item.type}
            </Typography>
            <Typography variant="caption" color="textSecondary" component="p">
              {item.prix ? `${item.prix} USD` : t("common:priceNotAvailable")}
            </Typography>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

export default ProductFinancierCheckout;
