import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import { Fulfillment } from "./components/fullfilment";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { LoadFullfilments } from "../../store/fullfilment/fullfilment.action";

export const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  tabs: {
    "& .MuiTabs-flexContainer": {
      borderBottom: "2px solid #ECF2F5",
    },
  },
  tab: {
    borderBottom: "none",
    fontSize: "0.8rem",
  },
}));

export const FulfillmentTabs = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const globalDefaults = useSelector((state) => state.Login.globalDefaults);
  const useQuery = () => new URLSearchParams(useLocation().search);
  const { t } = useTranslation(["clients", "common", "fulfillment"]);
  let query = useQuery();

  const [value, setValue] = React.useState(
    query.get("tab") ? parseInt(query.get("tab")) : 0
  );
  const fullfilments = useSelector((state) => state.Fullfilment.fullfilments);
  const selectedData = useSelector((state) => state.Login.selectedData);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onTabClick = (tab) => history.push(`/fulfillement?tab=${tab}`);

  useEffect(() => {
    if (globalDefaults?.default_company)
      selectedData?.store &&
        dispatch(
          LoadFullfilments(selectedData.store, globalDefaults?.default_company)
        );
  }, [globalDefaults?.default_company]);

  return (
    <div className={classes.root}>
      <Box position="static" color="default">
        <Tabs
          value={value}
          className={classes.tabs}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab
            className={classes.tab}
            label={t("fulfillment:verif")}
            {...a11yProps(0)}
            onClick={() => onTabClick(0)}
          />
          <Tab
            className={classes.tab}
            label={t("fulfillment:prep")}
            {...a11yProps(1)}
            onClick={() => onTabClick(1)}
          />
          <Tab
            className={classes.tab}
            label={t("fulfillment:picking")}
            {...a11yProps(2)}
            onClick={() => onTabClick(2)}
          />
          <Tab
            className={classes.tab}
            label={t("fulfillment:packing")}
            {...a11yProps(3)}
            onClick={() => onTabClick(3)}
          />
          <Tab
            className={classes.tab}
            label={t("fulfillment:delTrack")}
            {...a11yProps(4)}
            onClick={() => onTabClick(4)}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Fulfillment
          tab={0}
          fullfilments={fullfilments.filter(
            (f) =>
              f?.status == "canceled" ||
              f?.status == "cancel" ||
              f.status == "to verify"
          )}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Fulfillment
          tab={1}
          fullfilments={fullfilments.filter(
            (f) => f.status == "verified" || f.status == "in prepare"
          )}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Fulfillment
          tab={2}
          fullfilments={fullfilments.filter((f) => f.status == "prepared")}
        />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Fulfillment
          tab={3}
          fullfilments={fullfilments.filter(
            (f) => f.status == "picked" || f.status == "in packing"
          )}
        />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Fulfillment
          tab={4}
          fullfilments={fullfilments.filter((f) => f.status == "packed")}
        />
      </TabPanel>
    </div>
  );
};
