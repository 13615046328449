import React, {useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Collapse from "@material-ui/core/Collapse";
import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";
import {
  Modal,
  Typography,
  InputLabel,
  OutlinedInput,
} from "@material-ui/core";
import ExpandMore from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import {FormControlLabel} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import {useSelector, useDispatch} from "react-redux";
import {
  UpdateSalesPerson,
  CreateSalesPerson,
} from "../../../../store/checkout/checkout.action";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    height: 40,
    "& span": {
      fontSize: 14,
      color: theme.palette.primary.main,
    },
  },
  button: {
    width: "100%",
    backgroundColor: "transparent",
    color: "#657288",
    border: "1px solid #A6B4CB",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  doneButton: {
    backgroundColor: theme.palette.primary.main,
    width: "100%",
    color: "#657288",
  },
  box: {
    position: "relative",
  },
  active: {
    borderBottom: "none",
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  scanCodeIcon: {
    color: theme.palette.primary.main,
  },
  collapse: {
    position: "absolute",
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    border: "1px solid #A6B4CB",
    borderTop: "none",
    backgroundColor: theme.palette.white.main,
    width: "calc(100% - 2px)",
    zIndex: 2,
    "& .MuiInputBase-root": {
      background: theme.palette.white.main,
      marginRight: 13,
    },
    "& .MuiOutlinedInput-input": {
      padding: "7.5px 14px",
      fontSize: 12,
    },
  },
  avatar: {
    height: 37,
    marginRight: 11,
    color: theme.palette.blue.main,
    fontSize: 12,
    backgroundColor: theme.palette.secondary.main,
  },
  not: {
    backgroundColor: "none",
    color: theme.palette.blue.main,
  },
  collapseContent: {
    padding: "8px 11px",
    backgroundColor: theme.palette.secondary.main,
  },
  linkSection: {
    borderTop: "1px solid #f2f4f7",
  },
  noWrap: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    whiteSpace: "nowrap",
  },
  link: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontSize: 14,
    justifyContent: "space-between",
    color: theme.palette.blue.main,
  },
  userIcon: {
    marginLeft: 4,
  },
  clientList: {},
  doneIcon: {
    fontSize: "16px",
    color: theme.palette.blue.main,
  },
  paper: {
    width: "500px",
    textAlign: "center",
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 10,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  label: {
    fontSize: 12,
    marginBottom: 6,
    color: theme.palette.gray.main,
    textAlign: "left",
    paddingLeft: 5,
  },
  formInput: {
    width: "100%",
    marginBottom: 15,
    height: 35,
    fontSize: 12,
  },
  modalButton: {
    marginLeft: 5,
    marginRight: 5,
  },
}));

export const SalesPerson = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openMiniForm, setOpenMiniForm] = React.useState(false);
  const dispatch = useDispatch();
  const {t} = useTranslation(["common", "checkout"]);

  const salesPersonList = useSelector(
      (state) => state.Checkout.salesPersonList
  );
  const checkedItems = useSelector(
      (state) => state.Checkout.selectedSalesPerson
  );
  const store = useSelector((state) => state.Login.selectedData);

  const storePos = useSelector((state) => state.Login.pos);

  const [createSalesPersonForm, setCreateSalesPersonForm] = React.useState({
    sales_person_name: null,
    commission_rate: null,
  });

  useEffect(() => {
    if (!!store) {
      const selectedPos = storePos?.filter((item) => item.name === store.cashier) || null;
      const selectedPosName = selectedPos?.[0]?.sales_person;
      if (!!selectedPosName && !checkedItems.includes(selectedPosName)) {
        let updatedList = [...checkedItems, selectedPosName];
        dispatch(UpdateSalesPerson(updatedList));
      }
    }
  }, [store]);

  const handleClick = () => {
    setOpen(!open);
  };
  const handleClickAway = () => {
    setOpen(false);
  };

  const handleCheck = (event) => {
    var updatedList = [...checkedItems];
    if (event.target.checked) {
      updatedList = [...checkedItems, event.target.value];
    } else {
      updatedList.splice(checkedItems.indexOf(event.target.value), 1);
      console.log('updatedList:', updatedList)
    }
    dispatch(UpdateSalesPerson(updatedList));
  };

  var isChecked = (item) =>
      checkedItems.includes(item)
          ? true
          : checkedItems.findIndex((s, i) => s.sales_person_name == item) != -1
              ? true
              : false;

  const handleChange = (e) => {
    if (e.target.name == "commission_rate") {
      if (e.target.value < 100 && e.target.value > 0) {
        setCreateSalesPersonForm({
          ...createSalesPersonForm,
          [e.target.name]: e.target.value,
        });
      }
    } else {
      setCreateSalesPersonForm({
        ...createSalesPersonForm,
        [e.target.name]: e.target.value,
      });
    }
  };

  const createSalesPerson = () => {
    const data = {
      doctype: "Sales Person",
      is_group: 0,
      enabled: 1,
      sales_person_name: createSalesPersonForm.sales_person_name,
      parent_sales_person: "Sales Team",
      commission_rate: createSalesPersonForm.commission_rate,
      warehouse: store && store.store,
    };
    createSalesPersonForm.sales_person_name &&
    createSalesPersonForm.commission_rate &&
    dispatch(CreateSalesPerson(data));
    setOpenMiniForm(false);
    setCreateSalesPersonForm({
      sales_person_name: null,
      commission_rate: null,
    });
  };

  const isContainObjects = checkedItems.find((element) => {
    if (element.item) {
      return true;
    }
    return false;
  });

  const miniForm = (
      <ClickAwayListener onClickAway={() => setOpenMiniForm(false)}>
        <Box className={classes.paper}>
          <Typography align={"center"} color={"primary"}>
            {t("common:addVendor")}
          </Typography>
          <Box mt={3} justifyContent="center" className={classes.formBlock}>
            <InputLabel className={classes.label}>
              {t("common:vendorName")}
            </InputLabel>
            <OutlinedInput
                autoComplete="off"
                className={classes.formInput}
                placeholder={t("common:vendorName")}
                value={createSalesPersonForm.sales_person_name}
                onChange={(e) => handleChange(e)}
                name="sales_person_name"
            />
            <InputLabel className={classes.label}>
              {t("common:commission")}
            </InputLabel>
            <OutlinedInput
                autoComplete="off"
                type="number"
                className={classes.formInput}
                placeholder={t("common:commission")}
                value={createSalesPersonForm.commission_rate}
                onChange={(e) => handleChange(e)}
                name="commission_rate"
            />
          </Box>
          <Box mt={3} justifyContent="center">
            <Button
                disabled={
                    !createSalesPersonForm.commission_rate ||
                    !createSalesPersonForm.sales_person_name
                }
                variant="contained"
                color="primary"
                className={classes.modalButton}
                onClick={() => createSalesPerson()}
            >
              {t("common:ajouter")}
            </Button>
            <Button
                className={classes.modalButton}
                variant="contained"
                color="secondary"
                onClick={() => setOpenMiniForm(false)}
            >
              {t("common:annuler")}
            </Button>
          </Box>
        </Box>
      </ClickAwayListener>
  );

  const checkedItems1 = salesPersonList.filter((row) => isChecked(row.sales_person_name));
  const uncheckedItems = salesPersonList.filter((row) => !isChecked(row.sales_person_name));

// Combine checked and unchecked items
  const combinedList = [...checkedItems1, ...uncheckedItems];

  return (
      <>
        <ClickAwayListener onClickAway={handleClickAway}>
          <Box className={classes.box}>
            <Button
                aria-controls="customized-menu"
                aria-haspopup="true"
                className={clsx({
                  [classes.button]: true, //always applies
                  [classes.active]: open, //only when open === true
                })}
                onClick={handleClick}
            >
              <Box display={"flex"} alignItems={"center"}>
                <Avatar className={classes.avatar}/>
                {t("common:vendeurs")} ({checkedItems.length})
              </Box>
              <ExpandMore/>
            </Button>
            <Collapse
                className={classes.collapse}
                in={open}
                timeout="auto"
                unmountOnExit
            >
              <List className={classes.clientList} component="div">
                {Array.isArray(salesPersonList) &&
                    combinedList.map((row, index) => (
                        <ListItem key={index} className={classes.nested}>
                          <FormControlLabel
                              value={row.sales_person_name}
                              control={
                                <Checkbox
                                    color="primary"
                                    onChange={handleCheck}
                                    checked={isChecked(row.sales_person_name)}
                                    disabled={isContainObjects}
                                />
                              }
                              label={
                                  row.sales_person_name +
                                  ` ( ${
                                      checkedItems.filter((s, i) => s === row.sales_person_name).length +
                                      checkedItems.filter((s, i) => s.sales_person_name === row.sales_person_name).length
                                  } )`
                              }
                              labelPlacement="end"
                          />
                        </ListItem>
                    ))}
              </List>
              {/* <div className={classes.noWrap} >
              <Button className={classes.link} onClick={() => setOpenMiniForm(true)}>{t("common:addVendor")}</Button>
            </div> */}
            </Collapse>
          </Box>
        </ClickAwayListener>
        <Modal
            open={openMiniForm}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
          {miniForm}
        </Modal>
      </>
  );
};