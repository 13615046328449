import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { Result } from "./result";
import { BasickeyPad } from "./basickeyPad";
import { SuggestionPrice } from "./suggestionPrice";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  box: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  prices: {
    width: "45%",
  },
  keypad: {
    width: "55%",
  },
  block: {
    backgroundColor: theme.palette.secondary.main,
    padding: 10,
    borderRadius: 19,
  },
  basicblock: {
    backgroundColor: theme.palette.calculator.background,
    borderRadius: 19,
    "& > *": {
      margin: theme.spacing(1),
    },
    overflow: "auto",
    height: "100%",
  },
  transparentBlock: {
    backgroundColor: "transparent",
    borderRadius: 19,
    "& > *": {
      margin: theme.spacing(1),
    },
    overflow: "auto",
    height: "100%",
  },
}));

export const Calculator = (props) => {
  const classes = useStyles();

  const HandleAmount = (amount) => {
    props.HandleAmount(amount);
    setResult(amount);
  };

  const [result, setResult] = React.useState(0.01);
  if (props.basic) {
    return (
      <Box
        className={
          props.transparent ? classes.transparentBlock : classes.basicblock
        }
      >
        <BasickeyPad
          isCalcul={props.isCalcul}
          amount={props.amount}
          HandleAmount={HandleAmount}
        />
      </Box>
    );
  }
  return (
    <Box className={classes.block}>
      <Result result={result} currency={props.currency} />
      <Box className={classes.box}>
        <Box className={classes.keypad}>
          <BasickeyPad
            isCalcul={props.isCalcul}
            amount={props.amount}
            HandleAmount={HandleAmount}
          />
        </Box>
        <Box className={classes.prices}>
          <SuggestionPrice
            rest={props.rest}
            currency={props.currency}
            HandleAmount={HandleAmount}
          />
        </Box>
      </Box>
    </Box>
  );
};
