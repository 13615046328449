import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {Table, TableBody, TableCell, TableHead, TableRow, Box} from "@material-ui/core";
import clsx from "clsx";
import { useTableStyles } from "../../../../styles/table.style";
import { formatDate } from "../../../../helpers/utils";
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root : {
    padding :"1.0625rem 1.5rem",
  },
  price: {
    fontWeight: 600,
  },
  filterList : {
      borderTop : 'none'
  }
}));


export const PosOrders = (props) => {
  const classes = useStyles();
  const tableClasses = useTableStyles();
  const {t} = useTranslation(["common"])
  const globalDefaults = useSelector(state => state.Login.globalDefaults);

  return (
    <Box className={clsx(classes.root,tableClasses.paper)}>
      <Table className={tableClasses.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={tableClasses.tablecellHeader} align="left">
              {" "}
              {t("common:date")}{" "}
            </TableCell>
            <TableCell className={tableClasses.tablecellHeader} align="left">
               {t("common:ref")}
            </TableCell>
            <TableCell className={tableClasses.tablecellHeader} align="left">
               {t("common:client")}
            </TableCell>
            <TableCell className={tableClasses.tablecellHeader} align="center">
               {t("common:totalPrice")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.orders.map((row, index) => (
            <TableRow className={tableClasses.tableRow} key={index}>
              <TableCell className={tableClasses.tableCell} align="left">
              {formatDate(row.creation, "DD/MM/YYYY HH:mm")}
              </TableCell>
              <TableCell className={tableClasses.tableCell} align="left">
                {row.pos_invoice}
              </TableCell>
              <TableCell className={tableClasses.tableCell} align="left">
                {row.customer}
              </TableCell>
              <TableCell
                className={clsx(tableClasses.tableCell, classes.price)}
                align="center"
              >
                {row.grand_total.toFixed(3)} {globalDefaults?.default_currency}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};
