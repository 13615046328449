import React from "react";
import Box from "@material-ui/core/Box";
import clsx from "clsx";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useTableStyles } from "../../../../styles/table.style";
import { useTranslation } from "react-i18next";

export const ProductsToCheckout = (props) => {
  const tableclasses = useTableStyles();
  const { t } = useTranslation(["common", "hold", "checkout"]);

  return (
    <Box className={clsx(tableclasses.paper)}>
      <Table className={tableclasses.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={tableclasses.tablecellHeader} align="center">
              {t("common:produit")}
            </TableCell>
            <TableCell className={tableclasses.tablecellHeader} align="center">
              {t("common:ref")}
            </TableCell>
            <TableCell className={tableclasses.tablecellHeader} align="center">
              {t("common:remise")}
            </TableCell>
            <TableCell className={tableclasses.tablecellHeader} align="center">
              {t("common:MontTotal")}
            </TableCell>
            <TableCell className={tableclasses.tablecellHeader} align="center">
              {t("common:stock")}
            </TableCell>
            <TableCell className={tableclasses.tablecellHeader} align="center">
              {t("hold:ordrdQty")}
            </TableCell>
            {props.ignorePricingRule != 1 && (
              <TableCell
                className={tableclasses.tablecellHeader}
                align="center"
              >
                {t("checkout:PricingRule")}
              </TableCell>
            )}
            {props.ignorePricingRule != 1 && (
              <TableCell
                className={tableclasses.tablecellHeader}
                align="center"
              >
                {t("checkout:discountAmount")}
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {props.products &&
            props.products.map((row, index) => (
              <TableRow
                className={clsx({
                  [tableclasses.tableRow]: true, //always applies
                })}
                key={index}
              >
                <TableCell className={tableclasses.tableCell} align="center">
                  {row.item_name}
                </TableCell>
                <TableCell className={tableclasses.tableCell} align="center">
                  {row.item_code}
                </TableCell>
                <TableCell className={tableclasses.tableCell} align="center">
                  {row.discount ? row.discount + " %" : 0 + " %"}
                </TableCell>
                <TableCell className={tableclasses.tableCell} align="center">
                  {row.standard_rate.toFixed(3)} {props.currencySymbol}
                </TableCell>
                <TableCell className={tableclasses.tableCell} align="center">
                  {row.is_stock_item ? (
                    <>{t("common:isStock")}</>
                  ) : (
                    <>{t("common:isNotStock")}</>
                  )}
                </TableCell>
                <TableCell className={tableclasses.tableCell} align="center">
                  {row.quantity}
                </TableCell>
                {props.ignorePricingRule != 1 && (
                  <TableCell className={tableclasses.tableCell} align="center">
                    {row.pricing_rules ? JSON.parse(row.pricing_rules) : "_"}
                  </TableCell>
                )}
                {props.ignorePricingRule != 1 && (
                  <TableCell className={tableclasses.tableCell} align="center">
                    {row.discount_amount}
                  </TableCell>
                )}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </Box>
  );
};
