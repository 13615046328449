import "./QuotationComponent.css";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../../../../components/loader";
import { useTranslation } from "react-i18next";
import i18n from "./../../../../i18n";

import Divider from "@mui/material/Divider";

const PrintQuotation = ({ setCloseModalPrint, id }) => {
  const { t } = useTranslation();

  //fonction qui rend le montant en lettres
  //   const convertNumberToWords = (num) => {
  //     if (i18n.language === "en") {
  //     const units = [
  //       "Zero",
  //       "One",
  //       "Two",
  //       "Three",
  //       "Four",
  //       "Five",
  //       "Six",
  //       "Seven",
  //       "Eight",
  //       "Nine",
  //     ];
  //     const teens = [
  //       "Ten",
  //       "Eleven",
  //       "Twelve",
  //       "Thirteen",
  //       "Fourteen",
  //       "Fifteen",
  //       "Sixteen",
  //       "Seventeen",
  //       "Eighteen",
  //       "Nineteen",
  //     ];
  //     const tens = [
  //       "",
  //       "",
  //       "Twenty",
  //       "Thirty",
  //       "Forty",
  //       "Fifty",
  //       "Sixty",
  //       "Seventy",
  //       "Eighty",
  //       "Ninety",
  //     ];

  //     if (num < 10) return units[num];
  //     if (num < 20) return teens[num - 10];
  //     if (num < 100)
  //       return (
  //         tens[Math.floor(num / 10)] +
  //         (num % 10 !== 0 ? " " + units[num % 10] : "")
  //       );
  //     if (num < 1000)
  //       return (
  //         units[Math.floor(num / 100)] +
  //         " Hundred" +
  //         (num % 100 !== 0 ? " and " + convertNumberToWords(num % 100) : "")
  //       );
  //     if (num < 1000000)
  //       return (
  //         convertNumberToWords(Math.floor(num / 1000)) +
  //         " Thousand" +
  //         (num % 1000 !== 0 ? " " + convertNumberToWords(num % 1000) : "")
  //       );
  //     if (num < 1000000000)
  //       return (
  //         convertNumberToWords(Math.floor(num / 1000000)) +
  //         " Million" +
  //         (num % 1000000 !== 0 ? " " + convertNumberToWords(num % 1000000) : "")
  //       );
  //     return "Number too large to convert";
  //   } else if (i18n.language === "fr") {
  //     const units = [
  //       "Zéro",
  //       "Un",
  //       "Deux",
  //       "Trois",
  //       "Quatre",
  //       "Cinq",
  //       "Six",
  //       "Sept",
  //       "Huit",
  //       "Neuf",
  //     ];
  //     const teens = [
  //       "Dix",
  //       "Onze",
  //       "Douze",
  //       "Treize",
  //       "Quatorze",
  //       "Quinze",
  //       "Seize",
  //       "Dix-sept",
  //       "Dix-huit",
  //       "Dix-neuf",
  //     ];
  //     const tens = [
  //       "",
  //       "",
  //       "Vingt",
  //       "Trente",
  //       "Quarante",
  //       "Cinquante",
  //       "Soixante",
  //       "Soixante-dix",
  //       "Quatre-vingt",
  //       "Quatre-vingt-dix",
  //     ];

  //     if (num < 10) return units[num];
  //     if (num < 20) return teens[num - 10];
  //     if (num < 100)
  //       return (
  //         tens[Math.floor(num / 10)] +
  //         (num % 10 !== 0 ? " " + units[num % 10] : "")
  //       );
  //     if (num < 1000)
  //       return (
  //         units[Math.floor(num / 100)] +
  //         " Cent" +
  //         (num % 100 !== 0 ? " et " + convertNumberToWords(num % 100) : "")
  //       );
  //     if (num < 1000000)
  //       return (
  //         convertNumberToWords(Math.floor(num / 1000)) +
  //         " Mille " +
  //         (num % 1000 !== 0 ? " " + convertNumberToWords(num % 1000) : "")
  //       );
  //     if (num < 1000000000)
  //       return (
  //         convertNumberToWords(Math.floor(num / 1000000)) +
  //         " Million " +
  //         (num % 1000000 !== 0 ? " " + convertNumberToWords(num % 1000000) : "")
  //       );
  //     return "Nombre trop grand pour la conversion";
  //   } else {
  //     const units = [
  //       "صفر",
  //       "واحد",
  //       "اثنان",
  //       "ثلاثة",
  //       "اربعة",
  //       "خمسة",
  //       "ستة",
  //       "سبعة",
  //       "ثمانية",
  //       "تسعة",
  //     ];
  //     const teens = [
  //       "عشرة",
  //       "إحدى عشر",
  //       "اثنا عشر",
  //       "ثلاثة عشر",
  //       "اربعة عشر",
  //       "خمسة عشر",
  //       "ستة عشر",
  //       "سبعة عشر",
  //       "ثمانية عشر",
  //       "تسعة عشر",
  //     ];
  //     const tens = [
  //       "",
  //       "",
  //       "عشرون",
  //       "ثلاثون",
  //       "اربعون",
  //       "خمسون",
  //       "ستون",
  //       "سبعون",
  //       "ثمانون",
  //       "تسعون",
  //     ];

  //     if (num < 10) return units[num];
  //     if (num < 20) return teens[num - 10];
  //     if (num < 100)
  //       return (
  //         tens[Math.floor(num / 10)] +
  //         (num % 10 !== 0 ? " " + units[num % 10] : "")
  //       );
  //     if (num < 1000)
  //       return (
  //         units[Math.floor(num / 100)] +
  //         " مائة " +
  //         (num % 100 !== 0 ? " و " + convertNumberToWords(num % 100) : "")
  //       );
  //     if (num < 1000000)
  //       return (
  //         convertNumberToWords(Math.floor(num / 1000)) +
  //         " ألف" +
  //         (num % 1000 !== 0 ? " " + convertNumberToWords(num % 1000) : "")
  //       );
  //     if (num < 1000000000)
  //       return (
  //         convertNumberToWords(Math.floor(num / 1000000)) +
  //         " مليون " +
  //         (num % 1000000 !== 0 ? " " + convertNumberToWords(num % 1000000) : "")
  //       );
  //     return "عدد كبير جدا للتحويل";
  //   }
  // }

  // fonction qui transforme le montant en lettres

  // const transformeEnLettres=({montant})=> {

  // const uniteDinars = ['', 'un', 'deux', 'trois', 'quatre', 'cinq', 'six', 'sept', 'huit', 'neuf'];
  //   const dizaineDinars = ['', 'dix', 'vingt', 'trente', 'quarante', 'cinquante', 'soixante', 'soixante-dix', 'quatre-vingts', 'quatre-vingt-dix'];
  //   const uniteMillimes = ['', 'un', 'deux', 'trois', 'quatre', 'cinq', 'six', 'sept', 'huit', 'neuf'];

  //   const convertirEnLettres = ({nombre}) => {
  //     if (nombre === 0) return 'zéro';

  //     let dinars = Math.floor(nombre / 1000);
  //     let millimes = nombre % 1000;

  //     let resultat = '';

  //     if (dinars > 0) {
  //       resultat += convertirDinars(dinars) + ' dinars ';
  //     }

  //     if (millimes > 0) {
  //       resultat += convertirMillimes(millimes) + ' millimes ';
  //     }

  //     return resultat.trim();
  //   };

  //   const convertirDinars = (nombre) => {
  //     if (nombre === 0) return '';

  //     let resultat = '';

  //     if (nombre >= 100) {
  //       resultat += uniteDinars[Math.floor(nombre / 100)] + ' cent ';
  //       nombre %= 100;
  //     }

  //     if (nombre >= 10 && nombre <= 19) {
  //       resultat += 'dix ';
  //       nombre %= 10;
  //     } else if (nombre >= 20) {
  //       resultat += dizaineDinars[Math.floor(nombre / 10)] + ' ';
  //       nombre %= 10;
  //     }

  //     if (nombre > 0) {
  //       resultat += uniteDinars[nombre] + ' ';
  //     }

  //     return resultat.trim();
  //   };

  //   const convertirMillimes = (nombre) => {
  //     if (nombre === 0) return '';

  //     let resultat = '';

  //     if (nombre >= 100) {
  //       resultat += uniteMillimes[Math.floor(nombre / 100)] + ' cent ';
  //       nombre %= 100;
  //     }

  //     if (nombre >= 10 && nombre <= 19) {
  //       resultat += 'dix ';
  //       nombre %= 10;
  //     } else if (nombre >= 20) {
  //       resultat += dizaineDinars[Math.floor(nombre / 10)] + ' ';
  //       nombre %= 10;
  //     }

  //     if (nombre > 0) {
  //       resultat += uniteMillimes[nombre] + ' ';
  //     }

  //     return resultat.trim();
  //   };
  //   return (
  //     <div>
  //       <p>{convertirEnLettres(montant)}</p>
  //     </div>
  //   );
  // }

  // fonction qui transforme le montant en lettres
  const convertirMontantEnLettres = (montant) => {
    let unites = [];
    let dizaines = [];
    if (i18n.language === "fr") {
      unites = [
        "",
        "un",
        "deux",
        "trois",
        "quatre",
        "cinq",
        "six",
        "sept",
        "huit",
        "neuf",
      ];
      dizaines = [
        "",
        "dix",
        "vingt",
        "trente",
        "quarante",
        "cinquante",
        "soixante",
        "soixante-dix",
        "quatre-vingts",
        "quatre-vingt-dix",
      ];
    } else if (i18n.language === "en") {
      unites = [
        "",
        "one",
        "two",
        "three",
        "four",
        "five",
        "six",
        "seven",
        "eight",
        "nine",
      ];
      dizaines = [
        "",
        "ten",
        "twenty",
        "thirty",
        "forty",
        "fifty",
        "sixty",
        "seventy",
        "eighty",
        "ninety",
      ];
    } else {
      unites = [
        "",
        "واحد",
        "اثنين",
        "ثلاثة",
        "اربعة",
        "خمسة",
        "ستة",
        "سبعة",
        "ثمانية",
        "تسعة",
      ];
      dizaines = [
        "",
        "عشرة",
        "عشرون",
        "ثلاثون",
        "اربعون",
        "خمسون",
        "ستون",
        "سبعون",
        "ثمانون",
        "تسعون",
      ];
    }

    const convertirGroupeEnLettres = (nombre) => {
      let resultat = "";

      const centaines = Math.floor(nombre / 100);
      const dizainesEtUnites = nombre % 100;

      if (i18n.language === "fr") {
        if (centaines > 0) {
          resultat += unites[centaines] + " cent ";
        }

        if (dizainesEtUnites >= 10 && dizainesEtUnites <= 19) {
          resultat += "et " + unites[dizainesEtUnites - 10] + " ";
        } else if (dizainesEtUnites >= 20) {
          resultat += dizaines[Math.floor(dizainesEtUnites / 10)] + " ";
          if (dizainesEtUnites % 10 !== 0) {
            resultat += unites[dizainesEtUnites % 10] + " ";
          }
        } else if (dizainesEtUnites > 0) {
          resultat += unites[dizainesEtUnites] + " ";
        }

        return resultat.trim();
      } else if (i18n.language === "en") {
        if (centaines > 0) {
          resultat += unites[centaines] + " hundred ";
        }

        if (dizainesEtUnites >= 10 && dizainesEtUnites <= 19) {
          resultat += "and " + unites[dizainesEtUnites - 10] + " ";
        } else if (dizainesEtUnites >= 20) {
          resultat += dizaines[Math.floor(dizainesEtUnites / 10)] + " ";
          if (dizainesEtUnites % 10 !== 0) {
            resultat += unites[dizainesEtUnites % 10] + " ";
          }
          return resultat.trim();
        } else if (dizainesEtUnites > 0) {
          resultat += unites[dizainesEtUnites] + " ";
        }

        return resultat.trim();
      } else {
        if (centaines > 0) {
          resultat += unites[centaines] + " مائة ";
        }

        if (dizainesEtUnites >= 10 && dizainesEtUnites <= 19) {
          resultat += "و " + unites[dizainesEtUnites - 10] + " ";
        } else if (dizainesEtUnites >= 20) {
          resultat += dizaines[Math.floor(dizainesEtUnites / 10)] + " ";
          if (dizainesEtUnites % 10 !== 0) {
            resultat += unites[dizainesEtUnites % 10] + " ";
          }
        } else if (dizainesEtUnites > 0) {
          resultat += unites[dizainesEtUnites] + " ";
        }

        return resultat.trim();
      }
    };

    const montantEntier = Math.floor(montant);
    const montantDecimal = Math.round((montant - montantEntier) * 1000);

    const milliards = Math.floor(montantEntier / 1000000000);
    const millions = Math.floor(
      (montantEntier - milliards * 1000000000) / 1000000
    );
    const milliers = Math.floor(
      (montantEntier - milliards * 1000000000 - millions * 1000000) / 1000
    );
    const restant = montantEntier % 1000;

    let resultat = "";

    if (i18n.language === "fr") {
      if (milliards > 0) {
        resultat += convertirGroupeEnLettres(milliards) + " milliard ";
      }

      if (millions > 0) {
        resultat += convertirGroupeEnLettres(millions) + " million ";
      }

      if (milliers > 0) {
        resultat += convertirGroupeEnLettres(milliers) + " mille ";
      }

      if (restant > 0) {
        resultat += convertirGroupeEnLettres(restant);
      }

      resultat += " dinars";

      if (montantDecimal > 0) {
        resultat +=
          " et " + convertirGroupeEnLettres(montantDecimal) + " millimes";
      }

      return resultat;
    } else if (i18n.language === "en") {
      if (milliards > 0) {
        resultat += convertirGroupeEnLettres(milliards) + " billion ";
      }

      if (millions > 0) {
        resultat += convertirGroupeEnLettres(millions) + " million ";
      }

      if (milliers > 0) {
        resultat += convertirGroupeEnLettres(milliers) + " thousand ";
      }

      if (restant > 0) {
        resultat += convertirGroupeEnLettres(restant);
      }

      resultat += " dinars";

      if (montantDecimal > 0) {
        resultat +=
          " and " + convertirGroupeEnLettres(montantDecimal) + " millims";
      }

      return resultat;
    } else {
      if (milliards > 0) {
        resultat += convertirGroupeEnLettres(milliards) + " مليار ";
      }

      if (millions > 0) {
        resultat += convertirGroupeEnLettres(millions) + " مليون ";
      }

      if (milliers > 0) {
        resultat += convertirGroupeEnLettres(milliers) + " ألف ";
      }

      if (restant > 0) {
        resultat += convertirGroupeEnLettres(restant);
      }

      resultat += " دينار";

      if (montantDecimal > 0) {
        resultat += " و " + convertirGroupeEnLettres(montantDecimal) + " مليم";
      }

      return resultat;
    }
  };

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const [supplierList, setSupplierList] = useState([]);
  const [loadingButton, setLoadingButton] = useState(false);
  const [itemList, setItemList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [CurrencyList, setCurrencyList] = useState([]);
  const [taxList, setTaxList] = useState([]);
  const [supplierSelected, setSupplierSelected] = useState("");
  const [companySelected, setCompanySelected] = useState("");
  const [quotationNo, setQuotationNo] = useState("");
  const [addressList, setAddressList] = useState([]);
  const [addressSelected, setAddressSelected] = useState("");
  const [contactList, setContactList] = useState([]);
  const [contactSelected, setContactSelected] = useState("");
  const [CurrencySelected, setCurrencySelected] = useState("");
  const [taxCategoryList, setTaxCategoryList] = useState([]);
  const [priceList, setPriceList] = useState([]);
  const [priceSelected, setPriceSelected] = useState("");
  const [taxCategorySelected, setTaxCategorySelected] = useState("");
  const [purchaseTaxTemplateList, setPurchaseTaxTemplateList] = useState([]);
  const [purchaseTaxTemplateSelected, setPurchaseTaxTemplateSelected] =
    useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [transactionDate, setTransactionDate] = useState("");
  // const [dataQuotationList, setDataQuotationList] = useState([]);

  const [error, setError] = useState({
    supplier: false,
    scheduleDate: false,
    scheduleDateTill: false,
    items: false,
    taxes: false,
  });

  const [state, setState] = useState({
    items: [],
    taxes: [],
  });
  console.log("state", state.items);
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Set loading to false whether data fetching succeeds or fails

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URI}/api/details`,
          {
            params: {
              doctype: "Supplier Quotation",
              name: id.name,
            },
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
              Authorization: JSON.parse(localStorage.getItem("user")).token,
            },
          }
        );

        // Operations to be executed after data fetching is completed successfully
        response.data["docs"].forEach((item) => {
          setContactNumber(item.contact_mobile);
          setQuotationNo(item.name);
          setSupplierSelected(item.supplier);
          setCompanySelected(item.company);
          setCurrencySelected(item.currency);
          setPriceSelected(item.price_list);
          setPurchaseTaxTemplateSelected(item.purchase_tax_template);
          setTaxCategorySelected(item.tax_category);
          setPurchaseTaxTemplateSelected(item.taxes_and_charges);
          setPriceSelected(item.buying_price_list);
          setAddressSelected(item.supplier_address);
          setContactSelected(item.contact_person);
          setTransactionDate(item.transaction_date);
          item.taxes.map((item) => {
            state.taxes.push(item);
          });
          //setTaxCategorySelected(item.tax_category);
          item.items.map((item) => {
            state.items.push(item);
          });
        });
        setLoading(false); // Set loading to false whether data fetching succeeds or fails
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle error as needed
      } finally {
        setLoading(false); // Set loading to false whether data fetching succeeds or fails
      }
    };

    fetchData();
  }, []);
  if (loading) {
    return <Loader />;
  }
  return (
    <div
      className="supplier-quotation"
      style={{
        width: "200mm",
        height: "297mm",
        margin: "auto",
      }}
    >
      <div style={{ margin: "15px" }}>
        <div
          // className="header"
          style={{
            fontSize: "24px",
            fontWeight: 500,
            textAlign: "right",
            color: "rgb(102, 102, 102)",
            lineHeight: "31.2px",
            fontFamily: "Helvetica Neue",
            textRendering: "optimizeLegibility",
            textSizeAdjust: "100%",
            textTransform: "uppercase",
            marginTop: "85px",
            marginRight: "20px",
          }}
        >
          {t("common:SupplierQuotation")}
        </div>
      </div>
      <Divider orientation="horizontal" fullWidth />

      <div
        style={{ marginTop: "30px" }}
        onClick={() => {
          var bouton = document.getElementById("monBouton");
          bouton.parentNode.removeChild(bouton);
          window.print();
        }}
      >
        <svg
          className="print-icon"
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          id="monBouton"
          height="40"
          viewBox="0 0 40 40"
        >
          <path d="M19 8H5c-1.66 0-3 1.34-3 3v6h4v4h12v-4h4v-6c0-1.66-1.34-3-3-3zm-3 11H8v-5h8v5zm3-7c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm-1-9H6v4h12V3z" />
        </svg>
      </div>

      <div style={{ marginTop: "-20px", marginLeft: "80%" }}>
        <span style={{ fontSize: "14px", fontWeight: "bold" }}>
          {t("common:date")} :
        </span>
        &nbsp;
        <span>{transactionDate}</span>
      </div>
      <div style={{ marginLeft: "20px", width: "100%" }}>
        <div>
          <span style={{ fontSize: "15px", fontWeight: "bold" }}>
            {t("common:name")} :
          </span>
          <span style={{ marginLeft: 130 }}> {supplierSelected}</span>
        </div>
        <br />
        <br />
        <div>
          <span style={{ fontSize: "14px", fontWeight: "bold" }}>
            {t("common:addr")} :
          </span>{" "}
          <span style={{ marginLeft: 130 }}> {addressSelected}</span>
        </div>
        <br />
        <br />
        <div>
          <span style={{ fontSize: "14px", fontWeight: "bold" }}>
            {t("common:contact")} :
          </span>{" "}
          <span style={{ marginLeft: 130 }}> {contactSelected}</span>
        </div>
        <br />
        <br />

        <div>
          <span style={{ fontSize: "14px", fontWeight: "bold" }}>
            {t("common:phone")} :
          </span>
          <span style={{ marginLeft: 130 }}> {contactNumber}</span>
        </div>
        <br />
        <br />
      </div>

      <div
        className="items"
        style={{
          marginTop: "40px",
          marginLeft: "20px",
          marginRight: "40px",
          width: "95%",
        }}
      >
        <table style={{ borderCollapse: "collapse" }}>
          <thead style={{ backgroundColor: "#eeeeee", color: "black" }}>
            <tr>
              <th style={{ border: "1px solid black" }}>{t("common:sr")}</th>
              <th style={{ border: "1px solid black" }}>
                {t("product:itemcode")}
              </th>
              <th style={{ border: "1px solid black" }}>
                {t("common:Description")}
              </th>
              <th style={{ border: "1px solid black" }}>{t("common:qty")}</th>
              <th style={{ border: "1px solid black" }}>
                {t("common:remise")}
              </th>
              <th style={{ border: "1px solid black" }}>{t("common:taux")}</th>
              <th style={{ border: "1px solid black" }}>
                {t("common:montant")}
              </th>
              <th style={{ border: "1px solid black" }}>
                {t("common:weightUnit")}
              </th>
              <th style={{ border: "1px solid black" }}>
                {t("common:totWeight")}
              </th>
            </tr>
          </thead>
          <tbody>
            {state.items.map((item, index) => (
              <tr key={index}>
                <td style={{ border: "1px solid black", textAlign: "center" }}>
                  {index + 1}
                </td>
                <td style={{ border: "1px solid black", textAlign: "center" }}>
                  {item.item_code}
                </td>
                <td style={{ border: "1px solid black" }}>
                  {item.description}
                </td>
                <td style={{ border: "1px solid black", textAlign: "center" }}>
                  {item.qty}
                </td>
                <td style={{ border: "1px solid black", textAlign: "center" }}>
                  {item.discount}
                </td>
                <td style={{ border: "1px solid black", textAlign: "center" }}>
                  {item.rate}
                </td>
                <td style={{ border: "1px solid black", textAlign: "center" }}>
                  {item.amount}
                </td>
                <td style={{ border: "1px solid black", textAlign: "center" }}>
                  {item.weightPerUnit}
                </td>
                <td style={{ border: "1px solid black", textAlign: "center" }}>
                  {item.totalWeight}
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td
                colSpan="3"
                style={{ border: "1px solid black", textAlign: "center" }}
              >
               {t("common:qtyTotal")} 
              </td>
              <td style={{ border: "1px solid black", textAlign: "center" }}>
                {state.items.reduce((total, item) => total + item.qty, 0)}
              </td>
              <td
                colSpan="4"
                style={{ border: "1px solid black", textAlign: "center" }}
              >
               {t("common:total")} 
              </td>
              <td style={{ border: "1px solid black", textAlign: "center" }}>
                {state.items
                  .reduce((total, item) => total + parseFloat(item.amount), 0)
                  .toFixed(3)}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>

      <div
        className="grand-total"
        style={{
          fontSize: "14px",
          fontWeight: "bold",
        }}
      >
        <div style={{ textDecoration: "underline" }}>
          {t("common:grandTotal")} :
        </div>
        <div>
          {state.items
            .reduce((total, item) => total + parseFloat(item.amount), 0)
            .toFixed(3)}{" "}
          &nbsp;{" "}
          {i18n.language === "fr" || i18n.language === "en" ? "TND" : "دينار"}
          {/* {console.log(state.items
            .reduce((total, item) => total + parseFloat(item.amount), 0)
            .toFixed(3))} */}
        </div>
      </div>

      <div className="grand-total">
        <div
          style={{
            fontSize: "14px",
            fontWeight: "bold",
            textDecoration: "underline",
          }}
        >
          {t("common:priceLetter")} :
        </div>
        <div style={{ fontSize: "13px", fontWeight: "bold" }}>
          {convertirMontantEnLettres(
            state.items
              .reduce((total, item) => total + parseFloat(item.amount), 0)
              .toFixed(3)
          )}
        </div>
      </div>
    </div>
  );
};

export default PrintQuotation;
