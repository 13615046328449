import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import clsx from "clsx";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { FormControl, MenuItem, OutlinedInput, Select } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export const useStyles = makeStyles((theme) => ({
    card: {
        background: theme.palette.secondary.main,
        borderRadius: 10,
        border: "none",
    },
    cardBoldText: {
        color: theme.palette.primary.main,
        fontWeight: 600,
        fontSize: 14
    },
    cardTitle: {
        fontSize: 16
    },
    cardTitleBlock: {
        borderBottom: "1px solid #e7eaef",
        padding: "10px 20px 17px"
    },
    cardContentBlock: {
        padding: "1.4375rem 1.5rem 2.0625rem"
    },
    usernameBlock: {
        background: '#FFF',
        borderRadius: 19,
        padding: 14,
        marginBottom: 17
    },
    username: {
        color: "#2F334D",
        fontSize: 20,
        fontWeight: 600
    },
    textLight: {
        color: theme.palette.gray.main,
        fontSize: 12,
        fontWeight: 500
    },
    text: {
        fontSize: 14,
        fontWeight: 600
    },
    item: {
        padding: "0rem 1.625rem",
        marginBottom: "1.4375rem"
    },
    backLink: {
        fontSize: 12,
        color: '#2F334D',
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        marginTop: 14,
        marginLeft: 22
    },
    linkIcon: {
        height: 10,
        width: 8
    },
    selectInput: {
        '& .MuiInputBase-root': {
            background: "white",
            borderRadius: 5,
            border: 0,
            maxWidth: 200
        },
        '& .MuiOutlinedInput-input': {
            padding: "2.9px 14px",
            fontSize: 14
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: 0
        }
    }
}));

export const PaymentDocument = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const globalDefaults = useSelector(state => state.Login.globalDefaults);
    const { t } = useTranslation(["common", "order"]);
    return (
        <Box>
            {props.details && (
                <Card
                    className={clsx(classes.card, classes.cardBoldText, classes.selectInput)}
                    variant="outlined"
                >
                    <CardContent>
                        <Box display="flex">
                            <Grid item md={6}>
                                <Box className={classes.item}>
                                    <Typography color={'primary'} className={classes.textLight}>Email:</Typography>
                                    <Typography className={classes.text}>{props.details.contact_email}</Typography>
                                </Box>
                                <Box className={classes.item}>
                                    <Typography color={'primary'} className={classes.textLight}>{t("common:user")}:</Typography>
                                    <Typography className={classes.text}>{props.details.party_name}</Typography>
                                </Box>
                                <Box className={classes.item}>
                                    <Typography color={'primary'} className={classes.textLight}>{t("common:statut")}:</Typography>
                                    <Typography className={classes.text}>{props.details.status}</Typography>
                                </Box>
                            </Grid>
                            <Grid item md={6}>
                                <Box className={classes.item}>
                                    <Typography color={'primary'} className={classes.textLight}>Date:</Typography>
                                    <Typography className={classes.text}>{props.details.posting_date}</Typography>
                                </Box>
                                <Box className={classes.item}>
                                    <Typography color={'primary'} className={classes.textLight}>{t("common:total")}:</Typography>
                                    <Typography className={classes.text}>{props.details.paid_amount} {globalDefaults?.default_currency}</Typography>
                                </Box>
                                <Box className={classes.item}>
                                    <Typography color={'primary'} className={classes.textLight}>{t("common:company")}:</Typography>
                                    <Typography className={classes.text}>{props.details.company}</Typography>
                                </Box>
                            </Grid>
                        </Box>
                        <Box>
                            <Box className={classes.item}>
                                <Typography color={'primary'} className={classes.textLight}>{t("common:modePayment")}:</Typography>
                                <FormControl>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        defaultValue={props.payement_method}
                                        IconComponent={ExpandMore}
                                        onChange={(e) => {  props.setPayement_method(e.target.value) }}
                                        label={t("common:user")}
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "left"
                                            },
                                            getContentAnchorEl: null
                                        }}
                                        input={
                                            <OutlinedInput
                                                name="User"
                                                id="demo-simple-select-outlined-label"
                                            />}
                                    >
                                        <MenuItem disabled value={0}>{t("common:modePayment")}</MenuItem>
                                        {props.payementsMethods.map((p, index) => (
                                            <MenuItem key={index} value={p.mode_of_payment}>
                                                {p.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Box>
                    </CardContent>
                </Card>
            )}
        </Box>
    );
};
