import { SvgIcon } from "@material-ui/core";
import React from "react";
import { useTheme } from "@material-ui/core/styles";

function CalculatorIcon(props) {
  const theme = useTheme();
  return (
    <SvgIcon {...props} viewBox="-20 -30 550 550">
      <rect
        x="60.954"
        style={{ fill: theme.palette.primary.main }}
        width="390.092"
        height="512"
      />
      <rect
        x="60.954"
        style={{ fill: theme.palette.primary.main }}
        width="390.092"
        height="162.502"
      />
      <rect
        x="101.589"
        y="35.548"
        style={{ fill: theme.palette.calculator.background }}
        width="308.828"
        height="95.698"
      />
      <g>
        <rect
          x="264.051"
          y="343.851"
          style={{ fill: theme.palette.calculator.background }}
          width="61.158"
          height="55.309"
        />
        <rect
          x="264.051"
          y="419.24"
          style={{ fill: theme.palette.calculator.background }}
          width="61.158"
          height="55.309"
        />
        <rect
          x="264.051"
          y="268.476"
          style={{ fill: theme.palette.calculator.background }}
          width="61.158"
          height="55.309"
        />
        <rect
          x="264.051"
          y="193.1"
          style={{ fill: theme.palette.calculator.background }}
          width="61.158"
          height="55.309"
        />
        <rect
          x="345.29"
          y="268.476"
          style={{ fill: theme.palette.calculator.background }}
          width="61.158"
          height="55.309"
        />
        <rect
          x="345.29"
          y="193.1"
          style={{ fill: theme.palette.calculator.background }}
          width="61.158"
          height="55.309"
        />
        <rect
          x="345.29"
          y="419.24"
          style={{ fill: theme.palette.calculator.background }}
          width="61.158"
          height="55.309"
        />
        <rect
          x="345.29"
          y="343.851"
          style={{ fill: theme.palette.calculator.background }}
          width="61.158"
          height="55.309"
        />
        <rect
          x="182.826"
          y="343.851"
          style={{ fill: theme.palette.calculator.background }}
          width="61.158"
          height="55.309"
        />
        <rect
          x="101.589"
          y="268.476"
          style={{ fill: theme.palette.calculator.background }}
          width="61.158"
          height="55.309"
        />
        <rect
          x="101.589"
          y="419.24"
          style={{ fill: theme.palette.calculator.background }}
          width="61.158"
          height="55.309"
        />
        <rect
          x="182.826"
          y="419.24"
          style={{ fill: theme.palette.calculator.background }}
          width="61.158"
          height="55.309"
        />
        <rect
          x="101.589"
          y="193.1"
          style={{ fill: theme.palette.calculator.background }}
          width="61.158"
          height="55.309"
        />
        <rect
          x="101.589"
          y="343.851"
          style={{ fill: theme.palette.calculator.background }}
          width="61.158"
          height="55.309"
        />
        <rect
          x="182.826"
          y="268.476"
          style={{ fill: theme.palette.calculator.background }}
          width="61.158"
          height="55.309"
        />
        <rect
          x="182.826"
          y="193.1"
          style={{ fill: theme.palette.calculator.background }}
          width="61.158"
          height="55.309"
        />
      </g>
      <rect
        x="345.391"
        y="86.58"
        style={{ fill: "#D6D6D6" }}
        width="43.455"
        height="19.076"
      />
    </SvgIcon>
  );
}

export default CalculatorIcon;
