import React, { useEffect } from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { Select, MenuItem, FormControl } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    // alignItems: "center",
    justifyContent: "flex-end",
    margin: "0.75rem 0px 0.8125rem",
    flexWrap: "wrap",
    "& .MuiInputBase-root": {
      background: theme.palette.white.main,
    },
    "& .MuiOutlinedInput-input": {
      padding: "8.9px 14px",
      fontSize: 12,
    },
  },
  button: {
    marginRight: "10px",
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.blue.main,
    border: "1px solid",
    borderColor: theme.palette.blue.main,
    fontSize: "0.875rem",
    borderRadius: 10,
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
      borderColor: theme.palette.blue.main,
    },
  },
  select: {
    width: "100px",
  },
}));
export const Settings = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t, i18n } = useTranslation(["common"]);

  const handleLangChange = (e) => {
    i18n.changeLanguage(e.target.value);
    localStorage.setItem("language", e.target.value);
  };

  return (
    <Box>
      <Box className={classes.header}>
        <Button
          className={classes.button}
          size={"small"}
          variant="outlined"
          color="primary"
          onClick={() => history.push("/initialization")}
        >
          {t("common:resetData")}
        </Button>
        <Box>
          <FormControl variant="outlined" className={classes.formGroup}>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              className={classes.select}
              value={localStorage.getItem("i18nextLng")}
              onChange={handleLangChange}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
            >
              <MenuItem value={"fr"}>{t("common:francais")}</MenuItem>
              <MenuItem value={"en"}>{t("common:english")}</MenuItem>
              <MenuItem value={"ar"}>عربي</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
    </Box>
  );
};
