import React from "react";
import Box from "@material-ui/core/Box";
import { useStyles } from "../../../../style";
import { Button, Typography } from "@material-ui/core";
import clsx from "clsx";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import SortIcon from '@material-ui/icons/Sort';
import { useTableStyles } from "../../../../../../styles/table.style";
import Barcode from "react-barcode";
import { useDispatch, useSelector } from "react-redux";
import { SetTransfertData } from "../../../../../../store/transferRequest/transferRequest.action";
import { useTranslation } from "react-i18next";

export const OrdersToPick = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const tableclasses = useTableStyles();
  const [products, setProducts] = React.useState(props.orderdProducts);
  const Transferts = useSelector((state) => state.Transfert.Transferts);
  const { t } = useTranslation(["order", "common", "fulfillment", "product"]);

  const orderByLocation = () => {
    const sorted = props.orderdProducts.sort((a, b) => (a.item_location > b.item_location) ? 1 : -1)
    const fromIndex = sorted.map(function (e) { return e.item_location; }).indexOf('Inconnu');
    const element = sorted.splice(fromIndex, 1)[0];
    const toIndex = sorted.length;
    sorted.splice(toIndex, 0, element);
    setProducts(sorted)
  };

  return (
    <Box className={clsx(classes.productPaper, tableclasses.paper)}>
      <Table className={tableclasses.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            {props.byProduct && (<TableCell className={tableclasses.tablecellHeader} align="left">
              {t("common:order")}
            </TableCell>)}
            <TableCell className={tableclasses.tablecellHeader} align="center">
              {t("common:produit")}
            </TableCell>
            <TableCell className={tableclasses.tablecellHeader} align="center">
              {t("common:ref")}
            </TableCell>
            <TableCell className={tableclasses.tablecellHeader} align="center">
              {t("common:stock")}
            </TableCell>
            <TableCell className={tableclasses.tablecellHeader} align="center">
              {t("fulfillment:actualQty")}
            </TableCell>
            <TableCell className={tableclasses.tablecellHeader} align="center">
              {t("fulfillment:itemTopick")}
            </TableCell>
           {/*  <TableCell className={tableclasses.tablecellHeader} align="center" onClick={() => orderByLocation()} cursor="pointer">
              {t("fulfillment:plcmnt")} <SortIcon className={classes.sorticon} />
            </TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
            {props.orderdProducts.map((row, index) => (
              <TableRow
                className={clsx({
                  [tableclasses.tableRow]: true, //always applies
                })}
                key={index}
              >
                {props.byProduct && (<TableCell className={tableclasses.tableCell} align="left">
                  {row.order_id}
                </TableCell>)}
                <TableCell className={tableclasses.tableCell} align="center">
                  {row.item_name}
                </TableCell>
                <TableCell className={tableclasses.tableCell} align="center">
                  {row.item_code}
                </TableCell>
                <TableCell className={tableclasses.tableCell} align="center">
                  {row.is_stock_item ? t("product:inStock") : t("product:outOfStock")}
                </TableCell>
                <TableCell className={tableclasses.tableCell} align="center">
                  {row.actual_qty}
                </TableCell>
                <TableCell className={tableclasses.tableCell} align="center">
                  <Typography className={classes.nbScannerText}>{row.quantity}</Typography>
                </TableCell>
             {/*    <TableCell className={tableclasses.tableCell} align="center">
                  <Typography className={classes.nbScannerText}>{row.item_location ? row.item_location : "Inconnu"}</Typography>
                </TableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
  );
};
