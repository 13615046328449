import { CLEAR_STATE } from "../login/login.type";
import {
  SET_NOTIFICATION
} from "./notification.type";
const initialState = {
  notification: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NOTIFICATION:
      return {
        ...state,
        notification: action.notification,
      };
    case CLEAR_STATE:
      return (state = initialState);
    default:
      return state;
  }
};
export default reducer;
