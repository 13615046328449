import React, { useState } from "react";
import { useStyles } from "./style";
import { useSelector } from "react-redux";
import { useTableStyles } from "../../../styles/table.style";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { removeHTML, statusFormat } from "../../../helpers/utils";

export const PurchaseComponentToPrint = React.forwardRef((props, ref) => {
  const { document } = props;
  const classes = useStyles();
  const tableClasses = useTableStyles();
  const globalDefaults = useSelector((state) => state.Login.globalDefaults);
  const activeImageTheme = useSelector((state) => state.Theme.image);
  const selectedData = useSelector((state) => state.Login.selectedData);
  const exchangeRate = useSelector((state) => state.Checkout.exchangeRate);
  const selectCurrency = useSelector((state) => state.Checkout.selectCurrency);

  const { t } = useTranslation(["common", "buying", "checkout"]);

  return (
    <div className={classes.main} ref={ref}>
      <header className={classes.header}>
        <div className={classes.logo}>
          <img
            src={activeImageTheme}
            style={{
              display: "block",
              marginLeft: "80%",
              // marginRight: "50%",
              width: "30%",
            }}
          />
        </div>
        <h1 className={classes.title}>
          {"Facture N° : "}
          {document?.name}
          <br />
        </h1>

        <Grid container spacing={2}>
          {document && (
            <Grid item style={{ width: "70%" }}>
              <div>
                <u>
                  <strong>
                    {document?.status?.toLowerCase() !== "paid" &&
                      statusFormat(document?.status).value}
                  </strong>
                </u>
              </div>
              <div>
                <strong>
                  {t("common:supplier")} {": "}
                </strong>
                {document?.supplier ?? "-"}
              </div>
              <div>
                <strong>
                  {t("common:contact")}
                  {": "}
                </strong>
                {document?.address_display && <br />}
                {document?.address_display
                  ? removeHTML(document?.address_display)
                  : "-"}
              </div>
            </Grid>
          )}
          <Grid item style={{ width: "30%" }}>
            <div>{globalDefaults?.default_company}</div>
            {selectedData &&
              selectedData.contact &&
              Array.isArray(selectedData.contact) &&
              selectedData.contact.map((row, i) => (
                <>
                  <div>{row?.phone_no ?? row?.phone_no}</div>
                  <div>{row?.mobile_no ?? row?.mobile_no}</div>
                  <div>
                    {row.address_line_1 === null ? "-" : row.address_line_1}
                  </div>
                  <div>{row.city === null ? "-" : row.city}</div>
                </>
              ))}
          </Grid>
        </Grid>
      </header>
      <table className={classes.table}>
        <thead>
          <tr>
            <th className={classes.tableheader}>{t("common:ref")}</th>
            <th className={classes.tableheader}>{t("common:name")}</th>
            <th className={classes.tableheader}>P.U</th>
            <th className={classes.tableheader}>T.V.A.</th>
            <th className={classes.tableheader}>{t("common:remise")}</th>
            <th className={classes.tableheader}>{t("common:qty")}</th>
            <th className={classes.tableheader}>{t("common:total")}</th>
          </tr>
        </thead>
        <tbody>
          {document?.items?.map((row, index) => (
            <tr key={index}>
              <td className={classes.tableColumn} style={{ textAlign: "left" }}>
                {row?.item_code}
              </td>
              <td className={classes.tableColumn} style={{ textAlign: "left" }}>
                {row?.item_name}
              </td>
              <td className={classes.tableColumn} style={{ textAlign: "left" }}>
                {Math.abs(row?.price_list_rate)?.toFixed(3)}{" "}
                {document?.currency}
              </td>
              <td className={classes.tableColumn} style={{ textAlign: "left" }}>
                {row.item_tax_template ? row?.item_tax_template : "-"}
              </td>
              <td className={classes.tableColumn} style={{ textAlign: "left" }}>
                {row?.discount_percentage && row?.discount_percentage != 0
                  ? row?.discount_percentage + " %"
                  : row?.discount_amoun && row?.discount_amoun != 0
                  ? Math.abs(row?.discount_amount)?.toFixed(3) +
                    " " +
                    document?.currency
                  : "0 %"}
              </td>
              <td className={classes.tableColumn} style={{ textAlign: "left" }}>
                {Math.abs(row?.qty)}
              </td>
              <td className={classes.tableColumn} style={{ textAlign: "left" }}>
                {Math.abs(row?.amount)?.toFixed(3)} {document?.currency}
              </td>
            </tr>
          ))}
          <tr>
            <td className={classes.tableColumn} colSpan="5">
              {t("common:totalHT")}
            </td>
            <td
              className={classes.tableColumn}
              style={{ textAlign: "left" }}
              colSpan="2"
            >
              {Math.abs(document?.net_total)?.toFixed(3)} {document?.currency}
            </td>
          </tr>
          <tr>
            <td className={classes.tableColumn} colSpan="5">
              {t("common:totalTaxe")}
            </td>
            <td
              className={classes.tableColumn}
              style={{ textAlign: "left" }}
              colSpan="2"
            >
              {Math.abs(document?.total_taxes_and_charges)?.toFixed(3)}{" "}
              {document?.currency}
            </td>
          </tr>
          <tr>
            <td className={classes.tableColumn} colSpan="5">
              {t("common:remise")} {t("common:total")}
            </td>
            <td
              className={classes.tableColumn}
              style={{ textAlign: "left" }}
              colSpan="2"
            >
              {document?.discount
                ? document?.discount
                : document?.additional_discount_percentage
                ? Math.abs(document?.additional_discount_percentage)
                : 0}
              %
            </td>
          </tr>
          <tr>
            <td className={classes.tableColumn} colSpan="5">
              {t("common:qtyTotal")}
            </td>
            <td
              className={classes.tableColumn}
              style={{ textAlign: "left" }}
              colSpan="2"
            >
              {Math.abs(document?.total_qty)}
            </td>
          </tr>
          <tr>
            <td className={classes.tableColumn} colSpan="5">
              {t("common:grandTotal")}
            </td>
            <td
              className={classes.tableColumn}
              style={{ textAlign: "left" }}
              colSpan="2"
            >
              {Math.abs(document?.grand_total)?.toFixed(3)} {document?.currency}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
});
