export const handleBarcodeScan = async (barcode, setItemList, setState, setError) => {
    const filter = barcode
      ? { filters: [["Item", "barcode", "like", barcode]] }  // Filter when barcode is provided
      : {};  // No filter, fetch all items
  
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
          Authorization: JSON.parse(localStorage.getItem("user")).token,
        },
        body: JSON.stringify({
          doctype: "Item",
          fields: [
            "`tabItem`.`name`",
            "`tabItem`.`description`",
            "`tabItem`.`item_group`",
            "`tabItem`.`item_collection`",
            "`tabItem`.`item_name`",
            "`tabItem`.`standard_rate`",
            "`tabItem`.`opening_stock`",
            "`tabItem`.`image`",
            "`tabItem`.`net_amount`",
            "`tabItem`.`net_rate`",
            "`tabItem`.`price_list_rate`",
            "`tabItem`.`discount_amount`",
            "`tabItem Tax`.`item_tax_template`",
            "`tabItem`.`is_stock_item`",
            "`tabItem`.`has_variants`",
            "`tabItem`.`default_material_request_type`",
            "`tabItem`.`net_rate`",
            "`tabItem`.`standard_rate`",
            "`tabItem`.`item_code`",
            "`tabItem`.`description`",
            "`tabItem`.`item_group`",
            "`tabItem`.`item_name`",
            "`tabItem`.`barcode`",
            "`tabItem`.`total_projected_qty`",
          ],
          ...filter,  // Apply the filter (or no filter)
        }),
      });
  
      const data = await response.json();
      if (Array.isArray(data) && data.length > 0) {
        console.log("Fetched item data:", data);
  
        setItemList(data); // Set the item list
  
        if (barcode) {
          // Add the item directly to the state if barcode was provided
          setState(prevState => ({
            ...prevState,
            items: [
              ...prevState.items,
              {
                ...data[0],
                quantity: 1,
                last_purchase_rate: data[0].last_purchase_rate,
                base_amount: data[0].last_purchase_rate,
              },
            ],
          }));
        }
      } else {
        setError(prevError => ({
          ...prevError,
          items: barcode ? "No item found for this barcode" : "No items found",
        }));
      }
    } catch (error) {
      console.error("Error fetching item list:", error);
      setError(prevError => ({
        ...prevError,
        items: "Error fetching items. Please try again.",
      }));
    }
  };
  
  

export const fetchAllItems = async (setItemList, setError) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        doctype: "Item",
        fields: [
          "`tabItem`.`name`",
          "`tabItem`.`description`",
          "`tabItem`.`item_group`",
          "`tabItem`.`item_collection`",
          "`tabItem`.`item_name`",
          "`tabItem`.`standard_rate`",
          "`tabItem`.`opening_stock`",
          "`tabItem`.`image`",
          "`tabItem`.`net_amount`",
          "`tabItem`.`net_rate`",
          "`tabItem`.`price_list_rate`",
          "`tabItem`.`discount_amount`",
          "`tabItem Tax`.`item_tax_template`",
          "`tabItem`.`is_stock_item`",
          "`tabItem`.`has_variants`",
          "`tabItem`.`default_material_request_type`",
          "`tabItem`.`net_rate`",
          "`tabItem`.`standard_rate`",
          "`tabItem`.`item_code`",
          "`tabItem`.`description`",
          "`tabItem`.`item_group`",
          "`tabItem`.`item_name`",
          "`tabItem`.`barcode`",
          "`tabItem`.`total_projected_qty`",
        ],
      }),
    });

    const data = await response.json();
    if (Array.isArray(data)) {
      console.log("All items fetched:", data);
      setItemList(data);
    } else {
      setError(prevError => ({
        ...prevError,
        items: "No items found",
      }));
    }
  } catch (error) {
    console.error("Error fetching item list:", error);
    setError(prevError => ({
      ...prevError,
      items: "Error fetching items. Please try again.",
    }));
  }
};
