import { CLEAR_STATE } from "../login/login.type";
import {
  FULLFILMENTS_LOADED,
  FULLFILMENTS_PACKS_LOADED,
  FULFILLMENT_LOADED,
  CREATE_FULLFILMENTS_PACKS_SUCCESS,
  UPDATE_ORDER_STATUS_SUCCESS,
  UPDATE_MANY_ORDER_STATUS_SUCCESS,
  SET_ORDERS_TO_PICK,
  ORDER_CREATED_BRODCAST,
  ITEM_LOCATION_LOADED,
  SET_FULLFILMENT,
  CREATE_STATUS_EMAIL_SUCCESS,
  SET_PACKING_SELECTED,
  SET_MAPPED_DELIVERYNOTE,
} from "./fullfiment.type";

const initialState = {
  fullfilment: null,
  fullfilments: [],
  packs: [],
  order: null,
  ordersToPick: {
    orders: [],
    groupBy: null,
  },
  itemLocation: [],
  selectedPacking: null,
  mappedSalesDeliveryNote: null,
};

const reducer = (state = initialState, action) => {
  var fullfilments = state.fullfilments;

  switch (action.type) {
    case FULLFILMENTS_LOADED:
      return {
        ...state,
        fullfilments: action.payload,
      };
    case FULLFILMENTS_PACKS_LOADED:
      return {
        ...state,
        packs: action.payload,
      };
    case FULFILLMENT_LOADED:
      return {
        ...state,
        order: action.payload,
      };
    case CREATE_FULLFILMENTS_PACKS_SUCCESS:
      var fullfilmentIndex = fullfilments.findIndex(
        (f) => f.order_id == action.payload.order_id
      );
      fullfilments[fullfilmentIndex].status = "packed";
      fullfilments[fullfilmentIndex].products = action.payload.products;
      return {
        ...state,
        fullfilments: fullfilments,
        order: action.payload,
      };
    case UPDATE_ORDER_STATUS_SUCCESS:
      var fullfilmentIndex = fullfilments.findIndex(
        (f) => f.id == action.payload.id
      );
      fullfilments[fullfilmentIndex].status = action.payload.status;
      return {
        ...state,
        fullfilments: fullfilments,
        order: fullfilments[fullfilmentIndex],
      };
    case UPDATE_MANY_ORDER_STATUS_SUCCESS:
      const ordersToUpdate = fullfilments.filter((f) =>
        action.payload.orders.includes(f.order_id)
      );
      ordersToUpdate.forEach((element) => {
        var fullfilmentIndex = fullfilments.findIndex(
          (f) => f.id == element.id
        );
        fullfilments[fullfilmentIndex].status = action.payload.status;
      });
      return {
        ...state,
        fullfilments: fullfilments,
      };
    case SET_ORDERS_TO_PICK:
      return {
        ...state,
        ordersToPick: action.ordersToPick,
      };
    case SET_FULLFILMENT:
      return {
        ...state,
        fullfilment: action.fullfilment,
      };
    case ORDER_CREATED_BRODCAST:
      fullfilments.unshift(action.order);
      return {
        ...state,
        fullfilments: fullfilments,
      };
    case ITEM_LOCATION_LOADED:
      return {
        ...state,
        itemLocation: action.payload,
      };
    case CREATE_STATUS_EMAIL_SUCCESS:
      return {
        ...state,
        fullfilments: fullfilments,
      };
    case SET_PACKING_SELECTED:
      return {
        ...state,
        selectedPacking: action.payload,
      };
    case SET_MAPPED_DELIVERYNOTE:
      return {
        ...state,
        mappedSalesDeliveryNote: action.doc,
      };
    case CLEAR_STATE:
      return (state = initialState);
    default:
      return state;
  }
};

export default reducer;
