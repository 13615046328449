import React, { useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useTableStyles } from "../../../theme/table.style";
import { Box } from "@material-ui/core";
import { useStyles } from "./style";
import clsx from "clsx";
import { formatColumnsHeaders } from "../../../utils/formatColumnsHeaders";
import { useSelector } from "react-redux";
import { CurrencyFormatter, formatNumber } from "../../../helpers/helper";
import { useTheme } from "@material-ui/core/styles";
import { Pagination } from "@material-ui/lab";
import { useTranslation } from "react-i18next";

const StockReport = ({ doc }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation(["columns", "dashboard", "product"]);
  const tableClasses = useTableStyles();
  const globalDefaults = useSelector((state) => state.Login.globalDefaults);
  const [page, setPage] = useState(1);

  function formatResult(row, column, isResult) {
    if (isResult === true) {
      if (column.fieldtype === "Currency") {
        return row ? (
          <CurrencyFormatter
            locale="fr-FR"
            currency={globalDefaults?.default_currency}
            minimumFractionDigits={3}
            value={parseFloat(row)}
          />
        ) : null;
      } else if (!column.convertible && column.fieldtype === "Float") {
        return row ? formatNumber(row) : null;
      }
      return row;
    } else if (column.fieldtype === "Currency") {
      return row[column.fieldname] ? (
        <CurrencyFormatter
          locale="fr-FR"
          currency={globalDefaults?.default_currency}
          minimumFractionDigits={3}
          value={parseFloat(row[column.fieldname])}
        />
      ) : null;
    }
    return row[column.fieldname];
  }

  return (
    <>
      <Box className={tableClasses.paper} mt={2}>
        <Table
          className={clsx(tableClasses.table, classes.selectInput)}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              {doc &&
                doc?.columns?.map((column, index) => (
                  <TableCell
                    className={tableClasses.tablecellHeader}
                    align="left"
                    key={index}
                  >
                    {t(formatColumnsHeaders(column.fieldname))}
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {doc?.result
              ?.slice((page - 1) * 20, page * 20)
              .map((row, rowIndex) =>
                Array.isArray(row) ? (
                  <TableRow key={rowIndex} className={tableClasses.tableRow}>
                    {doc?.columns?.map((column, colIndex) => (
                      <TableCell
                        className={clsx(
                          tableClasses.tableCell,
                          classes.backgroundColor
                        )}
                        align={
                          ["Currency"].includes(column.fieldtype)
                            ? "right"
                            : "left"
                        }
                        key={colIndex}
                      >
                        {formatResult(row[colIndex], column, true)}
                      </TableCell>
                    ))}
                  </TableRow>
                ) : (
                  <TableRow key={rowIndex} className={tableClasses.tableRow}>
                    {doc?.columns?.map((column, colIndex) => (
                      <TableCell
                        className={tableClasses.tableCell}
                        align={
                          ["Currency"].includes(column.fieldtype)
                            ? "right"
                            : "left"
                        }
                        style={
                          column.fieldname === "in_qty"
                            ? { color: theme.palette.success.main }
                            : column.fieldname === "out_qty"
                            ? { color: theme.palette.error.main }
                            : {}
                        }
                        key={colIndex}
                      >
                        {formatResult(row, column, false)}
                      </TableCell>
                    ))}
                  </TableRow>
                )
              )}
          </TableBody>
        </Table>
      </Box>
      {Math.ceil(doc?.result?.length / 20) > 0 && (
        <Box display="flex" justifyContent="center" m={4}>
          <Pagination
            onChange={(event, newPage) => setPage(newPage)}
            page={page}
            count={Math.ceil(doc?.result?.length / 20)}
            color="primary"
          />
        </Box>
      )}
    </>
  );
};

export default StockReport;
