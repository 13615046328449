import React, { useEffect } from 'react'
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, FormControl, OutlinedInput, InputAdornment, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { useSelector } from "react-redux";
import { ProductBundle } from "../../../../components/productBundle";
import { useTranslation } from 'react-i18next';
import { GlobalfilterData } from "../../../../helpers/helper";
import Pagination from '@material-ui/lab/Pagination';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Box display="flex" flexWrap="wrap">
            {children}
          </Box>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  productItem: {
    marginBottom: "1.75rem",
    width: 220,
  },
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "0.75rem 0px 0.8125rem",
    flexWrap: "wrap",
    "& .MuiInputBase-root": {
      background: theme.palette.white.main,
    },
    "& .MuiOutlinedInput-input": {
      padding: "8.9px 14px",
      fontSize: 12,
    },
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    "& > *": {
      margin: theme.spacing(1),
    },
    flexWrap: "wrap",
    "& .MuiInputBase-root": {
      background: theme.palette.white.main,
    },
    "& .MuiOutlinedInput-input": {
      fontSize: 12,
    },
  },
  formControl: {
    maxWidth: 300,
    [theme.breakpoints.down("xs")]: {
      marginTop: 10,
    },
  },
  collection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    margin: "0.75rem 0px 0.8125rem",
    flexWrap: "wrap",
    "& .MuiInputBase-root": {
      background: theme.palette.white.main,
    },
    "& .MuiOutlinedInput-input": {
      padding: "8.9px 14px",
      fontSize: 12,
    },
  },
  paper: {
    width: "auto",
    height: "auto",
    maxHeight: "90%",
    maxWidth: "90vw",
    textAlign: "center",
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 10,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  button: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.blue.main,
    border: "1px solid",
    borderColor: theme.palette.blue.main,
    fontSize: "0.875rem",
    borderRadius: 10,
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
      borderColor: theme.palette.blue.main,
    },
  },
  icon: {
    marginRight: "0.375rem",
  },
  formBlock: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
    },
  },
  firstCol: {
    [theme.breakpoints.up("md")]: {
      marginRight: 37,
    },
  },
  label: {
    fontSize: 12,
    marginBottom: 6,
    color: theme.palette.gray.main,
    paddingLeft: 9,
  },
  modalFormControl: {
    marginBottom: 15,
    "& .MuiInputBase-root": {
      background: theme.palette.white.main,
    },
    "& .MuiOutlinedInput-input": {
      padding: "8.9px 14px",
      fontSize: 12,
    },
  },
  roott: {
    width: "100%",
    height: 545,
  },
  buttonn: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  backLink: {
    fontSize: 12,
    color: '#2F334D',
    display: "flex",
    alignItems: "center",
    cursor: "pointer"
  },
  backBox: {
    marginTop: "0.5rem",
    width: "fit-content"
  }
}));
export const ProductBundleList = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation(["common","product"])
  const [value, setValue] = React.useState(0);
  const [searchProduct, setSearchProduct] = React.useState("");
  const productsBundle = useSelector((state) => state.Product.productsBundle);
  const [products, setProducts] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const max_elemnts = 20;
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const SearchProduct = (e) => {
    setSearchProduct(e.target.value);
    const data = GlobalfilterData(productsBundle, e.target.value, [
      "name",
      "new_item_code",
      "description"
    ]);
    setProducts(data);

  };
  useEffect(() => {
    setProducts(productsBundle);
  }, [productsBundle]);
  return (
    <div className={classes.root}>
      <Box className={classes.header}>
        <Box className={classes.backBox}>
          <Typography
            align={"center"}
            color={"primary"}
            variant="caption"
            className={classes.backLink}
            onClick={() => history.goBack()}
          >
            <ArrowBackIcon />
            {t("common:back")}
          </Typography>
        </Box>
        <Box>
          <Grid container spacing={2}>
            <Grid item>

            </Grid>
            <Grid item>
              <FormControl className={classes.formControl} variant="outlined">
                <OutlinedInput
                  id="outlined-adornment-weight"
                  value={searchProduct}
                  placeholder={t("common:rechercher")}
                  onChange={SearchProduct}
                  endAdornment={
                    <InputAdornment position="end">
                      <span className="icon-search" />
                    </InputAdornment>
                  }
                  aria-describedby="outlined-weight-helper-text"
                  inputProps={{
                    "aria-label": "weight",
                  }}
                  labelWidth={0}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box mt={3}>

        <Box position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab
              label={t("product:allproductbundles")}
              {...a11yProps(0)}
            />

          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          {products &&
            products.slice((page - 1) * max_elemnts, page * max_elemnts).map((p, index) => (
              <Box key={index} className={classes.productItem}>
                <ProductBundle product={p} />
              </Box>
            ))}

        </TabPanel>
        {Math.ceil(products?.length / max_elemnts) > 0 && (
          <Box display="flex" justifyContent="center" m={4}>
            <Pagination onChange={(event, newPage) => setPage(newPage)} page={page} count={Math.ceil(products?.length / max_elemnts)} color="primary" />
          </Box>
        )}
      </Box>
    </div>
  );
};
