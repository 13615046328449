import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    main : {
        padding: "0px 30px"
    },
    header: {
        padding: "60px 0",
        marginBottom: 60
      },
    logo: {
      textAlign: "center",
      marginBottom: 10
    },
    title: {
        borderTop: '1px solid  #5D6975',
        borderBottom: '1px solid  #5D6975',
        color: '#5D6975',
        fontSize: '30px',
        lineHeight: '1.4em',
        fontWeight: 'normal',
        textAlign: 'center',
        margin: '0 0 20px 0'
      },
    project: {
        float: 'left',
        marginTop: 30,
        marginBottom: 60
      },
    projectitem :{
        color: '#5D6975',
        textAlign: 'left',
        marginRight: 10,
        fontSize: '0.8em',
      },
    discInput : {
        width: 50,
        "& .MuiInputBase-formControl": {
          marginTop: "-4px"
        },
        "& .MuiInputBase-input": {
          textAlign: "center"
        }
      },
    qtyControl: {
        position: "relative",
        width: "100px"
      }
}));