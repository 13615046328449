import {
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAIL,
  LOGOUT_USER,
  LOAD_STORES_SUCCESS,
  SET_SELECTED_CASHIER,
  SET_CUSH_FUND,
  LOAD_POS_SUCCESS,
  LOAD_POS_OPENINGS_SUCCESS,
  SET_POS_OPENED_DATE,
  LOGOUT,
  SET_SIGNATURE,
  OPEN_SIGNATURE,
  SET_USER,
  SET_GLOBAL_DEFAULTS,
  SET_USER_DETAILS,
  CLEAR_STATE,
} from "./login.type";

const initialState = {
  user: null,
  stores: [],
  pos: [],
  selectedData: null,
  cushFund: 0,
  openedPos: [],
  posOpeningDate: null,
  signature: null,
  openSignature: null,
  consumer: null,
  globalDefaults: null,
  userDetails: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
      };
    case LOGIN_USER_FAIL:
      return {
        ...state,
        user: action.payload,
      };
    case LOGOUT_USER:
      window.localStorage.removeItem("user");
      return {
        ...state,
        user: null,
        stores: [],
        pos: [],
        selectedData: null,
        cushFund: 0,
        openedPos: [],
        userDetails: null,
      };
    case LOGOUT:
      return {
        ...state,
        user: null,
        openedPos: [],
        posOpeningDate: null,
      };
    case LOAD_STORES_SUCCESS:
      return {
        ...state,
        stores: action.payload,
      };
    case LOAD_POS_SUCCESS:
      return {
        ...state,
        pos: action.payload,
      };
    case LOAD_POS_OPENINGS_SUCCESS:
      return {
        ...state,
        openedPos: !Array.isArray(action?.payload)
          ? []
          : [...new Set(action.payload.map((item) => item.pos_profile))],
      };
    case SET_SELECTED_CASHIER:
      return {
        ...state,
        selectedData: action.selectedData,
      };
    case SET_CUSH_FUND:
      return {
        ...state,
        cushFund: action.cushFund,
      };
    case SET_POS_OPENED_DATE:
      return {
        ...state,
        posOpeningDate: action.posOpeningDate,
      };
    case SET_SIGNATURE:
      return {
        ...state,
        signature: action.signature,
      };
    case OPEN_SIGNATURE:
      return {
        ...state,
        openSignature: action.openSignature,
      };
    case SET_USER:
      return {
        ...state,
        consumer: action.consumer,
      };
    case SET_GLOBAL_DEFAULTS:
      return {
        ...state,
        globalDefaults: action.globalDefaults,
      };
    case SET_USER_DETAILS:
      return {
        ...state,
        userDetails: action.userDetails,
      };
    case CLEAR_STATE:
      return (state = initialState);
    default:
      return state;
  }
};
export default reducer;
