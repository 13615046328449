import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { Cashier } from "./components/cashier";
import { isMobileOnly } from "react-device-detect";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import BusinessIcon from "@material-ui/icons/Business";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    marginTop: "5%",
    flexDirection: "column",
    alignItems: "center",
  },
  paper: {
    borderRadius: 10,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    width: "30%",
    padding: theme.spacing(3),
    backgroundColor: "#EEF2F6",
  },
  logo: {
    width: "30%",
    aspectRatio: "3/2",
    objectFit: "contain",
    mixBlendMode: "darken",
  },
  iconCon: {
    backgroundColor: theme.palette.primary.main,
    width: 70,
    height: 70,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 5,
    margin: 5,
  },
}));

export const Login = () => {
  const classes = useStyles();
  const { t } = useTranslation(["login"]);
  const activeImageTheme = useSelector((state) => state.Theme.image);

  return (
    <div className={classes.root}>
      <Box className={classes.paper}>
        {activeImageTheme ? (
          <img src={activeImageTheme} alt={"logo"} className={classes.logo} />
        ) : (
          <div className={classes.iconCon}>
            <BusinessIcon style={{ width: 60, height: 60, color: "white" }} />
          </div>
        )}
        <Cashier />
      </Box>
    </div>
  );
};
