import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import {
  Typography,
  Card,
  CardMedia,
  CardContent,
  Chip,
  Box,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  UpdateProducts,
  UpdatePayment,
  loadItemDetail,
} from "../../store/checkout/checkout.action";
import { formatDate } from "../../helpers/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    margin: 10,
    width: 200,
    flexGrow: 1,
    borderRadius: 10,
    position: "relative",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.2)",
  },
  smallRoot: {
    display: "flex",
    flexDirection: "column",
    margin: 5,
    width: 150,
    flexGrow: 1,
    borderRadius: 10,
    position: "relative",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.2)",
  },
  chip: {
    position: "absolute",
    backgroundColor: theme.palette.primary.main,
    color: "white",
    fontWeight: 600,
    borderRadius: 7,
    margin: "5px",
  },
  chipErr: {
    position: "absolute",
    backgroundColor: theme.palette.error.main,
    color: "white",
    fontWeight: 600,
    borderRadius: 7,
    margin: "5px",
  },
  img: {
    marginLeft: "auto",
    marginRight: "auto",
    width: "70%",
    aspectRatio: "3/2",
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: 14,
    fontWeight: 600,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    marginBottom: 0,
  },
  price: {
    color: theme.palette.gray.main,
    fontSize: 12,
  },
  templateBadge: {
    position: "absolute",
    bottom: 20,
    right: -70,
    width: 200,
    backgroundColor: theme.palette.primary.main,
    transform: "rotate(-45deg)",
    color: "white",
    fontSize: 14,
    fontWeight: 600,
    align: "center",
  },
  variantBadge: {
    position: "absolute",
    bottom: 20,
    right: -70,
    width: 200,
    backgroundColor: theme.palette.blue.main,
    transform: "rotate(-45deg)",
    color: "white",
    fontSize: 14,
    fontWeight: 600,
    align: "center",
  },
}));
export const ProductItem = (props) => {
 
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation(["common", "checkout"]);
  const products = useSelector((state) => state.Product.filtredProducts);
  const activeImageTheme = useSelector((state) => state.Theme.image);
  const globalDefaults = useSelector((state) => state.Login.globalDefaults);
  const suggestion = useSelector((state) => state.Product.products);
  const productsPos = useSelector((state) => state.Product.productsPos);
  const rows = useSelector((state) => state.Checkout.products);
  const currencyExchangeRate = useSelector(
    (state) => state.Checkout.currencyExchangeRate
  );
  const amount = useSelector((state) => state.Checkout.amount);
  const user = useSelector((state) => state.Login.user);
  const pricingRules = useSelector((state) => state.Checkout.pricingRules);
  const defaultClient = useSelector((state) => state.Checkout.defaultClient);
  const selectedClient = useSelector((state) => state.Checkout.client);
  const store = useSelector((state) => state.Login.selectedData?.store);
  const cashier = useSelector((state) => state.Login.selectedData?.cashier);
  const hold = useSelector((state) => state.Checkout.holds);
  const imageList = useSelector((state) => state.Product);

  const loadItemDetails = (items) => {
    items?.forEach((item) => {
      let doc = {
        doctype: "POS Invoice",
        company: globalDefaults.default_company,
        posting_date: formatDate(new Date(), "YYYY-MM-DD"),
        currency: globalDefaults.default_currency,
        price_list_currency: globalDefaults.default_currency,
        ignore_pricing_rule: 0,
        party_account_currency: globalDefaults.default_currency,
        items: [
          {
            doctype: "POS Invoice Item",
            item_group: item.item_group,
            parentfield: "items",
            parenttype: "POS Invoice",
            qty: item.qty,
            item_code: item.item_code,
          },
        ],
        pos_profile: cashier,
        set_warehouse: store,
        customer_name: selectedClient?.customer_name
          ? selectedClient?.customer_name
          : defaultClient?.customer_name,
        customer: selectedClient?.customer_name
          ? selectedClient?.customer_name
          : defaultClient?.customer_name,
      };
      let args = {
        item_code: item.item_code,
        set_warehouse: store,
        customer: selectedClient?.customer_name
          ? selectedClient?.customer_name
          : defaultClient?.customer_name,
        currency: globalDefaults.default_currency,
        price_list_currency: globalDefaults.default_currency,
        company: globalDefaults.default_company,
        ignore_pricing_rule: 0,
        doctype: "POS Invoice",
        pos_profile: cashier,
      };
      dispatch(loadItemDetail(args, doc));
    });
  };
  const availableStock = (id) => {
    const IndexinstockPOS = productsPos.findIndex((s) => s.item_code == id);
    const ItemIndex = rows.findIndex((s) => s.item_code == id);
    return (
      rows[ItemIndex]?.quantity <
        productsPos[IndexinstockPOS].actual_qty - isReservedInPOS(id) &&
      rows[ItemIndex]?.quantity <
        productsPos[IndexinstockPOS].actual_qty - isReservedInPOS(id)
    );
  };

  const handleAddProduct = async () => {
    
    if (props.isCheckout) {
      if (isExistInPOS(props.product.item_code)) {
        var discountedProducts =
          pricingRules && pricingRules.items
            ? pricingRules.items.map((i) => i.item_code)
            : null;
        var data = suggestion.find(
          (s) => s.item_code == props.product.item_code
        );

        if (data) {
          const match = productsPos.find(
            (pos) => pos.item_code === data.item_code
          );

          if (match) {
            data = {
              ...data,
              ...match,
            };
          }
        }

        const ItemIndex = rows.findIndex(
          (s) => s.item_code == props.product.item_code
        );
        
        
        if (ItemIndex != -1) {
          const teststock = await availableStock(rows[ItemIndex]?.item_code);
          if (teststock) {
            rows[ItemIndex].quantity = parseInt(rows[ItemIndex].quantity) + 1;
            rows[ItemIndex].qty = parseInt(rows[ItemIndex].qty) + 1;
            rows[ItemIndex].price_list_rate = parseFloat(
              rows[ItemIndex].price_list_rate
                ? rows[ItemIndex].price_list_rate
                : rows[ItemIndex].standard_rate
            );
            rows[ItemIndex].net_rate =
              (rows[ItemIndex].price_list_rate -
                (rows[ItemIndex].price_list_rate *
                  parseFloat(rows[ItemIndex].discount)) /
                  100) /
              currencyExchangeRate;
            rows[ItemIndex].net_amount =
              rows[ItemIndex].net_rate * rows[ItemIndex].quantity;
            rows[ItemIndex].discount_amount =
              (rows[ItemIndex].price_list_rate - rows[ItemIndex].net_rate) *
              rows[ItemIndex].quantity;
          }
        } else {
          data.discount = 0;
          data.quantity = 1;
          data.qty = 1;
          data.price_list_rate = data.price_list_rate
            ? parseFloat(data.price_list_rate)
            : parseFloat(data.standard_rate);
          data.net_rate =
            (data.price_list_rate -
              (data.price_list_rate * parseFloat(data.discount)) / 100) /
            currencyExchangeRate;
          data.net_amount = data.net_rate * data.quantity;
          data.discount_amount =
            (data.price_list_rate - data.net_rate) * data.quantity;
          if (
            discountedProducts &&
            discountedProducts.includes(data.item_code) &&
            pricingRules?.discount_percentage != 0
          ) {
            data.discount = pricingRules?.discount_percentage;
            data.net_rate =
              (data.price_list_rate -
                (data.price_list_rate *
                  parseFloat(pricingRules?.discount_percentage)) /
                  100) /
              currencyExchangeRate;
            data.net_amount = data.net_rate * data.quantity;
          } else if (
            discountedProducts &&
            discountedProducts.includes(data.item_code) &&
            pricingRules?.discount_amount != 0
          ) {
            data.discount_amount = pricingRules?.discount_amount;
            data.net_rate =
              data.price_list_rate - pricingRules?.discount_amount;
            data.net_amount = data.net_rate * data.quantity;
          }
          rows.push(data);
          props.initialLines.splice(-1);
        }
        dispatch(UpdateProducts(rows));
        loadItemDetails(rows);
        const totalAmount = props.GetTotalPrice(rows);
        const taxAmount =
          totalAmount -
          rows.reduce((a, v) => (a = a + parseFloat(v.net_amount)), 0);
        dispatch(UpdatePayment({ ...amount, totalAmount, taxAmount }));
      }
    } else {
      history.push(`/stock-product/${props?.product?.item_code}`);
    }
  };





  const isExistInPOS = (item_code) => {
    if (
      productsPos &&
      productsPos.find((p) => p.item_code == item_code)?.actual_qty
    ) {
      return true;
    } else {
      return false;
    }
  };

  function getImgUrl(itemCode) {
    if (imageList && imageList?.products) {
      const IIndex = imageList?.products?.findIndex(
        (e) => e.item_code === itemCode
      );
      if (IIndex !== -1 && imageList?.products[IIndex]?.image ) {
 
        return (
          `https://erp.vndit.io` +
          imageList?.products[IIndex]?.image
        );
      } else {
        return activeImageTheme;
      }
    } else {
      return activeImageTheme;
    }
  }

  const isReservedInPOS = (item_code) => {
    let totalQtyReserved = 0;
    hold &&
      hold
        ?.filter((element) => element?.payment_status === "partly paid")
        ?.forEach((element) => {
          if (element?.amount?.payments.length > 0) {
            const reservedProducts = element?.products?.filter(
              (el) => el.item_code === item_code
            );

            reservedProducts?.forEach((reservedProduct) => {
              totalQtyReserved += reservedProduct.qty;
            });
          }
        });

    return totalQtyReserved;
  };

  return (
    <Card className={props?.isCheckout ? classes.smallRoot : classes.root}>
      {user && user.role == "Cashier" && props?.product?.has_variants === 0 ? (
        <Chip
          className={
            isExistInPOS(props?.product?.item_code)
              ? classes.chip
              : classes.chipErr
          }
          label={
            productsPos &&
            productsPos.find((p) => p.item_code == props?.product?.item_code)
              ?.actual_qty
              ? productsPos.find(
                  (p) => p.item_code == props?.product?.item_code
                )?.actual_qty - isReservedInPOS(props?.product?.item_code)
              : 0
          }
        />
      ) : null}
      <CardMedia
        className={classes.img}
        image={getImgUrl(props?.product?.item_code)}
        title={props?.product?.item_name}
      />
      <CardContent
        style={{ cursor: "pointer" }}
        onClick={() => {handleAddProduct()}}
      >
        <Typography
          gutterBottom
          color="primary"
          style={{ lineHeight: 0.8, fontWeight: 600 }}
          variant="body1"
          component="h2"
        >
          {props?.product?.item_name ? props?.product?.item_name : ""}
        </Typography>
        <Typography variant="caption" color="textSecondary" component="p">
          {props?.product?.item_code}
        </Typography>
        <Typography variant="caption" color="textSecondary" component="p">
          {props?.product?.item_group}
        </Typography>
      </CardContent>
      {props?.product?.has_variants === 1 ? (
        <Box
          display={"flex"}
          justifyContent={"center"}
          className={classes.templateBadge}
        >
          TEMPLATE
        </Box>
      ) : props?.product?.variant_of ? (
        <Box
          display={"flex"}
          justifyContent={"center"}
          className={classes.variantBadge}
        >
          VARIANT
        </Box>
      ) : null}
    </Card>
  );
};
