import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { CancelCheckout, SetDiscount } from "../store/checkout/checkout.action";
export const ButtonsFunction = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const GoBack = () => {
    history.goBack();
  };

  const SetPercentDiscount = (value) => {
    dispatch(SetDiscount(value));
  };
  
  const CancelCheckoutData = (value) => {
    dispatch(CancelCheckout());
  };
  return { GoBack, SetPercentDiscount,CancelCheckoutData };
};
