import Box from "@material-ui/core/Box";
import AddItem from "../../../../components/addItem/AddItem";
import { SetNotification } from "../../../../store/notification/notification.action";
import { Delete } from "@material-ui/icons";
import { useNavigate, useParams } from "react-router";
import { Loader } from "../../../../components/loader";
import axios from "axios";
import { useTranslation } from "react-i18next";

import useGetAxios from "../../../initialization/useGetAxios";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import AddBoxIcon from "@mui/icons-material/AddBox";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Grid,
  TextField,
  Collapse,
  LinearProgress,
  FormControl,
  InputAdornment,
  Link,
  Select,
  Modal,
  OutlinedInput,
  InputLabel,
  MenuItem,
} from "@mui/material";
import { Button } from "@material-ui/core";
import {
  DatePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import useGetDoc from "../../../../hooks/useGetDoc";
import React, { useEffect, useState } from "react";
import { formatDate } from "../../../../helpers/utils";
import { fr } from "date-fns/esm/locale";
import DateFnsUtils from "@date-io/date-fns";
import { SpaceBar } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
const DetailMaterialRequest = ({ setCloseModal, id }) => {
  console.log("id purchase", id);
  // const { id } = useParams();
  const { t } = useTranslation(["common", "buying"]);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const [loadingButton, setLoadingButton] = useState(false);
  const [itemList, setItemList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [companySelected, setCompanySelected] = useState("");
  const [addressList, setAddressList] = useState([]);
    
  const [warehouseList, setWarehouseList] = useState([]);
  const [warehouseSelected, setWarehouseSelected] = useState("");
    const [purposeList, setPurposeList] = useState(["Purchase","Material Transfer","Material Issue","Manufacture","Customer Provided"]);

  const [purposeSelected, setPurposeSelected] = useState("Purchase");
  const [scannedCode, setScannedCode] = useState("");
  const [showScanner, setShowScanner] = useState(false);
  const [creation, setCreated] = useState('');
  const [modified, setModified] = useState('');
  const [modifiedBy, setModifiedBy] = useState('');
   
  // const [dataQuotationList, setDataQuotationList] = useState([]);

  const [error, setError] = useState({
    supplier: false,
    scheduleDate: false,
    scheduleDateTill: false,
    items: false,
    taxes: false,
  });
  const [scheduleDate, setScheduleDate] = useState(
    formatDate(new Date(), "YYYY-MM-DD")
  );

  const [scheduleDateTill, setScheduleDateTill] = useState(
    formatDate(new Date(), "YYYY-MM-DD")
  );
 
  const [state, setState] = useState({
    items: [],
    taxes: [],
  });

  const handleQuantityChange = (index, value) => {
    const updatedItems = [...state.items];
    updatedItems[index].qty = value;
    setState({ ...state, items: updatedItems });
  };

  const handleDeleteItem = (index) => {
    const updatedItems = [...state.items];
    updatedItems.splice(index, 1);
    setState({ ...state, items: updatedItems });
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Set loading to false whether data fetching succeeds or fails

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URI}/api/details`,
          {
            params: {
              doctype: "Material Request",
              name: id.name,
            },
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
              Authorization: JSON.parse(localStorage.getItem("user")).token,
            },
          }
        );

        // Operations to be executed after data fetching is completed successfully
        response.data["docs"].forEach((item) => { 
          setModified(item.modified);
          setCreated(item.creation);
          setModifiedBy(item.modified_by);
          setPurposeSelected(item.material_request_type); 
          setCompanySelected(item.company); 
          setWarehouseSelected(item.set_warehouse);
          setScheduleDate(item.transaction_date);
          setScheduleDateTill(item.schedule_date);
          
          item.items.map((item) => {
            state.items.push(item);
          });
        });
        setLoading(false); // Set loading to false whether data fetching succeeds or fails
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle error as needed
      } finally {
        setLoading(false); // Set loading to false whether data fetching succeeds or fails
      }
    };

    fetchData();
  }, []);
 
 
  const getItemList = () => {
    if (companySelected) {
      fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
          Authorization: JSON.parse(localStorage.getItem("user")).token,
        },
        body: JSON.stringify({
          doctype: "Item",
          fields: [
            "`tabItem`.`name`",
            "`tabItem`.`description`",
            "`tabItem`.`item_group`",
            "`tabItem`.`item_collection`",
            "`tabItem`.`item_name`",
            "`tabItem`.`standard_rate`",
            "`tabItem`.`opening_stock`",
              "`tabItem`.`uoms`",

            "`tabItem`.`image`",
            "`tabItem`.`net_amount`",
            "`tabItem`.`net_rate`",
            "`tabItem`.`price_list_rate`",
            "`tabItem`.`discount_amount`",
            "`tabItem Tax`.`item_tax_template`",
            "`tabItem`.`is_stock_item`",
            "`tabItem`.`has_variants`",
            "`tabItem`.`default_material_request_type`",
            "`tabItem`.`net_rate`",
            "`tabItem`.`standard_rate`",
            "`tabItem`.`item_code`",
            "`tabItem`.`description`",
            "`tabItem`.`item_group`",
            "`tabItem`.`total_projected_qty`",
          ],
          filters: [["Item Default", "company", "=", companySelected]],
        }),
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          if (Array.isArray(data)) {
            setItemList(data);
          }
        });
    }
  };
  const getCompanyList = () => {
    fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        doctype: "Company",
        fields: ["`tabCompany`.`name`"],
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (Array.isArray(data)) {
          setCompanyList(data);
        }
      });
  };
   
  const getWarehouseList = () => {
    fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        doctype: "Warehouse",
        fields: [
          "`tabWarehouse`.`name`",
          "`tabWarehouse`.`owner`",
          "`tabWarehouse`.`creation`",
          "`tabWarehouse`.`modified`",
          "`tabWarehouse`.`modified_by`",
          "`tabWarehouse`.`_user_tags`",
          "`tabWarehouse`.`_comments`",
          "`tabWarehouse`.`_assign`",
          "`tabWarehouse`.`_liked_by`",
          "`tabWarehouse`.`docstatus`",
          "`tabWarehouse`.`parent`",
          "`tabWarehouse`.`parenttype`",
          "`tabWarehouse`.`parentfield`",
          "`tabWarehouse`.`idx`",
          "`tabWarehouse`.`is_group`",
          "`tabWarehouse`.`company`",
          "`tabWarehouse`.`disabled`",
          "`tabWarehouse`.`city`",
          "`tabWarehouse`.`warehouse_name`",
        ],
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (Array.isArray(data)) {
          setWarehouseList(data);
        }
      });
  };
  const handleSaveQuotation = () => {
    setLoadingButton(true);
    const data = {
      doc: {
        docstatus: 0,
        doctype: "Material Request",
        name: id.name,
        status: "Draft",
        modified: modified,
        modified_by: modifiedBy,
        creation: creation,
        naming_series: "MAT-MR-.YYYY.-",
        material_request_type: purposeSelected,
        transaction_date: scheduleDate,
        company: companySelected,
         
        __run_link_triggers: false,
        schedule_date: scheduleDateTill,
        set_warehouse: warehouseSelected,
        items: state.items.map((item) => {
          
          return {
            docstatus: 0,
            doctype: "Material Request Item",
            rate: item.standard_rate,
            amount: item.standard_rate,
            base_rate: item.base_rate,
            base_amount: item.base_amount,
            net_rate: item.net_rate,
            net_amount: item.net_amount,
            base_net_rate: item.base_net_rate,
            base_net_amount: item.base_net_amount,
            discount_amount: item.discount_amount,
            is_free_item: 0,
            conversion_factor: item.conversion_factor,
            external_id: item.external_id,
            stock_uom:  item.stock_uom,
            uom:  item.uom,
            page_break: 0,
            parent: id.name,
            parentfield: "items",
            parenttype: "Material Request",
            idx: 1,
            qty: item.qty,
            stock_qty:  item.stock_qty,
            item_code: item.item_code,
            item_name: item.item_name,
            description:  item.description,
       
          
           
          };
        }),
         __onload : {
           make_payment_via_journal_entry : 0,
           backflush_based_on : "BOM"
        },
        "__unsaved": 1,
       // "customer": "aba - 1"
      },
      action: "Submit",
    };

    fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem("user"))?.token,
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        setLoadingButton(false);
        if (!res.ok) {
          dispatch(
            SetNotification({ code: "error", message: "Something went wrong" })
          );
        }
        return res.json();
      })
      .then((data) => {
        if (data?.success === false) {
          setLoadingButton(false);
          dispatch(
            SetNotification({ code: "warning", message: data?.message })
          );
        } else {
          setLoadingButton(false);
          dispatch(
            SetNotification({
              code: "success",
              message: `Demande d'Achat' enregistrée avec succès`,
            })
          );
          handleCancel();
          handleReload();
        }
      });
  };
  const handleReload = () => {
    window.location.reload();
  }
  useEffect(() => {
    getCompanyList()
    getWarehouseList();
  }, []);
  useEffect(() => {
  
    getItemList();
  }, [companySelected]);
 
  const handleChangeSelectmultiple = (event) => {
    setError({ ...error, [event.target.name]: false });
    setState({ ...state, [event.target.name]: event.target.value });
  };
  const handleDateChange = (date, event) => {
    isNaN(Date.parse(date))
      ? setError({ ...error, scheduleDate: false })
      : setScheduleDate(date.toISOString().slice(0, 10));
  };
  const handleDateTillChange = (date, event) => {
    isNaN(Date.parse(date))
      ? setError({ ...error, scheduleDateTill: false })
      : setScheduleDateTill(date.toISOString().slice(0, 10));
  };
  const handleCancel = () => {
    setCloseModal(); // Call setCloseModal to close the modal
  };
  if (loading) {
    return <Loader />; // Render loader while data is being fetched
  }
  return (
    <Card
      sx={{
        boxShadow: 20,
        marginLeft: "-20px",
        marginRight: "-20px",
        marginTop: "-5px",
        marginBottom: "-12px",
      }}
    >
      <div style={{ margin: "15px" }}>
        <Grid item xs={12} style={{ marginTop: 20, fontWeight: "bold" }}>
          <Box
            display={"flex"}
            alignItems={"center"}
            style={{ fontSize: "1.25rem", fontWeight: 800 }}
          >
            {/* <AddBoxIcon sx={{ mr: 1 }} style={{ color: "#0b68ff" }} /> */}
            <div style={{ color: "#2F4B7C" }}>
              {t("common:MaterialRequest")}: {id.name}
            </div>
          </Box>
        </Grid>
      </div>
      <Divider orientation="horizontal" fullWidth />

      <div style={{ marginLeft: "40px" }}>
        <Grid container spacing={12} style={{ paddingLeft: "3%" }}>
        <Grid item xs={4} style={{ marginTop: 25 }} >
            <FormControl fullWidth style={{ marginTop: 35 }}>
              <InputLabel
                id="demo-simple-select-label"
                style={{ fontSize: "16px" }}
              >
                {" "}
                {t("common:Purpose")}
              </InputLabel>
              <Select
                className="CuFormInput"
                style={{ width: "80%" }}
                labelId="select-label"
                id="simple-select"
                value={purposeSelected}
                label={"Purpose"}
                onChange={(e) => setPurposeSelected(e.target.value)}
              >
                {purposeList?.map((item) => (
                  <MenuItem style={{ fontSize: 12 }} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
           <Grid style={{ marginTop: 25 }}>
             <MuiPickersUtilsProvider
               padding={50}
               locale={fr}
               utils={DateFnsUtils}
             >
               <InputLabel className={"label"}>{t("common:TransactionDate")}</InputLabel>
               <KeyboardDatePicker
                 className={"date"}
                 style={{ marginTop: 10, width: "80%" }}
                 disableToolbar
                 variant="inline"
                 format="dd/MM/yyyy"
                 name="scheduleDate"
                 inputVariant="outlined"
                 id="date-picker-inline"
                 value={scheduleDate}
                 onChange={handleDateChange}
                 KeyboardButtonProps={{
                   "aria-label": "change date",
                 }}
                 minDateMessage={t("common:minDateMessage")}
               />
             </MuiPickersUtilsProvider>
           </Grid>
         </Grid>
          <Grid item xs={4}>
            <Grid style={{ marginTop: 25 }}>
              <MuiPickersUtilsProvider
                padding={50}
                locale={fr}
                utils={DateFnsUtils}
              >
                <InputLabel className={"label"}>{t("common:RequiredBy")}</InputLabel>
                <KeyboardDatePicker
                  className={"date"}
                  style={{ marginTop: 10, width: "80%" }}
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  name="schedule_date"
                  inputVariant="outlined"
                  id="date-picker-inline"
                  value={scheduleDateTill}
                  onChange={handleDateTillChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  minDate={formatDate(scheduleDate, "YYYY-MM-DD")}
                  minDateMessage={t("common:minDateMessage")}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>


          <Grid item xs={6}>
            <FormControl fullWidth style={{ marginTop: 35 }}>
              <InputLabel
                id="demo-simple-select-label"
                style={{ fontSize: "16px" }}
              >
                {" "}
                {t("common:company")}
              </InputLabel>
              <Select
                className="CuFormInput"
                style={{ width: "80%" }}
                labelId="select-label"
                id="simple-select"
                value={companySelected}
                label={t("common:company")}
                onChange={(e) => setCompanySelected(e.target.value)}
              >
                {companyList?.map((item) => (
                  <MenuItem style={{ fontSize: 12 }} value={item?.name}>
                    {item?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <FormControl style={{ marginTop: 35 }}>
              <InputLabel
                id=" simple-select-label"
                style={{ fontSize: "16px" }}
              >
                {t("common:set_warehouse")}
              </InputLabel>
              <Select
                className="CuFormInput"
                style={{ width: "80%" }}
                labelId="select-label-item"
                id="simple-select-item"
                value={warehouseSelected}
                label={t("common:set_warehouse")}
                onChange={(e) => {
                  setWarehouseSelected(e.target.value);
                }}
              >
                {warehouseList?.map((item) => (
                  <MenuItem style={{ fontSize: 12 }} value={item?.name}>
                    {item?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
 

          <Grid item xs={6}>
            <AddItem
              sx={{ width: "68%" }}
              fullwidth
              label={t("common:Items")}
              name="items"
              value={state.items}
              error={error.items}
              propToRender={"item_name"}
              handleChange={handleChangeSelectmultiple}
              defaultItem={""}
              data={itemList}
            />
          </Grid>

          {state.items.length > 0 ? (
            <Grid item xs={11}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          fontWeight: "bold",
                          fontSize: 15,
                          textAlign: "center",
                        }}
                      >
                        {t("common:Items")}
                      </TableCell>
                      <TableCell
                        style={{
                          fontWeight: "bold",
                          fontSize: 15,
                          textAlign: "center",
                        }}
                      >
                        {t("common:qty")}
                      </TableCell>
                      <TableCell
                        style={{
                          fontWeight: "bold",
                          fontSize: 15,
                          textAlign: "center",
                        }}
                      >
                        {t("common:Action")}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {state.items.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>{item.item_name}</TableCell>
                        <TableCell>
                          <TextField
                            required
                            type="number"
                            value={item.qty}
                            onChange={(e) =>
                              handleQuantityChange(index, e.target.value)
                            }
                          />
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          <IconButton onClick={() => handleDeleteItem(index)}>
                            <Delete color="error" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          ) : null}

         
          
       
          {/* <Grid item xs={6}>
            <TextField
              name="qnumber"
              className="CuFormInput"
              style={{ marginTop: "28px", width: "80%" }}
              onChange={(e) => setScannedCode(e.target.value)}
              label="Barcode"
              variant="outlined"
              value={scannedCode ? scannedCode : ""}
            />
          </Grid> */}

          <Grid item xs={12} style={{ margin: "20px", marginRight: "15%" }}>
            <Box style={{ display: "flex", justifyContent: "center" }}>
              <Button
                size="large"
                disabled={loadingButton}
                color="primary"
                variant="contained"
                onClick={handleSaveQuotation}
                style={{ marginRight: "16px" }} // Add margin to separate buttons
              >
                {t("common:demender")}
              </Button>
              <Button
                size="large"
                color="error"
                variant="contained"
                onClick={handleCancel}
              >
                {t("common:annuler")}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </div>
    </Card>
  );
};

export default DetailMaterialRequest;
