import React, { useEffect, useRef } from "react";
import ReactToPrint from "react-to-print";
import { makeStyles } from "@material-ui/core/styles";
import PrintIcon from "@material-ui/icons/Print";
import {
  Badge,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { TransfertComponentToPrint } from "../componentToPrint/transfert";
import { useState } from "react";
import { useParams } from "react-router-dom";
import DescriptionRoundedIcon from "@material-ui/icons/DescriptionRounded";
import { StockEntry2Print } from "../componentToPrint/stockEntry/stockEntry2Print";
import axiosInstance from "../../history/axiosInstance";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  print: {
    display: "none",
  },
  icons: {
    color: theme.palette.gray.main,
    cursor: "pointer",
  },
}));

export const Transfert = ({ count }) => {
  const componentRef = useRef();
  const classes = useStyles();
  const { t } = useTranslation(["common", "transfert"]);

  const { id } = useParams();
  const header = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
    Authorization: JSON.parse(localStorage.getItem("user"))?.token,
  };

  const [open, setOpen] = useState(false);
  const [stockEntryList, setStockEntryList] = useState([]);
  const [stockEntryDoc, setStockEntryDoc] = useState(null);

  const loadStockEntryDoc = async (name) => {
    return axiosInstance
      .get(
        `${process.env.REACT_APP_API_URI}/api/details?doctype=Stock%20Entry&name=${name}`,
        { headers: header }
      )
      .then(({ data }) => {
        setStockEntryDoc(data?.docs[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getStockEntryList = () => {
    fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
      method: "POST",
      headers: header,
      body: JSON.stringify({
        doctype: "Stock Entry",
        fields: ["`tabStock Entry`.`name`"],
        filters: [
          ["Stock Entry Detail", "material_request", "=", id],
          ["Stock Entry", "docstatus", "=", "1"],
        ],
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (Array.isArray(data)) {
          setStockEntryList(data);
        }
      });
  };

  useEffect(() => {
    if (id) {
      getStockEntryList();
    }
  }, []);

  return (
    <div>
      <IconButton
        color="primary"
        disabled={false}
        onClick={() => setOpen(true)}
      >
        <Badge badgeContent={count} color="secondary">
          <PrintIcon />
        </Badge>
      </IconButton>

      {/* POPUP */}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle align={"center"}>
          <Typography align={"center"} color={"primary"}>
            {t("transfert:sel")}
          </Typography>
        </DialogTitle>
        <DialogContent>
          {stockEntryList?.map((item, i) => (
            <Box
              p={2}
              mb={1}
              key={`STE-${i}`}
              style={{
                backgroundColor: "ButtonShadow",
                borderRadius: 10,
              }}
            >
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Box display={"flex"} alignItems={"center"}>
                  <DescriptionRoundedIcon
                    color="primary"
                    style={{ marginRight: 5 }}
                  />
                  <Typography
                    style={{ fontWeight: 800, fontSize: 13 }}
                    color={"primary"}
                  >
                    {item.name}
                  </Typography>
                </Box>
                <Box>
                  <ReactToPrint
                    trigger={() => (
                      <IconButton color="primary" style={{ padding: 5 }}>
                        <PrintIcon />
                      </IconButton>
                    )}
                    content={() => stockEntryDoc && componentRef.current}
                    onBeforeGetContent={async () => {
                      setStockEntryDoc(null);
                      await loadStockEntryDoc(item.name);
                    }}
                    onAfterPrint={() => {
                      setStockEntryDoc(null);
                    }}
                  />
                </Box>
              </Box>
            </Box>
          ))}
        </DialogContent>
        <DialogActions>
          <Button variant="text" color="primary" onClick={() => setOpen(false)}>
            {t("common:fermer")}
          </Button>
        </DialogActions>
      </Dialog>

      {/* DOC TO PRINT */}
      <div className={classes.print}>
        {stockEntryDoc && (
          <StockEntry2Print ref={componentRef} doc={stockEntryDoc} />
        )}
      </div>
    </div>
  );
};
