import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import clsx from "clsx";
import { Grid } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useStyles } from "../../../pages/fulfillment/style";
import { StockEntryDetails } from "../../../pages/stockEntry/components/stockEntryDetails";
import { SimpleTable } from "../../../components/simpleTable";
import {
  LoadStockEntryById,
  updateSelectedStockEntry,
} from "../../../store/stockEntries/stockEntries.action";
import { LoadFullfilmentsPacksById } from "../../../store/fullfilment/fullfilment.action";
import { PackingDetails } from "../../../pages/fulfillment/components/packingDetails";
import axiosInstance from "../../../history/axiosInstance";
import CoffreDetails from "../../../components/safeBox/components/CoffreDetails";

export const NotificationDetails = () => {
  const classes = useStyles();
  const history = useHistory();
  const notificationsRetreived = useSelector(
    (state) => state.BroadcastNotification.notifications
  );
  const stockEntry = useSelector(
    (state) => state.StockEntry.selectedStockEntry
  );
  const selectedPacking = useSelector(
    (state) => state.Fullfilment.selectedPacking
  );
  const [title, setTitle] = useState("transaction");
  const [deliverynote, setDeliverynote] = useState(null);
  const { id } = useParams();
  const { t } = useTranslation(["transfert", "common"]);

  const stockEntryDetailsColumns = [
    { key: "item_code", title: "common:ref" },
    { key: "item_name", title: "common:name" },
    { key: "qty", title: "common:qty" },
    { key: "t_warehouse", title: "common:mgsCible" },
    { key: "s_warehouse", title: "common:mgsSource" },
  ];
  const bonLivraisonDetailsColumns = [
    { key: "item_code", title: "common:ref" },
    { key: "item_name", title: "common:name" },
    { key: "qty", title: "common:qty" },
  ];
  const dispatch = useDispatch();

  const initailisationStockReceipt = async () => {
    await dispatch(LoadStockEntryById(id));
  };

  const initailisationFullfilmentPacking = async () => {
    await dispatch(LoadFullfilmentsPacksById(id));
  };

  useEffect(() => {
    const ItemIndex = notificationsRetreived?.findIndex(
      (notif) => notif.url.indexOf(id) !== -1
    );

    if (ItemIndex > -1) {
      switch (notificationsRetreived[ItemIndex]?.doctype) {
        case "Coffre":
          setTitle("coffre");
          break;
        case "Stock Entry":
          initailisationStockReceipt();
          setTitle("stockreceipt");
          break;
        case "Fullfilment Packing":
          initailisationFullfilmentPacking();
          setTitle("bonLivraison");
          break;
        default:
          break;
      }
    }
  }, [notificationsRetreived, id]);

  useEffect(() => {
    if (selectedPacking) {
      const header = {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem("user"))?.token,
      };
      return axiosInstance
        .get(
          `${process.env.REACT_APP_API_URI}/api/details?doctype=Delivery+Note&name=${selectedPacking?.deliverynote_id}`,
          { headers: header }
        )
        .then(({ data }) => {
          setDeliverynote(data?.docs[0]);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [selectedPacking]);

  return (
    <Box className={classes.block}>
      <Box className={classes.titleblock}>
        <span
          onClick={() => {
            dispatch(updateSelectedStockEntry(null));
            history.goBack();
          }}
          className={clsx(classes.closeicon, "icon-close")}
        />
        <Typography
          align={"center"}
          className={classes.title}
          color={"primary"}
          variant="h5"
        >
          {title !== "bonLivraison"
            ? t(`common:${title}`)
            : t(`order:${title}`)}
          {": "}
          {title === "stockreceipt" && stockEntry && stockEntry?.name}
          {title === "bonLivraison" &&
            selectedPacking &&
            selectedPacking?.deliverynote_id}
        </Typography>
      </Box>
      <Grid
        container
        direction="row"
        spacing={2}
        className={classes.container}
        display="flex"
        justifyContent={"space-between"}
        flexWrap="wrap"
      >
        <Grid item xs={12} md={5}>
          {title === "stockreceipt" && (
            <StockEntryDetails details={stockEntry} />
          )}
          {title === "bonLivraison" && (
            <PackingDetails
              selectedPacking={selectedPacking}
              deliverynote={deliverynote}
            />
          )}
          {title === "coffre" && <CoffreDetails id={id} />}
        </Grid>
        <Grid item xs={12} md={7}>
          {title !== "coffre" && (
            <SimpleTable
              title={stockEntry ? "stockEntryDetail" : "bonLivraisonDetail"}
              hasPaper={true}
              data={
                stockEntry
                  ? stockEntry.items
                  : deliverynote
                  ? deliverynote?.items
                  : []
              }
              columns={
                stockEntry
                  ? stockEntryDetailsColumns
                  : bonLivraisonDetailsColumns
              }
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
