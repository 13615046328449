import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { Button, Grid } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { useTranslation } from "react-i18next";
import { SelectButton } from "../Drawer/components/freeButtonSettings/select";
import DeleteSweepIcon from "@material-ui/icons/DeleteSweep";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import LoyaltyOutlinedIcon from "@material-ui/icons/LoyaltyOutlined";
import AssignmentReturnIcon from "@material-ui/icons/AssignmentReturn";
import YoutubeSearchedForIcon from "@material-ui/icons/YoutubeSearchedFor";
import { useSelector } from "react-redux";
import CardMembershipIcon from "@material-ui/icons/CardMembership";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    overflow: "auto",
    height: "100%",
  },
  icon: {
    margin: theme.spacing(1),
    color: theme.palette.freeButton.icon,
  },
  button: {
    width: "100%",
    height: 90,
    backgroundColor: theme.palette.freeButton.background,
    color: theme.palette.freeButton.text,
    border: "1.5px solid",
    fontWeight: "bold",
    fontSize: 16,
    borderColor: theme.palette.blue.main,
    "&:hover": {
      backgroundColor: theme.palette.freeButton.background,
    },
  },
}));
const listbuttons = [
  {
    id: 1,
    title: "common:remise",
    icon: <RemoveCircleIcon />,
  },
  {
    id: 2,
    title: "checkout:couponCode",
    icon: (
      <LoyaltyOutlinedIcon
        style={{ height: 30, width: 30, marginBottom: -5 }}
      />
    ),
  },
  {
    id: 3,
    title: "checkout:CRecent",
    icon: (
      <YoutubeSearchedForIcon
        style={{ height: 30, width: 30, marginBottom: -5 }}
      />
    ),
  },
  {
    id: 4,
    title: "checkout:GRetour",
    icon: (
      <AssignmentReturnIcon
        style={{ height: 30, width: 30, marginBottom: -5 }}
      />
    ),
  },
  {
    id: 5,
    title: "checkout:annulation",
    icon: <DeleteSweepIcon />,
  },
  {
    id: 6,
    title: "checkout:lyty",
    icon: <CardMembershipIcon />,
  },
  {
    id: 7,
    title: "checkout:PricingRule",
    icon: <AccountBalanceWalletIcon />,
  },
];

export const Freebutton = (props) => {
  const { freebuttons, setFreebuttons } = props;

  const themes_db = useSelector((state) => state.Theme.themes_db);
  const activeTheme = useSelector((state) => state.Theme.activeTheme);

  const { t } = useTranslation([
    "interfaceCustomization",
    "common",
    "checkout",
  ]);
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [buttons, setButtons] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [selectedButton, setSelectedButton] = useState(null);
  const [selectedfreebuttons, setselectedfreebuttons] = useState([]);

  useEffect(() => {
    const newArray = [...buttons];
    var Arrayfreebuttons = [...freebuttons];
    JSON.parse(
      themes_db?.find(
        (theme_db) => theme_db?.theme_id === activeTheme?.theme_id
      )?.freebuttons || null
    )?.forEach(async (freebutton) => {
      const indexbt = listbuttons?.findIndex(
        (element) => element?.id === freebutton?.button_id
      );
      if (indexbt > -1 && freebutton?.activated === true) {
        newArray.push(listbuttons[indexbt]);
        Arrayfreebuttons = Arrayfreebuttons.map((button) =>
          button?.button_id === listbuttons[indexbt]?.id
            ? { button_id: button?.button_id, activated: true }
            : button
        );
      }
    });
    setButtons(newArray);
    setselectedfreebuttons(Arrayfreebuttons);
  }, [themes_db]);
  useEffect(() => {
    if (selectedfreebuttons && selectedfreebuttons?.length > 0)
      setFreebuttons(selectedfreebuttons);
  }, [selectedfreebuttons]);
  const handleSelectedButtonTitle = (value) => {
    setSelectedButton({ ...selectedButton, title: value });
  };
  const handleSelectedButtonPosition = (value) => {
    setSelectedButton({ ...selectedButton, position: value });
  };
  const handleSelectedButtonIcon = (value) => {
    setSelectedButton({ ...selectedButton, icon: value });
  };
  /*   const handleAddbutton = () => {
      var button = selectedButton;
      const newArray = [...buttons];
      button.id = buttons.length + 1;
      newArray.splice(button.position, 0, button);
      setButtons(newArray);
      setOpen(false);
    } */
  const handleAddbutton = (newbutton) => {
    const newArray = [...buttons];
    newArray.push(newbutton);
    setButtons(newArray);
    const Arrayfreebuttons = [...selectedfreebuttons];
    setselectedfreebuttons(
      Arrayfreebuttons.map((button) =>
        button.button_id === newbutton.id
          ? { button_id: button?.button_id, activated: true }
          : button
      )
    );
  };
  const handledeletebutton = (buttondeleted) => {
    setButtons(buttons.filter((el) => el?.id !== buttondeleted?.id));
    const Arrayfreebuttons = [...selectedfreebuttons];
    setselectedfreebuttons(
      Arrayfreebuttons.map((button) =>
        button.button_id === buttondeleted.id
          ? { button_id: button?.button_id, activated: false }
          : button
      )
    );
  };
  const handleUpdateFreeButton = (button) => {
    setSelectedButton(button);
    setIsUpdate(true);
    setOpen(true);
  };

  const handleUpdatebutton = () => {
    const objIndex = buttons.findIndex((obj) => obj.id == 1);
    const newArray = [...buttons];
    newArray[objIndex] = selectedButton;
    setButtons(newArray);
    setOpen(false);
  };

  const handleOpen = () => {
    setIsUpdate(false);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div style={{ height: "100%" }}>
      <div className={clsx("freebutton", classes.root)}>
        <Grid container spacing={1}>
          <Button
            onClick={handleOpen}
            className={classes.button}
            variant="contained"
            color="primary"
          >
            <AddIcon />
          </Button>
          {buttons.map((button) => (
            <Grid key={button?.id} item xs={6}>
              <Button
                onClick={() => handledeletebutton(button)}
                className={classes.button}
                variant="contained"
                color="primary"
              >
                <Grid item>{button?.icon}</Grid>
                <Grid item>
                  <span>{t(button?.title)}</span>
                </Grid>
              </Button>
            </Grid>
          ))}
        </Grid>
      </div>
      <SelectButton
        buttons={listbuttons}
        selected={buttons}
        handleAddbutton={handleAddbutton}
        handledeletebutton={handledeletebutton}
        open={open}
        handleClose={handleClose}
      />
    </div>
  );
};
