import moment from "moment";
import "moment/locale/fr";

export const formatColumnsHeaders = (str, tag) => {
  switch (str) {
    case "name":
      return tag ? tag : "columns:name";
    case "creation":
      return "columns:creationDate";
    case "country":
      return "columns:country";
    case "parent_company":
      return "columns:parentCompany";
    case "monthly_sales_target":
      return "columns:monthlySalesTarget";
    case "total_monthly_sales":
      return "columns:totalMonthlySales";
    case "credit_limit":
      return "columns:creditLimit";
    case "company_logo":
      return "columns:companyLogo";
    case "default_currency":
      return "columns:defaultCurrency";
    case "modified":
      return "columns:modified";
    case "item_name":
      return "columns:descriptiveName";
    case "item_code":
      return "columns:productCode";
    case "item_group":
      return "columns:category";
    case "item_collection":
      return "columns:collection";
    case "has_variants":
      return "columns:hasVariants";
    case "variant_of":
      return "columns:variantOf";
    case "disabled":
      return "columns:status";
    case "item_group_name":
      return "columns:categoryName";
    case "parent_item_group":
      return "columns:parentCategory";
    case "modified_by":
      return "columns:modifiedBy";
    case "attribute_name":
      return "columns:typeName";
    case "role_profile":
      return "columns:roleProfile";
    case "user_type":
      return "columns:userType";
    case "enabled":
      return "columns:status";
    case "full_name":
      return "columns:fullName";
    case "is_group":
      return "columns:band";
    case "company":
      return "columns:company";
    case "city":
      return "columns:city";
    case "warehouse_name":
      return "columns:warehouseName";
    case "Submitted":
      return "columns:submitted";
    case "Stoppé":
      return "columns:stopped";
    case "Closed":
      return "columns:closed";
    case "owner":
      return "Owner";
    case "period_start_date":
      return "columns:periodStartDate";
    case "grand_total":
      return "columns:totalPrice";
    case "pos_invoice":
      return "columns:reference";
    case "mode_of_payment":
      return "columns:paymentMethods";
    case "product_category":
      return "columns:productCategory";
    case "is_principal":
      return "columns:principal";
    case "pos_profile":
      return "columns:cashierProfile";
    case "discount_value":
      return "columns:discountPercentage";
    case "collection_name":
      return "columns:collection";
    case "message_for_supplier":
      return "columns:messageForSupplier";
    case "naming_series":
      return "columns:namingSeries";
    case "transaction_date":
      return "columns:transactionDate";
    case "warehouse":
      return "columns:warehouse";
    case "customer_name":
      return "columns:client";
    case "base_grand_total":
      return "columns:totalAmount";
    case "currency":
      return "columns:currency";
    case "title":
      return "columns:title";
    case "apply_on":
      return "columns:applyOn";
    case "coupon_type":
      return "columns:couponType";
    case "valid_from":
      return "columns:validFrom";
    case "valid_upto":
      return "columns:validupto";
    case "priority":
      return "columns:priority";
    case "status":
      return "columns:status";
    case "territory":
      return "columns:territory";
    case "customer_group":
      return "columns:customerGroup";
    case "customer_type":
      return "columns:customerType";
    case "mobile_no":
      return "columns:mobileNo";
    case "loyalty_program":
      return "columns:loyaltyProgram";
    case "customer":
      return "columns:client";
    case "supplier_name":
      return "columns:supplierName";
    case "schedule_date":
      return "columns:scheduleDate";
    case "supplier_group":
      return "columns:supplierGroup";
    case "supplier_primary_contact":
      return "columns:supplierPrimaryContact";
    case "supplier_type":
      return "columns:supplierType";
    case "posting_date":
      return "columns:postingDate";
    case "total_taxes_and_charges":
      return "columns:totalTaxes";
    case "avg._selling_rate":
      return "dashboard:asr";
    case "valuation_rate":
      return "dashboard:vr";
    case "selling_amount":
      return "dashboard:sa";
    case "buying_amount":
      return "dashboard:ba";
    case "gross_profit":
      return "dashboard:gp";
    case "gross_profit_%":
      return "dashboard:gpp";
    case "project":
      return "dashboard:project";
    case "brand":
      return "dashboard:brand";
    case "owner":
      return "dashboard:user";
    case "payment_mode":
      return "dashboard:pm";
    case "sales_and_returns":
      return "dashboard:sar";
    case "taxes":
      return "dashboard:taxes";
    case "payments":
      return "dashboard:payments";
    case "entity":
      return "dashboard:ent";
    case "entity_name":
      return "dashboard:entname";
    case "lead_time_days":
      return "columns:leadTimeDays";
    case "in_qty":
      return "dashboard:inqty";
    case "out_qty":
      return "dashboard:outqty";
    case "stock_uom":
      return "Stock UOM";
    case "bal_qty":
      return "dashboard:balqty";
    case "bal_val":
      return "dashboard:balval";
    case "opening_qty":
      return "dashboard:openingqty";
    case "opening_val":
      return "dashboard:openingval";
    case "in_val":
      return "dashboard:inval";
    case "out_val":
      return "dashboard:outval";
    case "val_rate":
      return "dashboard:vr";
    case "reorder_level":
      return "dashboard:reorderlevel";
    case "reorder_qty":
      return "dashboard:reorderqty";
    case "average_qty":
      return "dashboard:averageqty";
    case "delivered_qty":
      return "dashboard:delivered_qty";
    case "pending_qty":
      return "dashboard:pending_qty";
    case "qty_to_bill":
      return "dashboard:qty_to_bill";
    case "amount":
      return "dashboard:amount";
    case "billed_amount":
      return "dashboard:billed_amount";
    case "billed_qty":
      return "dashboard:billed_qty";
    case "pending_amount":
      return "dashboard:pending_amount";
    case "delivered_qty_amount":
      return "dashboard:delivered_qty_amount";
    case "delivery_date":
      return "dashboard:delivery_date";
    case "delay":
      return "dashboard:delay";
    case "time_taken_to_deliver":
      return "dashboard:time_taken_to_deliver";
    default:
      return str;
  }
};

export const formatColumnsString = (str) => {
  // Remove underscores
  str = str.replace(/_/g, " ");

  // Capitalize  letter
  const words = str.split(" ");
  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word?.slice(1)
  );
  str = capitalizedWords.join(" ");

  return str;
};

export const formatDate = (date, format) => {
  return moment(date).locale("fr").format(format);
};

export const formatData = (data) => {
  let arr = [...data];
  arr.forEach(function (obj) {
    const keyCreationExists = Object.keys(obj).includes("creation");
    const keyModifiedExists = Object.keys(obj).includes("modified");
    const keyEnableExists = Object.keys(obj).includes("enabled");
    const keyDisableExists = Object.keys(obj).includes("disabled");
    const keyStatusExists = Object.keys(obj).includes("status");
    const keyIsPrincipal = Object.keys(obj).includes("is_principal");
    const keyIsGroup = Object.keys(obj).includes("is_group");

    if (keyCreationExists) {
      obj.creation = formatDate(obj.creation, "YYYY-MM-DD");
    }
    if (keyModifiedExists) {
      obj.modified = formatDate(obj.modified, "YYYY-MM-DD");
    }
    if (keyEnableExists) {
      obj.enabled = obj["enabled"] == 1 ? "Activé" : "Désactivé";
    }
    if (keyDisableExists) {
      obj.disabled = obj["disabled"] == 1 ? "Désactivé" : "Activé";
    }
    if (keyStatusExists) {
      //    obj.status = obj['status'] == 'Closed' ? 'Fermée' : 'Ouvert';
    }
    if (keyIsPrincipal) {
      obj.is_principal = obj["is_principal"] == 1 ? "Oui" : "Non";
    }
    if (keyIsGroup) {
      obj.is_group = obj["is_group"] == 1 ? "Oui" : "Non";
    }
  });
  return arr;
};

export const GlobalfilterData = (array, keyword, keys) => {
  const lowercasedValue = keyword.toLowerCase().trim();
  if (lowercasedValue === "") return array;
  else {
    const filteredData = array.filter((item) => {
      return Object.keys(item).some((key) => {
        if (item[key] !== null) {
          return keys.includes(key)
            ? item[key]
                .toString()
                .toLowerCase()
                .match(new RegExp(lowercasedValue, "g"))
            : false;
        } else {
          return false;
        }
      });
    });
    return filteredData;
  }
};

export const FilterDataTree = (array, keyword, key) => {
  return searchtree(array, keyword);
};

const searchtree = (items, term) => {
  return items.reduce((acc, item) => {
    if (contains(item.name, term)) {
      acc.push(item);
    } else if (
      getChildren(items, item.name) &&
      getChildren(items, item.name).length > 0
    ) {
      let newItems = searchtree(getChildren(items, item.name), term);
      if (newItems && newItems.length > 0) {
        acc.push(item);
      }
    }
    return acc;
  }, []);
};

const contains = (text, term) => {
  return text.toLowerCase().indexOf(term.toLowerCase()) >= 0;
};
const getChildren = (data, parentName) => {
  return data.filter((el) => el.parent_item_group == parentName);
};
