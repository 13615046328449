import {
  CREATE_CLIENT,
  CREATE_CLIENT_SUCCESS,
  LOAD_FILTRED_CLIENTS,
  CLIENTS_LOADED,
  CLIENT_CREATED_BRODCAST,
  LOYALTY_PROGRAMS_LOADED,
  LOADING_PAYMENT_ENTRIES,
  PAYMENT_ENTRIES_LOADED,
  LOADING_RETURNS,
  RETURNS_LOADED,
  UNPAID_SALES_INVOICE_LOADED,
  LOADING_UNPAID_SALES_INVOICE,
  SELECTED_CLIENT_LOADED,
  ORDERS_LOADED,
  LOADING_ORDERS,
  LOYALTY_POINTS_LOADED,
  LOADING_LOYALTY_POINTS,
  CURRENCIES_LOADED,
  LOADING_ACCOUNT_BY_CURRENCY,
  ACCOUNT_BY_CURRENCY_LOADED,
  CUSTOMERS_GROUP_LOADED,
  CREATE_ADDRESS_CUSTOMER,
  PAYMENT_ENTRY_CANCELED,
  CREATE_ADDRESS_CUSTOMER_SUCCESS,
  CREATE_ADVANCE_PAYMENT,
  ADVANCE_PAYMENT_CREATED,
  CANCEL_PAYMENT_ENTRY,
  INVOICES_LOADED,
  SET_ORDER,
  CANCEL_ORDER,
  PERSONNAL_MSG_TOGGLED,
  SEND_GIFT_CARD_EMAIL,
  CREATE_GIFT_CARD_EMAIL_SUCCESS,
  SET_INVOICE_DETAIL,
  CREDIT_NOTE_LODED,
  CLIENT_COUPON_CODE_LOADED,
  LOADING_CLIENT_COUPON_CODE,
  SET_CLIENT_PRICING_RULE,
  COUPON_CODES_LOADED,
  LOADING_COUPON_CODES,
  SEC_SET_DETAIL_CLIENT,
  COUPON_CODES_LIST_LOADED,
  UPDATE_CARD,
  UPDATE_CARD_SUCCESS,
  CUSTOMER_LEDGER_LOADED,
  LOADING_CUSTOMER_LEDGER,
  CLIENT_AVOIR_ACCOUNT_LOADED,
  INITIALISATION_DETAILS_CLIENT,
} from "./client.type";
import { FAILED_ADD_DATA } from "../rollback/rollback.type";
import { useSelector } from "react-redux";
import axiosInstance from "../../history/axiosInstance";

export const CreateClient = (content) => {
  const action = {
    type: CREATE_CLIENT,
    payload: content,
    meta: {
      offline: {
        // the network action to execute:
        effect: {
          url: `${process.env.REACT_APP_API_URI}/api/customers/create`,
          method: "POST",
          body: JSON.stringify({
            doc: content,
          }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
            Authorization: JSON.parse(localStorage.getItem("user"))?.token,
          },
        },
        // action to dispatch when effect succeeds:
        commit: {
          type: CREATE_CLIENT_SUCCESS,
          meta: {
            notification: {
              code: "success",
              message: "notification:customerAdded",
            },
          },
        },
        rollback: { type: FAILED_ADD_DATA },
      },
    },
  };
  action.meta.offline.rollback.meta = action;
  return (dispatch, getState) => {
    dispatch(action);
  };
};

export const CreateGuestClient = (content) => {
  const action = {
    type: CREATE_CLIENT,
    payload: content,
    meta: {
      offline: {
        // the network action to execute:
        effect: {
          url: `${process.env.REACT_APP_API_URI}/api/guest/create`,
          method: "POST",
          body: JSON.stringify({
            doc: content,
          }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
          },
        },
        // action to dispatch when effect succeeds:
        commit: {
          type: CREATE_CLIENT_SUCCESS,
          meta: {
            notification: {
              code: "success",
              message: "notification:customerAdded",
            },
          },
        },
        rollback: { type: FAILED_ADD_DATA },
      },
    },
  };
  action.meta.offline.rollback.meta = action;
  return (dispatch, getState) => {
    dispatch(action);
  };
};

export const LoadClients = (clients) => {
  return {
    type: CLIENTS_LOADED,
    clients: clients,
  };
};

export const GetFiltredClients = (key) => {
  return {
    type: LOAD_FILTRED_CLIENTS,
    search: key,
  };
};

export const ClientCreatedBrodcast = (client) => {
  return {
    type: CLIENT_CREATED_BRODCAST,
    client: client,
  };
};

export const LoadLoyaltyPrograms = (loyaltyPrograms) => {
  return {
    type: LOYALTY_PROGRAMS_LOADED,
    loyaltyPrograms: loyaltyPrograms,
  };
};

export const loadClientPaymentEntries = (content, token) => ({
  type: LOADING_PAYMENT_ENTRIES,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${process.env.REACT_APP_API_URI}/api/customers/payment-entry-list`,
        method: "post",
        body: JSON.stringify({
          doctype: "Sales Order",
          fields: [
            "`tabSales Order`.`name`",
            "`tabSales Order`.`creation`",
            "`tabSales Order`.`status`",
            "`tabSales Order`.`grand_total`",
            "`tabSales Order`.`customer_name`",
            "`tabSales Order`.`currency`",
            "`tabSales Order`.`delivery_date`",
            "`tabSales Order`.`transaction_date`",
            "`tabSales Order`.`total_qty`",
            "`tabSales Order`.`total_taxes_and_charges`",
          ],
          filters: [
            ["Sales Order", "customer_name", "=", content?.customer_name],
          ],
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: JSON.parse(localStorage.getItem("user")).token,
        },
      },
      // action to dispatch when effect succeeds:
      commit: { type: PAYMENT_ENTRIES_LOADED, meta: "" },
      // action to dispatch if network action fails permanently:
    },
  },
});

export const loadReturnseByClient = (client, company) => ({
  type: LOADING_RETURNS,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${process.env.REACT_APP_API_URI}/api/customers/returns-list`,
        method: "post",
        body: JSON.stringify({
          doctype: "Sales Invoice",
          fields: [
            "`tabSales Invoice`.`name`",
            "`tabSales Invoice`.`owner`",
            "`tabSales Invoice`.`creation`",
            "`tabSales Invoice`.`modified`",
            "`tabSales Invoice`.`modified_by`",
            "`tabSales Invoice`.`_user_tags`",
            "`tabSales Invoice`.`_comments`",
            "`tabSales Invoice`.`_assign`",
            "`tabSales Invoice`.`_liked_by`",
            "`tabSales Invoice`.`docstatus`",
            "`tabSales Invoice`.`idx`",
            "`tabSales Invoice`.`total_billing_amount`",
            "`tabSales Invoice`.`total`",
            "`tabSales Invoice`.`net_total`",
            "`tabSales Invoice`.`total_taxes_and_charges`",
            "`tabSales Invoice`.`discount_amount`",
            "`tabSales Invoice`.`grand_total`",
            "`tabSales Invoice`.`rounding_adjustment`",
            "`tabSales Invoice`.`rounded_total`",
            "`tabSales Invoice`.`total_advance`",
            "`tabSales Invoice`.`outstanding_amount`",
            "`tabSales Invoice`.`paid_amount`",
            "`tabSales Invoice`.`change_amount`",
            "`tabSales Invoice`.`write_off_amount`",
            "`tabSales Invoice`.`status`",
            "`tabSales Invoice`.`title`",
            "`tabSales Invoice`.`customer`",
            "`tabSales Invoice`.`customer_name`",
            "`tabSales Invoice`.`base_grand_total`",
            "`tabSales Invoice`.`due_date`",
            "`tabSales Invoice`.`company`",
            "`tabSales Invoice`.`currency`",
            "`tabSales Invoice`.`is_return`",
            "`tabSales Invoice`.`_seen`",
            "`tabSales Invoice`.`party_account_currency`",
          ],
          filters: [
            ["Sales Invoice", "customer", "=", client.customer_name],
            ["Sales Invoice", "company", "=", `${company}`],
            ["Sales Invoice", "status", "=", "Return"],
          ],
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: JSON.parse(localStorage.getItem("user")).token,
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        },
      },
      // action to dispatch when effect succeeds:
      commit: { type: RETURNS_LOADED, meta: "" },
      // action to dispatch if network action fails permanently:
    },
  },
});

export const loadLedgerByCustomer = (client, date, company) => ({
  type: LOADING_CUSTOMER_LEDGER,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${process.env.REACT_APP_API_URI}/api/inventory`,
        method: "post",
        body: JSON.stringify({
          report_name: "Customer Ledger Summary",
          filters: {
            company: `${company}`,
            from_date: date.from_date,
            to_date: date.to_date,
            party: client.customer_name,
            customer_name: client.customer_name,
          },
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: JSON.parse(localStorage.getItem("user")).token,
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        },
      },
      // action to dispatch when effect succeeds:
      commit: { type: CUSTOMER_LEDGER_LOADED, meta: "" },
      // action to dispatch if network action fails permanently:
    },
  },
});

export const setLedgerByCustomer = (payload) => {
  return {
    type: CUSTOMER_LEDGER_LOADED,
    payload: payload,
  };
};

export const loadOutstandingByClient = (client, company, token) => ({
  type: LOADING_UNPAID_SALES_INVOICE,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${process.env.REACT_APP_API_URI}/api/customers/unpaid-sales-invoice`,
        method: "post",
        body: JSON.stringify({
          doctype: "Sales Invoice",
          fields: ["`tabSales Invoice`.`outstanding_amount`"],
          filters: [
            ["Sales Invoice", "customer", "=", client.customer_name],
            ["Sales Invoice", "company", "=", `${company}`],
            ["Sales Invoice", "status", "=", "Unpaid"],
          ],
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token
            ? token
            : JSON.parse(localStorage.getItem("user")).token,
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        },
      },
      // action to dispatch when effect succeeds:
      commit: { type: UNPAID_SALES_INVOICE_LOADED, meta: "" },
      // action to dispatch if network action fails permanently:
    },
  },
});

export const LoadSelectedClient = (client) => {
  return {
    type: SELECTED_CLIENT_LOADED,
    client: client,
  };
};

export const loadOrdersByClient = (client, company, token) => ({
  type: LOADING_ORDERS,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${process.env.REACT_APP_API_URI}/api/customers/sales-orders`,
        method: "post",
        body: JSON.stringify({
          doctype: "POS Invoice",
          fields: [
            "`tabPOS Invoice`.`name`",
            "`tabPOS Invoice`.`owner`",
            "`tabPOS Invoice`.`creation`",
            "`tabPOS Invoice`.`docstatus`",
            "`tabPOS Invoice`.`idx`",
            "`tabPOS Invoice`.`total`",
            "`tabPOS Invoice`.`net_total`",
            "`tabPOS Invoice`.`total_taxes_and_charges`",
            "`tabPOS Invoice`.`discount_amount`",
            "`tabPOS Invoice`.`grand_total`",
            "`tabPOS Invoice`.`status`",
            "`tabPOS Invoice`.`customer_name`",
            "`tabPOS Invoice`.`currency`",
            "`tabPOS Invoice`.`party_account_currency`",
          ],
          filters: [
            ["POS Invoice", "customer", "=", client.customer_name],
            ["POS Invoice", "company", "=", `${company}`],
          ],
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token
            ? token
            : JSON.parse(localStorage.getItem("user")).token,
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        },
      },
      // action to dispatch when effect succeeds:
      commit: { type: ORDERS_LOADED, meta: "" },
      // action to dispatch if network action fails permanently:
    },
  },
});

export const LoadOrderByName = (name, doctype) => {
  return (dispatch) => {
    const header = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem("user")).token,
      },
    };
    return axiosInstance
      .get(
        `${process.env.REACT_APP_API_URI}/api/details?doctype=${doctype}&name=${name}`,
        header
      )
      .then(({ data }) => {
        dispatch(SetOrder(data?.docs?.[0]));
      });
  };
};

export const SetOrder = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_ORDER,
      order: data,
    });
  };
};

export function CancelOrder() {
  return (dispatch, getState) => {
    dispatch({
      type: CANCEL_ORDER,
    });
  };
}

export const loadPOSInvoicesByClient = (client, company) => ({
  type: LOADING_ORDERS,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${process.env.REACT_APP_API_URI}/api/customers/invoices`,
        method: "post",
        body: JSON.stringify({
          doctype: "POS Invoice",
          fields: [
            "`tabPOS Invoice`.`name`",
            "`tabPOS Invoice`.`owner`",
            "`tabPOS Invoice`.`total`",
            "`tabPOS Invoice`.`net_total`",
            "`tabPOS Invoice`.`total_taxes_and_charges`",
            "`tabPOS Invoice`.`discount_amount`",
            "`tabPOS Invoice`.`grand_total`",
            "`tabPOS Invoice`.`rounding_adjustment`",
            "`tabPOS Invoice`.`rounded_total`",
            "`tabPOS Invoice`.`total_advance`",
            "`tabPOS Invoice`.`outstanding_amount`",
            "`tabPOS Invoice`.`paid_amount`",
            "`tabPOS Invoice`.`change_amount`",
            "`tabPOS Invoice`.`write_off_amount`",
            "`tabPOS Invoice`.`status`",
            "`tabPOS Invoice`.`title`",
            "`tabPOS Invoice`.`customer`",
            "`tabPOS Invoice`.`customer_name`",
            "`tabPOS Invoice`.`base_grand_total`",
            "`tabPOS Invoice`.`due_date`",
            "`tabPOS Invoice`.`company`",
            "`tabPOS Invoice`.`currency`",
            "`tabPOS Invoice`.`is_return`",
            "`tabPOS Invoice`.`party_account_currency`",
            "`tabPOS Invoice`.`posting_date`",
          ],
          filters: [
            ["POS Invoice", "customer", "=", client.customer_name],
            ["POS Invoice", "company", "=", `${company}`],
          ],
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: JSON.parse(localStorage.getItem("user")).token,
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        },
      },
      // action to dispatch when effect succeeds:
      commit: { type: INVOICES_LOADED, meta: "" },
      // action to dispatch if network action fails permanently:
    },
  },
});

export const LoadPOSInvoiceByName = (name) => {
  return (dispatch) => {
    const header = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem("user")).token,
      },
    };
    return axiosInstance
      .get(
        `${process.env.REACT_APP_API_URI}/api/details?doctype=POS%20Invoice&name=${name}`,
        header
      )
      .then(({ data }) => {
        dispatch(SetInvoice(data?.docs[0]));
      });
  };
};

export const SetInvoice = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_INVOICE_DETAIL,
      detailInvoice: data,
    });
  };
};

export const loadLoyaltyPointsByClient = (client, token) => ({
  type: LOADING_LOYALTY_POINTS,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${process.env.REACT_APP_API_URI}/api/customers/loyalty-points`,
        method: "post",
        body: JSON.stringify({
          doctype: "Loyalty Point Entry",
          fields: [
            "`tabLoyalty Point Entry`.`name`",
            "`tabLoyalty Point Entry`.`owner`",
            "`tabLoyalty Point Entry`.`creation`",
            "`tabLoyalty Point Entry`.`modified`",
            "`tabLoyalty Point Entry`.`modified_by`",
            "`tabLoyalty Point Entry`.`_user_tags`",
            "`tabLoyalty Point Entry`.`_comments`",
            "`tabLoyalty Point Entry`.`_assign`",
            "`tabLoyalty Point Entry`.`_liked_by`",
            "`tabLoyalty Point Entry`.`docstatus`",
            "`tabLoyalty Point Entry`.`idx`",
            "`tabLoyalty Point Entry`.`customer`",
            "`tabLoyalty Point Entry`.`invoice`",
            "`tabLoyalty Point Entry`.`loyalty_points`",
            "`tabLoyalty Point Entry`.`expiry_date`",
            "`tabLoyalty Point Entry`.`loyalty_program`",
            "`tabLoyalty Point Entry`.`loyalty_program_tier`",
            "`tabLoyalty Point Entry`.`company`",
            "`tabLoyalty Point Entry`.`posting_date`",
            "`tabLoyalty Point Entry`.`purchase_amount`",
          ],
          filters: [
            ["Loyalty Point Entry", "customer", "=", client?.customer_name],
          ],
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token
            ? token
            : JSON.parse(localStorage.getItem("user")).token,
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        },
      },
      // action to dispatch when effect succeeds:
      commit: { type: LOYALTY_POINTS_LOADED, meta: "" },
      // action to dispatch if network action fails permanently:
    },
  },
});

export const LoadCustomersGroup = (customersgroup) => {
  return {
    type: CUSTOMERS_GROUP_LOADED,
    customersgroup: customersgroup,
  };
};

export const CreateAddressCustomer = (content) => {
  const action = {
    type: CREATE_ADDRESS_CUSTOMER,
    payload: content,
    meta: {
      offline: {
        // the network action to execute:
        effect: {
          url: `${process.env.REACT_APP_API_URI}/api/address/create`,
          method: "POST",
          body: JSON.stringify({
            action: "Save",
            doc: {
              doctype: "Address",
              docstatus: 0,
              __unsaved: 1,
              __islocal: 1,
              is_primary_address: 0,
              name: content.name,
              address_type: content.address_type,
              address_title: content.address_title,
              address_line1: content.address_line1,
              city: content.city,
              country: content.country,
              email_id: content.email_id,
              phone: content.phone,
              links: [
                {
                  link_doctype: "Customer",
                  owner: "cashier@email.com",
                  link_name: content.link_name,
                  parent: content.parent,
                  parentfield: "links",
                  parenttype: "Address",
                  doctype: "Dynamic Link",
                  docstatus: 0,
                  idx: 1,
                },
              ],
            },
          }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
            Authorization: JSON.parse(localStorage.getItem("user")).token,
          },
        },
        // action to dispatch when effect succeeds:
        commit: {
          type: CREATE_ADDRESS_CUSTOMER_SUCCESS,
          meta: {
            notification: {
              code: "success",
              message: "notification:addressAdded",
            },
          },
        },
        rollback: { type: FAILED_ADD_DATA },
      },
    },
  };
  action.meta.offline.rollback.meta = action;
  return (dispatch, getState) => {
    dispatch(action);
  };
};

export const LoadCurrencies = (currencies) => {
  return {
    type: CURRENCIES_LOADED,
    currencies: currencies,
  };
};

export const getAccountByCurrency = (company, currency) => ({
  type: LOADING_ACCOUNT_BY_CURRENCY,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${process.env.REACT_APP_API_URI}/api/currency-account`,
        method: "post",
        body: JSON.stringify({
          doctype: "Account",
          fields: ["`tabAccount`.`name`", "`tabAccount`.`account_name`"],
          filters: [
            ["Account", "account_currency", "=", currency],
            ["Account", "company", "=", `${company}`],
            ["Account", "account_type", "=", "Receivable"],
          ],
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: JSON.parse(localStorage.getItem("user")).token,
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        },
      },
      // action to dispatch when effect succeeds:
      commit: { type: ACCOUNT_BY_CURRENCY_LOADED, meta: "" },
      // action to dispatch if network action fails permanently:
      rollback: { type: FAILED_ADD_DATA },
    },
  },
});

export const CreateAdvancePayment = (
  content,
  company,
  default_recievable_account,
  default_currency,
  default_cash_account
) => ({
  type: CREATE_ADVANCE_PAYMENT,
  payload: content,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${process.env.REACT_APP_API_URI}/api/document/create-advance-payment`,
        method: "POST",
        body: JSON.stringify({
          action: "Submit",
          doc: {
            docstatus: 0,
            doctype: "Payment Entry",
            payment_type: "Receive",
            company: `${company}`,
            party_type: "Customer",
            party: content.customer,
            paid_from: `${default_recievable_account}`,
            paid_to: `${default_cash_account}`,
            party_name: content.customer,
            mode_of_payment: "Cash",
            paid_amount: content.amount,
            received_amount: content.amount,
            unallocated_amount: null,
            source_exchange_rate: 1,
            target_exchange_rate: 1,
            paid_from_account_currency: `${default_currency}`,
            paid_to_account_currency: `${default_currency}`,
          },
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
          Authorization: JSON.parse(localStorage.getItem("user")).token,
        },
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: ADVANCE_PAYMENT_CREATED,
        meta: {
          notification: {
            code: "success",
            message: "notification:creditAvoirAdded",
          },
        },
      },
      rollback: { type: FAILED_ADD_DATA },
    },
  },
});

export const cancelPaymentEntry = (content) => ({
  type: CANCEL_PAYMENT_ENTRY,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${process.env.REACT_APP_API_URI}/api/payment/cancel-payment-entry`,
        method: "post",
        body: JSON.stringify({
          doctype: "Avoir Account",
          action: "cancel",
          docnames: content,
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: JSON.parse(localStorage.getItem("user")).token,
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        },
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: PAYMENT_ENTRY_CANCELED,
        meta: "",
      },
      // action to dispatch if network action fails permanently:
      rollback: { type: FAILED_ADD_DATA },
    },
  },
});

export const togglePersonnalMsg = (value) => {
  return {
    type: PERSONNAL_MSG_TOGGLED,
    showPersonnalMsg: value,
  };
};

export const SendGiftCardEmail = (customer_email, content) => ({
  type: SEND_GIFT_CARD_EMAIL,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${process.env.REACT_APP_API_URI}/api/email/gift-card`,
        method: "POST",
        body: JSON.stringify({
          customer_email: customer_email,
          content: content,
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
          Authorization: JSON.parse(localStorage.getItem("user")).token,
        },
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: CREATE_GIFT_CARD_EMAIL_SUCCESS,
        meta: {
          notification: {
            code: "success",
            message: "notification:giftsSent",
          },
        },
      },
    },
  },
});

export const SendMultipleEmail = (content) => ({
  type: SEND_GIFT_CARD_EMAIL,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${process.env.REACT_APP_API_URI}/api/email/send-multiple-email`,
        method: "POST",
        body: JSON.stringify(content),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
          Authorization: JSON.parse(localStorage.getItem("user")).token,
        },
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: CREATE_GIFT_CARD_EMAIL_SUCCESS,
        meta: {
          notification: {
            code: "success",
            message: "notification:giftSent",
          },
        },
      },
    },
  },
});

export const loadClientAvoirAccount = (content) => ({
  type: LOADING_CLIENT_COUPON_CODE,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${process.env.REACT_APP_API_URI}/api/avoir-account-list`,
        method: "post",
        body: JSON.stringify({
          doctype: "Avoir Account",
          fields: [
            "`tabAvoir Account`.`name`",
            "`tabAvoir Account`.`credit_amount`",
            "`tabAvoir Account`.`naming_series`",
            "`tabAvoir Account`.`creation_date`",
            "`tabAvoir Account`.`customer`",
            "`tabAvoir Account`.`docstatus`",
          ],
          filters: [["Avoir Account", "customer", "=", content.customer_name]],
          order_by: "`tabAvoir Account`.`modified` desc",
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: JSON.parse(localStorage.getItem("user")).token,
        },
      },
      // action to dispatch when effect succeeds:
      commit: { type: CLIENT_AVOIR_ACCOUNT_LOADED, meta: "" },
      // action to dispatch if network action fails permanently:
    },
  },
});

export const loadClientCouponCode = (content) => ({
  type: LOADING_CLIENT_COUPON_CODE,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${process.env.REACT_APP_API_URI}/api/coupon-codes-list`,
        method: "post",
        body: JSON.stringify({
          doctype: "Coupon Code",
          fields: [
            "`tabCoupon Code`.`name`",
            "`tabCoupon Code`.`docstatus`",
            "`tabCoupon Code`.`idx`",
            "`tabCoupon Code`.`coupon_type`",
            "`tabCoupon Code`.`valid_from`",
            "`tabCoupon Code`.`coupon_name`",
            "`tabCoupon Code`.`coupon_code`",
            "`tabCoupon Code`.`pricing_rule`",
            "`tabCoupon Code`.`customer`",
            "`tabCoupon Code`.`description`",
            "`tabCoupon Code`.`pos_invoice`",
            "`tabCoupon Code`.`validity_period`",
            "`tabCoupon Code`.`used`",
            "`tabCoupon Code`.`valid_upto`",
          ],
          filters: [["Coupon Code", "customer", "=", content.customer_name]],
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: JSON.parse(localStorage.getItem("user")).token,
        },
      },
      // action to dispatch when effect succeeds:
      commit: { type: CLIENT_COUPON_CODE_LOADED, meta: "" },
      // action to dispatch if network action fails permanently:
    },
  },
});

export const LoadPricingRuleByName = (name) => {
  return (dispatch) => {
    const header = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem("user")).token,
      },
    };
    return axiosInstance
      .get(
        `${process.env.REACT_APP_API_URI}/api/details?doctype=Pricing%20Rule&name=${name}`,
        header
      )
      .then(({ data }) => {
        dispatch(SetPricingRulesClient(data.docs[0]));
      });
  };
};
export const SetPricingRulesClient = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_CLIENT_PRICING_RULE,
      pricingRuleClient: data,
    });
  };
};

export const loadCouponCodes = () => ({
  type: LOADING_COUPON_CODES,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${process.env.REACT_APP_API_URI}/api/coupon-codes-list`,
        method: "post",
        body: JSON.stringify({
          doctype: "Coupon Code",
          fields: [
            "`tabCoupon Code`.`name`",
            "`tabCoupon Code`.`docstatus`",
            "`tabCoupon Code`.`idx`",
            "`tabCoupon Code`.`coupon_type`",
            "`tabCoupon Code`.`valid_from`",
            "`tabCoupon Code`.`coupon_name`",
            "`tabCoupon Code`.`coupon_code`",
            "`tabCoupon Code`.`pricing_rule`",
            "`tabCoupon Code`.`customer`",
            "`tabCoupon Code`.`description`",
            "`tabCoupon Code`.`pos_invoice`",
            "`tabCoupon Code`.`validity_period`",
            "`tabCoupon Code`.`used`",
            "`tabCoupon Code`.`valid_upto`",
          ],
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: JSON.parse(localStorage.getItem("user")).token,
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        },
      },
      // action to dispatch when effect succeeds:
      commit: { type: COUPON_CODES_LIST_LOADED, meta: "" },
      // action to dispatch if network action fails permanently:
    },
  },
});

export const LoadCouponCode = (giftCardCouponCode) => {
  return {
    type: COUPON_CODES_LOADED,
    giftCardCouponCode: giftCardCouponCode,
  };
};

export const updateClientPricingRule = (content) => {
  const action = {
    type: UPDATE_CARD,
    payload: content,
    meta: {
      offline: {
        // the network action to execute:
        effect: {
          url: `${process.env.REACT_APP_API_URI}/api/${content.api}`,
          method: "POST",
          body: JSON.stringify({
            doctype: content.doctype,
            action: "update",
            docnames: [content.name],
            data: {
              discount_amount: content.discount_amount,
              ...content,
            },
          }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
            Authorization: JSON.parse(localStorage.getItem("user")).token,
          },
        },
        // action to dispatch when effect succeeds:
        commit: {
          type: UPDATE_CARD_SUCCESS,
          meta: {
            notification: {
              code: "success",
              message: "notification:amountUpdated",
            },
          },
        },
        rollback: { type: FAILED_ADD_DATA },
      },
    },
  };
  action.meta.offline.rollback.meta = action;
  return (dispatch, getState) => {
    dispatch(action);
  };
};

export const SecondLoadDetailClient = (name) => {
  return (dispatch) => {
    const header = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem("user")).token,
      },
    };
    return axiosInstance
      .get(
        `${process.env.REACT_APP_API_URI}/api/details?doctype=Customer&name=${name}`,
        header
      )
      .then(({ data }) => {
        dispatch(SecondSetDetailClient(data?.docs?.[0]));
      });
  };
};

export const SecondSetDetailClient = (data) => {
  return (dispatch) => {
    dispatch({
      type: SEC_SET_DETAIL_CLIENT,
      detailClient: data,
    });
  };
};
export const SetInitialisationDetailsClient = () => {
  return (dispatch) => {
    dispatch({
      type: INITIALISATION_DETAILS_CLIENT,
    });
  };
};
