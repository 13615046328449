import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from "i18next-http-backend";



i18n
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    backend: {
        // translation file path
        loadPath: "/assets/i18n/{{ns}}/{{lng}}.json",
    },
    fallbackLng: "fr",
    lng: "fr",
    debug: false,
    ns: ["address", "notification", "order", "payement", "product", "session", "transfert", "buying", "interfaceCustomization", "inventory", "login", "common", "checkout", "order", "clients", "fulfillment", "hold", "initialization"],
    interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
        formatSeparator: ",",
    },
    react: {
        wait: true,
        useSuspense: false
    },
  });

export default i18n;