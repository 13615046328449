import React from "react";
import { useStyles } from "./style";
import {
  Button,
  Typography,
  Box,
  ClickAwayListener,
  Modal,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { CancelOrder } from "../../../store/client/client.action";
import { useDispatch, useSelector } from "react-redux";
import {
  ignorePricingRules,
  updateItemDetails,
} from "../../../store/checkout/checkout.action";
const AlertModal = (props) => {
  const classes = useStyles();
  const { t } = useTranslation(["common", "checkout"]);
  const dispatch = useDispatch();
  const {
    setOpenModal,
    products,
    title,
    openModal,
    handleCancel,
    HandleDelete,
    HandleSubmit,
    data,
    defaultClient,
    order,
    closeAlert,
    isDeleteRow,
    isPricingRule,
  } = props;
  const itemDetails = useSelector((state) => state.Checkout.itemDetails);
  const ignorePricingRule = useSelector(
    (state) => state.Checkout.ignorePricingRules
  );
  const showPricingRules = () => {
    dispatch(ignorePricingRules(!ignorePricingRule));
  };

  const itemsWithPricingRules = itemDetails?.filter((item) =>
    Object.keys(item).includes("pricing_rules")
  );
  const handleClose = () => {
    HandleDelete(data.item_code);
    handleCancel();
  };

  const close = () => {
    isDeleteRow ? setOpenModal({ open: false }) : setOpenModal(false);
    order && dispatch(CancelOrder());
  };

  return (
    <Modal
      open={openModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {/*   <ClickAwayListener
        onClickAway={() => (setOpenModal ? setOpenModal(false) : null)}
      > */}
      <div className={classes.paper}>
        <Typography align={"center"} color={"primary"}>
          {title
            ? title
            : defaultClient?.customer_name
            ? title
            : "Il n'y a pas de client par défaut dans ce magasin! Veuillez définir un client."}
        </Typography>
        {products ? (
          <Box mt={3} justifyContent="center" display="flex">
            {products && products.length == 1
              ? "Vous avez déjà un produit en caisse!"
              : `Vous avez déjà ${products.length} produits en caisse!`}
          </Box>
        ) : (
          ""
        )}
        {isPricingRule
          ? itemsWithPricingRules.length != 0 && (
              <Box
                mt={3}
                style={{
                  paddingBottom: 5,
                  paddingLeft: 15,
                  paddingRight: 15,
                  paddingTop: 5,
                }}
                bgcolor={"#AAB3BF"}
                borderRadius={10}
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={ignorePricingRule}
                      onChange={() => showPricingRules()}
                    />
                  }
                  label={
                    <span style={{ fontWeight: "bold", fontSize: 14 }}>
                      {t("checkout:ignorePricingRule")}
                    </span>
                  }
                />
              </Box>
            )
          : ""}
        <Box
          mt={3}
          justifyContent="center"
          display="flex"
          className={classes.root}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={
              data ? handleClose : HandleSubmit ? HandleSubmit : handleCancel
            }
          >
            {t("common:continuer")}
          </Button>
          <Button color="primary" onClick={close}>
            {t("common:annuler")}
          </Button>
        </Box>
      </div>
      {/*   </ClickAwayListener> */}
    </Modal>
  );
};

export default AlertModal;
