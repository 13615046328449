import { Box } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "../components/form";
import { Header } from "../../stockEntry/components/header";
import { ButtongoBack } from "../../../components/buttongoback";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import TransfertForm from "../components/form/components/TransfertForm";
import { LoadStockDataReport } from "../../../store/inventory/inventory.action";
import { Loader } from "../../../components/loader";

export const CreateTransferRequest = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation(["common"]);

  const globalDefaults = useSelector((state) => state.Login.globalDefaults);

  const onClickgoBack = () => {
    history.goBack();
  };

  useEffect(() => {
    const data = {
      report_name: "Stock Projected Qty",
      filters: {
        company: globalDefaults?.default_company,
        warehouse: "",
        item_group: "",
      },
    };
    globalDefaults && dispatch(LoadStockDataReport(data));
  }, [globalDefaults]);

  if (!globalDefaults) return <Loader />;

  return (
    <Box>
      <ButtongoBack label={t("common:back")} onClick={onClickgoBack} />
      <Box mt={5}>
        <TransfertForm company={globalDefaults?.default_company} />
      </Box>
    </Box>
  );
};
