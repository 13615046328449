import { CLEAR_STATE } from "../login/login.type.js";
import {
  DOCUMENTS_COUNT_LOADED,
  SET_SELECTED_TRANSFERT_REQUEST,
  SET_TRANSFERT_DATA,
  UPDATE_ITEMS_REQUEST,
  SET_SELECTED_WAREHOUSE_CIBLE,
  MATERIAL_REQUEST_PURCHASE_LOADED,
  SET_PRODUCTS_STOCK,
} from "./transferRequest.type.js";

const initialState = {
  Transferts: {
    set_warehouse: "",
    set_from_warehouse: "",
    items: [],
  },
  selectedTransfertRequest: null,
  documentNumber: null,
  receivedMaterial: null,
  PurchaseMatReqList: null,
  ProductsStock: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TRANSFERT_DATA:
      return {
        ...state,
        Transferts: action.payload,
      };
    case SET_SELECTED_TRANSFERT_REQUEST:
      return {
        ...state,
        selectedTransfertRequest: action?.payload?.docs?.[0],
        receivedMaterial: action?.payload?.docs?.[0]?.items,
      };
    case UPDATE_ITEMS_REQUEST:
      return {
        ...state,
        receivedMaterial: action.payload,
      };

    case DOCUMENTS_COUNT_LOADED:
      return {
        ...state,
        documentNumber: action.documentNumber.message.count,
      };
    case SET_SELECTED_WAREHOUSE_CIBLE:
      return {
        ...state,
        selectedWarehouseCible: action.payload.docs[0],
      };
    case MATERIAL_REQUEST_PURCHASE_LOADED:
      return {
        ...state,
        PurchaseMatReqList: action.payload,
      };
    case SET_PRODUCTS_STOCK:
      return {
        ...state,
        ProductsStock: action.payload,
      };
    case CLEAR_STATE:
      return (state = initialState);
    default:
      return state;
  }
};

export default reducer;
