import React, { useState } from "react";
import MainCard from "../../../components/cards/MainCard";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { fr } from "date-fns/locale";
import DateFnsUtils from "@date-io/date-fns";
import FilterListIcon from "@material-ui/icons/FilterList";
import { useStyles } from "./style";
import StockReport from "./StockReport";
import { useDispatch, useSelector } from "react-redux";
import { formatDate } from "../../../helpers/utils";
import moment from "moment-timezone";
import { useEffect } from "react";
import {
  PrepareReport,
  loadStockBalance,
} from "../../../store/stockEntries/stockEntries.action";
import { Empty } from "../../../components/empty";
import { useTranslation } from "react-i18next";
import { Loader } from "../../../components/loader";
import InfoIcon from "@material-ui/icons/Info";
import * as XLSX from "xlsx";

const StockImage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const globalDefaults = useSelector((state) => state.Login.globalDefaults);
  const stockBalance = useSelector((state) => state.StockEntry.stockBalance);
  const stores = useSelector((state) => state.Login.stores);
  const items = useSelector((state) => state.Product.products);
  const categories = useSelector((state) => state.Product.categories);
  const [loading, setLoading] = useState(false);

  const [date, setDate] = useState({
    from_date: moment().subtract(1, "months").format("YYYY-MM-DD"),
    to_date: moment().format("YYYY-MM-DD"),
  });

  const handleFromDateChange = (e) => {
    setDate({ ...date, from_date: formatDate(e, "YYYY-MM-DD") });
  };

  const handleToDateChange = (e) => {
    setDate({ ...date, to_date: formatDate(e, "YYYY-MM-DD") });
  };
  const [filterState, setFilterState] = useState({
    warehouse: "",
    item_code: "",
    item_group: "",
  });

  const handleChange = (event) => {
    setFilterState({ ...filterState, [event.target.name]: event.target.value });
  };

  const onPageLoad = () => {
    const data = {
      report_name: "Stock Balance",
      filters: {
        company: globalDefaults?.default_company,
        from_date: date?.from_date,
        to_date: date?.to_date,
        warehouse: filterState?.warehouse,
        item_code: filterState?.item_code,
        item_group: filterState?.item_group,
      },
    };
    dispatch(loadStockBalance(data));
  };

  const prepareReport = () => {
    setLoading(true);
    const data = {
      report_name: "Stock Balance",
      filters: {
        company: globalDefaults?.default_company,
        from_date: date?.from_date,
        to_date: date?.to_date,
        warehouse: filterState?.warehouse,
        item_code: filterState?.item_code,
        item_group: filterState?.item_group,
      },
    };
    dispatch(PrepareReport(data)).then(() => {
      setLoading(false);
    });
  };

  function formatRelativeDateTime(datetimeString) {
    const date = new Date(datetimeString);
    const now = new Date();

    const diffInSeconds = Math.floor((now - date) / 1000);

    if (diffInSeconds < 60) {
      return "just now";
    } else if (diffInSeconds < 3600) {
      const minutes = Math.floor(diffInSeconds / 60);
      return `${minutes}  ${t("common:minutes")}.`;
    } else if (diffInSeconds < 86400) {
      const hours = Math.floor(diffInSeconds / 3600);
      return `${hours} ${t("common:hours")}.`;
    } else {
      const formatter = new Intl.DateTimeFormat("en", {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      });
      return formatter.format(date);
    }
  }

  const formattedDateTime =
    stockBalance?.doc?.report_end_time &&
    formatRelativeDateTime(stockBalance?.doc?.report_end_time);

  useEffect(() => {
    if (globalDefaults?.default_company) {
      onPageLoad();
    }
  }, [globalDefaults?.default_company]);

  useEffect(() => {
    onPageLoad();
  }, [filterState, date, globalDefaults?.default_company]);

  function convertArrayOfObjectsToCSV(data) {
    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(data[0]);

    let result = keys.join(columnDelimiter);
    result += lineDelimiter;

    data.forEach((item) => {
      if (!Array.isArray(item)) {
        let ctr = 0;
        keys.forEach((key) => {
          if (ctr > 0) result += columnDelimiter;
          result += item[key];
          ctr++;
        });
        result += lineDelimiter;
      }
    });

    return result;
  }

  function downloadCSV(data, filename = "export.csv") {
    let csv = convertArrayOfObjectsToCSV(data);
    if (csv == null) return;

    if (!csv.startsWith("data:text/csv")) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    const link = document.createElement("a");
    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  function downloadXLSX(data, filename = "export.xlsx") {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, filename);
  }

  const currentDate = new Date();

  if (loading) return <Loader />;

  return (
    <div>
      {/* filters */}
      <MainCard
        title={
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            gap={5}
          >
            <Box
              color="blue"
              display={"flex"}
              alignItems={"center"}
              fontSize={20}
              fontWeight={800}
            >
              Filters
              <FilterListIcon className={classes.color} />
            </Box>
            <Button
              color="primary"
              variant="contained"
              onClick={() => prepareReport()}
            >
              {stockBalance?.doc === null
                ? t("inventory:generateReport")
                : t("inventory:rebuild")}
            </Button>
          </Box>
        }
        boxShadow
        divider
        style={{ marginTop: 2 }}
      >
        <div
          style={{
            padding: "20px 0px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 10,
          }}
        >
          <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
            <KeyboardDatePicker
              size="small"
              name="from_date"
              label="De"
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              inputVariant="outlined"
              id="date-picker-inline"
              keyboardIcon={<span className={"icon-calendar"} />}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              onChange={handleFromDateChange}
              value={date.from_date}
            />
          </MuiPickersUtilsProvider>

          <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
            <KeyboardDatePicker
              size="small"
              name="to_date"
              label="À"
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              inputVariant="outlined"
              id="date-picker-inline"
              keyboardIcon={<span className={"icon-calendar"} />}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              onChange={handleToDateChange}
              value={date.to_date}
            />
          </MuiPickersUtilsProvider>

          <FormControl sx={{ minWidth: 120 }} variant="outlined" size="small">
            <InputLabel id="demo-simple-select-required-label">
              {t("common:magasin")}
            </InputLabel>
            <Select
              labelId="demo-simple-select-required-label"
              id="demo-simple-select-required"
              label={t("common:magasin")}
              name="warehouse"
              value={filterState?.warehouse}
              onChange={handleChange}
            >
              <MenuItem value="">
                <em>All</em>
              </MenuItem>
              {Array.isArray(stores) &&
                stores?.map((row) => (
                  <MenuItem value={row.name} key={row.name}>
                    {row.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          <FormControl sx={{ minWidth: 120 }} variant="outlined" size="small">
            <InputLabel id="demo-simple-select-required-label">
              {t("columns:category")}
            </InputLabel>
            <Select
              labelId="demo-simple-select-required-label"
              id="demo-simple-select-required"
              label={t("columns:category")}
              name="item_group"
              value={filterState?.item_group}
              onChange={handleChange}
            >
              <MenuItem value="">
                <em>All</em>
              </MenuItem>
              {Array.isArray(categories) &&
                categories?.map((row) => (
                  <MenuItem value={row.name} key={row.name}>
                    {row.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          <FormControl variant="outlined" size="small">
            <InputLabel id="demo-simple-select-required-label">
              {" "}
              {t("common:itemCode")}
            </InputLabel>
            <Select
              labelId="demo-simple-select-required-label"
              id="demo-simple-select-required"
              label={t("common:itemCode")}
              name="item_code"
              value={filterState?.item_code}
              onChange={handleChange}
            >
              <MenuItem value="">
                <em>All</em>
              </MenuItem>
              {Array.isArray(items) &&
                items?.map((row) => (
                  <MenuItem value={row.name} key={row.name}>
                    {row.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
      </MainCard>

      {/* Report */}
      <MainCard
        title={
          <>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
              gap={5}
            >
              <Box
                color={"primary"}
                display={"flex"}
                alignItems={"center"}
                fontSize={20}
                fontWeight={800}
              >
                Stock
              </Box>
              <Box
                display={"flex"}
                flexDirection={"row"}
                alignItems={"center"}
                justifyContent={"flex-end"}
                mt={1}
              >
                <Button
                  variant="text"
                  onClick={() =>
                    downloadCSV(
                      stockBalance?.result,
                      `[VNDIT] 'Stock Balance' [${formatDate(
                        currentDate,
                        "YYYY-MM-DD HH:mm"
                      )}].csv`
                    )
                  }
                >
                  CSV
                </Button>
                <Button
                  variant="text"
                  onClick={() =>
                    downloadXLSX(
                      stockBalance?.result,
                      `[VNDIT] 'Stock Balance' [${formatDate(
                        currentDate,
                        "YYYY-MM-DD HH:mm"
                      )}].xlsx`
                    )
                  }
                >
                  XLSX
                </Button>
              </Box>
            </Box>
            {stockBalance?.doc?.report_end_time && (
              <Box
                display={"flex"}
                flexDirection={"row"}
                alignItems={"center"}
                style={{ gap: 5, paddingTop: 3 }}
              >
                <InfoIcon sx={{ mt: 0.6 }} />
                <Typography p={1} color="primary">
                  {formattedDateTime === "just now"
                    ? t("common:msgNow")
                    : `${t("common:info1Report")} ${formattedDateTime} ${t(
                        "common:info2Report"
                      )}`}
                </Typography>
              </Box>
            )}
          </>
        }
        isWhite
        boxShadow
        divider
        style={{ marginTop: 20 }}
      >
        {stockBalance?.doc === null ? (
          <Empty text={t("inventory:report")} />
        ) : (
          <>
            {stockBalance?.result?.length === 0 ? (
              <Empty text={t("inventory:nothing2show")} />
            ) : (
              <StockReport doc={stockBalance} />
            )}
          </>
        )}
      </MainCard>
    </div>
  );
};

export default StockImage;
