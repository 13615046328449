import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {Card, CardContent, Typography, Box} from "@material-ui/core";
import clsx from "clsx";
import { formatDate } from "../../../../helpers/utils";
import { useTranslation } from "react-i18next";

export const useStyles = makeStyles((theme) => ({
    card : {
        background: theme.palette.secondary.main,
        borderRadius: 10,
        border : "none",
    },
    cardBoldText : {
        color: theme.palette.primary.main,
        fontWeight : 600,
        fontSize : 14
    },
    cardTitle : {
        fontSize : 16
    },
    cardTitleBlock : {
        borderBottom: "1px solid #e7eaef",
        padding: "10px 20px 17px"
    },
    cardContentBlock : {
        padding: "1.4375rem 1.5rem 2.0625rem"
    },
    usernameBlock : {
        background: '#FFF',
        borderRadius: 19,
        padding : 14,
        marginBottom : 17
    },
    username : {
        color: "#2F334D",
        fontSize: 20,
        fontWeight: 600
    },
    textLight : {
        color: theme.palette.gray.main,
        fontSize: 12,
        fontWeight: 500
    },
    text : {
        fontSize : 14,
        fontWeight: 600
    },
    item : {
        padding: "0rem 1.625rem",
        marginBottom:"1.4375rem"
    },
    backLink : {
        fontSize : 12,
        color : '#2F334D',
        display :"flex",
        alignItems : "center",
        cursor :"pointer",
        marginTop: 14,
        marginLeft: 22
    },
    linkIcon : {
        height: 10,
        width: 8
    }
  }));
  
export const PosDetail = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const {t} = useTranslation(["common"])

  return (
    <Box ref={ref}>
        {props.details && (
        <Card
            className={clsx(classes.card, classes.cardBoldText)}
            variant="outlined"
        > 
            <CardContent>
                <Box>
                    <Box className={classes.item}>
                        <Typography color={'primary'} className={classes.textLight}>{t("common:ref")}</Typography>
                        <Typography className={classes.text}>{props.details.name}</Typography>
                    </Box>
                    <Box className={classes.item}>
                        <Typography color={'primary'} className={classes.textLight}>{t("common:user")}</Typography>
                        <Typography className={classes.text}>{props.details.user}</Typography>
                    </Box>
                    <Box className={classes.item}>
                        <Typography color={'primary'} className={classes.textLight}>{t("common:caisse")}</Typography>
                        <Typography className={classes.text}>{props.details.pos_profile}</Typography>
                    </Box>
                    <Box className={classes.item}>
                        <Typography color={'primary'} className={classes.textLight}>{t("common:total")}</Typography>
                        <Typography className={classes.text}>{props.details.grand_total}</Typography>
                    </Box>
                    <Box className={classes.item}>
                        <Typography color={'primary'} className={classes.textLight}>{t("common:refOuverture")}</Typography>
                        <Typography className={classes.text}>{props.details.pos_opening_entry}</Typography>
                    </Box>
                    <Box className={classes.item}>
                        <Typography color={'primary'} className={classes.textLight}>{t("common:dateOuverture")}</Typography>
                        <Typography className={classes.text}>{formatDate(props.details.period_start_date, "DD/MM/YYYY HH:mm")}</Typography>
                    </Box>
                    <Box className={classes.item}>
                        <Typography color={'primary'} className={classes.textLight}>{t("common:dateFermeture")}</Typography>
                        <Typography className={classes.text}>{formatDate(props.details.period_end_date, "DD/MM/YYYY HH:mm")}</Typography>
                    </Box>
                    <Box className={classes.item}>
                        <Typography color={'primary'} className={classes.textLight}>{t("common:company")}</Typography>
                        <Typography className={classes.text}>{props.details.company}</Typography>
                    </Box>
                </Box>
            </CardContent>
        </Card> 
        )} 
    </Box>
  );
});
