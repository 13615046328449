import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Button,
  Paper,
  TextField,
  Link,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { fr } from "date-fns/esm/locale";
import DateFnsUtils from "@date-io/date-fns";
import { ExpandMore } from "@material-ui/icons";
import { ButtongoBack } from "../../components/buttongoback";
import { BasicTable } from "../checkout/components/basictable";
import { formatDate } from "../../helpers/utils";
import { GlobalfilterData } from "../../helpers/helper";
import { useParams } from "react-router-dom";
import { SetNotification } from "../../store/notification/notification.action";
import { CardDetails } from "../buying/components/purchaseOrderDetails/cardDetails";
import { Loader } from "../../components/loader";
import { TransitionAlerts } from "../../components/TransitionAlerts";
import {
  SetpackagesWithItemsDbErpData,
  updatePurchaseOrderPackage,
} from "../../store/buying/buying.action";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { Empty } from "../../components/empty";
import AlertModal from "../../components/common/alertModal";

const useStyles = makeStyles((theme) => ({
  blockTitle: {
    fontSize: 14,
    marginBottom: 8,
    paddingLeft: 10,
  },
  formControl: {
    marginBottom: 15,
    "& .MuiInputBase-root": {
      background: theme.palette.white.main,
    },
    "& .MuiOutlinedInput-input": {
      padding: "8.9px 14px",
      fontSize: 12,
    },
  },
  paper: {
    paddingLeft: 10,
    "& .MuiInputBase-root": {
      background: theme.palette.white.main,
    },
    "& .MuiOutlinedInput-input": {
      padding: "8.9px 14px",
      fontSize: 12,
    },
    "& .MuiSelect-root": {
      padding: "0.4938rem 0.875rem",
    },
  },
  label: {
    fontSize: 12,
    color: theme.palette.gray.main,
    paddingLeft: 9,
    marginBottom: 6,
  },
  date: {
    marginBottom: 15,
  },
  formBlock: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
    },
    paddingTop: "20px",
  },
  customButton: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.blue.main,
    border: "1px solid",
    borderColor: theme.palette.blue.main,
    fontSize: "0.875rem",
    borderRadius: 10,
    margin: "0rem 1.375rem",
    padding: "0.1875rem 1.4375rem",
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
      borderColor: theme.palette.blue.main,
    },
  },
  cancelButton: {
    color: "#909BAB",
  },
  basictable: {
    height: "255px",
  },
  save: {
    marginTop: "10px",
    marginLeft: 0,
    marginRight: -20,
  },
}));

export const AddPackagePurchase = () => {
  const dispatch = useDispatch();
  const search = useLocation().search;

  const packagesWithItems = useSelector(
    (state) => state.Buying.packagesWithItemsMatchingDbErp
  );
  const package_id = new URLSearchParams(search).get("package_id");

  const header = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
    Authorization: JSON.parse(localStorage.getItem("user"))?.token,
  };
  const { id } = useParams();
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation(["common", "buying"]);
  const [formErrState, setFormErrState] = useState({
    scheduleDate: false,
    items: false,
  });
  const [alert, setAlert] = React.useState({
    open: false,
    message: "",
    severity: "",
  });
  const [scheduleDate, setScheduleDate] = useState(
    formatDate(new Date(), "YYYY-MM-DD")
  );
  const [searchProduct, setSearchProduct] = useState("");

  const [initialLines, setInitialLines] = useState([0, 1, 2, 3]);
  const [suggestion, setsuggestion] = useState([]);
  const [selectitem, setSelectitem] = useState(false);
  const [synchronized, setSynchronized] = useState(false);
  const [itemsInformations, setItemsInformations] = useState(null);
  const [error, setError] = useState({
    items: false,
    scheduleDate: false,
  });
  const [items, setItems] = useState([]);
  const [purchaseOrder, setPurchaseOrder] = useState(null);
  const [packagePurchase, setPackagePurchase] = useState(null);
  const [packageEdited, setPackageEdited] = useState(null);
  const [allInPackage, setAllInPackage] = useState(true);
  const [request, setRequest] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const initialization = () => {
    fetch(
      `${process.env.REACT_APP_API_URI}/api/details?doctype=Purchase%20Order&name=${id}`,
      {
        method: "GET",
        headers: header,
      }
    )
      .then((res) => {
        if (!res.ok) {
          dispatch(
            SetNotification({
              code: "error",
              message: t("common:problemRefresh"),
            })
          );
        } else {
          return res.json();
        }
      })
      .then((data) => {
        try {
          setPurchaseOrder(data?.docs[0]);
        } catch (error) {
          console.log(error);
        }
      });
    fetch(`${process.env.REACT_APP_API_URI}/api/get-by-purchase-order/${id}`, {
      method: "GET",
      headers: header,
    })
      .then((res) => {
        if (!res.ok) {
          dispatch(
            SetNotification({
              code: "error",
              message: t("common:problemRefresh"),
            })
          );
        } else {
          return res.json();
        }
      })
      .then((data) => {
        setPackagePurchase(data);
      });
  };
  useEffect(() => {
    initialization();
    return () => {
      dispatch(SetpackagesWithItemsDbErpData(null));
    };
  }, []);
  useEffect(() => {
    if (id && purchaseOrder && packagePurchase && !synchronized) {
      if (package_id) {
        let itemsinfo = packagesWithItems?.find(
          (el) => el?.id + "" === package_id
        );
        if (itemsinfo) setItemsInformations(itemsinfo);
        const packageE = packagePurchase?.find(
          (element) => element?.id + "" === package_id
        );

        setScheduleDate(
          formatDate(new Date(packageE?.scheduledate), "YYYY-MM-DD")
        );
        setComments(packageE?.comments);
        setPackageEdited(packageE);
        try {
          const it = JSON.parse(packageE.items);
          setItems(it);
        } catch (error) {}
      }
      const var_items = [];
      purchaseOrder?.items?.forEach((elOrder) => {
        let it = elOrder;
        packagePurchase?.forEach((elPackage) => {
          try {
            if (!package_id || elPackage?.id + "" !== package_id) {
              let tabitemspackage = JSON.parse(elPackage?.items);
              let indexitem = tabitemspackage.findIndex(
                (e) => e.item_code == it.item_code
              );
              if (indexitem > -1) {
                it.qty = it.qty - tabitemspackage[indexitem]?.qty;
              }
            }
          } catch (error) {}
        });
        var_items.push(it);
      });
      setSynchronized(true);
      let indexit = var_items?.findIndex((el) => el?.qty > 0);
      if (indexit > -1) {
        setAllInPackage(false);
      }
      setPurchaseOrder({ ...purchaseOrder, var_items });
    }
  }, [purchaseOrder, packagePurchase]);

  const onClickgoBack = () => {
    history.goBack();
  };
  const handleSubmit = async (event) => {
    let itemstosave = [];
    const testerror = {
      scheduleDate:
        scheduleDate || scheduleDate < formatDate(new Date(), "YYYY-MM-DD"),
      items: items?.length < 1,
    };
    setFormErrState(testerror);
    if (items?.length < 1) {
      setAlert({
        open: true,
        message: t("buying:youCannotSaveAnEmptyPackage"),
        severity: "error",
      });
    }
    if (JSON.stringify(testerror).indexOf("true") < 0) {
      items.forEach((element) => {
        itemstosave.push({
          item_code: element?.item_code,
          item_name: element?.item_name,
          qty: element?.qty,
          acceptedQty: package_id ? element?.acceptedQty : 0,
        });
      });
      if (!package_id) {
        const req = {
          purchaseorder_id: id,
          scheduledate: scheduleDate,
          items: JSON.stringify(itemstosave),
          comments: comments,
          status: "inprogress",
        };
        fetch(`${process.env.REACT_APP_API_URI}/api/purchase-package/create`, {
          method: "POST",
          headers: header,
          body: JSON.stringify({ content: req }),
        })
          .then((res) => {
            if (!res.ok) {
              dispatch(
                SetNotification({
                  code: "error",
                  message: "An error has occurred",
                })
              );
            } else {
              return res.json();
            }
          })
          .then((data) => {
            if (data?.success === false) {
              dispatch(
                SetNotification({
                  code: "warning",
                  message: data?.message,
                })
              );
            } else {
              dispatch(
                SetNotification({
                  code: "success",
                  message: "buying:successfulPackageCreation",
                })
              );
              history.push(`/wms`);
            }
          });
      } else {
        let itemclosed = itemstosave?.filter((e) => e?.acceptedQty != e?.qty);
        if (itemclosed?.length > 0) {
          const req = {
            id: package_id,
            scheduledate: scheduleDate,
            items: JSON.stringify(itemstosave),
            comments: comments,
          };
          await dispatch(
            updatePurchaseOrderPackage(req, t("buying:successfulPackageUpdate"))
          );
          history.push(`/purchase-order/${id}`);
        } else {
          const req = {
            id: package_id,
            scheduledate: scheduleDate,
            items: JSON.stringify(itemstosave),
            comments: comments,
            status: "Completed",
          };
          setRequest(req);
          setOpenDialog(true);
        }
      }
    }
  };
  const updateAndCompletedPackage = async () => {
    await dispatch(
      updatePurchaseOrderPackage(request, t("buying:successfulPackageUpdate"))
    );
    history.push(`/purchase-order/${id}`);
  };
  const handleDateChange = (date, event) => {
    isNaN(Date.parse(date))
      ? setError({ ...error, scheduleDate: false })
      : setScheduleDate(date.toISOString().slice(0, 10));
  };
  const HandleQuantityChange = (onAdd, id) => {
    const ItemIndex = items.findIndex((s) => s.item_code == id);
    const ItemIndexPO = purchaseOrder?.items?.findIndex(
      (s) => s.item_code == id
    );
    const data = [...items];
    let itemInfo = itemsInformations
      ? itemsInformations?.items?.find((el) => el?.item_code === id)
      : null;

    if (
      ItemIndex != -1 &&
      ItemIndexPO != -1 &&
      ((onAdd &&
        parseInt(purchaseOrder?.items[ItemIndexPO]?.qty) >
          parseInt(data[ItemIndex].qty)) ||
        (!onAdd &&
          (!packageEdited ||
            (itemInfo &&
              itemInfo?.acceptedQty < parseInt(data[ItemIndex].qty)))))
    ) {
      try {
        data[ItemIndex].qty = onAdd
          ? parseInt(data[ItemIndex].qty) + 1
          : parseInt(data[ItemIndex].qty) > 1
          ? data[ItemIndex].qty - 1
          : data[ItemIndex].qty;
        data[ItemIndex].qty = data[ItemIndex].qty;
        data[ItemIndex].stock_qty = data[ItemIndex].qty;
        setItems(data);
      } catch (error) {}
    } else {
      if (
        !onAdd &&
        (!packageEdited ||
          (itemInfo && itemInfo?.acceptedQty > parseInt(data[ItemIndex].qty)))
      ) {
        setAlert({
          open: true,
          message:
            t(
              "buying:theExpectedQuantityToBeReceivedShouldNotBeLessThanTheReceivedQuantity"
            ) + itemInfo?.acceptedQty,
          severity: "error",
        });
      }
    }
  };
  const HandleUpdateQuantity = (e, iditem) => {
    const ItemIndex = items.findIndex((s) => s.item_code == iditem);
    const ItemIndexPO = purchaseOrder?.items?.findIndex(
      (s) => s.item_code == iditem
    );
    const ItemIndexItInf = itemsInformations?.items?.findIndex(
      (s) => s.item_code == iditem
    );
    const data = [...items];

    try {
      if (
        ItemIndex != -1 &&
        ItemIndexPO != -1 &&
        parseInt(purchaseOrder?.items[ItemIndexPO]?.qty) >=
          parseInt(e.target.value) &&
        parseInt(e.target.value) > 0 &&
        (!packageEdited ||
          (ItemIndexItInf != -1 &&
            parseInt(itemsInformations?.items[ItemIndexItInf]?.acceptedQty) <=
              parseInt(e.target.value)))
      ) {
        data[ItemIndex].qty = parseInt(e.target.value);
        setItems(data);
      } else {
        if (ItemIndex != -1 && ItemIndexPO != -1) {
          if (
            parseInt(purchaseOrder?.items[ItemIndexPO]?.qty) <
            parseInt(e.target.value)
          ) {
            setAlert({
              open: true,
              message:
                t("buying:doNotExceedTheRequiredQuantity") +
                purchaseOrder?.items[ItemIndexPO]?.qty,
              severity: "error",
            });
          } else if (
            ItemIndexItInf != -1 &&
            parseInt(itemsInformations?.items[ItemIndexItInf]?.acceptedQty) >
              parseInt(e.target.value)
          ) {
            setAlert({
              open: true,
              message:
                t(
                  "buying:theExpectedQuantityToBeReceivedShouldNotBeLessThanTheReceivedQuantity"
                ) + itemsInformations?.items[ItemIndexItInf]?.acceptedQty,
              severity: "error",
            });
          } else {
            setAlert({
              open: true,
              message: "Entrée invalide ",
              severity: "error",
            });
          }
        }
      }
    } catch (error) {}
  };
  const HandleDelete = (id) => {
    let itemInfo = itemsInformations
      ? itemsInformations?.items?.find((el) => el?.item_code === id)
      : null;
    if (!packageEdited || (itemInfo && itemInfo?.acceptedQty < 1)) {
      const data = items.filter((s) => s.item_code !== id);
      setItems(data);
    } else {
      setAlert({
        open: true,
        message: t(
          "buying:cannotBeDeletedApurchaseReceiptHasBeenCreatedForThisItem"
        ),
        severity: "error",
      });
    }
  };
  const handleSearchProduct = async (e) => {
    setSearchProduct(e.target.value);
    if (e.target.value == "") {
      setsuggestion([]);
      return;
    }
    let itemstock = purchaseOrder?.items?.filter(
      (s) => s.is_stock_item !== 0 && s.qty > 0
    );
    const data = await GlobalfilterData(itemstock, e.target.value, [
      "item_code",
      "item_name",
      "item_group",
      "description",
    ]);
    setsuggestion(data);
  };
  const handleAddproduct = async (id) => {
    setSelectitem(true);
    var item = suggestion.find((s) => s.item_name == id);
    const data = [...items];
    const ItemIndex = items.findIndex((s) => s.item_name == id);

    if (ItemIndex != -1) {
      const ItemIndexPO = purchaseOrder?.items?.findIndex(
        (s) => s.item_code == data[ItemIndex]?.item_code
      );
      if (
        ItemIndexPO != -1 &&
        parseInt(purchaseOrder?.items[ItemIndexPO]?.qty) >
          parseInt(data[ItemIndex]?.qty)
      ) {
        data[ItemIndex].quantity = data[ItemIndex].quantity + 1;
        data[ItemIndex].qty = data[ItemIndex].qty + 1;
        data[ItemIndex].stock_qty = data[ItemIndex].stock_qty + 1;
      }
    } else {
      const dataitem = {
        item_code: item?.item_code,
        item_name: item?.item_name,
        description: item?.description,
        qty: 1,
      };
      data.push(dataitem);
    }
    setItems(data);
    setsuggestion([]);
    setSelectitem(false);
    setSearchProduct("");
  };
  const [comments, setComments] = useState("");
  const handleChangeComments = (e) => {
    setComments(e.target.value);
  };
  if (!purchaseOrder) return <Loader />;
  if (!packagesWithItems && purchaseOrder && package_id) {
    dispatch(
      SetNotification({
        code: "error",
        message: t("common:followTheStepsOrder"),
      })
    );
    history.push(`/purchase-order/${id}`);
  }
  return (
    <Box>
      <ButtongoBack label={t("common:back")} onClick={onClickgoBack} />
      <Box mt={5}>
        <Grid
          container
          direction="row"
          spacing={2}
          className={classes.root}
          display="flex"
          style={{ marginTop: 5 }}
        >
          <Grid item xs={12} md={4}>
            <CardDetails
              details={purchaseOrder}
              schedule_date={purchaseOrder?.schedule_date}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            {allInPackage ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                className={classes.paper}
              >
                <Empty text={t("buying:allProductsAreDividedIntoPacks")} />

                <Link href={`/purchase-order/${id}`} style={{ color: "blue" }}>
                  {t("common:moreDetails")}
                </Link>
              </Box>
            ) : (
              <Box className={classes.paper}>
                <Box className={classes.formBlock}>
                  <Grid container spacing={3}>
                    <Grid item xs>
                      <MuiPickersUtilsProvider locale={fr} utils={DateFnsUtils}>
                        <InputLabel className={classes.label}>
                          {t("buying:RequiredOn")}
                        </InputLabel>
                        <KeyboardDatePicker
                          className={classes.date}
                          disableToolbar
                          variant="inline"
                          format="dd/MM/yyyy"
                          name="scheduleDate"
                          inputVariant="outlined"
                          id="date-picker-inline"
                          value={scheduleDate}
                          onChange={handleDateChange}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          minDate={formatDate(new Date(), "YYYY-MM-DD")}
                          minDateMessage={t("common:minDateMessage")}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs></Grid>
                  </Grid>
                </Box>
                <Typography
                  className={classes.blockTitle}
                  color={error.items ? "error" : "primary"}
                  variant="caption"
                  display="block"
                >
                  {t("common:article")} *
                </Typography>
                <div data-grid={{ w: 9, h: 3, x: 0, y: 0, minW: 4, minH: 3 }}>
                  <Box justifyContent="center" display="flex">
                    <TransitionAlerts
                      severity={alert.severity}
                      setAlert={setAlert}
                      open={alert.open}
                      message={alert.message}
                      timeout={3000}
                    />
                  </Box>
                  <BasicTable
                    selectitem={selectitem}
                    suggestion={suggestion}
                    initialLines={initialLines}
                    rows={items}
                    setSearchProduct={setSearchProduct}
                    setsuggestion={setsuggestion}
                    HandleQuantityChange={HandleQuantityChange}
                    HandleDelete={HandleDelete}
                    handleSearchProduct={handleSearchProduct}
                    handleAddproduct={handleAddproduct}
                    searchProduct={searchProduct}
                    HandleUpdateQuantity={HandleUpdateQuantity}
                    setInitialLines={setInitialLines}
                    addPurchaseAction={true}
                    isPurchasePackage
                    invaliditems={error?.invaliditems}
                  />
                </div>

                <br />
                <TextField
                  variant="outlined"
                  multiline
                  id="outlined-basic"
                  label={t("common:comment")}
                  name="comments"
                  value={comments}
                  onChange={handleChangeComments}
                />
                <br />
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  className={classes.save}
                >
                  <Button
                    onClick={handleSubmit}
                    className={classes.customButton}
                    size={"small"}
                    variant="outlined"
                    color="primary"
                  >
                    {t("common:save")}
                  </Button>
                </Box>
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
      <AlertModal
        setOpenModal={setOpenDialog}
        openModal={openDialog}
        HandleSubmit={updateAndCompletedPackage}
        title={t("common:doYouReallyWantToCloseThisPackage")}
      />
    </Box>
  );
};
