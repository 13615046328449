import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Button,
  Typography,
  Paper,
} from "@material-ui/core";
import { useStyles } from "../../style";
import { useTableStyles } from "../../../../styles/table.style";
import { useTranslation } from "react-i18next";

export const PurchaseRecDetails = (props) => {
  const { puchaseInvoiceDoc, handleCreatePurInvoice, handleCloseModal } = props;
  const classes = useStyles();
  const tableclasses = useTableStyles();
  const { t } = useTranslation(["common", "buying"]);
  
  return (
    <div className={classes.paper}>
      <Box className={classes.paperr}>
        <Typography align={"center"} color={"primary"}>
          {t("buying:PurchaseInvoice")}:{" "}
          {puchaseInvoiceDoc?.items[0]?.purchase_order}
        </Typography>
        <Box mt={3}>
          <Box>
            <Paper className={classes.paperr} style={{ marginBottom: 10 }}>
              <Typography align={"left"} color={"primary"}>
                {t("common:AddBy")}: {puchaseInvoiceDoc?.owner}
              </Typography>
              <Typography align={"left"} color={"primary"}>
                {t("common:mgsCible")}: {puchaseInvoiceDoc?.items[0]?.warehouse}
              </Typography>
              <Typography align={"left"} color={"primary"}>
                {t("buying:SupplierName")}: {puchaseInvoiceDoc?.supplier_name}
              </Typography>
              <Typography align={"left"} color={"primary"}>
                {puchaseInvoiceDoc?.address_display?.replace(
                  /<br\s*\/?>/gi,
                  ";   "
                )}
              </Typography>
              <Typography align={"left"} color={"primary"}>
                {t("common:createDate")}: {puchaseInvoiceDoc?.transaction_date}
              </Typography>
            </Paper>
          </Box>
        </Box>
        <Box mt={2}>
          <Typography
            style={{ marginBottom: -5 }}
            align={"left"}
            color={"primary"}
          >
            {t("common:article")}:
          </Typography>
          <Table className={tableclasses.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  className={tableclasses.tablecellHeader}
                  align="left"
                >
                  {t("common:produit")}
                </TableCell>
                <TableCell
                  className={tableclasses.tablecellHeader}
                  align="left"
                >
                  {t("common:itemCode")}
                </TableCell>
                <TableCell
                  className={tableclasses.tablecellHeader}
                  align="left"
                >
                  {t("common:amnt")}
                </TableCell>
                <TableCell
                  className={tableclasses.tablecellHeader}
                  align="left"
                >
                  {t("common:qtyAchat")}
                </TableCell>
                <TableCell
                  className={tableclasses.tablecellHeader}
                  align="left"
                >
                  {t("common:taux")}
                </TableCell>
                <TableCell
                  className={tableclasses.tablecellHeader}
                  align="left"
                >
                  {t("common:Taxation")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {puchaseInvoiceDoc?.items.map((row, i) => (
                <TableRow className={tableclasses.tableRow} key={i}>
                  <TableCell className={tableclasses.tableCell} align="left">
                    {row.item_name}
                  </TableCell>
                  <TableCell className={tableclasses.tableCell} align="left">
                    {row.item_code}
                  </TableCell>
                  <TableCell className={tableclasses.tableCell} align="left">
                    {row.amount.toFixed(3)} {puchaseInvoiceDoc?.currency}
                  </TableCell>
                  <TableCell className={tableclasses.tableCell} align="left">
                    {row.qty}
                  </TableCell>
                  <TableCell className={tableclasses.tableCell} align="left">
                    {row.rate.toFixed(3)} {puchaseInvoiceDoc?.currency}
                  </TableCell>
                  <TableCell className={tableclasses.tableCell} align="left">
                    {row.item_tax_rate != "" &&
                      Object.keys(JSON.parse(row.item_tax_rate))}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Paper className={classes.paperr} style={{ marginBottom: 10 }}>
            <Typography align={"left"} color={"primary"}>
              {t("common:qtyTotal")}: {puchaseInvoiceDoc?.total_qty}
            </Typography>
            <Typography align={"left"} color={"primary"}>
              {t("common:totalHT")}: {puchaseInvoiceDoc?.total?.toFixed(3)}{" "}
              {puchaseInvoiceDoc?.currency}
            </Typography>
            <Typography align={"left"} color={"primary"}>
              {t("common:totalTaxe")}:{" "}
              {puchaseInvoiceDoc?.base_total_taxes_and_charges?.toFixed(3)}{" "}
              {puchaseInvoiceDoc?.currency}
            </Typography>
            <Typography align={"left"} color={"primary"}>
              {t("common:grandTotal")} :{" "}
              {puchaseInvoiceDoc?.grand_total?.toFixed(3)}{" "}
              {puchaseInvoiceDoc?.currency}
            </Typography>
          </Paper>
        </Box>
        <Box style={{ display: "flex", justifyContent: "center" }}>
          <Button
            style={{ marginTop: 20, alignContent: "center" }}
            variant="contained"
            color="primary"
            onClick={handleCreatePurInvoice}
          >
            {t("common:valider")}
          </Button>
          <Button
            style={{ marginTop: 20, alignContent: "center", marginLeft: 10 }}
            variant="outlined"
            color="primary"
            onClick={handleCloseModal}
          >
            {t("common:fermer")}
          </Button>
        </Box>
      </Box>
    </div>
  );
};
