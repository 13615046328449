import axiosInstance from "../../history/axiosInstance";
import { FAILED_ADD_DATA } from "../rollback/rollback.type";
import {
  FULLFILMENTS_LOADED,
  GET_FULLFILMENTS,
  UPDATE_ORDER_STATUS,
  UPDATE_ORDER_STATUS_SUCCESS,
  FULLFILMENTS_PACKS_LOADED,
  GET_FULLFILMENTS_PACKS,
  CREATE_FULLFILMENTS_PACKS,
  CREATE_FULLFILMENTS_PACKS_SUCCESS,
  GET_FULLFILMENTS_BY_ID,
  FULFILLMENT_LOADED,
  UPDATE_MANY_ORDER_STATUS_SUCCESS,
  UPDATE_MANY_ORDER_STATUS,
  SET_ORDERS_TO_PICK,
  ORDER_CREATED_BRODCAST,
  LOAD_ITEM_LOCATION,
  ITEM_LOCATION_LOADED,
  SET_FULLFILMENT,
  SEND_STATUS_EMAIL,
  CREATE_STATUS_EMAIL_SUCCESS,
  CREATE_DELIVERY_NOTE_PACKING,
  CREATE_DELIVERY_NOTE_PACKING_SUCCESS,
  CREATE_EMAIL_SUCCESS,
  SEND_EMAIL,
  REJECTION_ORDER,
  REJECTION_ORDER_SUCCESS,
  CANCEL_SALES_ORDER,
  CANCEL_SALES_ORDER_SUCCESS,
  SET_PACKING_SELECTED,
  SET_MAPPED_DELIVERYNOTE,
  UPDATE_SALES_ORDER_REASON_SUCCESS,
} from "./fullfiment.type";

export const LoadFullfilments = (store, company) => ({
  type: GET_FULLFILMENTS,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${process.env.REACT_APP_API_URI}/api/orders/fullfilments`,
        method: "POST",
        body: JSON.stringify({
          company: `${company}`,
          store: store,
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
          Authorization: JSON.parse(localStorage.getItem("user")).token,
        },
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: FULLFILMENTS_LOADED,
        meta: "",
      },
    },
  },
});
export const SetFullfilmentsOrder = (fullfilmentsOrder) => {
  return {
    type: FULFILLMENT_LOADED,
    payload: fullfilmentsOrder,
  };
};
export const LoadFullfilmentsOrderById = (id) => ({
  type: GET_FULLFILMENTS_BY_ID,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${process.env.REACT_APP_API_URI}/api/orders/fullfilments/details`,
        method: "POST",
        body: JSON.stringify({
          id: id,
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
          Authorization: JSON.parse(localStorage.getItem("user")).token,
        },
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: FULFILLMENT_LOADED,
        meta: "",
      },
    },
  },
});

export const LoadFullfilmentsPacks = (id) => ({
  type: GET_FULLFILMENTS_PACKS,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${process.env.REACT_APP_API_URI}/api/orders/fullfilments`,
        method: "POST",
        body: JSON.stringify({
          order_id: id,
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
          Authorization: JSON.parse(localStorage.getItem("user")).token,
        },
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: FULLFILMENTS_PACKS_LOADED,
        meta: "",
      },
    },
  },
});

export const CreateOrderPack = (content, deleverycontent) => ({
  type: CREATE_FULLFILMENTS_PACKS,
  payload: content,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${process.env.REACT_APP_API_URI}/api/orders/fullfilments/packs/create`,
        method: "POST",
        body: JSON.stringify({
          order_id: content.order_id,
          products: content.products,
          status: content.status,
          pack_details: content.packDetails,
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
          Authorization: JSON.parse(localStorage.getItem("user")).token,
        },
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: CREATE_FULLFILMENTS_PACKS_SUCCESS,
        meta: {
          notification: {
            code: "success",
            message: "notification:packCreated",
          },
          then: (data) => (dispatch, getState) => {
            const index = data?.packs?.length;
            if (index > 0) {
              dispatch(
                createdeliverynote(deleverycontent, data?.packs[index - 1]?.id)
              );
            }
          },
        },
      },
    },
  },
});

export const CreateOrderInPacking = (content, deleverycontent) => ({
  type: CREATE_FULLFILMENTS_PACKS,
  payload: content,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${process.env.REACT_APP_API_URI}/api/orders/fullfilments/packs/create`,
        method: "POST",
        body: JSON.stringify({
          order_id: content.order_id,
          products: content.products,
          status: content.status,
          pack_details: content.packDetails,
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
          Authorization: JSON.parse(localStorage.getItem("user")).token,
        },
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: CREATE_FULLFILMENTS_PACKS_SUCCESS,
        meta: {
          notification: {
            code: "success",
            message: "notification:packCreated",
          },
          then: (data) => (dispatch, getState) => {
            const index = data?.packs?.length;
            if (index > 0) {
              dispatch(
                createdeliverynote(deleverycontent, data?.packs[index - 1]?.id)
              );
            }
          },
        },
      },
    },
  },
});

export const CreateStatusEmail = (customer_email, content) => ({
  type: SEND_STATUS_EMAIL,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${process.env.REACT_APP_API_URI}/api/email/status`,
        method: "POST",
        body: JSON.stringify({
          customer_email: customer_email,
          content: content,
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
          Authorization: JSON.parse(localStorage.getItem("user")).token,
        },
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: CREATE_STATUS_EMAIL_SUCCESS,
        meta: {
          notification: {
            code: "success",
            message: "notification:orderStatusSent",
          },
        },
      },
    },
  },
});

export const UpdateOrderStatus = (content) => ({
  type: UPDATE_ORDER_STATUS,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${process.env.REACT_APP_API_URI}/api/orders/fullfilment/update`,
        method: "POST",
        body: JSON.stringify({
          id: content.id,
          status: content.status,
          grand_total: content.grand_total,
          additional_discount_percentage:
            content.additional_discount_percentage,
          discount_amount: content.discount_amount,
          delivery_date: content.delivery_date,
          products: content.products,
          point_of_sales: content.point_of_sales,
          total_amount: content.total_amount,
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
          Authorization: JSON.parse(localStorage.getItem("user")).token,
        },
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: UPDATE_ORDER_STATUS_SUCCESS,
        meta: {
          notification: {
            code: "success",
            message: "notification:statusChanged",
          },
          then: (data) => (dispatch, getState) => {
            window.location.href = `/fulfillement?tab=${content.redirectToTab}`;
          },
        },
      },
    },
  },
});

export const UpdateManyOrderStatus = (content) => ({
  type: UPDATE_MANY_ORDER_STATUS,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${process.env.REACT_APP_API_URI}/api/orders/fullfilment/update/many`,
        method: "POST",
        body: JSON.stringify({
          orders: content.orders,
          status: content.status,
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
          Authorization: JSON.parse(localStorage.getItem("user")).token,
        },
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: UPDATE_MANY_ORDER_STATUS_SUCCESS,
        meta: {
          notification: {
            code: "success",
            message: "notification:statusChanged",
          },
          then: (data) => (dispatch, getState) => {
            window.location.href = `/fulfillement?tab=${content.redirectToTab}`;
          },
        },
      },
    },
  },
});
export const SetOrdersToPick = (ordersToPick) => {
  return {
    type: SET_ORDERS_TO_PICK,
    ordersToPick: ordersToPick,
  };
};

export const setFullfilment = (fullfilment) => {
  return {
    type: SET_FULLFILMENT,
    fullfilment: fullfilment,
  };
};

export const OrderCreatedBrodcast = (order) => {
  return {
    type: ORDER_CREATED_BRODCAST,
    order: order,
  };
};

export const getItemLocation = (warehouse) => ({
  type: LOAD_ITEM_LOCATION,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${process.env.REACT_APP_API_URI}/api/warehouses/item-location`,
        method: "POST",
        body: JSON.stringify({
          doctype: "Item Location",
          fields: [
            "`tabItem Location`.`name`",
            "`tabItem Location`.`item_location`",
            "`tabItem Location`.`item_code`",
          ],
          filters: [["Item Location", "warehouse", "=", warehouse]],
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
          Authorization: JSON.parse(localStorage.getItem("user")).token,
        },
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: ITEM_LOCATION_LOADED,
        meta: "",
      },
    },
  },
});

export const createdeliverynote = (content, id_packing) => ({
  type: CREATE_DELIVERY_NOTE_PACKING,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${process.env.REACT_APP_API_URI}/api/save-docs`,
        method: "POST",
        body: JSON.stringify({ doc: content, action: "Submit" }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
          Authorization: JSON.parse(localStorage.getItem("user")).token,
        },
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: CREATE_DELIVERY_NOTE_PACKING_SUCCESS,
        meta: {
          notification: {
            code: "success",
            message: "notification:purRecieptCreated",
          },
          then: (data) => (dispatch, getState) => {
            const res = JSON.parse(data)?.docs[0];
            const header = {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
                Authorization: JSON.parse(localStorage.getItem("user")).token,
              },
            };
            const doc = {
              id: id_packing,
              deliverynote_id: res?.name,
            };
            axiosInstance
              .post(
                `${process.env.REACT_APP_API_URI}/api/orders/fullfilments/packs/update`,
                doc,
                header
              )
              .then((response) => {
                //window.location.reload();
              })
              .catch((error) => {});
          },
        },
      },
    },
  },
});

export const CreateEmail = (email, message, order_id, customer_name) => {
  const action = {
    type: SEND_EMAIL,
    meta: {
      offline: {
        // the network action to execute:
        effect: {
          url: `${process.env.REACT_APP_API_URI}/api/email/cancellationfullfilment`,
          method: "POST",
          body: JSON.stringify({
            email: email,
            msg: message,
            order_id: order_id,
            customer_name: customer_name,
          }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
            Authorization: JSON.parse(localStorage.getItem("user")).token,
          },
        },
        // action to dispatch when effect succeeds:
        commit: {
          type: CREATE_EMAIL_SUCCESS,
          meta: {
            notification: {
              code: "success",
              message: "notification:emailSent",
            },
          },
        },
        rollback: { type: FAILED_ADD_DATA },
      },
    },
  };
  action.meta.offline.rollback.meta = action;
  return (dispatch, getState) => {
    dispatch(action);
  };
};

export const UpdateSalesOrderReasonById = (content, doctype) => {
  const action = {
    type: UPDATE_SALES_ORDER_REASON_SUCCESS,
    payload: content,
    meta: {
      offline: {
        // the network action to execute:
        effect: {
          url: `${process.env.REACT_APP_API_URI}/api/pos/update`,
          method: "POST",
          body: JSON.stringify({
            doctype: "Sales Order",
            action: "update",
            docnames: [content.name],
            data: {
              reason: content.reason,
            },
          }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
            Authorization: JSON.parse(localStorage.getItem("user")).token,
          },
        },
        // action to dispatch when effect succeeds:
        commit: {
          type: UPDATE_SALES_ORDER_REASON_SUCCESS,
          meta: {},
        },
        rollback: { type: FAILED_ADD_DATA },
      },
    },
  };
  action.meta.offline.rollback.meta = action;
  return (dispatch, getState) => {
    dispatch(action);
  };
};

export const CreateRejection = (id, status, message, order_id) => {
  const action = {
    type: REJECTION_ORDER,
    meta: {
      offline: {
        // the network action to execute:
        effect: {
          url: `${process.env.REACT_APP_API_URI}/api/orders/fullfilment/rejection`,
          method: "POST",
          body: JSON.stringify({
            id: id,
            status: status,
            notes: message,
          }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
            Authorization: JSON.parse(localStorage.getItem("user")).token,
          },
        },
        // action to dispatch when effect succeeds:
        commit: {
          type: REJECTION_ORDER_SUCCESS,
          meta: {
            notification: {
              code: "success",
              message: "notification:orderrejectionsuccess",
            },
            then: (data) => (dispatch, getState) => {
              const data = {
                name: order_id,
                reason: message,
              };
              dispatch(UpdateSalesOrderReasonById(data));
            },
          },
        },
        rollback: { type: FAILED_ADD_DATA },
      },
    },
  };
  action.meta.offline.rollback.meta = action;
  return (dispatch, getState) => {
    dispatch(action);
  };
};
export const cancelsalesorder = (id) => {
  const action = {
    type: CANCEL_SALES_ORDER,
    meta: {
      offline: {
        // the network action to execute:
        effect: {
          url: `${process.env.REACT_APP_API_URI}/api/cancel-doc`,
          method: "POST",
          body: JSON.stringify({ doctype: "Sales Order", name: id }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
            Authorization: JSON.parse(localStorage.getItem("user")).token,
          },
        },
        // action to dispatch when effect succeeds:
        commit: {
          type: CANCEL_SALES_ORDER_SUCCESS,
          meta: "",
        },
        rollback: { type: FAILED_ADD_DATA },
      },
    },
  };
  action.meta.offline.rollback.meta = action;
  return (dispatch, getState) => {
    dispatch(action);
  };
};

export const LoadFullfilmentsPacksById = (id) => {
  return (dispatch) => {
    const header = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem("user")).token,
      },
    };
    return axiosInstance
      .get(
        `${process.env.REACT_APP_API_URI}/api/orders/fullfilments/packs/details/${id}`,
        header
      )
      .then((res) => {
        dispatch(setSelectedPacking(res?.data));
      });
  };
};
export const setSelectedPacking = (packing) => {
  return {
    type: SET_PACKING_SELECTED,
    payload: packing,
  };
};

export const SetMappedDeliveryNote = (data) => {
  return {
    type: SET_MAPPED_DELIVERYNOTE,
    doc: data,
  };
};
