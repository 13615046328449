import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Box from "@material-ui/core/Box";
import { Button, Typography } from "@material-ui/core";
import { Calculator } from "../../../../components/calculator";
import { BasicTable } from "../basictable";
import { Freebutton } from "../freebutton";
import { Products } from "../products";
import { CirclePicker } from "react-color";
import { useDispatch, useSelector } from "react-redux";
import { globalColor } from "./../../../../store/theme/theme.action";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
  list: {
    width: 250,
    padding: 15,
  },
  title: {
    fontWeight: 700,
    marginBottom: 30,
  },
  subtitle: {
    color: theme.palette.gray.main,
    fontWeight: 500,
    marginBottom: 20,
  },
  block: {
    marginBottom: 20,
  },
  button: {
    marginRight: 10,
    marginBottom: 10,
  },
}));

export default function GlobalDrawer(props) {
  const classes = useStyles();

  const dispatch = useDispatch();
  const {t} = useTranslation(["interfaceCustomization","common"]);

  const theme = useSelector((state) => state.Theme.activeTheme);
  const themes = useSelector((state) => state.Theme.themes);

  const globalColors = useSelector((state) => state.Theme.globalColors);
  const freeButtonsStyle = useSelector(
    (state) => state.Theme.freeButtonsStyles
  );

  const handleChangeComplete = (key, color) => {
    const data = { ...globalColors, [key]: color.hex };
    dispatch(globalColor(data));
  };

  // const handleChangeComplete = (key, color) => {
  //   theme.globalColors = { ...theme.globalColors, [key]: color.hex };
  //   dispatch(UpdateTheme(theme));
  // };

  const [amount, setAmount] = React.useState("");
  const HandleAmount = (price) => {
    setAmount(price);
  };

  return (
    <div>
      <React.Fragment key={"right"}>
        <Drawer anchor={"right"} className={"interfacePage"} open={props.open} onClose={props.close}>
          <Box className={classes.list}>
            <Typography
              className={classes.title}
              align={"center"}
              color={"primary"}
            >
              {t("common:param")}
            </Typography>
            <Box className={classes.block}>
              <Box>
                <Box className={classes.block}>
                  <Typography className={classes.subtitle}>
                  {t("common:primColor")}
                  </Typography>
                  <CirclePicker
                    onChangeComplete={(color) =>
                      handleChangeComplete("primary", color)
                    }
                  />
                </Box>
                <Box className={classes.block}>
                  <Typography className={classes.subtitle}>
                    {" "}
                    {t("common:secColor")}
                  </Typography>
                  <CirclePicker
                    onChangeComplete={(color) =>
                      handleChangeComplete("secondary", color)
                    }
                  />
                </Box>
                <Box className={classes.block}>
                  <Typography className={classes.subtitle}>
                    {" "}
                    {t("common:actColor")}
                  </Typography>
                  <CirclePicker
                    onChangeComplete={(color) =>
                      handleChangeComplete("customColor", color)
                    }
                  />
                </Box>
                <Box className={classes.block}>
                  <Typography className={classes.subtitle}> {t("common:menu")}</Typography>
                  <CirclePicker
                    onChangeComplete={(color) =>
                      handleChangeComplete("menu", color)
                    }
                  />
                </Box>
                <Box className={classes.block}>
                  <Typography className={classes.subtitle}>
                  {t("common:actMenu")}
                  </Typography>
                  <CirclePicker
                    onChangeComplete={(color) =>
                      handleChangeComplete("activeMenu", color)
                    }
                  />
                </Box>
              </Box>
            </Box>
            <Box className={classes.block}>
              <Typography className={classes.subtitle}>
               {t("common:ajtBlock")}
              </Typography>
              <Box>
                <Button
                  onClick={() =>
                    props.onAddItem({
                      w: 4,
                      h: 6,
                      x: 8,
                      y: 0,
                      minW: 2,
                      minH: 3,
                      i: "1",
                      component: (
                        <Calculator
                          amount={0}
                          HandleAmount={HandleAmount}
                          basic={true}
                        />
                      ),
                      title: "Calculatrice",
                      key: "calculator",
                    })
                  }
                  className={classes.button}
                  variant={"contained"}
                  size={"small"}
                  color={"primary"}
                >
                  {t("common:calculator")}
                </Button>
                <Button
                  onClick={() =>
                    props.onAddItem({
                      w: 8,
                      h: 6,
                      x: 0,
                      y: 9,
                      minW: 2,
                      minH: 2,
                      i: "0",
                      component: <Products />,
                      title: "Produits",
                      key: "products",
                    })
                  }
                  className={classes.button}
                  variant={"contained"}
                  size={"small"}
                  color={"primary"}
                >
                   {t("common:produit")}
                </Button>
                <Button
                  onClick={() =>
                    props.onAddItem({
                      w: 8,
                      h: 9,
                      x: 0,
                      y: 0,
                      minW: 2,
                      minH: 3,
                      i: "2",
                      component: <BasicTable />,
                      title: "Commandes",
                      key: "selectedProducts",
                    })
                  }
                  className={classes.button}
                  variant={"contained"}
                  size={"small"}
                  color={"primary"}
                >
                 {t("common:commande")}
                </Button>
                <Button
                  onClick={() =>
                    props.onAddItem({
                      w: 4,
                      h: 9,
                      x: 8,
                      y: 0,
                      minW: 2,
                      minH: 3,
                      i: "3",
                      component: <Freebutton />,
                      title: "Buttons libre",
                      key: "freeButton",
                    })
                  }
                  className={classes.button}
                  variant={"contained"}
                  size={"small"}
                  color={"primary"}
                >
                 {t("interfaceCustomization:buttonLib")}
                </Button>
              </Box>
            </Box>
          </Box>
        </Drawer>
      </React.Fragment>
    </div>
  );
}
