
import { makeStyles } from '@material-ui/core/styles';


export const useStyles = makeStyles((theme) => ({

  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: 224,
  },
  content: {
    display: "flex",
    boxShadow: "0px 0.3125rem 0.5625rem #091f461f",
    border: "2px solid #CCD8E8",
    padding: "0.9375rem 0.625rem",
    borderRadius: 15,
    opacity: 1,
    margin: theme.spacing(0.5),
    background: "#FFFFFF",
    borderColor: theme.palette.products.border,
    text: theme.palette.products.text,
    minWidth: 170,
    cursor: "pointer"
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  img: {
    width: 41,
    marginRight: 10,
  },
  defaultImg: {
    width: 41,
    opacity: 0.15,
    marginRight: 10,
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: 14,
    fontWeight: 600,
    whiteSpace: "nowrap",
    width: "120px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    '&:hover': {
      overflow: "visible",
    },
  },
  price: {
    color: theme.palette.gray.main,
    fontSize: 12,
    marginTop: 9,
  },
  description: {
    color: theme.palette.text.primary,
    fontSize: 12,
    marginTop: 20,
    textAlign: "center",
    marginLeft: theme.spacing(-7),
  },
  isstock: {
    color: theme.palette.primary.main,
    fontSize: 12,
    fontWeight: 600,
    whiteSpace: "nowrap",
    width: "120px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    '&:hover': {
      overflow: "visible",
    },

  },
  root: {
    marginTop: "1rem"
  },
  backLink: {
    fontSize: 12,
    color: '#2F334D',
    display: "flex",
    alignItems: "center",
    cursor: "pointer"
  },
  backBox: {
    marginTop: "0.5rem",
    width: "fit-content"
  },
  roott: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  productItem: {
    marginBottom: "1.75rem",
    width: 220,
  },
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "0.75rem 0px 0.8125rem",
    flexWrap: "wrap",
    "& .MuiInputBase-root": {
      background: theme.palette.white.main,
    },
    "& .MuiOutlinedInput-input": {
      padding: "8.9px 14px",
      fontSize: 12,
    },
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    "& > *": {
      margin: theme.spacing(1),
    },
    flexWrap: "wrap",
    "& .MuiInputBase-root": {
      background: theme.palette.white.main,
    },
    "& .MuiOutlinedInput-input": {
      fontSize: 12,
    },
  },
  formControl: {
    maxWidth: 300,
    [theme.breakpoints.down("xs")]: {
      marginTop: 10,
    },
  },
  collection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    margin: "0.75rem 0px 0.8125rem",
    flexWrap: "wrap",
    "& .MuiInputBase-root": {
      background: theme.palette.white.main,
    },
    "& .MuiOutlinedInput-input": {
      padding: "8.9px 14px",
      fontSize: 12,
    },
  },
  paper: {
    width: "auto",
    height: "auto",
    maxHeight: "90%",
    maxWidth: "90vw",
    textAlign: "center",
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 10,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  button: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.blue.main,
    border: "1px solid",
    borderColor: theme.palette.blue.main,
    fontSize: "0.875rem",
    borderRadius: 10,
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
      borderColor: theme.palette.blue.main,
    },
  },
  icon: {
    marginRight: "0.375rem",
  },
  formBlock: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
    },
  },
  firstCol: {
    [theme.breakpoints.up("md")]: {
      marginRight: 37,
    },
  },
  label: {
    fontSize: 12,
    marginBottom: 6,
    color: theme.palette.gray.main,
    paddingLeft: 9,
  },
  modalFormControl: {
    marginBottom: 15,
    "& .MuiInputBase-root": {
      background: theme.palette.white.main,
    },
    "& .MuiOutlinedInput-input": {
      padding: "8.9px 14px",
      fontSize: 12,
    },
  },
  buttonn: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  backLink: {
    fontSize: 12,
    color: '#2F334D',
    display: "flex",
    alignItems: "center",
    cursor: "pointer"
  },
  backBox: {
    marginTop: "0.5rem",
    width: "fit-content"
  }

}));
