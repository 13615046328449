import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Box, Button } from "@material-ui/core";
import clsx from "clsx";
import { useTableStyles } from "../../../../styles/table.style";
import { useDispatch, useSelector } from "react-redux";
import { formatDate } from "../../../../helpers/utils";
import { PaiementsClient } from "../../../../components/componentToPrint/paiementsClient";
import { useReactToPrint } from "react-to-print";
import { useTranslation } from "react-i18next";
import { symbol } from "../../../../helpers/utils";
import { CommandeClient } from "../../../../components/componentToPrint/commandeClient";
import { LoadOrderByName } from "../../../../store/client/client.action";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "1.0625rem 1.5rem",
  },
  price: {
    fontWeight: 600,
  },
  filterList: {
    borderTop: "none",
  },
  print: {
    display: "none",
  },
}));

export const ClientPaiments = (props) => {
  const classes = useStyles();
  const tableClasses = useTableStyles();
  const componentRef = useRef();
  const dispatch = useDispatch();
  const [selectedRow, setSelectedRow] = React.useState(null);
  const paymentEntries = useSelector((state) => state.Client.paymentEntries);
  const currencies = useSelector((state) => state.Client.currencies);
  const detailClient = useSelector((state) => state.Checkout.detailClient);
  const globalDefaults = useSelector((state) => state.Login.globalDefaults);
  const order = useSelector((state) => state.Client.order);

  const { t } = useTranslation(["common", "clients", "order"]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => setSelectedRow(null),
  });
  const currencySymbol = (row) => {
    return row && row.paid_to_account_currency
      ? symbol(row.paid_to_account_currency, currencies)?.symbol
      : symbol(globalDefaults?.default_currency, currencies)?.symbol;
  };
  return (
    <Box className={clsx(classes.root, tableClasses.paper)}>
      <Table className={tableClasses.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={tableClasses.tablecellHeader} align="left">
              {t("common:date")}
            </TableCell>
            <TableCell className={tableClasses.tablecellHeader} align="left">
              {t("common:ref")}
            </TableCell>
            <TableCell className={tableClasses.tablecellHeader} align="left">
              Client
            </TableCell>
            <TableCell className={tableClasses.tablecellHeader} align="left">
              {t("common:prix")}
            </TableCell>
            <TableCell className={tableClasses.tablecellHeader} align="left">
              {t("common:statut")}
            </TableCell>
            <TableCell
              className={tableClasses.tablecellHeader}
              align="right"
            ></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.isArray(paymentEntries) &&
            paymentEntries.map((row, index) => (
              <>
                <TableRow className={tableClasses.tableRow} key={index}>
                  <TableCell className={tableClasses.tableCell} align="left">
                    {formatDate(row.creation, "YYYY-MM-DD")}
                  </TableCell>
                  <TableCell className={tableClasses.tableCell} align="left">
                    {row.name}
                  </TableCell>
                  <TableCell className={tableClasses.tableCell} align="left">
                    {row.customer_name}
                  </TableCell>
                  <TableCell
                    className={clsx(tableClasses.tableCell, classes.price)}
                    align="left"
                  >
                    {row.grand_total.toFixed(3)} {currencySymbol(row)}
                  </TableCell>
                  <TableCell className={tableClasses.tableCell} align="left">
                    {row.status}
                  </TableCell>
                  <TableCell
                    className={clsx(tableClasses.tableCell, classes.price)}
                    align="right"
                  >
                    <Button
                      size="small"
                      color="primary"
                      variant="contained"
                      onClickCapture={() => setSelectedRow(row)}
                      onClick={async () => {
                        await dispatch(
                          LoadOrderByName(row.name, "Sales%20Order")
                        );
                        handlePrint();
                      }}
                    >
                      Imprimer
                    </Button>
                  </TableCell>
                </TableRow>
                {selectedRow && (
                  <div className={classes.print}>
                    <CommandeClient
                      client={props.client}
                      document={selectedRow}
                      order={selectedRow}
                      detail={order}
                      title={t("common:order")}
                      ref={componentRef}
                    />
                  </div>
                )}
              </>
            ))}
        </TableBody>
      </Table>
    </Box>
  );
};
