import "./QuotationComponent.css";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../../../../components/loader";
import { useTranslation } from "react-i18next";
import i18n from "./../../../../i18n";

import Divider from "@mui/material/Divider";
import { set } from "date-fns/esm";

const RecapMaterialReq = ({ id }) => {
  const { t } = useTranslation();
  // fonction qui transforme le montant en lettres
  const convertirMontantEnLettres = (montant) => {
    let unites = [];
    let dizaines = [];
    if (i18n.language === "fr") {
      unites = [
        "",
        "un",
        "deux",
        "trois",
        "quatre",
        "cinq",
        "six",
        "sept",
        "huit",
        "neuf",
      ];
      dizaines = [
        "",
        "dix",
        "vingt",
        "trente",
        "quarante",
        "cinquante",
        "soixante",
        "soixante-dix",
        "quatre-vingts",
        "quatre-vingt-dix",
      ];
    } else if (i18n.language === "en") {
      unites = [
        "",
        "one",
        "two",
        "three",
        "four",
        "five",
        "six",
        "seven",
        "eight",
        "nine",
      ];
      dizaines = [
        "",
        "ten",
        "twenty",
        "thirty",
        "forty",
        "fifty",
        "sixty",
        "seventy",
        "eighty",
        "ninety",
      ];
    } else {
      unites = [
        "",
        "واحد",
        "اثنين",
        "ثلاثة",
        "اربعة",
        "خمسة",
        "ستة",
        "سبعة",
        "ثمانية",
        "تسعة",
      ];
      dizaines = [
        "",
        "عشرة",
        "عشرون",
        "ثلاثون",
        "اربعون",
        "خمسون",
        "ستون",
        "سبعون",
        "ثمانون",
        "تسعون",
      ];
    }

    const convertirGroupeEnLettres = (nombre) => {
      let resultat = "";

      const centaines = Math.floor(nombre / 100);
      const dizainesEtUnites = nombre % 100;

      if (i18n.language === "fr") {
        if (centaines > 0) {
          resultat += unites[centaines] + " cent ";
        }

        if (dizainesEtUnites >= 10 && dizainesEtUnites <= 19) {
          resultat += "et " + unites[dizainesEtUnites - 10] + " ";
        } else if (dizainesEtUnites >= 20) {
          resultat += dizaines[Math.floor(dizainesEtUnites / 10)] + " ";
          if (dizainesEtUnites % 10 !== 0) {
            resultat += unites[dizainesEtUnites % 10] + " ";
          }
        } else if (dizainesEtUnites > 0) {
          resultat += unites[dizainesEtUnites] + " ";
        }

        return resultat.trim();
      } else if (i18n.language === "en") {
        if (centaines > 0) {
          resultat += unites[centaines] + " hundred ";
        }

        if (dizainesEtUnites >= 10 && dizainesEtUnites <= 19) {
          resultat += "and " + unites[dizainesEtUnites - 10] + " ";
        } else if (dizainesEtUnites >= 20) {
          resultat += dizaines[Math.floor(dizainesEtUnites / 10)] + " ";
          if (dizainesEtUnites % 10 !== 0) {
            resultat += unites[dizainesEtUnites % 10] + " ";
          }
          return resultat.trim();
        } else if (dizainesEtUnites > 0) {
          resultat += unites[dizainesEtUnites] + " ";
        }

        return resultat.trim();
      } else {
        if (centaines > 0) {
          resultat += unites[centaines] + " مائة ";
        }

        if (dizainesEtUnites >= 10 && dizainesEtUnites <= 19) {
          resultat += "و " + unites[dizainesEtUnites - 10] + " ";
        } else if (dizainesEtUnites >= 20) {
          resultat += dizaines[Math.floor(dizainesEtUnites / 10)] + " ";
          if (dizainesEtUnites % 10 !== 0) {
            resultat += unites[dizainesEtUnites % 10] + " ";
          }
        } else if (dizainesEtUnites > 0) {
          resultat += unites[dizainesEtUnites] + " ";
        }

        return resultat.trim();
      }
    };

    const montantEntier = Math.floor(montant);
    const montantDecimal = Math.round((montant - montantEntier) * 1000);

    const milliards = Math.floor(montantEntier / 1000000000);
    const millions = Math.floor(
      (montantEntier - milliards * 1000000000) / 1000000
    );
    const milliers = Math.floor(
      (montantEntier - milliards * 1000000000 - millions * 1000000) / 1000
    );
    const restant = montantEntier % 1000;

    let resultat = "";

    if (i18n.language === "fr") {
      if (milliards > 0) {
        resultat += convertirGroupeEnLettres(milliards) + " milliard ";
      }

      if (millions > 0) {
        resultat += convertirGroupeEnLettres(millions) + " million ";
      }

      if (milliers > 0) {
        resultat += convertirGroupeEnLettres(milliers) + " mille ";
      }

      if (restant > 0) {
        resultat += convertirGroupeEnLettres(restant);
      }

      resultat += " dinars";

      if (montantDecimal > 0) {
        resultat +=
          " et " + convertirGroupeEnLettres(montantDecimal) + " millimes";
      }

      return resultat;
    } else if (i18n.language === "en") {
      if (milliards > 0) {
        resultat += convertirGroupeEnLettres(milliards) + " billion ";
      }

      if (millions > 0) {
        resultat += convertirGroupeEnLettres(millions) + " million ";
      }

      if (milliers > 0) {
        resultat += convertirGroupeEnLettres(milliers) + " thousand ";
      }

      if (restant > 0) {
        resultat += convertirGroupeEnLettres(restant);
      }

      resultat += " dinars";

      if (montantDecimal > 0) {
        resultat +=
          " and " + convertirGroupeEnLettres(montantDecimal) + " millims";
      }

      return resultat;
    } else {
      if (milliards > 0) {
        resultat += convertirGroupeEnLettres(milliards) + " مليار ";
      }

      if (millions > 0) {
        resultat += convertirGroupeEnLettres(millions) + " مليون ";
      }

      if (milliers > 0) {
        resultat += convertirGroupeEnLettres(milliers) + " ألف ";
      }

      if (restant > 0) {
        resultat += convertirGroupeEnLettres(restant);
      }

      resultat += " دينار";

      if (montantDecimal > 0) {
        resultat += " و " + convertirGroupeEnLettres(montantDecimal) + " مليم";
      }

      return resultat;
    }
  };
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [purpose, setPurpose] = useState("");
  const [sourceWarehouse, setSourceWarehouse] = useState("");
  const [targetWarehouse, setTargetWarehouse] = useState("");
  const [date, setDate] = useState("");
  const [state, setState] = useState({
    items: [],
  });
  const [error, setError] = useState({
    supplier: false,
    scheduleDate: false,
    scheduleDateTill: false,
    items: false,
    taxes: false,
  });

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Set loading to false whether data fetching succeeds or fails

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URI}/api/details`,
          {
            params: {
              doctype: "Material Request",
              name: id.name,
            },
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
              Authorization: JSON.parse(localStorage.getItem("user")).token,
            },
          }
        );

        // Operations to be executed after data fetching is completed successfully
        response.data["docs"].forEach((item) => {
          console.log("item", item);
          setPurpose(item.material_request_type);
          setDate(item.schedule_date);

          //setTaxCategorySelected(item.tax_category);
          item.items.map((item) => {
            state.items.push(item);
          });
        });
        setLoading(false); // Set loading to false whether data fetching succeeds or fails
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle error as needed
      } finally {
        setLoading(false); // Set loading to false whether data fetching succeeds or fails
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <div
      className="supplier-quotation"
      style={{
        width: "200mm",
        height: "297mm",
        margin: "auto",
      }}
    >
       {/* <div
        onClick={() => {
          var bouton = document.getElementById("monBouton");
          bouton.parentNode.removeChild(bouton);
          window.print();
        }}
      >
        <svg
        // style={{ marginTop: "20px" }}
          className="print-icon"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          id="monBouton"
          height="24"
          viewBox="0 0 24 24"
        >
          <path d="M19 8H5c-1.66 0-3 1.34-3 3v6h4v4h12v-4h4v-6c0-1.66-1.34-3-3-3zm-3 11H8v-5h8v5zm3-7c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm-1-9H6v4h12V3z" />
        </svg>
      </div> */}
      <div style={{ margin: "15px",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start", }}>
        <div
          // className="header"
          style={{
            fontSize: "24px",
            fontWeight: 700,
            textAlign: "right",
            color: "rgb(102, 102, 102)",
            lineHeight: "31.2px",
            fontFamily: "Helvetica Neue",
            textRendering: "optimizeLegibility",
            textSizeAdjust: "100%",
            textTransform: "uppercase",
            marginTop: "60px",
          }}
        >
          {t("buying:MaterialRequest")}
        </div>
        <div
            style={{
              fontSize: "19px",
              fontWeight: 500,
              textAlign: "right",
              color: "rgb(102, 102, 102)",
              lineHeight: "31.2px",
              fontFamily: "Helvetica Neue",
              textRendering: "optimizeLegibility",
              textSizeAdjust: "100%",
              textTransform: "uppercase",
            }}
        >
          {id.name}
        </div>
      </div>
      <Divider orientation="horizontal" fullWidth sx={{ marginTop: "10px" }} />
      <br />
      <br />
      {/* <div style={{ marginTop: "-10px", marginLeft: "60%" }}>
        <span style={{ fontSize: "14px", fontWeight: "bold" }}>
          {t("buying:DateTransaction")} :
        </span>
        &nbsp;
        <span>{date}</span>
      </div> */}
      <div style={{ marginLeft: "40px", width: "100%" }}>
        <div>
          <span style={{ fontSize: "15px", fontWeight: "bold" }}>
            {t("common:purpose")} :
          </span>
          <span style={{ marginLeft: 20 }}> {purpose}</span>
        </div>
        <br />
        <br />
        <div>
          <span style={{ fontSize: "15px", fontWeight: "bold" }}>
            {t("buying:DateTransaction")} :
          </span>
          <span style={{ marginLeft: 20 }}> {date}</span>
        </div>
        <br />
        <br />

        <div style={{ marginLeft: 350, width: "100%", marginTop: -90 }}>
          <div>
            <span style={{ fontSize: "14px", fontWeight: "bold" }}>
              {t("common:schedule_date")} :
            </span>
            <span style={{ marginLeft: 20 }}> {id.schedule_date}</span>
          </div>
          <br />
          <br />
          <div>
            <span style={{ fontSize: "14px", fontWeight: "bold" }}>
              {t("common:SourceWarehouse")} :
            </span>{" "}
            <span style={{ marginLeft: 20 }}> {id.set_warehouse}</span>
          </div>
          <br />
          <br />
          {console.log("ghazi",id.set_from_warehouse)}
          {(id.set_from_warehouse )? (
            <div>
              <span style={{ fontSize: "14px", fontWeight: "bold" }}>
                {t("common:TargetWarehouse")} :
              </span>{" "}
              <span style={{ marginLeft: 130 }}> {id.set_from_warehouse}</span>
            </div>
          ) : (<div></div>)}
        </div>
      </div>
      <br />
      <div
        className="items"
        style={{
          marginTop: "40px",
          marginLeft: "20px",
          marginRight: "40px",
          width: "95%",
        }}
      >
        <table style={{ borderCollapse: "collapse" }}>
          <thead style={{ backgroundColor: "#eeeeee", color: "black" }}>
            <tr>
              <th style={{ border: "1px solid black" }}>{t("common:sr")}</th>
              <th style={{ border: "1px solid black" }}>
                {t("product:itemcode")}
              </th>
              <th style={{ border: "1px solid black" }}>
                {t("common:schedule_date")}
              </th>
              <th style={{ border: "1px solid black" }}>
                {t("common:Description")}
              </th>
              <th style={{ border: "1px solid black" }}>{t("common:qty")}</th>
              <th style={{ border: "1px solid black" }}>
                {t("common:mgsSource")}
              </th>
              <th style={{ border: "1px solid black" }}>
                {t("common:mgsCible")}
              </th>
            </tr>
          </thead>
          <tbody>
            {state.items.map(
              (item, index) => (
                console.log("prouit ****", item),
                (
                  <tr key={index}>
                    <td
                      style={{ border: "1px solid black", textAlign: "center" }}
                    >
                      {index + 1}
                    </td>
                    <td
                      style={{ border: "1px solid black", textAlign: "center" }}
                    >
                      {item.item_code}
                    </td>
                    <td
                      style={{ border: "1px solid black", textAlign: "center" }}
                    >
                      {item.schedule_date}
                    </td>
                    <td style={{ border: "1px solid black" }}>
                      {item.description}
                    </td>
                    <td
                      style={{ border: "1px solid black", textAlign: "center" }}
                    >
                      {item.qty}
                    </td>

                    <td
                      style={{ border: "1px solid black", textAlign: "center" }}
                    >
                      {item.warehouse}
                    </td>
                    <td
                      style={{ border: "1px solid black", textAlign: "center" }}
                    >
                      {item.cost_center}
                    </td>
                  </tr>
                )
              )
            )}
          </tbody>
          {/* <tfoot>
            <tr>
              <td
                colSpan="3"
                style={{ border: "1px solid black", textAlign: "center" }}
              >
                {t("common:qtyTotal")}
              </td>
              <td style={{ border: "1px solid black", textAlign: "center" }}>
                {state.items.reduce((total, item) => total + item.qty, 0)}
              </td>
              <td
                colSpan="4"
                style={{ border: "1px solid black", textAlign: "center" }}
              >
                {t("common:total")}
              </td>
              <td style={{ border: "1px solid black", textAlign: "center" }}>
                {state.items
                  .reduce((total, item) => total + parseFloat(item.amount), 0)
                  .toFixed(3)}
              </td>
            </tr>
          </tfoot> */}
        </table>
      </div>
      
    </div>
  );
};

export default RecapMaterialReq;
