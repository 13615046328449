export const GET_FULLFILMENTS = "GET_FULLFILMENTS";
export const FULLFILMENTS_LOADED = "FULLFILMENTS_LOADED";

export const UPDATE_ORDER_STATUS = "UPDATE_ORDER_STATUS";
export const UPDATE_ORDER_STATUS_SUCCESS = "UPDATE_ORDER_STATUS_SUCCESS";

export const UPDATE_MANY_ORDER_STATUS = "UPDATE_MANY_ORDER_STATUS";
export const UPDATE_MANY_ORDER_STATUS_SUCCESS =
  "UPDATE_MANY_ORDER_STATUS_SUCCESS";

export const GET_FULLFILMENTS_PACKS = "GET_FULLFILMENTS";
export const FULLFILMENTS_PACKS_LOADED = "FULLFILMENTS_LOADED";

export const CREATE_FULLFILMENTS_PACKS_SUCCESS =
  "CREATE_FULLFILMENTS_PACKS_SUCCESS";
export const CREATE_FULLFILMENTS_PACKS = "CREATE_FULLFILMENTS_PACKS";

export const FULFILLMENT_LOADED = "FULFILLMENT_LOADED";
export const GET_FULLFILMENTS_BY_ID = "GET_FULLFILMENTS_BY_ID";

export const SET_ORDERS_TO_PICK = "SET_ORDERS_TO_PICK";
export const ORDER_CREATED_BRODCAST = "ORDER_CREATED_BRODCAST";
export const SET_FULLFILMENT = "SET_FULLFILMENT";

export const ITEM_LOCATION_LOADED = "ITEM_LOCATION_LOADED";
export const LOAD_ITEM_LOCATION = "LOAD_ITEM_LOCATION";
export const SEND_STATUS_EMAIL = "SEND_STATUS_EMAIL";
export const CREATE_STATUS_EMAIL_SUCCESS = "CREATE_STATUS_EMAIL_SUCCESS";
export const CREATE_DELIVERY_NOTE_PACKING = "CREATE_DELIVERY_NOTE_PACKING";
export const CREATE_DELIVERY_NOTE_PACKING_SUCCESS =
  "CREATE_DELIVERY_NOTE_PACKING_SUCCESS";
export const SEND_EMAIL = "SEND_EMAIL";
export const CREATE_EMAIL_SUCCESS = "CREATE_EMAIL_SUCCESS";

export const REJECTION_ORDER = "REJECTION_ORDER";
export const REJECTION_ORDER_SUCCESS = "REJECTION_ORDER_SUCCESS";

export const CANCEL_SALES_ORDER = "CANCEL_SALES_ORDER";
export const CANCEL_SALES_ORDER_SUCCESS = "CANCEL_SALES_ORDER_SUCCESS";
export const SET_PACKING_SELECTED = "SET_PACKING_SELECTED";

export const SET_MAPPED_DELIVERYNOTE = "SET_MAPPED_DELIVERYNOTE";

export const SALES_ORDER_REASON = "SALES_ORDER_REASON";
export const UPDATE_SALES_ORDER_REASON_SUCCESS =
  "UPDATE_SALES_ORDER_REASON_SUCCESS";
