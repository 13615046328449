import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Box from "@material-ui/core/Box";
import clsx from "clsx";
import { StyledCheckbox } from "../../../../../components/styledCheckbox";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { useStyles } from "../../../style";
import { useTableStyles } from "../../../../../theme/table.style";
import { useDispatch, useSelector } from "react-redux";
import {
  SetOrdersToPick,
  UpdateOrderStatus,
} from "../../../../../store/fullfilment/fullfilment.action";
import { useHistory } from "react-router-dom";
import { formatDate, statusFormat } from "../../../../../helpers/utils";
import { useTranslation } from "react-i18next";

export const FulfillementTable = (props) => {
  const classes = useStyles();
  const tableclasses = useTableStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const globalDefaults = useSelector((state) => state.Login.globalDefaults);

  const { t } = useTranslation(["order", "common", "fulfillment"]);
  const GetButtonInfo = (status, id) => {
    switch (true) {
      case status == "prepared":
        return { text: "pick up", link: `/fulfillement/picking` };
      case status == "picked" || status == "in packing":
        return { text: "pack", link: `/fulfillement/packing/${id}` };
      case status == "verified" || status == "in prepare":
        return { text: "Fullfil", link: `/fulfillement/prepare/${id}` };
      case status == "packed" ||
        status == "to verify" ||
        status == "canceled" ||
        status == "cancel":
        return { text: "Voir détails", link: `/fulfillement/details/${id}` };
      default:
        return { text: "", link: "" };
    }
  };
  const handleButtonAction = (order) => {
    switch (true) {
      case order.status == "prepared":
        dispatch(
          SetOrdersToPick({ orders: [order.order_id], groupBy: "groupByOrder" })
        );
        history.push("/fulfillement/picking");
        break;
      case order.status == "picked" || order.status == "in packing":
        history.push(`/fulfillement/packing/${order.id}`);
        break;
      case order.status == "verified" || order.status == "in prepare":
        history.push(`/fulfillement/prepare/${order.id}`);
        break;
      case order.status == "packed" ||
        order.status == "to verify" ||
        order.status == "canceled" ||
        order.status == "cancel":
        history.push(`/fulfillement/details/${order.id}`);
        break;
      default:
        break;
    }
  };

  const rAllChecked = (itemsCkecked) => {
    let trueCount = 0;
    for (const key in itemsCkecked) {
      if (itemsCkecked.hasOwnProperty(key) && itemsCkecked[key] === true) {
        trueCount++;
      }
    }

    if (props?.fullfilments?.length === trueCount) return true;
    return false;
  };

  return (
    <Box className={tableclasses.paper}>
      <Table className={tableclasses.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            {props.tab !== 1 && props.tab !== 3 && props.tab !== 4 && (
              <TableCell className={tableclasses.tablecellHeader}>
                <StyledCheckbox
                  checked={rAllChecked(props.itemsCkecked)}
                  onChange={props.handleCheckboxChange}
                  name={"check-all"}
                />
              </TableCell>
            )}
            <TableCell className={tableclasses.tablecellHeader} align="left">
              ID
            </TableCell>
            <TableCell className={tableclasses.tablecellHeader} align="left">
              {t("common:client")}
            </TableCell>
            <TableCell className={tableclasses.tablecellHeader} align="left">
              {t("fulfillment:saleDate")}
            </TableCell>
            <TableCell className={tableclasses.tablecellHeader} align="left">
              {t("fulfillment:delDate")}
            </TableCell>
            <TableCell className={tableclasses.tablecellHeader} align="left">
              {t("common:grandTotal")}
            </TableCell>
            <TableCell className={tableclasses.tablecellHeader} align="left">
              {t("common:statut")}
            </TableCell>
            <TableCell className={tableclasses.tablecellHeader} align="left">
              {t("common:Action")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.fullfilments.map((row, index) => (
            <TableRow
              className={clsx({
                [tableclasses.tableRow]: true, //always applies
                [tableclasses.active]:
                  props.itemsCkecked && props.itemsCkecked[row.order_id]
                    ? props.itemsCkecked[row.order_id]
                    : false, //only when open === true
              })}
              key={index}
            >
              {props.tab !== 1 && props.tab !== 3 && props.tab !== 4 && (
                <TableCell className={tableclasses.tableCell} scope="row">
                  <StyledCheckbox
                    checked={
                      props.itemsCkecked && props.itemsCkecked[row.order_id]
                        ? props.itemsCkecked[row.order_id]
                        : false
                    }
                    onChange={props.handleCheckboxChange}
                    name={row.order_id}
                  />
                </TableCell>
              )}
              <TableCell className={tableclasses.tableCell} align="left">
                {row.order_id}
              </TableCell>
              <TableCell className={tableclasses.tableCell} align="left">
                {row.customer_name}
              </TableCell>
              <TableCell className={tableclasses.tableCell} align="left">
                {formatDate(row.sales_date, "YYYY-MM-DD")}
              </TableCell>
              <TableCell className={tableclasses.tableCell} align="left">
                {formatDate(row.delivery_date, "YYYY-MM-DD")}
              </TableCell>
              <TableCell className={tableclasses.tableCell} align="left">
                {parseFloat(row.grand_total).toFixed(3)}{" "}
                {row.currency ? row.currency : globalDefaults?.default_currency}
              </TableCell>
              <TableCell
                className={clsx(
                  tableclasses.tableCell,
                  tableclasses[statusFormat(row.status).color]
                )}
                align="left"
              >
                {statusFormat(row.status).value}
              </TableCell>
              <TableCell className={tableclasses.tableCell} align="left">
                <Link to={GetButtonInfo(row.status, row.id).link}>
                  <Button
                    onClick={() => {
                      handleButtonAction(row);
                    }}
                    className={clsx({
                      [classes.button]: true, //always applies
                      [classes.buttonFulfill]: row.status == "verified",
                      [classes.buttonDetails]: row.status == "Completé", //only when open === true
                    })}
                    size={"small"}
                    variant="outlined"
                    // onClick={()=> handleUpdateOrder(row.status,row.id)}
                    color="primary"
                  >
                    {GetButtonInfo(row.status, row.id).text}
                  </Button>
                </Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};
