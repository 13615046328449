import moment from "moment";
import {
  UPDATE_SAFEBOX,
  CLOSED_ENTRY_LOADED,
  GET_CLOSED_ENTRY,
  CREATE_CLOSE_POS_ENTRY,
  CREATE_CLOSE_POS_ENTRY_SUCCESS,
  POS_INVOICES_LOADED,
  GET_POS_INVOICES,
  SUCCESS_GET_CLOSED_POS_BY_ID,
  GET_CLOSED_POS_BY_ID,
  CREATE_SESSION,
  CREATE_SESSION_SUCCESS,
  RESET_CLOSE_ENTRY,
  SESSION_LOADED,
  CREATE_COFFRE_OP,
  COFFRE_OP_CREATED,
  LOAD_COFFRE_OP,
  COFFRE_OP_LOADED,
  LOAD_CLOSED_POS,
  COFFRE_OP_LOADED_ID,
  LOAD_COFFRE_OP_ID,
} from "./pos.type";
import { FAILED_ADD_DATA } from "../rollback/rollback.type";
import { SetNotification } from "../notification/notification.action";
import axiosInstance from "../../history/axiosInstance";

export const OpenSession = (content, company, default_currency) => ({
  type: CREATE_SESSION,
  payload: content,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${process.env.REACT_APP_API_URI}/api/pos/open`,
        method: "POST",
        body: JSON.stringify({
          pos_profile: content.pos,
          company: `${company}`,
          balance_details: [
            {
              mode_of_payment: `${process.env.REACT_APP_PAYEMENT_METHOD}`,
              opening_amount: content.amount,
            },
          ],
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
          Authorization: JSON.parse(localStorage.getItem("user")).token,
        },
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: CREATE_SESSION_SUCCESS,
        meta: {
          notification: {
            code: "success",
            message: "notification:posOpening",
            values: ` (${content.amount} ${default_currency})`,
          },
        },
      },
      // action to dispatch if network action fails permanently:
    },
  },
});
export function updateSession(open) {
  return (dispatch, getState) => {
    dispatch({
      type: SESSION_LOADED,
      open: open,
    });
  };
}

export const LoadClosingEntry = () => ({
  type: GET_CLOSED_ENTRY,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${process.env.REACT_APP_API_URI}/api/pos-closing-entry`,
        method: "POST",
        body: JSON.stringify({
          doctype: "POS Closing Entry",
          fields: [
            "`tabPOS Closing Entry`.`name`",
            "`tabPOS Closing Entry`.`owner`",
            "`tabPOS Closing Entry`.`modified`",
            "`tabPOS Closing Entry`.`status`",
            "`tabPOS Closing Entry`.`pos_profile`",
          ],
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
          Authorization: JSON.parse(localStorage.getItem("user")).token,
        },
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: CLOSED_ENTRY_LOADED,
        meta: "",
      },
    },
  },
});

export const LoadPOSInvoices = (content, user) => ({
  type: GET_POS_INVOICES,
  payload: content,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${process.env.REACT_APP_API_URI}/api/pos/pos-closing/get-pos-invoice`,
        method: "POST",
        body: JSON.stringify({
          start: content.period_start_date,
          end: moment().format("YYYY-MM-DDTHH:mm:ss"),
          pos_profile: content.pos_profile,
          user: user,
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
          Authorization: JSON.parse(localStorage.getItem("user")).token,
        },
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: POS_INVOICES_LOADED,
        meta: "",
      },
    },
  },
});

export const posClosingEntry = (
  content,
  caisseBalance,
  cushFund,
  company,
  itemTax
) => {
  return function (dispatch) {
    const header = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
        Authorization: JSON.parse(localStorage.getItem("user")).token,
      },
    };
    const data = {
      start: content?.period_start_date,
      end: moment().format("YYYY-MM-DDTHH:mm:ss"),
      pos_profile: content?.pos_profile,
      user: content?.user,
    };
    return axiosInstance
      .post(
        `${process.env.REACT_APP_API_URI}/api/pos/pos-closing/get-pos-invoice`,
        data,
        header
      )
      .then(({ data }) => {
        let payment_reconciliation = [
          {
            docstatus: 0,
            doctype: "POS Closing Entry Detail",
            closing_amount: 0,
            parentfield: "payment_reconciliation",
            parenttype: "POS Closing Entry",
            mode_of_payment: "Cash",
            opening_amount: parseFloat(cushFund),
            expected_amount: parseFloat(cushFund),
            difference: 0,
          },
        ];
        data.message.map((inv, index) => {
          inv.payments.map((p) => {
            const ItemIndex = payment_reconciliation.findIndex(
              (s) => s.mode_of_payment == p.mode_of_payment
            );
            if (ItemIndex != -1) {
              payment_reconciliation[ItemIndex].expected_amount =
                parseFloat(payment_reconciliation[ItemIndex].expected_amount) +
                p.amount;
              payment_reconciliation[ItemIndex].difference =
                0 -
                parseFloat(payment_reconciliation[ItemIndex].expected_amount);
              payment_reconciliation[ItemIndex].currency = inv.currency;
            } else {
              payment_reconciliation.push({
                docstatus: 0,
                doctype: "POS Closing Entry Detail",
                closing_amount: 0,
                parentfield: "payment_reconciliation",
                parenttype: "POS Closing Entry",
                mode_of_payment: p.mode_of_payment,
                opening_amount: 0,
                expected_amount: p.amount,
                difference: 0 - parseFloat(p.amount),
                currency: inv.currency,
              });
            }
          });
        });

        let taxes = [];

        if (Array.isArray(itemTax)) {
          itemTax?.forEach((el) => {
            taxes.push({
              docstatus: 0,
              doctype: "POS Closing Entry Taxes",
              owner: content.owner,
              parentfield: "taxes",
              parenttype: "POS Closing Entry",
              account_head: el?.tax_type,
              rate: el?.tax_rate,
              amount: 0,
            });
          });
        }

        data.message.map((inv, index) => {
          inv.taxes.map((t) => {
            const ItemIndex = taxes.findIndex(
              (s) => s.account_head == t.account_head
            );
            if (ItemIndex != -1) {
              taxes[ItemIndex].amount =
                parseFloat(taxes[ItemIndex].amount) + t.tax_amount;
              taxes[ItemIndex].currency = inv.currency;
            } else {
              taxes.push({
                docstatus: 0,
                doctype: "POS Closing Entry Taxes",
                owner: content.owner,
                parentfield: "taxes",
                parenttype: "POS Closing Entry",
                account_head: t.account_head,
                rate: t.rate,
                amount: t.tax_amount,
                currency: inv.currency,
              });
            }
          });
        });

        let pos_transactions = [];
        data.message.map((inv) =>
          pos_transactions.push({
            doctype: "POS Invoice Reference",
            owner: inv.owner,
            is_return: inv.is_return,
            parentfield: "pos_transactions",
            parenttype: "POS Closing Entry",
            pos_invoice: inv.name,
            posting_date: inv.posting_date,
            grand_total: inv.grand_total,
            customer: inv.customer_name,
            currency: inv.currency,
          })
        );

        let total_quantity = 0;
        data.message.map(
          (inv) => (total_quantity = total_quantity + inv.total_qty)
        );

        const doc = {
          doctype: "POS Closing Entry",
          period_end_date: moment().format("YYYY-MM-DDTHH:mm:ss"),
          posting_date: content.posting_date,
          grand_total: data.message.reduce(
            (a, v) => (a = a + v.grand_total),
            0
          ),
          net_total: data.message.reduce((a, v) => (a = a + v.net_total), 0),
          pos_profile: content.pos_profile,
          pos_opening_entry: content.name,
          period_start_date: content.period_start_date,
          company: `${company}`,
          owner: content.owner,
          user: content.user,
          pos_transactions: pos_transactions,
          payment_reconciliation: payment_reconciliation,
          taxes: taxes,
          total_quantity: total_quantity,
          closing_amount: parseFloat(caisseBalance),
        };

        let header = {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
            Authorization: JSON.parse(localStorage.getItem("user")).token,
          },
        };
        let reqData = JSON.stringify({
          doc: doc,
          action: "Submit",
        });
        return axiosInstance
          .post(
            `${process.env.REACT_APP_API_URI}/api/pos/pos-closing/doc-submit`,
            reqData,
            header
          )
          .then(({ data }) => {
            dispatch(submitClosingPosDoc(data));
          })
          .catch((error) => {
            dispatch({
              type: FAILED_ADD_DATA,
            });
          });
      });
  };
};

const submitClosingPosDoc = (doc) => {
  return {
    type: CREATE_CLOSE_POS_ENTRY_SUCCESS,
    meta: {
      notification: {
        code: "success",
        message: "notification:posClosing",
      },
    },
    doc: doc,
  };
};

export const LoadClosedPosById = (id) => ({
  type: GET_CLOSED_POS_BY_ID,
  payload: id,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${process.env.REACT_APP_API_URI}/api/report-z`,
        method: "POST",
        body: JSON.stringify({
          doctype: "POS Closing Entry",
          name: id,
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
          Authorization: JSON.parse(localStorage.getItem("user")).token,
        },
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: SUCCESS_GET_CLOSED_POS_BY_ID,
        meta: "",
      },
    },
  },
});

export const ResetClose = () => {
  return {
    type: RESET_CLOSE_ENTRY,
  };
};

export const updateSafeBox = (amount, date) => {
  return {
    type: UPDATE_SAFEBOX,
    amount: amount,
    date: date,
  };
};

export const SetClosedPOS = (closedEntry) => {
  return {
    type: LOAD_CLOSED_POS,
    closedEntry: closedEntry,
  };
};

export const createCoffreOperation = (content, default_currency) => ({
  type: CREATE_COFFRE_OP,
  payload: content,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${process.env.REACT_APP_API_URI}/api/save-coffre-operation`,
        method: "POST",
        body: JSON.stringify(content),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
          Authorization: JSON.parse(localStorage.getItem("user")).token,
        },
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: COFFRE_OP_CREATED,
        meta: {
          then: (payload) => (dispatch, getState) => {
            dispatch(
              SetNotification({
                code: "success",
                message: "notification:coffreDeposit",
                values: ` (${content.amount} ${default_currency})`,
              })
            );
          },
        },
      },
    },
  },
});

export const loadCoffreOperation = (content) => ({
  type: LOAD_COFFRE_OP,
  payload: content,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${process.env.REACT_APP_API_URI}/api/coffre-operations`,
        method: "POST",
        body: JSON.stringify(content),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
          Authorization: JSON.parse(localStorage.getItem("user")).token,
        },
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: COFFRE_OP_LOADED,
        meta: "",
      },
    },
  },
});

export const loadCoffreOperationById = (content) => ({
  type: LOAD_COFFRE_OP_ID,
  payload: content,
  meta: {
    offline: {
      // the network action to execute:
      effect: {
        url: `${process.env.REACT_APP_API_URI}/api/coffre-operation`,
        method: "POST",
        body: JSON.stringify(content),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
          Authorization: JSON.parse(localStorage.getItem("user")).token,
        },
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: COFFRE_OP_LOADED_ID,
        meta: "",
      },
    },
  },
});
