export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAIL = "LOGIN_USER_FAIL";
export const LOGOUT_USER = "LOGOUT_USER";
export const LOAD_STORES = "LOAD_STORES";
export const LOAD_STORES_SUCCESS = "LOAD_STORES_SUCCESS";
export const LOAD_POS = "LOAD_POS";
export const LOAD_POS_SUCCESS = "LOAD_POS_SUCCESS";
export const SET_SELECTED_CASHIER = "SET_SELECTED_CASHIER";
export const SET_CUSH_FUND = "SET_CUSH_FUND";
export const LOAD_POS_OPENINGS = "LOAD_POS_OPENINGS";
export const LOAD_POS_OPENINGS_SUCCESS = "LOAD_POS_OPENINGS_SUCCESS";
export const SET_POS_OPENED_DATE = "SET_POS_OPENED_DATE";
export const LOGOUT = "LOGOUT";
export const SET_SIGNATURE = "SET_SIGNATURE";
export const OPEN_SIGNATURE = "OPEN_SIGNATURE";
export const SET_USER = "SET_USER";
export const SET_GLOBAL_DEFAULTS = "SET_GLOBAL_DEFAULTS";
export const SET_USER_DETAILS = "SET_USER_DETAILS";
export const CLEAR_STATE = "CLEAR_STATE";
