import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  main: {
    padding: "0px 50px",
    fontSize: 12,
    fontFamily: "system-ui",
  },
  header: {
    padding: "60px 0",
    marginBottom: 60,
  },
  logo: {
    textAlign: "center",
    marginBottom: 10,
  },
  title: {
    borderTop: "1px solid  #5D6975",
    borderBottom: "1px solid  #5D6975",
    color: "#5D6975",
    fontSize: "30px",
    lineHeight: "1.4em",
    fontWeight: "normal",
    textAlign: "center",
    margin: "0 0 20px 0",
  },
  project: {
    float: "left",
    marginTop: 30,
    marginBottom: 60,
  },
  table: {
    width: "100%",
    marginTop: -60,
  },
  tableheader: {
    padding: "5px 5px",
    background: "#E5E5E5",
    border: "1px solid #C1CED9",
    fontWeight: "normal",
  },
  tableColumn: {
    padding: 10,
    textAlign: "right",
    border: "1px solid #C1CED9",
  },
  ref: {
    width: "60%",
    marginTop: 5,
  },
  headtable: {
    border: " 1px solid #C1CED9 ",
    background: "#E5E5E5",
    fontWeight: "normal",
  },
  columntable: {
    padding: 10,
    textAlign: "center",
    border: "1px solid #C1CED9",
  },
  pay: {
    width: "60%",
    display: "inline-table",
    tableLayout: "fixed",
  },
  comp: {
    width: "80%",
  },
  address: {
    paddingTop: "40px",
  },
  item: {
    color: "#5D6975",
    textAlign: "left",
    width: "52px",
    fontSize: "0.8em",
  },
  company: {
    float: "left",
    marginTop: 30,
    marginBottom: 60,
  },
  float: {
    width: "40%",
    height: "50%",
    marginRight: -60,
    textAlign: "left",
  },
}));
