import { Box} from "@material-ui/core";
import React from "react";
import { Form } from "../components/form";
import { Header } from "../components/header";

export const CreateStockEntry = () => {
  return (
    <Box>
      <Header/>
      <Form />
    </Box>
  );
};
