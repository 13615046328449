import React from 'react';
import { useTableStyles } from "../../../styles/table.style";
import {useTranslation} from "react-i18next";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {Box} from "@material-ui/core";
import {useStyles} from "./style";
import clsx from "clsx";
import { useSelector } from "react-redux";


export const InventoryToPrint = React.forwardRef((props, ref) => {
    const classes = useStyles();
    const tableClasses = useTableStyles();
    const params = useSelector((state) => state.Inventory.params);
    const activeImageTheme = useSelector(state => state.Theme.image);
    const globalDefaults = useSelector(state => state.Login.globalDefaults);

    const {t} = useTranslation(["inventory","common", "product"]);
    function removeHTML(str){ 
      var tmp = document.createElement("DIV");
      tmp.innerHTML = str;
      return tmp.textContent || tmp.innerText || "";
    }
 
    return (
     <div className={classes.main} ref={ref}>
        <header className={classes.header}>
          <div  className={classes.logo}>
            <img src={activeImageTheme}/>
          </div>
          <h1 className={classes.title}>{props.invList && props.title + ' ' +  props.invList.list_id} </h1>
          <div className={classes.project}>
            <div><span className={classes.projectitem}>{t("common:dateCreation")}: </span>{props.invList.creation_date}</div>
            <div><span className={classes.projectitem}>{t("common:type")}: </span>{props.invList.list_type}</div>
            {props.invList.inventory_period && <div><span className={classes.projectitem}>{t("inventory:inventoryPeriod")}: </span>{JSON.parse(props.invList.inventory_period)[0]} -- {JSON.parse(props.invList.inventory_period)[1]}</div>}
            <div><span className={classes.projectitem}>{t("common:statut")}: </span>{props.invList.status}</div>
            {props.invList.sales_person && <div><span className={classes.projectitem}>{t("inventory:vendeur")}: </span>{props.invList.sales_person}</div> } 
          </div>
         </header>
        <Box >
          <Table aria-label="simple table">
            <TableHead>
                <TableRow>
                  {params.ref && (<TableCell className={tableClasses.tablecellHeader} align="left">{t("common:ref")}</TableCell>)}
                  {params.nameProd && (<TableCell className={tableClasses.tablecellHeader} align="left">{t("common:produit")}</TableCell>)}
                  {params.qteReel && ( <TableCell className={tableClasses.tablecellHeader} align="left">{t("common:qteReel")}</TableCell>)}
                  {params.qteProj && (  <TableCell className={tableClasses.tablecellHeader} align="left">{t("common:qteProjetee")}</TableCell>)}
                  {params.qteResv && (  <TableCell className={tableClasses.tablecellHeader} align="left">{t("common:qteReservee")}</TableCell>)}
                  {params.magasin && (  <TableCell className={tableClasses.tablecellHeader} align="left">{t("common:magasin")}</TableCell>)}
                  {params.group && ( <TableCell className={tableClasses.tablecellHeader} align="left">{t("common:ProductGroup")}</TableCell>)}
                  {params.price && ( <TableCell className={tableClasses.tablecellHeader} align="left">{t("inventory:productPrice")}</TableCell>)}
                  {params.collection && ( <TableCell className={tableClasses.tablecellHeader} align="left">{t("inventory:collection")}</TableCell>)}
                  {params.openingStock && ( <TableCell className={tableClasses.tablecellHeader} align="left">{t("common:stockOuverture")}</TableCell>)}
                  {params.location && ( <TableCell className={tableClasses.tablecellHeader} align="left">{t("inventory:emplacement")}</TableCell>)}
                  {params.originCountry && ( <TableCell className={tableClasses.tablecellHeader} align="left">{t("inventory:originProduct")}</TableCell>)}
                  {params.description && ( <TableCell className={tableClasses.tablecellHeader} align="left">{t("common:Description")}</TableCell>)} 
                  {params.isStock && ( <TableCell className={tableClasses.tablecellHeader} align="left">{t("product:inStock")}</TableCell>)}
                  {params.qteTrouv && ( props.invList.status == "Finished" || props.invList.status == "Closed") && (<TableCell className={clsx(tableClasses.tablecellHeader, classes.qtyControl)} align="center">{t("inventory:quantiteTrouvee")}</TableCell>)}
                </TableRow>
            </TableHead>
            <TableBody>
            {props.invList.status != "Finished" && props.invList.status != "Closed"? 
              props.invList.products.length != 0 && props.invList.products.map((row, index) => (
                <TableRow className={tableClasses.tableRow} key={index}>
                  {params.ref && (<TableCell className={tableClasses.tableCell} align="left">{row}</TableCell>)}
                  {params.nameProd && (<TableCell className={tableClasses.tableCell} align="left">{props.inventory.find(((s) => s.item_code == row)) ? props.inventory.find(((s) => s.item_code == row)).item_name : ""}</TableCell>)}
                  {params.qteReel && (<TableCell className={tableClasses.tableCell} align="left">{props.inventory.find(((s) => s.item_code == row)) ? props.inventory.find(((s) => s.item_code == row)).actual_qty : ""}</TableCell>)}
                  {params.qteProj && (<TableCell className={tableClasses.tableCell} align="left">{props.inventory.find(((s) => s.item_code == row)) ? props.inventory.find(((s) => s.item_code == row)).projected_qty : ""}</TableCell>)}
                  {params.qteResv && ( <TableCell className={tableClasses.tableCell} align="left">{props.inventory.find(((s) => s.item_code == row)) ? props.inventory.find(((s) => s.item_code == row)).reserved_qty : ""}</TableCell>)}
                  {params.magasin && (<TableCell className={tableClasses.tableCell} align="left">{props.inventory.find(((s) => s.item_code == row)) ? props.inventory.find(((s) => s.item_code == row)).warehouse : ""}</TableCell>)}
                  {params.group && ( <TableCell className={tableClasses.tableCell} align="left">{props.fetchFromProd(row).item_group}</TableCell>)}
                  {params.price && ( <TableCell className={tableClasses.tableCell} align="left">{props.fetchPrice(row)} {globalDefaults?.default_currency}</TableCell>)}
                  {params.collection && ( <TableCell className={tableClasses.tableCell} align="left">{props.fetchFromProd(row).item_collection}</TableCell>)}
                  {params.openingStock && ( <TableCell className={tableClasses.tableCell} align="left">{props.fetchFromProd(row).opening_stock}</TableCell>)}
                  {params.location && ( <TableCell className={tableClasses.tableCell} align="left">{props.itemlocation.find(((s) => s.item_code == row)) ? props.itemlocation.find(((s) => s.item_code == row)).item_location : ""}</TableCell>)}
                  {params.originCountry && ( <TableCell className={tableClasses.tableCell} align="left">{props.fetchFromProd(row).country_of_origin}</TableCell>)}
                  {params.description && ( <TableCell className={tableClasses.tableCell} align="left">{removeHTML(props.fetchFromProd(row).description)}</TableCell>)} 
                  {params.isStock && ( <TableCell className={tableClasses.tableCell} align="left">{props.fetchFromProd(row).is_stock_item =="1" ? "oui" : "non"}</TableCell>)}
                  <TableCell/>         
                </TableRow>       
              ))
              :
              props.invList.products.length != 0 && props.invList.products.map((row, index) => (
                <TableRow className={tableClasses.tableRow} key={index}>
                  {params.ref && (<TableCell className={tableClasses.tableCell} align="left">{row.item_code}</TableCell>)}
                  {params.nameProd && (<TableCell className={tableClasses.tableCell} align="left">{props.inventory.find(((s) => s.item_code == row.item_code)) ? props.inventory.find(((s) => s.item_code == row.item_code)).item_name : ""}</TableCell>)}
                  {params.qteReel && (<TableCell className={tableClasses.tableCell} align="left">{props.inventory.find(((s) => s.item_code == row.item_code)) ? props.inventory.find(((s) => s.item_code == row.item_code)).actual_qty : ""}</TableCell>)}
                  {params.qteProj && (<TableCell className={tableClasses.tableCell} align="left">{props.inventory.find(((s) => s.item_code == row.item_code)) ? props.inventory.find(((s) => s.item_code == row.item_code)).projected_qty : ""}</TableCell>)}
                  {params.qteResv && (<TableCell className={tableClasses.tableCell} align="left">{props.inventory.find(((s) => s.item_code == row.item_code)) ? props.inventory.find(((s) => s.item_code == row.item_code)).reserved_qty : ""}</TableCell>)}
                  {params.magasin && (<TableCell className={tableClasses.tableCell} align="left">{props.inventory.find(((s) => s.item_code == row.item_code)) ? props.inventory.find(((s) => s.item_code == row.item_code)).warehouse : ""}</TableCell>)}
                  {params.group && ( <TableCell className={tableClasses.tableCell} align="left">{props.fetchFromProd(row.item_code).item_group }</TableCell>)}
                  {params.price && ( <TableCell className={tableClasses.tableCell} align="left">{props.fetchPrice(row.item_code)} {globalDefaults?.default_currency}</TableCell>)}
                  {params.collection && ( <TableCell className={tableClasses.tableCell} align="left">{props.fetchFromProd(row.item_code).item_collection}</TableCell>)}
                  {params.openingStock && ( <TableCell className={tableClasses.tableCell} align="left">{props.fetchFromProd(row.item_code).opening_stock}</TableCell>)}
                  {params.location && ( <TableCell className={tableClasses.tableCell} align="left">{props.itemlocation.find(((s) => s.item_code == row.item_code)) ? props.itemlocation.find(((s) => s.item_code == row.item_code)).item_location : ""}</TableCell>)}
                  {params.originCountry && ( <TableCell className={tableClasses.tableCell} align="left">{props.fetchFromProd(row).country_of_origin}</TableCell>)}
                  {params.description && ( <TableCell className={tableClasses.tableCell} align="left">{removeHTML(props.fetchFromProd(row.item_code).description)}</TableCell>)}
                  {params.isStock && ( <TableCell className={tableClasses.tableCell} align="left">{props.fetchFromProd(row.item_code).is_stock_item =="1" ? "oui" : "non"}</TableCell>)}
                  {params.qteTrouv && (<TableCell className={tableClasses.tableCell} align="center">{row.found_qty}</TableCell>)}
                  <TableCell/>
                </TableRow>     
              ))
            }
            </TableBody>
          </Table>
      </Box> 
      </div>
    );
  });
