import React, { useState } from "react";
import clsx from "clsx";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Button,
  Grid,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  Modal,
  Typography,
  Paper,
  FormControlLabel,
  Checkbox,
  TextField,
  FormHelperText,
} from "@material-ui/core";
import { useStyles } from "../../style";
import { useTableStyles } from "../../../../styles/table.style";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  createQuotationReq,
  LoadSupplierDetails,
  SetQuotationMappedDoc,
  CreateQuotationRequest,
} from "../../../../store/buying/buying.action";
import { Loader } from "../../../../components/loader";
import {
  CreateEmail,
  CreateRejection,
  LoadFullfilmentsOrderById,
  cancelsalesorder,
} from "../../../../store/fullfilment/fullfilment.action";

export const Cancellation = (props) => {
  const { order, setOpenModalCancellation, id } = props;
  console.log("order", order);
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation(["fulfillment", "buying", "common"]);
  const [message, setMessage] = useState("");
  const [errmessage, setErrmessage] = useState(false);
  const [submit, setSubmit] = useState(false);
  if (submit) return <Loader />;
  const handleSubmit = async () => {
    //update data base
    if (!message || message == "") {
      setErrmessage(true);
    } else {
      setSubmit(true);
      await dispatch(CreateRejection(id, "canceled", message, order?.order_id));
      await dispatch(
        CreateEmail(
          order?.customer_email,
          message,
          order?.order_id,
          order?.customer_name
        )
      );
      // await dispatch(cancelsalesorder(order?.order_id));
      await dispatch(LoadFullfilmentsOrderById(id));

      setOpenModalCancellation(false);
    }
  };
  return (
    <div className={classes.paper}>
      <Box className={classes.paperr}>
        <Typography align={"center"} color={"primary"}>
          {t("fulfillment:orderCancellation")}
          <br /> <strong>{order.order_id}</strong>
        </Typography>
        <Box className={classes.formBlock}>
          <Grid
            container
            spacing={3}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Grid item xs>
              <Paper
                className={classes.paperr}
                style={{ margin: 10, padding: 10 }}
              >
                <Typography align={"left"} color={"primary"}>
                  {t("common:name")}: {order?.customer_name}
                </Typography>
                <Typography align={"left"} color={"primary"}>
                  {t("common:addr")}: {order?.delivery_address}
                </Typography>
                <Typography align={"left"} color={"primary"}>
                  {t("common:email")}:{" "}
                  {order?.customer_email && order?.customer_email != "None"
                    ? order?.customer_email
                    : "-"}
                </Typography>
                {order?.contact_phone && (
                  <Typography align={"left"} color={"primary"}>
                    {t("common:numTel")}: {order?.contact_phone}
                  </Typography>
                )}
              </Paper>
              <Box mt={2} justifyContent="center" display="flex">
                <FormControl
                  sx={{ minWidth: 120 }}
                  variant="outlined"
                  size="small"
                  required
                >
                  <Typography variant="subtitle1" color="textSecondary">
                    {t("fulfillment:reasonfororderrejection")}
                  </Typography>
                  <Select
                    id="demo-simple-select-required"
                    name="message"
                    value={message}
                    error={errmessage}
                    onChange={(e) => {
                      setMessage(e.target.value);
                      errmessage && setErrmessage(false);
                    }}
                  >
                    {[
                      {
                        key: "customer-cancellations",
                        value: t("fulfillment:customerCancellations"),
                      },
                      {
                        key: "out-of-stock-item",
                        value: t("fulfillment:outOfStockItem"),
                      },
                    ].map((row) => (
                      <MenuItem value={row.key} key={row.key}>
                        {row.value}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {t("fulfillment:helperTextReason")}
                  </FormHelperText>
                </FormControl>
              </Box>
            </Grid>
          </Grid>

          <Box display="flex" justifyContent="flex-end" paddingTop={"3%"}>
            <Button
              className={classes.verifyButton}
              onClick={() => {
                setMessage("");
                setOpenModalCancellation(false);
              }}
              variant="outlined"
              color="primary"
            >
              {t("common:no")}
            </Button>
            <Button
              className={classes.verifyButton}
              disabled={false}
              onClick={() => handleSubmit()}
              variant={"contained"}
              color={"primary"}
            >
              {t("common:yes")}
            </Button>
          </Box>
        </Box>
      </Box>
    </div>
  );
};
