import React, { useEffect, useState } from "react";
import { makeStyles, Collapse } from "@material-ui/core";
import { Products } from "./components/products";
import Box from "@material-ui/core/Box";
import clsx from "clsx";
import { BasicTable } from "./components/basictable";
import { Freebutton } from "./components/freebutton";
import { Calculator } from "../../components/calculator";
import { WidthProvider, Responsive } from "react-grid-layout";
import Fab from "@material-ui/core/Fab";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import _ from "lodash";
import TemporaryDrawer from "./components/Drawer";
import GlobalDrawer from "./components/globalDrawer";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  ActiveThemes,
  UpdateThemes,
  UpdatefreebuttonsThemes,
} from "../../store/theme/theme.action";
import { Prompt, useHistory } from "react-router-dom";
import { Button, Modal, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import UpdateIcon from "@material-ui/icons/Update";
import { ButtongoBack } from "../../components/buttongoback";
import { Alert, AlertTitle } from "@material-ui/lab";

//import { Stack } from '@material-ui/material';

const ResponsiveReactGridLayout = WidthProvider(Responsive);
const originalLayouts = getFromLS("layouts") || {};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    marginTop: 30,
  },
  absolute: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(3),
  },
  productSection: {
    [theme.breakpoints.up("md")]: {
      paddingRight: 18,
    },
  },
  calculator: {
    marginBottom: 20,
  },
  actionsButton: {
    position: "absolute",
    display: "flex",
    right: 6,
    top: 8,
    zIndex: 13,
  },
  actionsButtonItem: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: "50%",
    opacity: 0.3,
    marginRight: 4,
  },
  ButtonItemIcon: {
    padding: 4,
    color: theme.palette.white.main,
  },
  dataGrid: {
    "&:hover": {
      zIndex: 13,
    },
    "& .showButtons": {
      display: "flex",
    },
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 10,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  button: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.blue.main,
    border: "1px solid",
    borderColor: theme.palette.blue.main,
    fontSize: "0.875rem",
    borderRadius: 10,
    "&:hover": {
      backgroundColor: theme.palette.secondary.light,
      borderColor: theme.palette.blue.main,
    },
  },
}));

function getFromLS(key) {
  let ls = {};
  if (global.localStorage) {
    try {
      ls = JSON.parse(global.localStorage.getItem("rgl-8")) || {};
    } catch (e) {
      /*Ignore*/
    }
  }
  return ls[key];
}

function saveToLS(key, value) {
  if (global.localStorage) {
    global.localStorage.setItem(
      "rgl-8",
      JSON.stringify({
        [key]: value,
      })
    );
  }
}

export const InterfaceCustomization = () => {
  const [freebuttonss, setFreebuttonss] = useState([
    {
      button_id: 1,
      activated: false,
    },
    {
      button_id: 2,
      activated: false,
    },
    {
      button_id: 3,
      activated: false,
    },
    {
      button_id: 4,
      activated: false,
    },
    {
      button_id: 5,
      activated: false,
    },
    {
      button_id: 6,
      activated: false,
    },
    {
      button_id: 7,
      activated: false,
    },
  ]);
  const initialItems = [
    {
      w: 8,
      h: 6,
      x: 0,
      y: 9,
      minW: 2,
      minH: 2,
      i: "0",
      component: <Products />,
      title: "Produits",
      key: "products",
    },
    {
      w: 4,
      h: 8.5,
      x: 8,
      y: 0,
      minW: 2,
      minH: 3,
      i: "1",
      component: (
        <Calculator
          isCalcul={true}
          amount={0}
          HandleAmount={(amount) => {}}
          basic={true}
        />
      ),
      title: "Calculatrice",
      key: "calculator",
    },
    {
      w: 8,
      h: 9,
      x: 0,
      y: 0,
      minW: 2,
      minH: 3,
      i: "2",
      component: <BasicTable />,
      title: "Commandes",
      key: "selectedProducts",
    },
    {
      w: 4,
      h: 9,
      x: 8,
      y: 0,
      minW: 2,
      minH: 3,
      i: "3",
      component: (
        <Freebutton
          freebuttons={freebuttonss}
          setFreebuttons={setFreebuttonss}
        />
      ),
      title: "Buttons libre",
      key: "freeButton",
    },
  ];
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation(["interfaceCustomization", "common", "hold"]);
  const themes = useSelector((state) => state.Theme.themes);
  const themeActive = useSelector((state) => state.Theme.activeTheme);
  const globalColors = useSelector((state) => state.Theme.globalColors);
  const freeButton = useSelector((state) => state.Theme.freeButtonsStyles);
  const products = useSelector((state) => state.Theme.productsStyle);
  const calculator = useSelector((state) => state.Theme.calculatorStyle);
  const selectedProducts = useSelector(
    (state) => state.Theme.selectedProductStyle
  );

  const [alertflag, setAlertflag] = useState(false);
  let { id } = useParams();
  const [leaveLocation, setLeaveLocation] = useState(null);
  const [isBlocking, setIsBlocking] = useState(false);
  const [componentsList, setComponentsList] = useState(initialItems);
  const [drawer, setDrawer] = useState({ item: null, open: false });
  const [globalDrawer, setGlobalDrawer] = useState(false);
  const [state, setState] = useState({
    layouts: JSON.parse(JSON.stringify(originalLayouts)),
  });

  const [isHovered, setIsHovered] = useState({
    0: false,
    1: false,
    2: false,
    3: false,
  });

  const resetLayout = () => {
    window.dispatchEvent(new Event("resize"));
    setState({ layouts: {} });
  };

  const onRemoveItem = (i) => {
    setComponentsList(componentsList.filter((el) => i !== el.i));
  };

  const onAddItem = (item) => {
    setComponentsList([...componentsList, item]);
  };

  const onLayoutChange = (layout, layouts) => {
    saveToLS("layouts", layouts);
    setState({ layouts });
  };
  const handleHover = (key) => {
    setIsHovered({ [key]: !isHovered[key] });
  };

  const createElement = (el, index) => {
    return (
      <Box
        className={clsx({
          [classes.actionsButton]: true, //always applies
          ["showButtons"]: isHovered[index], //only when open === true
        })}
      >
        <Box className={classes.actionsButtonItem}>
          <IconButton
            className={classes.ButtonItemIcon}
            component="span"
            onClick={() => setDrawer({ item: el, open: true })}
          >
            <EditIcon />
          </IconButton>
        </Box>
        <Box className={classes.actionsButtonItem}>
          <IconButton
            onClick={() => onRemoveItem(el.i)}
            className={classes.ButtonItemIcon}
            aria-label="upload picture"
            component="span"
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      </Box>
    );
  };

  const componentsItems = componentsList.map((el, index) => (
    <div
      onMouseEnter={() => handleHover(index)}
      onMouseLeave={() => handleHover(index)}
      className={classes.dataGrid}
      key={index.toString()}
      data-grid={el}
      // key={index}
    >
      {createElement(el, index)}
      {el.component}
    </div>
  ));

  const handleLeave = (location) => {
    setLeaveLocation(location);
  };

  const handleActiveClick = () => {
    dispatch(
      UpdatefreebuttonsThemes({
        theme_id: themeActive.theme_id,
        freebuttons: freebuttonss,
      })
    );

    const lastThemeIndex = themes.findIndex((obj) => obj.active === 1);
    const newThemeIndex = themes.findIndex(
      (obj) => obj.theme_id === themeActive.theme_id
    );
    themes[lastThemeIndex].active = 0;
    themes[newThemeIndex].active = 1;
    themes[newThemeIndex].globalColors = globalColors;
    themes[newThemeIndex].freeButton = freeButton;
    themes[newThemeIndex].calculator = calculator;
    themes[newThemeIndex].selectedProducts = selectedProducts;
    themes[newThemeIndex].products = products;
    setAlertflag(true);
    dispatch(UpdateThemes(themes));
    ActiveThemes({
      ...themes[newThemeIndex],
    });
    const timerpush = setTimeout(() => {
      history.push("/interfaces");
      setLeaveLocation(null);
      clearTimeout(timerpush);
    }, 1500);
    /*  history.push("/interfaces");
    setLeaveLocation(null);
      */
  };

  const handleConfirmClick = () => {
    dispatch(
      UpdatefreebuttonsThemes({
        theme_id: themeActive.theme_id,
        freebuttons: freebuttonss,
      })
    );
    const newThemeIndex = themes.findIndex(
      (obj) => obj.theme_id === themeActive.theme_id
    );
    themes[newThemeIndex].globalColors = globalColors;
    themes[newThemeIndex].freeButton = freeButton;
    themes[newThemeIndex].calculator = calculator;
    themes[newThemeIndex].selectedProducts = selectedProducts;
    themes[newThemeIndex].products = products;
    dispatch(UpdateThemes(themes));

    setInterval(history.push("/interfaces"), 5000);
    history.push("/interfaces");
  };
  const handleCancelClick = () => {
    dispatch(UpdateThemes(themes));
    history.push("/interfaces");
  };

  const body = (
    <div className={classes.paper}>
      <Collapse in={alertflag} timeout={15}>
        <Alert severity="success">
          <AlertTitle>Success</AlertTitle>
          <strong>{t("common:opsuccess")}</strong>
        </Alert>
      </Collapse>
      {!alertflag && (
        <>
          <Typography align={"center"} color={"primary"}>
            {t("interfaceCustomization:typo")}
          </Typography>
          <Box mt={3} justifyContent="center" display="flex">
            <Button
              color="primary"
              variant="contained"
              onClick={handleConfirmClick}
            >
              {t("interfaceCustomization:confirmer")}
            </Button>
            <Button onClick={handleActiveClick} color="primary">
              {t("interfaceCustomization:confActive")}
            </Button>
            <Button onClick={handleCancelClick} color="primary">
              {t("interfaceCustomization:annuler")}
            </Button>
          </Box>
        </>
      )}
    </div>
  );

  return (
    <>
      <Box
        display={"flex"}
        alignItems={"left"}
        flexWrap={"wrap"}
        style={{ marginTop: -20 }}
      >
        <Button
          className={classes.button}
          size={"small"}
          variant="outlined"
          color="primary"
          onClick={(location) => {
            setIsBlocking(true);
            setLeaveLocation(location);
          }}
        >
          <UpdateIcon className={classes.icon} />{" "}
          {t("hold:Finalisermodification")}
        </Button>
      </Box>
      <Box
        display={"flex"}
        alignItems={"left"}
        flexWrap={"wrap"}
        style={{ marginTop: 10 }}
      >
        <ButtongoBack label={t("common:back")} onClick={handleCancelClick} />
      </Box>
      <Box className={classes.root}>
        <div style={{ width: "100%" }}>
          <ResponsiveReactGridLayout
            isBounded={true}
            className="layout"
            cols={{ lg: 12, md: 12, sm: 12, xs: 8, xxs: 8 }}
            rowHeight={30}
            margin={[20, 20]}
            isDraggable={true}
            isResizable={true}
            containerPadding={[0, 0]}
            resizeHandles={["sw", "nw", "se", "ne"]}
            onLayoutChange={(layout, layouts) =>
              onLayoutChange(layout, layouts)
            }
          >
            {componentsItems}
          </ResponsiveReactGridLayout>
          <Tooltip
            style={{ zIndex: 20 }}
            title="Add"
            onClick={() => setGlobalDrawer(true)}
            aria-label="setting"
          >
            <Fab color="primary" className={classes.absolute}>
              <span className="icon-setting" />
            </Fab>
          </Tooltip>
          <TemporaryDrawer
            close={() => setDrawer({ item: null, open: false })}
            drawer={drawer}
          />
          <GlobalDrawer
            onAddItem={onAddItem}
            open={globalDrawer}
            close={() => setGlobalDrawer(false)}
          />
        </div>
        <Box>
          <Prompt
            when={isBlocking}
            message={(location) => {
              handleLeave(location);
              return Boolean(leaveLocation);
            }}
          />
          {leaveLocation && (
            <Modal
              open={isBlocking}
              onClose={handleCancelClick}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              {body}
            </Modal>
          )}
        </Box>
      </Box>
    </>
  );
};
