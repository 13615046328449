export const CLOSED_ENTRY_LOADED = "CLOSED_ENTRY_LOADED";
export const GET_CLOSED_ENTRY = "GET_CLOSED_ENTRY";

export const CREATE_CLOSE_POS_ENTRY = "CREATE_CLOSE_POS_ENTRY";
export const CREATE_CLOSE_POS_ENTRY_SUCCESS = "CREATE_CLOSE_POS_ENTRY_SUCCESS";

export const POS_INVOICES_LOADED = "POS_INVOICES_LOADED";
export const GET_POS_INVOICES = "GET_POS_INVOICES";

export const GET_CLOSED_POS_BY_ID = "GET_CLOSED_POS_BY_ID";
export const SUCCESS_GET_CLOSED_POS_BY_ID = "SUCCESS_GET_CLOSED_POS_BY_ID";

export const CREATE_SESSION = "CREATE_SESSION";
export const CREATE_SESSION_SUCCESS = "CREATE_SESSION_SUCCESS";

export const SESSION_LOADED = "SESSION_LOADED";
export const RESET_CLOSE_ENTRY = "RESET_CLOSE_ENTRY";

export const UPDATE_SAFEBOX = "UPDATE_SAFEBOX";

export const CREATE_COFFRE_OP = "CREATE_COFFRE_OP";
export const COFFRE_OP_CREATED = "COFFRE_OP_CREATED";

export const LOAD_COFFRE_OP = "LOAD_COFFRE_OP";
export const COFFRE_OP_LOADED = "COFFRE_OP_LOADED";

export const LOAD_CLOSED_POS = "LOAD_CLOSED_POS";

export const LOAD_COFFRE_OP_ID = "LOAD_COFFRE_OP_ID";
export const COFFRE_OP_LOADED_ID = "COFFRE_OP_LOADED_ID";
