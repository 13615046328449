import { FAILED_ADD_DATA, RESET_ERROR_MESSAGE } from "./rollback.type";
const initialState = {
  rollback: [],
  errorMessage: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FAILED_ADD_DATA:
      var rollback = state.rollback;
      if (
        action &&
        action.meta &&
        action.meta.meta &&
        action.meta.meta.offline
      ) {
        const rollbackIndex = rollback.findIndex(
          (r) =>
            r.meta.meta.offline.effect.body ==
            action.meta.meta.offline.effect.body
        );
        if (rollbackIndex == -1) {
          rollback.push(action);
        }
      }

      // Update errorMessage based on the API response message
      const errorMessage =
        action.payload && action.payload.response
          ? action.payload.response.message
          : "An error occurred"; // Default error message
      return {
        ...state,
        rollback: rollback,
        errorMessage: errorMessage,
      };

    case RESET_ERROR_MESSAGE:
      return { ...state, errorMessage: "" };

    default:
      return state;
  }
};
export default reducer;
