import React, { useEffect } from "react";
import { loadCoffreOperationById } from "../../../store/pos/pos.action";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardContent } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { formatDate } from "../../../helpers/utils";

export const useStyles = makeStyles((theme) => ({
  card: {
    background: theme.palette.secondary.main,
    borderRadius: 10,
    border: "none",
    minHeight: 610,
  },
  cardBoldText: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: 14,
  },
  cardTitle: {
    fontSize: 16,
  },
  cardTitleBlock: {
    borderBottom: "1px solid #e7eaef",
    padding: "10px 20px 17px",
  },
  cardContentBlock: {
    padding: "1.4375rem 1.5rem 2.0625rem",
  },
  usernameBlock: {
    background: "#FFF",
    borderRadius: 19,
    padding: 14,
    marginBottom: 17,
  },
  username: {
    color: "#2F334D",
    fontSize: 20,
    fontWeight: 600,
  },
  textLight: {
    color: theme.palette.gray.main,
    fontSize: 12,
    fontWeight: 500,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    "&:hover": {
      overflow: "visible",
    },
  },
  text: {
    fontSize: 14,
    fontWeight: 600,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    "&:hover": {
      overflow: "visible",
    },
  },
  item: {
    marginBottom: "1.4375rem",
  },
  backLink: {
    fontSize: 12,
    color: "#2F334D",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    marginTop: 14,
    marginLeft: 22,
  },
  linkIcon: {
    height: 10,
    width: 8,
  },
}));

function CoffreDetails({ id }) {
  const classes = useStyles();
  const { t } = useTranslation(["common", "fulfillment"]);
  const dispatch = useDispatch();
  const globalDefaults = useSelector((state) => state.Login.globalDefaults);
  const coffreOperationDetails = useSelector(
    (state) => state.Pos.coffreOperationDetails
  );

  console.log(coffreOperationDetails);
  useEffect(() => {
    if (id) {
      dispatch(loadCoffreOperationById({ id: id }));
    }
  }, [id]);

  return (
    <div>
      <Card
        className={clsx(classes.card, classes.cardBoldText)}
        variant="outlined"
      >
        <CardContent>
          <Box>
            <Box className={classes.cardTitleBlock}>
              <Typography
                className={clsx(classes.cardTitle, classes.cardBoldText)}
              >
                Détails de l'opération de coffre
              </Typography>
            </Box>
            <Box className={classes.item}>
              <Typography color={"primary"} className={classes.textLight}>
                Ouverture de la caisse
              </Typography>
              <Typography className={classes.text}>
                {coffreOperationDetails?.opening_entry ?? "-"}
              </Typography>
            </Box>
            <Box className={classes.item}>
              <Typography color={"primary"} className={classes.textLight}>
                Magasin - Caisse
              </Typography>
              <Typography className={classes.text}>
                {coffreOperationDetails?.warehouse ?? "-"} -{" "}
                {coffreOperationDetails?.cashier ?? "-"}
              </Typography>
            </Box>
            <Box className={classes.item}>
              <Typography color={"primary"} className={classes.textLight}>
                Montant déposé
              </Typography>
              <Typography className={classes.text}>
                {coffreOperationDetails?.amount +
                  " " +
                  globalDefaults?.default_currency ?? "-"}{" "}
                ({coffreOperationDetails?.payment_method ?? "-"})
              </Typography>
            </Box>
            <Box className={classes.item}>
              <Typography color={"primary"} className={classes.textLight}>
                Date de transaction
              </Typography>
              <Typography className={classes.text}>
                {formatDate(
                  coffreOperationDetails?.created_at,
                  "DD/MM/YYYY HH:mm"
                ) ?? "-"}
              </Typography>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
}

export default CoffreDetails;
