
import {
    SET_NOTIFICATION,
  } from "./notification.type";
  
export const SetNotification = (data) => {
    return {
      type: SET_NOTIFICATION,
      notification: data,
    };
  };