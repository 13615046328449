import React, { useEffect, useState } from "react";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import * as XLSX from "xlsx";
 
import {
  Typography,
  Button,
  Modal,
  Box,
  OutlinedInput,
  ClickAwayListener,
  FormControl,
  InputAdornment,
} from "@material-ui/core";
import { useStyles } from "../../style";
import Pagination from "@material-ui/lab/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  LoadPurchaseRequests,
  LoadSupplierList,
} from "../../../../store/buying/buying.action";
import { Loader } from "../../../../components/loader";
import { MatReqTable } from "../materialReqTable";
import { Form } from "../form";
import {
  FilterBy,
  GetFilterItems,
  GlobalfilterData,
} from "../../../../helpers/helper";
import { useTableStyles } from "../../../../styles/table.style";
import { Filter } from "../../../../components/filter";
import ImportMaterialReqFile from "../materialReqTable/importMaterialReq";

export const MaterialRequest = () => {
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [ openModalImport, setOpenModalImport ] = useState(false)
  const tableclasses = useTableStyles();
  const { t } = useTranslation(["buying", "common"]);
  const globalDefaults = useSelector((state) => state.Login.globalDefaults);
  const [openCreationModal, setOpenCreationModal] = useState(false);
  const selectedData = useSelector((state) => state.Login.selectedData);
  const purchaseMatReq = useSelector((state) => state.Buying.purchaseMatReq);
  const [listPurchaseMatReq, setListPurchaseMatReq] = useState([]);
  const [valueSearch, setValueSearch] = useState("");
  const [filters, setFilters] = useState({
    status: [],
  });
  const addModalImport = () => {
    setOpenModalImport(true);
    }
  const maxitems = 20;
  const handleSearch = (event) => {
    setPage(1);
    setValueSearch(event.target.value);
    setListPurchaseMatReq(
      GlobalfilterData(
        filters?.status?.length > 0
          ? purchaseMatReq?.slice(0)?.filter(FilterBy(filters))
          : purchaseMatReq,
        event.target.value,
        ["name", "creation", "set_warehouse"]
      )
    );
  };
  const HandleFilter = (key, item) => {
    setPage(1);
    const FilterDataBy = { ...filters, [key]: item };
    setFilters(FilterDataBy);
    setListPurchaseMatReq(
      valueSearch
        ? GlobalfilterData(purchaseMatReq, valueSearch, [
            "name",
            "creation",
            "set_warehouse",
          ])
            ?.slice(0)
            ?.filter(FilterBy(FilterDataBy))
        : purchaseMatReq?.slice(0)?.filter(FilterBy(FilterDataBy))
    );
  };
  const handleOpen = () => {
    setOpenCreationModal(true);
  };
  const handleCloseModalImport = () => {
    setOpenModalImport(false);
  };
  useEffect(() => {
    if (globalDefaults?.default_company)
      dispatch(LoadPurchaseRequests(globalDefaults?.default_company));
    dispatch(LoadSupplierList());
  }, [globalDefaults?.default_company]);
  const handleOnExport = () => {
    var wb = XLSX.utils.book_new();
    var ws = XLSX.utils.json_to_sheet(purchaseMatReq)
    XLSX.utils.book_append_sheet(wb, ws, "sheet1")
    XLSX.writeFile(wb, "Material_Request.xlsx")
}
  const creationModal = (
    <div className={classes.paper}>
      <Typography align={"center"} color={"primary"}>
        {t("buying:CreateDAchat")}
      </Typography>
      <Box>
        <Form setOpenCreationModal={setOpenCreationModal} />
      </Box>
    </div>
  );

  if (!purchaseMatReq) return <Loader />;

  return (
    <Box>
      <Box className={classes.header}>
        <Button
          className={classes.custombutton}
          size={"small"}
          variant="contained"
          color="primary"
          onClick={handleOpen}
        >
          {t("buying:CreateDAchat")}
        </Button>
        <Button
        style={{ marginLeft: 10,marginBottom: 10, marginTop: 10 }}
        size="small"
        color="primary"
        variant="outlined"
        onClick={addModalImport}
        startIcon={<CloudDownloadIcon />} // Use the CloudUploadIcon as the start icon
      >
         {t("common:importFile")}
      </Button>
      <Button
        style={{ marginLeft: 10,marginBottom: 10, marginTop: 10 }}
        size="small"
        color="primary"
        variant="outlined"
        onClick={handleOnExport}
        startIcon={<CloudUploadIcon />} // Use the CloudUploadIcon as the start icon
      >
         {t("common:exportFile")}
      </Button>
        <FormControl className={classes.formControlSearch} variant="outlined">
          <OutlinedInput
            id="outlined-adornment-weight"
            value={valueSearch}
            placeholder={t("common:rechercher")}
            onChange={handleSearch}
            endAdornment={
              <InputAdornment position="end">
                <span className="icon-search" />
              </InputAdornment>
            }
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              "aria-label": "weight",
            }}
            labelWidth={0}
          />
        </FormControl>
      </Box>
      <Box className={tableclasses.filterBlock}>
        <Box className={tableclasses.filter}>
          <Filter
            data={GetFilterItems([
              ...new Set(purchaseMatReq?.map((item) => item?.status)),
            ])}
            title={t("common:statut")}
            id={"status"}
            HandleFilter={HandleFilter}
            value={filters.status}
            number={filters.status.length}
          />
        </Box>
      </Box>
      <Box>
        <MatReqTable
          purchaseMatReq={
            valueSearch || filters.status.length > 0
              ? listPurchaseMatReq?.slice(
                  (page - 1) * maxitems,
                  page * maxitems
                )
              : purchaseMatReq.slice((page - 1) * maxitems, page * maxitems)
          }
        />
        {Math.ceil(
          (valueSearch || filters.status.length > 0
            ? listPurchaseMatReq?.length
            : purchaseMatReq.length) / maxitems
        ) > 0 && (
          <Box display="flex" justifyContent="center" m={4}>
            <Pagination
              onChange={(event, newPage) => setPage(newPage)}
              defaultPage={page}
              count={Math.ceil(
                (valueSearch || filters.status.length > 0
                  ? listPurchaseMatReq?.length
                  : purchaseMatReq.length) / maxitems
              )}
              color="primary"
            />
          </Box>
        )}
      </Box>
      <Modal
        open={openCreationModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {creationModal}
      </Modal>
      <Modal
      open={ openModalImport }
      aria-labelledby="simple-modal-import"
      aria-describedby="simple-modal-import"

    >
        <ClickAwayListener
        onClickAway={() => (setOpenModalImport ? setOpenModalImport(false) : null)}
      >
      <div className={classes.paper} style={{ overflowY: 'auto', maxHeight: '90vh',width: '50%' }}    >
        <ImportMaterialReqFile setCloseModalImport={handleCloseModalImport}/>
      </div>
         </ClickAwayListener> 
    </Modal>
    </Box>
  );
};
