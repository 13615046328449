import React, { useEffect } from "react";
import Box from "@material-ui/core/Box";
import clsx from "clsx";
import { FulfillementTable } from "./table";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import Chip from "@material-ui/core/Chip";
import ClearIcon from "@material-ui/icons/Clear";
import { Typography, Button } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import { useStyles } from "../../style";
import { useTableStyles } from "../../../../styles/table.style";
import { useDispatch, useSelector } from "react-redux";
import GpsFixed from "@material-ui/icons/GpsFixed";
import {
  FilterBy,
  GetFilterItems,
  GetOrderdProducts,
  GlobalfilterData,
} from "../../../../helpers/helper";
import { Filter } from "../../../../components/filter";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import RestoreIcon from "@material-ui/icons/Restore";
import {
  SetOrdersToPick,
  UpdateManyOrderStatus,
} from "../../../../store/fullfilment/fullfilment.action";
import { useHistory } from "react-router";
import { Invoice } from "../../../../components/invoice";
import Pagination from "@material-ui/lab/Pagination";
import { CreateStatusEmail } from "../../../../store/fullfilment/fullfilment.action";
import validator from "validator";
import { useTranslation } from "react-i18next";

export const Fulfillment = (props) => {
  const classes = useStyles();
  const tableclasses = useTableStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation(["order", "common", "fulfillment"]);
  const [filtredFillfilments, setFiltredFillfilments] = React.useState([]);
  const products = useSelector((state) => state.Product.productsPos);
  const [fullfilmentSearch, setFullfilmentSearch] = React.useState("");
  const [pickUpType, setPickUpType] = React.useState(0);
  const [productFullfilments, setProductFullfilments] = React.useState([]);

  const [itemsCkecked, setItemsChecked] = React.useState(null);
  const selectedOrders = itemsCkecked
    ? Object.keys(itemsCkecked).filter((el) => itemsCkecked[el])
    : [];

  const handleClick = () => {
    selectedOrders.forEach((element) => {
      const order = props.fullfilments.find((f) => f.order_id == element);
      if (validator.isEmail(order.customer_email)) {
        dispatch(CreateStatusEmail(order.customer_email, order));
      }
    });
  };

  const handleCheckboxChange = (event) => {
    if (event.target.name === "check-all") {
      const ordersObject = {};
      filtredFillfilments.forEach((item) => {
        const orderId = item.order_id;
        ordersObject[orderId] = true;
      });
      event.target.checked
        ? setItemsChecked(ordersObject)
        : setItemsChecked({});
    } else {
      setItemsChecked({
        ...itemsCkecked,
        [event.target.name]: event.target.checked,
      });
    }
  };

  const handleSearchFullfilment = (event) => {
    setFullfilmentSearch(event.target.value);
    setFiltredFillfilments(
      GlobalfilterData(props.fullfilments, event.target.value, [
        "order_id",
        "customer_name",
        "grand_total",
        "delivery_date",
        "sales_date",
      ])
    );
  };

  const [filters, setFilters] = React.useState({
    status: [],
    recipient: [],
    point_of_sales: [],
  });

  const HandleFilter = (key, item) => {
    const FilterDataBy = { ...filters, [key]: item };
    setFilters(FilterDataBy);
    setFiltredFillfilments(props.fullfilments.filter(FilterBy(FilterDataBy)));
  };
  const HandleDeleteAll = () => {
    const data = {
      status: [],
      recipient: [],
      point_of_sales: [],
    };
    setFilters(data);
    setFiltredFillfilments(props.fullfilments.filter(FilterBy(data)));
  };

  const [page, setPage] = React.useState(1);

  const HandleCloseChip = (item, key) => {
    const FilterDataBy = {
      ...filters,
      [key]: filters[key].filter((f) => f !== item),
    };
    setFilters(FilterDataBy);
    setFiltredFillfilments(props.fullfilments.filter(FilterBy(FilterDataBy)));
  };
  useEffect(() => {
    setFiltredFillfilments(props.fullfilments);
  }, [props.fullfilments]);

  useEffect(() => {
    if (selectedOrders.length > 0) {
      const allProductsTopick = [];
      selectedOrders.forEach((element) => {
        const orderToPick = props.fullfilments.find(
          (f) => f.order_id == element
        );
        const ProductsTopick =
          orderToPick && orderToPick.products
            ? GetOrderdProducts([...products], orderToPick.products)
            : [];
        ProductsTopick.forEach((product) => {
          const index = allProductsTopick.findIndex(
            (p) => p.item_code == product.item_code
          );
          if (index !== -1) {
            allProductsTopick[index].quantity =
              allProductsTopick[index].quantity + product.quantity;
          } else {
            allProductsTopick.push(product);
          }
        });
      });
      setProductFullfilments(allProductsTopick);
    }
  }, [itemsCkecked]);

  const handleUpdateOrderStatus = (status) => {
    const tab = status == "verified" ? 1 : 0;
    dispatch(
      UpdateManyOrderStatus({
        status,
        orders: selectedOrders,
        redirectToTab: tab,
      })
    );
  };

  const handlePickUp = () => {
    setPickUpType("groupByProduct");
    dispatch(
      SetOrdersToPick({ orders: selectedOrders, groupBy: "groupByProduct" })
    );
    history.push("fulfillement/picking");
  };

  const isOrdersAvailable = () => {
    if (selectedOrders?.length === 0) {
      return false;
    }
    let orderedItems = [];
    selectedOrders.forEach((element) => {
      const order = props.fullfilments.find((f) => f.order_id == element);
      orderedItems.push(JSON.parse(order.products));
    });
    orderedItems = orderedItems.flat();
    const uniqueOrderedItems = Array.from(
      new Set(orderedItems.map(JSON.stringify))
    ).map(JSON.parse);
    const allExist = uniqueOrderedItems.every((item) =>
      products.some((product) => product.item_code === item.item_code)
    );
    if (!allExist) {
      return false;
    }
    return true;
  };

  return (
    <Box>
      <Box className={classes.header}>
        {props.tab == 0 && (
          <Box>
            {/* SINCE THE ORDER CAN BE CANCELLED BY THE CUSTOMER */}
            {/* <Button
              disabled={selectedOrders.length == 0}
              onClick={() => handleUpdateOrderStatus("canceled")}
              className={clsx(classes.custombutton, classes.refusedButton)}
              size={"small"}
              variant="outlined"
              color="primary"
            >
              <NotInterestedIcon className={classes.icon} /> {t("fulfillment:refuse")}
            </Button> */}
            <Button
              startIcon={<RestoreIcon />}
              disabled={!isOrdersAvailable()}
              onClick={() => handleUpdateOrderStatus("verified")}
              className={classes.custombutton}
              size={"small"}
              variant="outlined"
              color="primary"
            >
              {t("fulfillment:markVerif")}
            </Button>
          </Box>
        )}
        {props.tab == 2 && (
          <Button
            startIcon={<GpsFixed />}
            disabled={selectedOrders.length == 0}
            className={clsx(classes.formControl, classes.pickIcon)}
            onClick={handlePickUp}
            size={"small"}
            variant="outlined"
            color="primary"
          >
            Pick up
          </Button>
        )}
        {props.tab == 2 && (
          <Invoice
            title={"Liste de picking"}
            header={selectedOrders}
            order={productFullfilments}
            document={null}
          />
        )}
        <FormControl className={classes.formControl} variant="outlined">
          <OutlinedInput
            id="outlined-adornment-weight"
            value={fullfilmentSearch}
            placeholder={t("common:rechercher")}
            onChange={handleSearchFullfilment}
            endAdornment={
              <InputAdornment position="end">
                <span className="icon-search" />
              </InputAdornment>
            }
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              "aria-label": "weight",
            }}
            labelWidth={0}
          />
        </FormControl>
      </Box>
      <Box className={tableclasses.filterBlock}>
        <Box className={tableclasses.filter}>
          <Filter
            data={GetFilterItems([
              ...new Set(props.fullfilments.map((item) => item.status)),
            ])}
            title={t("common:statut")}
            id={"status"}
            HandleFilter={HandleFilter}
            value={filters.status}
            number={filters.status.length}
          />
          <Filter
            data={GetFilterItems([
              ...new Set(props.fullfilments.map((item) => item.recipient)),
            ])}
            title={t("fulfillment:dest")}
            id={"recipient"}
            HandleFilter={HandleFilter}
            value={filters.recipient}
            number={filters.recipient.length}
          />
          <Filter
            data={GetFilterItems([
              ...new Set(props.fullfilments.map((item) => item.point_of_sales)),
            ])}
            title={t("fulfillment:pos")}
            id={"point_of_sales"}
            HandleFilter={HandleFilter}
            value={filters.point_of_sales}
            number={filters.point_of_sales.length}
          />
        </Box>
      </Box>
      <Box display="flex" flexWrap="wrap" alignItems="center">
        {filters.status.map((filter) => (
          <Chip
            className={tableclasses.chip}
            label={filter}
            onDelete={() => HandleCloseChip(filter, "status")}
            color="secondary"
            size={"small"}
            deleteIcon={<ClearIcon />}
          />
        ))}
        {filters.recipient.map((filter) => (
          <Chip
            className={tableclasses.chip}
            label={filter}
            onDelete={() => HandleCloseChip(filter, "recipient")}
            color="secondary"
            size={"small"}
            deleteIcon={<ClearIcon />}
          />
        ))}
        {filters.point_of_sales.map((filter) => (
          <Chip
            className={tableclasses.chip}
            label={filter}
            onDelete={() => HandleCloseChip(filter, "point_of_sales")}
            color="secondary"
            size={"small"}
            deleteIcon={<ClearIcon />}
          />
        ))}
        {(filters.point_of_sales.length > 0 ||
          filters.recipient.length > 0 ||
          filters.status.length > 0) && (
          <Typography onClick={HandleDeleteAll} className={tableclasses.remove}>
            {t("common:delete")}
          </Typography>
        )}
      </Box>
      <Box>
        <FulfillementTable
          tab={props.tab}
          handleCheckboxChange={handleCheckboxChange}
          itemsCkecked={itemsCkecked}
          fullfilments={filtredFillfilments
            .slice(0)
            .reverse()
            .slice((page - 1) * 20, page * 20)}
        />
        {Math.ceil(filtredFillfilments.length / 20) > 0 && (
          <Box display="flex" justifyContent="center" m={4}>
            <Pagination
              onChange={(event, newPage) => setPage(newPage)}
              page={page}
              count={Math.ceil(filtredFillfilments.length / 20)}
              color="primary"
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};
