import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import clsx from "clsx";
import { useStyles } from "./style";
import { Card } from "@material-ui/core";

export const BasickeyPad = (props) => {
  const classes = useStyles();
  const amount = props.amount;
  const [calc, setCalc] = useState("");
  const [result, setResult] = useState("");
  const ops =['/','*','+','-','.','(',')'];
 
  const updateCalc = value => {
    if(
      ops.includes(value) && calc === '' ||
      ops.includes(value) && ops.includes(calc.slice(-1)) ||
      calc === '' &&  value == "0"
    ){
      return;
    }
    setCalc(calc + value);

    if(!ops.includes(value)){
      setResult(eval(calc + value).toString());
    }
  } 
  const calculate = () => {
    if (ops.includes(calc.charAt(calc.length-1))) {
      return;
    } else {
      return eval(calc) ? setCalc(eval(calc).toString()) : null;
    }
  }
  const deleteLast = () => {
    if(calc == ''){
      return;
    }
    const value=calc.slice(0,-1);
    setCalc(value);
  }

  const clear = ()=>{
    setCalc("");
    setResult("0");
  }
  const HandleKey = (key) => {
    if (amount === 0 && key !== "remove") {
      props.HandleAmount(key);
    } else if (key !== "remove") {
      props.HandleAmount(amount + key);
    } else if (key == "remove") {
      amount?.toString()?.length > 1 ? props.HandleAmount(parseFloat(amount.toString().slice(0, -1))) : props.HandleAmount(0);
    }
  };
  return (
    <div className={classes.keyPadContainer}>   
      {props.isCalcul && 
      <Box className={classes.section}>
        <Button
            style={{width:"50%"}}
            className={clsx(classes.Button, classes.border)}
            name="R" 
            onClick={clear}
            
          >
          CE
        </Button>
        <Button className={clsx(classes.Button, classes.border)}
          style={{width:"50%"}}
          name="="
          onClick={calculate}
        >
          =
        </Button>
      </Box>
      }
      <Box className={classes.section}>
        <Button
          className={clsx(classes.Button, classes.border)}
          name="1"
          onClick={props.isCalcul ? ()=>updateCalc("1"):(e) => HandleKey("1")} 
        >
          1
        </Button>
        <Button
          className={clsx(classes.Button, classes.border)}
          name="2"
          onClick={props.isCalcul ? ()=>updateCalc("2"):(e) => HandleKey("2")} 
        >
          2
        </Button>
        <Button
          className={clsx(classes.Button, classes.border)}
          name="3"
          onClick={props.isCalcul ? ()=>updateCalc("3"):(e) => HandleKey("3")} 
        >
          3
        </Button>
        {props.isCalcul && 
        <Button className={clsx(classes.Button, classes.border)} 
            name="/"
            onClick={()=>updateCalc('/')}
          >
            &divide;
        </Button>
        }
      </Box>
      <Box className={classes.section}>
        <Button
          className={clsx(classes.Button, classes.border)}
          name="4"
          onClick={props.isCalcul ? ()=>updateCalc("4"):(e) => HandleKey("4")} 
        >
          4
        </Button>
        <Button
          className={clsx(classes.Button, classes.border)}
          name="5"
          onClick={props.isCalcul ? ()=>updateCalc("5"):(e) => HandleKey("5")} 
        >
          5
        </Button>
        <Button
          className={clsx(classes.Button, classes.border)}
          name="6"
          onClick={props.isCalcul ? ()=>updateCalc("6"):(e) => HandleKey("6")} 
        >
          6
        </Button>
        {props.isCalcul &&
        <Button className={clsx(classes.Button, classes.border)} 
          name="*"
          onClick={()=>updateCalc("*")} 
        >
          &times;
        </Button>
        }
      </Box>
      <Box className={classes.section}>
        <Button
          className={clsx(classes.Button, classes.border)}
          name="7"
          onClick={props.isCalcul ? ()=>updateCalc("7"):(e) => HandleKey("7")} 
        >
          7
        </Button>
        <Button
          className={clsx(classes.Button, classes.border)}
          name="8"
          onClick={props.isCalcul ? ()=>updateCalc("8"):(e) => HandleKey("8")} 
        >
          8
        </Button>
        <Button
          className={clsx(classes.Button, classes.border)}
          name="9"
          onClick={props.isCalcul ? ()=>updateCalc("9"):(e) => HandleKey("9")} 
        >
          9
        </Button>
        {props.isCalcul && 
        <Button className={clsx(classes.Button, classes.border)}
          name="-"
          onClick={()=>updateCalc("-")} 
        >
          -
        </Button>
        }
      </Box>
      <Box className={classes.section}>
        <Button
          disabled={!props.isCalcul && amount % 1 !== 0}
          className={classes.Button}
          name="."
          onClick={props.isCalcul ? () => updateCalc(".") : (e) => HandleKey(".")} 
        >
          .
        </Button>
        <Button
          className={classes.Button}
          name="0"
          onClick={props.isCalcul ? ()=>updateCalc("0"):(e) => HandleKey("0")} 
        >
          0
        </Button>
        <Button
          className={classes.Button}
          name="C"
          onClick={props.isCalcul ? deleteLast : (e) => HandleKey("remove")} 
        >
          <span className={clsx("icon-remove-2", classes.icon)}></span>
        </Button>
        {props.isCalcul &&
        <Button
            className={classes.Button}
            name="+"
            onClick={()=>updateCalc("+")}  
          >
          +
        </Button>
        }
      </Box>
      {props.isCalcul && 
      <Box>
        <Card className={classes.resultCard}>
          {calc || "0"}
        </Card>
      </Box>
      }
    </div>
  );
};
