import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Box from "@material-ui/core/Box";
import clsx from "clsx";
import { useTableStyles } from "../../../../styles/table.style";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "1.0625rem 1.5rem",
  },
  price: {
    fontWeight: 600,
  },
  filterList: {
    borderTop: "none",
  },
}));

export const Products = (props) => {
  const classes = useStyles();
  const tableClasses = useTableStyles();
  const { t } = useTranslation(["common", "transfert"]);
  const globalDefaults = useSelector((state) => state.Login.globalDefaults);

  return (
    <Box className={clsx(classes.root, tableClasses.paper)}>
      <Table className={tableClasses.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={tableClasses.tablecellHeader} align="left">
              {" "}
              {t("common:ref")}{" "}
            </TableCell>
            <TableCell className={tableClasses.tablecellHeader} align="center">
              {t("common:name")}
            </TableCell>
            <TableCell className={tableClasses.tablecellHeader} align="center">
              {t("transfert:qtyDemandé")}
            </TableCell>
            <TableCell className={tableClasses.tablecellHeader} align="center">
              {t("transfert:qtyRecu")}
            </TableCell>
            <TableCell className={tableClasses.tablecellHeader} align="center">
              {t("common:mgsCible")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.orders &&
            Array.isArray(props.orders) &&
            props.orders.map((row, index) => (
              <TableRow className={tableClasses.tableRow} key={index}>
                <TableCell className={tableClasses.tableCell} align="left">
                  {row.item_code}
                </TableCell>
                <TableCell className={tableClasses.tableCell} align="center">
                  {row.item_name}
                </TableCell>
                <TableCell className={tableClasses.tableCell} align="center">
                  {row.qty}
                </TableCell>
                <TableCell className={tableClasses.tableCell} align="center">
                  {row.ordered_qty}
                </TableCell>
                <TableCell className={tableClasses.tableCell} align="center">
                  {row.warehouse}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </Box>
  );
};
