import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import AddBoxIcon from "@mui/icons-material/AddBox";
import AddItem from "../../../../components/addItem/AddItem";
import { SetNotification } from "../../../../store/notification/notification.action";
import { Delete } from "@material-ui/icons";
import { Loader } from "../../../../components/loader";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Autocomplete from "@mui/material/Autocomplete";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Grid,
  TextField,
  Collapse,
  LinearProgress,
  FormControl,
  InputAdornment,
  Link,
  Select,
  Modal,
  OutlinedInput,
  InputLabel,
  MenuItem,
} from "@mui/material";
import { Button } from "@material-ui/core";
import {
  DatePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import React, { useEffect, useState } from "react";
import { formatDate } from "../../../../helpers/utils";
import { fr } from "date-fns/esm/locale";
import DateFnsUtils from "@date-io/date-fns";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";


const DuplicatePurchaseOrder = ({ setCloseModal, id }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation(["buying", "common"]);
    const [loading, setLoading] = useState(true);
    const [supplierList, setSupplierList] = useState([]);
    const [loadingButton, setLoadingButton] = useState(false);
    const [itemList, setItemList] = useState([]);
    const [companyList, setCompanyList] = useState([]);
    const [CurrencyList, setCurrencyList] = useState([]);
    const [taxList, setTaxList] = useState([]);
    const [supplierSelected, setSupplierSelected] = useState("");
    const [companySelected, setCompanySelected] = useState("");
    const [checked, setChecked] = useState(false);
    const [addressList, setAddressList] = useState([]);
    const [addressSelected, setAddressSelected] = useState("");
    const [contactList, setContactList] = useState([]);
    const [contactSelected, setContactSelected] = useState("");
    const [CurrencySelected, setCurrencySelected] = useState("");
    const [taxCategoryList, setTaxCategoryList] = useState([]);
    const [priceList, setPriceList] = useState([]);
    const [priceSelected, setPriceSelected] = useState("");
    const [grandTotal, setGrandTotal] = useState("");
    const [totaxcharge, setTotaxcharge] = useState("");
    const [baseTotal, setBaseTotal] = useState("");
    const [taxCategorySelected, setTaxCategorySelected] = useState("");
    const [purchaseTaxTemplateList, setPurchaseTaxTemplateList] = useState([]);
    const [itemPriceList, setItemPriceList] = useState([]);
    const [purchaseTaxTemplateSelected, setPurchaseTaxTemplateSelected] =
      useState("");
    const [title, setTitle] = useState("");
    const [returnData, setReturnData] = useState("");
  
    const [error, setError] = useState({
      supplier: false,
      scheduleDate: false,
      scheduleDateTill: false,
      schedule_date: false,
      items: false,
      taxes: false,
    });
    const [scheduleDate, setScheduleDate] = useState(
      formatDate(new Date(), "YYYY-MM-DD")
    );

    const [scheduleDateTill, setScheduleDateTill] = useState(
      formatDate(new Date(), "YYYY-MM-DD")
    );
    const [state, setState] = useState({
      items: [],
      taxes: [],
    });

    const header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
      Authorization: JSON.parse(localStorage.getItem("user")).token,
    };
  
    const getItemPriceList = () => {
      fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
        method: "POST",
        headers: header,
        body: JSON.stringify({
          doctype: "Item Price",
          fields: ['`tabItem Price`.`name`', '`tabItem Price`.`item_code`', '`tabItem Price`.`item_name`', '`tabItem Price`.`price_list_rate`'],
          filters: [
            ['Item Price', 'selling', '=', 1],
        ],
        }),
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          if (Array.isArray(data)) {
            setItemPriceList(data);
          }
        });
    };
  
    useEffect(() => {
      getItemPriceList();
      }
      , []);

      console.log(itemPriceList, "itemPriceList");
  
      const handleQuantityChange = (index, value) => {
        const updatedItems = [...state.items];
        const quantity = value === '' ? 0 : parseFloat(value);
        updatedItems[index].qty = quantity;
        updatedItems[index].amount = updatedItems[index].price_list_rate * quantity;
        setState({ ...state, items: updatedItems });
      };
  
    const handleDeleteItem = (index) => {
      const updatedItems = [...state.items];
      updatedItems.splice(index, 1);
      setState({ ...state, items: updatedItems });
    };
  
    useEffect(() => {
      setLoading(true);
      const makeMappedDoc = async () => {
        try {
          const response = await axios.get(
              `${process.env.REACT_APP_API_URI}/api/details`,
              {
                params: {
                  doctype: "Purchase Order",
                  name: id,
                },
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
                  Authorization: JSON.parse(localStorage.getItem("user")).token,
                },
              }
          );
          const responseDoc = response.data["docs"][0];
          console.log('responseDoc :',responseDoc)
          setTitle(responseDoc.supplier);
          setSupplierSelected(responseDoc.supplier);
          setCompanySelected(responseDoc.company);
          setCurrencySelected(responseDoc.currency);
          setPriceSelected(responseDoc.price_list);
          setPurchaseTaxTemplateSelected(responseDoc.purchase_tax_template);
          setTaxCategorySelected(responseDoc.tax_category);
          setPurchaseTaxTemplateSelected(responseDoc.taxes_and_charges);
          setPriceSelected(responseDoc.buying_price_list);
          setAddressSelected(responseDoc.supplier_address);
          setContactSelected(responseDoc.contact_person);
          setBaseTotal(responseDoc.base_total);
          setTotaxcharge(responseDoc.total_taxes_and_charges);
          setGrandTotal(responseDoc.grand_total);
          setScheduleDate(responseDoc.scheduleDateTill);
          responseDoc["taxes"].map((tax) => {
            state.taxes.push(tax);
          });
          responseDoc["items"].map((item) => {
            state.items.push({ ...item, qty: item.qty });
          });
        } catch (error) {
          console.error("Error:", error);
        } finally {
          setLoading(false);
        }
      };
      makeMappedDoc();
    }, []);
  
    const getSupplierList = () => {
      fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
          Authorization: JSON.parse(localStorage.getItem("user")).token,
        },
        body: JSON.stringify({
          doctype: "Supplier",
          fields: ["`tabSupplier`.`name`"],
        }),
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          if (Array.isArray(data)) {
            setSupplierList(data);
          }
        });
    };
    const getItemList = () => {
      if (companySelected) {
        fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
            Authorization: JSON.parse(localStorage.getItem("user")).token,
          },
          body: JSON.stringify({
            doctype: "Item",
            fields: [
              "`tabItem`.`name`",
              "`tabItem`.`description`",
              "`tabItem`.`item_group`",
              "`tabItem`.`item_collection`",
              "`tabItem`.`item_name`",
              "`tabItem`.`standard_rate`",
              "`tabItem`.`opening_stock`",
  
              "`tabItem`.`image`",
              "`tabItem`.`net_amount`",
              "`tabItem`.`net_rate`",
              "`tabItem`.`price_list_rate`",
              "`tabItem`.`discount_amount`",
              "`tabItem Tax`.`item_tax_template`",
              "`tabItem`.`is_stock_item`",
              "`tabItem`.`has_variants`",
              "`tabItem`.`default_material_request_type`",
              "`tabItem`.`net_rate`",
              "`tabItem`.`standard_rate`",
              "`tabItem`.`item_code`",
              "`tabItem`.`description`",
              "`tabItem`.`item_group`",
              "`tabItem`.`total_projected_qty`",
            ],
            filters: [["Item Default", "company", "=", companySelected]],
          }),
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            if (Array.isArray(data)) {
              setItemList(data);
            }
          });
      }
    };
    const getCompanyList = () => {
      fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
          Authorization: JSON.parse(localStorage.getItem("user")).token,
        },
        body: JSON.stringify({
          doctype: "Company",
          fields: ["`tabCompany`.`name`"],
        }),
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          if (Array.isArray(data)) {
            setCompanyList(data);
          }
        });
    };
    const getTaxList = () => {
      if (companySelected) {
        fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
            Authorization: JSON.parse(localStorage.getItem("user")).token,
          },
          body: JSON.stringify({
            doctype: "Account",
            fields: [
              "`tabAccount`.`name`",
              "`tabAccount`.`owner`",
              "`tabAccount`.`creation`",
              "`tabAccount`.`modified`",
              "`tabAccount`.`modified_by`",
              "`tabAccount`.`_user_tags`",
              "`tabAccount`.`_comments`",
              "`tabAccount`.`_assign`",
              "`tabAccount`.`_liked_by`",
              "`tabAccount`.`docstatus`",
              "`tabAccount`.`parent`",
              "`tabAccount`.`parenttype`",
              "`tabAccount`.`parentfield`",
              "`tabAccount`.`idx`",
              "`tabAccount`.`account_name`",
              "`tabAccount`.`account_number`",
              "`tabAccount`.`disabled`",
              "`tabAccount`.`account_type`",
              "`tabAccount`.`tax_rate`",
            ],
            filters: [
              ["Account", "account_type", "=", "Tax"],
              ["Account", "company", "=", companySelected],
            ],
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            if (Array.isArray(data)) {
              setTaxList(data);
            }
          });
      }
    };
    const getAddressList = () => {
      if (supplierSelected) {
        fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
            Authorization: JSON.parse(localStorage.getItem("user")).token,
          },
          body: JSON.stringify({
            doctype: "Address",
            fields: [
              "`tabAddress`.`name`",
              "`tabAddress`.`owner`",
              "`tabAddress`.`creation`",
              "`tabAddress`.`modified`",
              "`tabAddress`.`modified_by`",
              "`tabAddress`.`_user_tags`",
              "`tabAddress`.`_comments`",
              "`tabAddress`.`_assign`",
              "`tabAddress`.`_liked_by`",
              "`tabAddress`.`docstatus`",
              "`tabAddress`.`parent`",
              "`tabAddress`.`parenttype`",
              "`tabAddress`.`parentfield`",
              "`tabAddress`.`idx`",
              "`tabAddress`.`address_type`",
              "`tabAddress`.`city`",
              "`tabAddress`.`disabled`",
            ],
            filters: [
              ["Address", "address_type", "=", "Billing"],
              ["Dynamic Link", "link_name", "=", supplierSelected],
            ],
          }),
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            if (Array.isArray(data)) {
              setAddressList(data);
            }
          });
      }
    };
    const getCurrencyList = () => {
      fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
          Authorization: JSON.parse(localStorage.getItem("user")).token,
        },
        body: JSON.stringify({
          doctype: "Currency",
          fields: [
            "`tabCurrency`.`name`",
            "`tabCurrency`.`owner`",
            "`tabCurrency`.`creation`",
            "`tabCurrency`.`modified`",
            "`tabCurrency`.`modified_by`",
            "`tabCurrency`.`_user_tags`",
            "`tabCurrency`.`_comments`",
            "`tabCurrency`.`_assign`",
            "`tabCurrency`.`_liked_by`",
            "`tabCurrency`.`docstatus`",
            "`tabCurrency`.`parent`",
            "`tabCurrency`.`parenttype`",
            "`tabCurrency`.`parentfield`",
            "`tabCurrency`.`idx`",
            "`tabCurrency`.`enabled`",
            "`tabCurrency`.`fraction`",
            "`tabCurrency`.`fraction_units`",
            "`tabCurrency`.`symbol`",
            "`tabCurrency`.`number_format`",
          ],
          filters: [["Currency", "enabled", "=", 1]],
        }),
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          if (Array.isArray(data)) {
            setCurrencyList(data);
          }
        });
    };
    const getConstactList = () => {
      if (supplierSelected) {
        fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
            Authorization: JSON.parse(localStorage.getItem("user")).token,
          },
          body: JSON.stringify({
            doctype: "Contact",
            fields: [
              "`tabContact`.`name`",
              "`tabContact`.`owner`",
              "`tabContact`.`creation`",
              "`tabContact`.`modified`",
              "`tabContact`.`modified_by`",
              "`tabContact`.`_user_tags`",
              "`tabContact`.`_comments`",
              "`tabContact`.`_assign`",
              "`tabContact`.`_liked_by`",
              "`tabContact`.`docstatus`",
              "`tabContact`.`parent`",
              "`tabContact`.`parenttype`",
              "`tabContact`.`parentfield`",
              "`tabContact`.`idx`",
              "`tabContact`.`email_id`",
              "`tabContact`.`status`",
              "`tabContact`.`phone`",
              "`tabContact`.`image`",
            ],
            filters: [
              ["Dynamic Link", "link_name", "=", supplierSelected],
              ["Contact", "status", "=", "Passive"],
            ],
          }),
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            if (Array.isArray(data)) {
              setContactList(data);
            }
          });
      }
    };
    const getTaxCategoryList = () => {
      fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
          Authorization: JSON.parse(localStorage.getItem("user")).token,
        },
        body: JSON.stringify({
          doctype: "Tax Category",
          fields: [
            "`tabTax Category`.`name`",
            "`tabTax Category`.`owner`",
            "`tabTax Category`.`creation`",
            "`tabTax Category`.`modified`",
            "`tabTax Category`.`modified_by`",
            "`tabTax Category`.`_user_tags`",
            "`tabTax Category`.`_comments`",
            "`tabTax Category`.`_assign`",
            "`tabTax Category`.`_liked_by`",
            "`tabTax Category`.`docstatus`",
            "`tabTax Category`.`parent`",
            "`tabTax Category`.`parenttype`",
            "`tabTax Category`.`parentfield`",
            "`tabTax Category`.`idx`",
            "`tabTax Category`.`title`",
            "`tabTax Category`.`disabled`",
          ],
          filters: [["Tax Category", "disabled", "=", 0]],
        }),
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          if (Array.isArray(data)) {
            setTaxCategoryList(data);
          }
        });
    };
    const getPriceList = () => {
      fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
          Authorization: JSON.parse(localStorage.getItem("user")).token,
        },
        body: JSON.stringify({
          doctype: "Price List",
          fields: [
            "`tabPrice List`.`name`",
            "`tabPrice List`.`price_list_name`",
            "`tabPrice List`.`buying`",
            "`tabPrice List`.`selling`",
            "`tabPrice List`.`currency`",
          ],
          filters: [["Price List", "buying", "=", 1]],
        }),
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          if (Array.isArray(data)) {
            setPriceList(data);
          }
        });
    };
    const getPurchaseTaxTemplateList = () => {
      if (companySelected) {
        fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
            Authorization: JSON.parse(localStorage.getItem("user")).token,
          },
          body: JSON.stringify({
            doctype: "Purchase Taxes and Charges Template",
            fields: [
              "`tabPurchase Taxes and Charges Template`.`name`",
              "`tabPurchase Taxes and Charges Template`.`owner`",
              "`tabPurchase Taxes and Charges Template`.`creation`",
              "`tabPurchase Taxes and Charges Template`.`modified`",
              "`tabPurchase Taxes and Charges Template`.`modified_by`",
              "`tabPurchase Taxes and Charges Template`.`_user_tags`",
              "`tabPurchase Taxes and Charges Template`.`_comments`",
              "`tabPurchase Taxes and Charges Template`.`_assign`",
              "`tabPurchase Taxes and Charges Template`.`_liked_by`",
              "`tabPurchase Taxes and Charges Template`.`docstatus`",
              "`tabPurchase Taxes and Charges Template`.`parent`",
              "`tabPurchase Taxes and Charges Template`.`parenttype`",
              "`tabPurchase Taxes and Charges Template`.`parentfield`",
              "`tabPurchase Taxes and Charges Template`.`idx`",
              "`tabPurchase Taxes and Charges Template`.`is_default`",
              "`tabPurchase Taxes and Charges Template`.`disabled`",
              "`tabPurchase Taxes and Charges Template`.`company`",
            ],
            filters: [
              [
                "Purchase Taxes and Charges Template",
                "company",
                "=",
                companySelected,
              ],
            ],
          }),
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            if (Array.isArray(data)) {
              setPurchaseTaxTemplateList(data);
            }
          });
      }
    };
    const handleDuplicate = () => {
      setLoadingButton(true);
      // const data = {
      //   doc:{
      //     naming_series: "ACC-PINV-.YYYY.-",
      //     supplier: supplierSelected,
      //     due_date: scheduleDate,
      //     company: companySelected,
      //     is_paid: 0,
      //     is_return: 0,
      //     currency: CurrencySelected,
      //     buying_price_list: priceSelected,
      //     price_list_currency: CurrencySelected,
      //     base_total: baseTotal,
      //     total_taxes_and_charges: totaxcharge,
      //     grand_total: grandTotal,
      //     apply_discount_on: "Grand Total",
      //     disable_rounded_total: 1,
      //     raw_materials_supplied: "No",
      //     contact_person: contactSelected,
      //     doctype: "Purchase Invoice",
      //     items: state.items.map((item) => {
      //       return {
      //         parentfield: "items",
      //         parenttype: "Purchase Invoice",
      //         item_name: item.item_name,
      //         qty: item.qty,
      //         rate: item.rate,
      //         amount: item.amount,
      //         expense_account: "Stock Received But Not Billed - PB",
      //         doctype: "Purchase Invoice Item"
      //         };
      //       }),
      //     taxes: state.taxes.map((tax)=>{
      //       return {
      //         parentfield: "taxes",
      //         parenttype: "Purchase Invoice",
      //         charge_type: "On Net Total",
      //         account_head: tax.account_head,
      //         description: tax.description,
      //         rate: tax.rate,
      //         cost_center: "Main - PB",
      //         account_currency: "TND",
      //         tax_amount: tax.tax_amount,
      //         doctype: "Purchase Taxes and Charges",
      //         };
      //     }),
      // },
      //   action: "Submit",
      // };
      const data = {
        doc: {
        
            "docstatus": 0,
            "doctype": "Purchase Order",
            "name": "new-purchase-order-2",
            "__islocal": 1,
            "__unsaved": 1,
             
            "naming_series": "ACC-PINV-.YYYY.-",
            "company":  companySelected,
            "posting_date": scheduleDate, 
            "is_return": 0,
            "apply_tds": 0,
            "currency": "TND",
            "supplier": supplierSelected, 
          "conversion_rate": 1,
          "supplier_name": supplierSelected,
          "supplier_address": addressSelected,
          "taxes_and_charges": purchaseTaxTemplateSelected,
            "items": state.items.map((item) => {
  
              return {
                
                    "docstatus": 0,
                    "doctype": "Purchase Order Item",
                    "name": "new-purchase-order-item-2",
                    "rate": item.standard_rate,
                    "amount": item.standard_rate,
                    "base_rate": item.base_rate,
                    "base_amount": item.base_amount,
                    "net_rate": item.net_rate,
                    "net_amount": item.net_amount,
                    "base_net_rate": item.base_net_rate,
                    "base_net_amount": item.base_net_amount,
                    "discount_amount": item.discount_amount,
                    "is_free_item": 0,
                    "item_group": item.item_group,
                    "stock_uom": "Nos",
                    "page_break": 0,
                    "parent": "new-purchase-order2",
        "parentfield": "items",
        "parenttype": "Purchase Order",
                    "idx": 1,
                    "qty": item.qty,
                    "stock_qty": 1,
                    "item_code": item.item_code,
                    "item_name": item.item_name,
                    "description": "test",
                    "transaction_date": scheduleDate,
                    "schedule_date": scheduleDateTill,
                    "has_margin": false,
                    "child_docname": "new-purchase-order-item-2",
                
              }
            }),
            "taxes": 
            state.taxes.map((tax)=>{ 
                return {
                       "docstatus": 0,
                       "doctype": "Purchase Taxes and Charges",
                       "account_currency": null,
                       "name": "new-purchase-taxes-and-charges-3",
                       "parent": "new-purchase-order-2",
                       "parentfield": "taxes",
                       "parenttype": "Purchase Order",
                       "idx": 1,
                       "account_head": tax.account_head,
                       "description":tax.description,
                       "rate": tax.tax_rate,
                }
            }),
          
        },
  
        action: "Save",
      }
      fetch(`${process.env.REACT_APP_API_URI}/api/save-docs`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
          Authorization: JSON.parse(localStorage.getItem("user"))?.token,
        },
        body: JSON.stringify(data),
      })
        .then((res) => {
          setLoadingButton(false);
          if (!res.ok) {
            dispatch(
              SetNotification({ code: "error", message: data?.message })
            );
          }
          return res.json();
        })
        .then((data) => {
          if (data?.success === false) {
            setLoadingButton(false);
            dispatch(
              SetNotification({ code: "warning", message: data?.message })
            );
          } else {
            setLoadingButton(false);
            dispatch(
              SetNotification({
                code: "success",
                message: `Purchase Order Duplicated successfully`,
              })
            );
            window.location.href = "/buying?tab=5";
            handleCancel();
          }
        });
    };

    const handleDateTillChange = (date, event) => {
      isNaN(Date.parse(date))
        ? setError({ ...error, scheduleDateTill: false })
        : setScheduleDateTill(date.toISOString().slice(0, 10));
    };
  
    useEffect(() => {
      getSupplierList();
      getCompanyList();
      getCurrencyList();
      getTaxCategoryList();
      getPriceList();
    }, []);
    useEffect(() => {
      getTaxList();
      getPurchaseTaxTemplateList();
      getItemList();
    }, [companySelected]);
    useEffect(() => {
      getAddressList();
      getConstactList();
    }, [supplierSelected]);
    const handleChangeSelectmultiple = (event) => {
      setError({ ...error, [event.target.name]: false });
      setState({ ...state, [event.target.name]: event.target.value });
    };

    //useEffect(() => {
      //console.log('Current state.items:', state.items);
    //}, [state.items]);

    //useEffect(() => {
     // console.log('itemList:', itemList);
    //}, [itemList]);

    const handleDateChange = (date, event) => {
      isNaN(Date.parse(date))
        ? setError({ ...error, scheduleDate: false })
        : setScheduleDate(date.toISOString().slice(0, 10));
    };
    const handleCancel = () => {
      setCloseModal(); // Call setCloseModal to close the modal
    };
    if (loading) {
      return <Loader />; // Render loader while data is being fetched
    }
    return (
      <Card
        sx={{
          boxShadow: 20,
          marginLeft: "-20px",
          marginRight: "-20px",
          marginTop: "-5px",
          marginBottom: "-12px",
        }}
      >
        <div style={{ margin: "15px" }}>
          <Grid item xs={12} style={{ marginTop: 20, fontWeight: "bold" }}>
            <Box
              display={"flex"}
              alignItems={"center"}
              style={{ fontSize: "1.25rem", fontWeight: 800 }}
            >
              <div style={{ color: "#2F4B7C" }}>
               Duplicate Purchase Order : {id}
              </div>
            </Box>
          </Grid>
        </div>
        <Divider orientation="horizontal" fullWidth />
        <div style={{ marginLeft: "40px" }}>
          <Grid container spacing={5} style={{ paddingLeft: "3%" }}>
            <Grid item xs={6}>
              <FormControl style={{ marginTop: 35 }}>
                <InputLabel
                  id="demo-simple-select-label"
                  style={{ fontSize: "16px" }}
                >
                  {t("common:supplier")}
                </InputLabel>
                <Select
                  className="CuFormInput"
                  style={{ width: "80%" }}
                  labelId="select-label"
                  id="simple-select"
                  value={supplierSelected}
                  label={t("common:supplier")}
                  onChange={(e) => {
                    setSupplierSelected(e.target.value);
                  }}
                >
                  {supplierList?.map((item) => (
                    <MenuItem style={{ fontSize: 12 }} value={item?.name}>
                      {item?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Grid style={{ marginTop: 25 }}>
                <MuiPickersUtilsProvider
                  padding={50}
                  locale={fr}
                  utils={DateFnsUtils}
                >
                  <InputLabel className={"label"}>{t("common:date")}</InputLabel>
                  <KeyboardDatePicker
                    className={"date"}
                    style={{ marginTop: 10, width: "80%" }}
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    name="scheduleDate"
                    inputVariant="outlined"
                    id="date-picker-inline"
                    value={scheduleDate}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              <Grid style={{ marginTop: 25 }}>
              <MuiPickersUtilsProvider
                padding={50}
                locale={fr}
                utils={DateFnsUtils}
              >
                <InputLabel className={"label"}>{t("common:RequiredBy")}</InputLabel>
                <KeyboardDatePicker
                  className={"date"}
                  style={{ marginTop: 10, width: "80%" }}
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  name="scheduleDateTill"
                  inputVariant="outlined"
                  id="date-picker-inline"
                  value={scheduleDateTill}
                  onChange={handleDateTillChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  minDate={formatDate(scheduleDate, "YYYY-MM-DD")}
                  minDateMessage={t("common:minDateMessage")}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth style={{ marginTop: 35 }}>
                <InputLabel
                  id="demo-simple-select-label"
                  style={{ fontSize: "16px" }}
                >
                  {t("common:company")}
                </InputLabel>
                <Select
                  className="CuFormInput"
                  style={{ width: "80%" }}
                  labelId="select-label"
                  id="simple-select"
                  value={companySelected}
                  label={t("common:company")}
                  onChange={(e) => setCompanySelected(e.target.value)}
                >
                  {companyList?.map((item) => (
                    <MenuItem style={{ fontSize: 12 }} value={item?.name}>
                      {item?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Grid style={{ marginTop: 28 , marginLeft : 10 }}>
              <Autocomplete
                                multiple
                                id="items-autocomplete"
                                options={itemList}
                                getOptionLabel={(option) => option.item_name + ' - ' + option.item_code}
                                value={state.items}
                                onChange={(event, newValue) => {
                                    handleChangeSelectmultiple({
                                        target: {
                                            name: 'items',
                                            value: newValue.map((item) => {
                                                const existingItem = state.items.find(
                                                    (stateItem) => stateItem.item_code === item.item_code
                                                );
                                                const matchedItem = itemPriceList.find(
                                                    (priceItem) => priceItem.item_code === item.item_code
                                                );
                                                const price = matchedItem ? matchedItem.price_list_rate : 0;
                                                return {
                                                    ...item,
                                                    qty: existingItem ? existingItem.qty : 0,
                                                    price_list_rate: price,
                                                    amount: existingItem ? existingItem.qty * price : 0
                                                };
                                            })
                                        }
                                    });
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t('common:Items')}
                                        error={!!error.items}
                                        helperText={error.items}
                                        fullWidth
                                        sx={{ width: '80%' }}
                                    />
                                )}
                            />
              </Grid>
            </Grid>
            {state.items.length > 0 ? (
              <Grid item xs={11}>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          style={{
                            fontWeight: "bold",
                            fontSize: 15,
                            textAlign: "center",
                          }}
                        >
                          {t("common:Items")}
                        </TableCell>
                        <TableCell
                          style={{
                            fontWeight: "bold",
                            fontSize: 15,
                            textAlign: "center",
                          }}
                        >
                          {t("common:qty")}
                        </TableCell>
                        <TableCell  style={{
                            fontWeight: "bold",
                            fontSize: 15,
                            textAlign: "center",
                          }}>{t("common:totalPrice")}</TableCell>
                        <TableCell  style={{
                            fontWeight: "bold",
                            fontSize: 15,
                            textAlign: "center",
                          }}>{t("common:Action")}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                                {state.items.map((item, index) => (
                                    <TableRow key={index}>
                                        <TableCell style={{ textAlign: 'center' }}>{item.item_name}</TableCell>
                                        <TableCell style={{ textAlign: 'center' }}>
                                            <TextField
                                                required
                                                type="number"
                                                value={item.qty}
                                                onChange={(e) => handleQuantityChange(index, e.target.value)}
                                            />
                                        </TableCell>
                                        <TableCell style={{ textAlign: 'center' }}>
                                            {isNaN(item.amount) ? 0 : item.amount.toFixed(2)}
                                        </TableCell>
                                        <TableCell style={{ textAlign: 'center' }}>
                                            <IconButton onClick={() => handleDeleteItem(index)}>
                                                <Delete color="error" />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            ) : null}
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel
                  id="demo-simple-select-label"
                  style={{ fontSize: "16px" }}
                >
                  {t("common:SupplierAddress")}
                </InputLabel>
                <Select
                  labelId="select-label"
                  className="CuFormInput"
                  style={{ width: "80%" }}
                  id="simple-select"
                  value={addressSelected}
                  label={t("common:SupplierAddress")}
                  onChange={(e) => {
                    setAddressSelected(e.target.value);
                  }}
                >
                  {addressList?.map((item) => (
                    <MenuItem style={{ fontSize: 12 }} value={item?.name}>
                      {item?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel
                  id=" simple-select-label"
                  style={{ fontSize: "16px" }}
                >
                  {t("common:ContactPerson")}
                </InputLabel>
                <Select
                  labelId="select-label-item"
                  className="CuFormInput"
                  style={{ width: "80%" }}
                  id="simple-select-item"
                  value={contactSelected}
                  label={t("common:ContactPerson")}
                  onChange={(e) => setContactSelected(e.target.value)}
                >
                  {contactList?.map((item) => (
                    <MenuItem style={{ fontSize: 12 }} value={item?.name}>
                      {item?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel
                  id=" simple-select-label"
                  style={{ fontSize: "16px" }}
                >
                  {t("common:currency")}
                </InputLabel>
                <Select
                  labelId="select-label-item"
                  className="CuFormInput"
                  style={{ width: "80%" }}
                  id="simple-select-item"
                  value={CurrencySelected}
                  label={t("common:currency")}
                  onChange={(e) => setCurrencySelected(e.target.value)}
                >
                  {CurrencyList?.map((item) => (
                    <MenuItem style={{ fontSize: 12 }} value={item?.name}>
                      {item?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel
                  id=" simple-select-label"
                  style={{ fontSize: "16px" }}
                >
                  {t("common:PriceList")}
                </InputLabel>
                <Select
                  labelId="select-label-item"
                  className="CuFormInput"
                  style={{ width: "80%" }}
                  id="simple-select-item"
                  value={priceSelected}
                  label={t("common:PriceList")}
                  onChange={(e) => setPriceSelected(e.target.value)}
                >
                  {priceList?.map((item) => (
                    <MenuItem style={{ fontSize: 12 }} value={item?.name}>
                      {item?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} style={{ margin: "20px", marginRight: "15%" }}>
              <Box style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  size="large"
                  disabled={loadingButton}
                  color="primary"
                  variant="contained"
                  onClick={handleDuplicate}
                  style={{ marginRight: "16px" }} // Add margin to separate buttons
                >
                  {t("common:save")}
                </Button>
                <Button
                  size="large"
                  color="error"
                  variant="contained"
                  onClick={handleCancel}
                >
                  {t("common:annuler")}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </div>
      </Card>
    );
  };

export default DuplicatePurchaseOrder;
