import { Box, Button, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  backLink: {
    fontSize: 12,
    color: "#2F334D",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    marginBottom: -35,
  },
  backIcon: {
    height: 10,
  },
}));

export const Header = (props) => {
  const { goBack } = props;
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation(["common"]);

  return (
    <Box>
      <Button
        onClick={() => (goBack ? history.push(goBack) : history.goBack())}
        color={"primary"}
        className={clsx(classes.title, classes.backLink)}
      >
        <ArrowBackIcon />
        {t("common:back")}
      </Button>
    </Box>
  );
};
