import React from 'react'
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { AddressForm } from '../components/createForm';
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
 root : {
    marginTop: "1rem"
 },
 backLink : {
    fontSize : 12,
    color : '#2F334D',
    display :"flex",
    alignItems : "center",
    cursor :"pointer"
},
backBox : {
    marginTop : "0.5rem",
    width : "fit-content"
}
}));
export const AddressCreate = () => {
    const classes = useStyles();
    const history = useHistory();
    const {t} = useTranslation(["common"]);
    return (
        <Box mt={10}>
            <Box className={classes.backBox}>
                <Typography
                    align={"center"}
                   // className={classes.title}
                    color={"primary"}
                    variant="caption"
                    className={classes.backLink}
                    onClick={() => history.goBack()}
                >
                    <ArrowBackIcon/>
                    {t("common:back")}
                </Typography>
            </Box>
            <Box className={classes.root} display="flex">
                <AddressForm/>
            </Box>
        </Box>
    )
}
