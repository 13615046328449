import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  gooey: {
    background: "white",
    position: "absolute",
    top: "50%",
    left: "50%",
    width: "142px",
    height: "40px",
    margin: "-20px 0 0 -71px",
    filter: "contrast(20)",
  },
  dot: {
    position: "absolute",
    width: "16px",
    height: "16px",
    top: "12px",
    left: "15px",
    filter: "blur(4px)",
    background: "#000",
    borderRadius: "50%",
    transform: "translateX(0)",
    animation: "$dot 2.8s infinite",
  },
  dots: {
    transform: "translateX(0)",
    marginTop: "12px",
    marginLeft: "31px",
    animation: "$dots 2.8s infinite",
  },
  span: {
    display: "block",
    float: "left",
    width: "16px",
    height: "16px",
    marginLeft: "16px",
    filter: "blur(4px)",
    background: "#000",
    borderRadius: "50%",
  },
  "@keyframes dot": {
    "50%": {
      transform: "translateX(96px)",
    },
  },
  "@keyframes dots": {
    "50%": {
      transform: "translateX(-31px)",
    },
  },
}));

export const Loader = () => {
  const classes = useStyles();

  return (
    <div className={classes.gooey}>
      <span className={classes.dot}></span>
      <div className={classes.dots}>
        <span className={classes.span}></span>
        <span className={classes.span}></span>
        <span className={classes.span}></span>
      </div>
    </div>
  );
};
