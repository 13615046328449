import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import clsx from 'clsx';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import {useTableStyles } from "../../../../styles/table.style";
import { Link } from 'react-router-dom';
import { formatDate, statusFormat } from '../../../../helpers/utils';
import {useTranslation} from "react-i18next";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  avatar : {
    width: 24,
    height: 24
  },
  avatarCell : {
    width :24
  },
  selectInput : {
    '& .MuiInputBase-root' : {
      background: "#F4F7F9",
      borderRadius : 5,
      border : 0
  },
  '& .MuiOutlinedInput-input' : {
      padding: "2.9px 14px",
      fontSize : 14
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border : 0
  }
  }
}));


export const  OrdersTable = (props) => {
  const classes = useStyles();
  const tableClasses = useTableStyles();
  const {t} = useTranslation(["common"]);
  const globalDefaults = useSelector(state => state.Login.globalDefaults);

  return (
    <Box className={tableClasses.paper} >
      <Table className={clsx(tableClasses.table, classes.selectInput)} aria-label="simple table">
        <TableHead >
          <TableRow>
            <TableCell  className={tableClasses.tablecellHeader}align="left">Date</TableCell>
            <TableCell  className={tableClasses.tablecellHeader} align="left">{t("client")}</TableCell>
            <TableCell  className={tableClasses.tablecellHeader} align="left">{t("client")}</TableCell>
            <TableCell  className={tableClasses.tablecellHeader} align="left">{t("statut")}</TableCell>
            <TableCell  className={tableClasses.tablecellHeader} align="left">{t("totalPrice")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.orders.map((row,index) => (  
            <TableRow component={Link} to={`/orders/${row.order_id}`} className={tableClasses.tableRow} key={index}>
                <TableCell className={tableClasses.tableCell} align="left">{formatDate(row.created_at, "DD/MM/YYYY HH:mm")}</TableCell>
                <TableCell className={tableClasses.tableCell} align="left">{row.order_id}</TableCell>
                <TableCell className={tableClasses.tableCell} align="left">{row.customer_name}</TableCell>
                <TableCell className={clsx(tableClasses.tableCell, tableClasses[statusFormat(row.status).color])} align="left">{statusFormat(row.status).value}</TableCell>
                <TableCell className={tableClasses.tableCell} align="left">{row.total_amount.toFixed(3)} {globalDefaults?.default_currency}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
}