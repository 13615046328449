import { Typography } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Products } from "../../../pages/transfertRequest/components/products";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export const useStyles = makeStyles((theme) => ({
  main: {
    padding: "0px 50px",
  },
  header: {
    padding: "30px 0",
  },
  logo: {
    textAlign: "center",
    marginBottom: 10,
  },
  title: {
    borderTop: "1px solid  #5D6975",
    borderBottom: "1px solid  #5D6975",
    color: "#5D6975",
    fontSize: "30px",
    lineHeight: "1.4em",
    fontWeight: "normal",
    textAlign: "center",
    margin: "0 0 20px 0",
  },
  root: {
    flexGrow: 1,
  },
  card: {
    minWidth: 200,
  },
  titlecard: {
    borderTop: "1px solid  #5D6975",
    borderBottom: "1px solid  #5D6975",
    color: "#5D6975",
    fontSize: "20px",
    lineHeight: "1.4em",
    fontWeight: "normal",
    textAlign: "center",
    margin: "0 0 20px 0",
  },
  paper: {
    overflow: "auto",
    padding: "0px 10px",
  },
  status: {
    padding: "5px 10px",
    marginTop: 20,
  },
  company: {
    float: "left",
    textAlign: "left",
  },
}));

const BonDeTransfert = React.forwardRef(({ data, doc }, ref) => {
  const classes = useStyles();
  const activeImageTheme = useSelector((state) => state.Theme.image);
  const { t } = useTranslation(["common", "transfert"]);

  return (
    <div className={classes.main} ref={ref}>
      <header className={classes.header}>
        <div className={classes.logo}>
          <img src={activeImageTheme} 
           style={{
            display: 'block',
            marginLeft: '40%',
            marginBottom: '30px',
            width: '15%'
        }}
          />
        </div>
        <Typography variant="h5" align="center" gutterBottom>
          Bon de Transfert: {doc?.name}
        </Typography>
      </header>
      <Typography
        align={"center"}
        color={"primary"}
        className={classes.subtitle}
      >
        {t("transfert:dateTransaction")}:{data?.transaction_date ?? "_"}
      </Typography>
      <Typography
        align={"center"}
        color={"primary"}
        className={classes.subtitle}
      >
        {t("transfert:schedule_date")}:{data?.schedule_date ?? "_"}
      </Typography>

      <div style={{ marginTop: 20 }}>
        <Typography ariant="h5" component="h2">
          De: {data?.set_from_warehouse}
        </Typography>
        <Typography ariant="h5" component="h2">
          A: {data?.set_warehouse}
        </Typography>
      </div>
      <Products orders={data?.items} />
    </div>
  );
});

export default BonDeTransfert;
