import React, { useEffect, useState } from "react";

import { useStyles } from "../../style";
import { useTableStyles } from "../../../../styles/table.style";
import { GlobalfilterData } from "../../../../helpers/helper";
import {
  Box,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  CircularProgress,
  Fade,
  Button,
  Collapse,
} from "@material-ui/core";
import {
  Radio,
  FormControl,
  FormControlLabel,
  Modal,
  Typography,
  InputLabel,
  OutlinedInput,
  ClickAwayListener,
  Select,
  MenuItem,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import clsx from "clsx";
import PaymentIcon from "@material-ui/icons/Payment";
import { Deposit } from "../deposit";
import { Calculator } from "../../../../components/calculator";
import Hold from "../../../../components/hold";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { formatDate } from "../../../../helpers/utils";
import { useTranslation } from "react-i18next";
import { SetTPEticketNumber } from "../../../../store/checkout/checkout.action";
import { Alert, AlertTitle } from "@material-ui/lab";

export const FormTpe = (props) => {
  const { handleRedirect } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [numfacture, setNumfacture] = useState("");
  const classes = useStyles();
  const { t } = useTranslation(["payement", "common", "checkout"]);
  const randomNumberInRange = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };
  const timeout = (delay) => {
    return new Promise((res) => setTimeout(res, delay));
  };
  const testtpe = async () => {
    const delaitpe = randomNumberInRange(4, 15) * 1000;
    await timeout(delaitpe);
    setLoading(false);
  };
  //await timeout(1000);
  useEffect(() => {
    testtpe();
  }, []);
  const handleConfirmer = () => {
    dispatch(SetTPEticketNumber(numfacture));
    handleRedirect();
  };
  return (
    <div className={classes.paper}>
      <Typography
        align={"center"}
        color={"primary"}
        variant="h6"
        style={{ fontWeight: "bold" }}
      >
        {loading ? t("payement:waittpe") : t("payement:paymentconfirmation")}
      </Typography>
      <Box
        style={{
          justifyContent: "space-between",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          padding: 20,
        }}
      >
        {loading ? (
          <Box
            style={{
              height: 40,
              margin: 20,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Fade
              in={loading}
              style={{
                transitionDelay: loading ? "800ms" : "0ms",
              }}
              unmountOnExit
            >
              <CircularProgress />
            </Fade>
          </Box>
        ) : (
          <Box style={{ display: "flex", flexDirection: "column", gap: 15 }}>
            <Collapse in={true}>
              <Alert severity="success">
                <AlertTitle>Success</AlertTitle>
                <strong>{t("payement:paymentCompletedSuccessfully")}</strong>
              </Alert>
            </Collapse>

            <FormControl style={{ width: "100%" }} variant="outlined">
              <InputLabel id="demo-simple-select-label">
                {t("payement:TPEticketnumber")}
              </InputLabel>
              <OutlinedInput
                label={t("payement:TPEticketnumber")}
                id="outlined-adornment-weight"
                aria-describedby="outlined-weight-helper-text"
                value={numfacture}
                placeholder={t("payement:TPEticketnumber")}
                onChange={(e) => {
                  setNumfacture(e.target.value);
                }}
              />
            </FormControl>

            <Button
              variant="contained"
              color="primary"
              size="large"
              disabled={!numfacture || numfacture === ""}
              onClick={() => handleConfirmer()}
            >
              {t("common:confirmer")}
            </Button>
          </Box>
        )}
      </Box>
    </div>
  );
};
