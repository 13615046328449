import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import Pagination from "@material-ui/lab/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../../components/loader";

import { LoadPurchaseOrderRequests } from "../../store/buying/buying.action";
import { PurReqTable } from "../buying/components/purchaseReqTable";
import axiosInstance from "../../history/axiosInstance";
import { useStyles } from "../buying/style";
 
import { useTranslation } from "react-i18next";
import {
  FilterBy,
  GetFilterItems,
  GlobalfilterData,
} from "../../helpers/helper";
import { FormControl, InputAdornment, OutlinedInput } from "@material-ui/core";
import { Filter } from "../../components/filter";
import { useTableStyles } from "../../styles/table.style";

export const PurchaseOrderList = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const tableclasses = useTableStyles();
  const { t } = useTranslation(["buying", "common"]);
  const globalDefaults = useSelector((state) => state.Login.globalDefaults);
  const purchaseOrderList = useSelector(
    (state) => state.Buying.purchaseOrderList
  );
  const [listPurchaseOrderList, setListPurchaseOrderList] = useState([]);
  const [valueSearch, setValueSearch] = useState("");
  const [filters, setFilters] = useState({
    status: [],
  });
  const maxitems = 20;
  const handleSearch = (event) => {
    setPage(1);
    setValueSearch(event.target.value);
    setListPurchaseOrderList(
      GlobalfilterData(
        filters?.status?.length > 0
          ? purchaseOrderList?.slice(0)?.filter(FilterBy(filters))
          : purchaseOrderList,
        event.target.value,
        ["name", "transaction_date", "supplier"]
      )
    );
  };
  const HandleFilter = (key, item) => {
    setPage(1);
    const FilterDataBy = { ...filters, [key]: item };
    setFilters(FilterDataBy);
    setListPurchaseOrderList(
      valueSearch
        ? GlobalfilterData(purchaseOrderList, valueSearch, [
            "name",
            "transaction_date",
            "supplier",
          ])
            ?.slice(0)
            ?.filter(FilterBy(FilterDataBy))
        : purchaseOrderList?.slice(0)?.filter(FilterBy(FilterDataBy))
    );
  };
  const [page, setPage] = useState(1);
  const [countPackage, setCountPackage] = useState([]);
  const header = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
    Authorization: JSON.parse(localStorage.getItem("user"))?.token,
  };
  const getCountGroupByPurchaseOrder = () => {
    return axiosInstance
      .get(
        `${process.env.REACT_APP_API_URI}/api/count-package-group-by-purchase-order`,
        { headers: header }
      )
      .then(({ data }) => {
        setCountPackage(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (globalDefaults?.default_company) {
      dispatch(LoadPurchaseOrderRequests(globalDefaults?.default_company));
      getCountGroupByPurchaseOrder();
    }
  }, [globalDefaults?.default_company]);

  if (!purchaseOrderList) return <Loader />;

  return (
    <Box>
      <Box className={classes.header}>
        <FormControl className={classes.formControlSearch} variant="outlined">
          <OutlinedInput
            id="outlined-adornment-weight"
            value={valueSearch}
            placeholder={t("common:rechercher")}
            onChange={handleSearch}
            endAdornment={
              <InputAdornment position="end">
                <span className="icon-search" />
              </InputAdornment>
            }
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              "aria-label": "weight",
            }}
            labelWidth={0}
          />
        </FormControl>
      </Box>
      <Box className={tableclasses.filterBlock}>
        <Box className={tableclasses.filter}>
          <Filter
            data={GetFilterItems([
              ...new Set(purchaseOrderList?.map((item) => item?.status)),
            ])}
            title={t("common:statut")}
            id={"status"}
            HandleFilter={HandleFilter}
            value={filters.status}
            number={filters.status.length}
          />
        </Box>
      </Box>
      <Box>
        <PurReqTable
          module={"wms"}
          countPackage={countPackage}
          purchaseOrderList={
            valueSearch || filters.status.length > 0
              ? listPurchaseOrderList.slice(
                  (page - 1) * maxitems,
                  page * maxitems
                )
              : purchaseOrderList.slice((page - 1) * maxitems, page * maxitems)
          }
        />
        {Math.ceil(
          (valueSearch || filters.status.length > 0
            ? listPurchaseOrderList?.length
            : purchaseOrderList.length) / maxitems
        ) > 0 && (
          <Box display="flex" justifyContent="center" m={4}>
            <Pagination
              onChange={(event, newPage) => setPage(newPage)}
              defaultPage={page}
              count={Math.ceil(
                (valueSearch || filters.status.length > 0
                  ? listPurchaseOrderList?.length
                  : purchaseOrderList.length) / maxitems
              )}
              color="primary"
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};
