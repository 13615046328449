import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import { useDispatch, useSelector } from "react-redux";
import { LoadPurchaseOrderRequests } from "../../../../store/buying/buying.action";
import { Loader } from "../../../../components/loader";
import { PurReqTable } from "../purchaseReqTable";
import Pagination from "@material-ui/lab/Pagination";
import {
  Button,
  FormControl,
  InputAdornment,
  OutlinedInput,
  Modal,
  ClickAwayListener,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useStyles } from "../../style";
import { useTableStyles } from "../../../../styles/table.style";
import {
  FilterBy,
  GetFilterItems,
  GlobalfilterData,
} from "../../../../helpers/helper";
import { Filter } from "../../../../components/filter";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ImportPurchaseOrder from './ImportPurchaseOrder';
import * as XLSX from "xlsx";

export const PurchaseRequest = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const tableclasses = useTableStyles();
  const { t } = useTranslation(["buying", "common"]);
  const globalDefaults = useSelector((state) => state.Login.globalDefaults);
  const selectedData = useSelector((state) => state.Login.selectedData);
  const purchaseOrderList = useSelector(
    (state) => state.Buying.purchaseOrderList
  );
  const [ openModalImport, setOpenModalImport ] = useState(false)
  const [page, setPage] = useState(1);
  const [listPurchaseOrderList, setListPurchaseOrderList] = useState([]);
  const [valueSearch, setValueSearch] = useState("");
  const [filters, setFilters] = useState({
    status: [],
  });

  const addModalImport = () => {
    setOpenModalImport(true);
    }
  const handleCloseModalImport = () => {
      setOpenModalImport(false);
    };
    const handleOnExport = () => {
      var wb = XLSX.utils.book_new();
      var ws = XLSX.utils.json_to_sheet(purchaseOrderList)
      XLSX.utils.book_append_sheet(wb, ws, "sheet1")
      XLSX.writeFile(wb, "Purchase_Order.xlsx")
  }
  const maxitems = 20;
  const handleSearch = (event) => {
    setPage(1);
    setValueSearch(event.target.value);
    setListPurchaseOrderList(
      GlobalfilterData(
        filters?.status?.length > 0
          ? purchaseOrderList?.slice(0)?.filter(FilterBy(filters))
          : purchaseOrderList,
        event.target.value,
        ["name", "creation", "set_warehouse"]
      )
    );
  };
  const HandleFilter = (key, item) => {
    setPage(1);
    const FilterDataBy = { ...filters, [key]: item };
    setFilters(FilterDataBy);
    setListPurchaseOrderList(
      valueSearch
        ? GlobalfilterData(purchaseOrderList, valueSearch, [
            "name",
            "transaction_date",
            "supplier",
          ])
            ?.slice(0)
            ?.filter(FilterBy(FilterDataBy))
        : purchaseOrderList?.slice(0)?.filter(FilterBy(FilterDataBy))
    );
  };
  useEffect(() => {
    if (globalDefaults?.default_company)
      dispatch(LoadPurchaseOrderRequests(globalDefaults?.default_company));
  }, [globalDefaults?.default_company]);

  const handleNavigation = () => {
    history.push("/purchase-order/create");
  };

  if (!purchaseOrderList) return <Loader />;

  return (
    <><Box>
      <Box className={classes.header}>
        <Button
          className={classes.custombutton}
          size={"small"}
          variant="contained"
          color="primary"
          onClick={handleNavigation}
        >
          {t("buying:CreateNewPurchaseOrder")}
        </Button>
        <Button
          style={{ marginLeft: 10, marginBottom: 10, marginTop: 10 }}
          size="small"
          color="primary"
          variant="outlined"
          onClick={addModalImport}
          startIcon={<CloudDownloadIcon />}
        >
          {t("common:importFile")}
        </Button>
        <Button
style={{ marginLeft: 10,marginBottom: 10, marginTop: 10, marginRight: 10 }}
size="small"
        color="primary"
        variant="outlined"
        onClick={handleOnExport}
        startIcon={<CloudUploadIcon />}
      >
         {t("common:exportFile")}
      </Button>
        <FormControl className={classes.formControlSearch} variant="outlined">
          <OutlinedInput
            id="outlined-adornment-weight"
            value={valueSearch}
            placeholder={t("common:rechercher")}
            onChange={handleSearch}
            endAdornment={<InputAdornment position="end">
              <span className="icon-search" />
            </InputAdornment>}
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              "aria-label": "weight",
            }}
            labelWidth={0} />
        </FormControl>
      </Box>
      <Box className={tableclasses.filterBlock}>
        <Box className={tableclasses.filter}>
          <Filter
            data={GetFilterItems([
              ...new Set(purchaseOrderList?.map((item) => item?.status)),
            ])}
            title={t("common:statut")}
            id={"status"}
            HandleFilter={HandleFilter}
            value={filters.status}
            number={filters.status.length} />
        </Box>
      </Box>
      <Box>
        <PurReqTable
          module={"purchase"}
          purchaseOrderList={valueSearch || filters.status.length > 0
            ? listPurchaseOrderList?.slice(
              (page - 1) * maxitems,
              page * maxitems
            )
            : purchaseOrderList.slice((page - 1) * maxitems, page * maxitems)} />
        {Math.ceil(
          (valueSearch || filters.status.length > 0
            ? listPurchaseOrderList?.length
            : purchaseOrderList.length) / 20
        ) > 0 && (
            <Box display="flex" justifyContent="center" m={4}>
              <Pagination
                onChange={(event, newPage) => setPage(newPage)}
                defaultPage={page}
                count={Math.ceil(
                  (valueSearch || filters.status.length > 0
                    ? listPurchaseOrderList?.length
                    : purchaseOrderList.length) / 20
                )}
                color="primary" />
            </Box>
          )}
      </Box>
    </Box><Modal
      open={openModalImport}
      aria-labelledby="simple-modal-import"
      aria-describedby="simple-modal-import"

    >
        <ClickAwayListener
          onClickAway={() => (setOpenModalImport ? setOpenModalImport(false) : null)}
        >
          <div className={classes.paper} style={{ overflowY: 'auto', maxHeight: '90vh', width: '50%' }}>
            <ImportPurchaseOrder setCloseModalImport={handleCloseModalImport} />
          </div>
        </ClickAwayListener>
      </Modal></>
  );
};
