import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useTableStyles } from "../../../../styles/table.style";
import { useStyles } from "../../style";
import { removeHTML } from "../../../../helpers/utils";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../../../history/axiosInstance";
import { useParams } from "react-router-dom";
import { Loader } from "../../../../components/loader";

export const ProductBundleDetails = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation(["common"]);
  const globalDefaults = useSelector((state) => state.Login.globalDefaults);
  const activeImageTheme = useSelector((state) => state.Theme.image);
  const imageList = useSelector((state) => state.Product.imagesData);
  const selectedProductBundle = useSelector(
    (state) => state.Product.selectedProductBundle
  );
  const tableClasses = useTableStyles();
  const { name } = useParams();
  const [docDetails, setDocDetails] = useState(null);
  const [parentDetails, setParentDetails] = useState(null);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchList = async () => {
    setLoading(true);
    await axiosInstance
      .get(
        `${process.env.REACT_APP_API_URI}/api/details?doctype=Product%20Bundle&name=${name}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
            Authorization: JSON.parse(localStorage.getItem("user")).token,
          },
        }
      )
      .then(async ({ data }) => {
        if (data?.docs?.[0]) {
          const responses = [];
          for (const item of data?.docs?.[0].items) {
            await axiosInstance
              .get(
                `${process.env.REACT_APP_API_URI}/api/details?doctype=Item&name=${item?.item_code}`,
                {
                  headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
                    Authorization: JSON.parse(localStorage.getItem("user"))
                      .token,
                  },
                }
              )
              .then(({ data: resp }) => {
                responses.push(resp?.docs?.[0]);
              });
          }

          setItems(responses);

          // setLoading(false);
          setDocDetails(data?.docs?.[0]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchDetails = async () => {
    await axiosInstance
      .get(
        `${process.env.REACT_APP_API_URI}/api/details?doctype=Item&name=${name}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
            Authorization: JSON.parse(localStorage.getItem("user")).token,
          },
        }
      )
      .then(({ data }) => {
        if (data?.docs?.[0]) {
          setParentDetails(data?.docs?.[0]);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function getImgUrl(itemCode) {
    if (imageList && imageList?.itemImages) {
      const IIndex = imageList?.itemImages?.findIndex(
        (e) => e.itemCode === itemCode
      );
      if (IIndex !== -1) {
        return (
          `${process.env.REACT_APP_API_URI}` +
          imageList?.itemImages?.[IIndex]?.imgUrl
        );
      } else {
        return activeImageTheme;
      }
    } else {
      return activeImageTheme;
    }
  }

  useEffect(() => {
    fetchList();
    fetchDetails();
  }, []);

  if (loading) return <Loader />;

  return (
    <Box className={classes.block}>
      <Box className={classes.titleblock}>
        <IconButton onClick={() => history.goBack()}>
          <ArrowBackIcon style={{ position: "initial" }} />
        </IconButton>
        <Typography
          align={"center"}
          className={classes.title}
          color={"primary"}
          variant="h5"
        >
          {t("common:productList")}
        </Typography>
        <Typography
          align={"center"}
          className={classes.name}
          color={"primary"}
          variant="h6"
        >
          {selectedProductBundle.name} - {selectedProductBundle.description}
        </Typography>
        <Typography
          align={"center"}
          className={classes.name}
          color={"primary"}
          variant="h6"
        >
          {parentDetails?.standard_rate?.toFixed(3)}{" "}
          {globalDefaults?.default_currency}
        </Typography>
      </Box>
      <Box className={classes.paper}>
        <Table className={tableClasses.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                className={tableClasses.tablecellHeader}
                align="left"
              ></TableCell>
              <TableCell className={tableClasses.tablecellHeader} align="left">
                {t("common:itemCode")}
              </TableCell>
              <TableCell className={tableClasses.tablecellHeader} align="left">
                {t("common:qty")}
              </TableCell>
              <TableCell className={tableClasses.tablecellHeader} align="left">
                {t("common:Description")}
              </TableCell>
              <TableCell className={tableClasses.tablecellHeader} align="left">
                {t("common:prix")}
              </TableCell>
              <TableCell className={tableClasses.tablecellHeader} align="left">
                Disponibilité
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {docDetails &&
              Array.isArray(docDetails.items) &&
              docDetails.items.map((row, index) => (
                <TableRow key={index} className={tableClasses.tableRow}>
                  <TableCell className={tableClasses.tableCell} align="left">
                    <img
                      src={getImgUrl(row?.item_code)}
                      alt="image"
                      style={{ height: 50, width: 50, borderRadius: 10 }}
                    />
                  </TableCell>
                  <TableCell className={tableClasses.tableCell} align="left">
                    {row.item_code}
                  </TableCell>
                  <TableCell className={tableClasses.tableCell} align="left">
                    {row.qty}
                  </TableCell>
                  <TableCell className={tableClasses.tableCell} align="left">
                    {removeHTML(row.description)}
                  </TableCell>
                  <TableCell className={tableClasses.tableCell} align="left">
                    {
                      items?.find((chld) => chld?.item_code === row?.item_code)
                        ?.standard_rate
                    }{" "}
                    {globalDefaults?.default_currency}
                  </TableCell>
                  <TableCell className={tableClasses.tableCell} align="left">
                    {items?.find((chld) => chld?.item_code === row?.item_code)
                      ?.__onload?.stock_exists === 1
                      ? t("common:inStock")
                      : t("common:outOfStock")}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};
