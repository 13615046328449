import React, { useEffect, useState } from "react";
import { Filter } from "../../components/filter";
import ClearIcon from "@material-ui/icons/Clear";
import { TextField, Typography } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { useTableStyles } from "../../styles/table.style";
import * as localforage from "localforage";
import {
  FilterBy,
  GetFilterItems,
  GlobalfilterData,
} from "../../helpers/helper";
import { useSelector } from "react-redux";
import { SimpleTable } from "../../components/simpleTable";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { Button, Chip, makeStyles, Box } from "@material-ui/core";
import CachedIcon from "@material-ui/icons/Cached";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 10,
    whiteSpace: "nowrap",
  },
  formControl: {
    maxWidth: 300,
    "& .MuiOutlinedInput-input": {
      padding: "8px 14px !important",
      fontSize: 12,
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: 10,
    },
  },
  button: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.blue.main,
    border: "1px solid",
    borderColor: theme.palette.secondary.main,
    fontSize: "0.875rem",
    borderRadius: 10,
  },
  icon: {
    marginRight: "0.375rem",
  },
  label: {
    fontSize: 12,
    color: theme.palette.gray.main,
    paddingLeft: 9,
  },
  date: {
    marginBottom: 15,
    marginRight: 15,
  },
  filterBlock: {
    "& .MuiOutlinedInput-input": {
      padding: "5.9px 14px !important",
      fontSize: 12,
    },
  },
}));

function removeDuplicates(originalArray, prop) {
  var newArray = [];
  var lookupObject = {};

  for (var i in originalArray) {
    lookupObject[originalArray[i][prop]] = originalArray[i];
  }

  for (i in lookupObject) {
    newArray.push(lookupObject[i]);
  }
  return newArray;
}

export const Stock = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation(["common", "product"]);
  const tableclasses = useTableStyles();

  const stockQtyReport = useSelector((state) => state.Inventory.stockQtyReport);

  const [filtredStock, setFiltredStock] = useState([]);
  const [categories, setCategories] = useState([]);
  const [warehouses, setWarehouses] = useState([]);

  const [page, setPage] = useState(1);
  const [searchProduct, setSearchProduct] = useState("");
  const [posProducts, setPosProducts] = useState([]);
  const [posProfileList, setPosProfileList] = useState([]);
  const store = useSelector((state) => state.Login.selectedData?.store);
  const hold = useSelector((state) => state.Checkout.holds);
  const [filteredPosProducts, setFilteredPosProducts] = useState([]);

  const [filters, setFilters] = useState(() => {
    const storedFilters = localStorage.getItem('stockFilters');
    if (storedFilters) {
      return JSON.parse(storedFilters);
    }
    return {
      warehouse: store ? [store] : [],
      item_group: [],
    };
  });

  const mergeAndFilterStockData = (stockData, posData, selectedWarehouse) => {
    const posDataMap = new Map(posData.map(item => [item.item_code, item]));
    
    return stockData
      .filter(stockItem => 
        posDataMap.has(stockItem.item_code) && 
        stockItem.warehouse === selectedWarehouse
      )
      .map(stockItem => {
        const posItem = posDataMap.get(stockItem.item_code);
        const reservedQty = isReservedInPOS(stockItem.item_code);
        const calculatedQty = posItem.actual_qty - reservedQty;
        return {
          ...stockItem,
          actual_qty: Math.max(calculatedQty, 0)
        };
      });
  };

  const isReservedInPOS = (item_code) => {
    let totalQtyReserved = 0;
    hold &&
      hold
        ?.filter((element) => element?.payment_status === "partly paid")
        ?.forEach((element) => {
          if (element?.amount?.payments.length > 0) {
            const reservedProducts = element?.products?.filter(
              (el) => el.item_code === item_code
            );
  
            reservedProducts?.forEach((reservedProduct) => {
              totalQtyReserved += reservedProduct.qty;
            });
          }
        });
  
    return totalQtyReserved;
  };

  useEffect(() => {
    getPOSProfileList();
    if (store) {
      getPosProductsList(store);
    }
  }, [store]);

  useEffect(() => {
    localStorage.setItem('stockFilters', JSON.stringify(filters));
  }, [filters]);

  const header = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-API-Key": `${process.env.REACT_APP_API_KEY}`,
    Authorization: JSON.parse(localStorage.getItem("user")).token,
  };

  const getPOSProfileList = () => {
    fetch(`${process.env.REACT_APP_API_URI}/api/get-list`, {
      method: "POST",
      headers: header,
      body: JSON.stringify({
        doctype: "POS Profile",
        fields: ['`tabPOS Profile`.`name`', '`tabPOS Profile`.`warehouse`'],
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (Array.isArray(data)) {
          setPosProfileList(data);
        }
      });
  };

  useEffect(() => {
    if (filters.warehouse.length > 0 && posProfileList.length > 0) {
      getPosProductsList(filters.warehouse[0]);
    }
  }, [filters.warehouse, posProfileList]);

  console.log(posProfileList, "posProfileList");

  const getPosProductsList = async (warehouse) => {
    try {
      const selectedPOSProfile = posProfileList.find(profile => profile.warehouse === warehouse);
      
      if (!selectedPOSProfile) {
        console.error('No POS profile found for the selected warehouse');
        return;
      }
  
      const response = await fetch(`${process.env.REACT_APP_API_URI}/api/pos/products`, {
        method: "POST",
        headers: header,
        body: JSON.stringify({
          pos_profile: selectedPOSProfile.name,
          price_list: "Standard Selling",
          item_group: "Nos",
          start: 0,
          page_length: 500,
          warehouse: warehouse
        }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to fetch Pos Products lists');
      }
  
      const data = await response.json();
      const posProducts = data.message.items;
      console.log(posProducts, "posProducts");
  
      const mergedAndFilteredData = mergeAndFilterStockData(stockQtyReport, posProducts, warehouse);
    setFiltredStock(mergedAndFilteredData);
    setFilteredPosProducts(mergedAndFilteredData);
  } catch (error) {
    console.error('Error fetching Pos Products lists:', error);
    setFiltredStock([]);
    setFilteredPosProducts([]);
  }
  };

   // console.log(posProducts, "posProducts");

  const columns = [
    { key: "item_code", title: "common:ref" },
    { key: "item_name", title: "Article" },
    { key: "item_group", title: "common:categorie" },
    { key: "brand", title: "common:brand" },
    { 
      key: "actual_qty", 
      title: "common:actualqty",
      render: (row) => Math.max(row.actual_qty - isReservedInPOS(row.item_code), 0)
    },
    { key: "indented_qty", title: "common:indentedqty" },
    { key: "ordered_qty", title: "common:orderedqty" },
    { key: "planned_qty", title: "common:plannedqty" },
    { key: "projected_qty", title: "common:projectedqty" },
    { key: "re_order_level", title: "common:reorderlvl" },
    { key: "re_order_qty", title: "common:reorderqty" },
    { key: "reserved_qty", title: "common:reservedqty" },
    { key: "stock_uom", title: "common:stockuom" },
    { key: "warehouse", title: "common:warehouse" },
  ];

  const handleRedirection = (item_code) => {
    return history.push(`/stock/${encodeURIComponent(item_code)}`);
  };

  const handleSearchProduct = (event) => {
    setPage(1);
    setSearchProduct(event.target.value);
    setFiltredStock(
      GlobalfilterData(filteredPosProducts, event.target.value, [
        "item_name",
        "item_code",
        "item_group",
      ])
    );
  };

  const HandleFilter = (key, item) => {
    setPage(1);
    const FilterDataBy = { ...filters, [key]: item };
    setFilters(FilterDataBy);
  
    if (key === 'warehouse') {
      if (item.length > 0) {
        getPosProductsList(item[0]);
      } else {
        setFiltredStock([]);
        setFilteredPosProducts([]);
      }
    } else {
      const filteredData = filteredPosProducts.filter(FilterBy(FilterDataBy));
      setFiltredStock(filteredData);
    }
  };

  const HandleDeleteAll = () => {
    setPage(1);
    const data = {
      item_group: [],
      warehouse: [],
    };
    setFilters(data);
    setFiltredStock([]);
    setFilteredPosProducts([]);
    localStorage.removeItem('stockFilters');
  };

  const HandleCloseChip = (item, key) => {
    setPage(1);
    const FilterDataBy = {
      ...filters,
      [key]: filters[key].filter((f) => f !== item),
    };
    setFilters(FilterDataBy);
  
    if (key === 'warehouse' && FilterDataBy.warehouse.length === 0) {
      setFiltredStock([]);
      setFilteredPosProducts([]);
    } else if (key === 'warehouse' && FilterDataBy.warehouse.length > 0) {
      getPosProductsList(FilterDataBy.warehouse[0]);
    } else {
      const filteredData = filteredPosProducts.filter(FilterBy(FilterDataBy));
      setFiltredStock(filteredData);
    }
  };

  useEffect(() => {
    localforage.getItem("reduxPersist:Inventory", function (err, value) {
      const inventoryData = JSON.parse(value)?.stockQtyReport;
      if (inventoryData) {
        const data = [];
        const warehousesList = [];
        inventoryData.forEach((el) => {
          data.push({ label: el.item_group, value: el.item_group });
          warehousesList.push({ label: el.warehouse, value: el.warehouse });
        });
        setWarehouses(removeDuplicates(warehousesList, "value"));
        setCategories(removeDuplicates(data, "value"));
      }
    });
  }, []);

  return (
    <div className={classes.root}>
      <Box style={{ display: "flex", justifyContent: "flex-end" }}>
        <Box className={classes.header}>
          <TextField
            id="outlined-adornment-weight"
            value={searchProduct}
            placeholder={t("common:rechercher")}
            onChange={handleSearchProduct}
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              "aria-label": "weight",
            }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.push("/products")}
            startIcon={<CachedIcon />}
          >
            {t("common:produits")}
          </Button>
        </Box>
      </Box>
      <Box className={tableclasses.filterBlock}>
        <Box className={tableclasses.filter}>
          <Filter
            data={warehouses}
            title={t("common:magasin")}
            id={"warehouse"}
            HandleFilter={HandleFilter}
            value={filters.warehouse}
            number={filters.warehouse.length}
          />
          <Filter
            data={categories}
            title={t("common:categorie")}
            id={"item_group"}
            HandleFilter={HandleFilter}
            value={filters.item_group}
            number={filters.item_group.length}
          />
        </Box>
      </Box>

      <Box display="flex" flexWrap="wrap" alignItems="center">
        {filters.item_group.map((filter, i) => (
          <Chip
            key={i}
            className={tableclasses.chip}
            label={filter}
            onDelete={() => HandleCloseChip(filter, "item_group")}
            color="secondary"
            size={"small"}
            deleteIcon={<ClearIcon />}
          />
        ))}
        {filters?.warehouse?.map((filter, i) => (
          <Chip
            key={i}
            className={tableclasses.chip}
            label={filter}
            onDelete={() => HandleCloseChip(filter, "warehouse")}
            color="secondary"
            size={"small"}
            deleteIcon={<ClearIcon />}
          />
        ))}
        {(filters.warehouse.length > 0 || filters.item_group.length > 0) && (
          <Typography onClick={HandleDeleteAll} className={tableclasses.remove}>
            {t("common:delete")}
          </Typography>
        )}
      </Box>
      <Box>
      {filters.warehouse.length > 0 ? (
  <SimpleTable
  title={"stock"}
  data={filtredStock.slice((page - 1) * 20, page * 20)}
  columns={columns}
  hasPaper={true}
  isRowClickable={true}
  handleRowClick={handleRedirection}
  rowPrimaryKey={"item_code"}
/>
) : (
  <Typography variant="body1" align="center">
    Veuillez sélectionner un magasin pour afficher la liste des stocks de produits.
  </Typography>
)}
        {filters.warehouse.length > 0 && filtredStock.length > 0 && (
  <Box display="flex" justifyContent="center" m={4}>
    <Pagination
      onChange={(event, newPage) => setPage(newPage)}
      page={page}
      count={Math.ceil(filtredStock.length / 20)}
      color="primary"
    />
  </Box>
)}
      </Box>
    </div>
  );
};
