import { CLEAR_STATE } from "../login/login.type";
import {
  INVENTORY_LOADED,
  STOCK_SUMMARY_LOADED,
  STOCK_REPORT_LOADED,
  STOCK_QTY_DATA_LOADED,
  SET_CHECKED_ITEMS,
  INV_LISTS_LOADED,
  INV_LIST_LOADED,
  SET_LIST_EDIT,
  UPDATE_SELECTED_PARAMS,
  STOCK_REPORT_DATA_LOADED,
} from "./inventory.type";

const initialState = {
  inventory: [],
  stockSummary: [],
  stockReport: null,
  stockQtyReport: [],
  checkedItems: [],
  invLists: [],
  invList: null,
  listToEdit: null,
  params: {
    description: false,
    group: false,
    price: false,
    collection: false,
    location: false,
    openingStock: false,
    originCountry: false,
    isStock: false,
    ref: true,
    qteReel: true,
    qteProj: false,
    qteResv: false,
    magasin: true,
    qteTrouv: true,
    nameProd: true,
    all: false,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case INVENTORY_LOADED:
      return {
        ...state,
        inventory: action.inventory,
        invList: null,
      };
    case UPDATE_SELECTED_PARAMS:
      return {
        ...state,
        params: action.params,
      };
    case STOCK_SUMMARY_LOADED:
      return {
        ...state,
        stockSummary: JSON.parse(action.payload).message,
      };
    case STOCK_REPORT_LOADED:
      return {
        ...state,
        stockReport: JSON.parse(action.payload).message,
      };
    case STOCK_QTY_DATA_LOADED:
      state.stockQtyReport = null;
      let result = JSON.parse(action.payload).message.result;
      result.pop();
      return {
        ...state,
        stockQtyReport: result,
      };
    case STOCK_REPORT_DATA_LOADED:
      state.stockQtyReport = null;
      let res = action.payload.message.result;
      res.pop();
      return {
        ...state,
        stockQtyReport: res,
      };
    case SET_CHECKED_ITEMS:
      return {
        ...state,
        checkedItems: action.checkedItems,
        invList: null,
      };
    case INV_LISTS_LOADED:
      return {
        ...state,
        invLists: action.payload,
        invList: null,
      };
    case INV_LIST_LOADED:
      return {
        ...state,
        invList: action.payload,
        checkedItems: [],
      };
    case SET_LIST_EDIT:
      return {
        ...state,
        listToEdit: action.list,
        checkedItems: action.list ? action.list.products : [],
      };
    case CLEAR_STATE:
      return (state = initialState);
    default:
      return state;
  }
};

export default reducer;
