import { combineReducers } from "redux";
import Configuration from "../configuration/configuration.reducer";
import Theme from "../theme/theme.reducer";
import Login from "../login/login.reducer";
import Client from "../client/client.reducer";
import Notification from "../notification/notification.reducer";
import Product from "../product/product.reducer";
import itemFinancierList from "../productFinancier/productFinancier.reducer";
import Rollback from "../rollback/rollback.reducer";
import Checkout from "../checkout/checkout.reducer";
import Inventory from "../inventory/inventory.reducer";
import Initialization from "../initialization/initialization.reducer";
import Pos from "../pos/pos.reducer";
import Fullfilment from "../fullfilment/fullfilment.reducer";
import Setting from "../setting/setting.reducer";
import BroadcastNotification from "../broadcastNotification/broadcastNotification.reducer";
import Transfert from "../transferRequest/transferRequest.reducer";
import StockEntry from "../stockEntries/stockEntries.reducer";
import Page from "../page/page.reducer";
import Buying from "../buying/buying.reducer";
import Cart from "../cart/cart.reducer";
import ProductFinancierCheckout from "../../components/productItem/productFinancier";

export const rootReducer = combineReducers({
  Configuration,
  Theme,
  Login,
  Client,
  Notification,
  Product,
  Checkout,
  Inventory,
  Initialization,
  Rollback,
  Pos,
  Fullfilment,
  Setting,
  BroadcastNotification,
  Transfert,
  StockEntry,
  Page,
  Buying,
  itemFinancierList,
  Cart,
});
