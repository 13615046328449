import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useStyles } from "./style";
import { useSelector } from "react-redux";
import { Box, Typography, Divider, IconButton, Card } from "@material-ui/core";
import Barcode from "react-barcode";
import PrintIcon from "@material-ui/icons/Print";
import CloseIcon from "@material-ui/icons/Close";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import { useTranslation } from "react-i18next";
import ReactToPrint from "react-to-print";
import { symbol } from "../../../helpers/utils";

export const CadeauComponentToPrint = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const componentRef = useRef();
  const activeImageTheme = useSelector((state) => state.Theme.image);
  const client = useSelector((state) => state.Client.client);
  const currencies = useSelector((state) => state.Client.currencies);
  const pricingRuleClient = useSelector(
    (state) => state.Client.pricingRuleClient
  );
  const globalDefaults = useSelector((state) => state.Login.globalDefaults);
  const { t } = useTranslation(["common", "clients", "order"]);

  const selectedData = useSelector((state) => state.Login.selectedData);

  const currencySymbol =
    client && client.default_currency
      ? symbol(client.default_currency, currencies)?.symbol
      : globalDefaults?.default_currency;

  return (
    <div className={classes.paper}>
      <Box display="flex" justifyContent="flex-end">
        <ReactToPrint
          trigger={() => (
            <IconButton aria-label="print">
              <PrintIcon color="primary" />
            </IconButton>
          )}
          content={() => componentRef.current}
        />
        <IconButton aria-label="print">
          <CloseIcon color="primary" onClick={props.handleClose} />
        </IconButton>
      </Box>
      <Card className={classes.card} ref={componentRef}>
        <Box justifyContent="center" display="flex">
          <img width={65} height={65} src={activeImageTheme} 
           style={{
            display: 'block',
            marginLeft: '40%',
            marginBottom: '30px',
            width: '15%'
        }}
          />
        </Box>
        <Box mt={2} className={classes.ticketHeader}>
          <Box align={"center"}>{t("clients:BC")}</Box>
          <Box fontWeight={"normal"} fontSize={"12px"} align={"center"}>
            {t("clients:msg")}
            <IconButton color="primary" component="span">
              <FavoriteBorderIcon fontSize="small" />
            </IconButton>
          </Box>
        </Box>
        <Box mt={1} justifyContent="center">
          <Typography align={"center"}>
            Chère {props.state.row && props.state.row.customer}
          </Typography>
        </Box>
        <Box mt={1} justifyContent="center">
          <Typography
            align={"center"}
            style={{ fontWeight: "bold", marginBottom: 20 }}
          >
            Vous avez reçu{" "}
            {pricingRuleClient && pricingRuleClient?.discount_amount.toFixed(3)}{" "}
            {currencySymbol} <br />à {globalDefaults?.default_company}
          </Typography>
        </Box>
        <Box mt={1} justifyContent="center">
          <Typography align={"center"}>
            La carte cadeau est valable jusqu'à <br />{" "}
            {props.state.row && props.state.row.valid_upto}
          </Typography>
        </Box>
        <Box justifyContent="center" align={"center"}>
          <Barcode
            format="CODE128"
            width={0.5}
            height={30}
            style={{ fontSize: 3 }}
            value={props.state.row && props.state.row.coupon_code}
          />
        </Box>
        <Divider variant="middle" />
        {selectedData &&
          selectedData.contact &&
          Array.isArray(selectedData.contact) &&
          selectedData.contact.map((row, i) => (
            <Box key={i}>
              <Box fontWeight={"normal"} fontSize={"12px"} align={"center"}>
                {row.address_line_1 === null ? "-" : row.address_line_1}
              </Box>
              <Box fontWeight={"normal"} fontSize={"12px"} align={"center"}>
                Ville: {row.city === null ? "-" : row.city}
              </Box>
              <Box fontWeight={"normal"} fontSize={"12px"} align={"center"}>
                TELs: {row.phone_no === null ? "-" : row.phone_no} /{" "}
                {row.mobile_no === null ? "-" : row.mobile_no}
              </Box>
            </Box>
          ))}
      </Card>
    </div>
  );
});
