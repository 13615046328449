import React, {useEffect, useState} from "react";
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";
import * as localforage from "localforage";
import {useTranslation} from "react-i18next";
import DataRender from '../../../components/tables/DataRender';
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min';
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import {IconButton} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import {GlobalfilterData} from "../../../helpers/helper";
import {useSelector} from "react-redux";

const useStyles = makeStyles((theme) => ({
    header: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        margin: "0.75rem 0px 0.8125rem",
        flexWrap: "wrap",
        "& .MuiInputBase-root": {
            background: theme.palette.white.main,
        },
        "& .MuiOutlinedInput-input": {
            padding: "8.9px 14px",
            fontSize: 12,
        },
    },
    formControl: {
        maxWidth: 300,
        [theme.breakpoints.down("xs")]: {
            marginTop: 10,
        },
    },
    button: {
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.blue.main,
        border: "1px solid",
        borderColor: theme.palette.blue.main,
        fontSize: "0.875rem",
        borderRadius: 10,
        "&:hover": {
            backgroundColor: theme.palette.secondary.light,
            borderColor: theme.palette.blue.main,
        },
    },
    icon: {
        marginRight: "0.375rem",
    },
}));

export const Clients = () => {
    const classes = useStyles();
    const {t} = useTranslation(["common", "clients"]);
    const history = useHistory();

    const [filtredClients, setFiltredClients] = React.useState([]);
    const [selectionModel, setSelectionModel] = useState([]);
    const [searchClient, setSearchClient] = React.useState("");

    const clients = useSelector((state) => state.Client.clients);

    useEffect(
        () => {
            localforage.getItem("reduxPersist:Client", function (err, value) {
                setFiltredClients(JSON.parse(value).clients);
            });
        },
        []
    );

    const columns = [
        {
            field: 'customer_name',
            headerName: 'Nom complet',
            flex: 1
        },
        {
            field: 'customer_group',
            headerName: 'Groupe Client',
            flex: 1
        },
        {
            field: 'customer_type',
            headerName: 'Type de Client',
            flex: 1
        },
        {
            field: 'territory',
            headerName: 'Région',
            // type: 'number',
            flex: 1
        },
        {
            field: 'cin',
            headerName: 'CIN',
            flex: 1
        },
        {
            field: 'email_id',
            headerName: 'Email',
            flex: 1
        },
        {
            field: 'mobile_no',
            headerName: 'Mobile',
            flex: 1
        },
    ];

    const columnVisibilityModel = {
        cin: false,
        email_id: false,
        mobile_no: false,
    };

    const handleShowDetails = (row) => {
        history.push(`/clients/${row}`)
    }

    const SearchClients = (event) => {
        setSearchClient(event.target.value);
        setFiltredClients(
            GlobalfilterData(clients, event.target.value, [
                "email_id",
                "customer_name",
                "mobile_no",
                "cin",
            ])
        );
    };

    return (
        <Box>
            <Box className={classes.header}>
                <Link to={"/client/create"}>
                    <Button
                        className={classes.button}
                        size={"small"}
                        variant="outlined"
                        color="primary"
                    >
                        <span className={clsx("icon-add-user", classes.icon)}/>{" "}
                        {t("clients:newClient")}
                    </Button>
                </Link>
            </Box>
            <Box>
                <DataRender
                    data={filtredClients}
                    loading={false}
                    selectionModel={selectionModel}
                    setSelectionModel={setSelectionModel}
                    handleEdit={handleShowDetails}
                    tag={'customer_name'}
                    noActions={false}
                    isViewAction={true}
                    columnsDef={columns}
                    columnVisibilityModel={columnVisibilityModel}
                />
            </Box>
        </Box>
    );
};
