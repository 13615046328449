import {makeStyles} from '@material-ui/core/styles';
//import Image from './bg.jpg';

export const useStyles = makeStyles((theme) => ({

    root: {
        padding: "1.0625rem 1.5rem",
    },
    price: {
        fontWeight: 600,
    },
    filterList: {
        borderTop: 'none'
    },
    button: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    content: {
        flex: '1 0 auto',
    },
    title: {
        fontSize: 40,
        align: "center"
    },
    formControl: {
        marginBottom: 15,
        "& .MuiInputBase-root": {
            background: theme.palette.white.main,
        },
        "& .MuiOutlinedInput-input": {
            padding: "8.9px 14px",
            fontSize: 12,
        },
    },
    paper: {
        paddingLeft: 10,
        width: "100%",
        "& .MuiInputBase-root": {
            background: theme.palette.white.main,
        },
        "& .MuiOutlinedInput-input": {
            padding: "8.9px 14px",
            fontSize: 12,
        },
        "& .MuiSelect-root": {
            padding: "0.4938rem 0.875rem",
        },
    },
    label: {
        fontSize: 12,
        color: theme.palette.primary.main,
        marginBottom: 6,
        textAlign: "start",

    },
    errorLabel: {
        fontSize: 12,
        marginBottom: 6,
        textAlign: "start",
        color: "red"
    },
    firstCol: {
        [theme.breakpoints.up("md")]: {
            marginRight: 37,
        },
    },
    formBlock: {
        display: "flex",
        [theme.breakpoints.down("sm")]: {
            flexWrap: "wrap",
        },
    },
    paperMsg: {
        textAlign: "center",
        position: "absolute",
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        borderRadius: 10,
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    },
    ticketHeader: {
        marginBottom: "7px",
        textAlign: "center",
        fontWeight: "bold"
    },
    ticket: {
        '&:after': {
            content: '""',
            display: "block",
            height: "70px",
            backgroundImage: "url(/img/border.png)",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center bottom",
            marginTop: '-1.8625rem',
        }
    },
    ticketBlock: {
        textAlign: "center",
        boxShadow: "0px 5px 15px 4px #0000000F",
        [theme.breakpoints.up('md')]: {
            minWidth: 420
        },
        fontFamily: 'Courier Prime',
        color: "#707070",
        fontSize: 14,
        padding: "2rem 0.5rem",
        lineHeight: 2
    },
    instructions: {
        fontSize: 12,
    },
    modalButton: {
        marginLeft: 5,
        marginRight: 5
    },
}));
