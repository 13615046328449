import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  modalTitle: {
    borderTop: "1px solid  #5D6975",
    borderBottom: "1px solid  #5D6975",
    color: "#5D6975",
    fontSize: "30px",
    lineHeight: "1.4em",
    fontWeight: "normal",
    textAlign: "center",
    margin: "0 0 20px 0",
  },
  root: {
    flexGrow: 1,
    position: "absolute",
    width: 700,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2),
    borderRadius: 10,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  },
  card: {
    minWidth: 200,
  },
  titlecard: {
    borderTop: "1px solid  #5D6975",
    borderBottom: "1px solid  #5D6975",
    color: "#5D6975",
    fontSize: "20px",
    lineHeight: "1.4em",
    fontWeight: "normal",
    textAlign: "center",
    margin: "0 0 20px 0",
  },
  paper: {
    overflow: "auto",
    padding: "0px 10px",
  },
  status: {
    padding: "5px 10px",
    marginTop: 20,
  },
  company: {
    float: "left",
    textAlign: "left",
  },
  title: {
    fontWeight: "bold",
    fontSize: 20,
    marginBottom: theme.spacing(2),
  },
  subtitle: {
    fontSize: 15,
    marginBottom: theme.spacing(1),
  },
  avatar: {
    width: 24,
    height: 24,
  },
  avatarCell: {
    width: 24,
  },
  selectInput: {
    "& .MuiInputBase-root": {
      background: "#F4F7F9",
      borderRadius: 5,
      border: 0,
    },
    "& .MuiOutlinedInput-input": {
      padding: "2.9px 14px",
      fontSize: 14,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: 0,
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  color: {
    color: theme.palette.blue.main,
    margin: theme.spacing(1),
  },
  backgroundColor: {
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.primary.main,
    fontWeight: 700,
    fontSize: 14,
  },
}));
