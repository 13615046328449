import { CLEAR_STATE } from "../login/login.type";
import {
  UPDATE_SAFEBOX,
  CLOSED_ENTRY_LOADED,
  POS_INVOICES_LOADED,
  SUCCESS_GET_CLOSED_POS_BY_ID,
  CREATE_SESSION_SUCCESS,
  CREATE_CLOSE_POS_ENTRY_SUCCESS,
  RESET_CLOSE_ENTRY,
  SESSION_LOADED,
  COFFRE_OP_LOADED,
  COFFRE_OP_LOADED_ID,
  LOAD_CLOSED_POS,
} from "./pos.type";

const initialState = {
  safeBox: null,
  safeBoxActions: [],
  closedEntry: [],
  invoices: [],
  open: null,
  closedPosSucces: null,
  selectedPos: null,
  closedPostoShow: null,
  status: false,
  coffreOperationDetails: null,
  coffreOperations: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CLOSED_ENTRY_LOADED:
      return {
        ...state,
        closedEntry: action.payload,
      };
    case POS_INVOICES_LOADED:
      return {
        ...state,
        invoices: JSON.parse(action.payload).message,
      };
    case CREATE_SESSION_SUCCESS:
      return {
        ...state,
        open: JSON.parse(action.payload).message,
        closedPosSucces: null,
      };
    case SESSION_LOADED:
      return {
        ...state,
        selectedPos: action.open.message,
      };
    case SUCCESS_GET_CLOSED_POS_BY_ID:
      return {
        ...state,
        closedPostoShow: JSON.parse(action.payload).docs[0],
      };
    case CREATE_CLOSE_POS_ENTRY_SUCCESS:
      return {
        ...state,
        open: null,
        status: true,
        closedPosSucces: action.doc.docs[0],
      };
    case RESET_CLOSE_ENTRY:
      return {
        ...state,
        closedPosSucces: null,
      };
    case UPDATE_SAFEBOX:
      return {
        ...state,
        safeBox: state.safeBox + action.amount,
        safeBoxActions: [
          ...state.safeBoxActions,
          {
            Amount: action.amount,
            Date: action.date,
            Cashier: state.open.pos_profile,
          },
        ],
      };
    case COFFRE_OP_LOADED:
      return {
        ...state,
        coffreOperations: action.payload,
      };
    case COFFRE_OP_LOADED_ID:
      return {
        ...state,
        coffreOperationDetails: action.payload,
      };
    case LOAD_CLOSED_POS:
      return {
        ...state,
        closedPostoShow: action.closedEntry,
      };
    case CLEAR_STATE:
      return (state = initialState);
    default:
      return state;
  }
};

export default reducer;
