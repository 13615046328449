import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import { LoadProductBundleByName } from "../../store/product/product.action";
import { useStyles } from "./style";
import { useTranslation } from "react-i18next";

export const ProductBundle = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation(["common", "buying"])


  const handleDetailPage = () => {
    history.push(`/products_bundle/${props.product.name}`);
    dispatch(LoadProductBundleByName(props.product.name));
  };

  return (
    <Card className={classes.root} variant="outlined">
      <CardContent>
        <Typography
          className={classes.title}
          color="textSecondary"
          gutterBottom
        >
          {props.product ? props.product.name : ""}
        </Typography>
        <Typography
          className={classes.pos}
          variant="caption"
          display="block"
          gutterBottom
        >
          {props.product ? props.product.new_item_code : ""}
        </Typography>
        <Typography variant="body2" component="p">
          {props.product ? props.product.description : ""}
        </Typography>
      </CardContent>

      <CardActions>
        <Button
          className={classes.button}
          size="small"
          variant="contained"
          color="primary"
          onClick={() => handleDetailPage()}
        >
          {t("common:details")}
        </Button>
      </CardActions>
    </Card>
  );
};
