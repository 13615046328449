import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles((theme)=>({
  card : {
    borderRadius: 10,
    backgroundColor: theme.palette.productsTable.background,
    border : "none",
    height: 90
  },
  cardBoldText : {
    color: theme.palette.primary.main,
  },
  cardTitleBlock : {
    borderBottom: "1px solid #e7eaef",
  },
  cardTitle : {
    textAlign : 'center'
  },
  cursor : {
    cursor: "pointer"
  },
  icon : {
    marginLeft:10
  },
  selectCard : {
    backgroundColor : theme.palette.secondary.light,
    border : "2px solid",
    borderColor : theme.palette.blue.main,
    fontSize : "0.875rem",
    borderRadius : 5,
    width : 103,
  }
}))