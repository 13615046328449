import { CLEAR_STATE } from "../login/login.type";
import {
  UPDATE_THEME_STYLE,
  LOAD_THEMES,
  UPDATE_THEMES,
  ACTIVE_THEME,
  GLOBAL_COLOR,
  FREE_BUTTON_STYLE,
  SELECTED_PRODUCT,
  PRODUCT_STYLE,
  CALCULATOR_STYLE,
  LOGO_THEME,
  GET_THEMES_DB
} from "./theme.type";
const initialState = {
  themes: [],
  themes_db: [],
  activeTheme: {
    freeButtonsStyles: null,
    selectedProductStyle: null,
    productsStyle: null,
    calculatorStyle: null,
    globalColors: null,
    active: false,
    id: null,
  },

  globalColors: null,
  freeButtonsStyles: null,
  selectedProductStyle: null,
  productsStyle: null,
  calculatorStyle: null,
  image: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_THEME_STYLE:
      return {
        ...state,
        activeTheme: action.activeTheme,
      };
    case UPDATE_THEMES:
      const ActiveThemeIndex = action.themes.findIndex((obj) => obj.active === 1);

      return {
        ...state,
        themes: action.themes,
        activeTheme: action.themes[ActiveThemeIndex],
        globalColors: action.themes[ActiveThemeIndex].globalColors,
        freeButtonsStyles: action.themes[ActiveThemeIndex].freeButton,
        selectedProductStyle: action.themes[ActiveThemeIndex].selectedProducts,
        productsStyle: action.themes[ActiveThemeIndex].products,
        calculatorStyle: action.themes[ActiveThemeIndex].calculator,
        image: action.themes[ActiveThemeIndex].image,
      };
    case LOAD_THEMES:
      const index = action.themes.findIndex((obj) => obj.active === 1);
      return {
        ...state,
        themes: action.themes,
        activeTheme: action.themes[index],
        globalColors: action.themes[index].globalColors,
        freeButtonsStyles: action.themes[index].freeButton,
        selectedProductStyle: action.themes[index].selectedProducts,
        productsStyle: action.themes[index].products,
        calculatorStyle: action.themes[index].calculator,
        image: action.themes[index].image,
      };
    case ACTIVE_THEME:
      return {
        ...state,
        activeTheme: action.activeTheme,
      };
    case GLOBAL_COLOR:
      return {
        ...state,
        globalColors: action.globalColors,
      };
    case FREE_BUTTON_STYLE:
      return {
        ...state,
        freeButtonsStyles: action.freeButtonsStyles,
      };

    case SELECTED_PRODUCT:
      return {
        ...state,
        selectedProductStyle: action.selectedProductStyle,
      };

    case PRODUCT_STYLE:
      return {
        ...state,
        productsStyle: action.productsStyle,
      };

    case CALCULATOR_STYLE:
      return {
        ...state,
        calculatorStyle: action.calculatorStyle,
      };
    case LOGO_THEME:
      return {
        ...state,
        image: action.image,
      };
    case GET_THEMES_DB:
      return {
        ...state,
        themes_db: action.payload,
      };
    case CLEAR_STATE:
      return (state = initialState);
    default:
      return state;
  }
};

export default reducer;
