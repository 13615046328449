import axios from "axios";
import {
  GET_DISCOUNT,
  GET_COMPANY_ACCOUNT,
  GET_COMPANY_INFORMATIONS,
  GET_WAREHOUSE_PARENT,
  GET_TERRITORY_PARENT,
  GET_CUSTOMER_GROUP_PARENT,
  GET_DEFAULT_WAREHOUSE,
  GET_DEFAULT_TERRIOTRY,
  GET_PRICE_LIST,
  GET_DEFAULT_CUSTOMER_GROUP,
  GET_COMPANY_ITEM_GROUP,
  GET_BUYING_PRICE_LIST,
  LOAD_RETURN_REASONS,
  LOAD_MOTIF_REASONS,
} from "./configuration.type";

export const LoadDiscounts = (discounts) => {
  return {
    type: GET_DISCOUNT,
    payload: discounts,
  };
};
export const LoadCompanyAccount = (companyaccounts) => {
  return {
    type: GET_COMPANY_ACCOUNT,
    payload: companyaccounts,
  };
};

export const LoadCompanyInformation = (companyinformations) => {
  return {
    type: GET_COMPANY_INFORMATIONS,
    payload: companyinformations,
  };
};

export const LoadWarehouseParent = (warehouseparent) => {
  return {
    type: GET_WAREHOUSE_PARENT,
    payload: warehouseparent,
  };
};

export const LoadTerritoryParent = (territoryparent) => {
  return {
    type: GET_TERRITORY_PARENT,
    payload: territoryparent,
  };
};

export const LoadCustomerGroupParent = (customergroupparent) => {
  return {
    type: GET_CUSTOMER_GROUP_PARENT,
    payload: customergroupparent,
  };
};

export const LoadDefaultWarehouse = (defaultwarehouse) => {
  return {
    type: GET_DEFAULT_WAREHOUSE,
    payload: defaultwarehouse,
  };
};

export const LoadDefaultTerriotry = (defaultterriotry) => {
  return {
    type: GET_DEFAULT_TERRIOTRY,
    payload: defaultterriotry,
  };
};
export const LoadPriceList = (pricelist) => {
  return {
    type: GET_PRICE_LIST,
    payload: pricelist,
  };
};
export const LoadDefaultCustomerGroup = (defaultcustomergroup) => {
  return {
    type: GET_DEFAULT_CUSTOMER_GROUP,
    payload: defaultcustomergroup,
  };
};

export const Loadcompanyitemgroup = (itemgroup) => {
  return {
    type: GET_COMPANY_ITEM_GROUP,
    payload: itemgroup,
  };
};

export const LoadBuyingPriceList = (buyingpricelist) => {
  return {
    type: GET_BUYING_PRICE_LIST,
    payload: buyingpricelist,
  };
};

export const LoadLoadReturnReasons = (data) => {
  return {
    type: LOAD_RETURN_REASONS,
    payload: data,
  };
};
export const LoadMotifInventory = (data) => {
  return {
    type: LOAD_MOTIF_REASONS,
    payload: data,
  };
};
