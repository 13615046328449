import React, { useEffect } from 'react';
import { Box, Typography, Button, Modal, OutlinedInput, InputLabel, Grid, FormControl, InputAdornment } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next";
import { loadInventoryLists, CreateStockReconcialiation, SetListToEdit, LoadStockDataReport } from "../../store/inventory/inventory.action";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';
import { SearchClient } from "../checkout/components/searchClient";
import { GlobalfilterData } from "../../helpers/helper";
import { Loader } from "../../components/loader";
import { SimpleTable } from '../../components/simpleTable';
import Pagination from "@material-ui/lab/Pagination";

const useStyles = makeStyles((theme) => ({
  tableRow: {
    boxShadow: "0px 2px 8px #091F461D",
    minHeight : 45,
    borderRadius: 6,
    "& .MuiTableCell-root:first-child": {
      borderTopLeftRadius: 6,
      borderBottomLeftRadius: 6
    },
    "& .MuiTableCell-root:last-child": {
      borderTopRightRadius: 6,
      borderBottomRightRadius: 6
    },
    cursor: "pointer",
  },
  avatar : {
    width: 24,
    height: 24
  },
  avatarCell : {
    width :24
  },
  chartCell : {
    background : "#fff",
    fontSize : "0.875rem",
    padding : "8px 12px",
    color : theme.palette.primary.main,
    width: "500px",
    height: "80px"
  },
  myContainer: {
    marginTop : 20,
    width: "80%",
    height: "80%",
    paddingBottom : 0,
    color : "#657288",
    fontSize : 12,
    borderBottom : 'none',
    paddingLeft : 13
  },
  color: {
    fill : theme.palette.primary.main,
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 10,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  formControl: {
    maxWidth: 300,
    [theme.breakpoints.down("xs")]: {
      marginTop: 10,
    },
  },
  label: {
    fontSize: 12,
    marginBottom: 6,
    color: theme.palette.gray.main,
    paddingLeft: 9,
  },
  title : {
    marginBottom: 30,
    fontSize: 20
  },
  noWrap: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    '&:hover': {
      overflow: "visible",
    },
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    margin: "0.75rem 0px 0.8125rem",
    flexWrap: "wrap",
    "& .MuiInputBase-root": {
      background: theme.palette.white.main,
    },
    "& .MuiOutlinedInput-input": {
      padding: "8.9px 14px",
      fontSize: 12,
    },
  }
}));



export const Inventory = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const {t} = useTranslation(["inventory","common"]);
  const globalDefaults = useSelector((state) => state.Login.globalDefaults);
  const selectedData = useSelector (state => state.Login.selectedData);
  const companyacounts = useSelector((state) => state.Configuration.companyacounts);
  const invLists = useSelector((state) => state.Inventory.invLists);
  const warehouses = useSelector(state => state.Login.stores);
  const products = useSelector(state => state.Product.products);
  const [openModal, setOpenModal] = React.useState(false);
  const [searchProduct, setSearchProduct] = React.useState("");
  const [searchWarehouse, setSearchWarehouse] = React.useState("");
  const [filtredWarehouse, setFiltredWarehouse] = React.useState([]);
  const [filtredProducts, setFiltredProducts] = React.useState([]);
  const [state, setState] = React.useState({
    item_code: "",
    warehouse:"",
    qty: 0,
    current_qty: 0
  });
  const [loading, setLoading] = React.useState(true);
  const [invSearch, setInvSearch] = React.useState("");
  const [filtredInvList, setFiltredInvList] = React.useState([]);
  const [page, setPage] = React.useState(1);

  const columns = [
    {key: "name", title: "common:name"},
    {key: "creation_date", title: "common:createDate"},
    {key: "list_type", title: "inventory:listType"},
    {key: "owner", title: "common:AddBy"},
    {key: "warehouse", title: "common:magasin"},
    {key: "status", title: "common:statut"}
  ];

  const handleSearchInventory = (event) => {
    if (event.target.value == "") {
      setInvSearch(event.target.value);
      setFiltredInvList(invLists)
    } else {
      setInvSearch(event.target.value);
      setFiltredInvList(
        GlobalfilterData(invLists, event.target.value, ["creation_date", "owner", "warehouse", "name", "list_type", "list_id"])
      );
    }
  };

  const [selectedFilters, setSelectedFilters] = React.useState({
    selectedItem: "Tous les Produits",
    selectedItemGroup: "Toutes les Catégories",
    selectedSalesPerson: null
  });

  const handleRedirection = (item_code) => {
    return history.push(`/inventory/${item_code}`);
  }

  const handleRecQtyChange = (e) => {
    setState({ ...state, qty: parseInt(e.target.value)});
  }

  const handleRecWarehouseChange = (row) => {
    setState({ ...state, warehouse: row.name});
    setSearchWarehouse("");
  };

  const handleSearchWarehouse = (event) => {
    setSearchWarehouse(event.target.value);
    setFiltredWarehouse(
      GlobalfilterData(warehouses, event.target.value, [
        "name"
      ])
    );
  };

  const handleRecProductChange = (row) => {
    setState({ ...state, item_code: row.item_code});
    setSearchProduct("");
  };

  const handleProductChange = (row) => {
    if (row == "Tous les Produits" ) {
      setSelectedFilters({...selectedFilters, selectedItem: ""});
    } else {
      setSelectedFilters({...selectedFilters, selectedItem: row.item_code})
    }
    setSearchProduct("")
  };

  const handleSearchProduct = (event) => {
    setSearchProduct(event.target.value);
    setFiltredProducts(
      GlobalfilterData(
      products?.filter((p) => p?.is_stock_item == 1), event.target.value, [
        "item_name",
        "item_code",
        "item_group",
        "description"
      ])
    );
  };

  const handleSubmit = () => {
    const d = new Date();
    const data = {
      doc:{
        docstatus:0,
        doctype:"Stock Reconciliation",
        __islocal:1,
        __unsaved:1,
        naming_series:"MAT-RECO-.YYYY.-",
        company: `${globalDefaults?.default_company}`,
        purpose:"Stock Reconciliation",
        posting_date: d.getFullYear()+"-"+d.getMonth()+"-"+d.getDate(),
        set_posting_time:0,
        items:[
            {
                docstatus:0,
                doctype:"Stock Reconciliation Item",
                __islocal:1,
                __unsaved:1,
                allow_zero_valuation_rate:0,
                current_qty: state.current_qty,
                parentfield:"items",
                parenttype:"Stock Reconciliation",
                idx:1,
                item_code:state.item_code,
                warehouse:state.warehouse,
                qty:state.qty,
            }
        ],
        expense_account: `${companyacounts?.stock_adjustment_account}`,
        cost_center: `${companyacounts?.cost_center}`,
        posting_time: d.getHours()+":"+d.getMinutes()+":"+d.getSeconds(),
      },
      action:"Submit"
    }
    dispatch(CreateStockReconcialiation(data))
    setSelectedFilters({...selectedFilters, selectedItem: state.item_code, selectedFWarehouse: state.warehouse})
    setState({
      item_code: "",
      warehouse:"",
      qty: 0,
      current_qty: 0
    });
    setOpenModal(false);
  }

  const handleCancel = () => {
    setOpenModal(false);
    setState({
      item_code: "",
      warehouse:"",
      qty: 0,
      current_qty:0
    })
  }

  const handleNavigation = () => {
    dispatch(SetListToEdit(null));
    history.push("/inventory-list-generator")
  }

  useEffect(async () => {
    if(globalDefaults?.default_company){
    await dispatch(loadInventoryLists(globalDefaults?.default_company));
    setLoading(false);
    }
  }, [globalDefaults?.default_company]);

  useEffect(() => {
    setFiltredInvList(invLists);
  }, [invLists]);

  const body = (
      <div className={classes.paper}>
        <Typography align={"center"} color={"primary"} className={classes.title}>
          {t("inventory:reconciliation")}
        </Typography>
        <Box>
          <InputLabel required className={classes.label} id="demo-simple-select-outlined-label">
            {t("common:produit")}
          </InputLabel>
            <SearchClient 
              isClient={false} 
              products={products} 
              isProducts={true} 
              isRec={true}
              searchProduct={searchProduct} 
              handleSearchProduct={handleSearchProduct} 
              filtredProducts={filtredProducts} 
              handleProductChange={handleProductChange} 
              selectedFilters={selectedFilters}
              state={state}
              handleRecProductChange={handleRecProductChange}
            />

          <InputLabel required className={classes.label} id="demo-simple-select-outlined-label">
              {t("common:magasin")}
          </InputLabel>
            <SearchClient 
              isClient={false} 
              isProducts={false} 
              isCategory={false} 
              isWarehouse={true}
              isRecWarehouse={true} 
              selectedFilters={selectedFilters} 
              warehouses={warehouses}
              searchWarehouse={searchWarehouse}
              handleSearchWarehouse={handleSearchWarehouse}
              filtredWarehouse={filtredWarehouse}
              // handleWarehouseChange={handleWarehouseChange}
              state={state}
              handleRecWarehouseChange={handleRecWarehouseChange}
            />
          <InputLabel required className={classes.label}>
            {t("common:qty")}
          </InputLabel>
          
            <OutlinedInput
              type="number"
              id="outlined-adornment-weight"
              value={state.qty ?? 0}
              className={classes.recQty}
              name="qty"
              onChange={handleRecQtyChange}
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                "aria-label": "weight",
              }}
              labelWidth={0}
            />
            
        </Box>
        <Box mt={3} justifyContent="center" display="flex">
          <Button disabled={!state.item_code || !state.warehouse || state.qty == 0 || isNaN(state.qty) } variant="contained" color="primary" onClick={() => handleSubmit()}>
          {t("common:continuer")}
          </Button>
          <Button color="primary" onClick={() => handleCancel()}>
          {t("common:annuler")}
          </Button>
        </Box>
      </div>
  );
  
  return (
    <Box>
      <Box className={classes.header}>
        <FormControl className={classes.formControl} variant="outlined">
          <Button variant="contained" color="primary" onClick={handleNavigation}>
            {t("inventory:ListePréparatoireInv")}
          </Button>
        </FormControl>
        <FormControl className={classes.formControl} variant="outlined">
          <OutlinedInput
            id="outlined-adornment-weight"
            value={invSearch}
            placeholder={t("common:rechercher")}
            onChange={handleSearchInventory}
            endAdornment={
              <InputAdornment position="end">
                <span className="icon-search" />
              </InputAdornment>
            }
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
              "aria-label": "weight",
            }}
            labelWidth={0}
          />
        </FormControl>
      </Box>
      <Box mt={2}>
        {loading ? <Loader/> : (
          <Box>
            <SimpleTable title={"inventory"} data={filtredInvList.slice((page-1)*20,page*20)} columns={columns} hasPaper={true} isRowClickable={true} handleRowClick={handleRedirection} rowPrimaryKey={"list_id"} />
            {Math.ceil(filtredInvList.length/20) > 0 && (
              <Box display="flex" justifyContent="center" m={4}>
                <Pagination onChange={(event, newPage) => setPage(newPage)} page={page} count={Math.ceil(filtredInvList.length/20)} color="primary" />
              </Box>
            )}
          </Box>
        )}
      </Box>
      <Modal open={openModal}>
        {body}
      </Modal>
    </Box>
  );
};
