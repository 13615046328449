// productFinancier.reducer.js
import { ITEM_FINANCIER_LIST_LOADED ,ITEM_FINANCIER_POS_LOADED} from "./productFinancier.type";

const initialState = {
  itemFinancierList: [],
  itemFinancierPos: [],
  filtredItemFinancierList: [],
  // other initial state
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ITEM_FINANCIER_LIST_LOADED:
      return {
        ...state,
        itemFinancierList: action.itemFinancierList,
        filtredItemFinancierList: action.itemFinancierList,
      };
      case ITEM_FINANCIER_POS_LOADED:
      return {
        ...state,
        itemFinancierPos: action.itemFinancierPos,
      };
    // other cases
    default:
      return state;
  }
};

export default reducer;
