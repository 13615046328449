import React, { useEffect, useRef } from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { StockEntryDetails } from "../stockEntryDetails";
import { LoadStockEntryById } from "../../../../store/stockEntries/stockEntries.action";
import { Loader } from "../../../../components/loader";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Header } from "../header";
import { SimpleTable } from "../../../../components/simpleTable";
import { useTranslation } from "react-i18next";
import ReactToPrint from "react-to-print";
import PrintIcon from "@material-ui/icons/Print";
import { Button } from "@material-ui/core";
import { StockMvtComponentToPrint } from "../../../../components/componentToPrint/StockMvt";
import { TransfertComponentToPrint } from "../../../../components/componentToPrint/transfert";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 27,
  },
  print: {
    display: "none",
  },
  icons: {
    color: theme.palette.gray.main,
    cursor: "pointer",
  },
}));

export const StockEntryDetailPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const componentRef = useRef();
  const contactCompany = useSelector((state) => state.Login.selectedData);
  const stockEntry = useSelector(
    (state) => state.StockEntry.selectedStockEntry
  );
  const [loading, setLoading] = React.useState(true);
  let { id } = useParams();
  const { t } = useTranslation(["transfert", "common"]);

  const stockEntryDetailsColumns = [
    { key: "item_code", title: "common:ref" },
    { key: "item_name", title: "common:name" },
    { key: "qty", title: "common:qty" },
    { key: "t_warehouse", title: "common:mgsCible" },
    { key: "s_warehouse", title: "common:mgsSource" },
  ];
  const dispatch = useDispatch();

  const initailisation = async () => {
    await dispatch(LoadStockEntryById(id));
    setLoading(false);
  };

  useEffect(() => {
    initailisation();
  }, [id]);
  return (
    <Box>
      <Header goBack={"/stock-entry"} />
      {loading ? (
        <Loader />
      ) : (
        <Grid
          container
          direction="row"
          spacing={2}
          className={classes.root}
          display="flex"
        >
          <Grid item xs={12} md={4}>
            <StockEntryDetails details={stockEntry} />
          </Grid>
          <Grid item xs={12} md={8}>
            <div
              style={{
                display: "flex",
                justifyContent: "right",
                alignItems: "right",
              }}
            >
              {/*   {stockEntry?.stock_entry_type == "Material Transfer" && stockEntry?.from_warehouse == contactCompany?.store &&
              <Button
                color="primary"
                variant="text"
                className={classes.link}
                onClick={() => {
                  history.push("/transfer-requests/" + stockEntry?.name);
                }}
              >
                {t("transfert:DemandeAchat")}
              </Button>} */}
              <ReactToPrint
                trigger={() => (
                  <Button className={classes.button}>
                    <PrintIcon className={classes.icons} />
                  </Button>
                )}
                content={() => componentRef.current}
              />
              <div className={classes.print}>
                <StockMvtComponentToPrint
                  data={stockEntry}
                  columns={stockEntryDetailsColumns}
                  contactCompany={contactCompany && contactCompany.contact}
                  title={t("common:movements")}
                  ref={componentRef}
                />
              </div>
            </div>
            <SimpleTable
              title={"stockEntryDetail"}
              hasPaper={true}
              data={stockEntry ? stockEntry.items : []}
              columns={stockEntryDetailsColumns}
            />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};
