import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormControl from "@material-ui/core/FormControl";
import validator from 'validator';
import { MenuItem, Select } from "@material-ui/core";
import { CreateAddressCustomer } from "../../../../store/client/client.action";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { InputLabel, Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: 15,
    "& .MuiInputBase-root": {
      background: theme.palette.white.main,
    },
    "& .MuiOutlinedInput-input": {
      padding: "8.9px 14px",
      fontSize: 12,
    },
  },
  paper: {
    paddingLeft: 10,
    width: "100%",
    "& .MuiInputBase-root": {
      background: theme.palette.white.main,
    },
    "& .MuiOutlinedInput-input": {
      padding: "8.9px 14px",
      fontSize: 12,
    },
    "& .MuiSelect-root": {
      padding: "0.4938rem 0.875rem",
    },
  },
  label: {
    fontSize: 12,
    marginBottom: 6,
    color: theme.palette.gray.main,
    paddingLeft: 9,
  },
  checkbox: {
    fontSize: 12,
    marginBottom: 6,
    color: theme.palette.gray.main
  },
  date: {
    marginBottom: 15,
  },
  firstCol: {
    [theme.breakpoints.up("md")]: {
      marginRight: 37,
    },
  },
  formBlock: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
    },
  },
  icon: {
    borderRadius: 3,
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: theme.palette.blue.main,
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: theme.palette.blue.main,
    },
  },
  buttonicon: {
    marginLeft: -15,
    marginTop: 10
  },
}));

export const AddressForm = (props) => {
  const { closingModal } = props
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation(["common", "address"]);
  const country = useSelector(state => state.Setting.country);
  const loyaltyPrograms = useSelector(state => state.Client.loyaltyPrograms);
  const territory = useSelector(state => state.Setting.territory);
  const customersgroup = useSelector(state => state.Client.customersgroup);

  const { state, setState, submit } = props
  const [error, setError] = React.useState({
    address_type: false,
    address_title: false,
    address_line1: false,
    city: false,
    country: false,
    email_id: false,
    phone: false,
    /*    link_name: false */

  });
  const handleChange = (event) => {
    setError({ ...error, [event.target.name]: false });
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event) => {
    setError({
      address_type: state.address_type === "",
      address_title: state.address_title === "",
      address_line1: state.address_line1 === "",
      city: state.city === "",
      country: state.country === "",
      email_id: state.email_id !== "" && !(validator.isEmail(state.email_id)),
      phone: state.phone !== "" && !(validator.isMobilePhone(state.phone)),
      /*  link_name: state.link_name === "" */

    });
    /*  if (isValid) {
       dispatch(CreateAddressCustomer(state))
     } */
    submit();
  };

  return (
    <Box className={classes.paper}>
      <Box className={classes.formBlock}>
        <Grid className={classes.firstCol} item xs={12} md={6}>
          <InputLabel error={error.address_title} required className={classes.label}>
            {t("address:titreAdress")}
          </InputLabel>
          <FormControl variant="outlined" className={classes.formControl}>
            <OutlinedInput
              id="outlined-adornment-weight"
              value={state.address_title}
              error={error.address_title}
              name="address_title"
              onChange={handleChange}
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                "aria-label": "weight",
              }}
              labelWidth={0}
            />
          </FormControl>
          <InputLabel error={error.address_type} required className={classes.label}>
            {t("address:typeAddress")}
          </InputLabel>
          <FormControl variant="outlined" className={classes.formControl}>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              className={classes.select}
              value={state.address_type}
              error={error.address_type}
              name="address_type"
              onChange={handleChange}
              inputProps={{
                "aria-label": "weight",
              }}
              labelWidth={0}
            >
              <MenuItem value={"Billing"}>  {t("address:Billing")}</MenuItem>
              <MenuItem value={"Shipping"}> {t("address:expedition")}</MenuItem>
              <MenuItem value={"Office"}> {t("address:bureau")}</MenuItem>
              <MenuItem value={"Personal"}> {t("address:personnel")}</MenuItem>
              <MenuItem value={"Plant"}> {t("address:plant")}</MenuItem>
              <MenuItem value={"Postal"}> {t("address:postal")}</MenuItem>
              <MenuItem value={"Shop"}> {t("address:boutique")}</MenuItem>
              <MenuItem value={"Subsidiary"}> {t("address:filiale")}</MenuItem>
              <MenuItem value={"Warehouse"}> {t("address:magasin")}</MenuItem>
              <MenuItem value={"Current"}> {t("address:courant")}</MenuItem>
              <MenuItem value={"Permanent"}> {t("address:permanent")}</MenuItem>
              <MenuItem value={"Other"}> {t("address:autre")}</MenuItem>
            </Select>
          </FormControl>
          <InputLabel error={error.address_line1} required className={classes.label}>
            {t("address:ligneAddress")}
          </InputLabel>
          <FormControl variant="outlined" className={classes.formControl}>
            <OutlinedInput
              id="outlined-adornment-weight"
              value={state.address_line1}
              error={error.address_line1}
              name="address_line1"
              onChange={handleChange}
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                "aria-label": "weight",
              }}
              labelWidth={0}
            />
          </FormControl>
          <InputLabel error={error.city} required className={classes.label}>{t("common:city")}</InputLabel>
          <FormControl className={classes.formControl} variant="outlined">
            <OutlinedInput
              id="outlined-adornment-weight"
              value={state.city}
              error={error.city}
              name="city"
              onChange={handleChange}
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                "aria-label": "weight",
              }}
              labelWidth={0}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <InputLabel className={classes.label}>{t("common:name")} </InputLabel>
          <FormControl className={classes.formControl} variant="outlined">
            <OutlinedInput
              id="outlined-adornment-weight"
              value={state.name}
              name="name"
              onChange={handleChange}
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                "aria-label": "weight",
              }}
              labelWidth={0}
            />
          </FormControl>
          <InputLabel className={classes.label}>{t("common:country")}</InputLabel>
          <FormControl variant="outlined" className={classes.formControl}>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              className={classes.select}
              value={state.country}
              IconComponent={ExpandMore}
              onChange={handleChange}
              name="country"
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
            >
              {country.map((row, index) => (
                <MenuItem value={row.country_name} key={row.country_name}>
                  {row.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <InputLabel error={error.email_id} className={classes.label}>{t("common:email")}</InputLabel>
          <FormControl className={classes.formControl} variant="outlined">
            <OutlinedInput
              id="outlined-adornment-weight"
              value={state.email_id}
              error={error.email_id}
              name="email_id"
              onChange={handleChange}
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                "aria-label": "weight",
              }}
              labelWidth={0}
            />
          </FormControl>
          <InputLabel className={classes.label}>{t("address:Téléphone")}</InputLabel>
          <FormControl className={classes.formControl} variant="outlined">
            <OutlinedInput
              id="outlined-adornment-weight"
              value={state.phone}
              name="phone"
              onChange={handleChange}
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                "aria-label": "weight",
              }}
              labelWidth={0}
            />
          </FormControl>
        </Grid>
      </Box>
      {/* <Box className={classes.formBlock}>
        <Grid className={classes.firstCol} item xs={12} md={6}>
          <InputLabel className={classes.label}>{t("address:typeDoc")}</InputLabel>
          <FormControl className={classes.formControl} variant="outlined">
            <OutlinedInput
              id="outlined-adornment-weight"
              value={state.link_doctype}
              name="link_doctype"
              //onChange={handleChange}
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                "aria-label": "weight",
              }}
              labelWidth={0}
            />
          </FormControl>
        </Grid> */}
        {/*   <Grid item xs={12} md={6}>
          <InputLabel error={error.link_name} required className={classes.label}>{t("address:nomClient")}</InputLabel>
          <FormControl className={classes.formControl} variant="outlined">
            <OutlinedInput
              id="outlined-adornment-weight"
              value={state.link_name}
              error={error.link_name}
              name="link_name"
              onChange={handleChange}
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                "aria-label": "weight",
              }}
              labelWidth={0}
            />
          </FormControl>
        </Grid> 
      </Box>*/}


      <Box display="flex" justifyContent="space-between" >
        {closingModal && <Button className={classes.modalButton} variant="contained" color="secondary" onClick={() => closingModal(false)} >{t("common:annuler")}</Button>
        }
        <Button onClick={handleSubmit} variant="contained" color="primary">
          {t("common:save")}
        </Button>
      </Box>
    </Box>
  );
};
