export const CREATE_HOLD = "CREATE_HOLD";
export const CREATE_HOLD_SUCCESS = "CREATE_HOLD_SUCCESS";
export const GET_HOLDS = "GET_HOLDS";
export const HOLDS_LOADED = "HOLDS_LOADED";
export const DELETE_HOLDS_BY_ID = "DELETE_HOLDS_BY_ID";
export const HOLDS_DELETED = "HOLDS_DELETED";
export const SAVE_HOLD = "SAVE_HOLD";
export const CREATE_BULK_HOLD_SUCCESS = "CREATE_BULK_HOLD_SUCCESS";
export const CREATE_BULK_HOLD = "CREATE_BULK_HOLD";
export const SET_HOLD_SELECTED = "SET_HOLD_SELECTED";
export const RELOAD_HOLD = "RELOAD_HOLD";
export const GET_HOLD_BY_ID = "GET_HOLD_BY_ID";
export const HOLD_LOADED = "HOLD_LOADED";
export const UPDATE_PAYMENT_STATUS_SUCCESS = "UPDATE_PAYMENT_STATUS_SUCCESS";
export const UPDATE_PAYMENT_STATUS = "UPDATE_PAYMENT_STATUS";
